import React, {useEffect, useState} from "react";
import CustomInputField from "../../../../sharedComponents/reduxfields/customInputField";
import CustomTextarea from "../../../../sharedComponents/reduxfields/CustomTextArea";
import PrimaryButton from "../../../../sharedComponents/buttons/PrimaryButton";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import PersonalInfoInnerTabs from "./personalInfoInnerTabs";
import {connect, useDispatch, useSelector} from "react-redux";
import {change, Field, getFormValues, reduxForm} from "redux-form";
import * as nurshAction from "../nurseActionTypes";
import {getNurseAction, postNurseAction} from "../../../../services/NursesProfile";
import CustomDropDownNewField from "../../../../sharedComponents/reduxfields/customDropDownNew";
import {useQueryClient} from "@tanstack/react-query";
import stringHelperFunctions from "../../../../helpers/string.helpers";
import numberHelperFunctions from "../../../../helpers/number.helpers";
import booleanHelperFunctions from "../../../../helpers/boolean.helpers";
import {HttpsAction} from "../../../../commonApiFunction/httpsAction";
import {invalidateClientsList} from "../../../../queries/agency/clientsList";
import classes from "../../Client/ClientProfile/CareNote/CareNote.module.css";
import {required} from "../../../../helpers/validation";
import SaveButton from "../../../../sharedComponents/buttons/SaveButton";
import useCustomNav from "../../../../utils/hooks/useCustomNav";

// const Education = ({dataLoaded, initialValues, handleSubmit, submitFailed}) => {
const Education = ({formData, listLength, notNewAndNotDeletedListLength, handleSubmit, pristine, submitting, isAdmin, agencyId}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { id: nurseId } = useParams();
  const { nurseId } = useOutletContext();

    // const isAdmin = window.location.pathname.includes('/admin/agencyNurses/edit');
    //
    // const educationList = useSelector(
    //     (state) => {
    //         if (state != null && state.nurseListing != null && state.nurseListing.nurseEducationInfo != null) {
    //             console.log('submit- state.nurseListing.nurseEducationInfo', state.nurseListing.nurseEducationInfo);
    //         }
    //         return state?.nurseListing?.nurseEducationInfo;
    //     }
    // );

  const { goTo: gotoAdditionalQuestionsPage } = useCustomNav(isAdmin ? `/admin/agency/nurses/edit/profile/personal-info/additional-questions` : '/agency/nurses/edit/profile/personal-info/additional-questions');

  const coursesList = queryClient.getQueryData(['list-edu-courses']);
  const degreesList = queryClient.getQueryData(['list-edu-degrees']);

  // const [isLoading, setLoading] = useState(false);
  // const [loadedOnce, setLoadedOnce] = useState(false);
  // const [loadOnceDone, setLoadOnceDone] = useState(false);
  const [allowAutoAddition, setAllowAutoAddition] = useState(true);
  const [dataModified, setDataModified] = useState(false);
  const [xtraFieldValue, setXtraFieldValue] = useState(1);

    const handleAddWorkEducation = () => {
    // dispatch({ type: nurshAction.NURSE_ADD_EDUCATION });

      let updatedList = Array.from({length: listLength}, (v, i) => i).map((val, index) => {
        return {
          recordType: formData[`recordType${index + 1}`],
          id: formData[`id${index + 1}`],
          institutionName: formData[`institutionName${index + 1}`],
          // addressLine1: formData[`addressLine1${index + 1}`],
          location: formData[`location${index + 1}`],
          tenureStartDate: formData[`tenureStartDate${index + 1}`],
          tenureEndDate: formData[`tenureEndDate${index + 1}`],
          degreeId: formData[`degreeId${index + 1}`],
          majorEducationalSubjectId: formData[`majorEducationalSubjectId${index + 1}`],
          description: formData[`description${index + 1}`],
          // index,
        };
      });
      dispatch(HttpsAction({
        reducerName: 'NURSE',
        reduxKeyName: 'nurseEducationInfo',
        actionType: 'NURSE_EDUCATION',
        data: {xtraField: formData['xtraField'], list: updatedList},
        updateData: true,
        actionTypeIsDispatchType: true,
        positiveCallBack: (e) => {
          dispatch({ type: nurshAction.NURSE_ADD_EDUCATION });
          setDataModified(true);
        },
      }));
    };

    const handleDelete = (deletedIndex) => {
        // if (deletedIndex) {
        //     let clonedList = [...educationList];
        //     if (clonedList[deletedIndex - 1]?.nurseEduId) {
        //         clonedList[deletedIndex - 1] = {
        //             recordType: 'deleted',
        //             id: clonedList[deletedIndex - 1].nurseEduId,
        //         };
        //
        //         dispatch({
        //             type: 'ADD_NURSE_REMOVE_EDUCATION',
        //             payload: clonedList,
        //             name: 'nurseEducationInfo',
        //             meta: {type: 'REMOVE_EDUCATION'},
        //         });
        //     } else {
        //         const updatedList = educationList.filter((item, index) => index !== deletedIndex - 1)
        //         dispatch({
        //             type: 'ADD_NURSE_REMOVE_EDUCATION',
        //             payload: updatedList,
        //             name: 'nurseEducationInfo',
        //             meta: {type: 'REMOVE_EDUCATION'},
        //         });
        //     }
        // }

      const list = Array.from({ length: listLength }, (v, i) => i);
      let updatedList = list.map((val, index) => {
        return {
          recordType: formData[`recordType${index + 1}`],
          id: formData[`id${index + 1}`],
          institutionName: formData[`institutionName${index + 1}`],
          // addressLine1: formData[`addressLine1${index + 1}`],
          location: formData[`location${index + 1}`],
          tenureStartDate: formData[`tenureStartDate${index + 1}`],
          tenureEndDate: formData[`tenureEndDate${index + 1}`],
          degreeId: formData[`degreeId${index + 1}`],
          majorEducationalSubjectId: formData[`majorEducationalSubjectId${index + 1}`],
          description: formData[`description${index + 1}`],
          // index,
        };
      });
      const deletedItemIdValue = formData[`id${deletedIndex}`];
      if (deletedItemIdValue === '0') { // item not present in database, so remove item from list/array
        updatedList.splice(deletedIndex - 1, 1);
      } else { // item present in database, so identify/mark it for deletion for later API call
        updatedList[deletedIndex - 1].recordType = "deleted";
      }
// console.log('test- updatedList', updatedList)
      setAllowAutoAddition(false);
      dispatch(HttpsAction({
        reducerName: 'NURSE',
        reduxKeyName: 'nurseEducationInfo',
        actionType: 'NURSE_EDUCATION',
        data: {xtraField: formData['xtraField'], list: updatedList},
        updateData: true,
        actionTypeIsDispatchType: true,
        positiveCallBack: (_) => {
          setDataModified(true);
        },
      }));
    };

    const onSubmit = async (data) => {
// console.log('test- onSubmit', data)
    //     // console.log("submit- data", data);
    //     // console.log(educationList, "educationList");
    //     const payload = [];
    //     educationList.forEach((value, index) => {
    //         if (value.recordType !== "deleted") {
    //             // console.log("submit- value", value);
    //             const recordType = data[`recordType${index + 1}`];
    //             // alert(recordType)
    //             // alert(data[`id${index + 1}`])
    //             payload.push({
    //                 // id: data[`id${index + 1}`],
    //                 // recordType: data[`recordType${index + 1}`]
    //                 //   ? data[`recordType${index + 1}`]
    //                 //   : "new",
    //                 id: recordType === 'existing' ? data[`id${index + 1}`] : undefined,
    //                 recordType,
    //                 institutionName: stringHelperFunctions.nullIfEmpty(data[`institutionName${index + 1}`]),
    //                 // addressLine1: data[`addressLine1${index + 1}`],
    //                 location: stringHelperFunctions.nullIfEmpty(data[`location${index + 1}`]),
    //                 tenureStartDate: stringHelperFunctions.nullIfEmpty(data[`tenureStartDate${index + 1}`]),
    //                 tenureEndDate: stringHelperFunctions.nullIfEmpty(data[`tenureEndDate${index + 1}`]),
    //                 majorEducationalSubjectId: stringHelperFunctions.nullIfEmpty(+data[`majorEducationalSubjectId${index + 1}`]),
    //                 description: stringHelperFunctions.nullIfEmpty(data[`description${index + 1}`]),
    //                 degreeId: stringHelperFunctions.nullIfEmpty(+data[`degreeId${index + 1}`]),
    //             });
    //         } else {
    //             payload.push(value)
    //         }
    //     });
    //
    // // console.log('submit- payload', payload)
    //
    //     try {
    //         const response = await dispatch(
    //             postNurseAction(
    //                 payload,
    //                 "education",
    //                 nurseId,
    //                 "Nurse Education Updated Successfully"
    //             )
    //         );
    //         if (response?.success) {
    //             navigate(isAdmin ? `/admin/agencyNurses/edit/${nurseId}/additionalQuestions` : `/agency/nurses/edit/${nurseId}/additionalQuestions`)
    //         }
    //     } catch (error) {
    //         console.error(error);
    //     }
    //
    // // console.log(payload);
    //
    // // return false;

      try {
        const payload = [];
        for (let i = 0; i < listLength; i++) {
          if (data[`recordType${i + 1}`] !== "deleted") {
            const recordType = data[`recordType${i + 1}`];
            console.log(`index ==> ${i} ==>`, data[`recordType${i + 1}`])
            let id = +data[`id${i + 1}`];
            payload.push({
              recordType,
              id: id === 0 ? undefined : id,
              institutionName: stringHelperFunctions.nullIfEmpty(data[`institutionName${i + 1}`]),
              // addressLine1: stringHelperFunctions.nullIfEmpty(data[`addressLine1${i + 1}`]),
              location: stringHelperFunctions.nullIfEmpty(data[`location${i + 1}`]),
              tenureStartDate: stringHelperFunctions.nullIfEmpty(data[`tenureStartDate${i + 1}`]),
              tenureEndDate: stringHelperFunctions.nullIfEmpty(data[`tenureEndDate${i + 1}`]),
              degreeId: numberHelperFunctions.nullIfEmpty(data[`degreeId${i + 1}`], true),
              majorEducationalSubjectId: numberHelperFunctions.nullIfEmpty(data[`majorEducationalSubjectId${i + 1}`], true),
              description: stringHelperFunctions.nullIfEmpty(data[`description${i + 1}`]),
            });
          } else {
            const recordType = data[`recordType${i + 1}`];
            let id = +data[`id${i + 1}`];
            payload.push({recordType, id: id === 0 ? undefined : Number(id)})
          }
        }
// console.log('test- payload', payload)
        const response = await dispatch(postNurseAction(payload, "education", nurseId, "Nurse education updated successfully"));
        if (response?.success) {
          // navigate(isAdmin ? `/admin/agencyNurses/edit/${nurseId}/additionalQuestions` : `/agency/nurses/edit/${nurseId}/additionalQuestions`)
          gotoAdditionalQuestionsPage();
        }
      } catch (error) {
        console.error(error);
      }
    };

    // useEffect(() => {
    //     dispatch(getNurseAction("educationInfo", nurseId));
    //
    //     // populate the hidden fields
    //     if (educationList != null && Array.isArray(educationList)) {
    //         for (let i = 0; i < educationList.length; i++) {
    //             const educationInfoDatum = educationList[i];
    //             dispatch(change('Education', `recordType${i + 1}`, educationInfoDatum.recordType));
    //             dispatch(change('Education', `id${i + 1}`, educationInfoDatum.id));
    //         }
    //     }
    // }, [loadOnceDone]);

    // useEffect(() => {
    //     if (!loadOnceDone && nurseId != null && initialValues != null && dataLoaded) {
    //         setLoadOnceDone(true);
    //     }
    // }, [dataLoaded]);
    //
    // useEffect(() => {
    //     if (!isLoading && loadedOnce) {
    //         setLoadedOnce(false);
    //     }
    // }, [loadedOnce]);
    //
    // useEffect(() => {
    //     if (isLoading) {
    //         setLoading(false);
    //         setLoadedOnce(true);
    //     }
    // }, [isLoading]);

  useEffect(() => {
    if (dataModified) {
      setDataModified(false);
      dispatch(change("EducationForm", 'xtraField', String(xtraFieldValue), true));
      setXtraFieldValue(prevState => prevState + 1);
    }
  }, [dataModified]);

    useEffect(() => {
      if (allowAutoAddition && listLength === 0) {
        dispatch({type: nurshAction.NURSE_ADD_EDUCATION});
      }
    }, [listLength]);

    useEffect(() => {
        // setLoading(true);
      dispatch(getNurseAction("educationInfo", nurseId));
      return () => {
        dispatch(HttpsAction({
          reducerName: 'NURSE',
          reduxKeyName: 'nurseEducationInfo',
          actionType: 'NURSE_EDUCATION',
          removeData: true,
          actionTypeIsDispatchType: true,
        }));
      };
    }, []);

  // useEffect(() => {
  //   // alert(educationList?.length);
  //   if (educationList?.length === 0) {
  //     dispatch({ type: nurshAction.NURSE_ADD_EDUCATION });
  //   }
  // }, [educationList?.length === 0]);

// console.log('test- listLength', listLength)
// console.log('test- formData', formData)

    return (
        <div>
            <div className="card-white px-4">
                {/*<PersonalInfoInnerTabs></PersonalInfoInnerTabs>*/}
                <form className="pt-3" onSubmit={handleSubmit(onSubmit)}>
                  <Field
                    name="xtraField"
                    type="hidden"
                    component={CustomInputField}
                  />
                  <div className="p-0 m-0 d-flex flex-column justify-content-start align-content-start gap-3">
                    {/*{(educationList || []).filter(v => v.recordType !== 'deleted').map((value, index) => (*/}
                    {listLength > 0 && (Array.from({length: listLength}, (val, idx) => idx) || []).map((value, index) => (
                      <React.Fragment key={index}>
                        <div className="card" style={{
                          overflow: "hidden",
                          borderWidth: formData != null && JSON.stringify(formData) !== '{}' && formData[`recordType${index + 1}`] === "deleted" ? "0" : "auto",
                          height: formData != null && JSON.stringify(formData) !== '{}' && formData[`recordType${index + 1}`] === "deleted" ? "1px" : "auto"
                        }}>
                          <div className="card-body">
                            <div className={`row ${index === 0 ? '' : 'mt-2'}`}>
                              <div className="col-6 d-flex align-items-center">
                                <div className={`col-4`} style={{fontSize: "18px", fontWeight: 500, whiteSpace: "nowrap"}}>
                                  Education #{index + 1}
                                </div>
                              </div>
                              <div className="col-6 d-flex justify-content-end">
                                <Field
                                  name={`recordType${index + 1}`}
                                  type="hidden"
                                  component={CustomInputField}
                                />
                                <Field
                                  name={`id${index + 1}`}
                                  type="hidden"
                                  component={CustomInputField}
                                />
                                {(notNewAndNotDeletedListLength > 0 || listLength > 1 || index > 0) &&
                                  <PrimaryButton
                                    type="button"
                                    className="btn-view-repo gap-2 px-4 py-2"
                                    text="Delete Education"
                                    onClick={() => handleDelete(index + 1)}
                                  />}
                              </div>
                                <div className={classes.contactFormHeadingBorder}/>
                                <div style={{padding: '24px 24px 0 24px'}}>
                                  <div>
                                    <div className="row">
                                      <div className="col-12 col-md-6">
                                        <div className="mb-4">
                                          <Field
                                            className="form-control mk-wi"
                                            name={`institutionName${index + 1}`}
                                            label="Name Of institute"
                                            component={CustomInputField}
                                            requiredMark={true}
                                            validate={required}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-12 col-md-6">
                                        <div className="mb-4">
                                          <Field
                                            className="form-control mk-wi"
                                            name={`location${index + 1}`}
                                            label="Location"
                                            component={CustomInputField}
                                            // requiredMark={true}
                                            // validate={required}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-12 col-md-6">
                                        <div className="mb-4">
                                          <Field
                                            className="form-control mk-wi"
                                            name={`tenureStartDate${index + 1}`}
                                            label="Tenure Start Date"
                                            type="date"
                                            component={CustomInputField}
                                            // requiredMark={true}
                                            // validate={required}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-12 col-md-6">
                                        <div className="mb-4">
                                          <Field
                                            className="form-control mk-wi"
                                            type="date"
                                            name={`tenureEndDate${index + 1}`}
                                            label="Tenure End Date"
                                            component={CustomInputField}
                                            // requiredMark={true}
                                            // validate={required}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-12 col-md-6">
                                        <div className="mb-4">
                                          <Field
                                            className="form-control mk-wi"
                                            label="Course of study"
                                            name={`majorEducationalSubjectId${index + 1}`}
                                            component={CustomDropDownNewField}
                                            // requiredMark={true}
                                            // validate={required}
                                          >
                                            <option value="">Please select</option>
                                            {(coursesList || [])?.map((res, index) => {
                                              return (
                                                <option key={index} value={res.id}>
                                                  {res.name}
                                                </option>
                                              );
                                            })}
                                          </Field>
                                        </div>
                                      </div>
                                      <div className="col-12 col-md-6">
                                        <div className="mb-4">
                                          <Field
                                            className="form-control mk-wi"
                                            label="Degree"
                                            name={`degreeId${index + 1}`}
                                            component={CustomDropDownNewField}
                                            // requiredMark={true}
                                            // validate={required}
                                          >
                                            <option value="">Please select</option>
                                            {(degreesList || [])?.map((res, index) => {
                                              return (
                                                <option key={index} value={res.id}>
                                                  {res.name}
                                                </option>
                                              );
                                            })}
                                          </Field>
                                        </div>
                                      </div>
                                      <div className="col-12 col-md-12">
                                        <div className="mb-4">
                                          <Field
                                            className="form-control mk-wi"
                                            type="textarea"
                                            name={`description${index + 1}`}
                                            label="Description"
                                            component={CustomTextarea}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                      </React.Fragment>
                    ))}
                    <div className={'w-100 d-flex flex-row justify-content-center gap-2'}>
                      <PrimaryButton
                        type="button"
                        className="btn btn-primary w-100 rounded-0 border-btn-info"
                        style={{maxWidth: '210px'}}
                        text="Add New Education"
                        onClick={() => handleAddWorkEducation()}
                      />
                    </div>
                  </div>
                  <SaveButton pristine={pristine} submitting={submitting}
                              onClick={() => {
                                dispatch(change("EducationForm", 'xtraField', String(xtraFieldValue)));
                                setXtraFieldValue(prevState => prevState + 1);
                                return undefined;
                              }}
                  />
                </form>
            </div>
          {/* <div
           className="d-flex justify-content-center"
           style={{ margin: "50px 0" }}
           >
           <PrimaryButton
           type="button"
           className="btn-view-repo gap-2 px-5 py-2"
           text={"Next"}
           onClick={() => navigate("/agency/nurses/addnewrequest/certification")}
           />
           </div> */}
        </div>
    );
};

// export default Education;

const mapStateToProps = (state) => {
  if (state?.nurseListing?.nurseEducationInfo != null) {
    const {auth, nurseListing: {nurseEducationInfo: {xtraField = '0', list: dataList = []} = {xtraField: '0', list: []}}} = state;

    let initialValues = {xtraField};
    dataList?.forEach((edu, index) => {
      const recordType = edu.recordType ?? "existing";
      initialValues[`recordType${index + 1}`] = edu.recordType || "existing";
      initialValues[`id${index + 1}`] = edu?.id?.toString() || '0';
      initialValues[`institutionName${index + 1}`] = edu.institutionName ?? "";
      // initialValues[`addressLine1${index + 1}`] = edu.addressLine1 ?? "";
      initialValues[`location${index + 1}`] = edu.location ?? "";
      initialValues[`tenureStartDate${index + 1}`] = edu.tenureStartDate ?? "";
      initialValues[`tenureEndDate${index + 1}`] = edu.tenureEndDate ?? "";
      initialValues[`degreeId${index + 1}`] = edu.degreeId ?? "";
      initialValues[`majorEducationalSubjectId${index + 1}`] = edu.majorEducationalSubjectId ?? "";
      initialValues[`description${index + 1}`] = edu.description ?? "";
    });
    // console.log(initialValues, "initialValues");
    return {
      // dataLoaded: nurseEducationInfo != null,
      isAdmin: auth?.authData?.isAdmin || false,
      agencyId: auth?.authData?.agencyId || auth.agencyId,
      listLength: (dataList || []).length,
      notNewAndNotDeletedListLength: (dataList || []).filter(p => !(p.recordType === 'new' || p.recordType === 'deleted')).length,
      formData: getFormValues('EducationForm')(state),
      initialValues,
    };
  }

  return {};
};

export default connect(mapStateToProps)(
  reduxForm({form: "EducationForm", enableReinitialize: true})(Education)
);
