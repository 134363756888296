import React, {useEffect, useState} from "react";
import {Field, reduxForm} from "redux-form";
import CustomDropDownField from "../../../../../../sharedComponents/reduxfields/customDropDown";
import {connect, useDispatch, useSelector} from "react-redux";
import classes from "../../../css/careInformation.module.css"
import CustomInputField from "../../../../../../sharedComponents/reduxfields/customInputField";
import Modal from "../../../../../../sharedComponents/Modal";
import CreateReferral from "./CreateReferralModal";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {
    getCareInfoById,
    getClientProfilePreferredCaregiver,
    postClientProfileCareInfo
} from "../../../../../../services/client";
import PrimaryButton from "../../../../../../sharedComponents/buttons/PrimaryButton";
import moment from "moment"
import {HttpsAction} from "../../../../../../commonApiFunction/httpsAction";
import {
    getAdminsList,
    getAllPersonsList, getNursesList,
    getProfessionList,
    getRegionsList
} from "../../../../../../services/commonServices";
import dateHelperFunctions from "../../../../../../helpers/date.helpers";
import {required} from "../../../../../../helpers/validation";
import {useQueryClient} from "@tanstack/react-query";
import stringHelperFunctions from "../../../../../../helpers/string.helpers";
import numberHelperFunctions from "../../../../../../helpers/number.helpers";
import {invalidateClientsList} from "../../../../../../queries/agency/clientsList";
import PatientActiveDischargeInactivateModal from "./SetEffectiveEndDateModal";
import useCustomNav from "../../../../../../utils/hooks/useCustomNav";

const CareInformation = ({handleSubmit, statusColor, borderColor, buttonText, agencyId}) => {
    const statusWidth = '130px';
    const statusButtonWidth = '190px';

    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const {clientId, id} = useParams();
    const { clientId } = useOutletContext();

    const { goTo: gotoResidentialAddressPage } = useCustomNav('/agency/clients/edit/profile/residential-address');

    const adminsArray = queryClient.getQueryData(['list-admins', agencyId]);
    const regionsArray = queryClient.getQueryData(['list-regions', agencyId]);
    const personsList = queryClient.getQueryData(['list-persons', agencyId]);
    const nursesArray = queryClient.getQueryData(['list-internal-nurses', agencyId]);

    const [effectiveEndDateModal, setEffectiveEndDateModal] = useState(false);
    const [createNewModal, setCreateNewModal] = useState(false);
    const [effectiveEndDateModalData, setEffectiveEndDateModalData] = useState({});

    const handleChange = ({name, value}) => {
        setEffectiveEndDateModalData(pre => ({...pre, [name]: value}))
    }

    const handleEffectiveEndDateModal = () => setEffectiveEndDateModal(pre => !pre)
    const handleCreateNewModal = () => setCreateNewModal(pre => !pre)

    const onSubmit = async (data) => {
        const {
            leadCreatedDate,
            initialAssessmentDate,
            conversionDate,
            startDate,
            administratorId,
            regionId,
            referralPersonalInfoId,
            medicaidNumber,
            invoicingId,
            preferredNurseId
        } = data;
        const res = await dispatch(postClientProfileCareInfo({
            "regionId": numberHelperFunctions.nullIfEmpty(regionId),
            "startDate": stringHelperFunctions.nullIfEmpty(dateHelperFunctions.updateDateFormat(startDate)),
            "referralPersonalInfoId": numberHelperFunctions.nullIfEmpty(referralPersonalInfoId),
            "invoicingId": stringHelperFunctions.nullIfEmpty(invoicingId),
            "conversionDate": stringHelperFunctions.nullIfEmpty(dateHelperFunctions.updateDateFormat(conversionDate)),
            "medicaidNumber": stringHelperFunctions.nullIfEmpty(medicaidNumber),
            "administratorId": numberHelperFunctions.nullIfEmpty(/*administratorId*/ null),
            "leadCreatedDate": stringHelperFunctions.nullIfEmpty(dateHelperFunctions.updateDateFormat(leadCreatedDate)),
            // "effectiveEndDate": stringHelperFunctions.nullIfEmpty(effectiveEndDateModalData?.effectiveEndDate),
            "preferredNurseId": numberHelperFunctions.nullIfEmpty(preferredNurseId),
            "initialAssessmentDate": stringHelperFunctions.nullIfEmpty(initialAssessmentDate),
        }, clientId))
        if (res?.success) {
            // navigate(`/agency/clients/${clientId}/${id}/residentialAddress`);
            invalidateClientsList(queryClient, agencyId);
            gotoResidentialAddressPage();
        }
    }

    useEffect(() => {
        dispatch(getCareInfoById(clientId));
        // dispatch(getClientProfilePreferredCaregiver());
        dispatch(HttpsAction({
            apiSection: 'client',
            apiName: 'referrals',
            queryParameter: `/${clientId}`,
            reducerName: 'CLIENT',
            reduxKeyName: 'referrals',
            actionType: 'GET_REFERRALS'
        }));
    }, []);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-12 d-flex flex-row justify-content-between align-items-center">
                        <div className="mb-4 input-field-main">
                            <Field
                              readOnly={true}
                              className={`form-control mk-wi fw-bolder border border-1 border-${borderColor} text-${statusColor}`}
                              name="status"
                              label="Status"
                              style={{minWidth: statusWidth, maxWidth: statusWidth, textAlign: 'center'}}
                              component={CustomInputField}
                            />
                        </div>
                        <div className="mb-4 input-field-main" style={{width: '150px'}}>
                            <Field
                              readOnly={true}
                              className="form-control mk-wi"
                              name="dischargeDate"
                              label="Discharge Date"
                              type="date"
                              component={CustomInputField}
                            />
                        </div>
                        <div className="mb-4 input-field-main" style={{width: '150px'}}>
                            <Field
                              readOnly={true}
                              className="form-control mk-wi"
                              name="terminationDateText"
                              label="Effective End Date"
                              component={CustomInputField}
                            />
                        </div>
                        <div className="mb-4 input-field-main">
                            <PrimaryButton
                              type="button"
                              className="btn-view-repo gap-2 px-3 py-2 w-100"
                              text={buttonText}
                              style={{minWidth: statusButtonWidth, maxWidth: statusButtonWidth}}
                              onClick={handleEffectiveEndDateModal}
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="mb-4 input-field-main">
                            <Field
                              className="form-control mk-wi"
                              name="leadCreatedDate"
                              label="Lead Created Date"
                              type="date"
                              component={CustomInputField}
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="mb-4 input-field-main">
                            <Field
                              className="form-control mk-wi"
                              name="initialAssessmentDate"
                              label="Initial Assessment Date"
                              type="date"
                              component={CustomInputField}
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="mb-4 input-field-main">
                            <Field
                                className="form-control mk-wi"
                                name="conversionDate"
                                label="Conversion Date"
                                type="date"
                                component={CustomInputField}
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="mb-4 input-field-main">
                            <Field
                                className="form-control mk-wi"
                                name="startDate"
                                label="Start Date"
                                type="date"
                                component={CustomInputField}
                                // requiredMark={true}
                                // validate={required}
                            />
                        </div>
                    </div>
                    {/*<div className="col-4">
                        <div className="mb-4 input-field-main">
                            <Field
                                readOnly={true}
                                className="form-control mk-wi"
                                name="dischargeDate"
                                label="Discharge Date"
                                type="date"
                                component={CustomInputField}
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="mb-4 input-field-main">
                            <Field
                                readOnly={true}
                                className="form-control mk-wi"
                                name="terminationDateText"
                                label="Effective End Date"
                                type="text"
                                component={CustomInputField}
                            />
                        </div>
                    </div>*/}
                    {/*<div className="col-4">*/}
                    {/*    <div className="mb-4 input-field-main">*/}
                    {/*        <Field*/}
                    {/*            className="form-control mk-wi"*/}
                    {/*            label="Administrator"*/}
                    {/*            name="administratorId"*/}
                    {/*            component={CustomDropDownField}*/}
                    {/*            requiredMark={true}*/}
                    {/*            validate={required}*/}
                    {/*        >*/}
                    {/*            <option value="">Please select</option>*/}
                    {/*            {(adminsArray || [])?.map((res, index) =>*/}
                    {/*                <option key={index} value={res.id}>*/}
                    {/*                    {res?.name}*/}
                    {/*                </option>*/}
                    {/*            )}*/}
                    {/*        </Field>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="col-4">
                        <div className="mb-4 input-field-main">
                            <Field
                                className="form-control mk-wi"
                                label="Region"
                                name="regionId"
                                component={CustomDropDownField}
                                // requiredMark={true}
                                // validate={required}
                            >
                                <option value="">Please select</option>
                                {(regionsArray || [])?.map((res, index) =>
                                    <option key={index} value={res.id}>
                                        {res?.name}
                                    </option>
                                )}
                            </Field>
                        </div>
                    </div>
                    <div className="col-4 position-relative">
                        <div className="mb-4 input-field-main">
                            <Field
                              className="form-control mk-wi"
                              label="Referred by"
                              name="referralPersonalInfoId"
                              component={CustomDropDownField}
                            >
                                <option value="">Please select</option>
                                {(personsList || [])?.map((res, index) =>
                                  <option key={index} value={res.id}>
                                      {`${res?.name} ( ${res?.personCategory})`}
                                  </option>
                                )}
                            </Field>
                        </div>
                        <div className="position-absolute" style={{top: '-5px', right: '12px'}}>
                            <button
                              type="button"
                              className="view_report d-flex btn-view-repo align-items-center gap-2 px-3 py-1"
                              onClick={handleCreateNewModal}
                            >
                                <i className="bi bi-plus-lg"></i>
                                Create
                            </button>
                        </div>
                    </div>
                    {/*<div className="col-2">
                        <div className="mb-4 input-field-main">
                            <button
                              type="button"
                              className={`btn btn-primary w-100 rounded-0 border-btn-info ${classes.createNewButton}`}
                              onClick={handleCreateNewModal}>
                                Create New
                            </button>
                        </div>
                    </div>*/}
                    <div className="col-4">
                    <div className="mb-4 input-field-main">
                            <Field
                                className="form-control mk-wi"
                                name="medicaidNumber"
                                label="Medicaid ID"
                                component={CustomInputField}
                                // requiredMark={true}
                                // validate={required}
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="mb-4 input-field-main">
                            <Field
                                className="form-control"
                                label="Preferred Nurse"
                                name="preferredNurseId"
                                component={CustomDropDownField}
                                // requiredMark={true}
                                // validate={required}
                            >
                                <option value="">Please select</option>
                                {(nursesArray || [])?.map((res, index) =>
                                    <option key={index} value={res.id}>
                                        {res.nurseFirstLastName}
                                    </option>
                                )}
                            </Field>
                        </div>
                    </div>
                    <div className="col-4">
                        {/*<div className="mb-4 input-field-main">*/}
                        {/*    <Field*/}
                        {/*        className="form-control mk-wi"*/}
                        {/*        name="invoicingId"*/}
                        {/*        label="Invoicing ID"*/}
                        {/*        type="text"*/}
                        {/*        component={CustomInputField}*/}
                        {/*    />*/}
                        {/*</div>*/}
                    </div>
                    <div
                        className="d-flex justify-content-center"
                        style={{marginTop: "40px"}}
                    >
                        <PrimaryButton
                            type="submit"
                            className="btn-view-repo gap-2 px-5 py-2"
                            text={"Save"}
                            onClick={() => undefined}
                        />
                    </div>
                </div>
            </form>
            <Modal isOpen={effectiveEndDateModal} modalTitle={buttonText} onClose={handleEffectiveEndDateModal} modalBodyClass={'pt-1'}>
                <PatientActiveDischargeInactivateModal clientId={clientId} handleChange={handleChange} onClose={handleEffectiveEndDateModal} agencyId={agencyId} />
            </Modal>
            <Modal isOpen={createNewModal} modalTitle={'Create Referral'} onClose={handleCreateNewModal}>
                <CreateReferral clientId={clientId} onClose={handleCreateNewModal} />
            </Modal>
        </>
    )
};

function getStatusUiProps(statusId) {
    let buttonText = 'Activate';
    let statusColor = 'green';
    let borderColor = 'success';
    if (!(statusId == null || statusId === 0)) {
        if (statusId === 1) { // active
            // buttonText = 'Set Effective End Date';
            buttonText = 'Discharge / Terminate';
            statusColor = 'success';
            borderColor = 'success';
        }
        if (statusId === 2) { // inactive
            buttonText = 'Activate / Discharge';
            statusColor = 'danger';
            borderColor = 'danger';
        }
        if (statusId === 3) { // discharged
            buttonText = 'Activate / Terminate';
            statusColor = 'primary';
            borderColor = 'primary';
        }
    }
    return {buttonText, statusColor, borderColor};
}

const mapStateToProps = state => {
    const { auth, client: { careInfoById: careData = null, referrals: referralsData = [] }} = state;

// console.log('test- careData - status', careData?.statusId, careData?.statusText)
    let {buttonText, statusColor, borderColor} = getStatusUiProps(careData?.statusId);
    return {
        isAdmin: auth?.authData?.isAdmin || false,
        agencyId: auth?.authData?.agencyId || auth.agencyId,
        // buttonText: (careData?.statusId || 1) === 2 ? 'Activate' : 'Set Effective End Date',
        // statusColor: (careData?.statusId || 1) === 2 ? 'red' : 'green',
        // borderColor: (careData?.statusId || 1) === 2 ? 'danger' : 'success',
        buttonText,
        statusColor,
        borderColor,
        initialValues: {
            statusId: careData?.statusId?.toString(),
            status: careData?.statusText?.toUpperCase(),
            leadCreatedDate: dateHelperFunctions.updateDateFormat(careData?.leadCreatedDate),
            initialAssessmentDate: dateHelperFunctions.updateDateFormat(careData?.initialAssessmentDate),
            conversionDate: dateHelperFunctions.updateDateFormat(careData?.conversionDate),
            startDate: dateHelperFunctions.updateDateFormat(careData?.startDate),
            dischargeDate: dateHelperFunctions.updateDateFormat(careData?.dischargeDate),
            effectiveEndDate: dateHelperFunctions.updateDateFormat(careData?.effectiveEndDate),
            terminationDateText: stringHelperFunctions.sanitize(careData?.terminationDateFormatUs, false, undefined, 'yyyy-mm-dd'),
            administratorId: careData?.administratorId?.toString(),
            regionId: careData?.regionId?.toString(),
            referralPersonalInfoId: careData?.referralPersonalInfoId?.toString(),
            medicaidNumber: careData?.medicaidNumber,
            preferredNurseId: careData?.preferredNurseId?.toString(),
            invoicingId: careData?.invoicingId,
        }
    }
}

export default connect(mapStateToProps)(
    reduxForm({form: "careInformation", enableReinitialize: true})(CareInformation)
);
