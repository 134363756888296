import React, {useEffect, useState} from "react";
import {change, Field, reduxForm, reset} from "redux-form";
import CustomDropDownField from "../../../../../../sharedComponents/reduxfields/customDropDown";
import CustomInputField from "../../../../../../sharedComponents/reduxfields/customInputField";
import {connect, useDispatch, useSelector} from "react-redux";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {
    getCareInfoById,
    getCityList,
    getResidentialInfoAddress,
    postClientProfileResidentialInfoAddress
} from "../../../../../../services/client";
import PrimaryButton from "../../../../../../sharedComponents/buttons/PrimaryButton";
import {HttpsAction} from "../../../../../../commonApiFunction/httpsAction";
import Autocomplete from "../../../../../../sharedComponents/googleAddressAuto";
import {getStateList} from "../../../../../../services/commonServices";
import {required} from "../../../../../../helpers/validation";
import CustomRadioGroup from "../../../../../../sharedComponents/reduxfields/customRadioGroup";
import {useQueryClient} from "@tanstack/react-query";
import stringHelperFunctions from "../../../../../../helpers/string.helpers";
import CustomLabel from "../../../../../../sharedComponents/fields/CustomLabel";
import numberHelperFunctions from "../../../../../../helpers/number.helpers";
import CustomDropDownNewField from "../../../../../../sharedComponents/reduxfields/customDropDownNew";
import CustomAddressAutocomplete from "../../../../../../sharedComponents/reduxfields/CustomAddressAutocomplete";
import * as clientActions from "../../../clientActionType";
import {invalidateClientsList} from "../../../../../../queries/agency/clientsList";
import booleanHelperFunctions from "../../../../../../helpers/boolean.helpers";
import useCustomNav from "../../../../../../utils/hooks/useCustomNav";

const mobileAppGeofenceEnabledOptions = [
    {label: 'Yes', id: "yes1", value: 'true'},
    {label: 'No', id: "no1", value: 'false'}
];

const ResidentialAddress = ({agencyId, initialValues, handleSubmit}) => {
    const addressIsOptional = true;

    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const { id: clientId } = useParams();
    const { clientId } = useOutletContext();

    const { goTo: gotoBillingAddressPage } = useCustomNav('/agency/clients/edit/profile/billing-address');

    const residentialInfoAddress = useSelector(state => state.client?.residentialInfoAddress);

    const statesArray = queryClient.getQueryData(['list-provinces-states', 233]) || [];

    const [address, setAddress] = useState('');
    const [handleAutoCompleteTextChanged, setHandleAutoCompleteTextChanged] = useState(true);
    const [selectedPlace, setSelectedPlace] = useState('');
    const [cityList, setCityList] = useState([]);
    const [cityLoaded, setCityLoaded] = useState(false);
    const [patientGeoCoordinatesValid, setPatientGeoCoordinatesValid] = useState(addressIsOptional);

    const handleAutocompleteTextChanged = text => {
        if (handleAutocompleteTextChanged) {
            setAddress(text);
        }
    };

    const handlePlaceSelected = async place => {
        if (place != null) {
            setCityLoaded(true);
            setSelectedPlace(place);
            if (place.provinceOrStateName != null && place.provinceOrStateName.length > 0) {
                setCityList([]);
                const selectedStateItem = statesArray.find(s => s.name === place.provinceOrStateName);
                const selectedStateIdStr = String(selectedStateItem.id);
                // console.log('places-data - selectedStateItem', selectedStateItem);
                dispatch(change("residentialAddressForm", 'provinceOrStateId', selectedStateIdStr));
                const response = await dispatch(getCityList(selectedStateIdStr));
                setCityList(response?.data);
            }
        }
    };

    // const getStateId = async (e) => {
    //     // let index = e.target.selectedIndex;
    //     // let optionElement = e.target.childNodes[index];
    //     // let optionattr = optionElement.getAttribute("value");
    //     // // dispatch(getCityList(optionattr));
    //     // const response = await dispatch(getCityList(optionattr));
    //     // setCityList(response?.data);
    // };

    const onSubmit = async (data) => {
        const {
            // name,
            // addressLine1,
            provinceOrStateId,
            cityId,
            postalOrZipCode,
            // latitude,
            // longitude,
            mobileAppGeofenceOverrideRadius,
            mobileAppGeofenceEnabled
        } = data;

        const res = await dispatch(postClientProfileResidentialInfoAddress({
            // name,
            addressLine1: stringHelperFunctions.nullIfEmpty(selectedPlace?.address),
            provinceOrStateId: numberHelperFunctions.nullIfEmpty(provinceOrStateId),
            cityId: numberHelperFunctions.nullIfEmpty(cityId),
            postalOrZipCode: stringHelperFunctions.nullIfEmpty(postalOrZipCode),
            latitude: numberHelperFunctions.nullIfEmptyFloat(selectedPlace?.latLng?.lat),
            longitude: numberHelperFunctions.nullIfEmptyFloat(selectedPlace?.latLng?.lng),
            mobileAppGeofenceEnabled: booleanHelperFunctions.nullIfEmpty(mobileAppGeofenceEnabled),
            mobileAppGeofenceOverrideRadius: numberHelperFunctions.nullIfEmpty(mobileAppGeofenceOverrideRadius),
        }, clientId));
        if (res?.success) {
            invalidateClientsList(queryClient, agencyId);
            // navigate(`/agency/clients/${clientId}/${id}/billingAddress`);
            gotoBillingAddressPage();
        }
    };

    // useEffect(() => {
    //     if (!cityLoaded && initialValues?.cityId != null && initialValues?.cityId.length > 0 && cityList != null && cityList.length > 0) {
    //         setCityLoaded(true);
    //         dispatch(change("residentialAddressForm", 'cityId', initialValues?.cityId));
    //     }
    // }, [initialValues?.cityId, cityList]);

    useEffect(() => {
        if (selectedPlace != null && cityList != null && cityList.length > 0) {
            if (selectedPlace.cityName != null && selectedPlace.cityName.length > 0) {
                console.log('selected-place', selectedPlace)
                const selectedStateItem = cityList.find(c => c.name === selectedPlace.cityName)
                // console.log('places-data - selectedCityItem', selectedStateItem);
                if (selectedStateItem != null)
                    dispatch(change("residentialAddressForm", 'cityId', String(selectedStateItem.id)));
            }
        }
    }, [cityList]);

    useEffect(() => {
        if (residentialInfoAddress && clientId) {
            setSelectedPlace({
                address: residentialInfoAddress?.patientAddressLine1 || '',
                latLng: {lat: residentialInfoAddress?.patientLatitude?.toString(), lng: residentialInfoAddress?.patientLongitude?.toString()}
            });

            // bind city on load
            setCityList([]);
            if (residentialInfoAddress.patientProvinceOrStateId != null && residentialInfoAddress.patientProvinceOrStateId !== "0") {
                // IIFE
                (async () => {
                    const response = await dispatch(getCityList(residentialInfoAddress.patientProvinceOrStateId));
                    setCityList(response?.data);
                    dispatch(change("residentialAddressForm", 'cityId', residentialInfoAddress.patientCityId));
                })();
            }
        }
    }, [residentialInfoAddress && clientId]);

    useEffect(() => {
      dispatch(getResidentialInfoAddress(clientId));
      return () => {
          dispatch({
              type: clientActions.GET_RESIDENTIAL_INFO_ADDRESSES,
              payload: undefined,
          });
          dispatch(reset('residentialAddressForm'));
      };
    }, []);

    // useEffect(() => {
    //     if (!cityLoaded) {
    //         (async () => {
    //             if (initialValues?.addressLine1 != null && initialValues?.addressLine1.length > 0) {
    //                 setSelectedPlace({address: initialValues.addressLine1});
    //                 setPatientGeoCoordinatesValid(addressIsOptional || initialValues.addressLine1.length > 0);
    //             }
    //             if (initialValues?.provinceOrStateId != null && initialValues?.provinceOrStateId.length > 0) {
    //                 setCityList([]);
    //                 const provinceOrStateId = initialValues?.provinceOrStateId?.toString();
    //                 const cityId = initialValues?.cityId?.toString();
    //                 if (provinceOrStateId != null && provinceOrStateId.length > 0 && provinceOrStateId !== '0') {
    //                     // bind city on load
    //                     if (cityId != null && cityId.length > 0) {
    //                         const response = await dispatch(getCityList(provinceOrStateId));
    //                         setCityList(response?.data);
    //                         dispatch(change("residentialAddressForm", 'cityId', initialValues?.cityId));
    //                         setCityLoaded(true);
    //                     }
    //                 }
    //             }
    //         })();
    //     }
    // }, [initialValues, cityLoaded]);

    // useEffect(() => {
    //     (async () => {
    //         if (initialValues?.addressLine1 != null && initialValues?.addressLine1.length > 0) {
    //             setSelectedPlace({address: initialValues.addressLine1});
    //         }
    //     })()
    // }, [residentialInfoAddress]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    {/*<div className="col-4">*/}
                    {/*    <div className="mb-4 input-field-main">*/}
                    {/*        <Field*/}
                    {/*            className="form-control mk-wi"*/}
                    {/*            name="name"*/}
                    {/*            label="Name"*/}
                    {/*            type="text"*/}
                    {/*            component={CustomInputField}*/}
                    {/*            placeholder={'Peace estate'}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="col-8">
                        <div className="mb-4 input-field-main">
                            {/*<Field*/}
                            {/*    className="form-control mk-wi"*/}
                            {/*    name="addressLine1"*/}
                            {/*    label="Address"*/}
                            {/*    type="text"*/}
                            {/*    component={CustomInputField}*/}
                            {/*    placeholder={'Lorem ipsum dolor sit amet consectetur. Malesuada molestie turpis nullam convallis a id orci. Nam maecenas tristique .'}*/}
                            {/*/>*/}
                            {/*<div className="d-flex gap-3 mt-1">
                                <label>Address <span className="mandatory-field">*</span></label>
                                <CustomLabel labelText="Address" requiredMark={false} />
                                {
                                    selectedPlace &&
                                    <label className="selectedLocation">
                                        &#x28;Selected Location &#x3d; {selectedPlace?.address}&#x29;
                                    </label>
                                }
                            </div>
                            <Autocomplete onPlaceSelected={handlePlaceSelected} initAddress={selectedPlace?.address} disabled={statesArray.length === 0} />*/}
                            {/*<Autocomplete onTextChanged={handleAutocompleteTextChanged} onPlaceSelected={handlePlaceSelected} initAddress={selectedPlace?.address}/>*/}
                            <CustomAddressAutocomplete requiredMark={!addressIsOptional} label="Address" selectedPlaceAddress={selectedPlace?.address} handlePlaceSelected={handlePlaceSelected} disabled={statesArray.length === 0} handleClear={() => {
                                setSelectedPlace({address: ''});
                            }} error={!patientGeoCoordinatesValid} errorMessage={'This field is required'} />
                        </div>
                    </div>
                    <div className="col-4">&nbsp;</div>
                    <div className="col-4">
                        <div className="mb-4 input-field-main">
                            <Field
                                className="form-control mk-wi"
                                label="State"
                                name="provinceOrStateId"
                                component={CustomDropDownNewField}
                                // onChange={(e) => getStateId(e)}
                                // requiredMark={true}
                                // validate={required}
                                readOnly={true}
                                handleClear={() => {
                                    dispatch(change("residentialAddressForm", 'cityId', ''));
                                    setCityList([]);
                                    return dispatch(change("residentialAddressForm", 'provinceOrStateId', ''));
                                }}
                            >
                                <option value="">Select State</option>
                                {(statesArray || []).map((res, index) =>
                                    <option key={index} value={res.id}>{res.name}</option>
                                )}
                            </Field>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="mb-4 input-field-main">
                            <Field
                                className="form-control mk-wi"
                                label="City"
                                name="cityId"
                                component={CustomDropDownNewField}
                                // requiredMark={true}
                                // validate={required}
                            >
                                <option value="">Please Select</option>
                                {(cityList || []).map((res, index) =>
                                    <option key={index} value={res.id}>{res.name}</option>
                                )}
                            </Field>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="mb-4 input-field-main">
                            <Field
                                className="form-control mk-wi"
                                name="postalOrZipCode"
                                label="Zip Code"
                                type="text"
                                component={CustomInputField}
                                placeholder={'Enter code'}
                                // requiredMark={true}
                                // validate={required}
                            />
                        </div>
                    </div>
                    {/*<div className="col-5">*/}
                    {/*    <div className="mb-4 input-field-main">*/}
                    {/*        <Field*/}
                    {/*            className="form-control mk-wi"*/}
                    {/*            name="longitude"*/}
                    {/*            label="Longitude"*/}
                    {/*            type="number"*/}
                    {/*            component={CustomInputField}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className="col-5">*/}
                    {/*    <div className="mb-4 input-field-main">*/}
                    {/*        <Field*/}
                    {/*            className="form-control mk-wi"*/}
                    {/*            name="latitude"*/}
                    {/*            label="Latitude"*/}
                    {/*            type="number"*/}
                    {/*            component={CustomInputField}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="col-4">
                        <div className="mb-4 input-field-main">
                            {/*<Field*/}
                            {/*    className="form-control mk-wi"*/}
                            {/*    label="Mobile App Geo-Fence"*/}
                            {/*    name="mobileAppGeofenceEnabled"*/}
                            {/*    component={CustomDropDownField}*/}
                            {/*>*/}
                            {/*    <option value="">Please Select</option>*/}
                            {/*    {(mobileAppGeofenceOptions || []).map((res, index) =>*/}
                            {/*        <option key={index} value={res.value}>{res.label}</option>*/}
                            {/*    )}*/}
                            {/*</Field>*/}
                            {/*<input
                             className="form-check-input"
                             type="checkbox"
                             id="mobileAppGeofenceEnabled"
                             name="mobileAppGeofenceEnabled"
                             checked={false}
                             value="true"
                             />*/}
                            {/*<Field
                             className="form-control mk-wi"
                             // onChange={({target}) => handleChangeCheckBox(target)}
                             name="mobileAppGeofenceEnabled"
                             value="true"
                             label="Mobile App Geo-Fence"
                             type="checkbox"
                             component={CustomInputField}
                             // placeholder={'Enter code'}
                             />*/}
                            <label>
                                Mobile App Geo-Fence Enabled<i className="text-lightgrey ms-1">&nbsp;(Optional)</i>
                            </label>
                            <Field
                              name="mobileAppGeofenceEnabled"
                              component={CustomRadioGroup}
                              options={mobileAppGeofenceEnabledOptions}
                              onChange={(e) => undefined}
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="mb-4 input-field-main">
                            <Field
                              className="form-control mk-wi"
                              name="mobileAppGeofenceOverrideRadius"
                              label="Override geofence radius: &#x28;meters&#x29;"
                              type="number"
                              component={CustomInputField}
                                placeholder={'0'}
                            />
                        </div>
                    </div>
                    <div
                        className="d-flex justify-content-center"
                        style={{marginTop: "40px"}}>
                        <PrimaryButton
                            type="submit"
                            className="btn-view-repo gap-2 px-5 py-2"
                            text={"Save"}
                            onClick={() => undefined}
                        />
                    </div>
                </div>
            </form>
        </>
    )
};

const mapStateToProps = state => {
    const { auth, client: { residentialInfoAddress = null } } = state;

    const initialValues  = {
        // addressLine1: residentialInfoAddress?.patientAddressLine1 === 'Nil' ? '' : residentialInfoAddress?.patientAddressLine1,
        addressLine1: stringHelperFunctions.sanitize(residentialInfoAddress?.patientAddressLine1, true, 'Nil'),
        provinceOrStateId: numberHelperFunctions.convertToIntegerString(residentialInfoAddress?.patientProvinceOrStateId),
        cityId: numberHelperFunctions.convertToIntegerString(residentialInfoAddress?.patientCityId),
        postalOrZipCode: stringHelperFunctions.sanitize(residentialInfoAddress?.patientPostalOrZipCode),
        latitude: numberHelperFunctions.convertToFloatString(residentialInfoAddress?.patientLatitude),
        longitude: numberHelperFunctions.convertToFloatString(residentialInfoAddress?.patientLongitude),
        mobileAppGeofenceEnabled: booleanHelperFunctions.convertToString(residentialInfoAddress?.mobileAppGeofenceEnabled),
        mobileAppGeofenceOverrideRadius: numberHelperFunctions.convertToIntegerString(residentialInfoAddress?.mobileAppGeofenceOverrideRadius),
    };
// console.log('test- initialValues', initialValues, residentialInfoAddress);
    return {
        isAdmin: auth?.authData?.isAdmin || false,
        agencyId: auth?.authData?.agencyId || auth.agencyId,
        initialValues,
    }
};

export default connect(mapStateToProps)(
    reduxForm({form: "residentialAddressForm", enableReinitialize: true})(ResidentialAddress)
);




