import React, {useState} from 'react';
import {Outlet, useLocation} from "react-router-dom";
import stringHelperFunctions from "../helpers/string.helpers";
import AdminHeader from "../Admin/components/AdminHeader";

const AdminLayout = () => {
  const location = useLocation();

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  return (
    <div className={`max-area ${stringHelperFunctions.urlToDashCase(location.pathname)}`}>
      <div
        className={`max-area app-container app-theme-dark body-tabs-shadow fixed-sidebar fixed-header ${
          location.pathname !== "/" &&
          location.pathname !== "/forgot-password" &&
          location.pathname !== "/reset-password" &&
          location.pathname !== "/login/admin"
            ? isSidebarOpen
              ? ""
              : "closed-sidebar "
            : ""
        }`}
      >
        <AdminHeader
          setIsSidebarOpen={setIsSidebarOpen}
          isSidebarOpen={isSidebarOpen}
        />
        <div
          className={`max-area${
            location.pathname !== "/" &&
            location.pathname !== "/forgot-password" &&
            location.pathname !== "/reset-password" &&
            location.pathname !== "/login/admin"
              ? " app-main"
              : ""
          }`}
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
