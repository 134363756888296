import React, { useEffect, useState } from "react";
import { Field } from "redux-form";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

const searchOptions = {
  // types: ["geocode"],
  // types: ["locality"],
  // types: ["cities"],
  types: ["address"],
  // country: ['us'],
  componentRestrictions: { country: "US" }, // Restrict results to the United States
};

const Autocomplete = ({ onPlaceSelected, onTextChanged, initAddress, disabled = false }) => {
  const [address, setAddress] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [loadedOnce, setLoadedOnce] = useState(false);
  const [initialAddress, setInitialAddress] = useState(initAddress);

  const handleChange = (newAddress) => {
    setAddress(newAddress);
    if (onTextChanged != null) onTextChanged(newAddress);
  };

  const handleSelect = async (newAddress) => {
    try {
      const results = await geocodeByAddress(newAddress);
      let cityName = '';
      let provinceOrStateName = '';
      let countryName = '';
      results[0].address_components.forEach((item) => {
        if (item.types[0] === 'locality' || item.types[0] === 'administrative_area_level_3') {
          cityName = item.long_name === 'New York' ? 'New York City' : item.long_name;
        }
        if (item.types[0] === 'administrative_area_level_1') {
          provinceOrStateName = item.long_name;
        }
        if (item.types[0] === 'country') {
          countryName = item.long_name;
        }
      });
      // console.log('cityName:', cityName, '- provinceOrStateName:', provinceOrStateName, '- countryName:', countryName);
      const latLng = await getLatLng(results[0]);
      onPlaceSelected({ address: newAddress, cityName, provinceOrStateName, countryName, latLng });
      setAddress(newAddress);
    } catch (error) {
      console.error("Error selecting place:", error);
    }
  };

  useEffect(() => {
    // console.log('isLoading', isLoading)
    // console.log('loadedOnce', loadedOnce)
    // console.log('initAddress', initAddress, initialAddress)
    if (loadedOnce) setAddress(initialAddress);
  },[loadedOnce]);

  // useEffect(() => {
  //   if (initialAddress != null && initialAddress.length > 0) setAddress(initialAddress);
  // },[initialAddress]);

  useEffect(() => {
    // setAddress(initAddress);
    // if (initAddress != null && initAddress.length > 0) setInitialAddress(initAddress);
    // if (initAddress != null && initAddress.length > 0) setAddress(initAddress);
    setAddress(initAddress || '');
    // console.log('init-address', initAddress, initialAddress)
  },[initAddress]);

  // useEffect(() => {
  //   if (isLoading) {
  //     setLoading(false);
  //     setLoadedOnce(true);
  //   }
  // }, [isLoading]);
  //
  // useEffect(() => {
  //   setLoading(true);
  // }, []);

  console.log('init-AC', initAddress, '-------' , initialAddress, '-------', address)

  return (
    <div>
      <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="pos-relative">
            <input
              {...getInputProps({
                placeholder: 'Search Places...',
                className:'form-control mk-wi',
                disabled,
              })}
              
            />
            <div className={suggestions.length > 0 ? 'addAutocomplete' : ''}>
              {loading ? <div>Loading...</div> : null}
              {suggestions.map((suggestion, index) => {
                const style = {
                  backgroundColor: suggestion.active ? '#ddd' : '#fff',
                  padding:'8px 15px'
                };
                return (
                  <div key={index}
                    {...getSuggestionItemProps(suggestion, {
                      style,
                    })}
                  >
                    {suggestion.description}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
};

export default Autocomplete;
