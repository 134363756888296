import React, {useRef} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, IconButton} from '@mui/material';

const CustomDialog = ({
                          open = false,
                          onClose = () => undefined,
                          title = "",
                          children,
                          actions,
                          maxWidth = 'lg',
                          dialogClassName = "",
                          dialogTitleClassName = "",
                          dialogContentClassName = "",
                          dialogActionsClassName = "",
                          dialogActionsSx = {display: 'flex', justifyContent: 'center'},
                          dialogContentRef = "",
                          customWidth = false
                      }) => {
    const ref = useRef(null);
    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth={!customWidth}
            maxWidth={maxWidth}
            className={dialogClassName}
        >
            {/* Dialog Title */}
            <DialogTitle className={dialogTitleClassName}>
                {title}
                {/* Close button */}
                <IconButton aria-label="close" onClick={() => onClose('close-modal')}
                            sx={{position: 'absolute', right: 8, top: 8}}>
                    {/* SVG Close Icon */}
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1880_67785)">
                            <circle cx="20" cy="20" r="20" fill="#F2F2F2"/>
                            <path
                                d="M13.9219 26.0796L20.0007 20.0007M26.0796 13.9219L19.9996 20.0007M19.9996 20.0007L13.9219 13.9219M20.0007 20.0007L26.0796 26.0796"
                                stroke="#4F4F4F" stroke-width="1.73913" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_1880_67785">
                                <rect width="40" height="40" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                </IconButton>
            </DialogTitle>

            {/* Dialog Content */}
            <DialogContent dividers
                           sx={{width: customWidth || 'w-100', maxWidth: customWidth || 'auto'}}
                           className={dialogContentClassName}
                           ref={dialogContentRef ? dialogContentRef : ref}>
                {children}
            </DialogContent>

            {/* Dialog Actions */}
            {actions && (
                <DialogActions className={dialogActionsClassName} sx={dialogActionsSx}>
                    {actions}
                </DialogActions>
            )}
        </Dialog>
    );
};

export default CustomDialog;
