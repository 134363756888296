import React, {lazy, useEffect, useState} from "react";
import {connect, useSelector} from "react-redux";
import {useDispatch} from 'react-redux';
import CustomTable from "../../../sharedComponents/customTable/index";
import {getCountedVisits} from '../../../services/serviceRequest'
import {HttpsAction} from "../../../commonApiFunction/httpsAction";
import {Dialog} from "@mui/material";
import Swal from "sweetalert2";
import Pagination from "../../../sharedComponents/pagination";
import {pageLimit} from "../../../appConstants";
import rowHelperFunctions from "../../../helpers/row.helpers";
import {useLocation, useNavigate, useOutletContext} from "react-router-dom";
// import TaskDetails from "../TaskDetails";
import dateHelperFunctions from "../../../helpers/date.helpers";
import TableLayout from "../shared/TableLayout";
import NewTableLayout from "../shared/NewTableLayout";

const TaskDetails = lazy(() => import("../TaskDetails"));

const ActiveRequest = ({/*searchText, */activeServiceRequestsList}) => {
    const backButtonTextSuffix = 'Active Visits';
    const backLink = '/agency/servicerequest/activerequest';

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { searchText, setPaginationTab } = useOutletContext();

    // const isServiceRequestEdit = useSelector(state => state.serviceRequest?.isServiceRequestEdit || false);

    // const [dialog, setDialog] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    // const [showTaskDetails, setShowTaskDetails] = useState(false);
    const [taskIdToEdit, setTaskIdToEdit] = useState(0);

    // const activeAssessmentList = useSelector(state => state.serviceRequest?.activeAssessment?.data?.rows)

    const taskIdToSelect = location?.state != null && location?.state?.taskId != null ? location.state.taskId : 0;

    const transformedArray = activeServiceRequestsList?.rows?.map(item => {
        // let {relatedText, relatedTextColor, relatedLink, relatedId, hasValidChartingId} = rowHelperFunctions.getRelatedInfo(item);
        let { hasValidChartingId, relatedInfoList} = rowHelperFunctions.getRelatedInfo(item);

        const highlightRow = taskIdToSelect === item.taskId;

        return {
            'id': {taskId: item.taskId, nurseId: item.assignedNurse, serviceRequestId: item.serviceRequestId},
            'Recipients': rowHelperFunctions.getClientRowItem(navigate, item.patientId, item.patientFullName, false, backButtonTextSuffix, backLink, hasValidChartingId ? `/agency/clients/${item.patientId}/charting/0` : null),
            'age': item.patientAge,
            // 'serviceRequired': item.serviceRequestType,
            'serviceRequired': <div className="position-relative1 p-0 m-0 w-100 d-flex align-items-center">
                <span className="m-auto">{item.serviceRequestType}</span>
                {/*{relatedText && <div className="position-absolute top-22 start-50 translate-middle cursor-pointer" onClick={() => {
                    navigate(relatedLink, {
                        state: {
                            taskId: relatedId,
                        },
                    });
                }}><span className={`badge rounded-pill text-wrap lh-1 text-bg-${relatedTextColor}`}>{relatedText}</span></div>}*/}
                {(relatedInfoList || []).length > 0 && <div className="position-absolute top-17 start-50 translate-middle cursor-pointer w-100 d-flex justify-content-center gap-1">{relatedInfoList.map(({relatedTextColor, relatedText, relatedLink, relatedState}, index) => <span key={index} onClick={() => {
                    if (relatedLink != null) navigate(relatedLink, { state: relatedState });
                }} className={`badge rounded-pill text-wrap lh-1 text-bg-${relatedTextColor}`}>{relatedText}</span>)}</div>}
            </div>,
            // 'duration': <>
            //     {/*<div className="d-block">{`${new Date(item.startDate).toLocaleDateString('en-GB')} - ${new Date(item.endDate).toLocaleDateString('en-GB')}`}</div>*/}
            //     {/*<div className="d-block">{`${item.clockIn} - ${item.clockOut}`}</div>*/}
            //     <div className="d-block" style={{width: '85px'}}>{item.taskDate}</div>
            //     <div className="d-block">{`${item.scheduledStartTime} - ${item.scheduledEndTime}`}</div>
            // </>,
            // 'duration': <><span
            //   className="d-block">{item.startDateEndDate}</span><span
            //   className="d-block">{item.expectedTimings}</span></>,
            // 'duration': <><div className="d-block" style={{width: '85px'}}>{item.taskDate}</div><div className="d-block">{`${item.scheduledStartTime} - ${item.scheduledEndTime}`}</div></>,
            // 'duration': <><span
            //   className="d-block">{item.taskDateFormatUs}</span><span
            //   className="d-block" style={{fontSize: '13px', minWidth: '125px'}}>{item.expectedTimings}</span></>,
            'duration': <div className="d-flex flex-column align-items-center gap-0" style={{minWidth: '125px'}}>
                <span className="d-block" style={{fontSize: '14px'}}>{item.taskDateFormatUs}</span>
                <span className="d-block" style={{fontSize: '12px'}}>{item.scheduledTimingsAmPm}</span>
            </div>,
            'hours': dateHelperFunctions.hoursAndMinutesToText(item.totalScheduledHours, item.totalScheduledMinutesLessHours),
            // 'frequencies': item.frequencies,
            'location': item.patientLocation || 'N/A',
            // 'nurseName': item?.assignedNurseFirstName || 'N/A',
            'nurseName': rowHelperFunctions.getSingleNurseRowItem(navigate, item?.assignedNurseId, item?.assignedNurseFullName, false, item?.assignedNurseIsFreelance, backButtonTextSuffix, backLink, hasValidChartingId ? `/agency/nurses/edit/${item?.assignedNurseId}/charting` : null),
            highlightRow,
            importantRow: item.isCurrentDayTask,
            'taskTable': (item.activities || []).map(item2 => {
                return {
                    'task': hasValidChartingId ? 'Complete the form' : item2.name,
                    // 'date': new Date(item2.date).toLocaleDateString('en-GB'),
                    'submittedAt': item2.isCompleted ? dateHelperFunctions.convertTo12HourFormat(item2?.completionTime) : '',
                    'status': <span
                        className={`badge rounded-pill ${(item2.isCompleted ? 'text-bg-success' : 'text-bg-warning')}`}>{item2.isCompleted ? 'Completed' : 'Pending'}</span>
                }
            }),
            'task': item.tasks,
        }
    });

    const serviceHeaderData = [
        {
            title: "Recipients",
            dataIndex: "Recipients",
            // align: 'left',
            width: '200px',
        },
        {
            title: "Age",
            dataIndex: "age",
            width: '47px',
        },
        {
            title: "Service Required",
            dataIndex: "serviceRequired",
            width: '100px',
        },
        {
            title: "Date/Timings",
            dataIndex: "duration",
            width: '130px',
        },
        {
            title: "Hours",
            dataIndex: "hours",
            width: '75px',
        },
        // {
        //     title: "Frequency",
        //     dataIndex: "frequencies",
        //     render: (text) => {
        //         return text.map(item => {
        //             const firstThreeLetters = item.frequency.substring(0, 3);
        //             return firstThreeLetters;
        //         }).join(', ');
        //     }
        // },
        {
            title: "Location",
            dataIndex: "location",
        },
        {
            title: "Nurse",
            dataIndex: "nurseName",
            width: '200px',
        },
        // {
        //     title: "",
        //     dataIndex: "Action",
        // }
    ];

    const innerTableHeader = ['Activity Name', 'Submitted at', 'Status']

    const actionButton = [
        {
            // actionName: 'Edit',
            actionName: 'View',
            buttonAction: (specificData) => handleDialog(specificData.taskId),
            actionClass: 'btn-primary px-1',
            iconClass: 'fa-user-plus',
        },
        // {
        //     actionName: 'Cancel',
        //     buttonAction: (specificData) => {
        //         Swal.fire({
        //             title: 'Are you sure?',
        //             text: "You won't be able to revert this!",
        //             icon: 'warning',
        //             showCancelButton: true,
        //             confirmButtonColor: '#3085d6',
        //             cancelButtonColor: '#d33',
        //             confirmButtonText: 'Yes, delete it!',
        //         }).then((result) => {
        //             if (result.isConfirmed)
        //                 dispatch(HttpsAction({
        //                     method: 'PATCH',
        //                     apiSection: 'serviceRequests',
        //                     apiName: 'taskDetails',
        //                     queryParameter: `/${specificData.taskId}`,
        //                     positiveCallBack: () => {
        //                         dispatch(getCountedVisits('active', currentPage));
        //                     }
        //                 }));
        //         });
        //     },
        //     actionClass: 'btn-danger px-1',
        //     iconClass: 'fa-user-plus',
        // },
    ]

    const handleDialog = (id) => {
        if (id != null && id > 0) {
            // dispatch(HttpsAction({
            //     apiSection: 'serviceRequests',
            //     apiName: 'taskDetails',
            //     queryParameter: `/${id}`,
            //     actionType: `GET_TASK_DETAILS`,
            //     reduxKeyName: 'taskDetails',
            //     reducerName: 'SERVICE_REQUEST',
            // }));
            setTaskIdToEdit(id);
        }
        // setDialog(() => id)
    };

    const closeViewDialog = () => {
        setTaskIdToEdit(0);
        dispatch(HttpsAction({
            actionType: `GET_TASK_DETAILS`,
            reduxKeyName: 'taskDetails',
            reducerName: 'SERVICE_REQUEST',
            removeData: true,
        }));
    };

    const loadMethod = (curPage, toSearch, locationState) => {
        const useDefault = curPage == null && toSearch == null && locationState == null;
        if (useDefault) dispatch(getCountedVisits('active', 1));
        else {
            if (locationState != null && 'serviceRequestId' in locationState) {
                if (toSearch != null && toSearch.length > 0)
                    dispatch(getCountedVisits('active', curPage, toSearch, locationState.serviceRequestId));
                else
                    dispatch(getCountedVisits('active', curPage, null, locationState.serviceRequestId));
            } else {
                if (toSearch != null && toSearch.length > 0)
                    dispatch(getCountedVisits('active', 1, toSearch));
                else
                    dispatch(getCountedVisits('active', curPage));
            }
        }
    };

    // useEffect(() => {
    //     if (location?.state != null && 'serviceRequestId' in location.state) {
    //         if (searchText != null && searchText.length > 0)
    //             dispatch(getCountedVisits('active', currentPage, searchText, location.state.serviceRequestId));
    //         else
    //             dispatch(getCountedVisits('active', currentPage, null, location.state.serviceRequestId));
    //     } else {
    //         if (searchText != null && searchText.length > 0)
    //             dispatch(getCountedVisits('active', 1, searchText));
    //         else
    //             dispatch(getCountedVisits('active', currentPage));
    //     }
    //
    // }, [currentPage, searchText, location?.state]);

    useEffect(() => {
        if (activeServiceRequestsList != null && activeServiceRequestsList?.rows?.length != null) {
            setPaginationTab(activeServiceRequestsList);
        }
    }, [activeServiceRequestsList]);

    // useEffect(() => {
    //     if (location?.state != null && 'serviceRequestId' in location.state) {
    //         dispatch(getCountedVisits('active', currentPage, null, location.state.serviceRequestId));
    //     } else
    //         dispatch(getCountedVisits('active', currentPage));
    // }, [currentPage, location?.state]);
    //
    // useEffect(() => {
    //     dispatch(getCountedVisits('active', 1, searchText));
    // }, [searchText]);
    //
    // useEffect(() => {
    //     if (taskIdToEdit > 0) {
    //         setShowTaskDetails(true);
    //     }
    // }, [taskIdToEdit]);
    //
    // useEffect(() => {
    //     if (!showTaskDetails) {
    //         setTaskIdToEdit(0);
    //         dispatch(HttpsAction({
    //             actionType: `GET_TASK_DETAILS`,
    //             reduxKeyName: 'taskDetails',
    //             reducerName: 'SERVICE_REQUEST',
    //             removeData: true,
    //         }));
    //     }
    // }, [showTaskDetails]);

    return (
      <>
          {/*<div className="container-fluid bg-light">
              <div className="bg-light-gray px-3 py-3">
                  <div className="row">
                      <div className="container-fluid">
                          <div className="tab-content" id="pills-tabContent">
                              <div className="bg-white mt-3">
                                  <CustomTable headers={serviceHeaderData} rows={transformedArray} innerTableHeader={innerTableHeader}
                                               actions={actionButton} hasFixedWidth={true} actionColWidth="60px" tableClass="visits-table" />

                              </div>
                          </div>
                          <Pagination
                            currentPage={currentPage}
                            totalRecords={activeServiceRequestsList?.total || 0}
                            limit={pageLimit}
                            onPageChange={(page) => {
                                setCurrentPage(page);
                            }}
                          ></Pagination>
                      </div>
                  </div>
              </div>
          </div>*/}
          <NewTableLayout listData={activeServiceRequestsList} loadMethod={loadMethod} loadDeps={[searchText, location?.state]}>
              <CustomTable headers={serviceHeaderData} rows={transformedArray} innerTableHeader={innerTableHeader}
                           actions={actionButton} hasFixedWidth={true} actionColWidth="60px" tableClass="visits-table" />
          </NewTableLayout>
          <Dialog
            open={taskIdToEdit > 0}
            onClose={() => closeViewDialog()}
            maxWidth={"lg"}
            aria-labelledby={"form-dialog-title"}>
              <div className={'p-4'}>
                  <TaskDetails showingDataFor="activeVisits" taskIdToEdit={taskIdToEdit} onClose={(taskModified) => {
                      closeViewDialog();
                      if (taskModified) {
                          // dispatch(getCountedVisits('active', currentPage));
                          loadMethod();
                      }
                  }} />
              </div>
          </Dialog>
      </>
    );

};

const mapStateToProps = (state) => {
    const { serviceRequest } = state;
    return {
        activeServiceRequestsList: serviceRequest?.activeRequests || {},
    };
};

export default connect(mapStateToProps)(ActiveRequest);
