import React, {useState, useEffect, lazy} from "react";
import {connect} from "react-redux";
import {useDispatch} from 'react-redux';
import Table from "../../../sharedComponents/table/Table";
import {getCountedVisits} from '../../../services/serviceRequest'
import {HttpsAction} from "../../../commonApiFunction/httpsAction";
import Swal from "sweetalert2";
import Pagination from "../../../sharedComponents/pagination";
import {pageLimit} from "../../../appConstants";
import rowHelperFunctions from "../../../helpers/row.helpers";
import {useLocation, useNavigate, useOutletContext} from "react-router-dom";
// import TaskDetails from "../TaskDetails";
import {Dialog} from "@mui/material";
import dateHelperFunctions from "../../../helpers/date.helpers";
import CommonPaginationNew from "../../../sharedComponents/pagination/CommonPaginationNew";
import NewTableLayout from "../shared/NewTableLayout";

const TaskDetails = lazy(() => import("../TaskDetails"));

const IncompleteVisits = ({/*searchText, */incompleteVisitsList}) => {
    const backButtonTextSuffix = 'In-Complete Visits';
    const backLink = '/agency/servicerequest/incompleteVisits';

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { searchText, setPaginationTab } = useOutletContext();

    const [currentPage, setCurrentPage] = useState(0);
    // const [showTaskDetails, setShowTaskDetails] = useState(false);
    const [taskIdToEdit, setTaskIdToEdit] = useState(0);

    const taskIdToSelect = location?.state != null && location?.state?.taskId != null ? location.state.taskId : 0;

    const transformedArray = incompleteVisitsList?.rows?.map(item => {
        let statusClass = 'text-bg-success';
        let statusText = item.taskStatus;
        if (item.clockOut == null) {
            statusClass = 'text-bg-danger';
            statusText = 'Clock-out missing';
        } else {
            if (item.nurseSignatureDocumentLink == null || item.patientSignatureDocumentLink == null) {
                statusClass = 'text-bg-warning';
                statusText = 'Signatures missing';
            }
        }

        // let {relatedText, relatedTextColor, relatedLink, relatedId, hasValidChartingId} = rowHelperFunctions.getRelatedInfo(item);
        let { hasValidChartingId, relatedInfoList} = rowHelperFunctions.getRelatedInfo(item);

        const highlightRow = taskIdToSelect === item.taskId;

        return {
            'id': {taskId: item.taskId, nurseId: item.assignedNurse, serviceRequestId: item.serviceRequestId},
            'Recipients': rowHelperFunctions.getClientRowItem(navigate, item.patientId, item.patientFullName, false, backButtonTextSuffix, backLink, hasValidChartingId ? `/agency/clients/${item.patientId}/charting/0` : null),
            'age': item.patientAge,
            // 'serviceRequired': item.serviceRequestType,
            'serviceRequired': <div className="position-relative1 p-0 m-0">
                <span>{item.serviceRequestType}</span>
                {/*{relatedText && <div className="position-absolute top-22 start-50 translate-middle cursor-pointer" onClick={() => {
                    navigate(relatedLink, {
                        state: {
                            taskId: relatedId,
                        },
                    });
                }}><span className={`badge rounded-pill text-wrap lh-1 text-bg-${relatedTextColor}`}>{relatedText}</span></div>}*/}
                {(relatedInfoList || []).length > 0 && <div className="position-absolute top-17 start-50 translate-middle cursor-pointer w-100 d-flex justify-content-center gap-1">{relatedInfoList.map(({relatedTextColor, relatedText, relatedLink, relatedState}, index) => <span key={index} onClick={() => {
                    if (relatedLink != null) navigate(relatedLink, { state: relatedState });
                }} className={`badge rounded-pill text-wrap lh-1 text-bg-${relatedTextColor}`}>{relatedText}</span>)}</div>}
            </div>,
            'duration': <div className="d-flex flex-column gap-0" style={{minWidth: '125px'}}>
                <span className="d-block" style={{fontSize: '14px'}}>{item.taskDateFormatUs}</span>
                <span className="d-block" style={{fontSize: '12px'}}>{item.scheduledTimingsAmPm}</span>
            </div>,
            'hours': dateHelperFunctions.hoursAndMinutesToText(item.totalScheduledHours, item.totalScheduledMinutesLessHours),
            // 'frequencies': item.frequencies,
            'location': item.patientLocation || 'N/A',
            // 'status': item.serviceRequestStatus,
            'nurseName': rowHelperFunctions.getSingleNurseRowItem(navigate, item?.assignedNurseId, item?.assignedNurseFullName, false, item?.assignedNurseIsFreelance, backButtonTextSuffix, backLink, hasValidChartingId ? `/agency/nurses/edit/${item?.assignedNurseId}/charting` : null),
            highlightRow,
            importantRow: item.isCurrentDayTask,
        }
    });

    const serviceHeaderData = [
        {
            title: "Recipients",
            dataIndex: "Recipients",
            // align: 'left',
            width: '200px',
        },
        {
            title: "Age",
            dataIndex: "age",
            width: '47px',
        },
        {
            title: "Service Required",
            dataIndex: "serviceRequired",
            width: '100px',
        },
        {
            title: "Date/Timings",
            dataIndex: "duration",
            width: '130px',
        },
        {
            title: "Hours",
            dataIndex: "hours",
            width: '75px',
        },
        // {
        //     title: "Frequency",
        //     dataIndex: "frequencies",
        //     render: (text) => {
        //         return text.map(item => {
        //             const firstThreeLetters = item.frequency.substring(0, 3);
        //             return firstThreeLetters;
        //         }).join(', ');
        //     }
        // },
        {
            title: "Location",
            dataIndex: "location",
        },
        {
            title: "Nurse",
            dataIndex: "nurseName",
            width: '200px',
        },
        // {
        //     title: "",
        //     dataIndex: "Action",
        // }
    ];

    const actionButton = [
        {
            // actionName: 'Edit',
            actionName: 'View',
            buttonAction: (specificData) => handleDialog(specificData.taskId),
            actionClass: 'btn-primary',
            iconClass: 'fa-user-plus',
        },
    ];

    const handleDialog = (id) => {
        if (id != null && id > 0) {
            setTaskIdToEdit(id);
        }
    };

    const closeViewDialog = () => {
        setTaskIdToEdit(0);
        dispatch(HttpsAction({
            actionType: `GET_TASK_DETAILS`,
            reduxKeyName: 'taskDetails',
            reducerName: 'SERVICE_REQUEST',
            removeData: true,
        }));
    };

    const loadMethod = (curPage, toSearch) => {
        const useDefault = curPage == null && toSearch == null;
        if (useDefault) dispatch(getCountedVisits('completed', 1));
        else {
            if (location?.state != null && 'serviceRequestId' in location.state) {
                if (toSearch != null && toSearch.length > 0)
                    dispatch(getCountedVisits('completed', curPage, toSearch, location.state.serviceRequestId));
                else
                    dispatch(getCountedVisits('completed', curPage, null, location.state.serviceRequestId));
            } else {
                if (toSearch != null && toSearch.length > 0)
                    dispatch(getCountedVisits('completed', 1, toSearch));
                else
                    dispatch(getCountedVisits('completed', curPage));
            }
        }
    };

    useEffect(() => {
        if (incompleteVisitsList != null && incompleteVisitsList?.rows?.length != null) {
            setPaginationTab(incompleteVisitsList);
        }
    }, [incompleteVisitsList]);

    // useEffect(() => {
    //     if (location?.state != null && 'serviceRequestId' in location.state) {
    //         if (searchText != null && searchText.length > 0)
    //             dispatch(getCountedVisits('incomplete', currentPage, searchText, location.state.serviceRequestId));
    //         else
    //             dispatch(getCountedVisits('incomplete', currentPage, null, location.state.serviceRequestId));
    //     } else {
    //         if (searchText != null && searchText.length > 0)
    //             dispatch(getCountedVisits('incomplete', 1, searchText));
    //         else
    //             dispatch(getCountedVisits('incomplete', currentPage));
    //     }
    //
    // }, [currentPage, searchText, location?.state]);

    return (
      <>
          {/*<div className="container-fluid bg-light">
              <div className="bg-light-gray px-3 py-3">
                  <div className="row">
                      <div className="container-fluid">
                          <div className="tab-content" id="pills-tabContent">
                              <div className="bg-white mt-3">*/}
                                  {/*<Table headers={serviceHeaderData} rows={transformedArray} actions={actionButton} hasFixedWidth={true} actionColWidth="75px" tableClass="visits-table" />*/}
                                  {/*<CommonPaginationNew data={incompleteVisitsList} onPageChange={(page) => setCurrentPage(page)} />*/}
                              {/*</div>
                          </div>
                          <Pagination
                            currentPage={currentPage}
                            totalRecords={incompleteVisitsList?.total}
                            limit={pageLimit}
                            onPageChange={(page) => {
                                setCurrentPage(page);
                            }}
                          ></Pagination>
                      </div>
                  </div>
              </div>
          </div>*/}
          <NewTableLayout listData={incompleteVisitsList} loadMethod={loadMethod} loadDeps={[searchText]}>
              <Table headers={serviceHeaderData} rows={transformedArray} actions={actionButton} hasFixedWidth={true} actionColWidth="75px" tableClass="visits-table" />
          </NewTableLayout>
          <Dialog
            open={taskIdToEdit > 0}
            onClose={() => closeViewDialog()}
            maxWidth={"lg"}
            aria-labelledby={"form-dialog-title"}>
              <div className={'p-4'}>
                  <TaskDetails showingDataFor="incompleteVisits" taskIdToEdit={taskIdToEdit} onClose={(taskModified) => {
                      closeViewDialog();
                      if (taskModified) {
                          dispatch(getCountedVisits('incomplete', currentPage));
                      }
                  }} />
              </div>
          </Dialog>
      </>
    );

};

const mapStateToProps = (state) => {
    const { serviceRequest } = state;
    return {
        incompleteVisitsList: serviceRequest?.countedIncompleteVisits || {},
    }
}

export default connect(mapStateToProps)(IncompleteVisits);
