import React, {useEffect, useRef, useState} from "react";
import TableTitle from "../../Dashboard/TableTitle";
import {Card} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import Table from "../../../../sharedComponents/table/Table";
import CustomDialog from "../../../../sharedComponents/CustomDialog/index";
import PrimaryButton from "../../../../sharedComponents/buttons/PrimaryButton";
import {useReactToPrint} from "react-to-print";
import {getNursesData} from "../../../redux/actions/AdminActions";
import BackLink from "../../../../sharedComponents/buttons/BackLink";
import Pagination from "../../../../sharedComponents/pagination";
import {ADMIN_NURSES_NAV_TITLES} from "../../../../appConstants";

const AllNurses = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const printFormRef = useRef(null);

    const nursesList = useSelector(state => state.admin?.nursesList);
    const allNursesList = useSelector(state => state.admin?.allNursesListNoPaging);

    const [currentPage, setCurrentPage] = useState(1);
    const [dataFilters, setDataFilters] = useState({from: null, to: null, searchText: ''});

    const [exportModal, setExportModal] = useState(false);

    const transformedArray = (nursesList?.rows || [])?.map((item, index) => {
        return {
            id: item.id,
            sn: item?.nurseId,
            name: item?.nurseFullName,
            email: item?.email || 'N/A',
            dateCreated: item?.creationDateFormatUs || 'N/A',
            // hours:
            preferredRates: item?.preferredRates || "N/A",
            verification: item.isVerified ? "Verified" : "Unverified",
            status: item.isAvailable ? "Available" : "Unavailable"
        }
    });
    const exportTransformedArray = (allNursesList || [])?.map((item, index) => {
        return {
            id: item.id,
            sn: item?.nurseId,
            name: item?.nurseFullName,
            email: item?.email || 'N/A',
            dateCreated: item?.creationDateFormatUs || 'N/A',
            // hours:
            preferredRates: item?.preferredRates || "N/A",
            verification: item.isVerified ? "Verified" : "Unverified",
            status: item.isAvailable ? "Available" : "Unavailable"
        }
    });

    const serviceHeaderData = [
        {
            title: "S/N",
            dataIndex: "sn",
        },
        {
            title: "Name",
            dataIndex: "name",
        },
        {
            title: "Email address",
            dataIndex: "email",
        },
        {
            title: "Date created",
            dataIndex: "dateCreated",
        },
        {
            title: "Preferred rates",
            dataIndex: "preferredRates",
        },
        {
            title: "Verification",
            dataIndex: "verification",
        },
        {
            title: "Status",
            dataIndex: "status",
        },
    ];


    // const handleSearch = (e) => {
    //     setSearchTerm(e.target.value);
    // };

    const handleFilters = (filters) => {
        setDataFilters(filters);
    };

    const handleExport = async () => {
        dispatch(getNursesData('nurse-list-export', 1, `doPaging=false`));
        setExportModal(true);
    };

    const handlePrint = useReactToPrint({
        content: () => printFormRef.current,
        onAfterPrint: () => {
            // Close the modal after printing or cancelling the PDF
            handleCloseFormModal()
        }
    });

    const handleCloseFormModal = () => {
        setExportModal(false)
    };

    // const handleDateFilter = ({from, to}) => {
    //     if (from && to) {
    //         setFromDate(from);
    //         setToDate(to);
    //         // dispatch(getNursesData('nurse-list', 1, `fromDate=${from}&toDate=${to}`));
    //     } else {
    //         // dispatch(getNursesData('nurse-list'));
    //         setFromDate(null);
    //         setToDate(null);
    //     }
    // };

    function handlePageChange(page) {
        setCurrentPage(page);
    }

    useEffect(() => {
        // if (searchTerm) {
        //     dispatch(getNursesData('nurse-list', 1, `searchText=${searchTerm}`));
        // } else {
        //     dispatch(getNursesData('nurse-list', currentPage));
        // }

// console.log("test- dataFilters", dataFilters);
        const {from: fromDate, to: toDate, searchText} = dataFilters;
        if (searchText || (fromDate !== null && toDate !== null) || currentPage) {
          let qsFilter = '';
          if (searchText.length > 0) {
            qsFilter += `searchText=${searchText}`;
          }
          if (fromDate != null && toDate != null) {
              if (qsFilter.length > 0) qsFilter += '&';
              qsFilter += `fromDate=${fromDate}&toDate=${toDate}`;
          }
          dispatch(getNursesData("nurse-list", currentPage, qsFilter.length > 0 ? qsFilter : undefined));
        }
    }, [currentPage, dataFilters]);

    return (
        <>
            <div className="app-main__inner">
                <div className="main-dashboard-page">
                    <div className="col-12 page-back-btn">
                        <BackLink link="/admin/dashboard" linkText="Nurses Overview" />
                    </div>
                    <div className="row mb-15">
                        <TableTitle
                            title={ADMIN_NURSES_NAV_TITLES.ALL_NURSES}
                            // onSearch={handleSearch}
                            handleExport={handleExport}
                            // handleDateFilter={handleDateFilter}
                            handleFilters={handleFilters}
                        />
                        <Card>
                            <Card.Body>
                                <div>
                                    <Table headers={serviceHeaderData} rows={transformedArray} />
                                    <Pagination
                                      currentPage={nursesList?.page}
                                      totalRecords={nursesList?.total}
                                      limit={nursesList?.pageSize}
                                      onPageChange={handlePageChange}
                                    />
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>

            <CustomDialog
                open={exportModal && exportTransformedArray != null && exportTransformedArray.length > 0}
                onClose={() => handleCloseFormModal()}
                title={ADMIN_NURSES_NAV_TITLES.ALL_NURSES}
                maxWidth={'lg'}
                actions={
                    <div className="d-flex justify-content-center" style={{marginBottom: "20px"}}>
                        <PrimaryButton
                            type="submit"
                            className="btn-view-repo gap-2 px-5 py-2"
                            text={"Export"}
                            onClick={() => handlePrint()}
                        />
                    </div>
                }>
                <div ref={printFormRef} className={'p-4'}>
                    <Table headers={serviceHeaderData} rows={exportTransformedArray}/>
                </div>
            </CustomDialog>
        </>
    )
};

export default AllNurses;
