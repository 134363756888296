import React, {useState} from "react";
import {Popover} from "@mui/material";
import Typography from "@mui/material/Typography";

const InfoPopover = ({popoverText, popoverIdValue, size = '16px', topPos = '10px'}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverId, setPopoverId] = useState(undefined);

  return (
    <>
      <button onClick={({target}) => {
        setAnchorEl(target);
        setPopoverId(`${popoverIdValue}-popover`);
      }} aria-describedby={popoverIdValue} id={`${popoverIdValue}-btn`} type="button"
              style={{width: size, height: size, aspectRatio: '1/1', top: topPos, lineHeight: '10px', backgroundColor: '#1976d2'}}
        // popovertarget={`${popoverIdValue}-info`}
              className="position-absolute cursor-pointer text-white m-0 p-0 d-flex justify-content-center align-items-center justify-content-center bg-blue fs-8 fw-bold fst-italic top1-0 end-0 ratio1 ratio1-1x1 rounded-circle"
      >i</button>
      {/*<div id={`${popoverIdValue}-info`} anchor={`${popoverIdValue}-btn`}>{popoverText}</div>*/}
      <Popover
        id={popoverId}
        open={anchorEl != null}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
          setPopoverId(undefined);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{
          boxShadow: 1,
        }}
      >
        <Typography variant="body2" sx={{px: 1}}>{popoverText}</Typography>
      </Popover>
    </>
  );
};

export default InfoPopover;
