import React, {useState} from 'react';
import {Button} from "@mui/material";
import plusIcon from "../Agency/assets/image/plus-white.svg";
import CreateNewTaskModel from "../Agency/components/Charting/CreateNewTaskModel";
import Modal from "../sharedComponents/Modal";
import CreateInitialAssessmentModal from "../Agency/components/Charting/CreateInitialAssessmentModal";
import {NavLink, Outlet, useLocation, useNavigate} from "react-router-dom";
import {debounce} from "lodash";
import {defaultPaginationTab} from "../appConstants";
import PaginationSummary from "../sharedComponents/pagination/PaginationSummary";
import apiCallHelperFunctions from "../helpers/api.call.helpers";

const chartingTabsList = [
  {
    text: 'Forms (Templates)',
    path: 'templates',
  },
  {
    text: 'Initial Assessments',
    path: 'initial-assessments',
  },
  {
    text: 'Supervisory Visits',
    path: 'supervisory-visits',
  },
  {
    text: 'Discharge Summaries',
    path: 'discharge-summaries',
  },
  {
    text: 'Care Plans',
    path: 'care-plans',
  },
];

const creationButtonsList = [
  {
    text: 'CREATE NEW FORM',
    path: 'templates',
    modalTitle: null,
  },
  {
    text: 'CREATE ASSESSMENT',
    path: 'initial-assessments',
    modalTitle: 'Create Initial Assessment',
  },
  {
    text: 'CREATE VISIT',
    path: 'supervisory-visits',
    modalTitle: 'Create Supervisory Visit',
  },
  {
    text: 'CREATE SUMMARY',
    path: 'discharge-summaries',
    modalTitle: 'Create Discharge Summary',
  },
  {
    text: 'CREATE PLAN',
    path: 'care-plans',
    modalTitle: 'Create Care Plan',
  },
];

const CreationButton = ({currentPathname, setModal, setModalTitle, navigate}) => {
// console.log('test- creationButtonsList', creationButtonsList);
  // if (creationButtonsList == null || creationButtonsList.length === 0) return null;
  const foundInfo = creationButtonsList.find((item) => item.path === currentPathname);
  if (foundInfo == null) return null;
  const {text, modalTitle, path} = foundInfo;

  return (
    <>
      {path === 'templates' && <Button variant="contained" className="w-max" onClick={() => navigate(`/agency/lookups/form`)}>
        <div style={{display: 'flex', flexDirection: 'row', gap: 10}}>
          <img src={plusIcon} alt={''}/>
          <span>{text}</span>
        </div>
      </Button>}
      {path !== 'templates' && <Button variant="contained" className="w-max" onClick={() => {
        setModalTitle(modalTitle);
        setModal(prevState => ({
          ...prevState,
          createInitialAssessment: path === 'initial-assessments',
          createSupervisoryVisit: path === 'supervisory-visits',
          createDischargeSummary: path === 'discharge-summaries',
          createCarePlan: path === 'care-plans',
        }));
      }}>
        <div style={{display: 'flex', flexDirection: 'row', gap: 10}}>
          <img src={plusIcon} alt={''}/>
          <span>{text}</span>
        </div>
      </Button>}
    </>
    // <Button
    //   className="btn btn-primary"
    //   onClick={() => {
    //     setIsModalOpen(true);
    //     setModalTitle(text);
    //   }}
    // >
    //   <img src={plusIcon} alt="create" />
  );
};

const ChartingLayout = () => {
  const pathPrefix = '/agency/charting';

  const navigate = useNavigate();
  const location = useLocation();

  const pathLastParam = location.pathname.split('/').pop();

  const [searchText, setSearchText] = useState('');
  const [paginationTab, setPaginationTab] = useState(defaultPaginationTab);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modal, setModal] = useState({
    createInitialAssessment: false,
    createSupervisoryVisit: false,
    createDischargeSummary: false,
    createCarePlan: false,
  });

  const handleSearchTextChanged = debounce((value) => {
    setSearchText(value.length === 0 ? '' : value);
  }, 500);

  return (
    <>
      <div className="app-main__outer">
        <div className="main-service-request-page">
          <div className="app-main__inner service-request bg-white">
            <h5>{apiCallHelperFunctions.getChartingHeadingNew(pathLastParam)}</h5>

            <div className="row pb-2 pbx-4 justify-content-between">
              <div className="col-sm-7 col-12 d-flex align-items-end">
                <PaginationSummary listInfo={paginationTab}/>
              </div>
              <CreationButton navigate={navigate} currentPathname={pathLastParam} setModalTitle={setModalTitle} setModal={setModal}/>
            </div>

            <div className="row align-items-center">
              <div className="col-sm-9 col-12">
                <ul className="nav nav-tabs p-1" id="myTab" role="tablist">
                  {chartingTabsList.map(({text, path}, index) => (
                    <li key={index} className="nav-item" role="presentation">
                      <NavLink to={`${pathPrefix}/${path}`} className={'nav-link tab-nav-link-style'}>{text}</NavLink>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-sm-3 col-12">
                <div className="input-group input-search-djkds me-2 mb-3">
                  <span className="input-group-append d-flex">
                    <button className="btn ms-n5" type="button">
                      <i className="fa fa-search"></i>
                    </button>
                  </span>
                  <input
                    className="form-control hhgrsjd bg-transparent border-0 border"
                    type="search"
                    placeholder="Search..."
                    onChange={({target}) => handleSearchTextChanged(target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid bg-light">
            <div className="bg-light-gray px-3 py-3">
              <div className="row">
                <div className="container-fluid">
                  <Outlet context={{searchText, setPaginationTab}}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal --> */}
      {isModalOpen && (
        <CreateNewTaskModel
          isModalOpen={isModalOpen}
          closeModal={() => setIsModalOpen(false)}
          modalTitle="Create New Task"
        />
      )}

      <Modal
        isOpen={modal.createInitialAssessment || modal?.createSupervisoryVisit || modal?.createDischargeSummary || modal?.createCarePlan}
        onClose={() => setModal(prevState => ({...prevState, createInitialAssessment: false, createSupervisoryVisit: false, createDischargeSummary: false, createCarePlan: false}))}
        modalTitle={modalTitle}>
        {/* Create Initial Assessment Modal */}
        <CreateInitialAssessmentModal
          creatingForInitialAssessment={modal.createInitialAssessment || false}
          creatingForSupervisoryVisit={modal.createSupervisoryVisit || false}
          creatingForDischargeSummary={modal.createDischargeSummary || false}
          creatingForCarePlan={modal.createCarePlan || false}
          isOpen={modal.createInitialAssessment || modal?.createSupervisoryVisit || modal?.createDischargeSummary || modal?.createCarePlan}
          onClose={() => setModal(prevState => ({...prevState, createInitialAssessment: false, createSupervisoryVisit: false, createDischargeSummary: false, createCarePlan: false}))}
        />
      </Modal>
    </>
  );
};

export default ChartingLayout;
