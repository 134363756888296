import React from "react";
import {NavLink} from "react-router-dom";

const BackLink = ({link = '/', linkText = 'Home', linkClasses = '', id = null}) => {
  let classes = 'back-to text-decoration-none';
  if (linkClasses.length > 0) {
    classes += ' ' + linkClasses;
  }
  return (
    <>
      <NavLink to={link} className={classes.trim()} state={{id: id || undefined}}>
        <img src="/image/left-arrow.png" alt=""/>&nbsp;Back to&nbsp;
        {/*<i className="fa fa-angle-left" aria-hidden="true"></i>&nbsp;Back to&nbsp;*/}
        <span>{linkText}</span>
      </NavLink>
    </>
  );
};

export default BackLink;
