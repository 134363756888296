import React, {useRef, useState} from "react";
import classes from "./AdminTabs.module.css"
import {Popover, Stack} from "@mui/material";
import {Field, reduxForm} from "redux-form";
import CustomInputField from "../../../../sharedComponents/fields/customInputField";
import PrimaryButton from "../../../../sharedComponents/buttons/PrimaryButton";
import {connect} from "react-redux";
import {useParams} from "react-router";
import {required} from "../../../../helpers/validation";

const ContentHeader = ({handleSubmit, title = "", handleExport = () => undefined, handleDateFilter = () => undefined, onSearch = () => undefined, showSearch = true}) => {
    const {tabId} = useParams();
    const dropdownRef = useRef();

    const [isDropdown, setIsDropdown] = useState(false);

    const handleClickFilter = () => {
        setIsDropdown(!isDropdown)
    };

    const onSubmit = (data) => {
        handleDateFilter(data);
        handleClickFilter()
    };

    return (
        <>
            <div className={classes.tabTitleMain}>
                <div className={classes.tabTitle}>
                    {title}
                </div>
                <div className={'d-flex flex-column gap-2'}>
                    <div className={'d-flex justify-content-end'}>
                        <div className={classes.exportButton} onClick={handleExport}>
                            Export
                        </div>
                    </div>
                    <div className={'d-flex flex-row'}>
                        {showSearch && <div className="input-group input-search-djkds history-search">
                            <span className="input-group-append d-flex">
                              <button className="btn ms-n5" type="button">
                                <i className="fa fa-search"></i>
                              </button>
                            </span>
                            <input
                                className="form-control hhgrsjd bg-transparent border-0 border"
                                type="search"
                                placeholder="Search..."
                                onChange={onSearch}
                            />
                        </div>}
                        <Stack ref={dropdownRef} className={classes.filterByButton} onClick={handleClickFilter}
                               style={!showSearch ? {width: '120px'} : {}}>
                            <svg width="29" height="27" viewBox="0 0 29 27" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.203125" y="0.734375" width="28.8" height="25.6" rx="8" fill="#C2CFE0"
                                      fillOpacity="0.19"/>
                                <path fillRule="evenodd" clipRule="evenodd"
                                      d="M21.01 14.9462C22.775 14.9462 24.2109 16.2162 24.2109 17.7769C24.2109 19.3376 22.775 20.6075 21.01 20.6075C19.2439 20.6075 17.807 19.3376 17.807 17.7769C17.807 16.2162 19.2439 14.9462 21.01 14.9462ZM21.01 16.3177C20.0946 16.3177 19.3499 16.9723 19.3499 17.7769C19.3499 18.5824 20.0946 19.2361 21.01 19.2361C21.9244 19.2361 22.6681 18.5824 22.6681 17.7769C22.6681 16.9723 21.9244 16.3177 21.01 16.3177ZM13.4936 17.1266C13.9194 17.1266 14.265 17.4339 14.265 17.8124C14.265 18.1909 13.9194 18.4981 13.4936 18.4981H7.01251C6.58668 18.4981 6.24107 18.1909 6.24107 17.8124C6.24107 17.4339 6.58668 17.1266 7.01251 17.1266H13.4936ZM9.4129 6.07031C11.179 6.07031 12.6149 7.34118 12.6149 8.90187C12.6149 10.4626 11.179 11.7316 9.4129 11.7316C7.64786 11.7316 6.21094 10.4626 6.21094 8.90187C6.21094 7.34118 7.64786 6.07031 9.4129 6.07031ZM9.4129 7.44175C8.49849 7.44175 7.7538 8.09638 7.7538 8.90187C7.7538 9.70645 8.49849 10.3602 9.4129 10.3602C10.3283 10.3602 11.072 9.70645 11.072 8.90187C11.072 8.09638 10.3283 7.44175 9.4129 7.44175ZM22.8647 8.26498C23.2906 8.26498 23.6362 8.57218 23.6362 8.95069C23.6362 9.32921 23.2906 9.63641 22.8647 9.63641H16.3847C15.9589 9.63641 15.6133 9.32921 15.6133 8.95069C15.6133 8.57218 15.9589 8.26498 16.3847 8.26498H22.8647Z"
                                      fill="#434344"/>
                            </svg>

                            <Stack className={classes.filterText}>
                                Filter By
                            </Stack>
                        </Stack>

                    </div>
                </div>
            </div>

            <Popover
                open={isDropdown}
                anchorEl={dropdownRef.current}
                onClose={() => setIsDropdown(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={{
                    style: {
                        boxShadow: 'unset',
                        width: '235px',
                        height: 'auto',
                        borderRadius: '5px',
                        marginTop: '5px',
                        border: '1px solid #D5D5D5',
                        padding: '20px'
                    },
                }}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-12">
                            <div className="mb-4 input-field-main">
                                <Field
                                    className="form-control mk-wi"
                                    name="from"
                                    label="From"
                                    type="date"
                                    component={CustomInputField}
                                    validate={required}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="mb-4 input-field-main">
                                <Field
                                    className="form-control mk-wi"
                                    name="to"
                                    label="To"
                                    type="date"
                                    component={CustomInputField}
                                    validate={required}
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        className="d-flex justify-content-center"
                        style={{marginTop: "20px"}}
                    >
                        <PrimaryButton
                            type="submit"
                            className="btn-view-repo gap-2 px-5 py-2"
                            text={"Add Filter"}
                            onClick={() => undefined}
                        />
                    </div>
                </form>
            </Popover>

        </>
    )
};

export default connect()(
    reduxForm({form: "ContentHeader", enableReinitialize: true})(ContentHeader)
);
