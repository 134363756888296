import React, {useEffect, useState} from 'react';
import {NavLink, Outlet, useLocation, useNavigate} from "react-router-dom";
import {debounce} from "lodash";

import apiCallHelperFunctions from "../helpers/api.call.helpers";
import {ReactComponent as SearchIcon} from "../Agency/assets/image/small-search-icon.svg";
import Pagination from "../sharedComponents/pagination";
import PaginationSummary from "../sharedComponents/pagination/PaginationSummary";
import {defaultPaginationTab} from "../appConstants";

// const ServiceRequestsTabButton = ({navigate, linkText, linkPathname, currentPathname}) => {
//   return (
//     // <button
//     //   className={`tabs_btn nav-link d-flex ${
//     //     currentPathname === linkPathname ? "active" : ""
//     //   } `}
//     //   id={`${linkPathname}-tab`}
//     //   // data-bs-toggle="pill"
//     //   // data-bs-target="#pills-home"
//     //   type="button"
//     //   role="tab"
//     //   // aria-controls="pills-home"
//     //   // aria-selected="true"
//     //   onClick={() => navigate(`/agency/servicerequest/${linkPathname}`)}
//     // >
//     //   {linkText}
//     // </button>
//     <NavLink
//       className={`tabs_btn nav-link d-flex fs-7`}
//       id={`${linkPathname}-tab`}
//       // data-bs-toggle="pill"
//       // data-bs-target="#pills-home"
//       // type="button"
//       role="tab"
//       // aria-controls="pills-home"
//       // aria-selected="true"
//       to={`/agency/servicerequest/${linkPathname}`}
//     >
//       {linkText}
//     </NavLink>
//   );
// };

const TABS_LIST = [
  {
    linkText: "All Requests",
    linkPathname: "allrequest",
  },
  {
    linkText: "Scheduled Visits",
    linkPathname: "scheduledrequest",
  },
  {
    linkText: "Active Visits",
    link: "/service-requests/activerequest",
    linkPathname: "activerequest",
  },
  // {
  //   linkText: "Pending Visits",
  //   linkPathname: "pendingrequest",
  // },
  {
    linkText: "Posted Jobs",
    linkPathname: "postedjobs",
  },
  {
    linkText: "Charting Visits",
    linkPathname: "charting",
  },
  {
    linkText: "Completed Visits",
    linkPathname: "completeRequest",
  },
  {
    linkText: "Missed Visits",
    linkPathname: "missedRequests",
  },
  {
    linkText: "In-Complete Visits",
    linkPathname: "incompleteVisits",
  },
];

// const locationPathname = 'xxxxx';

const ServiceRequestsLayout = () => {
  const pathPrefix = '/agency/servicerequest';

  const navigate = useNavigate();
  const location = useLocation();

  const currentPathname = location?.pathname || locationPathname;
  const locationPathname = currentPathname.split('/').slice(-1)[0];
  const isJobRelatedPathname = currentPathname.startsWith('/agency/servicerequest/postedjobs');

  const [tabs, setTabs] = useState([]);
  const [paginationTab, setPaginationTab] = useState(defaultPaginationTab);
  const [search, setSearch] = useState('')

  const onSearch = debounce((e) => {
    setSearch(e.target.value);
  }, 500);

  useEffect(() => {
    setTabs(TABS_LIST);
  }, []);

  return (
    <div className="app-main__outer">
      <div className="app-main__inner bg-white">
        <div className="main-dashboard-page">
          <div className="row mb-0">
            <div className="col-12 page-heading pb-3 d-flex align-items-center justify-content-between">
              <div className="d-flex">
                <h5 className="me-3 mb-0">Service Requests</h5>
                <span className="py-1 px-2 rounded-3 bg-primary-subtle fs-5 fw-bold">
                  {paginationTab.total}
                </span>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between mb-3">
            <PaginationSummary listInfo={paginationTab} />
            <div>
              <button
                type="button"
                className="view_report d-flex btn-view-repo align-items-center gap-2 px-3 py-2"
                onClick={() => {
                  if (isJobRelatedPathname) {
                    const backLink = location.pathname;
                    const backButtonTextSuffix = `Service Request - Posted Jobs - ${apiCallHelperFunctions.getPostedJobsHeading(locationPathname)}`;
                    navigate('/agency/postjob', {
                      state: {
                        showBackButton: true,
                        backButtonTextSuffix: backButtonTextSuffix,
                        backLink,
                      }
                    });
                  } else {
                    navigate("/agency/new-service-request");
                  }
                }}
              >
                <i className="bi bi-plus-lg"></i> {isJobRelatedPathname ? 'Post New Job' : 'Add New Request'}
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-9 mb-0 d-flex align-items-end">
              <div className="d-flex align-items-center custom-tab-styling">
                <ul
                  className="nav nav-underline mb-0 mt-4"
                  id="pills-tab"
                  role="tablist"
                >
                  {tabs.map(({linkText, link, linkPathname}, index) => (
                    <li key={index} className="nav-item" style={{minHeight: '42px'}} role="presentation">
                      {/*<ServiceRequestsTabButton navigate={navigate} linkText={linkText} linkPathname={linkPathname} currentPathname={locationPathname}/>*/}
                      <NavLink to={`${pathPrefix}/${linkPathname}`} className="nav-link tab-nav-link-style" key={index}>{linkText}</NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-12 col-sm-3 mb-2">
              <div className="d-flex justify-content-end">
                <div
                  className="d-flex filter-box justify-content-end position-relative align-items-center ps-3 ">
                  <SearchIcon/>
                  <input
                    type="search"
                    className="form-control bg-light-blue"
                    placeholder="Search..."
                    onChange={onSearch}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light">
        <div className="bg-light-gray px-3 py-3">
          <div className="row">
            <div className="container-fluid">
              <Outlet context={{searchText: search, setPaginationTab}} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceRequestsLayout;
