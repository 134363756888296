import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {Field, reduxForm} from "redux-form";
import CustomInputField from "../../../../sharedComponents/reduxfields/customInputField";
import MultiSelectDropdown from "../../../../sharedComponents/multiSelect";
import PrimaryButton from "../../../../sharedComponents/buttons/PrimaryButton";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import CustomDropDownField from "../../../../sharedComponents/reduxfields/customDropDown";
import {required} from "../../../../helpers/validation";
import {getCityList} from "../../../../services/commonServices";
import {putAgencyAction} from "../../../../services/AgencyProfile";
import {isAdminRole} from "../../../../helpers/apiMethods";
import {useQueryClient} from "@tanstack/react-query";

const Address = ({reloadData, handleSubmit, initialValues}) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const {tabId, agencyId} = useParams();
    const { agencyId } = useOutletContext();

    const statesArray = queryClient.getQueryData(['list-provinces-states', 233]);

    const [cityList, setCityList] = useState([]);

    const fetchData = async (res) => {
        try {
            if (res.serviceName === "getCityList") {
                const response = await dispatch(getCityList(res.optionId));
                setCityList(response?.data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const getStateId = (e) => {
        let index = e.target.selectedIndex;
        let optionElement = e.target.childNodes[index];
        let optionattr = optionElement.getAttribute("value");

        const optionData = {
            optionId: optionattr,
            serviceName: "getCityList",
        };
        fetchData(optionData);
    };

    const onSubmit = async (data) => {
        // console.log('submit- data', data)
        const payload = {
            addressLine1: data?.addressLine1,
            addressLine2: data?.addressLine2,
            cityId: Number(data?.cityId),
            provinceOrStateId: Number(data?.provinceOrStateId),
            postalOrZipCode: data?.zipcode,
        };

        // console.log('submit- payload', payload)

        try {
            // const decodedTokenData = parseTokenAndGetData().data;
            const response = await dispatch(
              putAgencyAction(payload, "address", "Agency address updated successfully"/*, decodedTokenData.agencyId*/)
            );
            if (response?.success) {
                await dispatch(reloadData());
                navigate(isAdminRole ? `/admin/agency-profile/authPerson/${agencyId}` : `/agency/agency-profile/authPerson`)
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (initialValues.provinceOrStateId != null && initialValues.provinceOrStateId.length > 0) {
            const optionData = {
                optionId: initialValues.provinceOrStateId,
                serviceName: "getCityList",
            };
            fetchData(optionData);
        }
    }, [initialValues]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-9">
                        <div
                          className={`col-12`}
                          style={{ fonSize: "24px", fontWeight: 500 }}
                        >
                            Agency Address
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12">
                        <div className="mb-4 input-field-main">
                            <Field
                                className="form-control mk-wi"
                                name="addressLine1"
                                label="Address Line 1"
                                type="text"
                                component={CustomInputField}
                                placeholder={'Enter address'}
                                requiredMark={true}
                                validate={required}
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="mb-4 input-field-main">
                            <Field
                                className="form-control mk-wi"
                                name="addressLine2"
                                label="Address Line 2"
                                type="text"
                                component={CustomInputField}
                                placeholder={'Enter address'}
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="mb-4 input-field-main">
                            {/*<Field
                                className="form-control mk-wi"
                                name="state"
                                label="State"
                                type="text"
                                component={CustomInputField}
                                placeholder={'California'}
                            />*/}
                            <label>State <span className="mandatory-field">*</span></label>
                            <Field
                              className="form-control"
                              name="provinceOrStateId"
                              component={CustomDropDownField}
                              validate={required}
                              requiredMark={true}
                              onChange={(e) => getStateId(e)}
                            >
                                <option value="">Please select</option>
                                {statesArray?.map((res, index) => {
                                    return <option key={index} value={res.id}>{res.name}</option>
                                })}
                            </Field>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="mb-4 input-field-main">
                            {/*<Field
                                className="form-control mk-wi"
                                name="city"
                                label="City"
                                type="text"
                                component={CustomInputField}
                            />*/}
                            <label>City <span className="mandatory-field">*</span></label>
                            <Field
                              className="form-control read-only1"
                              name="cityId"
                              component={CustomDropDownField}
                              requiredMark={true}
                              validate={required}
                            >
                                <option value="">Please select</option>
                                {cityList?.map((res, index) => {
                                    return <option key={index} value={res.id}>{res.name}</option>
                                })}
                            </Field>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="mb-4 input-field-main">
                            <Field
                                className="form-control mk-wi"
                                name="zipcode"
                                label="Zipcode"
                                type="text"
                                component={CustomInputField}
                                requiredMark={true}
                                validate={required}
                            />
                        </div>
                    </div>
                </div>
                <div
                    className="d-flex justify-content-center"
                    style={{marginTop: "40px"}}
                >
                    <PrimaryButton
                        type="submit"
                        className="btn-view-repo gap-2 px-5 py-2"
                        text={"Next"}
                        onClick={() => undefined}
                    />
                </div>
            </form>
        </>
    )
};

const mapStateToProps = state => {
    const {common, agencyProfile} = state;
    const data = agencyProfile?.agencyProfile?.data;

    return {
        initialValues: {
            addressLine1: data?.agencyAddress?.agencyAddressLine1 || '',
            addressLine2: data?.agencyAddress?.agencyAddressLine2 || '',
            cityId: data?.agencyAddress?.agencyCityId?.toString() || '',
            provinceOrStateId: data?.agencyAddress?.agencyProvinceOrStateId?.toString() || '',
            zipcode: data?.agencyAddress?.agencyPostalOrZipCode || '',
        }
    }
}


export default connect(mapStateToProps)(
    reduxForm({form: "address", enableReinitialize: true})(Address)
);
