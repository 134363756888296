export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
export const tokenVarName = process.env.REACT_APP_ACCESS_TOKEN_VAR_NAME || 'LOCAL_TOKEN';
export const refreshTokenVarName = process.env.REACT_APP_REFRESH_TOKEN_VAR_NAME || 'REFRESH_TOKEN';
export const socketUrl = process.env.REACT_APP_SOCKET_URL;
export const pageLimit = 10;
export const noImagePath = '/images/no-image.png';
export const fieldBottomMarginClass = 'mb-3';
export const jobRelatedText = 'Job';
export const chartingRelatedText = 'Charting';
export const defaultErrorMessage = 'Something went wrong!';
export const defaultPaginationTab = {
    rows: [],
    page: 1,
    count: 0,
    total: 0,
};
export const jobsTabUrls = [
    '/agency/servicerequest/postedjobs',
    '/agency/servicerequest/postedjobsactive',
    '/agency/servicerequest/postedjobsaccepted',
    '/agency/servicerequest/postedjobsexpired',
    '/agency/servicerequest/postedjobsinprogress',
    '/agency/servicerequest/postedjobscompleted',
];
export const jobsTabNewUrls = [
    '/agency/servicerequest/postedjobs/pending',
    '/agency/servicerequest/postedjobs/active',
    '/agency/servicerequest/postedjobs/accepted',
    '/agency/servicerequest/postedjobs/expired',
    '/agency/servicerequest/postedjobs/inprogress',
    '/agency/servicerequest/postedjobs/completed',
];
export const chartingTabUrls = [
    '/agency/servicerequest/initialAssessments',
    '/agency/servicerequest/supervisoryVisits',
    '/agency/servicerequest/dischargeSummaries',
    '/agency/servicerequest/carePlans',
];
export const chartingTabNewUrls = [
    '/agency/servicerequest/charting/initialAssessments',
    '/agency/servicerequest/charting/supervisoryVisits',
    '/agency/servicerequest/charting/dischargeSummaries',
    '/agency/servicerequest/charting/carePlans',
];
export const pendingColor = '#ff69b4';
export const completedColor = '#39cb82';
export const activeColor = '#ffc300';
export const missedColor = '#dc3545';
export const maxUploadFileSizeMbs = 20;
export const paypalDeductionPercentage = 6;
export const ADMIN_NURSES_NAV_TITLES = {
    ALL_NURSES: "All Nurses",
    VERIFIED_NURSES: "Verified Nurses",
    UNVERIFIED_NURSES: "Unverified Nurses",
    WITHDRAWAL_REQUEST: "Withdrawal Requests",
};
