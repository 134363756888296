import React, {useEffect, useState} from "react";
import {change, Field, reduxForm} from "redux-form";
import CustomDropDownField from "../../../../../../sharedComponents/reduxfields/customDropDown";
import CustomInputField from "../../../../../../sharedComponents/reduxfields/customInputField";
import {emailValidation, required} from "../../../../../../helpers/validation";
import {connect, useDispatch, useSelector} from "react-redux";
import PrimaryButton from "../../../../../../sharedComponents/buttons/PrimaryButton";
import {HttpsAction} from "../../../../../../commonApiFunction/httpsAction";
import {useOutletContext, useParams} from "react-router-dom";
import stringHelperFunctions from "../../../../../../helpers/string.helpers";
import {useQueryClient} from "@tanstack/react-query";
import {auth} from "react-formio/lib/modules/auth/reducers";
import numberHelperFunctions from "../../../../../../helpers/number.helpers";
import AddPhysicianModal from "../../../../../AddPhysicianModal";
import {getHealthAgenciesList, getHospiceAgenciesList, getHospitalsList, getPhysiciansList} from "../../../../../../services/commonServices";
import CustomDropDownNewField from "../../../../../../sharedComponents/reduxfields/customDropDownNew";
import AddAgencyModal from "../../../../../AddAgencyModal";
import useCustomNav from "../../../../../../utils/hooks/useCustomNav";

const HealthcareInformation = ({handleSubmit/*, handleSelectTab*/, physiciansArray, hospitalsArray, healthAgenciesArray, hospiceAgenciesArray, agencyId}) => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    // const {clientId} = useParams();
    const { clientId } = useOutletContext();

    const { goTo: gotoHealthcareExtraInfoPage } = useCustomNav('/agency/clients/edit/healthcare/extra-info');

    // const clientReducer = useSelector(state => state.client);

    const physicianTypesArray = queryClient.getQueryData(['list-physician-types', agencyId]);
    // const physiciansArray = queryClient.getQueryData(['list-physicians', agencyId]);
    // const hospitalsArray = queryClient.getQueryData(['list-hospitals', agencyId]);
    // const healthAgenciesArray = queryClient.getQueryData(['list-home-health-agencies', agencyId]);
    // const hospiceAgenciesArray = queryClient.getQueryData(['list-hospice-agencies', agencyId]);

    const [showPhysicianCreateModal, setShowPhysicianCreateModal] = useState(false);
    const [showAgencyCreateModal, setShowAgencyCreateModal] = useState(false);
    const [modalEntityName, setModalEntityName] = useState('');
    const [isCreatingHospital, setIsCreatingHospital] = useState(false);
    const [isCreatingHealthAgency, setIsCreatingHealthAgency] = useState(false);
    const [isCreatingHospiceAgency, setIsCreatingHospiceAgency] = useState(false);

    const onSubmit = (data) => {
        const {
            primaryCarePhysicianId,
            physicianTypeId,
            physicianPhone,
            physicianEmail,
            hospitalEmail,
            homeHealthAgencyEmail,
            hospiceEmail,
            preferredHospitalId,
            preferredHomeHealthAgencyId,
            preferredHospiceAgencyId
        } = data;

        const physician_id = numberHelperFunctions.nullIfEmpty(primaryCarePhysicianId, true);
        const physician_email = physician_id == null ? null : stringHelperFunctions.nullIfEmpty(physicianEmail);
        const physician_phoneNumber = physician_id == null ? null : stringHelperFunctions.nullIfEmpty(physicianPhone);
        const physician_physicianTypeId = physician_id == null ? null : numberHelperFunctions.nullIfEmpty(physicianTypeId, true);
        const primaryPhysicianSelection = physician_id == null && physician_email == null && physician_phoneNumber == null && physician_physicianTypeId == null ? null : {
            recordType: "existing",
            id: physician_id,
            email: physician_email,
            phoneNumber: physician_phoneNumber,
            physicianTypeId: physician_physicianTypeId,
        };

        const hospital_id = numberHelperFunctions.nullIfEmpty(preferredHospitalId, true);
        const hospital_email = hospital_id == null ? null : stringHelperFunctions.nullIfEmpty(hospitalEmail);
        const preferredHospitalSelection = hospital_id == null && hospital_email == null ? null : {
            recordType: "existing",
            id: hospital_id,
            email: hospital_email,
        };

        const healthAgency_id = numberHelperFunctions.nullIfEmpty(preferredHomeHealthAgencyId, true);
        const healthAgency_email = healthAgency_id == null ? null : stringHelperFunctions.nullIfEmpty(homeHealthAgencyEmail);
        const preferredHealthAgencySelection = healthAgency_id == null && healthAgency_email == null ? null : {
            recordType: "existing",
            id: healthAgency_id,
            email: healthAgency_email,
        };

        const hospiceAgency_id = numberHelperFunctions.nullIfEmpty(preferredHospiceAgencyId, true);
        const hospiceAgency_email = hospiceAgency_id == null ? null : stringHelperFunctions.nullIfEmpty(hospiceEmail);
        const preferredHospiceAgencySelection = hospiceAgency_id == null && hospiceAgency_email == null ? null : {
            recordType: "existing",
            id: hospiceAgency_id,
            email: hospiceAgency_email,
        };

        // if (!(primaryPhysicianSelection == null && preferredHospitalSelection == null && preferredHealthAgencySelection == null && preferredHospiceAgencySelection == null)) {
            dispatch(HttpsAction({
                method: 'PUT',
                apiSection: 'client',
                apiName: 'healthcareInformation',
                queryParameter: `/${clientId}`,
                data: {
                    recordType: "new",
                    primaryPhysicianSelection,
                    preferredHospitalSelection,
                    preferredHealthAgencySelection,
                    preferredHospiceAgencySelection,
                },
                positiveCallBack: () => {
                    // handleSelectTab(1);
                    gotoHealthcareExtraInfoPage();
                }
            }));
        // }
    }

    const handlePhysicianChanged = target => {
        let index = target.selectedIndex;
        let optionElement = target.childNodes[index];
        let physicianIdStr = optionElement.getAttribute('value');
        if (physicianIdStr.length > 0) {
            const physicianId = Number(physicianIdStr);
            const data = physiciansArray.find(p => p.id === physicianId);
            if (data != null) {
                dispatch(change("basicInformationForm", 'physicianTypeId', data.physicianTypeId));
                dispatch(change("basicInformationForm", 'physicianEmail', data.physicianEmail));
                dispatch(change("basicInformationForm", 'physicianPhone', data.physicianPhone));
            }
        } else {
            dispatch(change("basicInformationForm", 'physicianTypeId', ''));
            dispatch(change("basicInformationForm", 'physicianEmail', ''));
            dispatch(change("basicInformationForm", 'physicianPhone', ''));
        }
    };

    const handleHospitalChanged = (target, type) => {
        let index = target.selectedIndex;
        let optionElement = target.childNodes[index];
        let agencyIdStr = optionElement.getAttribute('value');
        if (agencyIdStr.length > 0) {
            const agencyOrgId = Number(agencyIdStr);
            if (type === 'hospital') {
                const data = hospitalsArray.find(p => p.id === agencyOrgId);
                if (data != null) {
                    dispatch(change("basicInformationForm", 'hospitalEmail', data.email));
                }
            }
            if (type === 'health-agency') {
                const data = healthAgenciesArray.find(p => p.id === agencyOrgId);
                if (data != null) {
                    dispatch(change("basicInformationForm", 'homeHealthAgencyEmail', data.email));
                }
            }
            if (type === 'hospice-agency') {
                const data = hospiceAgenciesArray.find(p => p.id === agencyOrgId);
                if (data != null) {
                    dispatch(change("basicInformationForm", 'hospiceEmail', data.email));
                }
            }
        } else {
            if (type === 'hospital') {
                dispatch(change("basicInformationForm", 'hospitalEmail', ''));
            }
            if (type === 'health-agency') {
                dispatch(change("basicInformationForm", 'homeHealthAgencyEmail', ''));
            }
            if (type === 'hospice-agency') {
                dispatch(change("basicInformationForm", 'hospiceEmail', ''));
            }
        }
    };

    useEffect(() => {
        (async () => {
            dispatch(getPhysiciansList());
            dispatch(getHospitalsList());
            dispatch(getHealthAgenciesList());
            dispatch(getHospiceAgenciesList());
            dispatch(HttpsAction({
                apiSection: 'client',
                apiName: 'healthcareInformation',
                actionType: 'GET_HEALTHCAER_INFO',
                reduxKeyName: 'healthcareInformation',
                queryParameter: `/${clientId}`,
                reducerName: 'CLIENT',
            }))
        })();
    }, []);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-12">
                        <div className="col-6 position-relative">
                            <div className="mb-4 input-field-main">
                                <label>Primary Care Physician </label>
                                <Field
                                  className="form-control mk-wi"
                                  name="primaryCarePhysicianId"
                                  component={CustomDropDownNewField}
                                  onChange={({target}) => handlePhysicianChanged(target)}
                                >
                                    <option value="">Please Select</option>
                                    {(physiciansArray || []).map((res, index) =>
                                      <option key={index} value={res.id}>{res.physicianFullName}</option>
                                    )}
                                </Field>
                            </div>
                            <div className="position-absolute" style={{top: '-5px', right: '1px'}}>
                                <button
                                  type="button"
                                  className="view_report d-flex btn-view-repo align-items-center gap-2 px-3 py-1"
                                  onClick={() => setShowPhysicianCreateModal(true)}
                                >
                                    <i className="bi bi-plus-lg"></i>
                                    Create
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="mb-4 input-field-main">
                            <label>Physician Type </label>
                            <Field
                              className="form-control mk-wi"
                              name="physicianTypeId"
                              component={CustomDropDownField}
                            >
                                <option value="">Please Select</option>
                                {(physicianTypesArray || []).map((res, index) =>
                                  <option key={index} value={res.id}>{res.name}</option>
                                )}
                            </Field>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="mb-4 input-field-main">
                            <Field
                                className="form-control mk-wi"
                                name="physicianPhone"
                                label="Physician Phone"
                                type="text"
                                component={CustomInputField}
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="mb-4 input-field-main">
                            <Field
                                className="form-control mk-wi"
                                name="physicianEmail"
                                label="Physician Email"
                                type="text"
                                component={CustomInputField}
                                // validate={emailValidation}
                            />
                        </div>
                    </div>
                    <div className="col-6 position-relative">
                        <div className="mb-4 input-field-main">
                            <label>Preferred Hospital </label>
                            <Field
                                className="form-control mk-wi"
                                name="preferredHospitalId"
                                component={CustomDropDownField}
                                onChange={({target}) => handleHospitalChanged(target, 'hospital')}
                            >
                                <option value="">Please Select</option>
                                {(hospitalsArray || []).map((res, index) =>
                                    <option key={index} value={res.id}>{res.name}</option>
                                )}
                            </Field>
                        </div>
                        <div className="position-absolute" style={{top: '-5px', right: '12px'}}>
                            <button
                              type="button"
                              className="view_report d-flex btn-view-repo align-items-center gap-2 px-3 py-1"
                              onClick={() => {
                                  setIsCreatingHospital(true);
                                  setIsCreatingHealthAgency(false);
                                  setIsCreatingHospiceAgency(false);
                                  setModalEntityName('Hospital')
                                  setShowAgencyCreateModal(true);
                              }}
                            >
                                <i className="bi bi-plus-lg"></i>
                                Create
                            </button>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="mb-4 input-field-main">
                            <Field
                              className="form-control mk-wi"
                              name="hospitalEmail"
                              label="Hospital Email"
                              type="text"
                              // validate={emailValidation}
                              component={CustomInputField}
                            />
                        </div>
                    </div>

                    <div className="col-6 position-relative">
                        <div className="mb-4 input-field-main">
                            <label>Preferred Home Health Agency </label>
                            <Field
                              className="form-control mk-wi"
                              name="preferredHomeHealthAgencyId"
                              component={CustomDropDownField}
                              onChange={({target}) => handleHospitalChanged(target, 'health-agency')}
                            >
                                <option value="">Please Select</option>
                                {(healthAgenciesArray || []).map((res, index) =>
                                  <option key={index} value={res.id}>{res.name}</option>
                                )}
                            </Field>
                        </div>
                        <div className="position-absolute" style={{top: '-5px', right: '12px'}}>
                            <button
                              type="button"
                              className="view_report d-flex btn-view-repo align-items-center gap-2 px-3 py-1"
                              onClick={() => {
                                  setIsCreatingHospital(false);
                                  setIsCreatingHealthAgency(true);
                                  setIsCreatingHospiceAgency(false);
                                  setModalEntityName('Health Agency')
                                  setShowAgencyCreateModal(true);
                              }}
                            >
                                <i className="bi bi-plus-lg"></i>
                                Create
                            </button>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="mb-4 input-field-main">
                            <Field
                              className="form-control mk-wi"
                              name="homeHealthAgencyEmail"
                              label="Home Health Agency Email"
                              type="text"
                              // validate={emailValidation}
                              component={CustomInputField}
                            />
                        </div>
                    </div>

                    <div className="col-6 position-relative">
                        <div className="mb-4 input-field-main">
                            <label>Preferred Hospice Agency </label>
                            <Field
                              className="form-control mk-wi"
                              name="preferredHospiceAgencyId"
                              component={CustomDropDownField}
                              onChange={({target}) => handleHospitalChanged(target, 'hospice-agency')}
                            >
                                <option value="">Please Select</option>
                                {(hospiceAgenciesArray || []).map((res, index) =>
                                  <option key={index} value={res.id}>{res.name}</option>
                                )}
                            </Field>
                        </div>
                        <div className="position-absolute" style={{top: '-5px', right: '12px'}}>
                            <button
                              type="button"
                              className="view_report d-flex btn-view-repo align-items-center gap-2 px-3 py-1"
                              onClick={() => {
                                  setIsCreatingHospital(false);
                                  setIsCreatingHealthAgency(false);
                                  setIsCreatingHospiceAgency(true);
                                  setModalEntityName('Hospice Agency')
                                  setShowAgencyCreateModal(true);
                              }}
                            >
                                <i className="bi bi-plus-lg"></i>
                                Create
                            </button>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="mb-4 input-field-main">
                            <Field
                              className="form-control mk-wi"
                              name="hospiceEmail"
                              label="Hospice Agency Email"
                              type="text"
                              // validate={emailValidation}
                              component={CustomInputField}
                            />
                        </div>
                    </div>

                </div>
                <div
                  className="d-flex justify-content-center"
                  style={{marginTop: "40px"}}
                >
                    <PrimaryButton
                      type="submit"
                      className="btn-view-repo gap-2 px-5 py-2"
                      text={"Save"}
                        onClick={() => undefined}
                    />
                </div>
            </form>
            {showPhysicianCreateModal && (
              <AddPhysicianModal
                isModalOpen={showPhysicianCreateModal}
                closeModal={async (recordCreated, data) => {
                    setShowPhysicianCreateModal(false);
                    if (recordCreated) {
                        await dispatch(getPhysiciansList());
                        dispatch(change("basicInformationForm", 'primaryCarePhysicianId', data.id.toString()));
                        dispatch(change("basicInformationForm", 'physicianTypeId', data.physicianTypeId));
                        dispatch(change("basicInformationForm", 'physicianEmail', data.email));
                        dispatch(change("basicInformationForm", 'physicianPhone', data.phone));
                    }
                }}
                modalTitle="Add Physician"
                agencyId={agencyId}
              />
            )}
            {showAgencyCreateModal && (
              <AddAgencyModal
                isModalOpen={showAgencyCreateModal}
                closeModal={async (recordCreated, data) => {
                    setShowAgencyCreateModal(false);
                    if (recordCreated) {
                        if (isCreatingHospital) {
                            await dispatch(getHospitalsList());
                            dispatch(change("basicInformationForm", 'preferredHospitalId', data.id.toString()));
                            dispatch(change("basicInformationForm", 'hospitalEmail', data.email));
                        }
                        if (isCreatingHealthAgency) {
                            await dispatch(getHealthAgenciesList());
                            dispatch(change("basicInformationForm", 'preferredHomeHealthAgencyId', data.id.toString()));
                            dispatch(change("basicInformationForm", 'homeHealthAgencyEmail', data.email));
                        }
                        if (isCreatingHospiceAgency) {
                            await dispatch(getHospiceAgenciesList());
                            dispatch(change("basicInformationForm", 'preferredHospiceAgencyId', data.id.toString()));
                            dispatch(change("basicInformationForm", 'hospiceEmail', data.email));
                        }
                    }
                }}
                modalEntityName={modalEntityName}
                agencyId={agencyId}
                isHospital={isCreatingHospital}
                isHealthAgency={isCreatingHealthAgency}
                isHospiceAgency={isCreatingHospiceAgency}
              />
            )}
        </>
    )
};

const mapStateToProps = state => {
    const { auth, common, client: { healthcareInformation: { data = null } = { data: null } } } = state;

    return {
        isAdmin: auth?.authData?.isAdmin || false,
        agencyId: auth?.authData?.agencyId || auth.agencyId,
        physiciansArray: common?.physiciansList || [],
        hospitalsArray: common?.hospitalsList || [],
        healthAgenciesArray: common?.healthAgenciesList || [],
        hospiceAgenciesArray: common?.hospiceAgenciesList || [],
        initialValues: {
            primaryCarePhysicianId: data?.physicianId?.toString() || '',
            physicianTypeId: data?.physicianTypeId?.toString() || '',
            physicianPhone: data?.physicianPhone?.toString() || '',
            physicianEmail: data?.physicianEmail?.toString() || '',
            preferredHospitalId: data?.preferredHospitalId?.toString() || '',
            hospitalEmail: data?.preferredHospitalEmail?.toString() || '',
            preferredHomeHealthAgencyId: data?.preferredHealthAgencyId?.toString() || '',
            homeHealthAgencyEmail: data?.preferredHealthAgencyEmail?.toString() || '',
            preferredHospiceAgencyId: data?.preferredHospiceAgencyId?.toString() || '',
            hospiceEmail: data?.preferredHospiceAgencyEmail?.toString() || '',
        }
    }
}

export default connect(mapStateToProps)(
    reduxForm({form: "basicInformationForm", enableReinitialize: true})(HealthcareInformation)
);
