import React, {useEffect, useRef, useState} from "react";
import ContentHeader from "../index";
import classes from "../AdminTabs.module.css";
import Table from "../../../../../sharedComponents/table/Table";
import Pagination from "../../../../../sharedComponents/pagination";
import {pageLimit} from "../../../../../appConstants";
import PostedJobMoreOptionDropdown from "./MoreOptionDropdown";
import * as FileSaver from "file-saver";
import XLSX from "xlsx/dist/xlsx.full.min";
import PrimaryButton from "../../../../../sharedComponents/buttons/PrimaryButton";
import CustomDialog from "../../../../../sharedComponents/CustomDialog";
import {useParams} from "react-router";
import {debounce} from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {HttpsAction} from "../../../../../commonApiFunction/httpsAction";

const PostedJobs = () => {

    const exportRef = useRef(null);
    const dispatch = useDispatch(null);
    const {tabId, agencyId} = useParams();

    const adminJobs = useSelector(state => state.admin?.adminJobs?.data);
    const allAdminJobs = useSelector(state => state.admin?.allAdminJobs?.data);

    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [date, setDate] = useState({fromDate: null, toDate: null});

    const transformedArray = (adminJobs?.rows || [])?.map((item, index) => {
        return {
            jobTitle: item?.jobTitle,
            startDate: item?.startDateFormatUs,
            endDate: item?.endDateFormatUs,
            jobID: item?.jobId,
            budget: item?.budgetAmount,
            employmentType: item?.employmentType,
            actions: <PostedJobMoreOptionDropdown {...item}/>
        }
    });

    const exportTransformedArray = (allAdminJobs || [])?.map((item, index) => {
        return {
            jobTitle: item?.jobTitle,
            startDate: item?.startDateFormatUs,
            endDate: item?.endDateFormatUs,
            jobID: item?.jobId,
            budget: item?.budgetAmount,
            employmentType: item?.employmentType,
        }
    });

    const serviceHeaderData = [
        {
            title: "Job ID",
            dataIndex: "jobID",
        },
        {
            title: "Job Title",
            dataIndex: "jobTitle",
        },
        {
            title: "Start Date",
            dataIndex: "startDate",
        },
        {
            title: "End Date",
            dataIndex: "endDate",
        },
        {
            title: "Employment Type",
            dataIndex: "employmentType",
        },
        {
            title: "Budget",
            dataIndex: "budget",
        },
        {
            title: "",
            dataIndex: "actions",
        }
    ];

    const handlePageChange = (page) => {
        dispatch(HttpsAction({
            apiSection: 'admin',
            apiName: 'jobs',
            queryParameter: `/${agencyId}?page=${page}&searchText=${search}`,
            reduxKeyName: 'adminJobs',
            reducerName: 'ADMIN',
            actionType: 'GET_ADMIN_JOBS'
        }))
    };

    const handleModal = async () => {
        await dispatch(HttpsAction({
            apiSection: 'admin',
            apiName: 'jobs',
            queryParameter: `/${agencyId}?doPaging=false`,
            reduxKeyName: 'allAdminJobs',
            reducerName: 'ADMIN',
            actionType: 'GET_ADMIN_ALL_JOBS'
        }));
        setOpen(!open)
    };

    const handleDateFilter = ({from, to}) => {
        setDate({fromDate: from, toDate: to});
        dispatch(HttpsAction({
            apiSection: 'admin',
            apiName: 'jobs',
            queryParameter: `/${agencyId}?fromDate=${from}&toDate=${to}`,
            reduxKeyName: 'adminJobs',
            reducerName: 'ADMIN',
            actionType: 'GET_ADMIN_JOBS'
        }))
    };

    const handleExport = () => {
        const bookType = 'xlsx';
        const wb = XLSX.utils.table_to_book(exportRef.current, {sheet: "Sheet JS"});
        const wbout = XLSX.write(wb, {bookType, bookSST: true, type: 'array'});

        const blob = new Blob([wbout], {type: 'application/octet-stream'});
        const file = `postedJob.xlsx`;

        FileSaver.saveAs(blob, file);
        setOpen(false);
    };

    const handleSearch = debounce((value) => {
        setSearch(value);
        let queryParameter =  `/${agencyId}?searchText=${value}`;
        if (date.fromDate != null) {
            queryParameter += `&fromDate=${date.fromDate}&toDate=${date.toDate}`
        }
        console.log({queryParameter, date})
        dispatch(HttpsAction({
            apiSection: 'admin',
            apiName: 'jobs',
            queryParameter,
            reduxKeyName: 'adminJobs',
            reducerName: 'ADMIN',
            actionType: 'GET_ADMIN_JOBS'
        }))
    }, 500);

    useEffect(() => {
        dispatch(HttpsAction({
            apiSection: 'admin',
            apiName: 'jobs',
            queryParameter: `/${agencyId}`,
            reduxKeyName: 'adminJobs',
            reducerName: 'ADMIN',
            actionType: 'GET_ADMIN_JOBS'
        }))
    }, []);

    return (
        <>
            <div className={'p-3'}>
                <ContentHeader
                    title={'Jobs'}
                    handleExport={handleModal}
                    handleDateFilter={handleDateFilter}
                    onSearch={({target}) => handleSearch(target.value)}
                />
                <div className={classes.contentBox}>
                    <Table headers={serviceHeaderData} rows={transformedArray}/>
                    <Pagination
                        currentPage={adminJobs?.page}
                        totalRecords={adminJobs?.total}
                        limit={pageLimit}
                        onPageChange={handlePageChange}
                    />
                </div>
            </div>

            <CustomDialog
                maxWidth={'lg'}
                onClose={() => handleModal('closeModal')}
                open={open}
                dialogContentRef={exportRef}
                actions={<PrimaryButton
                    type="button"
                    className="btn-view-repo gap-2 px-5 py-2"
                    text={"Export"}
                    onClick={handleExport}
                />}
                title={'Posted Jobs'}>
                <div className={'p-4'}>
                    <Table headers={serviceHeaderData} rows={exportTransformedArray}/>
                </div>
            </CustomDialog>
        </>
    )
};

export default PostedJobs;
