import React, {useState} from 'react'
import { connect } from "react-redux";
import {change, Field, reduxForm} from "redux-form";
import { useDispatch } from 'react-redux';
import CustomInputField from '../sharedComponents/reduxfields/customInputField'
import CustomDropDownField from '../sharedComponents/reduxfields/customDropDown'
import {required, emailValidation} from '../helpers/validation'
import Modal from '../sharedComponents/Modal'
import {postNurseAction} from '../services/NursesProfile'
import {postCreateSpouse} from "../services/client";
import RadioOptionsComponent from "../sharedComponents/radioOptions";

const AddSpouseModal = props => {

    const { handleSubmit } = props;
    const dispatch = useDispatch();

    const { isModalOpen, closeModal, modalTitle, gendersArray, clientId} = props;

    const onSubmit = async (values) => {
        let payload = Object.assign({}, values);
        payload.firstName = values.firstName
        payload.lastName = values.lastName
        payload.email = values.email
        payload.genderId = parseInt(values.genderId)
        dispatch(postCreateSpouse(payload, clientId, 'Spouse Added Successfully', () => {
            closeModal(true);
        }))
    }

    return (
      <Modal isOpen={isModalOpen} onClose={() => closeModal(false)} modalTitle={modalTitle}>
        <form onSubmit={handleSubmit(onSubmit)} >
            <div className="form-group form-label">
                <div className="row">
                    <div className="col-sm-12">
                        <Field
                            className="form-control mk-wi"
                            name="firstName"
                            label="First Name"
                            type="text"
                            component={CustomInputField}
                            requiredMark={true}
                            validate={required}
                        ></Field>
                    </div>
                </div>
            </div>
            <div className="form-group form-label pt-2">
                <div className="row">
                    <div className="col-sm-12">
                        <Field
                            className="form-control mk-wi"
                            name="lastName"
                            label="Last Name"
                            type="text"
                            component={CustomInputField}
                            requiredMark={true}
                            validate={required}
                        ></Field>
                    </div>
                </div>
            </div>
            <div className="form-group form-label pt-2">
                <div className="row">
                    <div className="col-sm-12">
                        <Field
                            className="form-control mk-wi"
                            name="email"
                            label="Email"
                            type="email"
                            component={CustomInputField}
                            requiredMark={true}
                            validate={[required,emailValidation]}
                        ></Field>
                    </div>
                </div>
            </div>
            <div className="form-group form-label pt-2">
                <div className="row">
                    <div className="col-sm-12">
                        {/*<label>Gender <span className="mandatory-field">*</span></label>
                        <Field
                            className="form-control"
                            name="genderId"
                            component={CustomDropDownField}
                            validate={required}
                            requiredMark={true}
                        >
                            <option value="">Please select</option>
                            {gendersArray?.map((res, index)=>{
                            return <option key={index} value={res.id}>{res.name}</option>
                            })}
                        </Field>*/}
                        <Field
                          className="form-control1 input-field-main"
                          name="genderId"
                          // label="Please select your gender"
                          label="Gender"
                          component={RadioOptionsComponent}
                          requiredMark={true}
                          // validate={(val) => console.log('VALIDATION- value', val)}
                          validate={required}
                          optionsList={gendersArray}
                          handleGenderChanged={({target}) => {
                              if (target?.value != null) dispatch(change('AddSpouseModal', 'genderId', +target.value));
                          }}
                        />
                    </div>
                </div>
            </div>
            <div className="modal-footer justify-content-center pt-4 pb-0 gap-2 mt-4">
                <button type="button" className="btn btn-secondary" onClick={() => closeModal(false)}>Cancel</button>
                <button type="submit" className="btn btn-primary">Create</button>
            </div>
        </form>
      </Modal>
    )
}

export default connect()(
  reduxForm({ form: "AddSpouseModal"})(AddSpouseModal)
);
