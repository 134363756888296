import React, {useState} from 'react';
import PaginationSummary from "../sharedComponents/pagination/PaginationSummary";
import {NavLink, Outlet} from "react-router-dom";
import {defaultPaginationTab} from "../appConstants";
import WalletBalanceComponent from "../Agency/components/shared/WalletBalance";
import AddFundsComponent from "../Agency/components/shared/AddFunds";

const transactionTabsList = [
  {
    text: "Payout History",
    path: "payout-history",
  },
  {
    text: "Subscribed Plans",
    path: "subscribed-plans",
  },
];

const TransactionsLayout = () => {
  const pathPrefix = '/agency';

  const [paginationTab, setPaginationTab] = useState(defaultPaginationTab);
  const [reloadBalance, setReloadBalance] = useState(false);

  return (
    <div className="app-main__outer">
      <div className="main-service-request-page">
        <div className="app-main__inner service-request bg-white page-heading">
          <PaginationSummary heading="All Transactions" listInfo={paginationTab} showTotalWithHeading={false}/>

          <div className="row align-items-center">
            <div className="col-sm-9 col-12 pl-1">
              <ul className="nav nav-tabs pt-1" id="myTab" role="tablist">
                {transactionTabsList.map(({text, path}, idx) =>
                  <li key={idx} className="nav-item" role="presentation">
                    <NavLink to={`${pathPrefix}/${path}`} className={'nav-link tab-nav-link-style'}>{text}</NavLink>
                  </li>)}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-light">
        <div className="bg-light-gray px-3 py-3">
          <div className="row">
            <div className="container-fluid">
              <div className="tab-content" id="pills-tabContent">
                <div>
                  <div className="py-history-content d-flex align-items-center gap-3 gap-lg-0 justify-content-between my-4 flex-wrap">
                    <WalletBalanceComponent reloadBalance={reloadBalance} setReloadBalance={setReloadBalance}/>
                    <AddFundsComponent setReloadBalance={setReloadBalance} size="lg"/>
                  </div>
                  <Outlet context={{ setPaginationTab }}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionsLayout;
