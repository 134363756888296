import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useDispatch} from 'react-redux';
import Table from '../../../sharedComponents/table/Table'
import {useLocation, useNavigate, useOutletContext} from "react-router-dom";
import {getAllJobs, postedJobActions} from "../../../services/jobPosting";
import WarningModal from "../../../sharedComponents/warningModal";
import CustomTable from "../../../sharedComponents/customTable";
import Pagination from "../../../sharedComponents/pagination";
import {chartingRelatedText, jobsTabUrls, pageLimit} from "../../../appConstants";
import apiCallHelperFunctions from "../../../helpers/api.call.helpers";
import rowHelperFunctions from "../../../helpers/row.helpers";
import CommonPaginationNew from "../../../sharedComponents/pagination/CommonPaginationNew";


const PostedJobsPending = ({/*searchText, */postedJobsPendingList/*, currentPage*/}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { searchText, setPaginationTab } = useOutletContext();

    const [currentPage, setCurrentPage] = useState(0);
    const [jobIdForPublish, setJobIdForPublish] = useState(0);
    const [jobIdForDelete, setJobIdForDelete] = useState(0);
    const [showPublishConfirmation, setShowPublishConfirmation] = useState(false);

    const transformedArray = postedJobsPendingList?.rows?.map(item => {
        // const hasValidChartingId = item?.templateTypeId != null && item.templateTypeId > 0; // && item?.chartingId != null && item.chartingId > 0;
        let { hasValidChartingId, relatedInfoList} = rowHelperFunctions.getRelatedInfo(item, false, false);
        return {
            'id': item.jobId,
            // 'jobTitle': item?.certifications?.[0]?.certificate,
            // 'jobTitle': item?.jobTitle,
            'jobTitle': <div className="position-relative1 p-0 m-0">
                <span>{item?.jobTitle}</span>
                {/*<div className="position-absolute top-25 start-50 translate-middle d-flex gap-2">
                    {hasValidChartingId && chartingRelatedText && <div className="cursor-pointer1" onClick={() => undefined}>
                        <span className={`badge rounded-pill text-wrap lh-1 text-bg-warning`}>{chartingRelatedText}</span>
                    </div>}
                </div>*/}
                {(relatedInfoList || []).length > 0 && <div className="position-absolute top-25 start-50 translate-middle cursor-pointer w-100 d-flex justify-content-center gap-1">{relatedInfoList.map(({relatedTextColor, relatedText, relatedLink, relatedState}, index) => <span key={index} onClick={() => {
                    if (relatedLink != null) navigate(relatedLink, { state: relatedState });
                }} className={`badge rounded-pill text-wrap lh-1 text-bg-${relatedTextColor}`}>{relatedText}</span>)}</div>}
            </div>,
            'hours': item.totalHours,
            'location': item.jobLocation || 'N/A',
            'shiftTimings': item.shiftTimings,
            'expiry': item.endDateFormatUs,
        }
    });

    const serviceHeaderData = [
        {
            title: "Job Title",
            dataIndex: "jobTitle",
        },
        {
            title: "Hours",
            dataIndex: "hours",
            width: '55px',
        },
        {
            title: "Location",
            dataIndex: "location",
        },
        {
            title: "Shift Timings",
            dataIndex: "shiftTimings",
            width: '120px',
        },
        {
            title: "Expiry",
            dataIndex: "expiry",
            width: '100px',
        },
    ];

    const publishJob = id => {
        const message = "Are you sure you want to publish this job ?"
        // const apiEndPoint = "publishJob"
        // const payload = {id : id}
        // openConfirmationDialog(payload, message, apiEndPoint);
        setJobIdForDelete(0);
        setJobIdForPublish(id);
        // openConfirmationDialog(message);
        setShowPublishConfirmation(message)
    }

    const editJob = id => {
        navigate(`/agency/postjob/${id}`)
    }

    const deleteJob = id => {
        const message = "Are you sure you want to delete this job ?"
        // const apiEndPoint = "deleteJob"
        // const payload = {id : id}
        setJobIdForPublish(0);
        setJobIdForDelete(id)
        // openConfirmationDialog(payload, message, apiEndPoint);
        // openConfirmationDialog(message);
        setShowPublishConfirmation(message)
    }

    const actionButton = [
        {
            actionName: 'Publish',
            buttonAction: (id) => publishJob(id),
            actionClass: 'btn-primary px-1',
            iconClass: 'fa-user-plus',
        },
        {
            actionName: 'Edit',
            buttonAction: (id) => editJob(id),
            actionClass: 'btn-primary px-1',
            iconClass: 'fa-trash'
        },
        {
            actionName: 'Delete',
            buttonAction: (id) => deleteJob(id),
            actionClass: 'btn-danger px-1',
            iconClass: 'fa-trash'
        }
    ];

    const handleDisagree = () => {
        setShowPublishConfirmation(false)
    };

    const handleAgree = () => {
        if (jobIdForPublish > 0) {
            dispatch(postedJobActions({id: jobIdForPublish}, 'publishJob', function () {
                setJobIdForPublish(0);
                // dispatch(getAllJobs('postedJobs', currentPage));
                navigate('/agency/servicerequest/postedjobsactive');
            }));
        }

        if (jobIdForDelete > 0) {
            dispatch(postedJobActions({id: jobIdForDelete}, 'deleteJob', function () {
                setJobIdForDelete(0);
                dispatch(getAllJobs('postedJobs', currentPage));
            }));
        }
        setShowPublishConfirmation(false);
    };

    // useEffect(() => {
    //     dispatch(getAllJobs('postedJobs', currentPage));
    // }, [currentPage]);
    //
    // useEffect(() => {
    //     dispatch(getAllJobs('postedJobs', 1, searchText));
    // }, [searchText]);

    useEffect(() => {
        if (postedJobsPendingList != null && postedJobsPendingList?.rows?.length != null) {
            setPaginationTab(postedJobsPendingList);
        }
    }, [postedJobsPendingList]);

    useEffect(() => {
        if (searchText != null && searchText.length > 0)
            dispatch(getAllJobs('postedJobs', 1, searchText));
        else
            dispatch(getAllJobs('postedJobs', currentPage));
    }, [currentPage, searchText]);

    return (
      <>
          {/*<div className="container-fluid bg-light">
              <div className="bg-light-gray px-3 py-3">
                  <div className="row">
                      <div className="container-fluid">
                          <div className="tab-content" id="pills-tabContent">
                              <div className="bg-white mt-3">
                                  <div className="col-sm-3">
                                      <div className="d-flex gap-2 innerTabing">
                                          <>
                                              <button type="button" className="btn btn-sm text-nowrap btn-primary"
                                                      onClick={() => navigate("/agency/servicerequest/postedjobs")}>Pending
                                              </button>
                                              <button type="button" className="btn btn-sm"
                                                      onClick={() => navigate("/agency/servicerequest/postedjobsactive")}>Active
                                              </button>
                                              <button type="button" className="btn btn-sm"
                                                      onClick={() => navigate("/agency/servicerequest/postedjobsaccepted")}>Accepted
                                              </button>
                                              <button type="button" className="btn btn-sm"
                                                      onClick={() => navigate("/agency/servicerequest/postedjobsexpired")}>Expired
                                              </button>
                                              <button type="button" className="btn btn-sm text-nowrap"
                                                      onClick={() => navigate("/agency/servicerequest/postedjobsinprogress")}>In-Progress
                                              </button>
                                              <button type="button" className="btn btn-sm"
                                                      onClick={() => navigate("/agency/servicerequest/postedjobscompleted")}>Completed
                                              </button>
                                          </>
                                          {apiCallHelperFunctions.getTabButtons('postedJobs', jobsTabUrls, navigate, location.pathname)}
                                      </div>
                                  </div>*/}
                                  <CustomTable headers={serviceHeaderData} rows={transformedArray} actions={actionButton} actionColWidth="180px" noDataFoundMessage="No jobs found!" />
                              {/*</div>
                          </div>*/}
                          {/*<Pagination
                            currentPage={currentPage}
                            totalRecords={postedJobsPendingList?.total}
                            limit={pageLimit}
                            onPageChange={(page) => {
                                setCurrentPage(page);
                            }}
                          ></Pagination>*/}
                          <CommonPaginationNew data={postedJobsPendingList} onPageChange={(page) => setCurrentPage(page)} />
                      {/*</div>
                  </div>
              </div>
          </div>*/}

          <WarningModal
            open={!!showPublishConfirmation}
            desc={showPublishConfirmation}
            handleAgree={handleAgree}
            handleDisagree={handleDisagree}
            onClose={() => setShowPublishConfirmation(false)}
          />
      </>
    );

}

const mapStateToProps = state => {
    const {serviceRequest} = state;
    return {
        postedJobsPendingList: serviceRequest?.postedJobs || {},
    }
}

export default connect(mapStateToProps)(PostedJobsPending)
