import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from "react-redux";
import TableTitle from "../Dashboard/TableTitle";
import {Card} from "react-bootstrap";
import Table from "../../../sharedComponents/table/Table";
import Pagination from "../../../sharedComponents/pagination";
import {getAgenciesData} from "../../redux/actions/AdminActions";

const AgenciesOverview = ({title, subTitle, apiKey, data}) => {
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);

  const serviceHeaderData = [
    {
      title: "S/N",
      dataIndex: "sn",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Email address",
      dataIndex: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
    },
    {
      title: "Address",
      dataIndex: "address",
    },
    {
      title: "Verification",
      dataIndex: "verification",
    }
  ];

  const transformedArray = (data?.rows || [])?.map((item, index) => {
    return {
      id: item.id,
      sn: item?.id,
      name: item?.name,
      email: item?.email,
      phone: item?.phoneNumber,
      address: item?.location || "N/A",
      subscription: item?.subscriptionPlan || "N/A",
      verification: item.isVerified ? "Verified" : "Unverified",
    }
  });

  function handlePageChange(page) {
    setCurrentPage(page);
  }

  useEffect(() => {
    setCurrentPage(1);
  }, [apiKey]);

  useEffect(() => {
    dispatch(getAgenciesData(apiKey, currentPage));
  }, [apiKey, currentPage]);

  return (
    <>
      <TableTitle
        title={title}
        subTitle={subTitle}
        isFullView={false}
      />
      <Card>
        <Card.Body>
          <Table headers={serviceHeaderData} rows={transformedArray} />
          <Pagination
            currentPage={data?.page}
            totalRecords={data?.total}
            limit={data?.pageSize}
            onPageChange={handlePageChange}
          />
        </Card.Body>
      </Card>
    </>
  );
};


const mapStateToProps = (state, ownProps) => {
    const { admin } = state;
// console.log('test- apiKey', ownProps?.apiKey, admin);
    if (ownProps?.apiKey === 'agencies-list') {
        return {
            data: admin?.agenciesList,
        };
    }
    if (ownProps?.apiKey === 'verified-agencies-list') {
        return {
            data: admin?.verifiedAgenciesList,
        };
    }
    if (ownProps?.apiKey === 'unverified-agencies-list') {
        return {
            data: admin?.unverifiedAgenciesList,
        };
    }
    return {};
};

export default connect(mapStateToProps)(AgenciesOverview);
