import React, {useCallback, useEffect, useState} from 'react';
import Modal from "../../../sharedComponents/Modal";
import {addFundAction, getAgencyWalletDetailsAction, getPayoutHistoryAction} from "../../../services/transaction";
import {toastr} from "react-redux-toastr";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {getPaypalAmountWithChargesAdded, parseTokenAndGetData} from "../../../helpers/apiMethods";
import storageManager from "../../../helpers/storageManager";
import {useGlobalMessageEvent} from "../../../utils/hooks/useWindowEvent";
import numberHelperFunctions from "../../../helpers/number.helpers";

const AddFundsComponent = ({ setReloadBalance, size = 'fit', btnClassName = 'fund-btn', style = null }) => {
  const dispatch = useDispatch();
  // const location = useLocation();

  const addFundRequestInstance = useSelector((state) => state.transaction?.addFund ?? {});
  const addFundRequestCaptureInstance = useSelector((state) => state.transaction?.addFundPaymentOrder ?? {});

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [amount, setAmount] = useState("");
  const [error, setError] = useState(null); // State variable for error message
  const [loadedOnce, setLoadedOnce] = useState(false);


  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleOpenModel = () => {
    setModalIsOpen(true);
    setAmount("");
    setError(null)
  };

  const handleAddFund = async (e) => {
    e.preventDefault();
    // Check if amount is entered
    if (!amount || amount.trim() === "") {
      setError("Please enter an amount");
      return;
    }
    // Perform add funds operation with the amount
    let payload = {
      amountRequired: numberHelperFunctions.round(parseFloat(amount)),
      actualAmount: getPaypalAmountWithChargesAdded(parseFloat(amount)),
    }
    try {
      await dispatch(addFundAction("addFund", payload));

      // no need to get the paypal URL here, it'll be done in useEffect

      setAmount("");
      setModalIsOpen(false);
    } catch (error) {
      console.error('Error while upgrading subscription', error);
    }
  };

  const paymentSuccessHandler = (e) => {
// console.log('test- paymentSuccessHandler', e)
// console.log('test- paymentSuccessHandler data', e.data)
// alert(JSON.stringify(e.data.type))
//       if (e.data.type === "payment-success" || e.data.type === "payment-cancelled") {
//         setLoadedOnce(false);
//       }
    if (e.data.type === "payment-success") {
//         const decodedTokenData = parseTokenAndGetData().data;
// // alert(decodedTokenData.walletId)
//         if (decodedTokenData.walletId > 0)
//           dispatch(getAgencyWalletDetailsAction("agencyWalletDetails", decodedTokenData.walletId));
//         dispatch(
//           getPayoutHistoryAction(
//             "agencyTransaction",
//             // { /*fromDate: validFromDate, toDate: validToDate, */searchTerm, currentPage},
//           )
//         );

      const amountAdded = e.data.amount;
      storageManager.saveUpdateBalance(amountAdded, true);
      if (setReloadBalance != null) setReloadBalance(true);
    }
  };

  useEffect(() => {
// console.log('test- addFundRequestInstance', loadedOnce, addFundRequestInstance, location);
    if (loadedOnce && addFundRequestInstance != null && JSON.stringify(addFundRequestInstance) !== '{}') {
      // if (addFundRequestInstance.success) {
      //   const paypalUrl = addFundRequestInstance?.orderData?.url;
      const paypalUrl = addFundRequestInstance?.orderData?.approvalUrl;
      if (paypalUrl != null && paypalUrl.length > 0) {
        let popupHeight = 600;
        let popupWidth = 800;

        let popupTop = window.screenY + (((window.innerHeight || document.documentElement.clientHeight) - popupHeight) / 2);
        let popupLeft = window.screenX + (((window.innerWidth || document.documentElement.clientWidth) - popupWidth) / 2);

        // const addFundPaymentData = {
        //   agencyWalletId: addFundRequestInstance?.walletData?.id,
        //   amount: amount,
        //   orderId: addFundRequestInstance?.orderData?.orderId,
        //   accessToken: addFundRequestInstance?.orderData?.authData?.access_token,
        //   paypalUrl,
        // };
        // storageManager.savePaypalOrderData(addFundPaymentData, true);

        // window['orderId'] = addFundRequestInstance?.orderData?.orderId;
        // alert(addFundRequestInstance?.orderData?.orderId)
        // Open the URL in a new popup window, centered:
        let win = window.open(paypalUrl, 'PopupWindow', `height=${popupHeight},width=${popupWidth},top=${popupTop},left=${popupLeft},resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes`);
        // let win = window.open('/paypal-payment', 'PopupWindow', `height=${popupHeight},width=${popupWidth},top=${popupTop},left=${popupLeft},resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes`);
        // win['addFundPaymentData'] = addFundPaymentData;
//           win.onload = function() {
//             alert('loaded');
//             const addFundPaymentDataStr = window.opener['addFundPaymentDataStr'];
// console.log('test- addFundPaymentDataStr', addFundPaymentDataStr);
//             // storageManager.savePaypalOrderData(addFundPaymentData);
//           }
//           setTimeout(() => {
//             win.opener.postMessage(addFundPaymentData);
//           }, 1500);

        // Check if Popup Window is blocked
        if (!win || win.closed || typeof win.closed === 'undefined') {
          // POPUP BLOCKED
          console.log('popup blocked');
          // } else {
          //   // let timer = setInterval(() => {
          //   setTimeout(() => {
          //     if (win.closed) {
          //       // clearInterval(timer);
          //       dispatch(addFundPaymentOrderAction("addFundPaymentOrder", addFundPaymentData));
          //     }
          //   }, 500);
        }
      } else {
        console.log('Paypal URL not received');
      }
      // }
    }
    // }, [addFundRequestInstance?.orderData?.url, dispatch]);
  }, [addFundRequestInstance]);

  useEffect(() => {
// console.log('test- addFundRequestCaptureInstance', addFundRequestCaptureInstance);
    if (addFundRequestCaptureInstance != null && JSON.stringify(addFundRequestCaptureInstance) !== '{}') {
      if (addFundRequestCaptureInstance.success) {
        const successfulPaymentId = addFundRequestCaptureInstance?.paymentId;
        toastr.success(addFundRequestCaptureInstance?.message);
      } else {
        toastr.error(addFundRequestCaptureInstance?.message);
      }
    }

  }, [addFundRequestCaptureInstance]);

  useEffect(() => {
    setLoadedOnce(true);

    // window.addEventListener("message", paymentSuccessHandler);
    //
    // return () => {
    //   window.removeEventListener("message", paymentSuccessHandler);
    // };
  }, []);

  useGlobalMessageEvent(paymentSuccessHandler);

  return (
    <>
      <button type="button" className={`${btnClassName}${size === 'lg' ? ' lg' : ''}`} onClick={handleOpenModel} style={style || undefined}>
        Add Funds
      </button>
      <Modal title={"Add Fund"} isOpen={modalIsOpen} onClose={handleCloseModal} modalTitle="Add Funds">
        <label htmlFor="amount">
          Amount:
          <input placeholder={'Enter amount here'} className="form-control hhgrsjd bg-transparent" type="number" min="0" id="amount" value={amount}
                 onChange={handleAmountChange}/>
        </label>
        {/*<span className="fs-7 text-danger">Paypal charges (6%) will be added - Actual Amount = ${getPaypalAmountWithChargesAdded(amount)}</span>*/}
        <span className="fs-7 text-danger">Paypal processing fee (6%) will be added - Payment due = ${getPaypalAmountWithChargesAdded(amount)}</span>
        <div>{error && <p style={{color: 'red'}}>{error}</p>}</div>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <button type="button" style={{marginTop: '15px'}} className="fund-btn" onClick={handleAddFund}>Send add fund request</button>
        </div>
      </Modal>
    </>
  );
};

export default AddFundsComponent;
