import React, { Fragment } from "react";

const CustomDropDownField = (field) => (
  <>
    <select
      required={field.required || false}
      onChange={field.onChange}
      className={field.className}
      disabled={field.disabled || false}
      name={field.name}
      value={field.value}
      {...field.input}
    >
      {field.children}
    </select>
  </>
);

export default CustomDropDownField;
