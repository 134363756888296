import classes from "./index.module.css";
import PrimaryButton from "../../../../sharedComponents/buttons/PrimaryButton";
import Modal from "../../../../sharedComponents/Modal";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {HttpsAction} from "../../../../commonApiFunction/httpsAction";
import {useNavigate} from "react-router-dom";

const RATING_ARRAY = [
    {
        icon: 'icon 1',
        select: true
    }, {
        icon: 'icon 2',
        select: false
    }, {
        icon: 'icon 3',
        select: false
    }, {
        icon: 'icon 4',
        select: false
    }, {
        icon: 'icon 5',
        select: false
    }
];

const PaymentSuccessModal = ({onClose, isOpen, taskDetails}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // const taskDetails = useSelector(state => state.serviceRequest?.serviceRequestTaskDetails?.data);

    const [ratingOptions, setRatingOptions] = useState(RATING_ARRAY);

    const handleSubmitRate = async () => {
        await dispatch(HttpsAction({
            method: 'POST',
            apiSection: 'serviceRequests',
            apiName: 'nurseRatingByAgency',
            data: {
                taskId: taskDetails?.id,
                ratingOutOf5: ratingOptions.filter(e => e.select === true).length,
            },
            queryParameter: `/${taskDetails?.assignedNurseId}`,
        }))
        onClose()
        navigate(`/agency/servicerequest/completeRequest`)
    }

    const handleSelectRating = (index) => {
        setRatingOptions(prevState => (prevState || []).map((value, i) => {
            return index + 1 > i ? {...value, select: true} : {...value, select: false}
        }))
    }

    return (
        <>
            <Modal onClose={() => onClose()} modalTitle={''} isOpen={isOpen}>
                <div className={'d-flex justify-content-center flex-column align-items-center gap-2'}>
                    <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M28.5013 4.66797C15.6446 4.66797 5.16797 15.1446 5.16797 28.0013C5.16797 40.858 15.6446 51.3346 28.5013 51.3346C41.358 51.3346 51.8346 40.858 51.8346 28.0013C51.8346 15.1446 41.358 4.66797 28.5013 4.66797ZM39.6546 22.6346L26.4246 35.8646C26.098 36.1913 25.6546 36.378 25.188 36.378C24.7213 36.378 24.278 36.1913 23.9513 35.8646L17.348 29.2613C16.6713 28.5846 16.6713 27.4646 17.348 26.788C18.0246 26.1113 19.1446 26.1113 19.8213 26.788L25.188 32.1546L37.1813 20.1613C37.858 19.4846 38.978 19.4846 39.6546 20.1613C40.3313 20.838 40.3313 21.9346 39.6546 22.6346Z"
                            fill="#23A26D"/>
                    </svg>
                    <div className={classes.paymentSuccess}>
                        Payment Success
                    </div>
                    <div className={classes.paymentDesc}>
                        {/*${((taskDetails?.scheduledPaymentAmount || 0) + (taskDetails?.overtimePaymentAmount || 0))} has*/}
                        ${taskDetails?.total || 0} has
                        been paid to {taskDetails?.assignedNurseFirstName}
                    </div>
                    <div className={classes.rateDesc}>
                        Please rate your experience
                    </div>
                    <div className={'d-flex gap-2'}>

                        {(ratingOptions || []).map((value, index) =>
                            <div key={index} className={classes.starParentEle} onClick={() => handleSelectRating(index)}>
                                {value.select ? <svg width="13" height="12" viewBox="0 0 13 12" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M6.7184 9.73971L9.5391 11.4457C10.0557 11.7584 10.6878 11.2962 10.5518 10.7117L9.80418 7.50354L12.2986 5.34213C12.754 4.94791 12.5093 4.20025 11.9112 4.15268L8.62832 3.874L7.34371 0.842598C7.11262 0.292051 6.32418 0.292051 6.09309 0.842598L4.80848 3.86721L1.52559 4.14588C0.927463 4.19346 0.682776 4.94111 1.13817 5.33533L3.63262 7.49674L2.88496 10.7049C2.74903 11.2894 3.38113 11.7516 3.8977 11.4389L6.7184 9.73971Z"
                                        fill="#FFB946"/>
                                </svg> : <svg width="13" height="12" viewBox="0 0 13 12" fill="none"
                                              xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M6.2809 9.73971L9.1016 11.4457C9.61817 11.7584 10.2503 11.2962 10.1143 10.7117L9.36668 7.50354L11.8611 5.34213C12.3165 4.94791 12.0718 4.20025 11.4737 4.15268L8.19082 3.874L6.90621 0.842598C6.67512 0.292051 5.88668 0.292051 5.65559 0.842598L4.37098 3.86721L1.08809 4.14588C0.489963 4.19346 0.245276 4.94111 0.700666 5.33533L3.19512 7.49674L2.44746 10.7049C2.31153 11.2894 2.94363 11.7516 3.4602 11.4389L6.2809 9.73971Z"
                                        fill="#9E9E9E"/>
                                </svg>}
                            </div>
                        )}
                    </div>
                    <div
                        className="d-flex justify-content-center"
                        style={{marginTop: "10px"}}
                    >
                        <PrimaryButton
                            type="button"
                            className={"btn-view-repo gap-2 px-4 py-2"}
                            text={"Submit"}
                            onClick={handleSubmitRate}
                        />
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default PaymentSuccessModal;
