import React, {useEffect, useState} from "react";
import CustomInputField from "../../../sharedComponents/reduxfields/customInputField";
import CustomDropDownField from "../../../sharedComponents/reduxfields/customDropDownNew";
import {getChartingData, postChartingData} from "../../../services/charting";
import {connect, useDispatch, useSelector} from "react-redux";
import {Field, reduxForm} from "redux-form";
import {minLength5, required} from "../../../helpers/validation";
import {HttpsAction} from "../../../commonApiFunction/httpsAction";
import {useQueryClient} from "@tanstack/react-query";
import {useFormTemplatesList} from "../../../services/commonNew";

const CreateInitialAssessmentModal = ({creatingForInitialAssessment, creatingForSupervisoryVisit, creatingForDischargeSummary, creatingForCarePlan,
                                          handleSubmit, onClose, isOpen, isEdit = false, agencyId, showAccessLevel}) => {

    const queryClient = useQueryClient();
    const dispatch = useDispatch();

    const editInitialAssessmentData = useSelector(state => state.charting?.editInitialAssessmentData?.data);

    const clientsList = queryClient.getQueryData(['list-patients', agencyId]);
    // const clientsList = queryClient.getQueryData(['list-patients-with-latlng', agencyId]);
    const nursesList = queryClient.getQueryData(['list-nurses', agencyId]);
    const formTemplatesList = queryClient.getQueryData(['list-form-templates', agencyId,
        creatingForInitialAssessment ? 'initialAssessment' :
          (creatingForSupervisoryVisit ? 'supervisoryVisit' :
            (creatingForDischargeSummary ? 'dischargeSummary' : (creatingForCarePlan ? 'carePlan' : '')))
    ]);


    const [titleFieldLabel, setTitleFieldLabel] = useState('');

    const onSubmit = async (data) => {
        let endpoint = null;
        if (creatingForInitialAssessment) endpoint = 'initialAssessment';
        if (creatingForSupervisoryVisit) endpoint = 'supervisoryVisits';
        if (creatingForDischargeSummary) endpoint = 'dischargeSummaries';
        if (creatingForCarePlan) endpoint = 'carePlans';
        if (isEdit) {
            dispatch(HttpsAction({
                method: 'PUT',
                apiSection: 'charting',
                apiName: 'chartingData',
                queryParameter: `/${editInitialAssessmentData.id}`,
                data: {
                    patientId: +data.patientId,
                    assessmentTitle: data.assessmentTitle,
                    nurseId: +data.nurseId,
                    accessLevel: data.accessLevel || 'Edit',
                    templateId: +data.templateId,
                    expirationDate: data?.expirationDate || null
                },
                positiveCallBack: () => {
                    dispatch(getChartingData({endPoint: endpoint || ''}));
                }
            }));
        } else {
            await dispatch(postChartingData(endpoint, {
                assessmentTitle: data.assessmentTitle,
                nurseId: Number(data.nurseId),
                patientId: Number(data.patientId),
                accessLevel: data.accessLevel || 'Edit',
                templateId: +data.templateId,
                expirationDate: data.expirationDate,
            }, function () {
                dispatch(getChartingData({endPoint: endpoint || ''}));
            }));
        }

        onClose();
    };

    useEffect(() => {
        if (creatingForInitialAssessment) setTitleFieldLabel('Assessment Title');
        if (creatingForSupervisoryVisit) setTitleFieldLabel('Visit Title');
        if (creatingForDischargeSummary) setTitleFieldLabel('Summary Title');
        if (creatingForCarePlan) setTitleFieldLabel('Plan Title');
    }, [creatingForInitialAssessment, creatingForSupervisoryVisit, creatingForDischargeSummary, creatingForCarePlan]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className={showAccessLevel ? 'col-8' : 'col-12'}>
                        <Field
                            className="form-control mk-wi"
                            name="assessmentTitle"
                            label={titleFieldLabel}
                            type="text"
                            component={CustomInputField}
                            requiredMark={true}
                            validate={[required, minLength5]}
                            showErrorsFixed={true}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className={showAccessLevel ? 'col-8' : 'col-12'}>
                            <Field
                              label="Choose a Nurse"
                              className="form-control"
                              name="nurseId"
                              component={CustomDropDownField}
                              requiredMark={true}
                              validate={required}
                              showErrorsFixed={true}
                            >
                                <option value="">Please Select</option>
                                {nursesList?.map((res, index) => {
                                    return <option key={index} value={res.id}>{res.nurseFullName}</option>
                                })}
                            </Field>
                    </div>
                    {showAccessLevel && <div className={'col-4'}>
                            <Field
                              label="Access Level"
                              className="form-control"
                              name="accessLevel"
                              component={CustomDropDownField}
                              requiredMark={true}
                              validate={required}
                              showErrorsFixed={true}
                            >
                                <option value="">Please Select</option>
                                {(['Edit', 'View', 'No Access'] || [])?.map((res, index) =>
                                  <option key={index} value={res}>{res}</option>
                                )}
                            </Field>
                    </div>}
                </div>
                <div className="row mt-3">
                    <div className={showAccessLevel ? 'col-8' : 'col-12'}>
                            <Field
                              label="Choose a Client"
                              className="form-control"
                              name="patientId"
                              component={CustomDropDownField}
                              requiredMark={true}
                              validate={required}
                              showErrorsFixed={true}
                            >
                                <option value="">Please Select</option>
                                {clientsList?.map((res, index) => {
                                    return <option key={index} value={res.id}>{res.patientFirstLastName}</option>
                                })}
                            </Field>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className={showAccessLevel ? 'col-8' : 'col-12'}>
                            <Field
                              label="Choose a Template"
                              className="form-control"
                              name="templateId"
                              component={CustomDropDownField}
                              requiredMark={true}
                              validate={required}
                              showErrorsFixed={true}
                            >
                                <option value="">Please Select</option>
                                {formTemplatesList?.map((res, index) => {
                                    return <option key={index} value={res.id}>{res.name}</option>
                                })}
                            </Field>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className={showAccessLevel ? 'col-8' : 'col-12'}>
                        <div className="mb-4">
                            <Field
                              // label={<><span>Expiration date</span>&nbsp;<i className="text-lightgrey ms-1">(Optional)</i></>}
                              label="Expiration date"
                              className="form-control mk-wi"
                              name="expirationDate"
                              type="date"
                              component={CustomInputField}
                            />
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                <div className="col">
                        <div className="form-group">
                            <div className="col-12 text-center pt-4">
                                <button type="submit" className="btn btn-primary px-3">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};

const mapStateToProps = (state, ownProps) => {
    const data = state.charting?.editInitialAssessmentData?.data;
// console.log('ownProps', ownProps);
    return {
        agencyId: state?.auth.authData.agencyId,
        initialValues: {
            assessmentTitle: data?.assessmentTitle,
            nurseId: data?.nurseId?.toString(),
            accessLevel: data?.accessLevel,
            patientId: data?.patientId,
            templateId: data?.formTemplateId?.toString(),
            expirationDate: data?.expirationDate,
        },
        creatingForInitialAssessment: ownProps.creatingForInitialAssessment,
        creatingForSupervisoryVisit: ownProps.creatingForSupervisoryVisit,
        creatingForDischargeSummary: ownProps.creatingForDischargeSummary,
        creatingForCarePlan: ownProps.creatingForCarePlan,
    }
};

export default connect(mapStateToProps)(
    reduxForm({form: "createInitialAssessmentModal", enableReinitialize: true})(CreateInitialAssessmentModal)
);
