import React from 'react';
// import WalletBalanceComponent from "../../shared/WalletBalance";
// import AddFundsComponent from "../../shared/AddFunds";
import SubscriptionHistoryTable from "./SubscriptionHistoryTable";
import {useOutletContext} from "react-router-dom";

const SubscriptionHistoryComponent = () => {
  // const [reloadBalance, setReloadBalance] = useState(false);
  const { setPaginationTab } = useOutletContext();

  return (
    /*<div>
      <div className="py-history-content d-flex align-items-center gap-3 gap-lg-0 justify-content-between my-4 flex-wrap">
        <WalletBalanceComponent reloadBalance={reloadBalance} setReloadBalance={setReloadBalance} />
        <AddFundsComponent setReloadBalance={setReloadBalance} size="lg" />
      </div>*/
      <SubscriptionHistoryTable setPaginationTab={setPaginationTab} />
    /*</div>*/
  );
};

export default SubscriptionHistoryComponent;
