import React, {useEffect, useState} from "react";
import {Form} from 'react-formio';
import {useDispatch, useSelector} from "react-redux";
import PrimaryButton from "../../../../../../sharedComponents/buttons/PrimaryButton";
import {HttpsAction} from "../../../../../../commonApiFunction/httpsAction";

const EditCharting = ({editTemplateData, onClose}) => {
    const dispatch = useDispatch();

    const templateData = useSelector(state => state.charting?.templateData?.data);
    const formTemplateData = useSelector(state => state.charting?.formTemplateData?.data);

    const [formIoData, setFormIoData] = useState({components: []});
    const [data, setData] = useState({});

    const handleComponentChange = (updateComponent) => {
        if (updateComponent?.changed) {
            setData(updateComponent.data);
        }
    };

    const handleUpdateTemplate = () => {
        if (!editTemplateData?.formDataId) {
            dispatch(HttpsAction({
                method: 'POST',
                apiSection: 'forms',
                apiName: 'formsData',
                data: {
                    formTemplateId: editTemplateData.templateId,
                    data,
                    chartingId: editTemplateData.chartingId,
                }
            }))
        }
        if (editTemplateData?.formDataId) {
            dispatch(HttpsAction({
                method: 'PUT',
                apiSection: 'forms',
                queryParameter: `/${editTemplateData?.formDataId}`,
                apiName: 'formsData',
                data: {
                    formTemplateId: editTemplateData.templateId,
                    data,
                }
            }))
        }
        onClose(true);
    };

    useEffect(() => {
        if (templateData?.components?.length > 0) {
            setFormIoData({components: templateData?.components})

            if (formIoData?.data) {
                setData(formIoData?.data)
            }
        }

        return () => {
            setData({})
            setFormIoData({components: []})
        }
    }, [templateData?.components?.length > 0, formIoData?.data]);

    return (
        <>
            <div className={'p-4'}>
                {/*<h2>Edit Form/Data</h2>*/}
                <Form
                    form={formIoData}
                    submission={{data: formTemplateData?.data}}
                    onChange={(e) => handleComponentChange(e)} // Handle component changes
                />
                <div
                    className="d-flex justify-content-center"
                    style={{marginTop: "20px"}}
                >
                    <PrimaryButton
                        type="button"
                        className="btn-view-repo gap-2 px-5 py-2"
                        text={"Update Form/Data"}
                        onClick={handleUpdateTemplate}
                    />
                </div>
            </div>
        </>
    )
};

export default EditCharting;
