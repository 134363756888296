import React from 'react';
import {NavLink, Outlet, useNavigate, useOutletContext, useParams} from "react-router-dom";
import {isAdminUser, isAgencyUser} from "../helpers/apiMethods";

const personalInfoTabsList = [
  {
    path: "main",
    text: "Personal Info",
  },
  {
    path: "account-details",
    text: "Account Details",
    viewableByAgency: true,
    viewableByAdmin: false,
  },
  {
    path: "mailing-info",
    text: "Mailing Info",
  },
  {
    path: "work-experience",
    text: "Work Experience",
  },
  {
    path: "job-preferences",
    text: "Job Preferences",
    viewableByAgency: false,
    viewableByAdmin: true,
  },
  {
    path: "education",
    text: "Education",
  },
  {
    path: "additional-questions",
    text: "Additional Questions",
  },
  {
    path: "emergency-contacts",
    text: "Emergency Contacts",
  },
];

const NursePersonalInfoLayout = () => {
  const isAdmin = isAdminUser();
  const isAgency = isAgencyUser();
  const pathPrefix = `${isAdmin ? "/admin/agency" : "/agency"}/nurses/edit/profile/personal-info`;

  // const navigate = useNavigate();
  // const { id: nurseId } = useParams();
  // const { nurseId } = useOutletContext();

  return (
    <div className="card-white px-4">
      <div className="d-flex gap-2 profile-tabs">
        {personalInfoTabsList.map(({ path, text, viewableByAgency, viewableByAdmin }, index) => {
          return (
            <React.Fragment key={index}>
              {((viewableByAgency != null && viewableByAgency === true && isAgency) || (viewableByAdmin != null && viewableByAdmin === true && isAdmin) || viewableByAgency == null || viewableByAdmin == null) &&
                // <NavLink key={index} to={`${pathPrefix}/${path}/${nurseId}`} className="btn btn-sm px-1 nurse-profile-personal-info-tab-link neo1">{text}</NavLink>
                <NavLink to={`${pathPrefix}/${path}`} className="btn btn-sm px-1 nurse-profile-personal-info-tab-link neo1">{text}</NavLink>
              }
            </React.Fragment>
          );
        })}
      </div>
      {/*<Outlet context={{ nurseId }} />*/}
      <Outlet context={useOutletContext()} />
    </div>
  );
};

export default NursePersonalInfoLayout;
