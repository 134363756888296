// Function to retrieve authorization headers with a Bearer token from local storage
import storageManager from "../helpers/storageManager";

export default function getAuthHeaders() {
    // Retrieve the token from local storage
    const token = storageManager.retrieveToken();

    // Construct and return the authorization header
    // The Bearer token format is commonly used for authentication
    return {
        authorization: `Bearer ${token}`,
        ClientIP: '123.123.123.123',
        ClientPlatform: 'Web'
    };
}
