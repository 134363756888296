import React, {useEffect, useRef, useState} from "react";
import PrimaryButton from "../../../../sharedComponents/buttons/PrimaryButton";
import {connect, useDispatch, useSelector} from "react-redux";
import {reduxForm} from "redux-form";
import classes from "../TaskDetails.module.css"
import {Popover, Stack} from "@mui/material";
import {HttpsAction} from "../../../../commonApiFunction/httpsAction";
// import GoogleMapReact from 'google-map-react';
// import Marker from "./Marker";
import dateHelperFunctions from "../../../../helpers/date.helpers";
import {useJsApiLoader, GoogleMap, Marker, DirectionsRenderer} from "@react-google-maps/api";

// const loc1 = {lat: 11.342202, lng: 11.270828};
// const loc2 = {lat: 11.4342342, lng: 11.5342342};
// const loc2 = {lat: 11.343307, lng: 11.372381};
// const loc1 = {lat: 11.286076, lng: 11.3133828};
// const loc2 = {lat: 11.280859, lng: 11.30467};
//
// const LocationPin = ({ text }) => {
//
//     const ref = useRef(null);
//
//     const [showDetails, setShowDetails] = useState(null);
//
//     const handlePopover = (data) => {
//         setShowDetails(data);
//     }
//
//     return (
//       <div
//         ref={ref}
//         onMouseEnter={() => handlePopover(text)}
//         onMouseLeave={() => handlePopover(false)}
//       >
//           <div className="pin gap-1"
//           >
//               <svg width="29" height="38" viewBox="0 0 29 38" fill="none" xmlns="http://www.w3.org/2000/svg">
//                   <g clipPath="url(#clip0_9758_49044)">
//                       <path
//                         d="M14.4986 9.50977C12.12 9.50977 10.1758 11.4487 10.1758 13.845C10.1758 16.2305 12.1091 18.1803 14.4986 18.1803C16.8773 18.1803 18.8215 16.2414 18.8215 13.845C18.8215 11.4595 16.8773 9.50977 14.4986 9.50977Z"
//                         fill="#fd2b2b"/>
//                       <path
//                         d="M14.5 0C6.49513 0 0 6.51378 0 14.5416C0 21.3931 10.5464 33.778 13.6528 37.2854C14.109 37.7974 14.9019 37.7974 15.3581 37.2854C18.4644 33.778 29 21.3931 29 14.5416C29 6.51378 22.5049 0 14.5 0ZM14.5 21.0336C10.5464 21.0336 7.33146 17.8094 7.33146 13.8445C7.33146 9.8796 10.5464 6.65539 14.5 6.65539C18.4536 6.65539 21.6685 9.8796 21.6685 13.8445C21.6685 17.8094 18.4536 21.0336 14.5 21.0336Z"
//                         fill="#fd2b2b"/>
//                   </g>
//                   <defs>
//                       <clipPath id="clip0_9758_49044">
//                           <rect width="29" height="37.6667" fill="white"/>
//                       </clipPath>
//                   </defs>
//               </svg>
//               <p className="pin-text">{text}</p>
//           </div>
//           <Popover
//             open={showDetails != null}
//             anchorEl={ref.current}
//             onClose={() => handlePopover(null)}
//             anchorOrigin={{
//                 vertical: 'bottom',
//             }}
//             transformOrigin={{
//                 vertical: 'top',
//                 horizontal: 'center',
//             }}
//             PaperProps={{
//                 style: {
//                     boxShadow: 'unset',
//                     width: 'auto',
//                     height: 'auto',
//                     borderRadius: '5px',
//                     marginTop: '5px',
//                     border: '1px solid #D5D5D5',
//                     backgroundColor: '#FFF',
//                     padding: '10px'
//                 },
//             }}
//           >
//               <div>
//                   {showDetails}
//               </div>
//           </Popover>
//       </div>
//     );
// };

const Activity = ({show, handleSubmit, handleSelectTab, initialValues, completedActivities}) => {

    // const googleApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    const defaultZoomLevel = 18;

    const [map, setMap] = useState(/** @type google.maps.Map */null);
    const [centerOfMap, setCenterOfMap] = useState(/** @type google.maps.LatLng */null);

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    });

    const onSubmit = () => {
        handleSelectTab('', 4)
    }

    useEffect(() => {
        if (completedActivities.length > 0) {
            const mapCenter = {lat: completedActivities[0].completionLatitude, lng: completedActivities[0].completionLongitude};
            setCenterOfMap(mapCenter);
            // setCenterOfMap(loc1);
        }
    }, [completedActivities]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className={'mt-5'} style={{display: show ? 'block' : 'none'}}>

                <div className="google-map position-relative">
                    {/*{completedActivities.length > 0 ? <GoogleMapReact*/}
                    {/*    defaultCenter={{lat: completedActivities[0].completionLatitude, lng: completedActivities[0].completionLongitude}}*/}
                    {/*    defaultZoom={defaultZoomLevel}*/}
                    {/*    bootstrapURLKeys={{ key: googleApiKey }}*/}
                    {/*    // editorState={}*/}
                    {/*  >*/}
                    {/*      {completedActivities.map((activity, index) => (*/}
                    {/*        <LocationPin*/}
                    {/*          key={index}*/}
                    {/*          lat={activity.completionLatitude}*/}
                    {/*          lng={activity.completionLongitude}*/}
                    {/*          text={`${activity.completionTime} ${activity.name}`}*/}
                    {/*        />*/}
                    {/*      ))}*/}
                    {/*  </GoogleMapReact>*/}
                    {/*  : <div className={classes.noActivity}>*/}
                    {/*      No Activity*/}
                    {/*  </div>}*/}
                    {centerOfMap != null ? <>
                          <GoogleMap
                            center={centerOfMap}
                            zoom={defaultZoomLevel}
                            mapContainerStyle={{width: '100%', height: '100%'}}
                            options={{
                                zoomControl: false,
                                streetViewControl: false,
                                mapTypeControl: false,
                                fullscreenControl: false,
                            }}
                            onLoad={(map) => setMap(map)}
                          >
                              {/* YELLOW */}
                              {initialValues.clockInLatitude != null && initialValues.clockInLongitude != null && <Marker
                                position={{lat: initialValues.clockInLatitude, lng: initialValues.clockInLongitude}}
                                title={`${initialValues.clockInAmPm} Clock In`}
                                label={'Clock In'}
                                icon={{
                                    fillColor: 'yellow',
                                    path: 'M12.75 0l-2.25 2.25 2.25 2.25-5.25 6h-5.25l4.125 4.125-6.375 8.452v0.923h0.923l8.452-6.375 4.125 4.125v-5.25l6-5.25 2.25 2.25 2.25-2.25-11.25-11.25zM10.5 12.75l-1.5-1.5 5.25-5.25 1.5 1.5-5.25 5.25z',
                                    fillOpacity: 1,
                                    strokeColor: '#000',
                                    strokeWeight: 1,
                                    scale: 1,
                                }}
                              />}
                              {/* GREEN */}
                              {initialValues.clockOutLatitude != null && initialValues.clockOutLongitude != null && <Marker
                                position={{lat: initialValues.clockOutLatitude, lng: initialValues.clockOutLongitude}}
                                title={`${initialValues.clockOutAmPm} Clock Out`}
                                label={'Clock Out'}
                                icon={{
                                    fillColor: 'green',
                                    // strokeColor: 'yellow',
                                    path: 'M12.75 0l-2.25 2.25 2.25 2.25-5.25 6h-5.25l4.125 4.125-6.375 8.452v0.923h0.923l8.452-6.375 4.125 4.125v-5.25l6-5.25 2.25 2.25 2.25-2.25-11.25-11.25zM10.5 12.75l-1.5-1.5 5.25-5.25 1.5 1.5-5.25 5.25z',
                                    // fillColor: '#34495e',
                                    fillOpacity: 1,
                                    strokeColor: '#000',
                                    strokeWeight: 1,
                                    scale: 1,
                                }}
                              />}
                              {completedActivities.map((activity, index) => (
                                <Marker
                                  key={index}
                                  position={{lat: activity.completionLatitude, lng: activity.completionLongitude}}
                                  // position={loc1}
                                  title={`${activity.completionTime} ${activity.name}`}
                                  label={String(index + 1)}
                                  icon={{
                                      // path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARRO,
                                      // fillColor: '#BEDFFF',
                                      fillColor: '#1976D2',
                                      // strokeColor: 'yellow',
                                      path: 'M12.75 0l-2.25 2.25 2.25 2.25-5.25 6h-5.25l4.125 4.125-6.375 8.452v0.923h0.923l8.452-6.375 4.125 4.125v-5.25l6-5.25 2.25 2.25 2.25-2.25-11.25-11.25zM10.5 12.75l-1.5-1.5 5.25-5.25 1.5 1.5-5.25 5.25z',
                                      // fillColor: '#34495e',
                                      fillOpacity: 1,
                                      strokeColor: '#000',
                                      strokeWeight: 1,
                                      scale: 1,
                                  }}
                                />
                              ))}
                              {/*<Marker*/}
                              {/*  key={2}*/}
                              {/*  position={loc2}*/}
                              {/*/>*/}
                          </GoogleMap>
                          <div className="position-absolute top-0 end-0">
                              <button onClick={(e) => centerOfMap && map.panTo(centerOfMap)}>Back to Center</button>
                          </div>
                      </>
                      : <div className={classes.noActivity}>
                          No Activity
                      </div>}
                </div>

                <div className={'mt-2'}>
                    <div className={classes.box}>
                        <Stack direction={'row'} gap={'15px'}>
                            <div className={classes.clockInButton}>
                                <div className={'d-flex flex-row gap-2 align-items-center'}>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                          d="M2 8C2 8.78793 2.15519 9.56815 2.45672 10.2961C2.75825 11.0241 3.20021 11.6855 3.75736 12.2426C4.31451 12.7998 4.97595 13.2417 5.7039 13.5433C6.43185 13.8448 7.21207 14 8 14C8.78793 14 9.56815 13.8448 10.2961 13.5433C11.0241 13.2417 11.6855 12.7998 12.2426 12.2426C12.7998 11.6855 13.2417 11.0241 13.5433 10.2961C13.8448 9.56815 14 8.78793 14 8C14 6.4087 13.3679 4.88258 12.2426 3.75736C11.1174 2.63214 9.5913 2 8 2C6.4087 2 4.88258 2.63214 3.75736 3.75736C2.63214 4.88258 2 6.4087 2 8Z"
                                          stroke="black" strokeWidth="1.33333" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                        <path d="M8 4.66797V8.0013L10 10.0013" stroke="black" strokeWidth="1.33333"
                                              strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    <div className={classes.skyBlueButtonText}>
                                        Clocked In
                                    </div>
                                </div>
                            </div>

                            <div className={classes.skyBlueOutlineButton}>
                                <div className={'d-flex align-items-center'}>
                                    <div className={classes.skyBlueOutlineButtonText}>
                                        {/*{dateHelperFunctions.convertTo12HourFormat(initialValues?.clockIn) || 'N/A'}*/}
                                        {initialValues?.clockIn}
                                    </div>
                                </div>
                            </div>
                        </Stack>
                        {/*<div className={'mt-4'}>*/}
                        {/*    {initialValues?.notes || 'N/A'}*/}
                        {/*</div>*/}
                    </div>
                </div>

                {initialValues?.activities?.map((value, index) => (
                  <div key={index} className="m-0 p-0">
                      {value?.completionLatitude && value?.completionLongitude && value?.completionTime &&
                        <div className={'mt-2'}>
                            <div className={classes.box}>
                                <Stack direction={'row'} gap={'15px'}>
                                    <div className={classes.skyBlueButton}>
                                        <div className={'d-flex flex-row gap-2 align-items-center'}>
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                  d="M2 5.33268V4.66602H13.3333V5.33268H2ZM13.3333 7.99935V8.66602H2V7.99935H13.3333ZM2 11.3327H13.3333V11.9993H2V11.3327Z"
                                                  fill="black"/>
                                            </svg>

                                            <div className={classes.skyBlueButtonText}>
                                                Tasks Submitted
                                            </div>
                                        </div>
                                    </div>

                                    <div className={classes.skyBlueOutlineButton}>
                                        <div className={'d-flex align-items-center'}>
                                            <div className={classes.skyBlueOutlineButtonText}>
                                                {dateHelperFunctions.convertTo12HourFormat(value?.completionTime)}
                                            </div>
                                        </div>
                                    </div>
                                </Stack>
                                <div className={'mt-4'}>
                                    <ul>
                                        <li>
                                            {value?.name || 'N/A'}
                                            {/*<div className="d-flex gap-3" style={{maxWidth: 'max-content'}}>
                                             {value?.name || 'N/A'}
                                             <div className={classes.skyBlueOutlineButton}>
                                             <div className={'d-flex align-items-center'}>
                                             <div className={classes.skyBlueOutlineButtonText}>
                                             {dateHelperFunctions.convertTo12HourFormat(value?.completionTime)}
                                             </div>
                                             </div>
                                             </div>
                                             </div>*/}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>}
                  </div>
                ))}

                {initialValues?.clockOut != null && <div className={'mt-2'}>
                    <div className={classes.box}>
                        <Stack direction={'row'} gap={'15px'}>
                            <div className={classes.clockOutButton}>
                                <div className={'d-flex flex-row gap-2 align-items-center'}>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                          d="M2 8C2 8.78793 2.15519 9.56815 2.45672 10.2961C2.75825 11.0241 3.20021 11.6855 3.75736 12.2426C4.31451 12.7998 4.97595 13.2417 5.7039 13.5433C6.43185 13.8448 7.21207 14 8 14C8.78793 14 9.56815 13.8448 10.2961 13.5433C11.0241 13.2417 11.6855 12.7998 12.2426 12.2426C12.7998 11.6855 13.2417 11.0241 13.5433 10.2961C13.8448 9.56815 14 8.78793 14 8C14 6.4087 13.3679 4.88258 12.2426 3.75736C11.1174 2.63214 9.5913 2 8 2C6.4087 2 4.88258 2.63214 3.75736 3.75736C2.63214 4.88258 2 6.4087 2 8Z"
                                          stroke="black" strokeWidth="1.33333" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                        <path d="M8 4.66797V8.0013L10 10.0013" stroke="black" strokeWidth="1.33333"
                                              strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                    <div className={classes.skyBlueButtonText}>
                                        Clocked Out
                                    </div>
                                </div>
                            </div>

                            <div className={classes.skyBlueOutlineButton}>
                                <div className={'d-flex align-items-center'}>
                                    <div className={classes.skyBlueOutlineButtonText}>
                                        {/*{dateHelperFunctions.convertTo12HourFormat(initialValues?.clockIn) || 'N/A'}*/}
                                        {initialValues?.clockOut}
                                    </div>
                                </div>
                            </div>
                        </Stack>
                        {/*<div className={'mt-4'}>*/}
                        {/*    {initialValues?.notes || 'N/A'}*/}
                        {/*</div>*/}
                    </div>
                </div>}

                {/*<div
                  className="d-flex justify-content-center"
                  style={{margin: "40px 0 0 0"}}>
                    <PrimaryButton
                      type="submit"
                      className="btn-view-repo gap-2 px-5 py-2"
                      text={"Next"}
                      onClick={() => undefined}
                    />
                </div>*/}
            </form>
        </>
    )
}


const mapStateToProps = (state, ownProps) => {
    // const data = state.client?.taskDetails?.data;
    const data = ownProps?.taskDetails;

    const activities = data?.activities || [];
    return {
        initialValues: {
            assignedNurseId: data?.assignedNurseId?.toString() || "",
            serviceRequestId: data?.assignedNurseId?.toString() || "",
            startDate: data?.startDate || "",
            notes: data?.notes || "",
            clockIn: data?.clockIn != null ? dateHelperFunctions.convertTo12HourFormat(data?.clockIn) : "N/A",
            clockOut: data?.clockOut != null ? dateHelperFunctions.convertTo12HourFormat(data?.clockOut) : null,
            clockInAmPm: data?.clockInAmPm || "",
            clockInLatitude: data?.clockInLatitude || null,
            clockInLongitude: data?.clockInLongitude || null,
            clockOutAmPm: data?.clockOutAmPm || "",
            clockOutLatitude: data?.clockOutLatitude || null,
            clockOutLongitude: data?.clockOutLongitude || null,
            activities,
        },
        completedActivities: activities.filter(a => a.isCompleted),
    }
}

export default connect(mapStateToProps)(
  reduxForm({form: "activity", enableReinitialize: true})(Activity)
);

