import React from 'react';
// import AddFundsComponent from "../../shared/AddFunds";
// import WalletBalanceComponent from "../../shared/WalletBalance";
import PayoutHistoryTable from "./PayoutHistoryTable";
import {useOutletContext} from "react-router-dom";

const PayoutHistoryComponent = () => {
  // const [reloadBalance, setReloadBalance] = useState(false);
  const { setPaginationTab } = useOutletContext();

  return (
    /*<div>
      <div className="py-history-content d-flex align-items-center gap-3 gap-lg-0 justify-content-between my-4 flex-wrap">
        <WalletBalanceComponent reloadBalance={reloadBalance} setReloadBalance={setReloadBalance} />
        <AddFundsComponent setReloadBalance={setReloadBalance} size="lg" />
      </div>*/
      <PayoutHistoryTable setPaginationTab={setPaginationTab} />
    /*</div>*/
  );
};

export default PayoutHistoryComponent;
