import React from "react";
import Table from "../../../../../sharedComponents/table/Table";
import {useSelector} from "react-redux";
import {Stack} from "@mui/material";
import classes from "./CareNote.module.css";
import {getDayName} from "./ViewCare/TasksInformation";
import dateHelperFunctions from "../../../../../helpers/date.helpers";
import {apiBaseUrl, noImagePath} from "../../../../../appConstants";

const PrintCareNotes = () => {

    const careList = useSelector(state => state.client?.allCareList?.data || []);

    const transformedArray = (careList || []).flatMap((eachCareList, eachCareIndex) => {
        return (eachCareList?.tasks || [])?.map((eachTask, eachTaskIndex) => {
            return {
                date: (
                    <Stack gap={'5px'}>
                        <Stack className={classes.dayName}>
                            {getDayName(eachTask?.date)}
                        </Stack>
                        <Stack className={classes.date}>
                            {eachTask?.date}
                        </Stack>
                    </Stack>
                ),
                nurse: `${eachTask?.assignedNurseFullName || 'N/A'}`,
                clock: `${dateHelperFunctions.convertTo12HourFormat(eachTask?.clockIn)} - ${dateHelperFunctions.convertTo12HourFormat(eachTask?.clockOut)}`,
                taskName: (eachTask?.activities || [])?.map((value, index) => `${value?.category}, `),
                notes: <Stack className={classes.dailyNotesBox}> {eachTask?.notes} </Stack>,
                nurseSignature: <img style={{width: '100px', height: '50px'}}
                                     src={`${apiBaseUrl}${eachTask?.nurseSignatureDocumentLink || noImagePath}`}/>,
                clientSignature: <img style={{width: '100px', height: '50px'}}
                                      src={`${apiBaseUrl}${eachTask?.patientSignatureDocumentLink || noImagePath}`}/>,
                RNSignature: eachTask?.nurseNameForVerification ? eachTask?.nurseNameForVerification : 'N/A',
            };
        });
    });

    const serviceHeaderData = [
        {
            title: "Date",
            dataIndex: "date",
        },
        {
            title: "Nurse",
            dataIndex: "nurse",
        },
        {
            title: "Clock In/Clock Out",
            dataIndex: "clock",
        },
        {
            title: "Tasks Name and Category",
            dataIndex: "taskName",
        },
        {
            title: "Notes",
            dataIndex: "notes",
        },
        {
            title: "Nurse Signature",
            dataIndex: "nurseSignature",
        },
        {
            title: "Client Signature",
            dataIndex: "clientSignature",
        },
        {
            title: "RN Signature",
            dataIndex: "RNSignature",
        },
    ];


    return (
        <>
            <Table headers={serviceHeaderData} rows={transformedArray}/>
        </>
    )
};

export default PrintCareNotes;

