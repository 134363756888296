import React, {useEffect} from "react";
import {change, Field, getFormValues, reduxForm} from "redux-form";
import {connect, useDispatch} from "react-redux";
import {useOutletContext, useParams} from "react-router-dom";
import CustomInputField from "../../../../../../sharedComponents/reduxfields/customInputField";
import {getClientProfilePetInfo, postClientProfilePetInfo} from "../../../../../../services/client";
import PrimaryButton from "../../../../../../sharedComponents/buttons/PrimaryButton";
import * as clientAction from "../../../clientActionType";
import {HttpsAction} from "../../../../../../commonApiFunction/httpsAction";
import {useQueryClient} from "@tanstack/react-query";
import stringHelperFunctions from "../../../../../../helpers/string.helpers";
import {required} from "../../../../../../helpers/validation";
import CustomDropDownNewField from "../../../../../../sharedComponents/reduxfields/customDropDownNew";
import numberHelperFunctions from "../../../../../../helpers/number.helpers";
import useCustomNav from "../../../../../../utils/hooks/useCustomNav";

const PetInformation = ({handleSubmit, formData, listLength, notNewAndNotDeletedListLength}) => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    // const {clientId} = useParams();
    const { clientId } = useOutletContext();

    const { goTo: gotoPatientCalendarPage } = useCustomNav('/agency/clients/edit/calendar');

    const animalTypesArray = queryClient.getQueryData(['list-animal-types']);

    const handleAddPet = () => {
        let updatedList = Array.from({length: listLength}, (v, i) => i).map((val, index) => {
            return {
                // ...v,
                recordType: formData[`recordType${index + 1}`],
                id: formData[`id${index + 1}`],
                animalTypeId: formData[`animalTypeId${index + 1}`],
                petName: formData[`petName${index + 1}`],
                petAge: formData[`petAge${index + 1}`],
                index,
            };
        });
        /// IIFE
        (async () => {
            await dispatch(HttpsAction({
                reducerName: 'CLIENT',
                reduxKeyName: 'petList',
                actionType: 'GET_PET_INFO',
                data: updatedList,
                updateData: true,
                actionTypeIsDispatchType: true,
            }));
            dispatch({type: clientAction.ADD_PET});
        })();
    };

    const handleDeletePet = async (deletedIndex) => {
// console.log('test- deletedIndex', deletedIndex, formData)
        const list = Array.from({ length: listLength }, (v, i) => i);
        let updatedList = list.map((val, index) => {
            return {
                // ...v,
                recordType: formData[`recordType${index + 1}`],
                id: formData[`id${index + 1}`],
                animalTypeId: formData[`animalTypeId${index + 1}`],
                petName: formData[`petName${index + 1}`],
                petAge: formData[`petAge${index + 1}`],
                index,
            };
        });
        const deletedItemIdValue = formData[`id${deletedIndex}`];
        if (deletedItemIdValue === '0') { // item not present in database, so remove item from list/array
            updatedList.splice(deletedIndex - 1, 1);
            dispatch(HttpsAction({
                reducerName: 'CLIENT',
                reduxKeyName: 'petList',
                actionType: 'GET_PET_INFO',
                data: updatedList,
                updateData: true,
                actionTypeIsDispatchType: true,
            }));
        } else { // item present in database, so identify/mark it for deletion for later API call
            updatedList[deletedIndex - 1].recordType = "deleted";
            dispatch(HttpsAction({
                reducerName: 'CLIENT',
                reduxKeyName: 'petList',
                actionType: 'GET_PET_INFO',
                data: updatedList,
                updateData: true,
                actionTypeIsDispatchType: true,
            }));
        }
// console.log('test- updatedList', updatedList)
    };

    const onSubmit = async (data) => {
        const payload = [];
        for (let i = 0; i < listLength; i++) {
            if (data[`recordType${i + 1}`] !== "deleted") {
                const recordType = data[`recordType${i + 1}`];
                console.log(`index ==> ${i} ==>`, data[`recordType${i + 1}`])
                let id = data[`id${i + 1}`];
                payload.push({
                    recordType,
                    "id": id === 0 ? null : Number(id),
                    "animalTypeId": numberHelperFunctions.nullIfEmpty(data[`animalTypeId${i + 1}`]),
                    "petName": stringHelperFunctions.nullIfEmpty(data[`petName${i + 1}`]),
                    "petAge": numberHelperFunctions.nullIfEmpty(data[`petAge${i + 1}`]),
                });
            } else {
                const recordType = data[`recordType${i + 1}`];
                let id = data[`id${i + 1}`];
                payload.push({recordType, "id": id === 0 ? null : Number(id)})
            }
        }
        const response = await dispatch(postClientProfilePetInfo(payload, clientId));
        if (response.success) {
            // await dispatch(getClientProfilePetInfo(clientId));
            gotoPatientCalendarPage();
        }
    };

    useEffect(() => {
        dispatch(getClientProfilePetInfo(clientId));
        return () => {
            dispatch(HttpsAction({
                reducerName: 'CLIENT',
                reduxKeyName: 'petList',
                actionType: 'GET_PET_INFO',
                removeData: true,
                actionTypeIsDispatchType: true,
            }));
        };
    }, []);

    useEffect(() => {
        if (listLength === 0) {
            dispatch({type: clientAction.ADD_PET});
        }
    }, [listLength === 0]);
// console.log('test- valid', listLength, notNewAndNotDeletedListLength, initialValues)

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Field
                  id="xtra"
                  name="xtra"
                  type="hidden"
                  component={CustomInputField}
                />
                <div className="p-0 m-0 d-flex flex-column justify-content-start align-content-start gap-3">
                    {/*{petList?.length > 0 && (petList || []).map((value, index) => (*/}
                    {listLength > 0 && (Array.from({ length: listLength }, (v, i) => i) || []).map((value, index) => (
                      <React.Fragment key={index}>
                          {/*<div className="card" style={{display: formData != null && JSON.stringify(formData) !== '{}' && formData[`recordType${index + 1}`] === "deleted" ? "none" : "flex"}}>*/}
                          <div className="card" style={{
                              overflow: "hidden",
                              borderWidth: formData != null && JSON.stringify(formData) !== '{}' && formData[`recordType${index + 1}`] === "deleted" ? "0" : "auto",
                              height: formData != null && JSON.stringify(formData) !== '{}' && formData[`recordType${index + 1}`] === "deleted" ? "1px" : "auto"
                          }}>
                              <div className="card-body">
                                  <div className={`row ${index === 0 ? '' : 'mt-2'}`}>
                                      <div className="col-6 d-flex align-items-center">
                                          <div className={`col-4`} style={{fontSize: "18px", fontWeight: 500, whiteSpace: "nowrap"}}>
                                              Pet #{index + 1}
                                          </div>
                                      </div>
                                      {(notNewAndNotDeletedListLength > 0 || listLength > 1 || index > 0) && <div className="col-6 d-flex justify-content-end">
                                          <PrimaryButton
                                            type="button"
                                            className="btn-view-repo gap-2 px-4 py-2"
                                            text={"Delete Pet"}
                                            onClick={() => handleDeletePet(index + 1)}
                                          />
                                      </div>}
                                      <Field
                                        name={`recordType${index + 1}`}
                                        type="hidden"
                                        component={CustomInputField}
                                      />
                                      <Field
                                        name={`id${index + 1}`}
                                        type="hidden"
                                        component={CustomInputField}
                                      />
                                      <div className={`col-4${index !== 0 ? ' mt-3' : ''}`}>
                                          <div className="mb-2 input-field-main">
                                              <Field
                                                label="Pet Type"
                                                className="form-control mk-wi"
                                                name={`animalTypeId${index + 1}`}
                                                component={CustomDropDownNewField}
                                                requiredMark={true}
                                                validate={required}
                                              >
                                                  <option value="">Please Select</option>
                                                  {(animalTypesArray || []).map((res, index) =>
                                                    <option key={index} value={res.id}>{res.name}</option>
                                                  )}
                                              </Field>
                                          </div>
                                      </div>
                                      <div className={`col-4${index !== 0 ? ' mt-3' : ''}`}>
                                          <div className="mb-2 input-field-main">
                                              <Field
                                                className="form-control mk-wi"
                                                name={`petName${index + 1}`}
                                                label="Pet Name"
                                                type="text"
                                                component={CustomInputField}
                                                requiredMark={true}
                                                validate={required}
                                              />
                                          </div>
                                      </div>
                                      <div className={`col-4${index !== 0 ? ' mt-3' : ''}`}>
                                          <div className="mb-2 input-field-main">
                                              <Field
                                                className="form-control mk-wi"
                                                name={`petAge${index + 1}`}
                                                label="Pet Age"
                                                type="number"
                                                component={CustomInputField}
                                              />
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </React.Fragment>
                    ))}
                    <div className={'w-100 d-flex flex-row justify-content-start gap-2'}>
                        <PrimaryButton
                          type="button"
                          className="btn-view-repo gap-2 px-4 py-2"
                          text={"Add Another Pet"}
                          onClick={() => handleAddPet()}
                        />
                    </div>
                </div>
                <div
                  className="d-flex justify-content-center"
                  style={{marginTop: "40px"}}>
                    <PrimaryButton
                      type="submit"
                      className="btn-view-repo gap-2 px-5 py-2"
                      text={"Save"}
                      onClick={() => {
                          dispatch(change("petInformationForm", 'xtra', '1'));
                          return undefined;
                      }}
                    />
                </div>
            </form>
        </>
    )
};

const mapStateToProps = state => {
    // const {client: {allPetInfo: {data: petList = []} = {data: []}}} = state;
    const { client: { petList = [] } } = state;
    let initialValues = {};
    petList?.forEach((pet, index) => {
        initialValues[`recordType${index + 1}`] = pet.recordType || "existing";
        initialValues[`id${index + 1}`] = pet.id || 0;
        initialValues[`animalTypeId${index + 1}`] = pet.animalTypeId || "";
        initialValues[`petName${index + 1}`] = pet.petName || "";
        initialValues[`petAge${index + 1}`] = pet.petAge || "";
    });
    return {
        listLength: (petList || []).length,
        notNewAndNotDeletedListLength: (petList || []).filter(p => !(p.recordType === 'new' || p.recordType === 'deleted')).length,
        formData: getFormValues('petInformationForm')(state),
        initialValues,
    };
};

export default connect(mapStateToProps)(
  reduxForm({form: "petInformationForm", enableReinitialize: true})(PetInformation)
);
