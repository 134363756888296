import React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import PrimaryButton from "../buttons/PrimaryButton";
import classes from "./index.module.css";
import {Dialog} from "@mui/material";

const WarningModal = ({open = false, maxWidth = 'md', title = 'Are you sure ?', desc = "", handleDisagree = () => undefined, handleAgree = () => undefined, onClose = () => undefined}) => {

    return (
        <>
            <Dialog
                open={open}
                maxWidth={maxWidth}
                onClose={onClose}
            >
                <DialogTitle>
                    {title}
                </DialogTitle>
                <DialogContent>
                    {desc}
                </DialogContent>
                <DialogActions
                    sx={{display: 'flex', justifyContent: 'end', flexDirection: 'row', width: '530px', gap: '15px'}}>
                    <div className="d-flex justify-content-center" style={{marginBottom: "20px"}}>
                        <PrimaryButton
                            type="button"
                            className={`btn-view-repo gap-2 px-3 py-2 ${classes.disagree}`}
                            text={"Disagree"}
                            onClick={() => handleDisagree()}
                        />
                    </div>
                    <div className="d-flex justify-content-center" style={{margin: "0 20px 20px 0"}}>
                        <PrimaryButton
                            type="button"
                            className="btn-view-repo gap-2 px-3 py-2"
                            text={"Agree"}
                            onClick={() => handleAgree()}
                        />
                    </div>
                </DialogActions>
            </Dialog>
        </>
    )

};

export default WarningModal;