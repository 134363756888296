/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";

import { addAuth } from "../services/Auth";
import storageManager from "../helpers/storageManager";
import {isLoggedIn, parseTokenAndGetData, redirectOrLogout} from "../helpers/apiMethods";
import CustomInputField from "../sharedComponents/fields/customInputField";

import img2 from "./assets/image/logo.png";
import {adminFirstPagePathAfterLogin} from "../data/routes.values";

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

const AdminLoginPage = ({ adminLoginData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const [formErrors, setFormErrors] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let errors = formErrors;

    switch (name) {
      case "email":
        errors.email = validEmailRegex.test(value) ? "" : "Email is not valid!";
        break;
      case "password":
        errors.password =
          value.length < 8
            ? "Password must be at least 8 characters long!"
            : "";
        break;
      default:
        break;
    }
    setFormErrors({ ...errors });
    setLoginData({ ...loginData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    adminLoginData(loginData).then((res) => {
      if (res?.success) {
        // navigate(adminFirstPagePathAfterLogin, { replace: true });
        setTimeout(navigate, 0, adminFirstPagePathAfterLogin, {
          replace: true,
          reloadDocument: true,
        });
        window.location.reload(true);
      }
    });
  };

  useEffect(() => {
    // if (isLoggedIn()) {
    //   const decodedTokenData = parseTokenAndGetData().data;
    //   if (decodedTokenData.isAdmin) {
    //     navigate("/admin/dashboard");
    //   }
    //   if (decodedTokenData.isAgency) {
    //     navigate("/agency/dashboard");
    //   }
    //   if (decodedTokenData.isNurse) {
    //     navigate("/nurse/dashboard");
    //   }
    // }
    redirectOrLogout(dispatch, navigate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="login-page">
      {/* <!-- start page wrapper --> */}
      <main className="main-wrapper">
        <div className="container-fluid">
          <div className="col-12 col-sm-12 col-lg-12">
            {/* <!-- login form start --> */}
            <div className="login-form-area main-login">
              {/* <!-- page logo --> */}
              <div className="col-12 col-sm-12 text-center login-logo-area">
                <img
                  src={img2}
                  alt="login-logo"
                  className="login-logo img-fluid"
                />
                <h2 className="title">Welcome Back!!!</h2>
              </div>
              <form className="login-form">
                <div className="card">
                  <div className="card-body">
                    <div className="col-12 px-0">
                      <div className="form-group">
                        <CustomInputField
                          type="email"
                          className="form-control"
                          placeholder="Email Address"
                          required
                          name="email"
                          onChange={handleChange}
                        />
                        {formErrors.email ? (
                          <span className="validation">
                            <span className="iconWarning"></span>
                            {formErrors.email}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 px-0 position-relative ">
                      <div className="form-group d-flex">
                        <CustomInputField
                          id="password-field"
                          type={isPasswordVisible ? "text" : "password"}
                          autoComplete="off"
                          className="form-control pe-5"
                          placeholder="Password"
                          required
                          name="password"
                          onChange={handleChange}
                        />
                        {/*<span
                         toggle="#password-field"
                         className={`toggle-password fa fa-fw field-icon toggle-password ${
                         isPasswordVisible ? "fa-eye-slash" : "fa-eye"
                         }`}
                         onClick={() => setPasswordVisible(!isPasswordVisible)}
                         ></span>*/}
                        <div
                          style={{display: isPasswordVisible ? 'none' : 'block'}}
                          toggle="#password-field"
                          onClick={() => setPasswordVisible(!isPasswordVisible)}
                        >
                          <span className="toggle-password cursor-pointer fa fa-fw field-icon fa-eye"></span>
                        </div>
                        <div
                          style={{display: !isPasswordVisible ? 'none' : 'block'}}
                          toggle="#password-field"
                          onClick={() => setPasswordVisible(!isPasswordVisible)}
                        >
                          <span className="toggle-password cursor-pointer fa fa-fw field-icon fa-eye-slash"></span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 px-0">
                      <div className="form-group mb-0">
                        <button
                          onClick={(e) => handleSubmit(e)}
                          className="btn btn-primary w-100 mb1-4 mt1-3"
                          type="submit"
                        >
                          Login
                        </button>
                      </div>
                    </div>
                    {/*<div className="col-12 px-0 text-center">
                     <a href="#" className="forgot-pass">
                     Forgot Password?
                     </a>
                     </div>*/}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    adminLoginData: (dataToSend) =>
      dispatch(
        addAuth({
          dataToSend,
          userType: 'admin',
          // value: value,
          // endPoint: "login",
          // message: "Login Successful",
        })
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminLoginPage);
