import * as commonServices from "./commonServices.js";
import * as nurseAction from "../Agency/components/Nurses/nurseActionTypes.js";
import * as commonActions from "../helpers/commonActionTypes";
import getApiUrl from "../helpers/apiURLs.js";
import api from "../helpers/interceptor";
import { toastr } from "react-redux-toastr";
import * as type from "./../helpers/commonActionTypes.js";
import {NURSE_CERTIFICATIONS, NURSE_OTHER_CERTIFICATIONS} from "../Agency/components/Nurses/nurseActionTypes.js";
import stringHelperFunctions from "../helpers/string.helpers";
import booleanHelperFunctions from "../helpers/boolean.helpers";
import numberHelperFunctions from "../helpers/number.helpers";
import {defaultErrorMessage} from "../appConstants";
// Nurse Post Action

export const postNurseAction = (
  payload,
  endPoint,
  id,
  message,
  successCallback
) => {
  return async (dispatch) => {
    dispatch(commonServices.setLoader(true));
    let url = getApiUrl("nursesProfile", endPoint);
    // alert(url);
    if (endPoint === "addNurse" || id == null) {
      url = getApiUrl("nursesProfile", endPoint);
    } else {
      url = getApiUrl("nursesProfile", endPoint) + `/${id}`;
    }
    // alert(url);
    // return false;
    try {
      const response =
        endPoint === "addNurse" || endPoint === "nurseAdditionalQuestions"
          ? await api.post(url, payload)
          : await api.put(url, payload);
      if (successCallback != null) successCallback();
      dispatch(commonServices.setLoader(false));
      toastr.success(message);
      return response;
    } catch (err) {
      dispatch(commonServices.setLoader(false));
      if (err.status === 400) {
        toastr.error(err.message || "something went wrong");
      } else {
        toastr.error("something went wrong");
      }
      return err;
    }
  };
};

// Nurse Get Action

export const getNurseAction = (payload, id, pageNumber, successCallback) => {
  console.log(id, "id");
  return async (dispatch) => {
    dispatch(commonServices.setLoader(true));
    let url = "";
    switch (payload) {
      case "nurseList":
        url = `${getApiUrl("nursesProfile", "nurseList")}?page=${pageNumber}`;
        break;
      case "nurseProfile":
        url = `${getApiUrl("nursesProfile", "personalInfo")}/${id}`;
        break;
      case "nurseSkills":
        url = `${getApiUrl("nursesProfile", "nurseSkills")}/${id}`;
        break;
      case "nurseWorkExperience":
        url = `${getApiUrl("nursesProfile", "workExperience")}/${id}`;
        break;
      case "nurseClassList":
        url = `${getApiUrl("nursesProfile", "nurseClassList")}`;
        break;
      case "accountInfo":
        url = `${getApiUrl("nursesProfile", "accountInfo")}/${id}`;
        break;
      case "mailingInfo":
        url = `${getApiUrl("nursesProfile", "mailingInfo")}/${id}`;
        break;
      case "additionalQuestions":
        url = `${getApiUrl("nursesProfile", "nurseAdditionalQuestions")}/${id}`;
        break;
      case "cityList":
        url = `${getApiUrl("nursesProfile", "cityList")}/${id}`;
        break;
      // case "phoneTypeList":
      //   url = `${getApiUrl("nursesProfile", "phoneTypeList")}`;
      //   break;
      case "emergencyContacts":
        url = `${getApiUrl("nursesProfile", "emergencyContacts")}/${id}`;
        console.log(url);
        break;
      case "educationInfo":
        url = `${getApiUrl("nursesProfile", "education")}/${id}`;
        break;
      case "certifications":
        url = `${getApiUrl("nursesProfile", "certificationsList")}/${id}`;
        break;
      case "evaluations":
        url = `${getApiUrl("nursesProfile", "evaluationsList")}/${id}`;
        break;
      case "otherCertifications":
        url = `${getApiUrl("nursesProfile", "otherCertificationsList")}/${id}`;
        break;
      default:
        break;
    }
    try {
      // console.log("dispatch-info url", url);
      // return false;
      const response = await api.get(url);
      switch (payload) {
        case "cityList":
          dispatch({ type: nurseAction.GET_CITY_LIST, payload: response.data });
          break;
        // case "phoneTypeList":
        //   dispatch({
        //     type: nurseAction.GET_PHONE_TYPE_LIST,
        //     payload: response.data,
        //   });
        //   break;
        case "nurseList":
          dispatch({ type: nurseAction.NURSE_LIST, payload: response.data });
          break;
        case "nurseProfile":
          // console.log(response, "response");
          // return false;
          dispatch({ type: nurseAction.NURSE_PROFILE, payload: response.data });
          break;
        case "accountInfo":
          dispatch({
            type: nurseAction.NURSE_ACCOUNT_DETAILS,
            payload: response.data,
          });
          break;
        case "mailingInfo":
          dispatch({
            type: nurseAction.NURSE_MAILING_INFO,
            payload: response.data,
          });
          break;
        case "additionalQuestions":
          dispatch({
            type: nurseAction.NURSE_ADDITIONAL_QUESTIONS,
            payload: response.data,
          });
          break;
        case "nurseWorkExperience":
          // console.log(response.data, "response.data");
          dispatch({
            type: nurseAction.NURSE_WORK_EXPERIENCE,
            payload: {
              xtraField: '0',
              list: (response.data || []).map(item => ({
                recordType: 'existing',
                id: item.id,
                companyName: item.companyName,
                companyLocation: item.companyLocation,
                employerName: item.employerFirstName,
                employerPhoneNumber: item.employerPhoneNumber,
                startDate: item.startDate,
                endDate: item.endDate,
                duties: item.duties,
                reasonForLeaving: item.reasonForLeaving,
                hasIncontinenceExperience: item.hasIncontinenceExperience,
                hasHoyerLiftExperience: item.hasHoyerLiftExperience,
                hasCompanionshipExperience: item.hasCompanionshipExperience,
                hasDrivingExperience: item.hasDrivingExperience,
                hasBathingExperience: item.hasBathingExperience,
                hasAlzheimerExperience: item.hasAlzheimerExperience,
                hasTransferAssistExperience: item.hasTransferAssistExperience,
                hasSmokingExperience: item.hasSmokingExperience,
                hasMalesExperience: item.hasMalesExperience,
                hasFemalesExperience: item.hasFemalesExperience,
                hasCatsExperience: item.hasCatsExperience,
                hasDogsExperience: item.hasDogsExperience,
                hasGaitBeltExperience: item.hasGaitBeltExperience,
                hasDementiaExperience: item.hasDementiaExperience,
                hasDressingExperience: item.hasDressingExperience,
                canContactEmployer: item.canContactEmployer,
              })),
            },
          });
          break;
        case "emergencyContacts":
          dispatch({
            type: nurseAction.NURSE_EMERGENCY_CONTACTS,
            payload: {
              xtraField: '0',
              list: (response.data || []).map(item => ({
                recordType: 'existing',
                id: item.id,
                firstName: item.firstName,
                lastName: item.lastName,
                relationshipId: item.relationshipId,
                addressLine1: item.addressLine1,
                provinceOrStateId: item.provinceOrStateId,
                cityId: item.cityId,
                postalOrZipCode: item.postalOrZipCode,
                email: item.email,
                // phoneNumbers: item.phoneNumbers,
                phoneTypeId: item.phoneTypeId,
                phoneNumber: item.phoneNumber,
              })),
            },
          });
          break;
        case "educationInfo":
          dispatch({
            type: nurseAction.NURSE_EDUCATION,
            payload: {
              xtraField: '0',
              list: (response.data || []).map(item => ({
                recordType: 'existing',
                id: item.nurseEduId,
                institutionName: item.nurseEduInstitute,
                // addressLine1: item.nurseEduInstituteAddressLine1,
                location: item.eduInstituteLocation,
                tenureStartDate: item.nurseEduTenureStartDate,
                tenureEndDate: item.nurseEduTenureEndDate,
                degreeId: item.degreeId,
                majorEducationalSubjectId: item.nurseEduMajorEducationalSubjectId,
                description: item.nurseEduDescription,
              })),
            },
          });
          break;
        case "certifications":
          dispatch({
            type: nurseAction.NURSE_CERTIFICATIONS,
            payload: {
              xtraField: '0',
              list: (response.data || []).map(item => ({
                recordType: 'existing',
                id: item.id,
                checkIfComplete: true,
                certificationEvaluationId: item.nurseCertificationId,
                expiryDate: item.nurseCertificationExpiryDate,
                reminderDaysId: item.nurseCertificationReminderDaysId,
                note: item.nurseCertificationNote,
                renewalRequired: item.renewalRequired,
                renewalDate: item.renewalDate,
                renewalReminderDaysId: item.renewalReminderDaysId,
              })),
            },
          });
          break;
        case "evaluations":
          dispatch({
            type: nurseAction.NURSE_EVALUATIONS,
            payload: {
              xtraField: '0',
              list: (response.data || []).map(item => ({
                recordType: 'existing',
                id: item.id,
                checkIfComplete: true,
                certificationEvaluationId: item.nurseEvaluationId,
                expiryDate: item.nurseEvaluationExpiryDate,
                reminderDaysId: item.nurseEvaluationReminderDaysId,
                note: item.nurseEvaluationNote,
                renewalRequired: item.renewalRequired,
                renewalDate: item.renewalDate,
                renewalReminderDaysId: item.renewalReminderDaysId,
              })),
            },
          });
          break;
        case "otherCertifications":
          dispatch({
            type: nurseAction.NURSE_OTHER_CERTIFICATIONS,
            payload: {
              xtraField: '0',
              list: (response.data || []).map(item => ({
                recordType: 'existing',
                id: item.id,
                checkIfComplete: true,
                otherCertificateName: item.nurseCertificationName,
                expiryDate: item.nurseCertificationExpiryDate,
                reminderDaysId: item.nurseCertificationReminderDaysId,
                note: item.nurseCertificationNote,
                renewalRequired: item.renewalRequired,
                renewalDate: item.renewalDate,
                renewalReminderDaysId: item.renewalReminderDaysId,
              })),
            },
          });
          break;
        default:
          break;
      }
      dispatch(commonServices.setLoader(false));
      if (response != null && response.success && successCallback != null) {
        successCallback(response);
      }
      return response;
    } catch (err) {
      dispatch(commonServices.setLoader(false));
      toastr.error(defaultErrorMessage, "");
    }
  };
};

export const getNurseDetail = (apiUrl, id) => {
  return async (dispatch) => {
    dispatch(commonServices.setLoader(true));
    let url = "";
    switch (apiUrl) {
      case "nurseDetail":
        url = `${getApiUrl("nursesProfile", "nurseDetail")}/${id}`;
        break;
      default:
        break;
    }
    try {
      const response = await api.get(url);
      switch (apiUrl) {
        case "nurseDetail":
          dispatch({ type: nurseAction.NURSE_DETAIL, payload: response.data });
          break;
        default:
          break;
      }
      dispatch(commonServices.setLoader(false));
      return response;
    } catch (err) {
      dispatch(commonServices.setLoader(false));
      toastr.error(defaultErrorMessage, "");
    }
  };
};

// actions.js
export const addProfileData = ({ value, endPoint }) => {
  return async (dispatch) => {
    dispatch(commonServices.setLoader(true));
    const url = getApiUrl("nursesProfile", endPoint);
    let data = api
      .put(url, value)
      .then((response) => {
        dispatch(commonServices.setLoader(false));
        toastr.success(response.message, "");
        return response;
      })
      .catch((err) => {
        toastr.error(err.msg, "");
        dispatch(commonServices.setLoader(false));
      });
    return data;
  };
};
export const postProfileData = ({ value, endPoint }) => {
  return async (dispatch) => {
    dispatch(commonServices.setLoader(true));
    const url = getApiUrl("nursesProfile", endPoint);
    let data = api
      .put(url, value)
      .then((response) => {
        dispatch(commonServices.setLoader(false));
        toastr.success(response.message, "");
        return response;
      })
      .catch((err) => {
        toastr.error(err.msg, "");
        dispatch(commonServices.setLoader(false));
      });
    return data;
  };
};

export const getProfileData = ({ type, endPoint }) => {
  return async (dispatch) => {
    dispatch(commonServices.setLoader(true));
    const url = getApiUrl("nursesProfile", endPoint);
    api
      .get(url)
      .then((response) => {
        dispatch(commonServices.getData(response.data, type));
        dispatch(commonServices.setLoader(false));
        return response;
      })
      .catch((err) => {
        toastr.error(err.message, "");
        dispatch(commonServices.setLoader(false));
      });
  };
};

export const fetchFindNurses = (endPoint, { page, filter }) => {
  return async (dispatch) => {
    dispatch(commonServices.setLoader(true));
    const url = getApiUrl("findNurse", endPoint);
    try {
      const response = await api.get(url);
      dispatch(commonServices.setLoader(false));
      return response;
    } catch (err) {
      dispatch(commonServices.setLoader(false));
      toastr.error(defaultErrorMessage, "");
    }
  };
};

export const clearNurseActionData = (action) => {
  return async (dispatch) => {
    try {
      switch (action) {
        case "nurseProfile":
          dispatch({
            type: nurseAction.NURSE_PROFILE,
            payload: null,
          });
          break;
        case "accountInfo":
          dispatch({
            type: nurseAction.NURSE_ACCOUNT_DETAILS,
            payload: null,
          });
          break;
        case "mailingInfo":
          dispatch({
            type: nurseAction.NURSE_MAILING_INFO,
            payload: null,
          });
          break;
        case "additionalQuestions":
          dispatch({
            type: nurseAction.NURSE_ADDITIONAL_QUESTIONS,
            payload: null,
          });
          break;
        case "nurseWorkExperience":
          dispatch({
            type: nurseAction.NURSE_WORK_EXPERIENCE,
            payload: null,
          });
          break;
        case "emergencyContacts":
          dispatch({
            type: nurseAction.NURSE_EMERGENCY_CONTACTS,
            payload: null,
          });
          break;
        case "educationInfo":
          dispatch({
            type: nurseAction.NURSE_EDUCATION,
            payload: null,
          });
          break;
        default:
          break;
      }
      return null;
    } catch (err) {}
  };
};
