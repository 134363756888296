import React, {useEffect, useState} from "react";
import { Field, reduxForm } from "redux-form";
import CustomInputField from "../../../../sharedComponents/reduxfields/customInputField";
import CustomDropDownField from "../../../../sharedComponents/reduxfields/customDropDown";
import CustomRadioGroup from "../../../../sharedComponents/reduxfields/customRadioGroup";
import CheckboxGroup from "../../../../sharedComponents/reduxfields/customCheckboxGroup"
import {required} from '../../../../helpers/validation'
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';
import { registerField, unregisterField } from 'redux-form';
import {useQueryClient} from "@tanstack/react-query";

const rateTypesOptions = [
  { label: 'Base', id:"Base", value:'1'},
  { label: 'Auto', id:"Auto", value:'2'}
];

const payRateOptions = [
  { label: 'Per Hour', id:"Per_Hour", value:'1'},
  { label: 'Per Visit', id:"Per_Visit", value:'2'},
  { label: 'Per Day', id:"Per_Day", value:'3'}
];

const ServiceRequestInformationTab = ({/*setRateTypeValue, getChargeMethod, */initialTabValues, disableServiceRequestTypeId, disableAgencyRateToClient, disableNurseRate, disablePayRateTypeId, disableRateTypeSelection, /*RatesValue, chargeValue, */agencyId}) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
// console.log('TEST- ', typeof RatesValue, RatesValue, typeof chargeValue, chargeValue);
// console.log('TEST- initialTabValues', initialTabValues);

  // const frequencyDaysList = queryClient.getQueryData(['list-day-frequencies']);
  const servicesRequestTypesArray = queryClient.getQueryData(['list-service-request-types', agencyId]);

  const [rateTypeValue, setRateTypeValue] = useState('1');
  const [payRateTypeId, setPayRateTypeId] = useState('1');
  const [loadedOnce, setLoadedOnce] = useState(false);

  useEffect(() => {
    if (!loadedOnce && initialTabValues != null && JSON.stringify(initialTabValues) !== '{}') {
      setLoadedOnce(true);
      // dispatch(change('newServiceRequestForm', 'nurseOvertimeRate', ''));
      if (initialTabValues.rateTypeSelection != null && initialTabValues.payRateTypeId != null) {
        setRateTypeValue(initialTabValues.rateTypeSelection);
        setPayRateTypeId(initialTabValues.payRateTypeId);
        // if (initialTabValues.rateTypeSelection === '2' && initialTabValues.payRateTypeId === '1') {
        //   dispatch(registerField('newServiceRequestForm', 'nurseOvertimeRate'));
        // } else {
        //   dispatch(unregisterField('newServiceRequestForm', 'nurseOvertimeRate'));
        // }
      }
    }
  },[loadedOnce, initialTabValues, agencyId > 0]);

  // useEffect(() => {
  //   dispatch(change('newServiceRequestForm', 'nurseOvertimeRate', ''));
  // }, [!(rateTypeValue === '2' && payRateTypeId === '1')]);

  // if (!loadedOnce && initialTabValues != null && JSON.stringify(initialTabValues) !== '{}') return null;

  return (
    // <div className="col-12 mb-2" style={{maxWidth : "900px"}}>
    //   <div className="row" style={{width: 'max-content', maxWidth: 'auto'}}>
    //     <div className="tab-inner-heading" style={{width: 'max-content', maxWidth: 'auto'}}>Service Report Information</div>
    //     <div className="neo1 col-12 col-xl-9 py-2 px-4 m-0 border border-black rounded-4 border-opacity-10">
      <div className="col" style={{width: 'max-content', maxWidth: 'auto'}}>
        <div className="tab-inner-heading" style={{width: 'max-content', maxWidth: 'auto'}}>Service Report Information</div>
        <div className="neo1 py-2 px-4 m-0 border border-black rounded-4 border-opacity-10">
          <div className="col-12 col-sm-6">
            <Field
              className="form-control"
              label="Select a service Request"
              name="serviceRequestTypeId"
              component={CustomDropDownField}
              validate={required}
              requiredMark={true}
              disabled={disableServiceRequestTypeId}
            >
              <option value="">Please Select</option>
              {servicesRequestTypesArray?.map((res, index) => {
                return <option key={index} value={res.id}>{res.name}</option>
              })}
            </Field>
          </div>
          <div className="col-12 col-sm-12 w-100 mxt-3">
            <label>Rate Type <span className="mandatory-field">*</span></label>
            {/*<h3 className="form-inner-heading">Rates</h3>*/}
            <Field
              name="rateTypeSelection"
              component={CustomRadioGroup}
              options={rateTypesOptions}
              requiredMark={false}
              disabled={disableRateTypeSelection}
              onChange={(e) => setRateTypeValue(e.target.value)}
            />
          </div>
          <div className="row">
            <div className="col-12 col-sm-4">
              <Field
                className="form-control mk-wi"
                name="agencyRateToClient"
                label="Agency rate to clients"
                type="number"
                component={CustomInputField}
                requiredMark={true}
                validate={required}
                min="0"
                disabled={disableAgencyRateToClient}
              ></Field>
            </div>
            <div className="col-12 col-sm-4">
              <Field
                className="form-control mk-wi"
                name="nurseRate"
                label="Nurse Rate"
                type="number"
                component={CustomInputField}
                requiredMark={true}
                validate={required}
                min="0"
                disabled={disableNurseRate}
              ></Field>
            </div>
            <div className="col-12 col-sm-4">
              {
                rateTypeValue === '2' && payRateTypeId === '1' && (
                  <Field
                    className="form-control mk-wi"
                    name="nurseOvertimeRate"
                    label="Over Time Rate"
                    type="number"
                    component={CustomInputField}
                    requiredMark={true}
                    validate={required}
                    min="0"
                  ></Field>
                )
              }
              {/* <Field
               className="form-control mk-wi"
               name="nurseOvertimeRate"
               label="Over Time Rate"
               type="number"
               component={CustomInputField}
               requiredMark={RatesValue === '2' && chargeValue === '1' ? true : false}
               validate={RatesValue === '2' && chargeValue === '1' ? required : undefined}
               min="0"
               disabled={RatesValue === '2' && chargeValue === '1' ? false : true}
               value={'10'}
               ></Field> */}
            </div>
          </div>
          <div className="col-12">
            <label>Employment Type <span className="mandatory-field">*</span></label>
            <Field
              name="payRateTypeId"
              component={CustomRadioGroup}
              options={payRateOptions}
              requiredMark={false}
              disabled={disablePayRateTypeId}
              onChange={(e) => setPayRateTypeId(e.target.value)}
            />
          </div>
        </div>
      </div>
      // </div>
  );
};

export default ServiceRequestInformationTab;
