// courtesy: https://www.codedaily.io/tutorials/Creating-a-Reusable-Window-Event-Listener-Hook-with-useEffect-and-useCallback
import React, {useCallback, useEffect} from 'react';

const useWindowEvent = (event, callback/*, dependencies*/) => {
  useEffect(() => {
    window.addEventListener(event, callback);
    return () => window.removeEventListener(event, callback);
  }, [event, callback/*, ...dependencies*/]);
};

export const useGlobalStorageEvent = (callback) => {
  // return useWindowEvent("storage", callback);
  return useWindowEvent("storage", useCallback(callback, []));
};

export const useGlobalMessageEvent = (callback) => {
  // return useWindowEvent("message", callback);
  return useWindowEvent("message", useCallback(callback, []));
};

export default useWindowEvent;
