import React, {useEffect, useState} from "react";
import {connect, useSelector} from "react-redux";
import Table from "../../../sharedComponents/table/Table";
import {useDispatch} from 'react-redux';
import {getAllServiceRequest} from '../../../services/serviceRequest'
import {HttpsAction} from "../../../commonApiFunction/httpsAction";
import arrayHelperFunctions from "../../../helpers/array.helpers";
import {useLocation, useNavigate, useOutletContext} from "react-router-dom";
import Pagination from "../../../sharedComponents/pagination";
import {pageLimit} from "../../../appConstants";
import WarningModal from "../../../sharedComponents/warningModal";
import CustomDialog from "../../../sharedComponents/CustomDialog/index";
import EditRequest from "./EditRequest";
import rowHelperFunctions from "../../../helpers/row.helpers";
import MoreOptionDropdown from "./MoreOptionDropdown";
import dateHelperFunctions from "../../../helpers/date.helpers";
import TableLayout from "../shared/TableLayout";
import {activeColor, completedColor, missedColor, pendingColor} from "../../../appConstants";
import {Popover} from "@mui/material";
import Typography from "@mui/material/Typography";
import InfoPopover from "../../../sharedComponents/InfoPopover";
import NewTableLayout from "../shared/NewTableLayout";


const AllRequest = ({/*searchText, */allServiceRequestsList}) => {
    // const hideActions = false;
    const backButtonTextSuffix = 'All Requests';
    const backLink = '/agency/servicerequest/allrequest';

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { searchText, setPaginationTab } = useOutletContext();


    // const [currentPage, setCurrentPage] = useState(0);
    // const [editServiceRequestModal, setEditServiceRequestModal] = useState(false);

    const isServiceDetails = useSelector(state => state.client?.isServiceDetails || false);
    const totalAmountReservedCalculated = allServiceRequestsList?.rows?.[0]?.totalAmountReservedCalculated || 0;
    const agencyBalance = allServiceRequestsList?.rows?.[0]?.agencyBalance || 0;

    const [serviceDetails, setServiceDetails] = useState({modal: false, id: null});
    const [srIdForDeletion, setSrIdForDeletion] = useState(0);
    const [warningModal, setWarningModal] = useState(false);

    // const [nurseList, setNurseList] = useState([]);
    // const [isModalOpen, setIsModalOpen] = useState(false);
    // const [clientId, setClientId] = useState('');

    const serviceRequestIdToSelect = location?.state != null && location?.state?.serviceRequestId != null ? location.state.serviceRequestId : 0;

    const loadMethod = (curPage, toSearch) => {
        const useDefault = curPage == null && toSearch == null;
        if (useDefault) dispatch(getAllServiceRequest('all', 1));
        else {
            if (toSearch != null && toSearch.length > 0)
                dispatch(getAllServiceRequest('all', 1, toSearch));
            else
                dispatch(getAllServiceRequest('all', curPage));
        }
    };

    const transformedArray = allServiceRequestsList?.rows?.map(item => {
        let hasCurrentDayTask = item.hasCurrentDayTasks;
        // const nursesIdsList = item?.tasks?.map(t => t.assignedNurseId).filter(arrayHelperFunctions.onlyUnique).filter(Boolean);
        const filteredTasksForUniqueNurses = [];
        item?.tasks?.forEach((t, idx) => {
            // if (filteredTasksForUniqueNurses.findIndex(item => item.assignedNurseId === t.assignedNurseId) === -1 && t.assignedNurseId != null)
            if (filteredTasksForUniqueNurses.findIndex(item => item.nurseId === t.assignedNurseId) === -1 && t.assignedNurseId != null) {
                filteredTasksForUniqueNurses.push({nurseId: t.assignedNurseId, fullName: t.assignedNurseFullName, isFreelance: t.assignedNurseIsFreelance > 0});
            }
        });
        const isFreelanceNurse = item?.tasks?.[0]?.assignedNurseIsFreelance || false;
        const assignedNurseId = item?.tasks?.[0]?.assignedNurseId || 0;
        const assignedNursePersonalInformationId = item?.tasks?.[0]?.assignedNursePersonalInformationId || 0;
        const amountReservedCalculated = item?.amountReservedCalculated == null ? -1 : item?.amountReservedCalculated;

        // const filteredUniqueNurseIds = filteredTasksForUniqueNurses.map(n => n.id).filter(arrayHelperFunctions.onlyUnique).filter(Boolean);

        let serviceRequestType = 'N/A';
        if (item.tasks != null && item.tasks.length > 0) {
            serviceRequestType = item.tasks[0].serviceRequestType || 'N/A';
        }

        const sumOfTotalScheduledMinutes = item?.tasks?.map(t => t.totalScheduledMinutes).reduce((accu, min) => accu + min, 0);
        const sumHoursScheduled = Math.floor(sumOfTotalScheduledMinutes / 60);
        const sumMinutesScheduledLessHours = sumOfTotalScheduledMinutes - (sumHoursScheduled * 60);

        // let {relatedText, relatedTextColor, relatedLink, relatedId, hasValidChartingId} = rowHelperFunctions.getRelatedInfo(item, true);
        let { hasValidChartingId, relatedInfoList} = rowHelperFunctions.getRelatedInfo(item, false);

        // const offsetCurrentDateTime = new Date(Date.parse(item.offsetCurrentDateTime));
        const currentDateTimeUtc = new Date(Date.parse(item.currentDateTimeUtc.replace(' ', 'T') + '+00:00'));
        const currentDateUtc = new Date(Date.parse(currentDateTimeUtc.toISOString().substring(0, 10)+'T00:00:00+00:00'));

        const highlightRow = serviceRequestIdToSelect === item.serviceRequestId;
        return {
            'id': item.serviceRequestId,
            'Recipients': rowHelperFunctions.getClientRowItem(navigate, item.patientId, item.patientFullName, false, backButtonTextSuffix, backLink, hasValidChartingId ? `/agency/clients/${item.patientId}/charting/0` : null),
            'age': <span title={`Birth-Date = ${item.patientBirthDateFormatUs}`}>{item.patientAge}</span>,
            'serviceRequired': <div className="position-relative1 p-0 m-0">
                {/*<div onClick={() => handleServiceDetailsModal(item.id)} style={{cursor: 'pointer'}}>*/}
                    {/*{item.serviceRequestType}*/}
                    <span className="text-break">{serviceRequestType}</span>
                {/*</div>*/}
                {(relatedInfoList || []).length > 0 && <div className="position-absolute top-17 start-50 translate-middle cursor-pointer w-100 d-flex justify-content-center gap-1">{relatedInfoList.map(({relatedTextColor, relatedText, relatedLink, relatedState}, index) => <span key={index} onClick={() => {
                    if (relatedLink != null) navigate(relatedLink, { state: relatedState });
                }} className={`badge rounded-pill text-wrap lh-1 text-bg-${relatedTextColor}`}>{relatedText}</span>)}</div>}
            </div>,
            'duration': <>
                <div className="d-flex flex-column">{item?.tasks?.map(t => ({value: t.expectedTimingsWithDate, clockIn: t.clockIn, clockOut: t.clockOut})).reduce((accu, elem, idx) => {
                    return accu === null ? [<div key={idx} style={{minWidth: '183px', fontSize: '12px'}}>{elem.value}&nbsp;<span title={elem.clockIn != null && elem.clockOut != null ? 'Clocked-In and Clocked-Out' : (elem.clockIn != null ? 'Clocked-In' : (elem.clockOut != null ? 'Clocked-Out' : 'No-Activity'))}>&#x28;{`${elem.clockIn != null ? 'I' : '_'}/${elem.clockOut != null ? 'O' : '_'}`}&#x29;</span></div>] : [...accu,
                        <div key={idx} style={{minWidth: '183px', fontSize: '12px'}}>{elem.value}&nbsp;<span title={elem.clockIn != null && elem.clockOut != null ? 'Clocked-In and Clocked-Out' : (elem.clockIn != null ? 'Clocked-In' : (elem.clockOut != null ? 'Clocked-Out' : 'No-Activity'))}>&#x28;{`${elem.clockIn != null ? 'I' : '_'}/${elem.clockOut != null ? 'O' : '_'}`}&#x29;</span></div>];
                }, null)}</div>
            </>,
            // 'hours': item?.tasks?.map(t => t.scheduledHoursPerDay).reduce((accu, hr) => accu + hr, 0),
            // 'hours': item?.tasks?.map(t => t.totalScheduledHours).reduce((accu, hr) => accu + hr, 0),
            'hours': dateHelperFunctions.hoursAndMinutesToText(sumHoursScheduled, sumMinutesScheduledLessHours),
            // 'frequencies': item.frequencies,
            'location': item.patientLocation || 'N/A',
            'nurseName': filteredTasksForUniqueNurses.length === 0 ? 'N/A' : <div
              className="d-flex flex-column">{filteredTasksForUniqueNurses?.map((n, index) => rowHelperFunctions.getNurseRowItem(navigate, index, n.nurseId, n.fullName, false, n.isFreelance, backButtonTextSuffix, backLink, hasValidChartingId ? `/agency/nurses/edit/${n.nurseId}/charting` : null)).reduce((accu, elem, idx) => {
                return accu === null ? [<div key={idx} style={{minWidth: '90px', fontSize: '12px'}}>{elem}</div>] : [...accu,
                    <div key={idx} style={{minWidth: '90px', fontSize: '12px'}}>{elem}</div>];
            }, null)}</div>,
            // 'status': item.serviceRequestStatus,
            'status': <>
                <div className="d-flex flex-column">{item?.tasks?.map(t => {
                    const taskDateValue = new Date(Date.parse(t.date+'T00:00:00+00:00'));
                    const scheduledEndDateTimeUtc = new Date(Date.parse(t.scheduledEndDateTimeUtc.replace(' ', 'T') + '+00:00'));
// console.log('test- ', item.offsetCurrentDateTime, t.scheduledEndDateTimeUtc, offsetCurrentDateTime > scheduledEndDateTimeUtc)
// console.log('test- ', currentDateTimeUtc, t.scheduledEndDateTimeUtc, currentDateTimeUtc > scheduledEndDateTimeUtc)
//                     if (t.statusId < 3 && t.clockIn == null && offsetCurrentDateTime > scheduledEndDateTimeUtc) return 'Missed';
// console.log('test-- taskDateValue', t.scheduledStartTime, t.scheduledEndTime, taskDateValue.toISOString(), currentDateUtc.toISOString(), t.remainingActivitiesTotal, t.statusId, t.status)
                    if (t.statusId < 3 && t.clockIn == null && currentDateTimeUtc > scheduledEndDateTimeUtc) return <span style={{color: missedColor}}>Missed</span>;
                    if ((t.clockIn != null && taskDateValue < currentDateUtc && t.remainingActivitiesTotal > 0) || (t.clockIn != null && t.clockOut != null && t.remainingActivitiesTotal > 0) || (t.statusId === 4 && t.remainingActivitiesTotal > 0)) return <span style={{color: missedColor}} title={t.remainingActivitiesTotal + ' tasks remaining'}>Incomplete &#x28;{t.remainingActivitiesTotal}&#x29;</span>;
                    return <span style={{color: t.statusId === 1 ? pendingColor : (t.statusId > 3 ? completedColor : (t.statusId === 2 ? '' : activeColor))}}>{t.status}</span>;//39cb82,e6cf01,ffc300,dc3545
                }).reduce((accu, elem, idx) => {
                    return accu === null ? [<div key={idx} style={{minWidth: '90px', fontSize: '12px'}}>{elem}</div>] : [...accu,
                        <div key={idx} style={{minWidth: '90px', fontSize: '12px'}}>{elem}</div>];
                }, null)}</div>
            </>,
            'cost': <div>
                {!item.nursesAssigned && <span>N/A</span>}
                {item.nursesAssigned && (assignedNurseId < 1 || assignedNursePersonalInformationId < 1) && <span>Err</span>}
                {item.nursesAssigned && assignedNurseId > 0 && assignedNursePersonalInformationId > 0 && !isFreelanceNurse && <span>INT</span>}
                {item.nursesAssigned && assignedNurseId > 0 && isFreelanceNurse && (amountReservedCalculated >= 0 ? (<span>${amountReservedCalculated}</span>) : (<span>N/A</span>))}
            </div>,
            // 'nursesAssigned': item.nursesAssigned
            // menuIcon: <MoreOptionDropdown serviceRequestId={item.id} nursesAssigned={item.nursesAssigned} jobRelated={item.jobId != null} currentPage={currentPage}/>,
            menuIcon: <MoreOptionDropdown serviceRequestId={item.id} nursesAssigned={item.nursesAssigned} jobRelated={item.jobId != null} loadMethod={loadMethod} totalAmountReserved={item.totalAmountReservedCalculated} amountReserved={item.amountReservedCalculated} />,
            importantRow: hasCurrentDayTask,
            highlightRow,
        }
    });

    // const openModal = (id) => {
    //   setClientId(id)
    //   fetchData()
    //   setIsModalOpen(true);
    // };

    // const closeModal = () => {
    //   setClientId('')
    //   setIsModalOpen(false);
    // };

    const costTooltipText = (agencyBalance - totalAmountReservedCalculated) < 0 ? <span>Overcosting by <strong>${(totalAmountReservedCalculated - agencyBalance).toFixed(2)}</strong></span> : <span>Cost <strong>&#x28;${totalAmountReservedCalculated.toFixed(2)}&#x29;</strong> is under control</span>;

    const serviceHeaderData = [
        {
            title: "Recipients",
            dataIndex: "Recipients",
            // align: 'left',
            // width: '200px',
        },
        {
            title: "Age",
            dataIndex: "age",
            width: '47px',
        },
        {
            title: "Service Required",
            dataIndex: "serviceRequired",
            width: '105px',
        },
        {
            title: "Duration",
            dataIndex: "duration",
            width: '220px',
        },
        {
            title: "Hours",
            dataIndex: "hours",
            width: '60px',
        },
        // {
        //     title: "Frequency",
        //     dataIndex: "frequencies",
        //     render: (text) => {
        //         return text.map(item => {
        //             const firstThreeLetters = item.frequency.substring(0, 3);
        //             return firstThreeLetters;
        //         }).join(', ');
        //     }
        // },
        {
            title: "Location",
            dataIndex: "location",
            // width: '105px',
        },
        {
            title: "Nurses",
            dataIndex: "nurseName",
            width: '95px',
        },
        {
            title: "Status",
            dataIndex: "status",
            width: '95px',
        },
        {
            title: <div>
                <span
                  // title={costTooltipText}
                  className={`${(agencyBalance - totalAmountReservedCalculated) < 0 ? 'text-danger' : 'text-success'}`}>Cost</span>
                <InfoPopover popoverText={costTooltipText} popoverIdValue="cost-info" />
            </div>,
            dataIndex: "cost",
            width: '55px',
            relativePosition: true,
        },
        // {
        //     title: "",
        //     dataIndex: "Action",
        // }
        {
            title: "",
            dataIndex: "menuIcon",
            width: '35px',
        }
    ];

    // const deleteServiceRequest = id => {
    //     const message = "Are you sure you want to delete this record ?"
    //     // const apiEndPoint = "api/delete"
    //     // openConfirmationDialog(id, message, apiEndPoint);
    //     setSrIdForDeletion(id);
    //     // openConfirmationDialog(message);
    //     setWarningModal(message)
    // };
    //
    // // const editServiceARequest = () => {
    // //
    // // }
    //
    // const editServiceRequest = async (id) => {
    //     await dispatch(HttpsAction({
    //         apiSection: 'client',
    //         apiName: 'serviceDetails',
    //         queryParameter: `/${id}`,
    //         actionType: `GET_SERVICE_DETAILS_SINGLE_REQUEST`,
    //         reduxKeyName: 'serviceDetails',
    //         reducerName: 'SERVICE_REQUEST',
    //     }));
    //     setEditServiceRequestModal(true)
    // };
    //
    // const closeEditServiceRequestDialog = id => {
    //     setEditServiceRequestModal(false)
    //     dispatch(getAllServiceRequest('all', currentPage));
    // };


    // const actionButton = [
    //     {
    //         actionName: 'Edit',
    //         buttonAction: (id) => editServiceRequest(id),
    //         actionClass: 'btn-primary',
    //         iconClass: 'fa-user-plus',
    //     },
    //     // {
    //     //     actionName: 'Delete',
    //     //     buttonAction: (id) => deleteServiceRequest(id),
    //     //     actionClass: 'btn-danger',
    //     //     iconClass: 'fa-trash'
    //     // }
    // ];

    // const fetchData = async () => {
    //   try {
    //     const response = await dispatch(getAllServiceRequest('nurse'));
    //     setNurseList(response.data)
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };


    const handleDisagree = () => {
        setWarningModal(false)
    };

    const handleAgree = () => {
        if (srIdForDeletion > 0) {
            dispatch(HttpsAction({
                method: 'DELETE',
                apiSection: 'serviceRequests',
                apiName: 'deleteRequest',
                queryParameter: `/${srIdForDeletion}`,
                // actionType: `GET_SERVICE_DETAILS`,
                // reduxKeyName: 'serviceDetails',
                // reducerName: 'CLIENT',
                noResponseDispatch: true,
                positiveCallBack: (r) => {
                    setSrIdForDeletion(0);
                    // dispatch(getAllServiceRequest('all', currentPage));
                    loadMethod();
                },
                negativeCallBack: (e) => {
                    alert(JSON.stringify(e));
                },
            }));
        }
        setWarningModal(false)
    };

    const handleServiceDetailsModal = (patientId) => {
        dispatch({
            type: `ADD_CLIENT_REMOVE_TASK_DETAILS_DATA`,
            payload: {},
            name: `taskDetails`,
            meta: {type: `REMOVE_TASK_DETAILS_DATA`},
        });
        if (!serviceDetails.modal) {
            dispatch(HttpsAction({
                apiSection: 'client',
                apiName: 'serviceDetails',
                queryParameter: `/${patientId}`,
                actionType: `GET_SERVICE_DETAILS`,
                reduxKeyName: 'serviceDetails',
                reducerName: 'CLIENT',
            }))
        }
        dispatch({
            type: `ADD_CLIENT_UPDATE_SERVICE_DETAILS_MODAL_FLAG`,
            payload: !isServiceDetails,
            name: `isServiceDetails`,
            meta: {type: `UPDATE_SERVICE_DETAILS_MODAL_FLAG`},
        });
        setServiceDetails(prevState => ({...prevState, modal: !prevState.modal}))
    }

    useEffect(() => {
        if (allServiceRequestsList != null && allServiceRequestsList?.rows?.length != null) {
            setPaginationTab(allServiceRequestsList);
        }
    }, [allServiceRequestsList]);

    // useEffect(() => {
    //     dispatch(getAllServiceRequest('all', currentPage));
    // }, [currentPage]);
    //
    // useEffect(() => {
    //     dispatch(getAllServiceRequest('all', 1, searchText));
    // }, [searchText]);

    // useEffect(() => {
    //     if (searchText != null && searchText.length > 0)
    //         dispatch(getAllServiceRequest('all', 1, searchText));
    //     else
    //         dispatch(getAllServiceRequest('all', currentPage));
    // }, [currentPage, searchText]);

    return (
        <>
            {/*<div className="container-fluid bg-light">
                <div className="bg-light-gray px-3 py-3">
                    <div className="row">
                        <div className="container-fluid">
                            <div className="tab-content" id="pills-tabContent">
                                <div className="bg-white mt-3">
                                    <Table headers={serviceHeaderData} rows={transformedArray} hasFixedWidth={true} tableClass="visits-table" />
                                </div>
                            </div>
                            <Pagination
                                currentPage={allServiceRequestsList?.page}
                                totalRecords={allServiceRequestsList?.total || 0}
                                limit={pageLimit}
                                onPageChange={(page) => {
                                    setCurrentPage(page);
                                }}
                            ></Pagination>
                        </div>
                    </div>
                </div>
            </div>*/}
            <NewTableLayout listData={allServiceRequestsList} loadMethod={loadMethod} loadDeps={[searchText]}>
                <Table headers={serviceHeaderData} rows={transformedArray} hasFixedWidth={true} tableClass="visits-table" />
            </NewTableLayout>
            <WarningModal
                open={warningModal}
                desc={warningModal}
                handleAgree={handleAgree}
                handleDisagree={handleDisagree}
                onClose={() => setWarningModal(false)}
            />
            {/*<CustomDialog title={"Edit Service Request"} open={editServiceRequestModal} maxWidth={'md'}
                          onClose={closeEditServiceRequestDialog}>
                <EditRequest onClose={closeEditServiceRequestDialog}/>
            </CustomDialog>*/}
        </>
    );
};

const mapStateToProps = state => {
    const {serviceRequest} = state;
    return {
        allServiceRequestsList: serviceRequest.allRequests || {},
    };
};

export default connect(mapStateToProps)(AllRequest);
