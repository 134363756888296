import React, {lazy, useEffect, useState} from "react";
import CustomTable from "../../../sharedComponents/customTable";
import {connect, useDispatch, useSelector} from "react-redux";
import {HttpsAction} from "../../../commonApiFunction/httpsAction";
import {useLocation, useNavigate, useOutletContext} from "react-router-dom";
import Table from "../../../sharedComponents/table/Table";
import {getAllServiceRequest, getCountedVisits} from "../../../services/serviceRequest";
import Pagination from "../../../sharedComponents/pagination";
import {pageLimit} from "../../../appConstants";
import rowHelperFunctions from "../../../helpers/row.helpers";
// import TaskDetails from "../TaskDetails";
import {Dialog} from "@mui/material";
import dateHelperFunctions from "../../../helpers/date.helpers";
import PaymentSuccessModal from "./PayToCaregiver/PaymentSuccessModal";
import CommonPaginationNew from "../../../sharedComponents/pagination/CommonPaginationNew";
import NewTableLayout from "../shared/NewTableLayout";

const TaskDetails = lazy(() => import("../TaskDetails"));

const CompleteRequest = ({/*searchText, */completedVisitsList}) => {
    const backButtonTextSuffix = 'Completed Visits';
    const backLink = '/agency/servicerequest/completeRequest';

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { searchText, setPaginationTab } = useOutletContext();

    // const completeAssessmentList = useSelector(state => state.serviceRequest?.completeAssessment?.data?.rows)
    // console.log({completeAssessmentList})

    const [currentPage, setCurrentPage] = useState(0);
    // const [showTaskDetails, setShowTaskDetails] = useState(false);
    const [taskIdToEdit, setTaskIdToEdit] = useState(0);
    // const [viewDialogIsOpen, setViewDialogIsOpen] = useState(false);
    // const [actionButtonsList, setActionButtonsList] = useState([]);
    const [showPaymentSuccessModal, setShowPaymentSuccessModal] = useState(false);
    const [selectedTaskDetails, setSelectedTaskDetails] = useState(null);

    const taskIdToSelect = location?.state != null && location?.state?.taskId != null ? location.state.taskId : 0;

    const transformedArray = completedVisitsList?.rows?.map(item => {
        let statusClass = 'text-bg-success';
        let statusText = item.taskStatus;
        if (item.clockOut == null) {
            statusClass = 'text-bg-danger';
            statusText = 'Clock-out missing';
        } else {
            if (item.nurseSignatureDocumentLink == null || item.patientSignatureDocumentLink == null) {
                statusClass = 'text-bg-warning';
                statusText = 'Signatures missing';
            }
        }

        // let {relatedText, relatedTextColor, relatedLink, relatedId, hasValidChartingId} = rowHelperFunctions.getRelatedInfo(item);
        let { hasValidChartingId, relatedInfoList} = rowHelperFunctions.getRelatedInfo(item);

        const highlightRow = taskIdToSelect === item.taskId;

        return {
            'id': {taskId: item.taskId, serviceRequestId: item.serviceRequestId, taskStatusId: item.taskStatusId, amountPaid: item.amountPaid, agencyWasRated: item.agencyWasRated, assignedNurseId: item.assignedNurseId, assignedNurseFirstName: item.assignedNurseFirstName, allowPayment: item.clockOut != null && item.nurseSignatureDocumentLink != null && item.patientSignatureDocumentLink != null, assignedNurseIsFreelance: item.assignedNurseIsFreelance},
            'Recipients': rowHelperFunctions.getClientRowItem(navigate, item.patientId, item.patientFullName, false, backButtonTextSuffix, backLink, hasValidChartingId ? `/agency/clients/${item.patientId}/charting/0` : null),
            'age': item.patientAge,
            // 'serviceRequired': item.serviceRequestType,
            'serviceRequired': <div className="position-relative1 p-0 m-0">
                <span>{item.serviceRequestType}</span>
                {/*{relatedText && <div className="position-absolute top-22 start-50 translate-middle cursor-pointer" onClick={() => {
                    navigate(relatedLink, {
                        state: {
                            taskId: relatedId,
                        },
                    });
                }}><span className={`badge rounded-pill text-wrap lh-1 text-bg-${relatedTextColor}`}>{relatedText}</span></div>}*/}
                {(relatedInfoList || []).length > 0 && <div className="position-absolute top-17 start-50 translate-middle cursor-pointer w-100 d-flex justify-content-center gap-1">{relatedInfoList.map(({relatedTextColor, relatedText, relatedLink, relatedState}, index) => <span key={index} onClick={() => {
                    if (relatedLink != null) navigate(relatedLink, { state: relatedState });
                }} className={`badge rounded-pill text-wrap lh-1 text-bg-${relatedTextColor}`}>{relatedText}</span>)}</div>}
            </div>,
            // 'duration': <>
            //     <div
            //         className="d-block">{`${new Date(item.startDate).toLocaleDateString('en-GB')} - ${new Date(item.endDate).toLocaleDateString('en-GB')}`}</div>
            //     <div className="d-block">{`${item.clockIn} - ${item.clockOut}`}</div>
            // </>,
            // 'duration': <><div className="d-block" style={{width: '85px'}}>{item.taskDate}</div><div className="d-block">{`${item.scheduledStartTime} - ${item.scheduledEndTime}`}</div></>,
            // 'duration': <><span
            //   className="d-block">{item.taskDateFormatUs}</span><span
            //   className="d-block" style={{fontSize: '13px', minWidth: '125px'}}>{item.expectedTimings}</span></>,
            // 'duration': <span className="d-block" style={{fontSize: '12px', minWidth: '180px'}}>{item.expectedTimingsWithDate}</span>,
            'duration': <div className="d-flex flex-column gap-0" style={{minWidth: '125px'}}>
                <span className="d-block" style={{fontSize: '14px'}}>{item.taskDateFormatUs}</span>
                <span className="d-block" style={{fontSize: '12px'}}>{item.scheduledTimingsAmPm}</span>
            </div>,
            'hours': dateHelperFunctions.hoursAndMinutesToText(item.totalScheduledHours, item.totalScheduledMinutesLessHours),
            // 'frequencies': item.frequencies,
            'location': item.patientLocation || 'N/A',
            // 'nurseName': item?.assignedNurseFirstName || 'N/A',
            'nurseName': rowHelperFunctions.getSingleNurseRowItem(navigate, item?.assignedNurseId, item?.assignedNurseFullName, false, item?.assignedNurseIsFreelance, backButtonTextSuffix, backLink, hasValidChartingId ? `/agency/nurses/edit/${item?.assignedNurseId}/charting` : null),
            'status': <span className={`badge rounded-pill text-wrap lh-1 ${statusClass}`}>{statusText}</span>,
            highlightRow,
            importantRow: item.isCurrentDayTask,
            // 'taskTable': item.tasks.map(item2 => {
            //     return {
            //         'task': item2.task,
            //         'date': new Date(item2.date).toLocaleDateString('en-GB'),
            //         'status': <span
            //             className={`badge rounded-pill ${item2.status === 'Pending' ? 'text-bg-warning' : 'text-bg-success'}`}>{item2.status}</span>
            //     }
            // }),
            'task': item.tasks,
        }
    });

    const serviceHeaderData = [
        {
            title: "Recipients",
            dataIndex: "Recipients",
            // align: 'left',
            width: '180px',
        },
        {
            title: "Age",
            dataIndex: "age",
            width: '47px',
        },
        {
            title: "Service Required",
            dataIndex: "serviceRequired",
            width: '100px',
        },
        {
            title: "Date/Timings",
            dataIndex: "duration",
            width: '130px',
        },
        {
            title: "Hours",
            dataIndex: "hours",
            width: '75px',
        },
        // {
        //     title: "Frequency",
        //     dataIndex: "frequencies",
        //     render: (text) => {
        //         return text.map(item => {
        //             const firstThreeLetters = item.frequency.substring(0, 3);
        //             return firstThreeLetters;
        //         }).join(', ');
        //     }
        // },
        {
            title: "Location",
            dataIndex: "location",
        },
        {
            title: "Nurse",
            dataIndex: "nurseName",
            width: '120px',
        },
        {
            title: "Status",
            dataIndex: "status",
            width: '92px',
        },
        // {
        //     title: "Status",
        //     dataIndex: "status",
        // },
        // {
        //     title: "",
        //     dataIndex: "Action",
        // }
    ];

    // const innerTableHeader = ['Task Name', 'Task Date', 'Status']

    const actionButton = [
        {
            actionName: 'View',
            buttonAction: (specificData) => handleDialog(specificData.taskId),
            actionClass: 'btn-primary',
            iconClass: 'fa-user-plus',
        },
        {
            actionName: 'Pay to nurse',
            buttonAction: (specificData) => {
                // navigate(`/agency/service-request/pay-to-taregiver/${specificData.taskId}/${specificData.serviceRequestId}`)
                navigate(`/agency/pay-to-nurse/${specificData.taskId}/${specificData.serviceRequestId}`)
            },
            actionClass: 'btn-primary',
            iconClass: 'fa-user-plus',
            disableActionButtonFunc: (specificData) => !specificData.allowPayment,
            hideActionButtonFunc: (specificData) => !(specificData.taskStatusId === 4 || !specificData.assignedNurseIsFreelance),
        },
        {
            actionName: 'Rate Nurse',
            buttonAction: (specificData) => {
                setSelectedTaskDetails({
                    id: specificData.taskId,
                    total: specificData.amountPaid,
                    assignedNurseId: specificData.assignedNurseId,
                    assignedNurseFirstName: specificData.assignedNurseFirstName,
                });
                setShowPaymentSuccessModal(true);
            },
            actionClass: 'btn-primary',
            iconClass: 'fa-user-plus',
            // disableActionButtonFunc: (specificData) => specificData.amountPaid != null && !specificData.agencyWasRated,
            hideActionButtonFunc: (specificData) => {
                if (specificData.assignedNurseIsFreelance) {
                    if (specificData.taskStatusId > 4) {
                        return !(specificData.amountPaid != null && !specificData.agencyWasRated);
                    }
                }
                return true;
                // return specificData.taskStatusId > 4 && !(specificData.amountPaid != null && !specificData.agencyWasRated) || !(specificData.taskStatusId === 4 || !specificData.assignedNurseIsFreelance);
            },
        },
    ];

    const handleDialog = (id) => {
        if (id != null && id > 0) {
            setTaskIdToEdit(id);
            // setTimeout(() => {
            //     setShowTaskDetails(true);
            // }, 2000);
        }
    };

    const closeViewDialog = () => {
        // setShowTaskDetails(false);
        setTaskIdToEdit(0);
        dispatch(HttpsAction({
            actionType: `GET_TASK_DETAILS`,
            reduxKeyName: 'taskDetails',
            reducerName: 'SERVICE_REQUEST',
            removeData: true,
        }));
    };

    // function reload() {
    //     if (location?.state != null && 'serviceRequestId' in location.state) {
    //         if (searchText != null && searchText.length > 0)
    //             dispatch(getCountedVisits('completed', currentPage, searchText, location.state.serviceRequestId));
    //         else
    //             dispatch(getCountedVisits('completed', currentPage, null, location.state.serviceRequestId));
    //     } else {
    //         if (searchText != null && searchText.length > 0)
    //             dispatch(getCountedVisits('completed', 1, searchText));
    //         else
    //             dispatch(getCountedVisits('completed', currentPage));
    //     }
    // }

    const loadMethod = (curPage, toSearch) => {
        const useDefault = curPage == null && toSearch == null;
        if (useDefault) dispatch(getCountedVisits('completed', 1));
        else {
            if (location?.state != null && 'serviceRequestId' in location.state) {
                if (toSearch != null && toSearch.length > 0)
                    dispatch(getCountedVisits('completed', curPage, toSearch, location.state.serviceRequestId));
                else
                    dispatch(getCountedVisits('completed', curPage, null, location.state.serviceRequestId));
            } else {
                if (toSearch != null && toSearch.length > 0)
                    dispatch(getCountedVisits('completed', 1, toSearch));
                else
                    dispatch(getCountedVisits('completed', curPage));
            }
        }
    };

    useEffect(() => {
        if (completedVisitsList != null && completedVisitsList?.rows?.length != null) {
            setPaginationTab(completedVisitsList);
        }
    }, [completedVisitsList]);

    // useEffect(() => {
    //     reload();
    // }, [currentPage, searchText, location?.state]);

    return (
      <>
          {/*<div className="container-fluid bg-light">
              <div className="bg-light-gray px-3 py-3">
                  <div className="row">
                      <div className="container-fluid">
                          <div className="tab-content" id="pills-tabContent">
                              <div className="bg-white mt-3">*/}
                                  {/*<Table headers={serviceHeaderData} rows={transformedArray} actions={actionButton} hasFixedWidth={true} actionColWidth="115px" tableClass="visits-table" />*/}
                                  {/*<CustomTable*/}
                                  {/*    headers={serviceHeaderData}*/}
                                  {/*    rows={transformedArray}*/}
                                  {/*    innerTableHeader={innerTableHeader}*/}
                                  {/*    actions={actionButton}*/}
                                  {/*/>*/}
                              {/*</div>
                          </div>*/}
                          {/*<Pagination
                            currentPage={currentPage}
                            totalRecords={completedVisitsList?.total}
                            limit={pageLimit}
                            onPageChange={(page) => {
                                setCurrentPage(page);
                            }}
                          ></Pagination>*/}
                          {/*<CommonPaginationNew data={completedVisitsList} onPageChange={(page) => setCurrentPage(page)} />*/}
                      {/*</div>
                  </div>
              </div>
          </div>*/}
          <NewTableLayout listData={completedVisitsList} loadMethod={loadMethod} loadDeps={[searchText]}>
              <Table headers={serviceHeaderData} rows={transformedArray} actions={actionButton} hasFixedWidth={true} actionColWidth="115px" tableClass="visits-table" />
          </NewTableLayout>
          <Dialog
            open={taskIdToEdit > 0}
            onClose={() => closeViewDialog()}
            maxWidth={"lg"}
            aria-labelledby={"form-dialog-title"}>
              <div className={'p-4'}>
                  <TaskDetails showingDataFor="completedVisits" taskIdToEdit={taskIdToEdit} onClose={(taskModified) => {
                      closeViewDialog();
                      if (taskModified) {
                          dispatch(getCountedVisits('completed', currentPage));
                      }
                  }} />
              </div>
          </Dialog>
          {showPaymentSuccessModal && selectedTaskDetails != null && <PaymentSuccessModal onClose={() => {
              setSelectedTaskDetails(null);
              setShowPaymentSuccessModal(false);
              // reload();
              loadMethod(1, '');
          }} isOpen={showPaymentSuccessModal && selectedTaskDetails != null} taskDetails={selectedTaskDetails} />}
      </>
    );

}

const mapStateToProps = (state) => {
    const { serviceRequest } = state;
    return {
        completedVisitsList: serviceRequest?.completedRequests || {},
    };
};

export default connect(mapStateToProps)(CompleteRequest);
