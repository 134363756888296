import React from "react";

// const PaginationSummary = ({heading = null, listInfo, showTotalWithHeading = false}) => <>
//   {heading != null && <h3 className="mb-1">
//     {heading}&nbsp;{showTotalWithHeading && <mark>{listInfo?.total}</mark>}
//   </h3>}
//   <div className="pb-0 mb-1">
//     <p>
//       Showing&nbsp;Results&nbsp;<strong>{`${listInfo?.page > 1 ? ((listInfo?.page - 1) + 1) : `${listInfo?.count > 0 ? 1 : 0} - ${listInfo?.count > 0 ? listInfo?.count : 0}`}`}</strong>&nbsp;of&nbsp;<strong>{listInfo?.total}</strong>
//     </p>
//   </div>
// </>;

const PaginationSummary = ({heading = null, listInfo, showTotalWithHeading = false}) => {
  const total = listInfo?.total || 0;
  const from = total > 0 ? (listInfo?.page === 1 ? 1 : ((listInfo?.page - 1) * listInfo?.pageSize) + 1) : 0;
  const to = total > 0 ? ((listInfo?.page - 1) * listInfo?.pageSize) + listInfo?.count : 0;

  return <>
    {heading != null && <h3 className="mb-1">
      {heading}&nbsp;{showTotalWithHeading && <mark>{total}</mark>}
    </h3>}
    <div className="pb-0 mb-1">
      <p>
        Showing&nbsp;Results&nbsp;
        <strong>{from} - {to}</strong>&nbsp;of&nbsp;
        <strong>{total}</strong>
      </p>
    </div>
  </>;
};

export default PaginationSummary;
