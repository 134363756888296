import React, {useState, useEffect} from "react";
import {connect} from 'react-redux'
import {Field, reduxForm} from 'redux-form';
import CustomInputField from '../../../../sharedComponents/reduxfields/customInputField'
import CustomCheckboxField from '../../../../sharedComponents/reduxfields/CustomCheckBox'
import {required, emailValidation} from '../../../../helpers/validation'
import Autocomplete from '../../../../sharedComponents/googleAddressAuto/index';
import {useDispatch} from 'react-redux';
import {change} from 'redux-form';
import {registerField, unregisterField} from 'redux-form';
import {getCityList, getStateList} from "../../../../services/commonServices";
import CustomRadioGroup from "../../../../sharedComponents/reduxfields/customRadioGroup";
import {useQueryClient} from "@tanstack/react-query";
import CustomDropDownNewField from "../../../../sharedComponents/reduxfields/customDropDownNew";
import CustomLabel from "../../../../sharedComponents/fields/CustomLabel";
import CustomAddressAutocomplete from "../../../../sharedComponents/reduxfields/CustomAddressAutocomplete";
import CustomDropDownField from "../../../../sharedComponents/reduxfields/customDropDown";
import numberHelperFunctions from "../../../../helpers/number.helpers";

const chooseOrCreateClientOptions = [
    {label: 'Choose existing Client', id: "yes1", value: 'true'},
    {label: 'Create a new Client', id: "no1", value: 'false'},
];

const PatientInformationTab = ({address, patientGeoCoordinatesValid, getClientId, clientId, agencyId, showNurseSelection = false, disableNurseSelection = true, disablePatientSelection = false}) => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();

    const gendersArray = queryClient.getQueryData(['list-genders']);
    const statesArray = queryClient.getQueryData(['list-provinces-states', 233]);
    const patientsArray = queryClient.getQueryData(['list-patients', agencyId]);
    // const patientsArray = queryClient.getQueryData(['list-patients-with-latlng', agencyId]);
    const nursesArray = queryClient.getQueryData([showNurseSelection && !disableNurseSelection ? 'list-internal-nurses' : 'list-nurses', agencyId]);

    const [selectedPlace, setSelectedPlace] = useState(null);
    const [citiesList, setCitiesList] = useState([]);
    const [doChooseClient, setDoChooseClient] = useState(true);

    const handlePlaceSelected = place => {
        address(place);
        setSelectedPlace(place);
        if (place.provinceOrStateName != null && place.provinceOrStateName.length > 0) {
            const selectedStateItem = statesArray.find(s => s.name === place.provinceOrStateName)
            dispatch(change('newServiceRequestForm', 'provinceOrStateId', String(selectedStateItem.id)));
            setCitiesList([]);
            // IIFE
            (async () => {
                const response = await dispatch(getCityList(String(selectedStateItem.id)));
                setCitiesList(response?.data);
            })();
        }
    };

    const handleStateChanged = (e) => {
        let index = e.target.selectedIndex;
        let optionElement = e.target.childNodes[index];
        let optionattr = optionElement.getAttribute("value");
        setCitiesList([]);
        // IIFE
        (async () => {
            const response = await dispatch(getCityList(optionattr));
            setCitiesList(response?.data);
        })();
    };

    const handleChooseOrCreateClientSelection = (e) => {
        if (e.target.value === 'false') {
// console.log('registering fields')
            // const fieldNames = ['firstName', 'lastName', 'middleName', 'dateOfBirth', 'email', 'addressLine1', 'provinceOrStateId', 'cityId', 'postalOrZipCode', 'genderId'];
            // fieldNames.forEach(fieldName => {
            //     if (clientId === null) {
            //         dispatch(registerField('newServiceRequestForm', fieldName));
            //     } else {
            //         dispatch(unregisterField('newServiceRequestForm', fieldName));
            //         dispatch(change('newServiceRequestForm', fieldName, ''));
            //         setSelectedPlace(null);
            //     }
            // });
        }
        setDoChooseClient(e.target.value === 'true');
    };

    useEffect(() => {
        if (selectedPlace != null && citiesList != null && citiesList.length > 0) {
            if (selectedPlace.cityName != null && selectedPlace.cityName.length > 0) {
                const selectedStateItem = citiesList.find(c => c.name === selectedPlace.cityName)
                if (selectedStateItem != null)
                    dispatch(change('newServiceRequestForm', 'cityId', String(selectedStateItem.id)));
            }
        }
    }, [citiesList]);

    useEffect(() => {
        // const fieldNames = ['firstName', 'lastName', 'middleName', 'age', 'email', 'addressLine1', 'provinceOrStateId', 'cityId', 'postalOrZipCode', 'genderId'];
        // const fieldNames = ['firstName', 'lastName', 'middleName', 'dateOfBirth', 'email', 'addressLine1', 'provinceOrStateId', 'cityId', 'postalOrZipCode', 'genderId'];
        // fieldNames.forEach(fieldName => {
        //     if (clientId == null) {
        //         dispatch(registerField('newServiceRequestForm', fieldName));
        //     } else {
        //         dispatch(unregisterField('newServiceRequestForm', fieldName));
        //         dispatch(change('newServiceRequestForm', fieldName, ''));
        //         setSelectedPlace(null)
        //     }
        // });

        if (clientId != null) {
            setSelectedPlace(null)
        }
    }, [clientId]);

    // useEffect(() => {
    //     if (statesArray.length === 0) {
    //         dispatch(getStateList());
    //     }
    // }, [statesArray]);

    useEffect(() => {
        dispatch(change('newServiceRequestForm', 'chooseOrCreateClientSelection', 'true'));
        // dispatch(change('newServiceRequestForm', 'iAgree', 'true'));
    }, []);

    return (
      // <div className="col-12 mb-2" style={{maxWidth : "900px"}}>
      //     <div className="row">
      //         <div className="tab-inner-heading">Patient Information</div>
      //         <div className="neo1 col-12 col-xl-9 py-2 px-4 m-0 border border-black rounded-4 border-opacity-10">
      <div className="col" style={{width: 'max-content', maxWidth: 'auto'}}>
          <div className="tab-inner-heading" style={{width: 'max-content', maxWidth: 'auto'}}>Patient Information</div>
          <div className="neo1 py-2 px-4 m-0 border border-black rounded-4 border-opacity-10">
              <Field
                name="chooseOrCreateClientSelection"
                component={CustomRadioGroup}
                options={chooseOrCreateClientOptions}
                onChange={(e) => handleChooseOrCreateClientSelection(e)}
                disabled={disablePatientSelection}
              />
              {/*{doChooseClient && <div className="p-0 m-0 border1 border1-black">
               <div className="form-group fg-new row">
               <div className="col-12 col-sm-6">
               <label className="tab-inner-sub-heading">Choose Client<span className="mandatory-field">&nbsp;*</span></label>
               <Field
               className="form-control"
               name="chooseClient"
               component={CustomDropDownNewField}
               disabled={disablePatientSelection}
               onChange={(e) => getClientId(e)}
               >
               <option value={null}>Please select</option>
               {patientsArray?.map((res, index) => {
               return <option key={index}
               value={res.id}>{`${res.firstName} ${res?.middleName || ''} ${res.lastName}`}</option>
               })}
               </Field>
               </div>
               </div>
               </div>}*/}
              <div className="p-0 m-0 border1 border1-black" style={{maxWidth: '540px'}}>
                  {
                      !doChooseClient ? (<>
                          {/*<label className="tab-inner-sub-heading">Create new Client</label>*/}
                          <div className="form-group fg-new row">
                              <div className="col-12 col-sm-6">
                                  <Field className="form-control mk-wi" name="firstName" label="First Name" type="text"
                                         component={CustomInputField} validate={required} requiredMark={true}></Field>
                              </div>
                              {/*<div className="col-12 col-sm-4">
                               <Field className="form-control mk-wi" name="middleName" label="Middle Name" type="text"
                               component={CustomInputField}></Field>
                               </div>*/}
                              <div className="col-12 col-sm-6">
                                  <Field className="form-control mk-wi" name="lastName" label="Last Name" type="text"
                                         component={CustomInputField} validate={required} requiredMark={true}></Field>
                              </div>
                          </div>
                          <div className="form-group fg-new row">
                              <div className="col-12 col-sm-6 ">
                                  {/*<Field className="form-control mk-wi" name="age" label="Age" type="number"
                                   component={CustomInputField} validate={required} requiredMark={true} min="0"></Field>*/}
                                  <Field
                                    className="form-control mk-wi"
                                    name="dateOfBirth"
                                    label="Date of Birth"
                                    type="date"
                                    component={CustomInputField}
                                    requiredMark={true}
                                    validate={required}
                                  />
                              </div>
                              <div className="col-12 col-sm-6">
                                  <Field className="form-control mk-wi" name="email" label="Email" type="email"
                                         component={CustomInputField} validate={[required, emailValidation]} requiredMark={true}></Field>
                              </div>
                          </div>
                          <div className="form-group fg-new row">
                              <div className="col-12">
                                  {/*<div className="d-flex gap-3">
                                   <CustomLabel requiredMark={true} labelText="Landmark" />
                                   {
                                   selectedPlace &&
                                   <label className="selectedLocation">&#x28;Selected Location &#x3d; {selectedPlace.address}&#x29; </label>
                                   }
                                   </div>
                                   <Autocomplete onPlaceSelected={handlePlaceSelected}/>
                                   {
                                   !patientGeoCoordinatesValid && <span className="field-error">This field is required</span>
                                   }*/}
                                  <CustomAddressAutocomplete requiredMark={true} label="Landmark" selectedPlaceAddress={selectedPlace?.address}
                                                             handlePlaceSelected={handlePlaceSelected} error={!patientGeoCoordinatesValid} errorMessage={'This field is required'}/>
                              </div>
                          </div>
                          <div className="form-group fg-new row">
                              <div className="col-12">
                                  <Field className="form-control mk-wi" name="addressLine1" label="Address" type="text"
                                         component={CustomInputField} validate={required} requiredMark={true}></Field>
                              </div>
                          </div>
                          <div className="form-group fg-new row">
                              <div className="col-12 col-sm-4">
                                  <label>State <span className="mandatory-field">*</span></label>
                                  <Field
                                    className="form-control"
                                    name="provinceOrStateId"
                                    component={CustomDropDownNewField}
                                    validate={required}
                                    requiredMark={true}
                                    onChange={(e) => handleStateChanged(e)}
                                  >
                                      <option value="">Please select</option>
                                      {statesArray?.map((res, index) => {
                                          return <option key={index} value={res.id}>{res.name}</option>
                                      })}
                                  </Field>
                              </div>
                              <div className="col-12 col-sm-4">
                                  <label>City <span className="mandatory-field">*</span></label>
                                  <Field
                                    className="form-control"
                                    name="cityId"
                                    component={CustomDropDownNewField}
                                    validate={required}
                                    requiredMark={true}
                                  >
                                      <option value="">Please select</option>
                                      {(citiesList || [])?.map((res, index) => {
                                          return <option key={index} value={res.id}>{res.name}</option>
                                      })}
                                  </Field>
                              </div>
                              <div className="col-12 col-sm-4">
                                  <Field className="form-control mk-wi" name="postalOrZipCode" label="Postal Code"
                                         type="text" component={CustomInputField} validate={required} requiredMark={true}
                                         min="0"></Field>
                              </div>
                          </div>
                          <div className="form-group fg-new row">
                              <div className="col-12 col-sm-4">
                                  <label>Gender <span className="mandatory-field">*</span></label>
                                  <Field
                                    className="form-control"
                                    name="genderId"
                                    component={CustomDropDownNewField}
                                    validate={required}
                                    requiredMark={true}
                                  >
                                      <option value="">Please select</option>
                                      {gendersArray?.map((res, index) => {
                                          return <option key={index} value={res.id}>{res.name}</option>
                                      })}
                                  </Field>
                              </div>
                          </div>
                      </>) : (<>
                          <div className="form-group fg-new row">
                              <div className="col-12 col-sm-6">
                                  {/*<label className="tab-inner-sub-heading">Choose Client<span className="mandatory-field">&nbsp;*</span></label>*/}
                                  <Field
                                    className="form-control"
                                    name="chooseClient"
                                    label="Choose Client"
                                    component={CustomDropDownNewField}
                                    requiredMark={true}
                                    disabled={disablePatientSelection}
                                    onChange={(e) => getClientId(e)}
                                  >
                                      <option value={null}>Please select</option>
                                      {patientsArray?.map((res, index) => {
                                          return <option key={index}
                                                         value={res.id}>{`${res.firstName} ${res?.middleName || ''} ${res.lastName}`}</option>
                                      })}
                                  </Field>
                              </div>
                          </div>
                      </>)
                  }
              </div>
              {showNurseSelection && <>
                  <div className="tab-inner-heading mt-3">Assigned Nurse</div>
                  <div className="neo1 col-12 col-xl-9 py-2 px-4 m-0 border border-black rounded-4 border-opacity-10">
                      <div className="col-12 col-sm-6 mb-4">
                          <Field
                            className="form-control"
                            label="Choose Nurse"
                            name="assignedNurseId"
                            component={CustomDropDownNewField}
                            validate={required}
                            requiredMark={true}
                            disabled={disableNurseSelection}
                          >
                              <option value="">Please Select</option>
                              {nursesArray?.map((res, index) => {
                                  return <option key={index} value={res.id}>{res.nurseFullName}</option>
                                  })}
                              </Field>
                          </div>
                      </div>
                  </>}
                  <div className="col-12 mt-3 newClient">
                      <div className="col-12">
                          <div className="form-agreed-text">
                              It is assumed that all provided details belong to the care
                              recipient, <br/>so, click on I Agree to create request
                          </div>
                      </div>
                      <div className="col-12">
                          <div className="form-check">
                              <Field className="form-check-input" name="iAgree" label="I Agree"
                                     disabled={disablePatientSelection} checkedValue="true"
                                     component={CustomCheckboxField} validate={required}></Field>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          // </div>
    );
};

// const mapStateToProps = (state, ownProps) => {
// // console.log('test- ownProps', ownProps)
//     return {
//         initialValues: {
//             assignedNurseId: numberHelperFunctions.nonZeroPositiveIntegerToString(ownProps?.nurseId),
//             iAgree: ownProps.iAgree,
//         },
//     };
// };

export default PatientInformationTab;
// export default connect(mapStateToProps)(PatientInformationTab);
// export default connect(mapStateToProps)(
//   reduxForm({form: "newServiceRequestForm", enableReinitialize: true})(PatientInformationTab)
// );
