import React, {useEffect, useState} from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import CustomLabel from "../fields/CustomLabel";
import arrayHelperFunctions from "../../helpers/array.helpers";
const animatedComponents = makeAnimated();

const MultiSelectDropdownNew = (props) => {
  const {
    optionsList,
    // setSelectedOptions,
    // initialSelectedOptions,
    label,
    requiredMark,
    name,
  } = props;

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [loadedOnce, setLoadedOnce] = useState(false);

// console.log('optionsList', optionsList)
//   const dropdownOptions = !!optionsList ? optionsList.map((res) => {
//     return {
//       value: res?.id,
//       label: res?.name,
//     };
//   }) : [];
//   const dropdownOptions = arrayHelperFunctions.getMapped(optionsList, ['id', 'name'], ['value', 'label']);
// console.log('test- props.input.value', props.input.value)

  useEffect(() => {
    // console.log('test- selectedOptions', selectedOptions)
  }, [selectedOptions]);

  useEffect(() => {
    if (loadedOnce) {
      // console.log('test- props.input.value', props.input.value)
      if (props.input.value) {
        setSelectedOptions(props.input.value);
      }
    }
  }, [props.input.value]);

  useEffect(() => {
    setLoadedOnce(true);
    // setSelectedOptions(props.input.value || []);
  }, []);

  return (
    <>
      {label != null && <CustomLabel labelFor={name} requiredMark={requiredMark} readOnly={props.readOnly} className={props.labelClass || null} labelText={label}/>}
      <Select
        {...props.input}
        name={name}
        closeMenuOnSelect={false}
        escapeClearsValue={false}
        blurOnSelect
        components={animatedComponents}
        // value={initialSelectedOptions}
        // value={props.input.value || selectedOptions}
        value={selectedOptions}
        isMulti
        // options={dropdownOptions}
        options={optionsList}
        onBlur={(e) => {
          // console.log('test- blur target', e.target)
          // console.log('test- selectedOptions', selectedOptions)
          e.target.value = selectedOptions;
          // console.log('test- blur target value', e.target.value)
        }}
        // onChange={param => setSelectedOptions(param)}
        onChange={param => {
          // props.input.value = param;
// console.log('test- param', param)
          props.input.onChange(param);
          setSelectedOptions(param);
        }}
        styles={{
          multiValueLabel: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: "#0b5ed7",
            color: "#ffffff",
          }),
          multiValueRemove: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: "#0b5ed7",
            color: "#ffffff",
            ":hover": {
              backgroundColor: "#0b5ed7",
              color: "#ffffff",
            },
          }),
          // ...
        }}
      />
      {/*{props.handleClear && <span title="clear" className={`position-absolute top-38 end-2px btn btn-vs btn-close btn-outline-danger`} onClick={props.handleClear}>&nbsp;</span>}*/}
      {props.meta.touched && props.meta.error && (
        <span className={`field-error${(props?.showErrorsFixed) ? ' position-absolute bottom-field-error start-0' : ''}`}>{props.meta.error}</span>)}
    </>
  );
};

export default MultiSelectDropdownNew;
