import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from "react-redux";
import {change, Field, reduxForm} from "redux-form";
import CustomInputField from '../../../../sharedComponents/reduxfields/customInputField';
import CustomDropDownField from '../../../../sharedComponents/reduxfields/customDropDown';
import CheckboxGroup from '../../../../sharedComponents/reduxfields/customCheckboxGroup';
import {required} from '../../../../helpers/validation';
import Modal from '../../../../sharedComponents/Modal';
import MultiSelectDropdown from '../../../../sharedComponents/multiSelect';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker, DesktopTimePicker} from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {useQueryClient} from "@tanstack/react-query";
import dateHelperFunctions from "../../../../helpers/date.helpers";


const AddTaskModal = ({ handleSubmit, submitFailed, isModalOpen, closeModal, modalTitle, addTask, agencyId }) => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();

    // const patientsArray = queryClient.getQueryData(['list-patients-with-latlng', agencyId]);
    const frequencyDaysList = queryClient.getQueryData(['list-day-frequencies']);
    const serviceRequestTasksArray = queryClient.getQueryData(['list-service-request-tasks', agencyId]);

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [taskSelectionValidation, setTaskSelectionValidation] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [filTaskList, setFilTaskList] = useState([]);
    const [showDateRange, setShowDateRange] = useState(false);
    const [showSingleDate, setShowSingleDate] = useState(true);

    const [clockIn, setClockIn] = useState();
    const [clockOut, setClockOut] = useState();
    const [validationError, setValidationError] = useState('');
    const [selectedDate, setSelectedDate] = React.useState('');


    const getSelectedOptions = (selectedOptions) => {
        setSelectedOptions(selectedOptions)
        if(selectedOptions.length > 0){
            setTaskSelectionValidation(false)
        }
        else{
            setTaskSelectionValidation(true)
        }
    }

    const handleClockInChange = (newValue) => {
        setClockIn(newValue);
        if (newValue != null) {
            const dateVal = new Date(Date.parse(newValue));
            const clockInVal = dateVal.toLocaleTimeString('en-US', {hour12: false}).substring(0, 5);
            console.log('Selected clock-in:', newValue, clockInVal, dateVal);
            if (clockOut != null && newValue.isAfter(clockOut)) {
                setValidationError('Clock In time cannot be after Clock Out time');
            } else if (clockOut != null && newValue.isSame(clockOut)) {
                setValidationError('Clock In time cannot be the same as Clock Out time');
            } else {
                const clockInDisplay = dateVal.toLocaleTimeString('en-US', {hourCycle: 'h23', hour: '2-digit', minute: '2-digit', hour12: true});
                dispatch(change('AddTaskForm', 'clockIn', clockInDisplay));
                dispatch(change('AddTaskForm', 'scheduledStartTime', clockInVal));
                setValidationError('');
            }
        }
    };

    const handleClockOutChange = (newValue) => {
        setClockOut(newValue);
        if (newValue != null) {
            const dateVal = new Date(Date.parse(newValue));
            const clockOutVal = dateVal.toLocaleTimeString('en-US', {hour12: false}).substring(0, 5);
            console.log('Selected clock-out:', newValue, clockOutVal, dateVal);
            if (clockIn != null && newValue.isBefore(clockIn)) {
                setValidationError('Clock Out time cannot be before Clock In time');
            } else if (clockIn != null && newValue.isSame(clockIn)) {
                setValidationError('Clock Out time cannot be the same as Clock In time');
            } else {
                const clockOutDisplay = dateVal.toLocaleTimeString('en-US', {hourCycle: 'h23', hour: '2-digit', minute: '2-digit', hour12: true});
                dispatch(change('AddTaskForm', 'clockOut', clockOutDisplay));
                dispatch(change('AddTaskForm', 'scheduledEndTime', clockOutVal));
                setValidationError('');
            }
        }
    };

    const handleDateChange = (newDate) => {
        setSelectedDate(newDate);
        // const dateVal = new Date(Date.parse(newDate));
        // // You can perform additional actions here based on the newDate value
        // // console.log('Selected Date:', newDate, dateVal);
        //
        // const displayDate = dateVal.toLocaleDateString();
        // const dbFormattedDate = dateHelperFunctions.getFormattedDateForDb(dateVal);
        // console.log('Selected Date:', displayDate, dbFormattedDate);
        //
        // dispatch(change('AddTaskForm', 'startDate', dbFormattedDate));
        // dispatch(change('AddTaskForm', 'startDateDisplay', displayDate));
        // if (showSingleDate) {
        //     dispatch(change('AddTaskForm', 'endDate', dbFormattedDate));
        //     dispatch(change('AddTaskForm', 'endDateDisplay', displayDate));
        // }
    };


    const onSubmit = async (values) => {
// console.log('test- values', {...values})
        if (selectedOptions.length > 0) {
            values.dayName = 'All';
            if (showDateRange) {
                const extractedNames = values.modalFrequencyIds.map(id => {
                    const matchingItem = frequencyDaysList.find(item => item.id === id);
                    return matchingItem ? matchingItem.name : null;
                });
                values.dayName = extractedNames;
            }
            // values.id = Math.random().toString(36).substring(2);
            values.id = 0;
            values.taskid = selectedOptions.map(res => res.value);
            values.taskName = selectedOptions.map(res => res.label);
            values.status = 'New';
            values.scheduledStartTimeForCalc = values.scheduledStartTime + ':00';
            values.scheduledEndTimeForCalc = values.scheduledEndTime + ':00';
            values.scheduledEndDateTimeUtc = new Date().toISOString().substring(0,10) + ' ' + values.scheduledEndTimeForCalc;

            // values.startDateDisplay = values.startDate;
            // if (showSingleDate) {
            //     values.endDate = values.startDate;
            //     values.endDateDisplay = values.endDate;
            //     values.modalFrequencyIds = frequencyDaysList.map(item => item.id);
            // }

            const dateVal = new Date(Date.parse(selectedDate));
            const displayDate = dateVal.toLocaleDateString();
            const dbFormattedDate = dateHelperFunctions.getFormattedDateForDb(dateVal);
            console.log('Selected Date:', displayDate, dbFormattedDate);

            values.startDate = dbFormattedDate;
            values.startDateDisplay = displayDate;
            if (showSingleDate) {
                values.endDate = dbFormattedDate;
                values.endDateDisplay = displayDate;
                values.modalFrequencyIds = frequencyDaysList.map(item => item.id);
            }
// console.log('test- final values', values)
            addTask(values);
            closeModal();
        } else {
            setTaskSelectionValidation(true)
        }
    }

    const setTaskCategory = () => {
        const uniqueMap = new Map();
        const uniqueArray = [];
        for (const item of serviceRequestTasksArray) {
          if (!uniqueMap.has(item['taskCategoryId'])) {
            uniqueMap.set(item['taskCategoryId'], true);
            uniqueArray.push(item);
          }
        }
        setCategoryList(uniqueArray)
    }

    const filterTask = (e) => {
        let index = e.target.selectedIndex;
        let optionElement = e.target.childNodes[index]
        let optionattr =  optionElement.getAttribute('value');
        const filterTask = serviceRequestTasksArray?.filter(item => {
            if(item.taskCategoryId == optionattr){
                return item
            }
        })
        setFilTaskList(filterTask)
      }

    useEffect(() => {
        if ((serviceRequestTasksArray || []).length > 0) setTaskCategory();
    },[serviceRequestTasksArray]);

    useEffect(() => {
        submitFailed && setTaskSelectionValidation(true)
      },[submitFailed]);

    return (
      <Modal isOpen={isModalOpen} onClose={closeModal} modalTitle={modalTitle}>
        <form className='addTaskForm' onSubmit={handleSubmit(onSubmit)} id="addTaskForm">
            <Field
              name="scheduledStartTime"
              type="hidden"
              component={CustomInputField}
            />
            <Field
              name="scheduledEndTime"
              type="hidden"
              component={CustomInputField}
            />
            <Field
              name="startDateDisplay"
              type="hidden"
              component={CustomInputField}
            />
            <Field
              name="endDateDisplay"
              type="hidden"
              component={CustomInputField}
            />
            <div className="form-label">
                {showDateRange && <div className="row">
                    <div className="col-sm-6">
                        <Field
                          className="form-control mk-wi"
                          name="startDate"
                          label="Visit Start Date"
                          type="date"
                          component={CustomInputField}
                          requiredMark={true}
                          validate={required}
                        ></Field>
                    </div>
                    <div className="col-sm-6">
                        <Field
                          className="form-control mk-wi"
                          name="endDate"
                          label="Visit End Date"
                          type="date"
                          component={CustomInputField}
                          requiredMark={true}
                          validate={required}
                        ></Field>
                    </div>
                </div>}
                {showSingleDate && <div className="row">
                    <div className="col-sm-6">
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Select Date"
                                value={selectedDate || null}
                                onChange={(newDate) => handleDateChange(newDate)}
                                minDate={new Date()} // Set minimum selectable date to the current date
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </div>
                </div>}
            </div>
            <div className="form-label pt-2">
                <div className="row">
                    <div className="col-sm-6">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopTimePicker
                                label="Clock In"
                                value={clockIn}
                                onChange={handleClockInChange}
                                ampm={false}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </div>
                    <div className="col-sm-6">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopTimePicker
                                label="Clock Out"
                                value={clockOut}
                                onChange={handleClockOutChange}
                                ampm={false}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </div>
                </div>
                {validationError && <p style={{color: 'red'}} className="error-message">{validationError}</p>}
            </div>
            <div className='form-group form-label pt-2'>
            <label>Select Task Category <span className="mandatory-field">*</span></label>
            <Field
                className="form-control"
                name="provinceOrStateId"
                component={CustomDropDownField}
                validate={required}
                requiredMark={true}
                onChange={(e)=> filterTask(e) }
              >
                <option value="">Please select</option>
                {categoryList?.map((res, index)=>{
                    return <option key={index} value={res.taskCategoryId}>{res.taskCategoryName}</option>
                })}
              </Field>
            </div>
            <div className="form-group form-label pt-2">
                <div className="row">
                    <div className="col-sm-12">
                        <MultiSelectDropdown dpOptions={filTaskList} getSelectedOptions={getSelectedOptions} selectedOptions={selectedOptions} required={true} requiredMark={true} label="Select Task"/>
                        {taskSelectionValidation && (<span className="field-error">This field is required</span>)}
                    </div>
                </div>
            </div>
            {showDateRange && <div className="form-group form-label pt-2">
                <div className="row">
                    <div className="col-sm-12">
                        <CheckboxGroup label={'Select Frequency'} buttonCheckBox={true} name="modalFrequencyIds" options={frequencyDaysList} requiredMark={true}/>
                    </div>
                </div>
            </div>}
            <div className="modal-footer justify-content-center pt-4 pb-0 gap-2 mt-4">
                <button type="button" className="btn btn-secondary" onClick={() => closeModal()}>Close</button>
                <button type="submit" className="btn btn-primary">Add</button>
            </div>
        </form>
      </Modal>
    )
}

export default connect()(reduxForm({ form: "AddTaskForm"})(AddTaskModal));
