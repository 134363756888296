import React, {useEffect, useRef, useState} from "react";
import classes from "../../../Client/ClientProfile/CareNote/CareNote.module.css";
import {Dialog, Stack} from "@mui/material";
import Table from "../../../../../sharedComponents/table/Table";
import Status from "./Status";
import {useDispatch, useSelector} from "react-redux";
import {HttpsAction} from "../../../../../commonApiFunction/httpsAction";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import EditCharting from "../../../Client/ClientProfile/Charting/Edit";
import {hoursConvert} from "../../../../../helpers/apiMethods";
import dateHelperFunctions from "../../../../../helpers/date.helpers";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import CustomDialog from "../../../../../sharedComponents/CustomDialog/index";
import PrimaryButton from "../../../../../sharedComponents/buttons/PrimaryButton";
import {useReactToPrint} from "react-to-print";
import apiCallHelpers from "../../../../../helpers/api.call.helpers";
import {pageLimit} from "../../../../../appConstants";
import Pagination from "../../../../../sharedComponents/pagination";
import useCustomNav from "../../../../../utils/hooks/useCustomNav";

const NurseCharting = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const { id: nurseId } = useParams();
    const { nurseId } = useOutletContext();

    const { goTo: gotoInitialAssessmentsChartingPage } = useCustomNav('/agency/charting/initial-assessments');
    const { goTo: gotoSupervisoryVisitsChartingPage } = useCustomNav('/agency/charting/supervisory-visits');
    const { goTo: gotoDischargeSummariesChartingPage } = useCustomNav('/agency/charting/discharge-summaries');
    const { goTo: gotoCarePlansChartingPage } = useCustomNav('/agency/charting/care-plans');

    const printFormRef = useRef(null);

    const chartingList = useSelector(state => state.charting?.chartingList?.data);

    const [open, setOpen] = useState(false);
    const [editTemplateData, setEditTemplateData] = useState(false);

    // const transformedArray = (chartingList?.rows || [])?.map((item, index) => {
    //     return {
    //         name: item?.name,
    //         created: <Stack gap={'5px'}>
    //             <Stack className={classes.dayName}>
    //                 {item?.formCreationDateFormatUsWithTime || 'N/A'}
    //             </Stack>
    //             <Stack className={classes.date}>
    //                 {item.name}
    //             </Stack>
    //         </Stack>,
    //         modified: <Stack gap={'5px'}>
    //             <Stack className={classes.dayName}>
    //                 {item?.formModificationDateFormatUsWithTime || 'N/A'}
    //             </Stack>
    //             <Stack className={classes.date}>
    //                 {item.name}
    //             </Stack>
    //         </Stack>,
    //         expire: dateHelperFunctions.updateDateFormat(item?.expirationDate),
    //         status: <Status/>
    //     }
    // });

    const transformedArray = (chartingList?.rows || [])?.map((item, index) => {
        const offsetCurrentDateTime = new Date(Date.parse(item.offsetCurrentDateTime));
        const scheduledEndDateTimeUtc = new Date(Date.parse(item.scheduledEndDateTimeUtc));
        let taskStatusName = item.taskStatusName;
        if (item.taskStatusId < 3 && item.clockIn == null && offsetCurrentDateTime > scheduledEndDateTimeUtc) taskStatusName = 'Missed';
        if (item.clockIn != null && item.date < offsetCurrentDateTime && item.remainingActivitiesTotal > 0) taskStatusName = 'Incomplete';
        return {
            // name: item?.name,
            name: <div onClick={() => {
                // let url = apiCallHelpers.getChartingTabUrl(item.templateTypeId);
                // if (url != null) {
                //     navigate(url, {
                //         state: {
                //             chartingId: item.chartingId,
                //         },
                //     });
                // }

                if (item.templateTypeId === 1) gotoInitialAssessmentsChartingPage({ chartingId: item.chartingId });
                if (item.templateTypeId === 2) gotoSupervisoryVisitsChartingPage({ chartingId: item.chartingId });
                if (item.templateTypeId === 3) gotoDischargeSummariesChartingPage({ chartingId: item.chartingId });
                if (item.templateTypeId === 4) gotoCarePlansChartingPage({ chartingId: item.chartingId });
            }}><span className={`table-inner-link text-break lh-1`}>{item.name}</span></div>,
            created: <Stack gap={'5px'}>
                <Stack className={classes.dayName}>
                    {item?.formCreationDateFormatUsWithTime || 'N/A'}
                </Stack>
                <Stack className={classes.date}>
                    {item.templateName}
                </Stack>
            </Stack>,
            modified: <Stack gap={'5px'}>
                <Stack className={classes.dayName}>
                    {item?.formModificationDateFormatUsWithTime || 'N/A'}
                </Stack>
                <Stack className={classes.date}>
                    {item.templateName}
                </Stack>
            </Stack>,
            // expire: dateHelperFunctions.updateDateFormat(item?.expirationDate),
            expire: item?.assessmentExpirationDateFormatUs || 'N/A',
            // status: <Status/>
            // status: item.serviceRequestStatus,
            status: taskStatusName,
            visitDate: <Stack>
                <Stack>{item.taskDateFormatUs}</Stack>
                <Stack>{item.expectedTimings}</Stack>
            </Stack>,
        }
    });

    const serviceHeaderData = [
        {
            title: "Name",
            dataIndex: "name",
        },
        {
            title: "Created",
            dataIndex: "created",
        },
        {
            title: "Modified",
            dataIndex: "modified",
        },
        {
            title: "Expire",
            dataIndex: "expire",
        },
        {
            title: "Visit Date / Time",
            dataIndex: "visitDate",
        },
        {
            title: "Status",
            dataIndex: "status",
        }
    ];

    const actionButton = [
        {
            actionName: 'Edit',
            buttonAction: (id, index) => handleModal(chartingList?.rows[index]),
            actionClass: 'btn btn-primary border-btn-info px-4',
            iconClass: '',
        }
    ];

    const handleModal = (data) => {
        if (data !== "closeModal") {
            setEditTemplateData(data);
        }

        if (!open && data?.formDataId != null) {
            dispatch(HttpsAction({
                apiSection: 'forms',
                apiName: 'formsData',
                queryParameter: `/${data?.formDataId}`,
                reduxKeyName: 'formTemplateData',
                actionType: 'GET_FORM_TEMPLATE_BY_ID',
                reducerName: 'CHARTING'
            }));
        }

        if (!open && data?.templateId) {
            dispatch(HttpsAction({
                apiSection: 'forms',
                apiName: 'createTemplate',
                queryParameter: `/${data?.templateId}`,
                reduxKeyName: 'templateData',
                actionType: 'GET_TEMPLATE_BY_ID',
                reducerName: 'CHARTING'
            }));
        }

        if (!data?.templateId) {
            removeTemplateData();
        }
        if (data === "closeModal") {
            getChartingData(chartingList?.page);
            removeTemplateData();
            setEditTemplateData(false)
        }
        setOpen(!open)
    };

    const removeTemplateData = () => {
        const dispatchType = `ADD_CHARTING_GET_TEMPLATE_BY_ID`;
        dispatch({
            type: dispatchType,
            payload: {},
            name: 'templateData',
            meta: {type: 'GET_TEMPLATE_BY_ID'},
        });
    };

    const getChartingData = (page) => {
        dispatch(HttpsAction({
            apiSection: 'charting',
            apiName: 'chartingList',
            queryParameter: `?nurseId=${nurseId}&page=${page}&pageSize=${pageLimit}`,
            reduxKeyName: 'chartingList',
            actionType: 'GET_CHARTING_LIST',
            reducerName: 'CHARTING'
        }))
    };

    const handlePrint = useReactToPrint({
        content: () => printFormRef.current,
        onAfterPrint: () => {
            // Close the modal after printing or cancelling the PDF
            handleModal('closeModal')
        }
    });

    useEffect(() => {
        getChartingData(1);
        return () => setEditTemplateData(false)
    }, []);

    return (
        <>
            <div className={classes.contactMain}>
                <div className={classes.contactFormMain}>
                    <Table headers={serviceHeaderData} rows={transformedArray} actions={actionButton}/>
                </div>

                <Pagination
                    currentPage={chartingList?.page}
                    totalRecords={chartingList?.total}
                    limit={10}
                    onPageChange={getChartingData}
                />
            </div>

            <CustomDialog
                maxWidth={'lg'}
                onClose={() => handleModal('closeModal')}
                open={open}
                dialogContentRef={printFormRef}
                actions={<PrimaryButton
                    type="button"
                    className="btn-view-repo gap-2 px-5 py-2"
                    text={"Print"}
                    onClick={handlePrint}
                />}
                title={'Edit Form/Data'}>
                <EditCharting editTemplateData={editTemplateData} onClose={() => handleModal('closeModal')}/>
            </CustomDialog>
        </>
    )
};
export default NurseCharting;
