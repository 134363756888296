import React, { useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {isLoggedIn, redirectOrLogout} from "../helpers/apiMethods";
import {useDispatch} from "react-redux";

const tabData = [
  {
    pageName: "Dashboard",
    add: "/agency/dashboard",
    icon: (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 10H7C7.55 10 8 9.55 8 9V1C8 0.45 7.55 0 7 0H1C0.45 0 0 0.45 0 1V9C0 9.55 0.45 10 1 10ZM1 18H7C7.55 18 8 17.55 8 17V13C8 12.45 7.55 12 7 12H1C0.45 12 0 12.45 0 13V17C0 17.55 0.45 18 1 18ZM11 18H17C17.55 18 18 17.55 18 17V9C18 8.45 17.55 8 17 8H11C10.45 8 10 8.45 10 9V17C10 17.55 10.45 18 11 18ZM10 1V5C10 5.55 10.45 6 11 6H17C17.55 6 18 5.55 18 5V1C18 0.45 17.55 0 17 0H11C10.45 0 10 0.45 10 1Z"
          fill="#9E9E9E"
        />
      </svg>
    ),
    innerTab: [],
  },
  {
    pageName: " Post Job",
    add: "/agency/postjob",
    icon: (
      <svg
        width="20"
        height="16"
        viewBox="0 0 20 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 16V11H4V12H6V11H14V12H16V11H20V16H0ZM0 6C0 5.45 0.196 4.979 0.588 4.587C0.98 4.195 1.45067 3.99934 2 4H5V2C5 1.45 5.196 0.979002 5.588 0.587002C5.98 0.195002 6.45067 -0.000664969 7 1.69779e-06H13C13.55 1.69779e-06 14.021 0.196001 14.413 0.588001C14.805 0.980001 15.0007 1.45067 15 2V4H18C18.55 4 19.021 4.196 19.413 4.588C19.805 4.98 20.0007 5.45067 20 6V10H16V8H14V10H6V8H4V10H0V6ZM7 4H13V2H7V4Z"
          fill="#9E9E9E"
        />
      </svg>
    ),
    innerTab: [],
  },
  {
    pageName: " Find Nurse",
    // add: "/agency/find-nurses",
    add: "/agency/nurses/find",
    icon: (
      <svg
        width="24"
        height="28"
        viewBox="0 0 24 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.1112 17.4282L12 22.5384L6.88875 17.4282C3.05893 17.5943 0 20.7282 0 24.5956C0 25.4226 0.328544 26.2158 0.913356 26.8006C1.49817 27.3854 2.29134 27.714 3.11839 27.714H20.8816C21.7087 27.714 22.5018 27.3854 23.0866 26.8006C23.6715 26.2158 24 25.4226 24 24.5956C24 20.7282 20.9411 17.5943 17.1112 17.4282ZM12 16.5711C13.8186 16.5711 15.5628 15.8486 16.8487 14.5627C18.1347 13.2767 18.8571 11.5326 18.8571 9.71395V3.81145C18.8576 3.46217 18.7513 3.12109 18.5525 2.83387C18.3538 2.54665 18.072 2.327 17.745 2.20431L13.2038 0.503418C12.4276 0.212402 11.5724 0.212402 10.7963 0.503418L6.255 2.20645C5.92831 2.32901 5.6468 2.54834 5.44808 2.83513C5.24935 3.12193 5.14287 3.46254 5.14286 3.81145V9.71395C5.14286 11.5326 5.8653 13.2767 7.15127 14.5627C8.43723 15.8486 10.1814 16.5711 12 16.5711ZM9.85714 4.12485C9.85714 4.05381 9.88536 3.98568 9.9356 3.93544C9.98583 3.88521 10.054 3.85699 10.125 3.85699H11.2859V2.6961C11.2859 2.62506 11.3141 2.55693 11.3643 2.50669C11.4146 2.45646 11.4827 2.42824 11.5538 2.42824H12.4462C12.5173 2.42824 12.5854 2.45646 12.6357 2.50669C12.6859 2.55693 12.7141 2.62506 12.7141 2.6961V3.85699H13.875C13.946 3.85699 14.0142 3.88521 14.0644 3.93544C14.1146 3.98568 14.1429 4.05381 14.1429 4.12485V5.01735C14.1429 5.08839 14.1146 5.15652 14.0644 5.20675C14.0142 5.25698 13.946 5.2852 13.875 5.2852H12.7141V6.4461C12.7141 6.51714 12.6859 6.58527 12.6357 6.6355C12.5854 6.68573 12.5173 6.71395 12.4462 6.71395H11.5538C11.4827 6.71395 11.4146 6.68573 11.3643 6.6355C11.3141 6.58527 11.2859 6.51714 11.2859 6.4461V5.2852H10.125C10.054 5.2852 9.98583 5.25698 9.9356 5.20675C9.88536 5.15652 9.85714 5.08839 9.85714 5.01735V4.12485ZM7.71429 8.85681H16.2857V9.71395C16.2857 10.8506 15.8342 11.9407 15.0305 12.7444C14.2267 13.5481 13.1366 13.9997 12 13.9997C10.8634 13.9997 9.77327 13.5481 8.96954 12.7444C8.16582 11.9407 7.71429 10.8506 7.71429 9.71395V8.85681Z"
          fill="#9E9E9E"
        />
      </svg>
    ),
    innerTab: [],
    pathMatchIndex: 2,
  },
  {
    pageName: " Service Requests",
    add: "/agency/servicerequest/allrequest",
    icon: (
      <svg
        width="20"
        height="16"
        viewBox="0 0 20 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 16V11H4V12H6V11H14V12H16V11H20V16H0ZM0 6C0 5.45 0.196 4.979 0.588 4.587C0.98 4.195 1.45067 3.99934 2 4H5V2C5 1.45 5.196 0.979002 5.588 0.587002C5.98 0.195002 6.45067 -0.000664969 7 1.69779e-06H13C13.55 1.69779e-06 14.021 0.196001 14.413 0.588001C14.805 0.980001 15.0007 1.45067 15 2V4H18C18.55 4 19.021 4.196 19.413 4.588C19.805 4.98 20.0007 5.45067 20 6V10H16V8H14V10H6V8H4V10H0V6ZM7 4H13V2H7V4Z"
          fill="#9E9E9E"
        />
      </svg>
    ),
    innerTab: [
      {
        innertab_pageName: "All Requests",
        add: "/agency/servicerequest/allrequest",
      },
      {
        innertab_pageName: "Scheduled Visits",
        add: "/agency/servicerequest/scheduledrequest",
      },
      {
        innertab_pageName: "Active Visits",
        add: "/agency/servicerequest/activerequest",
      },
      // {
      //   innertab_pageName: "Pending Visits",
      //   add: "/agency/servicerequest/pendingrequest",
      // },
      {
        innertab_pageName: "Posted Jobs",
        add: "/agency/servicerequest/postedjobs",
      },
      {
        // innertab_pageName: "Charting Requests",
        innertab_pageName: "Charting Visits",
        add: "/agency/servicerequest/charting/initialAssessments",
      },
      {
        innertab_pageName: "Completed Visits",
        add: "/agency/servicerequest/completeRequest",
      },
      {
        innertab_pageName: "Missed Visits",
        add: "/agency/servicerequest/missedRequests",
      },
      {
        innertab_pageName: "In-Complete Visits",
        add: "/agency/servicerequest/incompleteVisits",
      },
    ],
  },
  {
    pageName: "Nurses",
    // add: "/agency/nurses/nurselist",
    add: "/agency/nurses/list/internal",
    icon: (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6 7.5V6H12V7.5H6ZM0 3.75C0 2.75544 0.395088 1.80161 1.09835 1.09835C1.80161 0.395088 2.75544 0 3.75 0H14.25C15.2446 0 16.1984 0.395088 16.9016 1.09835C17.6049 1.80161 18 2.75544 18 3.75V14.25C18 15.2446 17.6049 16.1984 16.9016 16.9016C16.1984 17.6049 15.2446 18 14.25 18H3.75C2.75544 18 1.80161 17.6049 1.09835 16.9016C0.395088 16.1984 0 15.2446 0 14.25V3.75ZM5.25 4.5C5.05109 4.5 4.86032 4.57902 4.71967 4.71967C4.57902 4.86032 4.5 5.05109 4.5 5.25V8.25C4.5 8.44891 4.57902 8.63968 4.71967 8.78033C4.86032 8.92098 5.05109 9 5.25 9H12.75C12.9489 9 13.1397 8.92098 13.2803 8.78033C13.421 8.63968 13.5 8.44891 13.5 8.25V5.25C13.5 5.05109 13.421 4.86032 13.2803 4.71967C13.1397 4.57902 12.9489 4.5 12.75 4.5H5.25Z"
          fill="#9E9E9E"
        />
      </svg>
    ),
    innerTab: [
      // {
      //   innertab_pageName: "Nurses List",
      //   add: "/agency/nurses/nurselist",
      // },
      //
      // {
      //   innertab_pageName: "Documents",
      //   add: "/agency/nurses/documents",
      // },
      // {
      //   innertab_pageName: "Comments/Notes",
      //   add: "/agency/nurses/comments-notes",
      // },
      // {
      //   innertab_pageName: "Heat Map",
      //   add: "/agency/nurses/heatmap",
      // },
      // {
      //   innertab_pageName: "Calender/Scheduler",
      //   add: "/agency/nurses/calender-scheduler",
      // },
      // {
      //   innertab_pageName: "Nurses Alerts",
      //   add: "/agency/nurses/nursesalerts",
      // },
    ],
    pathMatchIndex: 2,
  },
  {
    pageName: "Clients",
    add: "/agency/clients",
    icon: (
      <svg
        width="24"
        height="28"
        viewBox="0 0 24 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.1112 17.4282L12 22.5384L6.88875 17.4282C3.05893 17.5943 0 20.7282 0 24.5956C0 25.4226 0.328544 26.2158 0.913356 26.8006C1.49817 27.3854 2.29134 27.714 3.11839 27.714H20.8816C21.7087 27.714 22.5018 27.3854 23.0866 26.8006C23.6715 26.2158 24 25.4226 24 24.5956C24 20.7282 20.9411 17.5943 17.1112 17.4282ZM12 16.5711C13.8186 16.5711 15.5628 15.8486 16.8487 14.5627C18.1347 13.2767 18.8571 11.5326 18.8571 9.71395V3.81145C18.8576 3.46217 18.7513 3.12109 18.5525 2.83387C18.3538 2.54665 18.072 2.327 17.745 2.20431L13.2038 0.503418C12.4276 0.212402 11.5724 0.212402 10.7963 0.503418L6.255 2.20645C5.92831 2.32901 5.6468 2.54834 5.44808 2.83513C5.24935 3.12193 5.14287 3.46254 5.14286 3.81145V9.71395C5.14286 11.5326 5.8653 13.2767 7.15127 14.5627C8.43723 15.8486 10.1814 16.5711 12 16.5711ZM9.85714 4.12485C9.85714 4.05381 9.88536 3.98568 9.9356 3.93544C9.98583 3.88521 10.054 3.85699 10.125 3.85699H11.2859V2.6961C11.2859 2.62506 11.3141 2.55693 11.3643 2.50669C11.4146 2.45646 11.4827 2.42824 11.5538 2.42824H12.4462C12.5173 2.42824 12.5854 2.45646 12.6357 2.50669C12.6859 2.55693 12.7141 2.62506 12.7141 2.6961V3.85699H13.875C13.946 3.85699 14.0142 3.88521 14.0644 3.93544C14.1146 3.98568 14.1429 4.05381 14.1429 4.12485V5.01735C14.1429 5.08839 14.1146 5.15652 14.0644 5.20675C14.0142 5.25698 13.946 5.2852 13.875 5.2852H12.7141V6.4461C12.7141 6.51714 12.6859 6.58527 12.6357 6.6355C12.5854 6.68573 12.5173 6.71395 12.4462 6.71395H11.5538C11.4827 6.71395 11.4146 6.68573 11.3643 6.6355C11.3141 6.58527 11.2859 6.51714 11.2859 6.4461V5.2852H10.125C10.054 5.2852 9.98583 5.25698 9.9356 5.20675C9.88536 5.15652 9.85714 5.08839 9.85714 5.01735V4.12485ZM7.71429 8.85681H16.2857V9.71395C16.2857 10.8506 15.8342 11.9407 15.0305 12.7444C14.2267 13.5481 13.1366 13.9997 12 13.9997C10.8634 13.9997 9.77327 13.5481 8.96954 12.7444C8.16582 11.9407 7.71429 10.8506 7.71429 9.71395V8.85681Z"
          fill="#9E9E9E"
        />
      </svg>
    ),
    innerTab: [],
  },
  {
    pageName: "Alerts",
    // add: "/agency/alerts/all-alerts",
    add: "/agency/alerts/all",
    icon: (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.503 1C16.4421 1 15.4247 1.42143 14.6746 2.17157C13.9244 2.92172 13.503 3.93913 13.503 5V8L12.553 8.8C12.246 9.06 12.093 9.19 12.046 9.291C11.9907 9.41094 11.9852 9.54787 12.0305 9.67189C12.0759 9.79592 12.1684 9.89698 12.288 9.953C12.388 10 12.59 10 12.992 10H22.012C22.415 10 22.616 10 22.717 9.953C22.8366 9.89698 22.9291 9.79592 22.9745 9.67189C23.0198 9.54787 23.0143 9.41094 22.959 9.291C22.912 9.19 22.759 9.06 22.451 8.801L21.503 8V5C21.503 3.93913 21.0816 2.92172 20.3314 2.17157C19.5813 1.42143 18.5639 1 17.503 1ZM19.003 11C18.784 11.583 18.195 12 17.503 12C16.81 12 16.221 11.583 16.003 11H19.003ZM22 16.75V11H20.067L19.935 11.35C19.565 12.339 18.592 13 17.499 13C17.1081 13.0018 16.7217 12.9163 16.3681 12.7497C16.0145 12.5831 15.7025 12.3396 15.455 12.037L12.348 13.664C12.2406 13.7202 12.1212 13.7496 12 13.7496C11.8788 13.7496 11.7594 13.7202 11.652 13.664L2 8.608V16.75L2.005 16.934C2.052 17.7628 2.41437 18.5422 3.01777 19.1123C3.62117 19.6824 4.41986 20.0001 5.25 20H18.75L18.934 19.995C19.7628 19.948 20.5422 19.5856 21.1123 18.9822C21.6824 18.3788 22.0001 17.5801 22 16.75ZM12 12.153L14.202 11H12.966C12.785 11 12.611 11 12.471 10.993C12.2599 10.9881 12.0517 10.9422 11.858 10.858C11.4999 10.6897 11.2228 10.3868 11.0871 10.0153C10.9513 9.64366 10.9678 9.23347 11.133 8.874C11.2266 8.68491 11.356 8.51578 11.514 8.376C11.616 8.28 11.749 8.168 11.888 8.051L12.498 7.535V5C12.498 4.658 12.533 4.323 12.598 4H5.25L5.064 4.005C4.29168 4.04919 3.56045 4.36741 3.00175 4.90246C2.44305 5.43752 2.09352 6.15431 2.016 6.924L12 12.154V12.153Z"
          fill="#9E9E9E" />
      </svg>
    ),
    innerTab: [],
  },
  {
    pageName: "Messages",
    add: "/agency/messages",
    icon: (
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_659_4922"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="22"
          height="22"
        >
          <path
            d="M21 11C21 16.523 16.523 21 11 21H1V11C1 5.477 5.477 1 11 1C16.523 1 21 5.477 21 11Z"
            fill="white"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6 8H15M6 12H15M6 16H11"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </mask>
        <g mask="url(#mask0_659_4922)">
          <path d="M-1 -1H23V23H-1V-1Z" fill="#9E9E9E" />
        </g>
      </svg>
    ),
    innerTab: [],
  },
  {
    pageName: "Charting",
    // add: "/agency/charting/forms",
    add: "/agency/charting/templates",
    icon: (
      <svg
        width="22"
        height="19"
        viewBox="0 0 22 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_659_3924"
          style={{ maskType: "luminance" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="22"
          height="19"
        >
          <path
            d="M20 3H2C1.44772 3 1 3.44772 1 4V17C1 17.5523 1.44772 18 2 18H20C20.5523 18 21 17.5523 21 17V4C21 3.44772 20.5523 3 20 3Z"
            fill="white"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6 1V5"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M11.5 9.5H6M16 13.5H6"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M16 1V5"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </mask>
        <g mask="url(#mask0_659_3924)">
          <path d="M-1 -2H23V22H-1V-2Z" fill="#9E9E9E" />
        </g>
      </svg>
    ),
    innerTab: [
      {
        innertab_pageName: "Forms",
        // add: "/agency/charting/forms",
        add: "/agency/charting/templates",
      },
      {
        innertab_pageName: "Initial Assessments",
        // innertab_pageName: "Assessments",
        // add: "/agency/charting/initialassessment",
        add: "/agency/charting/initial-assessments",
      },
      // {
      //   innertab_pageName: "Task",
      //   add: "/agency/charting/task",
      // },
      {
        innertab_pageName: "Supervisory Visits",
        // add: "/agency/charting/supervisorvisit",
        add: "/agency/charting/supervisory-visits",
      },
      {
        innertab_pageName: "Discharge Summaries",
        // add: "/agency/charting/dischargesummary",
        add: "/agency/charting/discharge-summaries",
      },
      {
        innertab_pageName: "Care Plans",
        // add: "/agency/charting/careplan",
        add: "/agency/charting/care-plans",
      },
      // {
      //   innertab_pageName: "Care Recipients",
      //   add: "/agency/charting/carerecipient",
      // },
      // {
      //   innertab_pageName: "Service Agreements",
      //   add: "/agency/charting/serviceagreement",
      // },
    ],
  },
  // {
  //   pageName: "Reports",
  //   add: "/agency-reports/commonreports",
  //   icon: (
  //     <svg
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         d="M20 8L14 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V8ZM9 19H7V10H9V19ZM13 19H11V13H13V19ZM17 19H15V16H17V19ZM14 9H13V4L18 9H14Z"
  //         fill="#9E9E9E"
  //       />
  //     </svg>
  //   ),
  //   innerTab: [
  //     {
  //       innertab_pageName: "Common Reports",
  //       add: "/agency-reports/commonreports",
  //     },
  //     {
  //       innertab_pageName: "Financial Reports",
  //       add: "#",
  //     },
  //     {
  //       innertab_pageName: "Scheduling Reports",
  //       add: "#",
  //     },
  //     {
  //       innertab_pageName: "Index Reports",
  //       add: "#",
  //     },
  //     {
  //       innertab_pageName: "Nurse Reports",
  //       add: "#",
  //     },
  //     {
  //       innertab_pageName: "Marketing Reports",
  //       add: "#",
  //     },
  //   ],
  // },
];

const AgencySideBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function splitPath(pathname, index) {
    if (pathname[0] === '/')
      pathname = pathname.substring(1);
    return pathname.split('/')[index];
  }

  useEffect(() => {
    if (!isLoggedIn()) {
      redirectOrLogout(dispatch, navigate);
    }
  }, []);

  return (
    <div className="app-sidebar sidebar-shadow ">
      <div className="scrollbar-sidebar">
        <div className="app-sidebar__inner" style={{height: '100%', overflow: "auto"}}>
          {/* <!-- Side bar for desktop/mobile --> */}
          {/*<span className="text-white">{location.pathname}</span><br/>
          <span className="text-white">{splitPath(location.pathname, 1)}</span><br/>
          <span className="text-white">{splitPath(location.pathname, 2)}</span>*/}
          <ul className="vertical-nav-menu">
            {tabData.map((item, index) => {
              const pathMatchIndex = item.pathMatchIndex || 1;
              return (
                <li
                  key={index}
                  className={`${splitPath(location.pathname, pathMatchIndex) === splitPath(item.add, pathMatchIndex)
                    ? "mm-active"
                    : ""
                  }`}
                >
                  <NavLink
                    to={item.add}
                    className={`${splitPath(location.pathname, pathMatchIndex) === splitPath(item.add, pathMatchIndex)
                      ? "pr-mm"
                      : ""
                    } text-decoration-none`}
                  >
                    {item.icon}
                    {item.pageName}
                    {item.innerTab.length !== 0 && (
                      <i className="metismenu-state-icon pe-7s-angle-down caret-left" onClick={(e) => {
                        const targetElement = e.target;
                        const isActive = targetElement.parentElement.classList.contains('active');
                        // alert(isActive)
                        if (isActive) {
                          targetElement.parentElement.classList.remove('active');
                          targetElement.parentElement.classList.remove('pr-mm');
                          targetElement.parentElement.parentElement.classList.remove('mm-active');
                          targetElement.parentElement.nextElementSibling.classList.remove('mm-showmm-collapse');
                          // targetElement.parentElement.nextElementSibling.classList.add('mm-collapse');
                          targetElement.parentElement.nextElementSibling.classList.remove('mm-show');
                        } else {
                          // targetElement.parentElement.nextElementSibling.classList.remove('mm-collapse');
                          targetElement.parentElement.nextElementSibling.classList.add('mm-show');
                          targetElement.parentElement.nextElementSibling.classList.add('mm-showmm-collapse');
                          targetElement.parentElement.parentElement.classList.add('mm-active');
                          targetElement.parentElement.classList.add('pr-mm');
                          targetElement.parentElement.classList.add('active');
                        }
                      }}></i>
                    )}
                  </NavLink>
                  {item.innerTab.length !== 0 ? (
                    <ul
                      className={`${splitPath(location.pathname, pathMatchIndex)?.includes(splitPath(item.add, pathMatchIndex))
                        ? "mm-show "
                        : ""
                      }mm-collapse`}
                    >
                      {item.innerTab.map((innerTabitem, innerTabindex) => {
                        return (
                          <li key={innerTabindex} className={`${splitPath(location.pathname, pathMatchIndex + 1)?.includes(splitPath(innerTabitem.add, pathMatchIndex + 1))
                            ? "mm-active "
                            : ""
                          }`}>
                            <NavLink
                              to={innerTabitem.add}
                              className={`${splitPath(location.pathname, 2)?.includes(splitPath(innerTabitem.add, 2))
                                ? "text-decoration-underline "
                                : "text-decoration-none"
                              }`}
                            >
                              <i className="metismenu-icon"></i>
                              {innerTabitem.innertab_pageName}
                            </NavLink>
                          </li>
                        );
                      })}
                    </ul>
                  ) : null}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AgencySideBar;
