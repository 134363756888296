import React, {useEffect, useState} from 'react';
import NursesOverview from "./components/Nurse";
import {useDispatch, useSelector} from "react-redux";
import DashboardCard from "./components/Dashboard/DashboardCard";
import {getNurseList, getNursesData} from "./redux/actions/AdminActions";
import {isLoggedIn, redirectOrLogout} from "../helpers/apiMethods";
import {useNavigate} from "react-router-dom";
import {HttpsAction} from "../commonApiFunction/httpsAction";
import {ADMIN_NURSES_NAV_TITLES} from "../appConstants";

const NURSES_API_KEYS = {
  ALL_NURSES: "nurse-list",
  VERIFIED_NURSES: "verified-nurse-list",
  UNVERIFIED_NURSES: "unverified-nurse-list",
  WITHDRAWAL_REQUEST: "withdrawal-request-list",
};

const NURSES_NAV_PATHS = {
  // ALL_NURSES: '/admin/all/nurses',
  // VERIFIED_NURSES: '/admin/all/verifiedNurses',
  // UNVERIFIED_NURSES: '/admin/all/unverifiedNurses',
  // WITHDRAWAL_REQUEST: '/admin/all/withdrawalRequest',
  ALL_NURSES: '/admin/list/nurses/all',
  VERIFIED_NURSES: '/admin/list/nurses/verified',
  UNVERIFIED_NURSES: '/admin/list/nurses/unverified',
  WITHDRAWAL_REQUEST: '/admin/list/nurses/withdrawal-requests',
};

const AdminNurses = () => {
  const backButtonTextSuffix = 'Nurses Overview';
  // const backLink = '/admin/dashboard';
  const backLink = '/admin/list/nurses';

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const {admin} = useSelector((state) => state);
  const nursesSummary = useSelector(state => state.admin?.nursesSummary);

  const [activeCard, setActiveCard] = useState(ADMIN_NURSES_NAV_TITLES.ALL_NURSES);
  // const [firstLoad, setFirstLoad] = useState(true);
  // const [firstLoadCompleted, setFirstLoadCompleted] = useState(false);
  const [allNursesCount, setAllNursesCount] = useState(0);
  const [verifiedNursesCount, setVerifiedNursesCount] = useState(0);
  const [unverifiedNursesCount, setUnverifiedNursesCount] = useState(0);
  const [withdrawalRequestsCount, setWithdrawalRequestsCount] = useState(0);

  // const activeTabTableData = {
  //   [NURSES_NAV_TITLES.ALL_NURSES]: admin?.nurseList,
  //   [NURSES_NAV_TITLES.VERIFIED_NURSES]: admin?.verifiedNurseList,
  //   [NURSES_NAV_TITLES.UNVERIFIED_NURSES]: admin?.unverifiedNurseList,
  //   [NURSES_NAV_TITLES.WITHDRAWAL_REQUEST]: admin?.withdrawalRequest,
  // }[activeCard];

  const activeTabApiKey = {
    [ADMIN_NURSES_NAV_TITLES.ALL_NURSES]: NURSES_API_KEYS.ALL_NURSES,
    [ADMIN_NURSES_NAV_TITLES.VERIFIED_NURSES]: NURSES_API_KEYS.VERIFIED_NURSES,
    [ADMIN_NURSES_NAV_TITLES.UNVERIFIED_NURSES]: NURSES_API_KEYS.UNVERIFIED_NURSES,
    [ADMIN_NURSES_NAV_TITLES.WITHDRAWAL_REQUEST]: NURSES_API_KEYS.WITHDRAWAL_REQUEST,
  }[activeCard];

  const activeTabSubTitle = {
    [ADMIN_NURSES_NAV_TITLES.ALL_NURSES]: 'See all nurses and their details',
    [ADMIN_NURSES_NAV_TITLES.VERIFIED_NURSES]: 'See details of verified nurses only',
    [ADMIN_NURSES_NAV_TITLES.UNVERIFIED_NURSES]: 'See details of nurses only that are not verified',
    [ADMIN_NURSES_NAV_TITLES.WITHDRAWAL_REQUEST]: 'See all withdrawal requests made by nurses',
  }[activeCard];


  const handleSeeAll = (e, title) => {
    const state = {
      // type: title,
      showBackButton: true,
      backButtonTextSuffix,
      backLink,
    };
    switch (title) {
      case ADMIN_NURSES_NAV_TITLES.ALL_NURSES:
        navigate(NURSES_NAV_PATHS.ALL_NURSES, { state });
        break;
      case ADMIN_NURSES_NAV_TITLES.VERIFIED_NURSES:
        navigate(NURSES_NAV_PATHS.VERIFIED_NURSES, { state });
        break;
      case ADMIN_NURSES_NAV_TITLES.UNVERIFIED_NURSES:
        navigate(NURSES_NAV_PATHS.UNVERIFIED_NURSES, { state });
        break;
      case ADMIN_NURSES_NAV_TITLES.WITHDRAWAL_REQUEST:
        navigate(NURSES_NAV_PATHS.WITHDRAWAL_REQUEST, { state });
        break;
    }
  };

  const renderDashboardCards = () => {
    const cardData = [
      {
        title: ADMIN_NURSES_NAV_TITLES.ALL_NURSES,
        apiKey: NURSES_API_KEYS.ALL_NURSES,
        total: allNursesCount,
      },
      {
        title: ADMIN_NURSES_NAV_TITLES.VERIFIED_NURSES,
        apiKey: NURSES_API_KEYS.VERIFIED_NURSES,
        total: verifiedNursesCount,
      },
      {
        title: ADMIN_NURSES_NAV_TITLES.UNVERIFIED_NURSES,
        apiKey: NURSES_API_KEYS.UNVERIFIED_NURSES,
        total: unverifiedNursesCount,
      },
      {
        title: ADMIN_NURSES_NAV_TITLES.WITHDRAWAL_REQUEST,
        apiKey: NURSES_API_KEYS.WITHDRAWAL_REQUEST,
        total: withdrawalRequestsCount,
      },
    ];

    return cardData.map((card, index) => {
      return (
        <DashboardCard
          key={index}
          title={card.title}
          isActive={activeCard === card.title}
          numberOfNurses={card.total}
          handleClick={setActiveCard}
          handleSeeAll={handleSeeAll}
        />
      )
    });
  };

//   useEffect(() => {
//     if (firstLoadCompleted) {
// // console.log('test- firstLoadCompleted');
//       setFirstLoad(false);
//     }
//   }, [firstLoadCompleted]);
//
//   useEffect(() => {
//     if (!firstLoad && firstLoadCompleted) {
//       setAllNursesCount(admin?.nurseList?.allNursesList?.total || 0);
//       setVerifiedNursesCount(admin?.nurseList?.verifiedCount || 0);
//       setUnverifiedNursesCount(admin?.nurseList?.unverifiedCount || 0);
//       setWithdrawalRequestsCount(admin?.nurseList?.withdrawalRequestsCount || 0);
//     }
//   }, [firstLoad, firstLoadCompleted]);

  useEffect(() => {
    if (nursesSummary != null) {
      setAllNursesCount(nursesSummary?.allNursesCount || 0);
      setVerifiedNursesCount(nursesSummary?.verifiedCount || 0);
      setUnverifiedNursesCount(nursesSummary?.unverifiedCount || 0);
      setWithdrawalRequestsCount(nursesSummary?.withdrawalRequestsCount || 0);
    }
  }, [nursesSummary]);

  useEffect(() => {
    if (!isLoggedIn()) {
      redirectOrLogout(dispatch, navigate);
    } else {
      dispatch(getNursesData('nurses-summary'));
    //   dispatch(getNursesData('nurse-list'));
    //   dispatch(getNursesData('verified-nurse-list'));
    //   dispatch(getNursesData('unverified-nurse-list'));
    //   dispatch(getNursesData('withdrawal-request-list'));
    }

    // return () => {
    //   setActiveCard('XXX');
    //   setFirstLoad(true);
    //   setFirstLoadCompleted(false);
    // };
  }, []);


  return (
    <>
      <div id="canvas" className="row anylatics-data-box mt-4 mb-3">
        {renderDashboardCards()}
      </div>
      {/*<Nurse title={activeCard} data={activeTabTableData}/>*/}
      {/*<NursesOverview title={activeCard} apiKey={activeTabApiKey} firstLoad={firstLoad} firstLoadCompleted={firstLoadCompleted} setFirstLoadCompleted={setFirstLoadCompleted} />*/}
      <NursesOverview title={activeCard} apiKey={activeTabApiKey} subTitle={activeTabSubTitle} />
    </>
  );
};

export default AdminNurses;
