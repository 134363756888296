import React, {useEffect, useState} from "react";
import Table from '../../../../sharedComponents/table/Table'
import {useDispatch, useSelector} from "react-redux";
import EditTaskModal from "./EditTaskModal";

const EditTask = ({nursesAssigned, assignedNurseId, updateTasksArray, isTaskSelected/*, addToDeletedTasksArray, tasksListUpdated*/, agencyId, isJobRelated, isChartingRelated}) => {
    const dispatch = useDispatch();

    const getServiceRequestById = useSelector(state => state.serviceRequest?.serviceDetails?.data);
    const taskDataIndex = useSelector(state => state.serviceRequest?.taskDataIndex);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalTaskArray, setModalTaskArray] = useState([]);
    const [modalTitle, setModalTitle] = useState('');

    const headerData = [
        {
            title: "Task Name",
            dataIndex: "tasksNames",
            width: '200px',
            render: (taskNames) => {
                return (taskNames || []).map(item => {
                    return item.split(',')
                }).join(', ');
            }
        },
        // {
        //     title: "Start Date",
        //     dataIndex: "startDate",
        // },
        // {
        //     title: "End Date",
        //     dataIndex: "endDate",
        // },
        {
            title: "Visit Date",
            dataIndex: "startDate",
        },
        {
            title: "Clock In",
            dataIndex: "clockIn",
        },
        {
            title: "Clock Out",
            dataIndex: "clockOut",
        },
        {
            title: "Status",
            dataIndex: "status",
        },
        // {
        //     title: "Frequency",
        //     dataIndex: "frequencyDaysNames",
        //     width: '200px',
        //     render: (frNames) => {
        //         if (frNames === 'All') return 'All days';
        //         if (frNames === 'Any') return 'Any day';
        //         return (frNames || []).map(item => {
        //             return item.split(',')
        //         }).join(', ');
        //     }
        // },
    ]

    const openModal = () => {
        setModalTitle('New Task');
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        removeTaskData();
    };

    // const removeTask = (id) => {
    //     // const removedItem = modalTaskArray.find(res => res.id === removedTaskId);
    //     const removedItem = modalTaskArray.find(res => res.id === id);
    //     // setRemovedTaskId(id);
    //     const updatedList = modalTaskArray.filter(res => res.id !== id);
    //     const {id: pk, ...otherItemFields} = removedItem;
    //     if (otherItemFields.recordType === 'existing') {
    //         const deletedItemInfo = {id: pk, recordType: 'deleted'};
    //         // addToDeletedTasksArray(deletedItemInfo);
    //         updatedList.push(deletedItemInfo);
    //     }
    //     setModalTaskArray(updatedList);
    // };

    const updateTasksList = (taskItem) => {
        // console.log('taskItem', taskItem);
        if (taskDataIndex) {
            const updatedList = [...modalTaskArray];
            updatedList[taskDataIndex - 1] = taskItem;
            setModalTaskArray(updatedList);
        } else {
            const newTaskItem = {...taskItem, recordType: 'new', status: nursesAssigned ? 'Scheduled' : 'Pending', assignedNurseId: assignedNurseId > 0 ? assignedNurseId : null};
            setModalTaskArray([...modalTaskArray, newTaskItem]);
        }
    };

    // const handleEditTask = async (_, index) => {
    //     setModalTitle('Edit Task');
    //     await dispatch({
    //         type: 'ADD_SERVICE_REQUEST_SET_TASK_BY_INDEX',
    //         payload: getServiceRequestById.tasks[index],
    //         name: 'taskData',
    //         meta: {type: 'SET_TASK_BY_INDEX'},
    //     });
    //     await dispatch({
    //         type: 'ADD_SERVICE_REQUEST_SET_TASK_INDEX',
    //         payload: index + 1,
    //         name: 'taskDataIndex',
    //         meta: {type: 'SET_TASK_INDEX'},
    //     });
    //     setIsModalOpen(true);
    // };

    const removeTaskData = () => {
        dispatch({
            type: 'ADD_SERVICE_REQUEST_REMOVE_TASK_DATA',
            payload: undefined,
            name: 'taskData',
            meta: {type: 'REMOVE_TASK_DATA'},
        });
        dispatch({
            type: 'ADD_SERVICE_REQUEST_REMOVE_TASK_INDEX',
            payload: undefined,
            name: 'taskDataIndex',
            meta: {type: 'REMOVE_TASK_INDEX'},
        });
    };

    // const actionButton = [
    //     {
    //         actionName: 'Edit',
    //         buttonAction: (id, index) => handleEditTask(id, index),
    //         actionClass: 'btn-primary',
    //         iconClass: 'fa-user-plus',
    //     }, {
    //         actionName: 'Remove',
    //         buttonAction: (id) => removeTask(id),
    //         actionClass: 'btn-danger',
    //         iconClass: 'fa-user-plus',
    //     },
    // ];

    useEffect(() => {
        updateTasksArray(modalTaskArray);
    }, [modalTaskArray]);

    // useEffect(() => {
    //     alert(tasksListUpdated)
    //     if (tasksListUpdated && removedTaskId > 0) {
    //         const removedItem = modalTaskArray.find(res => res.id === removedTaskId);
    //         const {id: pk, ...otherItemFields} = removedItem;
    //         if (otherItemFields.recordType === 'existing') {
    //             const deletedItemInfo = {id: pk, recordType: 'deleted'};
    //             addToDeletedTasksArray(deletedItemInfo);
    //         }
    //         setRemovedTaskId(0);
    //     }
    // }, [tasksListUpdated]);

    useEffect(() => {
        (async () => {
            const data = [];
            if (getServiceRequestById) {
                const days = getServiceRequestById?.frequencies?.map(e => e.frequency);
                const dayIds = getServiceRequestById?.frequencies?.map(e => e.id);
                const activityNames = getServiceRequestById?.frequencies?.map(e => e.frequency);
                // const offsetCurrentDateTime = new Date(Date.parse(getServiceRequestById.offsetCurrentDateTime));
                const currentDateTimeUtc = new Date(Date.parse(getServiceRequestById.currentDateTimeUtc.replace(' ', 'T') + '+00:00'));
                await getServiceRequestById?.tasks?.map(v => {
                    // console.log('test- 1', v);

                    const scheduledEndDateTimeUtc = new Date(Date.parse(v.scheduledEndDateTimeUtc.replace(' ', 'T') + '+00:00'));
                    let statusVal = v.status;
                    if (v.statusId < 3 && v.clockIn == null && currentDateTimeUtc > scheduledEndDateTimeUtc) statusVal = 'Missed';
                    if (v.clockIn != null && v.date < currentDateTimeUtc && v.remainingActivitiesTotal > 0) statusVal = 'Incomplete'
                    return {
                        ...v,
                        startDate: v.dateFormatUs,
                        endDate: v.dateFormatUs,
                        // clockIn: v.scheduledStartTimeAmPm,
                        clockIn: v.scheduledStartTimeNoSeconds,
                        // clockOut: v.scheduledEndTimeAmPm,
                        clockOut: v.scheduledEndTimeNoSeconds,
                        frequencyDaysNames: days,
                        tasksIds: v.activities?.map(a => a.id) || [],
                        tasksNames: v.activityNames,
                        status: statusVal,
                        modalFrequencyIds: dayIds,
                    };
                }).map((v, i) => {
                    // console.log('test- ', {v});
                    data.push({
                        ...v,
                        id: v.id,
                        recordType: 'existing',
                        startDate: v.startDate,
                        endDate: v.endDate,
                        clockIn: v.clockIn,
                        clockOut: v.clockOut,
                        frequencyDaysNames: v.frequencyDaysNames,
                        tasksNames: v.tasksNames,
                        modalFrequencyIds: v.modalFrequencyIds,
                        tasksIds: v.tasksIds,
                        status: v.status,
                        isDirty: false,
                    })
                });
                setModalTaskArray(() => data);
            }
        })()

    }, [getServiceRequestById]);

    return (
        <div className="row">
            <div className="col-12">
                <div className="row align-items-center pb-3">
                    <div className="col-12 col-md-6">
                        <div className="tab-inner-heading">Task <span className="mandatory-field">*</span></div>
                    </div>
                    {!(isJobRelated || isChartingRelated) && <div className="col-12 col-md-6">
                        <div className="d-flex justify-content-end">
                            <button type="button" className="btn btn-primary" onClick={openModal}>
                                Add Task
                            </button>
                        </div>
                    </div>}
                </div>
            </div>
            <div className="col-12">
                <div className="table-task">
                    {/*<Table headers={headerData} rows={modalTaskArray.filter(item => item.recordType !== 'deleted')} actions={actionButton}/>*/}
                    <Table headers={headerData} rows={modalTaskArray.filter(item => item.recordType !== 'deleted')} />
                    {!isTaskSelected && (<span className="field-error d-block pt-2">Please Add Task</span>)}
                    <div className="buttonSpace"></div>
                </div>
                {
                    isModalOpen && (
                        <EditTaskModal agencyId={agencyId} isModalOpen={isModalOpen} closeModal={() => closeModal()} modalTitle={modalTitle}
                                       updateTasksList={updateTasksList} serviceRequestTypeIdForAdd={getServiceRequestById.tasks[0].serviceRequestTypeId}/>
                    )
                }
            </div>
        </div>
    );
};

export default EditTask;
