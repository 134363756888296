import React, {useEffect, useState} from "react";
import PrimaryButton from "../../../../sharedComponents/buttons/PrimaryButton";
import {connect, useSelector} from "react-redux";
import {reduxForm} from "redux-form";
import classes from "../TaskDetails.module.css"
import {Stack} from "@mui/material";
import dateHelperFunctions from "../../../../helpers/date.helpers";
import {DirectionsRenderer, GoogleMap, Marker, useJsApiLoader} from "@react-google-maps/api";
// import GoogleMapReact from 'google-map-react';

const Directions = ({handleSubmit, handleSelectTab, show, initialValues, isJobRelated, isChartingRelated}) => {

    // const googleApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    const defaultZoomLevel = 18;
    const travelMode = window.google.maps.TravelMode.DRIVING;

    const [map, setMap] = useState(/** @type google.maps.Map */null);
    const [centerOfMap, setCenterOfMap] = useState(/** @type google.maps.LatLng */null);
    const [directionsResponse, setDirectionsResponse] = useState(null);
    const [directionTime, setDirectionTime] = useState('Nil');
    const [directionDistance, setDirectionDistance] = useState('Nil');

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    });

    const [dropdown, setDropdown] = useState(false);
    const [nurseInfoHasNoCoordinates, setNurseInfoHasNoCoordinates] = useState(false);
    const [patientInfoHasNoCoordinates, setPatientInfoHasNoCoordinates] = useState(false);

    // const serviceDetails = useSelector(state => state.client?.taskDetails?.data);


    const handleChangeDropdown = () => setDropdown(pre => !pre);

    const onSubmit = () => {
        handleSelectTab('', 5)
    };

    const calculateDirection = async () => {
        if (!(initialValues?.nurseLatitude == null || initialValues?.nurseLongitude == null || initialValues?.patientLatitude == null || initialValues?.patientLongitude == null)) {
            const directionsService = new window.google.maps.DirectionsService();
// console.log('test-- calculateDirection', 'directionsService is null', directionsService == null);
// console.log('test-- calculateDirection', 'nurse- /lat/lng', initialValues.nurseLatitude, initialValues.nurseLongitude);
// console.log('test-- calculateDirection', 'patient- /lat/lng', initialValues.patientLatitude, initialValues.patientLongitude);
            const result = await directionsService.route({
                travelMode,
                origin: {lat: parseFloat(initialValues.nurseLatitude), lng: parseFloat(initialValues.nurseLongitude)},
                // destination: {lat: 21.845562399690625, lng:71.29982619399162},
                destination: {lat: parseFloat(initialValues.patientLatitude), lng: parseFloat(initialValues.patientLongitude)},
            }, (result, status) => {
                if (status === 'OK') {
                    // console.log('test-- calculateDirection: result', result);
                    // console.log('test-- calculateDirection: status', status);
                    setDirectionsResponse(result);
                    setDirectionDistance(result.routes[0].legs[0].distance.text);
                    setDirectionTime(result.routes[0].legs[0].duration.text);
                    console.log('route-direction', result.routes[0].legs[0].distance.text);
                    console.log('route-duration', result.routes[0].legs[0].duration.text);
                } else {
                    console.log('route-direction: no results obtained');
                }
            });
        }
    }

    useEffect(() => {
        if (initialValues?.nurseLatitude != null || initialValues?.patientLatitude != null) {
            if (initialValues?.nurseLatitude != null)
                setCenterOfMap({lat: parseFloat(initialValues.nurseLatitude), lng: parseFloat(initialValues.nurseLongitude)});
            else {
                setNurseInfoHasNoCoordinates(true);
                if (initialValues?.patientLatitude != null)
                    setCenterOfMap({lat: parseFloat(initialValues.patientLatitude), lng: parseFloat(initialValues.patientLongitude)});
                else
                    setPatientInfoHasNoCoordinates(true);
            }
        }
    }, [initialValues]);

    useEffect(() => {
        if (map != null && centerOfMap != null) {
            calculateDirection();
        }
    }, [map, centerOfMap]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className={'mt-2 pt-4 position-relative'} style={{display: show ? 'block' : 'none'}}>
                {(nurseInfoHasNoCoordinates || patientInfoHasNoCoordinates) && <div className="position-absolute top-0 start-1 d-flex gap-5 text-danger fw-bold fst-italic">
                    {nurseInfoHasNoCoordinates && <div className="d-flex gap-1">
                        <svg width="20" height="20" viewBox="0 0 122.879 122.879" enableBackground="new 0 0 122.879 122.879"
                             xmlns="http://www.w3.org/2000/svg">
                            <title>Not Done Yet</title>
                            <g>
                                <path fillRule="evenodd" clipRule="evenodd" fill="#FF4141"
                                      d="M61.44,0c33.933,0,61.439,27.507,61.439,61.439 s-27.506,61.439-61.439,61.439C27.507,122.879,0,95.372,0,61.439S27.507,0,61.44,0L61.44,0z M73.451,39.151 c2.75-2.793,7.221-2.805,9.986-0.027c2.764,2.776,2.775,7.292,0.027,10.083L71.4,61.445l12.076,12.249 c2.729,2.77,2.689,7.257-0.08,10.022c-2.773,2.765-7.23,2.758-9.955-0.013L61.446,71.54L49.428,83.728 c-2.75,2.793-7.22,2.805-9.986,0.027c-2.763-2.776-2.776-7.293-0.027-10.084L51.48,61.434L39.403,49.185 c-2.728-2.769-2.689-7.256,0.082-10.022c2.772-2.765,7.229-2.758,9.953,0.013l11.997,12.165L73.451,39.151L73.451,39.151z"/>
                            </g>
                        </svg>
                        <span>Nurse coordinates missing</span>
                    </div>}
                    {patientInfoHasNoCoordinates && <div className="d-flex gap-1">
                        <svg width="20" height="20" viewBox="0 0 122.879 122.879" enableBackground="new 0 0 122.879 122.879"
                             xmlns="http://www.w3.org/2000/svg">
                            <title>Not Done Yet</title>
                            <g>
                                <path fillRule="evenodd" clipRule="evenodd" fill="#FF4141"
                                      d="M61.44,0c33.933,0,61.439,27.507,61.439,61.439 s-27.506,61.439-61.439,61.439C27.507,122.879,0,95.372,0,61.439S27.507,0,61.44,0L61.44,0z M73.451,39.151 c2.75-2.793,7.221-2.805,9.986-0.027c2.764,2.776,2.775,7.292,0.027,10.083L71.4,61.445l12.076,12.249 c2.729,2.77,2.689,7.257-0.08,10.022c-2.773,2.765-7.23,2.758-9.955-0.013L61.446,71.54L49.428,83.728 c-2.75,2.793-7.22,2.805-9.986,0.027c-2.763-2.776-2.776-7.293-0.027-10.084L51.48,61.434L39.403,49.185 c-2.728-2.769-2.689-7.256,0.082-10.022c2.772-2.765,7.229-2.758,9.953,0.013l11.997,12.165L73.451,39.151L73.451,39.151z"/>
                            </g>
                        </svg>
                        <span>Patient coordinates missing</span>
                    </div>}
                </div>}
                <div className="google-map position-relative">
                    {centerOfMap != null && <>
                        <GoogleMap
                          center={centerOfMap}
                          zoom={defaultZoomLevel}
                          mapContainerStyle={{width: '100%', height: '100%'}}
                          options={{
                              zoomControl: false,
                              streetViewControl: false,
                              mapTypeControl: false,
                              fullscreenControl: false,
                          }}
                          onLoad={(map) => setMap(map)}
                        >
                            {initialValues.nurseLatitude != null && initialValues.nurseLongitude != null && <Marker
                              position={{lat: parseFloat(initialValues.nurseLatitude), lng: parseFloat(initialValues.nurseLongitude)}}
                              title={`Nurse Residence`}
                              label={'Nurse Residence'}
                              icon={{
                                  fillColor: 'yellow',
                                  path: 'M12.75 0l-2.25 2.25 2.25 2.25-5.25 6h-5.25l4.125 4.125-6.375 8.452v0.923h0.923l8.452-6.375 4.125 4.125v-5.25l6-5.25 2.25 2.25 2.25-2.25-11.25-11.25zM10.5 12.75l-1.5-1.5 5.25-5.25 1.5 1.5-5.25 5.25z',
                                  fillOpacity: 1,
                                  strokeColor: '#000',
                                  strokeWeight: 1,
                                  scale: 1,
                              }}
                            />}
                            {initialValues.patientLatitude != null && initialValues.patientLongitude != null && <Marker
                              position={{lat: parseFloat(initialValues.patientLatitude), lng: parseFloat(initialValues.patientLongitude)}}
                              title={'Patient Residence'}
                              label={'Patient Residence'}
                              icon={{
                                  fillColor: 'green',
                                  // strokeColor: 'yellow',
                                  path: 'M12.75 0l-2.25 2.25 2.25 2.25-5.25 6h-5.25l4.125 4.125-6.375 8.452v0.923h0.923l8.452-6.375 4.125 4.125v-5.25l6-5.25 2.25 2.25 2.25-2.25-11.25-11.25zM10.5 12.75l-1.5-1.5 5.25-5.25 1.5 1.5-5.25 5.25z',
                                  // fillColor: '#34495e',
                                  fillOpacity: 1,
                                  strokeColor: '#000',
                                  strokeWeight: 1,
                                  scale: 1,
                              }}
                            />}
                            {directionsResponse != null && <DirectionsRenderer directions={directionsResponse}/>}
                        </GoogleMap>
                        <div className="position-absolute top-0 end-0">
                            <button onClick={(e) => {
                                // centerOfMap && map.panTo(centerOfMap);
                                calculateDirection();
                            }}>Back to Center
                            </button>
                        </div>
                    </>}
                </div>

                <div className={'mt-2'}>
                    <div className={classes.box}>
                        <Stack direction={'row'} gap={'15px'}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="#1372DC"/>
                                <path
                                  d="M10.4657 18C10.0745 18 9.70408 17.826 9.4667 17.526L6.25401 13.479C6.15468 13.354 6.08217 13.2116 6.04061 13.0599C5.99905 12.9082 5.98927 12.7501 6.01182 12.5948C6.03436 12.4395 6.0888 12.2899 6.17201 12.1547C6.25523 12.0194 6.36559 11.9011 6.49678 11.8066C6.62793 11.7116 6.77745 11.6423 6.93677 11.6025C7.09608 11.5627 7.26206 11.5533 7.4252 11.5748C7.58833 11.5963 7.74541 11.6483 7.88744 11.7278C8.02946 11.8073 8.15363 11.9127 8.25284 12.038L10.3668 14.6988L15.6817 6.56208C15.8579 6.29342 16.1388 6.10239 16.4626 6.03088C16.7864 5.95936 17.1267 6.01321 17.409 6.18061C17.9961 6.52864 18.1768 7.26586 17.81 7.82648L11.5303 17.4359C11.4231 17.6007 11.2753 17.7381 11.0998 17.8361C10.9243 17.9341 10.7264 17.9897 10.5232 17.9983C10.5034 18 10.4854 18 10.4657 18Z"
                                  fill="#F8F8F8"/>
                                <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#1372DC"/>
                            </svg>
                            <Stack direction={'row'} justifyContent={'space-between'} width={'100%'}>
                                <Stack direction={'row'} gap={'15px'}>
                                    <Stack justifyContent={'space-between'}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                              d="M6 19V20C6 20.2833 5.904 20.521 5.712 20.713C5.52 20.905 5.28267 21.0007 5 21H4C3.71667 21 3.479 20.904 3.287 20.712C3.095 20.52 2.99934 20.2827 3 20V12L5.1 6C5.2 5.7 5.379 5.45833 5.637 5.275C5.895 5.09167 6.18267 5 6.5 5H17.5C17.8167 5 18.1043 5.09167 18.363 5.275C18.6217 5.45833 18.8007 5.7 18.9 6L21 12V20C21 20.2833 20.9043 20.521 20.713 20.713C20.5217 20.905 20.284 21.0007 20 21H19C18.7167 21 18.479 20.904 18.287 20.712C18.095 20.52 17.9993 20.2827 18 20V19H6ZM5.8 10H18.2L17.15 7H6.85L5.8 10ZM7.5 16C7.91667 16 8.271 15.854 8.563 15.562C8.855 15.27 9.00067 14.916 9 14.5C9 14.0833 8.854 13.729 8.562 13.437C8.27 13.145 7.916 12.9993 7.5 13C7.08334 13 6.729 13.146 6.437 13.438C6.145 13.73 5.99934 14.084 6 14.5C6 14.9167 6.146 15.271 6.438 15.563C6.73 15.855 7.084 16.0007 7.5 16ZM16.5 16C16.9167 16 17.271 15.854 17.563 15.562C17.855 15.27 18.0007 14.916 18 14.5C18 14.0833 17.854 13.729 17.562 13.437C17.27 13.145 16.916 12.9993 16.5 13C16.0833 13 15.729 13.146 15.437 13.438C15.145 13.73 14.9993 14.084 15 14.5C15 14.9167 15.146 15.271 15.438 15.563C15.73 15.855 16.084 16.0007 16.5 16Z"
                                              fill="#1372DC"/>
                                        </svg>
                                        <Stack gap={'5px'} alignItems={'center'}>
                                            <div className={classes.dot1}/>
                                            <div className={classes.dot2}/>
                                            <div className={classes.dot3}/>
                                        </Stack>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                              d="M12 11.5C11.337 11.5 10.7011 11.2366 10.2322 10.7678C9.76339 10.2989 9.5 9.66304 9.5 9C9.5 8.33696 9.76339 7.70107 10.2322 7.23223C10.7011 6.76339 11.337 6.5 12 6.5C12.663 6.5 13.2989 6.76339 13.7678 7.23223C14.2366 7.70107 14.5 8.33696 14.5 9C14.5 9.3283 14.4353 9.65339 14.3097 9.95671C14.1841 10.26 13.9999 10.5356 13.7678 10.7678C13.5356 10.9999 13.26 11.1841 12.9567 11.3097C12.6534 11.4353 12.3283 11.5 12 11.5ZM12 2C10.1435 2 8.36301 2.7375 7.05025 4.05025C5.7375 5.36301 5 7.14348 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 7.14348 18.2625 5.36301 16.9497 4.05025C15.637 2.7375 13.8565 2 12 2Z"
                                              fill="#1372DC"/>
                                        </svg>
                                    </Stack>
                                    <Stack gap={'40px'}>
                                        <Stack gap={'5px'}>
                                            <div className={classes.directionTitle}>
                                                Nurse Home
                                            </div>
                                            <div className={classes.directionAddress}>
                                                {initialValues.nurseLocation}
                                            </div>
                                        </Stack>
                                        <Stack gap={'5px'}>
                                            <div className={classes.directionTitle}>
                                                Clients Home
                                            </div>
                                            <div className={classes.directionAddress}>
                                                {initialValues.patientLocation}
                                            </div>
                                        </Stack>
                                    </Stack>
                                </Stack>
                                <Stack justifyContent={'space-between'}>
                                    <Stack gap={'5px'}>
                                        <div className={classes.directionTime}>
                                            Mode: <span className="text-capitalize">{travelMode.toLowerCase()}</span>
                                        </div>
                                        <div className={classes.directionTime}>
                                            {/*6:45a - 2:45p*/}
                                            {directionTime != null && directionTime}
                                        </div>
                                        <div className={classes.directionTime}>
                                            {/*1 ft, 1 min*/}
                                            {directionDistance != null && directionDistance}
                                        </div>
                                        <div className={classes.googleMapLink} onClick={() => {
                                            // const link = `https://www.google.com/maps/dir/33.5841721,73.0244652/'33.58433133,73.02518135'/@33.5843812,73.0244291,19z`;
                                            if (!(initialValues?.nurseLatitude == null || initialValues?.nurseLongitude == null || initialValues?.patientLatitude == null || initialValues?.patientLongitude == null)) {
                                                const link = `https://www.google.com/maps/dir/${initialValues.nurseLatitude},${initialValues.nurseLongitude}/'${initialValues.patientLatitude},${initialValues.patientLongitude}'/@${initialValues.nurseLatitude},${initialValues.nurseLongitude},${defaultZoomLevel}z`;
                                                window.open(link, "_blank", "noreferrer");
                                            }
                                        }}>
                                            Open on Google Maps
                                        </div>
                                    </Stack>
                                    {/*<Stack direction={'row'} gap={'5px'} sx={{cursor: 'pointer'}} alignItems={'center'}
                                     onClick={handleChangeDropdown}>
                                     <div className={classes.directionDropDown}>
                                     DIRECTION
                                     </div>
                                     {dropdown ? <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                     <path d="M19 15.5L12 8.5L5 15.5" stroke="#1372DC" stroke-width="1.5"
                                     stroke-linecap="round" stroke-linejoin="round"/>
                                     </svg>
                                     : <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                     <path d="M19 8.5L12 15.5L5 8.5" stroke="#1372DC" stroke-width="1.5"
                                     stroke-linecap="round" stroke-linejoin="round"/>
                                     </svg>}

                                     </Stack>*/}
                                </Stack>
                            </Stack>
                        </Stack>
                        {dropdown && <Stack className={classes.directionDropdownContent}>
                            <Stack gap={'5px'}>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M6 19V20C6 20.2833 5.904 20.521 5.712 20.713C5.52 20.905 5.28267 21.0007 5 21H4C3.71667 21 3.479 20.904 3.287 20.712C3.095 20.52 2.99934 20.2827 3 20V12L5.1 6C5.2 5.7 5.379 5.45833 5.637 5.275C5.895 5.09167 6.18267 5 6.5 5H17.5C17.8167 5 18.1043 5.09167 18.363 5.275C18.6217 5.45833 18.8007 5.7 18.9 6L21 12V20C21 20.2833 20.9043 20.521 20.713 20.713C20.5217 20.905 20.284 21.0007 20 21H19C18.7167 21 18.479 20.904 18.287 20.712C18.095 20.52 17.9993 20.2827 18 20V19H6ZM5.8 10H18.2L17.15 7H6.85L5.8 10ZM7.5 16C7.91667 16 8.271 15.854 8.563 15.562C8.855 15.27 9.00067 14.916 9 14.5C9 14.0833 8.854 13.729 8.562 13.437C8.27 13.145 7.916 12.9993 7.5 13C7.08334 13 6.729 13.146 6.437 13.438C6.145 13.73 5.99934 14.084 6 14.5C6 14.9167 6.146 15.271 6.438 15.563C6.73 15.855 7.084 16.0007 7.5 16ZM16.5 16C16.9167 16 17.271 15.854 17.563 15.562C17.855 15.27 18.0007 14.916 18 14.5C18 14.0833 17.854 13.729 17.562 13.437C17.27 13.145 16.916 12.9993 16.5 13C16.0833 13 15.729 13.146 15.437 13.438C15.145 13.73 14.9993 14.084 15 14.5C15 14.9167 15.146 15.271 15.438 15.563C15.73 15.855 16.084 16.0007 16.5 16Z"
                                      fill="#828282"/>
                                </svg>

                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                      d="M12 11.5C11.337 11.5 10.7011 11.2366 10.2322 10.7678C9.76339 10.2989 9.5 9.66304 9.5 9C9.5 8.33696 9.76339 7.70107 10.2322 7.23223C10.7011 6.76339 11.337 6.5 12 6.5C12.663 6.5 13.2989 6.76339 13.7678 7.23223C14.2366 7.70107 14.5 8.33696 14.5 9C14.5 9.3283 14.4353 9.65339 14.3097 9.95671C14.1841 10.26 13.9999 10.5356 13.7678 10.7678C13.5356 10.9999 13.26 11.1841 12.9567 11.3097C12.6534 11.4353 12.3283 11.5 12 11.5ZM12 2C10.1435 2 8.36301 2.7375 7.05025 4.05025C5.7375 5.36301 5 7.14348 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 7.14348 18.2625 5.36301 16.9497 4.05025C15.637 2.7375 13.8565 2 12 2Z"
                                      fill="#828282"/>
                                </svg>

                            </Stack>
                            <Stack gap={'40px'}>
                                <Stack gap={'5px'}>
                                    <div className={classes.directionTitle}>
                                        Nurse Home
                                    </div>
                                    <div className={classes.directionAddress}>
                                        {initialValues.nurseLocation}
                                    </div>
                                </Stack>
                                <div className={classes.googleMapLink}>
                                    Open on Google Map
                                </div>
                            </Stack>
                        </Stack>}
                    </div>
                </div>

                {/*<div
                 className="d-flex justify-content-center"
                 style={{margin: "40px 0 0 0"}}>
                 <PrimaryButton
                 type="submit"
                 className="btn-view-repo gap-2 px-5 py-2"
                 text={"Next"}
                 onClick={() => undefined}
                 />
                 </div>*/}
            </form>
        </>
    )
}


const mapStateToProps = (state, ownProps) => {
    // const data = state.client?.taskDetails?.data;
    const data = ownProps?.taskDetails;
// console.log('test- data', data)

    // const activities = data?.activities || [];
    return {
        initialValues: {
            // assignedNurseId: data?.assignedNurseId?.toString() || "",
            // serviceRequestId: data?.assignedNurseId?.toString() || "",
            // startDate: data?.startDate || "",
            // notes: data?.notes || "",
            // clockIn: data?.clockIn != null ? dateHelperFunctions.convertTo12HourFormat(data?.clockIn) : "N/A",
            // clockOut: data?.clockOut != null ? dateHelperFunctions.convertTo12HourFormat(data?.clockOut) : null,
            // clockInAmPm: data?.clockInAmPm || "",
            // clockInLatitude: data?.clockInLatitude || null,
            // clockInLongitude: data?.clockInLongitude || null,
            // clockOutAmPm: data?.clockOutAmPm || "",
            // clockOutLatitude: data?.clockOutLatitude || null,
            // clockOutLongitude: data?.clockOutLongitude || null,
            // activities,


            nurseLatitude: data?.assignedNurseLatitude?.toString() || null,
            nurseLongitude: data?.assignedNurseLongitude?.toString() || null,
            nurseLocation: data?.assignedNurseAddressLine1 || "Nil",
            patientLocation: data?.patientAddressLine1 || "Nil",
            patientLatitude: data?.patientLatitude?.toString() || null,
            patientLongitude: data?.patientLongitude?.toString() || null,
        },
        // completedActivities: activities.filter(a => a.isCompleted),
    }
};

export default connect(mapStateToProps)(
    reduxForm({form: "directions", enableReinitialize: true})(Directions)
);
