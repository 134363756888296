import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useDispatch} from 'react-redux';
import CustomTable from "../../../sharedComponents/customTable/index";
import {useLocation, useNavigate, useOutletContext} from "react-router-dom";
import Rating from '@mui/material/Rating';
import classes from "./ServiceRequset.module.css"
import {getAllJobs, postedJobActions} from "../../../services/jobPosting";
import WarningModal from "../../../sharedComponents/warningModal";
import apiCallHelperFunctions from "../../../helpers/api.call.helpers";
import {chartingRelatedText, jobsTabUrls} from "../../../appConstants";
import rowHelperFunctions from "../../../helpers/row.helpers";
import CommonPaginationNew from "../../../sharedComponents/pagination/CommonPaginationNew";

const PostedJobsAccepted = ({/*searchText, */postedJobsAcceptedList/*, currentPage*/}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { searchText, setPaginationTab } = useOutletContext();

    const [warningModal, setWarningModal] = useState(false);
    const [cancelId, setCancelId] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);

    const createRequestOrVisit = (id, payRateTypeId, budgetAmount, currentDateTimeUtc, templateTypeId) => {
        // if (templateTypeId == null) {
        //     navigate(`/agency/servicerequest/addnewrequest`, {
        //         state: {
        //             jobId: id,
        //             showBackButton: true,
        //             backButtonTextSuffix: 'Accepted Jobs',
        //             backLink: '/agency/servicerequest/postedjobsaccepted',
        //         },
        //     });
        // } else {
        //     navigate(`/agency/servicerequest/newJobServiceRequest`, {
        //         state: {
        //             jobId: id,
        //             templateTypeId,
        //             showBackButton: true,
        //             backButtonTextSuffix: 'Accepted Jobs',
        //             backLink: '/agency/servicerequest/postedjobsaccepted',
        //         },
        //     });
        // }
        let state = {
            jobId: id, payRateTypeId, budgetAmount, currentDateTimeUtc, isFreelance: true,
            showBackButton: true,
            backButtonTextSuffix: 'Accepted Jobs',
            backLink: '/agency/servicerequest/postedjobsaccepted',
        };
        if (templateTypeId != null) {
            state['templateTypeId'] = templateTypeId;
            // TODO: open create charting form without nurse selection and charting request creation fields added (NEW FORM NEEDED here)
        }
        navigate(`/agency/new-service-request`, { state });
    }

    const chatNow = (id) => {
        const jobItemContainingBidById = postedJobsAcceptedList?.rows?.find(item => item.bids.map(b => b.id).includes(id));
        if (jobItemContainingBidById) {
            const bid = jobItemContainingBidById.bids.find(b => b.id === id);
            navigate("/agency/messages", {
                state: {
                    id: bid.nursePersonalInfo,
                    firstName: bid.firstName,
                    lastName: bid.lastName,
                    isOnline: false,
                },
            });
        }
    }

    // <button className="send-btn" onClick={() => goToUserChatBox()}>
    //     Send a Message{" "}
    //     <span>
    //             <svg
    //                 xmlns="http://www.w3.org/2000/svg"
    //                 width="16"
    //                 height="16"
    //                 viewBox="0 0 16 16"
    //                 fill="none"
    //             >
    //               <path
    //                   d="M15.5483 0.465586C15.1481 0.0548785 14.5559 -0.0981303 14.0037 0.0629316L1.1264 3.80762C0.543759 3.96949 0.13079 4.43415 0.0195441 5.02444C-0.0941024 5.6252 0.30286 6.38783 0.821472 6.70673L4.84792 9.18145C5.26089 9.43512 5.79391 9.3715 6.13565 9.02683L10.7463 4.38744C10.9784 4.14585 11.3626 4.14585 11.5947 4.38744C11.8268 4.62098 11.8268 4.99948 11.5947 5.24107L6.97599 9.88126C6.63345 10.2251 6.56943 10.7607 6.82153 11.1762L9.28174 15.243C9.56985 15.7262 10.0661 16 10.6103 16C10.6743 16 10.7463 16 10.8104 15.9919C11.4346 15.9114 11.9308 15.4846 12.1149 14.8806L15.9325 2.01983C16.1005 1.47222 15.9485 0.876294 15.5483 0.465586Z"
    //                   fill="#109CF1"
    {/*              />*/
    }
    {/*            </svg>*/
    }
    {/*          </span>*/
    }
    {/*</button>*/
    }

    const transformedArray = postedJobsAcceptedList?.rows?.map(item => {
        const jobId = item.jobId;
        const currentDateTimeUtc = item.currentDateTimeUtc;
        const payRateTypeId = item.payRateTypeId;
        const budgetAmount = item.budgetAmount;
        // const hasValidChartingId = item?.templateTypeId != null && item.templateTypeId > 0; // && item?.chartingId != null && item.chartingId > 0;
        let { hasValidChartingId, relatedInfoList} = rowHelperFunctions.getRelatedInfo(item, false, false);
        return {
            'id': item.jobId,
            // 'jobTitle': item?.jobTitle,
            'jobTitle': <div className="position-relative1 p-0 m-0">
                <span>{item?.jobTitle}</span>
                {/*<div className="position-absolute top-25 start-50 translate-middle d-flex gap-2">
                    {hasValidChartingId && chartingRelatedText && <div className="cursor-pointer1" onClick={() => undefined}>
                        <span className={`badge rounded-pill text-wrap lh-1 text-bg-warning`}>{chartingRelatedText}</span>
                    </div>}
                </div>*/}
                {(relatedInfoList || []).length > 0 && <div className="position-absolute top-25 start-50 translate-middle cursor-pointer w-100 d-flex justify-content-center gap-1">{relatedInfoList.map(({relatedTextColor, relatedText, relatedLink, relatedState}, index) => <span key={index} onClick={() => {
                    if (relatedLink != null) navigate(relatedLink, { state: relatedState });
                }} className={`badge rounded-pill text-wrap lh-1 text-bg-${relatedTextColor}`}>{relatedText}</span>)}</div>}
            </div>,
            'hours': item.totalHours,
            'location': item.jobLocation || 'N/A',
            'shiftTimings': item.shiftTimings,
            'expiry': item.endDateFormatUs,
            'taskTable': (item?.bids || [])?.filter(v => v?.isAccepted === 1)?.map(item2 => {
                return {
                    'Applicant': item2?.firstName || 'N/A',
                    'Email': item2?.nurseEmail || 'N/A',
                    'Amount': item.budgetCurrencySymbol + item2.amount,
                    'Rating': <Rating name="read-only" value={item2?.nurseRating || 0} readOnly/>,
                    'Action': [
                        {
                            actionName: item.templateTypeId == null ? 'Create Request' : 'Create Visit',
                            buttonAction: () => createRequestOrVisit(jobId, payRateTypeId, budgetAmount, currentDateTimeUtc, item.templateTypeId),
                            actionClass: `btn-primary`,
                            iconClass: 'fa-user-plus',
                            id: item2.id,
                        }, {
                            actionName: <div className={classes.chatNow}>
                                <div style={{whiteSpace: 'nowrap'}}> Chat Now</div>
                                <div>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                    >
                                        <path
                                            d="M15.5483 0.465586C15.1481 0.0548785 14.5559 -0.0981303 14.0037 0.0629316L1.1264 3.80762C0.543759 3.96949 0.13079 4.43415 0.0195441 5.02444C-0.0941024 5.6252 0.30286 6.38783 0.821472 6.70673L4.84792 9.18145C5.26089 9.43512 5.79391 9.3715 6.13565 9.02683L10.7463 4.38744C10.9784 4.14585 11.3626 4.14585 11.5947 4.38744C11.8268 4.62098 11.8268 4.99948 11.5947 5.24107L6.97599 9.88126C6.63345 10.2251 6.56943 10.7607 6.82153 11.1762L9.28174 15.243C9.56985 15.7262 10.0661 16 10.6103 16C10.6743 16 10.7463 16 10.8104 15.9919C11.4346 15.9114 11.9308 15.4846 12.1149 14.8806L15.9325 2.01983C16.1005 1.47222 15.9485 0.876294 15.5483 0.465586Z"
                                            fill="#109CF1"
                                        />
                                    </svg>
                                </div>
                            </div>,
                            buttonAction: (id) => chatNow(id),
                            actionClass: `btn-primary ${classes.sendMessage}`,
                            iconClass: 'fa-user-plus',
                            id: item2.id,
                        },
                    ]
                }
            }),
        }
    });

    const serviceHeaderData = [
        {
            title: "Job Title",
            dataIndex: "jobTitle",
        },
        {
            title: "Hours",
            dataIndex: "hours",
            width: '55px',
        },
        {
            title: "Location",
            dataIndex: "location",
        },
        {
            title: "Shift Timings",
            dataIndex: "shiftTimings",
            width: '120px',
        },
    ];

    // const innerTableHeader = ['Applicant', 'Email Address', 'Rate Range', 'Rating', 'Action']
    const innerTableHeader = ['Applicant', 'Email Address', 'Amount', 'Rating', 'Action']

    const cancelJob = (id) => {
        setCancelId(id);
        const message = "Your request has been sent to ISHCN's.";
        setWarningModal(message);
    };

    const actionButton = [
        {
            actionName: 'Cancel Job',
            buttonAction: (id) => cancelJob(id),
            actionClass: 'btn-danger px-2',
            iconClass: 'fa-user-plus',
        }
    ];

    const handleAgree = () => {
        if (cancelId) {
            dispatch(postedJobActions({id: cancelId}, 'cancelJob', function () {
                setCancelId(null);
                dispatch(getAllJobs('PostedJobsAccepted', currentPage));
            }));
        }
        setWarningModal(false)
    };

    const handleDisagree = () => {
        setWarningModal(false)
        setCancelId(null);
    };

    // useEffect(() => {
    //     dispatch(getAllJobs('PostedJobsAccepted', currentPage));
    // }, [currentPage]);
    //
    // useEffect(() => {
    //     dispatch(getAllJobs('PostedJobsAccepted', 1, searchText));
    // }, [searchText]);

    useEffect(() => {
        if (postedJobsAcceptedList != null && postedJobsAcceptedList?.rows?.length != null) {
            setPaginationTab(postedJobsAcceptedList);
        }
    }, [postedJobsAcceptedList]);

    useEffect(() => {
        if (searchText != null && searchText.length > 0)
            dispatch(getAllJobs('PostedJobsAccepted', 1, searchText));
        else
            dispatch(getAllJobs('PostedJobsAccepted', currentPage));
    }, [currentPage, searchText]);

    return (
        <>
            {/*<div className="bg-white mt-3">
                <div className="col-sm-3">
                    <div className="d-flex gap-2 innerTabing">*/}
                        {/*<button type="button" className="btn btn-sm"
                                onClick={() => navigate("/agency/servicerequest/postedjobs")}>Pending
                        </button>
                        <button type="button" className="btn btn-sm"
                                onClick={() => navigate("/agency/servicerequest/postedjobsactive")}>Active
                        </button>
                        <button type="button" className="btn btn-primary btn-sm"
                                onClick={() => navigate("/agency/servicerequest/postedjobsaccepted")}>Accepted
                        </button>
                        <button type="button" className="btn btn-sm"
                                onClick={() => navigate("/agency/servicerequest/postedjobsexpired")}>Expired
                        </button>
                        <button type="button" className="btn btn-sm text-nowrap"
                                onClick={() => navigate("/agency/servicerequest/postedjobsinprogress")}>In-Progress
                        </button>
                        <button type="button" className="btn btn-sm"
                                onClick={() => navigate("/agency/servicerequest/postedjobscompleted")}>Completed
                        </button>*/}
                        {/*{apiCallHelperFunctions.getTabButtons('postedJobs', jobsTabUrls, navigate, location.pathname)}
                    </div>
                </div>*/}
                <CustomTable headers={serviceHeaderData} rows={transformedArray} actions={actionButton}
                             innerTableHeader={innerTableHeader} actionColWidth="110px" noDataFoundMessage="No jobs found!" />
                <CommonPaginationNew data={postedJobsAcceptedList} onPageChange={(page) => setCurrentPage(page)} />
            {/*</div>*/}
            <WarningModal
              title={"Are you sure you want to cancel it?"}
                open={warningModal}
                desc={""}
                handleAgree={handleAgree}
                handleDisagree={handleDisagree}
                onClose={() => setWarningModal(false)}
            />
        </>
    );

}

const mapStateToProps = state => {
    const {serviceRequest} = state
    return {
        postedJobsAcceptedList: serviceRequest.postedJobsAccepted || {},
    }
}

export default connect(mapStateToProps)(PostedJobsAccepted)
