import React, {useEffect, useState} from "react";
import {Stack} from "@mui/material";
import classes from "../CareNote/CareNote.module.css";
import PrimaryButton from "../../../../../sharedComponents/buttons/PrimaryButton";
import {connect, useDispatch, useSelector} from "react-redux";
import {HttpsAction} from "../../../../../commonApiFunction/httpsAction";
import {change, Field, getFormValues, reduxForm, reset} from "redux-form";
import CustomInputField from "../../../../../sharedComponents/reduxfields/customInputField";
import {emailValidation, required} from "../../../../../helpers/validation";
import CustomTextareaField from "../../../../../sharedComponents/reduxfields/customTextareaField";
import CustomDropDownField from "../../../../../sharedComponents/reduxfields/customDropDown";
import CustomRadioGroup from "../../../../../sharedComponents/reduxfields/customRadioGroup";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import CustomLabel from "../../../../../sharedComponents/fields/CustomLabel";
import {useQueryClient} from "@tanstack/react-query";
import stringHelperFunctions from "../../../../../helpers/string.helpers";
import {getCityList, getClientResponsiblePartyContacts} from "../../../../../services/client";
import numberHelperFunctions from "../../../../../helpers/number.helpers";
import {invalidateClientsList} from "../../../../../queries/agency/clientsList";
import * as clientAction from "../../clientActionType";
import booleanHelperFunctions from "../../../../../helpers/boolean.helpers";
import RadioOptionsComponent from "../../../../../sharedComponents/radioOptions";
import useCustomNav from "../../../../../utils/hooks/useCustomNav";

const HIPAADisclosureAuthorizationRadioOptions = [
    {label: 'Yes', id: "yes1", value: 'true'},
    {label: 'No', id: "no1", value: 'false'}
];
const medicalDecisionsRadioOptions = [
    {label: 'Yes', id: "yes2", value: 'true'},
    {label: 'No', id: "no2", value: 'false'}
];

const RespParty = ({handleSubmit, formData, listLength, notNewAndNotDeletedListLength, agencyId}) => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // const {clientId} = useParams();
    const { clientId } = useOutletContext();

    const { goTo: gotoPatientDocumentsPage } = useCustomNav('/agency/clients/edit/documents');

    // const responsiblePartyContactsList = useSelector(state => state.client?.responsiblePartyContacts?.data);
    // const respPartyContacts = useSelector(
    //     (state) => {
    //         if (state != null && state.client != null && state.client.responsiblePartyContacts?.data != null) {
    //             console.log('submit- state.nurseListing.nurseEvaluations', state.nurseListing.nurseEvaluations);
    //         }
    //         return state.client?.responsiblePartyContacts?.data?.map(v => (v.id !== 0 ? {
    //             ...v,
    //             recordType: 'existing'
    //         } : {...v})) || [];
    //     }
    // );

    const gendersArray = queryClient.getQueryData(['list-genders']);
    const statesArray = queryClient.getQueryData(['list-provinces-states', 233]);
    const phoneTypesArray = queryClient.getQueryData(['list-phone-types']);
    const relationshipsArray = queryClient.getQueryData(['list-relationships']);

    const [cityList, setCityList] = useState({});
    const [selectedProvinceOrStateId, setSelectedProvinceOrStateId] = useState(0);
    const [loadedOnce, setLoadedOnce] = useState(false);

    const getStateId = async (e) => {
        let index = e.target.selectedIndex;
        let optionElement = e.target.childNodes[index];
        let optionattr = optionElement.getAttribute("value");
        setSelectedProvinceOrStateId(+optionattr);
        if (cityList[optionattr] == null || JSON.stringify(cityList[optionattr] === '{}')) {
            const response = await dispatch(getCityList(optionattr));
            setCityList(prevState => ({...prevState, [optionattr]: response?.data}));
        }
    };

    const getData = () => {
        // dispatch(HttpsAction({
        //     apiSection: "client",
        //     apiName: 'respPartyContact',
        //     queryParameter: `/${clientId}`,
        //     reducerName: 'CLIENT',
        //     reduxKeyName: 'responsiblePartyContacts',
        //     actionType: 'GET_RESP_PARTY_CONTACT',
        //     // queryParameter: `/${clientId}`,
        //     positiveCallBack: ({data}) => {
        //         console.log('test-', {data})
        //         //  const d =  [{
        //         //         firstName: data[`name${i + 1}`],
        //         //         relationshipId: data[`relationship${i + 1}`],
        //         //         phoneNumbers: [
        //         //             {
        //         //                 phoneTypeId: 1,
        //         //                 phoneNumber: data[`phone1${i + 1}`]
        //         //             }, {
        //         //                 phoneTypeId: 2,
        //         //                 phoneNumber: data[`phone2${i + 1}`]
        //         //             },
        //         //         ],
        //         //         address: data[`address${i + 1}`],
        //         //         countryId: 233,
        //         //         cityId: data[`city${i + 1}`],
        //         //         stateId: data[`state${i + 1}`],
        //         //         postalOrZipCode: data[`zipCode${i + 1}`],
        //         //         email: data[`email${i + 1}`],
        //         //         hipaaDisclosureAuthorization: data[`hippDisclosureAuthorization${i + 1}` === "true"],
        //         //         medicalDecisions: data[`medicalDecisions${i + 1}` === 'true']
        //         //     })
        //         // }]
        //     },
        //     negativeCallBack: ({response}) => {
        //         if (response?.data?.success === false) {
        //             handleAddContact()
        //         }
        //     }
        // }))
        dispatch(getClientResponsiblePartyContacts(clientId));
    }

    const handleAddContact = () => {
        // const data = [...(respPartyContacts || []), {
        //     id: 0,
        //     recordType: 'new',
        //     "firstName": "",
        //     "lastName": "",
        //     "relationshipId": "",
        //     "email": "",
        //     "phoneTypeId": "",
        //     "phoneNumber": "",
        //     "addressLine1": "",
        //     "cityId": "",
        //     "provinceOrStateId": "",
        //     "postalOrZipCode": "",
        //     "hipaaDisclosureAuthorization": "",
        //     "medicalDecisions": "",
        // }]
        // dispatch({
        //     type: `ADD_CLIENT_ADD_CONTACT`,
        //     payload: {data},
        //     name: 'respPartyContact',
        //     meta: {type: 'ADD_CONTACT'}
        // })

        let updatedList = Array.from({length: listLength}, (v, i) => i).map((val, index) => {
            return {
                recordType: formData[`recordType${index + 1}`],
                id: formData[`id${index + 1}`],
                firstName: formData[`firstName${index + 1}`],
                lastName: formData[`lastName${index + 1}`],
                genderId: formData[`genderId${index + 1}`],
                relationshipId: formData[`relationshipId${index + 1}`],
                email: formData[`email${index + 1}`],
                phoneTypeId: formData[`phoneTypeId${index + 1}`],
                phoneNumber: formData[`phoneNumber${index + 1}`],
                addressLine1: formData[`addressLine1${index + 1}`],
                cityId: formData[`cityId${index + 1}`],
                provinceOrStateId: formData[`provinceOrStateId${index + 1}`],
                postalOrZipCode: formData[`postalOrZipCode${index + 1}`],
                hipaaDisclosureAuthorization: formData[`hipaaDisclosureAuthorization${index + 1}`],
                medicalDecisions: formData[`medicalDecisions${index + 1}`],
                index,
            };
        });
        dispatch(HttpsAction({
            reducerName: 'CLIENT',
            reduxKeyName: 'responsiblePartyContacts',
            actionType: 'GET_RESP_PARTY_CONTACTS',
            data: updatedList,
            updateData: true,
            actionTypeIsDispatchType: true,
            positiveCallBack: (e) => {
                dispatch({type: clientAction.ADD_RESP_PART_CONTACT});
            },
        }));
    };

    const handleDeleteContact = (deletedIndex) => {
        // if (deletedIndex) {
        //     let clonedRespPartyContactsList = [...respPartyContacts];
        //     if (clonedRespPartyContactsList[deletedIndex - 1]?.id) {
        //         clonedRespPartyContactsList[deletedIndex - 1] = {
        //             recordType: 'deleted',
        //             id: clonedRespPartyContactsList[deletedIndex - 1].id
        //         };
        //
        //         dispatch({
        //             type: 'ADD_CLIENT_REMOVE_RESP_PARTY_CONTACT',
        //             payload: {data: clonedRespPartyContactsList},
        //             name: 'respPartyContact',
        //             meta: {type: 'REMOVE_RESP_PARTY_CONTACT'},
        //         });
        //     } else {
        //         const updatedList = respPartyContacts.filter((item, index) => index !== deletedIndex - 1)
        //         dispatch({
        //             type: 'ADD_CLIENT_REMOVE_RESP_PARTY_CONTACT',
        //             payload: {data: updatedList},
        //             name: 'respPartyContact',
        //             meta: {type: 'REMOVE_RESP_PARTY_CONTACT'},
        //         });
        //     }
        // }

        const list = Array.from({ length: listLength }, (v, i) => i);
        let updatedList = list.map((val, index) => {
            return {
                recordType: formData[`recordType${index + 1}`],
                id: formData[`id${index + 1}`],
                firstName: formData[`firstName${index + 1}`],
                lastName: formData[`lastName${index + 1}`],
                genderId: formData[`genderId${index + 1}`],
                relationshipId: formData[`relationshipId${index + 1}`],
                email: formData[`email${index + 1}`],
                phoneTypeId: formData[`phoneTypeId${index + 1}`],
                phoneNumber: formData[`phoneNumber${index + 1}`],
                addressLine1: formData[`addressLine1${index + 1}`],
                cityId: formData[`cityId${index + 1}`],
                provinceOrStateId: formData[`provinceOrStateId${index + 1}`],
                postalOrZipCode: formData[`postalOrZipCode${index + 1}`],
                hipaaDisclosureAuthorization: formData[`hipaaDisclosureAuthorization${index + 1}`],
                medicalDecisions: formData[`medicalDecisions${index + 1}`],
                index,
            };
        });
        const deletedItemIdValue = formData[`id${deletedIndex}`];
        if (deletedItemIdValue === '0') { // item not present in database, so remove item from list/array
            updatedList.splice(deletedIndex - 1, 1);
        } else { // item present in database, so identify/mark it for deletion for later API call
            updatedList[deletedIndex - 1].recordType = "deleted";
        }
// console.log('test- updatedList', updatedList)
        dispatch(HttpsAction({
            reducerName: 'CLIENT',
            reduxKeyName: 'responsiblePartyContacts',
            actionType: 'GET_RESP_PARTY_CONTACTS',
            data: updatedList,
            updateData: true,
            actionTypeIsDispatchType: true,
        }));
    };

    const onSubmit = async (data) => {
// console.log('test- respPartyContact', data);
//         const payload = [];
//
//         (respPartyContacts || []).forEach((v, i) => {
//             console.log({v})
//             if (v.recordType !== "deleted") {
//                 const recordType = v.id !== 0 ? "existing" : "new";
//                 ;
//                 payload.push({
//                     id: v.id,
//                     recordType,
//                     firstName: stringHelperFunctions.nullIfEmpty(data[`firstName${i + 1}`]),
//                     lastName: stringHelperFunctions.nullIfEmpty(data[`lastName${i + 1}`]),
//                     relationshipId: numberHelperFunctions.nullIfEmpty(data[`relationshipId${i + 1}`]),
//                     email: stringHelperFunctions.nullIfEmpty(data[`email${i + 1}`]),
//                     // phoneNumbers: [
//                     //     {
//                     //         phoneTypeId: 1,
//                     //         phoneNumber: data[`phone1${i + 1}`],
//                     //     }, {
//                     //         phoneTypeId: 2,
//                     //         phoneNumber: data[`phone2${i + 1}`],
//                     //     },
//                     // ],
//                     phoneTypeId: numberHelperFunctions.nullIfEmpty(data[`phoneTypeId${i + 1}`]),
//                     phoneNumber: stringHelperFunctions.nullIfEmpty(data[`phoneNumber${i + 1}`]),
//                     addressLine1: stringHelperFunctions.nullIfEmpty(data[`addressLine1${i + 1}`]),
//                     // addressLine2: stringHelperFunctions.nullIfEmpty(data[`addressLine2${i + 1}`]),
//                     cityId: numberHelperFunctions.nullIfEmpty(data[`cityId${i + 1}`]),
//                     provinceOrStateId: numberHelperFunctions.nullIfEmpty(data[`provinceOrStateId${i + 1}`]),
//                     postalOrZipCode: stringHelperFunctions.nullIfEmpty(data[`postalOrZipCode${i + 1}`]),
//                     hipaaDisclosureAuthorization: data[`hipaaDisclosureAuthorization${i + 1}`] === "true",
//                     medicalDecisions: data[`medicalDecisions${i + 1}`] === "true",
//                 })
//             } else {
//                 payload.push(v)
//             }
//         })
//
//         dispatch(HttpsAction({
//             method: 'PUT',
//             apiSection: 'client',
//             apiName: 'respPartyContact',
//             queryParameter: `/${clientId}`,
//             data: payload,
//             positiveCallBack: () => {
//                 invalidateClientsList(queryClient, agencyId);
//                 navigate(`/agency/clients/${clientId}/documents/0`)
//             }
//         }))

        const payload = [];
        for (let i = 0; i < listLength; i++) {
            if (data[`recordType${i + 1}`] !== "deleted") {
                const recordType = data[`recordType${i + 1}`];
                console.log(`index ==> ${i} ==>`, data[`recordType${i + 1}`])
                let id = +data[`id${i + 1}`];
                payload.push({
                    recordType,
                    id: id === 0 ? undefined : id,
                    firstName: stringHelperFunctions.nullIfEmpty(data[`firstName${i + 1}`]),
                    lastName: stringHelperFunctions.nullIfEmpty(data[`lastName${i + 1}`]),
                    genderId: numberHelperFunctions.nullIfEmpty(data[`genderId${i + 1}`], true),
                    relationshipId: numberHelperFunctions.nullIfEmpty(data[`relationshipId${i + 1}`], true),
                    email: stringHelperFunctions.nullIfEmpty(data[`email${i + 1}`]),
                    phoneTypeId: numberHelperFunctions.nullIfEmpty(data[`phoneTypeId${i + 1}`]),
                    phoneNumber: stringHelperFunctions.nullIfEmpty(data[`phoneNumber${i + 1}`]),
                    addressLine1: stringHelperFunctions.nullIfEmpty(data[`addressLine1${i + 1}`]),
                    cityId: numberHelperFunctions.nullIfEmpty(data[`cityId${i + 1}`], true),
                    provinceOrStateId: numberHelperFunctions.nullIfEmpty(data[`provinceOrStateId${i + 1}`], true),
                    postalOrZipCode: stringHelperFunctions.nullIfEmpty(data[`postalOrZipCode${i + 1}`]),
                    hipaaDisclosureAuthorization: booleanHelperFunctions.nullIfEmpty(data[`hipaaDisclosureAuthorization${i + 1}`]),
                    medicalDecisions: booleanHelperFunctions.nullIfEmpty(data[`medicalDecisions${i + 1}`]),
                });
            } else {
                const recordType = data[`recordType${i + 1}`];
                let id = +data[`id${i + 1}`];
                payload.push({recordType, id: id === 0 ? undefined : Number(id)})
            }
        }
// console.log('test- payload', payload)
        dispatch(HttpsAction({
            method: 'PUT',
            apiSection: 'client',
            apiName: 'respPartyContact',
            queryParameter: `/${clientId}`,
            data: payload,
            positiveCallBack: (res) => {
                // if (res.success) {
                    invalidateClientsList(queryClient, agencyId);
                    // navigate(`/agency/clients/${clientId}/documents/0`);
                    gotoPatientDocumentsPage();
                // }
            }
        }));
    };

    useEffect(() => {
// console.log('test- ', listLength, formData, loadedOnce)
        if (listLength > 0 && formData != null && JSON.stringify(formData) !== '{}' && formData['id1'] !== '0' && !loadedOnce) {
            setLoadedOnce(true);
            setCityList({});
            // responsiblePartyContactsList.forEach((value, index) => {
            //     const provinceOrStateId = value?.provinceOrStateId?.toString();
            //     const cityId = value?.cityId?.toString();
            //     if (provinceOrStateId != null && provinceOrStateId.length > 0 && provinceOrStateId !== '0') {
            //         // IIFE
            //         (async () => {
            //             const response = await dispatch(getCityList(provinceOrStateId));
            //             setCityList(prevState => ({...prevState, [provinceOrStateId]: response?.data}));
            //             // bind city on load
            //             if (cityId != null && cityId.length > 0) {
            //                 dispatch(change('responsiblePartiesForm', `cityId${index + 1}`, cityId));
            //             }
            //         })();
            //     }
            // });

// console.log('test- formData', formData)
            const loadedList = Array.from({length: listLength}, (v, i) => i).map((val, index) => {
                return {
                    // recordType: formData[`recordType${index + 1}`],
                    // id: formData[`id${index + 1}`],
                    // firstName: formData[`firstName${index + 1}`],
                    // lastName: formData[`lastName${index + 1}`],
                    // relationshipId: formData[`relationshipId${index + 1}`],
                    // email: formData[`email${index + 1}`],
                    // phoneTypeId: formData[`phoneTypeId${index + 1}`],
                    // phoneNumber: formData[`phoneNumber${index + 1}`],
                    // addressLine1: formData[`addressLine1${index + 1}`],
                    cityId: formData[`cityId${index + 1}`],
                    provinceOrStateId: formData[`provinceOrStateId${index + 1}`],
                    // postalOrZipCode: formData[`postalOrZipCode${index + 1}`],
                    // hipaaDisclosureAuthorization: formData[`hipaaDisclosureAuthorization${index + 1}`],
                    // medicalDecisions: formData[`medicalDecisions${index + 1}`],
                    // index,
                };
            });
            loadedList.forEach((value, index) => {
// console.log('test- value', value)
                const provinceOrStateId = value?.provinceOrStateId?.toString() || null;
                const cityId = value?.cityId?.toString() || null;
                if (provinceOrStateId != null && provinceOrStateId.length > 0 && provinceOrStateId !== '0') {
                    // IIFE
                    (async () => {
                        const response = await dispatch(getCityList(provinceOrStateId));
                        setCityList(prevState => ({...prevState, [provinceOrStateId]: response?.data}));
                        // bind city on load
                        if (cityId != null && cityId.length > 0 && cityId !== '0') {
                            dispatch(change('responsiblePartiesForm', `cityId${index + 1}`, cityId));
                        }
                    })();
                }
            });
        }
    }, [listLength, formData, loadedOnce]);

    useEffect(() => {
        if (listLength === 0) {
            dispatch({type: clientAction.ADD_RESP_PART_CONTACT});
        }
    }, [listLength === 0]);

    useEffect(() => {
        getData();
        return () => {
            dispatch(HttpsAction({
                reducerName: 'CLIENT',
                reduxKeyName: 'responsiblePartyContacts',
                actionType: 'GET_RESP_PARTY_CONTACTS',
                removeData: true,
                actionTypeIsDispatchType: true,
            }));
            dispatch(reset('responsiblePartiesForm'));
        };
    }, []);

// console.log('test- cityList', cityList)

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Field
              id="xtra"
              name="xtra"
              type="hidden"
              component={CustomInputField}
            />
            <div className="p-0 m-0 d-flex flex-column justify-content-start align-content-start gap-3">
                {/*{(respPartyContacts || [])?.map((v, i) => (*/}
                {listLength > 0 && (Array.from({ length: listLength }, (val, idx) => idx) || []).map((v, i) => {
                    // let cities = cityList[String(selectedProvinceOrStateId)];
                    // if (cities == null && formData != null) {
                    //   cities = cityList[formData[`provinceOrStateId${i + 1}`]];
                    // }
                    return (
                      <React.Fragment key={i}>
                          <div className="card" style={{
                              overflow: "hidden",
                              borderWidth: formData != null && JSON.stringify(formData) !== '{}' && formData[`recordType${i + 1}`] === "deleted" ? "0" : "auto",
                              height: formData != null && JSON.stringify(formData) !== '{}' && formData[`recordType${i + 1}`] === "deleted" ? "1px" : "auto"
                          }}>
                              <div className="card-body">
                                  <div className={`row ${i === 0 ? '' : 'mt-2'}`}>
                                      <div className="col-6 d-flex align-items-center">
                                          <div className={`col-4`} style={{fontSize: "18px", fontWeight: 500, whiteSpace: "nowrap"}}>
                                              Contact #{i + 1}
                                          </div>
                                      </div>
                                      {(notNewAndNotDeletedListLength > 0 || listLength > 1 || i > 0) && <div className="col-6 d-flex justify-content-end">
                                          <PrimaryButton
                                            type="button"
                                            className="btn-view-repo gap-2 px-4 py-2"
                                            text={"Delete Contact"}
                                            onClick={() => handleDeleteContact(i + 1)}
                                          />
                                      </div>}
                                      <Field
                                        name={`recordType${i + 1}`}
                                        type="hidden"
                                        component={CustomInputField}
                                      />
                                      <Field
                                        name={`id${i + 1}`}
                                        type="hidden"
                                        component={CustomInputField}
                                      />
                                      <div className={classes.contactFormHeadingBorder}/>
                                      <div className={classes.contactFormMain}>
                                          <div>
                                              <div className="row">
                                                  <div className="col-4">
                                                      <div className="mb-3 input-field-main">
                                                          <Field
                                                            className="form-control mk-wi"
                                                            name={`firstName${i + 1}`}
                                                            label="First Name"
                                                            type="text"
                                                            component={CustomInputField}
                                                            requiredMark={true}
                                                            validate={required}
                                                          />
                                                      </div>
                                                  </div>
                                                  <div className="col-4">
                                                      <div className="mb-3 input-field-main">
                                                          <Field
                                                            className="form-control mk-wi"
                                                            name={`lastName${i + 1}`}
                                                            label="Last Name"
                                                            type="text"
                                                            component={CustomInputField}
                                                            // requiredMark={true}
                                                            // validate={required}
                                                          />
                                                      </div>
                                                  </div>
                                                  <div className="col-4">
                                                      <Field
                                                        className="form-control1 input-field-main"
                                                        name={`genderId${i + 1}`}
                                                        // label="Please select your gender"
                                                        label="Gender"
                                                        component={RadioOptionsComponent}
                                                        // requiredMark={true}
                                                        // validate={required}
                                                        optionsList={gendersArray}
                                                        handleGenderChanged={({target}) => {
                                                            if (target?.value != null) dispatch(change('responsiblePartiesForm', `genderId${i + 1}`, +target.value));
                                                        }}
                                                      />
                                                  </div>
                                                  <div className="col-4">
                                                      <div className="mb-3 input-field-main">
                                                          <Field
                                                            className="form-control mk-wi"
                                                            label="Relationship"
                                                            name={`relationshipId${i + 1}`}
                                                            component={CustomDropDownField}
                                                          >
                                                              <option value="">Select Relationship</option>
                                                              {(relationshipsArray || []).map((res, index) => (
                                                                <option key={index} value={res.id}>
                                                                    {res.name}
                                                                </option>
                                                              ))}
                                                          </Field>

                                                      </div>
                                                  </div>
                                                  <div className="col-3">
                                                      <div className="mb-3 input-field-main">
                                                          <Field
                                                            className="form-control mk-wi"
                                                            name={`email${i + 1}`}
                                                            label="Email"
                                                            type="text"
                                                            requiredMark={true}
                                                            validate={[required, emailValidation]}
                                                            component={CustomInputField}
                                                          />
                                                      </div>
                                                  </div>
                                                  {/*<div className="col-6">
                                                   <div className="mb-3 input-field-main">
                                                   <Field
                                                   className="form-control mk-wi"
                                                   name={`phone1${i + 1}`}
                                                   label="Phone#1"
                                                   type="number"
                                                   component={CustomInputField}
                                                   />
                                                   </div>
                                                   </div>
                                                   <div className="col-6">
                                                   <div className="mb-3 input-field-main">
                                                   <Field
                                                   className="form-control mk-wi"
                                                   name={`phone2${i + 1}`}
                                                   label="Phone#2"
                                                   type="number"
                                                   component={CustomInputField}
                                                   />
                                                   </div>
                                                   </div>*/}
                                                  <div className="col-3">
                                                      <div className="mb-3 input-field-main">
                                                          <Field
                                                            className="form-control mk-wi"
                                                            name={`phoneNumber${i + 1}`}
                                                            label="Phone"
                                                            type="number"
                                                            component={CustomInputField}
                                                            // requiredMark={true}
                                                            // validate={required}
                                                          />
                                                      </div>
                                                  </div>
                                                  <div className="col-2">
                                                      <div className="mb-3 input-field-main">
                                                          <Field
                                                            className="form-control mk-wi"
                                                            label="Type"
                                                            name={`phoneTypeId${i + 1}`}
                                                            component={CustomDropDownField}
                                                            // requiredMark={true}
                                                            // validate={required}
                                                          >
                                                              <option value="">Phone Type</option>
                                                              {(phoneTypesArray || []).map((res, index) => (
                                                                <option key={index} value={res.id}>
                                                                    {res.name}
                                                                </option>
                                                              ))}
                                                          </Field>
                                                      </div>
                                                  </div>
                                                  <div className="col-12">
                                                      <div className="mb-3 input-field-main">
                                                          <Field
                                                            className="form-control mk-wi"
                                                            name={`addressLine1${i + 1}`}
                                                            label="Address"
                                                            component={CustomTextareaField}
                                                            // requiredMark={true}
                                                            // validate={required}
                                                          />
                                                      </div>
                                                  </div>
                                                  <div className="col-4">
                                                      <div className="mb-3 input-field-main">
                                                          <Field
                                                            label="State"
                                                            className="form-control mk-wi"
                                                            name={`provinceOrStateId${i + 1}`}
                                                            component={CustomDropDownField}
                                                            // requiredMark={true}
                                                            // validate={required}
                                                            onChange={(e) => getStateId(e)}
                                                          >
                                                              <option value="">Please Select</option>
                                                              {(statesArray || []).map((res, index) =>
                                                                <option key={index} value={res.id}>{res.name}</option>
                                                              )}
                                                          </Field>
                                                      </div>
                                                  </div>
                                                  <div className="col-4">
                                                      <div className="mb-3 input-field-main">
                                                          <Field
                                                            className="form-control mk-wi"
                                                            label="City"
                                                            name={`cityId${i + 1}`}
                                                            component={CustomDropDownField}
                                                            // validate={required}
                                                            // requiredMark={true}
                                                          >
                                                              <option value="">Please select</option>
                                                              {((formData != null && cityList[formData[`provinceOrStateId${i + 1}`]]) || cityList[String(selectedProvinceOrStateId)])?.map((res, index) => {
                                                                  return <option key={index} value={res.id}>{res.name}</option>
                                                              })}
                                                          </Field>
                                                      </div>
                                                  </div>
                                                  <div className="col-4">
                                                      <div className="mb-3 input-field-main">
                                                          <Field
                                                            className="form-control mk-wi"
                                                            name={`postalOrZipCode${i + 1}`}
                                                            label="Zip code"
                                                            type="text"
                                                            component={CustomInputField}
                                                            // requiredMark={true}
                                                            // validate={required}
                                                          />
                                                      </div>
                                                  </div>
                                                  <div className="col-6">
                                                      <div className={`input-field-main ${classes.contactCheckBox}`}>
                                                          <CustomLabel labelFor={`hipaaDisclosureAuthorization${i + 1}`}
                                                                       requiredMark={false} className={classes.contactCheckBoxLabel}
                                                                       labelText="HIPAA Disclosure Authorization"/>
                                                          <Field
                                                            id={`hipaaDisclosureAuthorization${i + 1}`}
                                                            name={`hipaaDisclosureAuthorization${i + 1}`}
                                                            component={CustomRadioGroup}
                                                            options={HIPAADisclosureAuthorizationRadioOptions.map((v, index) => ({
                                                                ...v,
                                                                id: (i * index + 10)
                                                            }))}
                                                            requiredMark={false}
                                                            onChange={(e) => undefined}
                                                          />
                                                      </div>
                                                  </div>
                                                  <div className="col-6">
                                                      <div className={`mb-3 input-field-main ${classes.contactCheckBox}`}>
                                                          <CustomLabel labelFor={`medicalDecisions${i + 1}`} requiredMark={false}
                                                                       className={classes.contactCheckBoxLabel}
                                                                       labelText="Medical Decisions"/>
                                                          <Field
                                                            name={`medicalDecisions${i + 1}`}
                                                            component={CustomRadioGroup}
                                                            options={medicalDecisionsRadioOptions.map((v, index) => ({
                                                                ...v,
                                                                id: (i * index + 2)
                                                            }))}
                                                            requiredMark={false}
                                                            onChange={(e) => undefined}
                                                          />
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </React.Fragment>
                    );
                })}
                <div className={'w-100 d-flex flex-row justify-content-start gap-2'}>
                    <PrimaryButton
                      type="button"
                      className="btn-view-repo gap-2 px-4 py-2"
                      text={"Add Another Contact"}
                      onClick={() => handleAddContact()}
                    />
                </div>
            </div>
            <div
              className="d-flex justify-content-center"
              style={{marginTop: "40px"}}>
                <PrimaryButton
                  type="submit"
                  className="btn-view-repo gap-2 px-5 py-2"
                  text={"Save"}
                  onClick={() => {
                      dispatch(change("responsiblePartiesForm", 'xtra', '1'));
                      return undefined;
                  }}
                />
            </div>
        </form>
    )
};

const mapStateToProps = state => {
    // const {auth, client: {responsiblePartyContacts: {data: contactsList = []} = {data: []}}} = state;
    const {auth, client: {responsiblePartyContacts: contactsList = []}} = state;
    let initialValues = {};
// console.log('test- contactsList', contactsList)
    contactsList?.forEach((respPartyContact, index) => {
        initialValues[`recordType${index + 1}`] = respPartyContact.recordType || "existing";
        initialValues[`id${index + 1}`] = respPartyContact.id || 0;
        initialValues[`firstName${index + 1}`] = respPartyContact?.firstName || "";
        initialValues[`lastName${index + 1}`] = respPartyContact?.lastName || "";
        initialValues[`genderId${index + 1}`] = respPartyContact?.genderId?.toString() || "";
        initialValues[`relationshipId${index + 1}`] = respPartyContact?.relationshipId?.toString() || "";
        initialValues[`email${index + 1}`] = respPartyContact?.email || "";
        initialValues[`phoneTypeId${index + 1}`] = respPartyContact?.phoneTypeId || "";
        initialValues[`phoneNumber${index + 1}`] = respPartyContact?.phoneNumber || "";
        initialValues[`addressLine1${index + 1}`] = (respPartyContact?.addressLine1 || "") === "Nil" ? "" : respPartyContact?.addressLine1;
        initialValues[`cityId${index + 1}`] = respPartyContact?.cityId?.toString() || "";
        initialValues[`provinceOrStateId${index + 1}`] = respPartyContact?.provinceOrStateId?.toString() || "";
        initialValues[`postalOrZipCode${index + 1}`] = respPartyContact?.postalOrZipCode?.toString() || "";
        initialValues[`hipaaDisclosureAuthorization${index + 1}`] = respPartyContact?.hipaaDisclosureAuthorization != null ? (respPartyContact?.hipaaDisclosureAuthorization === 1 ? "true" : "false") : "false";
        initialValues[`medicalDecisions${index + 1}`] = respPartyContact?.medicalDecisions != null ? (respPartyContact?.medicalDecisions === 1 ? "true" : "false") : "false";
    });
    return {
        isAdmin: auth?.authData?.isAdmin || false,
        agencyId: auth?.authData?.agencyId || auth.agencyId,
        listLength: (contactsList || []).length,
        notNewAndNotDeletedListLength: (contactsList || []).filter(p => !(p.recordType === 'new' || p.recordType === 'deleted')).length,
        formData: getFormValues('responsiblePartiesForm')(state),
        initialValues,
    };
};

export default connect(mapStateToProps)(
  reduxForm({form: "responsiblePartiesForm", enableReinitialize: true})(RespParty)
);
