import React, {useEffect, useState} from "react";
import {change, Field, reduxForm} from "redux-form";
import CustomInputField from "../../../../sharedComponents/reduxfields/customInputField";
import {Stack} from "@mui/material";
import {connect, useDispatch, useSelector} from "react-redux";
import CustomDropDownField from "../../../../sharedComponents/reduxfields/customDropDown";
import PrimaryButton from "../../../../sharedComponents/buttons/PrimaryButton";
import {useNavigate, useParams} from "react-router-dom";
import {HttpsAction} from "../../../../commonApiFunction/httpsAction";
import {getFrequenciesList, getNursesList, getPatientsList, getServiceRequestTypesList} from "../../../../services/commonServices";
import {mapStateToPropsFactory} from "react-redux/es/connect/mapStateToProps";
import MultiSelectDropdown from "../../../../sharedComponents/multiSelect";
import {getAllServiceRequest} from "../../../../services/serviceRequest";
import {required} from "../../../../helpers/validation";
import {useQueryClient} from "@tanstack/react-query";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DesktopTimePicker} from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import dateHelperFunctions from "../../../../helpers/date.helpers";
import numberHelperFunctions from "../../../../helpers/number.helpers";

const Details = ({show, setInitialDataLoaded, doUpdateApiCall, showingDataFor = '', reload, taskDetails, handleSubmit, handleSelectTab, initialValues, agencyId/*, walletDetails, isFreelanceNurse*/}) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {id} = useParams();

    // const initialValues = taskDetails;
    const disableNurseSelection = true;
    // const agencyBalance = numberHelperFunctions.nullIfEmptyFloat(walletDetails?.balance) || 0;

    const patientsArray = queryClient.getQueryData(['list-patients', agencyId]);
    // const patientsArray = queryClient.getQueryData(['list-patients-with-latlng', agencyId]);
    // const nursesArray = queryClient.getQueryData(['list-internal-nurses', agencyId]);
    const nursesArray = queryClient.getQueryData([disableNurseSelection ? 'list-nurses' : 'list-internal-nurses', agencyId]);
    const servicesRequestTypesArray = queryClient.getQueryData(['list-service-request-types', agencyId]);
    const dayFrequenciesArray = queryClient.getQueryData(['list-day-frequencies']);

    // const weekDaysOptions = useSelector(state => state.client?.weekDays?.data);

    const [selectedOptions, setSelectedOptions] = useState([]);
    // const [clientList, setClientList] = useState([]);
    // const [nursesList, setNursesList] = useState([]);
    // const [servicesList, setServicesList] = useState([]);
    const [showDateRange, setShowDateRange] = useState(false);
    const [showSingleDate, setShowSingleDate] = useState(true);
    const [alreadyClockedIn, setAlreadyClockedIn] = useState(false);
    // const [scheduledClockInVal, setScheduledClockInVal] = useState(initialValues?.scheduledStartTimeDate);
    // const [scheduledClockOutVal, setScheduledClockOutVal] = useState(initialValues?.scheduledEndTimeDate);
    const [scheduledClockInVal, setScheduledClockInVal] = useState(null);
    const [scheduledClockOutVal, setScheduledClockOutVal] = useState(null);
    const [scheduledTimeValidationError, setScheduledTimeValidationError] = useState('');
    const [isEditable, setIsEditable] = useState(!(showingDataFor === 'activeVisits' || showingDataFor === 'completedVisits' || showingDataFor === 'missedVisits' || showingDataFor === 'incompleteVisits'));
    const [dataLoaded, setDataLoaded] = useState(false);

    const getSelectedOptions = (selectedOptions) => {
        setSelectedOptions(selectedOptions)
    }

    const handleClockInChange = (newValue) => {
        setScheduledClockInVal(newValue);
        const dateVal = new Date(Date.parse(newValue));
        const clockInVal = dateVal.toLocaleTimeString('en-US', {hour12: false}).substring(0, 5);
        console.log('Selected clock-in:', newValue, clockInVal, dateVal);
        if (scheduledClockOutVal != null && newValue.isAfter(scheduledClockOutVal)) {
            setScheduledTimeValidationError('Scheduled Clock In time cannot be after Clock Out time');
        } else if (scheduledClockOutVal != null && newValue.isSame(scheduledClockOutVal)) {
            setScheduledTimeValidationError('Scheduled Clock In time cannot be the same as Clock Out time');
        } else {
            dispatch(change('details', 'scheduledClockIn', clockInVal));
            setScheduledTimeValidationError('');
        }
    };

    const handleClockOutChange = (newValue) => {
        setScheduledClockOutVal(newValue);
        const dateVal = new Date(Date.parse(newValue));
        const clockOutVal = dateVal.toLocaleTimeString('en-US', {hour12: false}).substring(0, 5);
        console.log('Selected clock-out:', newValue, clockOutVal, dateVal);
        if (scheduledClockInVal != null && newValue.isBefore(scheduledClockInVal)) {
            setScheduledTimeValidationError('Scheduled Clock Out time cannot be before Clock In time');
        } else if (scheduledClockInVal != null && newValue.isSame(scheduledClockInVal)) {
            setScheduledTimeValidationError('Scheduled Clock Out time cannot be the same as Clock In time');
        } else {
            dispatch(change('details', 'scheduledClockOut', clockOutVal));
            setScheduledTimeValidationError('');
        }
    };

    const onSubmit = ({startDate, endDate, clockIn, clockOut, scheduledClockIn, scheduledClockOut/*, hours, frequencyIds*/, serviceRequestTypeId, patientId, nurseId}) => {
        handleSelectTab('', 1)
        // dispatch(HttpsAction({
        //     method: 'PUT',
        //     queryParameter: `/${1}`,
        //     apiSection: 'client',
        //     apiName: 'serviceDetails',
        //     data: {
        //         patientId: 0,
        //         patientPayDescription: "string",
        //         patientRequestId: 0,
        //         patientJobDescription: "string",
        //         serviceRequestTypeId: 0,
        //         serviceRequestStatusId: 0,
        //         requiresDischargeSummary: true,
        //         requiresInitialAssessment: true,
        //         requiresSupervisoryVisits: true,
        //         startDate,
        //         endDate: showSingleDate ? startDate : endDate,
        //         clockIn,
        //         clockOut,
        //         // hours,
        //         frequencyIds: showSingleDate ? [0] : selectedOptions.map(item => item.id),
        //         // frequencyIds: "0",
        //         agencyRateToClient: 0,
        //         nurseRate: 0
        //     },
        //     positiveCallBack: () => {
        //         // navigate(`/service-details/financial/${id}`)
        //     }
        // }))

        // const inDateVal = new Date(Date.parse(scheduledClockInVal));
        // const scheduledStartTime = inDateVal.toLocaleTimeString('en-US', {hour12: false}).substring(0, 5);
        // const outDateVal = new Date(Date.parse(scheduledClockOutVal));
        // const scheduledEndTime = outDateVal.toLocaleTimeString('en-US', {hour12: false}).substring(0, 5);

        const payload = {
            // updatingFor: showingDataFor,
            serviceRequestTypeId: +serviceRequestTypeId,
            patientId: +patientId,
            assignedNurseId: +nurseId,
            taskDate: startDate,
            scheduledStartTime: scheduledClockIn,
            scheduledEndTime: scheduledClockOut,
            // scheduledStartTime,
            // scheduledEndTime,
        };
        // dispatch(HttpsAction({
        //     method: 'PUT',
        //     queryParameter: `/${initialValues.taskId}`,
        //     apiSection: 'serviceRequests',
        //     apiName: 'taskDetails',
        //     data: payload,
        //     positiveCallBack: () => {
        //         reload();
        //     }
        // }));
        doUpdateApiCall(payload);
    };

    useEffect(() => {
        if (dataLoaded && scheduledClockInVal != null && scheduledClockOutVal != null) {
            const {hrs, mins} = dateHelperFunctions.timeDiffInHoursAndMins(scheduledClockInVal, scheduledClockOutVal);
            dispatch(change('details', 'scheduledHoursText', dateHelperFunctions.hoursAndMinutesToText(hrs, mins)));
        }
    }, [scheduledClockInVal, scheduledClockOutVal]);

    // useEffect(() => {
    //     if (nursesList.length > 0 && initialValues != null && initialValues.nurseId != null) {
    //         dispatch(change('details', 'nurseId', initialValues.nurseId))
    //     }
    // }, [nursesList]);

    // useEffect(() => {
    //     if (serviceDetails) {
    //         setSelectedOptions(() => serviceDetails?.frequencies?.map(e => ({
    //             ...e,
    //             label: e.frequency,
    //             value: e.id
    //         })) || [])
    //     }
    // }, [serviceDetails]);

    useEffect(() => {
        if (!dataLoaded && initialValues != null && JSON.stringify(initialValues) !== '{}' && initialValues.taskId > 0) {
            setDataLoaded(true);
            // setScheduledClockInVal(initialValues.scheduledStartTimeDate);
            // setScheduledClockOutVal(initialValues.scheduledEndTimeDate);
            // dispatch(change('details', 'scheduledClockIn', initialValues.scheduledStartTime));
            // dispatch(change('details', 'scheduledClockOut', initialValues.scheduledEndTime));

            // if (initialValues.scheduledStartTime != null && initialValues.scheduledStartTime.length > 0)
            //     // dispatch(change('details', 'scheduledClockIn', initialValues.scheduledStartTime));
            //     setScheduledClockInVal(initialValues.scheduledStartTimeDate);
            // if (initialValues.scheduledEndTime != null && initialValues.scheduledEndTime.length > 0)
            //     // dispatch(change('details', 'scheduledClockOut', initialValues.scheduledEndTime));
            //     setScheduledClockOutVal(initialValues.scheduledEndTimeDate);
        }
    }, [initialValues]);

    useEffect(() => {
        if (dataLoaded) {
            if (initialValues.clockIn != null && initialValues.clockIn.length > 0) {
                setAlreadyClockedIn(true);

                // show actual clock in/out difference in hours and minutes
                if (initialValues.clockOut != null && initialValues.clockOut.length > 0) {
                    // console.log('test- work time', initialValues.clockIn, initialValues.clockOut);
                    const timeFrom = dateHelperFunctions.convertTimeStringToDate(initialValues.clockIn);
                    const timeTo = dateHelperFunctions.convertTimeStringToDate(initialValues.clockOut);
                    const {hrs, mins} = dateHelperFunctions.timeDiffInHoursAndMins(timeFrom, timeTo);
                    dispatch(change('details', 'hoursWorked', dateHelperFunctions.hoursAndMinutesToText(hrs, mins)));
                }
            }

            if (initialValues.scheduledStartTime != null && initialValues.scheduledStartTime.length > 0) {
                // setScheduledClockInVal(initialValues.scheduledStartTimeDate);
                setScheduledClockInVal(dateHelperFunctions.convertTimeStringToDate(initialValues.scheduledStartTime));
                dispatch(change('details', 'scheduledClockIn', initialValues.scheduledStartTime));
            }
            if (initialValues.scheduledEndTime != null && initialValues.scheduledEndTime.length > 0) {
                // setScheduledClockOutVal(initialValues.scheduledEndTimeDate);
                setScheduledClockOutVal(dateHelperFunctions.convertTimeStringToDate(initialValues.scheduledEndTime));
                dispatch(change('details', 'scheduledClockOut', initialValues.scheduledEndTime));
            }

            setInitialDataLoaded(true);
        }
    }, [dataLoaded]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} style={{display: show ? 'block' : 'none'}}>
                <Stack margin={'15px 0 0 0'}>
                    {showDateRange && <div className={'row'}>
                        <div className={'col-6'}>
                            <div className="mb-4 input-field-main">
                                <Field
                                  className="form-control mk-wi"
                                  name="startDate"
                                  label="Start Date"
                                  type="date"
                                  component={CustomInputField}
                                  requiredMark={true}
                                  // readOnly={true}
                                />
                            </div>
                        </div>
                        <div className={'col-6'}>
                            <div className="mb-4 input-field-main">
                                <Field
                                  className="form-control mk-wi"
                                  name="endDate"
                                  label="End Date"
                                  type="date"
                                  component={CustomInputField}
                                  requiredMark={true}
                                  // readOnly={true}
                                />
                            </div>
                        </div>
                    </div>}
                    {showSingleDate && <div className="row position-relative">
                        {/*<div className={'col-6'}>
                         <div className="mb-4 input-field-main">
                         <Field
                         className="form-control mk-wi"
                         name="startDate"
                         label="Visit Date"
                         type="date"
                         component={CustomInputField}
                         requiredMark={true}
                         // validate={required}
                         // readOnly={alreadyClockedIn}
                         // readOnly={true}
                         ></Field>
                         </div>
                         </div>
                         <div className={'col-3'}>
                         <div className="mb-4 input-field-main">
                         <Field
                         className="form-control mk-wi"
                         name="scheduledTimingsAmPm"
                         label="Schedule"
                         type="text"
                         component={CustomInputField}
                         readOnly={true}
                         ></Field>
                         </div>
                         </div>
                         <div className={'col-2'}>
                         <div className="mb-4 input-field-main">
                         <Field
                         className="form-control mk-wi"
                         name="scheduledHours"
                         label="Total Hours Scheduled"
                         type="number"
                         readOnly={true}
                         component={CustomInputField}
                         />
                         </div>
                         </div>*/}
                        <div className={'col-4'}>
                            <div className="mb-4 input-field-main">
                                <Field
                                  className="form-control mk-wi"
                                  name="startDate"
                                  label="Visit Date"
                                  type="date"
                                  component={CustomInputField}
                                  requiredMark={true}
                                  // validate={required}
                                  // readOnly={alreadyClockedIn}
                                  // readOnly={true}
                                ></Field>
                            </div>
                        </div>
                        <div className={'col-3 pt-3'}>
                            <div className="mb-4 input-field-main">
                                {/*<Field
                                  className="form-control mk-wi"
                                  name="scheduledStartTime"
                                  label="Scheduled Clock In"
                                  type="text"
                                  component={CustomInputField}
                                  requiredMark={true}
                                ></Field>*/}
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopTimePicker
                                      label="Scheduled Clock In"
                                      value={scheduledClockInVal}
                                      onChange={handleClockInChange}
                                      ampm={false}
                                      renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                        <div className={'col-3 pt-3'}>
                            <div className="mb-4 input-field-main">
                                {/*<Field
                                  className="form-control mk-wi"
                                  name="scheduledEndTime"
                                  label="Scheduled Clock Out"
                                  type="text"
                                  component={CustomInputField}
                                  requiredMark={true}
                                ></Field>*/}
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopTimePicker
                                      label="Scheduled Clock Out"
                                      value={scheduledClockOutVal}
                                      onChange={handleClockOutChange}
                                      ampm={false}
                                      renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                        <div className={'col-2'}>
                            <div className="mb-4 input-field-main">
                                <Field
                                  className="form-control mk-wi"
                                  name="scheduledHoursText"
                                  label="Scheduled Hours/Mins"
                                  type="text"
                                  readOnly={true}
                                  component={CustomInputField}
                                />
                            </div>
                        </div>
                        {scheduledTimeValidationError && <p style={{color: 'red', left: 'calc(4/12*1150px)'}} className="error-message position-absolute bottom-0">{scheduledTimeValidationError}</p>}
                    </div>}
                    <div className={'row'}>
                        <div className={'col-4'}>
                            <div className="mb-4 input-field-main">
                                <Field
                                  className="form-control mk-wi"
                                  name="clockIn"
                                  label="Clock In"
                                  type="time"
                                  component={CustomInputField}
                                  // requiredMark={!alreadyClockedIn}
                                  // readOnly={alreadyClockedIn}
                                  requiredMark={false}
                                  readOnly={true}
                                />
                            </div>
                        </div>
                        <div className={'col-4'}>
                            <div className="mb-4 input-field-main">
                                <Field
                                  className="form-control mk-wi"
                                  name="clockOut"
                                  label="Clock Out"
                                  type="time"
                                  component={CustomInputField}
                                  // requiredMark={!alreadyClockedIn}
                                  // readOnly={alreadyClockedIn}
                                  requiredMark={false}
                                  readOnly={true}
                                />
                            </div>
                        </div>
                        <div className={'col-2'}>
                            <div className="mb-4 input-field-main">
                                <Field
                                  className="form-control mk-wi"
                                  name="hoursWorked"
                                  label="Total Work Hours/Mins"
                                  type="text"
                                  readOnly={true}
                                  component={CustomInputField}
                                />
                            </div>
                        </div>
                        <div className={'col-2 d-flex align-items-center'}>
                            <div className="mb-4 input-field-main d-flex align-items-center">
                                {initialValues.hoursWorked <= 0 && <p className="text-danger">Work not completed yet</p>}
                            </div>
                        </div>
                        {/*<div className={'col-6'}>
                            <div className="mb-4 input-field-main">
                                <MultiSelectDropdown
                                    dpOptions={dayFrequenciesArray}
                                    getSelectedOptions={getSelectedOptions}
                                    selectedLanguages={selectedOptions}
                                    label="Frequency"
                                />
                            </div>
                        </div>*/}
                        <div className={'col-6'}>
                            <div className="mb-4 input-field-main">
                                <Field
                                    className="form-control mk-wi"
                                    label="Client"
                                    name="patientId"
                                    component={CustomDropDownField}
                                    // requiredMark={!alreadyClockedIn}
                                    requiredMark={true}
                                    // validate={alreadyClockedIn ? undefined : required}
                                    // readOnly={alreadyClockedIn}
                                    readOnly={true}
                                >
                                    <option value="">Please Select</option>
                                    {patientsArray?.map((res, index)=>{
                                        return <option key={index} value={res.id}>{`${res.firstName} ${res?.middleName || ''} ${res.lastName}`}</option>
                                    })}
                                </Field>
                            </div>
                        </div>
                        <div className={'col-6'}>
                            <div className="mb-4 input-field-main">
                                <Field
                                    className="form-control mk-wi"
                                    label="Nurse"
                                    name="nurseId"
                                    component={CustomDropDownField}
                                    // requiredMark={!alreadyClockedIn}
                                    requiredMark={true}
                                    // validate={alreadyClockedIn ? undefined : required}
                                    // readOnly={alreadyClockedIn}
                                    readOnly={disableNurseSelection}
                                >
                                    <option value="" className="text-danger">Not Assigned</option>
                                    {nursesArray?.map((res, index)=>{
                                        return <option key={index} value={res.id}>{`${res.firstName} ${res?.middleName || ''} ${res.lastName}`}</option>
                                    })}
                                </Field>
                            </div>
                        </div>
                        <div className={'col-12'}>
                            <div className="mb-4 input-field-main">
                                <Field
                                    className="form-control mk-wi"
                                    label="Service"
                                    name="serviceRequestTypeId"
                                    component={CustomDropDownField}
                                    // requiredMark={!alreadyClockedIn}
                                    requiredMark={true}
                                    // readOnly={alreadyClockedIn}
                                    readOnly={true}
                                >
                                    <option value="">Please Select</option>
                                    {servicesRequestTypesArray?.map((res, index)=>{
                                        return <option key={index} value={res.id}>{res.name}</option>
                                    })}
                                </Field>
                            </div>
                        </div>
                    </div>
                </Stack>
                {isEditable && <div
                  className="d-flex justify-content-center"
                  style={{marginTop: "40px"}}>
                    <PrimaryButton
                      type="submit"
                      className="btn-view-repo gap-2 px-5 py-2"
                      text={"Save"}
                      onClick={() => undefined}
                    />
                </div>}
            </form>
        </>
    )
};

const mapStateToProps = (state, ownProps) => {
// console.log('test- ownProps', ownProps)
    const { auth, transaction } = state;
    // const data = state.client?.taskDetails?.data;
    const data = ownProps?.taskDetails;
    // const walletDetails = transaction?.agencyWalletDetails || null;

    return {
        isAdmin: auth?.authData?.isAdmin || false,
        agencyId: auth?.authData?.agencyId || auth.agencyId,
        // isFreelanceNurse: data?.assignedNurseIsFreelance || false,
        // walletDetails,
        initialValues: {
            startDate: data?.taskDate || "", // taskDateFormatUs
            endDate: data?.taskDate || "",
            // expectedTimings: data?.expectedTimings || "",
            scheduledStartTime: data?.scheduledStartTime || "",
            // scheduledStartTimeDate: dateHelperFunctions.convertTimeStringToDate(data.scheduledStartTime) || "",
            scheduledEndTime: data?.scheduledEndTime || "",
            // scheduledEndTimeDate: dateHelperFunctions.convertTimeStringToDate(data.scheduledEndTime) || "",
            scheduledTimingsAmPm: data?.scheduledTimingsAmPm || "",
            clockIn: data?.clockIn || "",
            clockOut: data?.clockOut || "",
            patientId: data?.patientId?.toString() || "",
            nurseId: data?.assignedNurseId?.toString() || "",
            serviceRequestTypeId: data?.serviceRequestTypeId?.toString() || "",
            hoursWorked: data?.totalHoursWorked?.toString() || "",
            scheduledHours: data?.scheduledHours?.toString() || "",
            // frequencyIds: data?.frequencies.map(f => f.id),
            // scheduledHoursPerDay: data?.scheduledHoursPerDay?.toString(),
            taskId: data?.id || 0,
        },
    }
}

export default connect(mapStateToProps)(
    reduxForm({form: "details", enableReinitialize: true})(Details)
);
