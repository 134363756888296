import {useNavigate} from "react-router-dom";
import React from "react";

const DashboardTabs = ({currentKey}) => {
  const navigate = useNavigate();

  const handleTabChange = (tabKey) => {
    if (tabKey === 'nurses') {
      // navigate('/admin/dashboard');
      navigate('/admin/list/nurses');
    }
    if (tabKey === 'agencies') {
      // navigate('/admin/agencies');
      navigate('/admin/list/agencies');
    }
  };

// console.log('test- currentKey', currentKey);
  return <ul className="admin-tabs nav nav-tabs" role="tablist">
    {/*<li className="nav-item" role="presentation">
     <button type="button" id="controlled-tabs-tab-nurses" role="tab" data-rr-ui-event-key="nurses" aria-controls="controlled-tabs-tabpane-nurses" aria-selected="true"
     onClick={() => handleClick("nurses")}
     className="nav-link active">Nurses
     </button>
     </li>
     <li className="nav-item" role="presentation">
     <button type="button" id="controlled-tabs-tab-agencies" role="tab" data-rr-ui-event-key="agencies" aria-controls="controlled-tabs-tabpane-agencies"
     onClick={() => handleClick("agencies")}
     aria-selected="false" tabIndex="-1" className="nav-link">Agencies
     </button>
     </li>*/}
    <li className="nav-item" role="presentation">
      <button type="button" id="controlled-tabs-tab-nurses"
              // role="tab" aria-selected={currentKey === 'nurses'}
              onClick={() => handleTabChange("nurses")}
              className={`nav-link${currentKey === 'nurses' ? ' active' : ''}`}>Nurses
      </button>
    </li>
    <li className="nav-item" role="presentation">
      <button type="button" id="controlled-tabs-tab-agencies"
              // role="tab" aria-selected={currentKey === 'agencies'}
              onClick={() => handleTabChange("agencies")}
              className={`nav-link${currentKey === 'agencies' ? ' active' : ''}`}>Agencies
      </button>
    </li>
  </ul>;
};

export default DashboardTabs;
