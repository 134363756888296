import * as commonServices from "./commonServices.js";
import * as transactionReducer from '../Agency/components/Dashboard/agecnyTransaction/agencyActionTypes.js'
import getApiUrl from "../helpers/apiURLs.js";
import api from "../helpers/interceptor";
import { toastr } from "react-redux-toastr";
import {defaultErrorMessage} from "../appConstants";


// Get Agency Transaction
export const getPayoutHistoryAction = (apiRoute, queryParams = {}) => {
  return async (dispatch) => {
    dispatch(commonServices.setLoader(true));
    let url = '';

    switch (apiRoute) {
      case 'agencyTransaction':
        // base URL
        url = getApiUrl('transaction', 'agencyTransaction');

        // Construct an array for params
        let urlParams = [];
        if (queryParams.searchTerm && queryParams.searchTerm.length > 0) {
          urlParams.push(`searchText=${queryParams.searchTerm}`);
        }
        if (queryParams.fromDate && queryParams.fromDate.length > 0 && queryParams.toDate.length > 0) {
          urlParams.push(`fromDate=${queryParams.fromDate}`);
          urlParams.push(`toDate=${queryParams.toDate}`);
        }
        if (queryParams.currentPage && queryParams.currentPage > 0) {
          urlParams.push(`page=${queryParams.currentPage}`)
        }

        // Append params to url
        if (urlParams.length > 0) {
          url += '?' + urlParams.join('&');
        }
        break;
      default:
        break;
    }
    try {
      const response = await api.get(url);
      switch (apiRoute) {
        case 'agencyTransaction':
          dispatch({ type: transactionReducer.AGENCY_TRANSACTION_LIST, payload: response.data })
          break;
        default:
          break;
      }
      dispatch(commonServices.setLoader(false));
      return response;
    } catch (err) {
      dispatch({ type: transactionReducer.AGENCY_TRANSACTION_LIST, payload: {} })
      dispatch(commonServices.setLoader(false));
      toastr.error(defaultErrorMessage, '');
    }
  };
}

// Get Subscription Transaction
export const getSubscriptionHistoryAction = (apiRoute, queryParams = {}) => {
  return async (dispatch) => {
    dispatch(commonServices.setLoader(true));
    let url = '';
    switch (apiRoute) {
      case 'subScriptionHistory':
        // base URL
        url = getApiUrl('transaction', 'subScriptionHistory');
        // Construct an array for params
        let urlParams = [];
        if (queryParams.searchTerm) {
          urlParams.push(`searchText=${queryParams.searchTerm}`);
        }
        if (queryParams.fromDate && queryParams.toDate) {
          urlParams.push(`fromDate=${queryParams.fromDate}`);
          urlParams.push(`toDate=${queryParams.toDate}`);
        }
        if(queryParams.currentPage){
          urlParams.push(`page=${queryParams.currentPage}`)
        }
        // Append params to url
        if (urlParams.length) {
          url += '?' + urlParams.join('&');
        }
        break;
      default:
        break;
    }
    try {
      const response = await api.get(url);
      switch (apiRoute) {
        case 'subScriptionHistory':
          dispatch({ type: transactionReducer.SUBSCRIPTION_HISTORY_LIST, payload: response.data })
          break;
        default:
          break;
      }
      dispatch(commonServices.setLoader(false));
      return response;
    } catch (err) {
      dispatch({ type: transactionReducer.SUBSCRIPTION_HISTORY_LIST, payload: [] })
      dispatch(commonServices.setLoader(false));
      toastr.error(defaultErrorMessage, '');
    }
  };
}

// Get Subscription Transaction
export const getSubscriptionPlanAction = (apiRoute, quaryParams = {}) => {
  return async (dispatch) => {
    dispatch(commonServices.setLoader(true));
    let url = '';

    switch (apiRoute) {
      case 'subscriptionPlan':
        // base URL
        url = getApiUrl('transaction', 'subscriptionPlan');

        // if quaryParams isn't empty, append it
        if (quaryParams) {
          url += `?`;
        }
        break;
      default:
        break;
    }
    try {
      const response = await api.get(url);
      switch (apiRoute) {
        case 'subscriptionPlan':
          dispatch({ type: transactionReducer.SUBSCRIPTION_PLAN_LIST, payload: response.data })
          break;
        default:
          break;
      }
      dispatch(commonServices.setLoader(false));
      return response;
    } catch (err) {
      dispatch({ type: transactionReducer.SUBSCRIPTION_PLAN_LIST, payload: [] })
      dispatch(commonServices.setLoader(false));
      toastr.error(defaultErrorMessage, '');
    }
  };
}

// UpgradeSubscriptionPlanAction Transaction
export const upgradeSubscriptionPlanAction = (apiRoute, id, payload) => {
  return async (dispatch) => {
    dispatch(commonServices.setLoader(true));
    let url = '';

    switch (apiRoute) {
      case 'upgradSubscriptionPlan':
        // base URL
        url = getApiUrl('transaction', 'upgradSubscriptionPlan');
        // if quaryParams isn't empty, append it
        if (id) {
          url += `/${id}`;
        }
        break;
      default:
        break;
    }
    try {
      const response = await api.put(url, payload);
      switch (apiRoute) {
        case 'upgradSubscriptionPlan':
          dispatch({ type: transactionReducer.UPGRADE_SUBSCRIPTION_PLAN_LIST, payload: response.data })
          break;
        default:
          break;
      }
      dispatch(commonServices.setLoader(false));
      return response;
    } catch (err) {
      dispatch({ type: transactionReducer.UPGRADE_SUBSCRIPTION_PLAN_LIST, payload: [] })
      dispatch(commonServices.setLoader(false));
      toastr.error(defaultErrorMessage, '');
    }
  };
}

export const paypalPaymentOrderPlanAction = (apiRoute, payload) => {
  return async (dispatch) => {
    console.log("PAYPAL_PAYMENT_ORDER 12345", payload);
    dispatch(commonServices.setLoader(true));
    let url = '';

    switch (apiRoute) {
      case 'paypalPaymentOrder':
        // base URL
        url = getApiUrl('transaction', 'paypalPaymentOrder');
        break;
      default:
        break;
    }
    try {
      const response = await api.post(url, payload);
      switch (apiRoute) {
        case 'paypalPaymentOrder':
          dispatch({ type: transactionReducer.PAYPAL_PAYMENT_ORDER, payload: response.data })
          break;
        default:
          break;
      }
      dispatch(commonServices.setLoader(false));
      return response;
    } catch (err) {
      dispatch({ type: transactionReducer.PAYPAL_PAYMENT_ORDER, payload: {} })
      dispatch(commonServices.setLoader(false));
      toastr.error(defaultErrorMessage, '');
    }
  };
}

export const addFundAction = (apiRoute, payload) => {
  return async (dispatch) => {
    dispatch(commonServices.setLoader(true));
    let url = '';

    switch (apiRoute) {
      case 'addFund':
        // base URL
        url = getApiUrl('transaction', 'requestAddFunds');
        break;
      default:
        break;
    }
    try {
      const response = await api.post(url, payload);
      switch (apiRoute) {
        case 'addFund':
          dispatch({ type: transactionReducer.ADD_FUND, payload: response.data })
          break;
        default:
          break;
      }
      dispatch(commonServices.setLoader(false));
      // toastr.success('Fund added successfully', '');
      return response;
    } catch (err) {
      dispatch({ type: transactionReducer.ADD_FUND, payload: {} })
      dispatch(commonServices.setLoader(false));
      toastr.error(defaultErrorMessage, '');
    }
  };
}

export const addFundTempDataAction = (apiRoute) => {
  return async (dispatch) => {
    dispatch(commonServices.setLoader(true));
    let url = '';

    switch (apiRoute) {
      case 'addFundsTempData':
        // base URL
        url = getApiUrl('transaction', 'addFundsTempOrderData');
        break;
      default:
        break;
    }
    try {
      const response = await api.get(url, false);
      switch (apiRoute) {
        case 'addFundsTempData':
          dispatch({ type: transactionReducer.ADD_FUND_TEMP_DATA, payload: response.data })
          break;
        default:
          break;
      }
      dispatch(commonServices.setLoader(false));
      // toastr.success('Fund added successfully', '');
      return response;
    } catch (err) {
      dispatch({ type: transactionReducer.ADD_FUND_TEMP_DATA, payload: {} })
      dispatch(commonServices.setLoader(false));
      toastr.error(defaultErrorMessage, '');
    }
  };
}

export const addFundPaymentOrderAction = (apiRoute, payload) => {
  return async (dispatch) => {
    dispatch(commonServices.setLoader(true));
    let url = '';

    switch (apiRoute) {
      case 'addFundPaymentOrder':
        // base URL
        url = getApiUrl('transaction', 'captureAddFundsRequest');
        break;
      default:
        break;
    }
    try {
      const response = await api.post(url, payload);
      switch (apiRoute) {
        case 'addFundPaymentOrder':
          dispatch({ type: transactionReducer.ADD_FUND_PAYMENT_ORDER, payload: response.data })
          break;
        default:
          break;
      }
      dispatch(commonServices.setLoader(false));
      toastr.success('Payment order successfully', '');
      return response;
    } catch (err) {
      dispatch({ type: transactionReducer.ADD_FUND_PAYMENT_ORDER, payload: {} })
      dispatch(commonServices.setLoader(false));
      toastr.error(defaultErrorMessage, '');
    }
  };
}

export const getTransactionHistory = (apiRoute, queryParams = {}) => {
  return async (dispatch) => {
    dispatch(commonServices.setLoader(true));
    let url = '';

    switch (apiRoute) {
      case 'agencyTransaction':
        // base URL
        url = getApiUrl('transaction', 'transactionHistory');

        // // Construct an array for params
        // let urlParams = [];
        // if (queryParams.searchTerm) {
        //   urlParams.push(`searchText=${queryParams.searchTerm}`);
        // }
        // if (queryParams.fromDate && queryParams.toDate) {
        //   urlParams.push(`fromDate=${queryParams.fromDate}`);
        //   urlParams.push(`toDate=${queryParams.toDate}`);
        // }
        // if(queryParams.currentPage){
        //   urlParams.push(`page=${queryParams.currentPage}`)
        // }

        // // Append params to url
        // if (urlParams.length) {
        //   url += '?' + urlParams.join('&');
        // }
        break;
      default:
        break;
    }
    try {
      const response = await api.get(url);
      switch (apiRoute) {
        case 'agencyTransaction':
          dispatch({ type: transactionReducer.AGENCY_TRANSACTION_LIST, payload: response.data })
          break;
        default:
          break;
      }
      dispatch(commonServices.setLoader(false));
      return response;
    } catch (err) {
      dispatch({ type: transactionReducer.AGENCY_TRANSACTION_LIST, payload: {} })
      dispatch(commonServices.setLoader(false));
      toastr.error(defaultErrorMessage, '');
    }
  };
}

// Get Agency Wallet Details
export const getAgencyWalletDetailsAction = (apiRoute, walletId, queryParams = {}) => {
  return async (dispatch) => {
    dispatch(commonServices.setLoader(true));
    let url = '';

    let response = null;
    try {
      switch (apiRoute) {
        case 'agencyWalletDetails':
          // base URL
          url = getApiUrl('transaction', 'agencyWalletDetails');
          if (walletId) {
            url += `/${walletId}`;
          }
          response = await api.get(url);
          dispatch({ type: transactionReducer.GET_AGENCY_WALLET_AMOUNT, payload: response.data })
          break;
        default:
          break;
      }

      dispatch(commonServices.setLoader(false));
      return response;
    } catch (err) {
      dispatch({ type: transactionReducer.GET_AGENCY_WALLET_AMOUNT, payload: {} })
      dispatch(commonServices.setLoader(false));
      toastr.error(defaultErrorMessage, '');
    }
  };
}
