import React, {useEffect, useState} from "react";
import {change, Field, reduxForm, reset} from "redux-form";
import CustomInputField from "../../../../../../sharedComponents/reduxfields/customInputField";
import CustomDropDownField from "../../../../../../sharedComponents/reduxfields/customDropDown";
import { connect, useDispatch, useSelector } from "react-redux";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import { emailValidation } from "../../../../../../helpers/validation";
import {
  getBillingInfoAddress, getCityList,
  postClientProfileBillingInfoAddress,
} from "../../../../../../services/client";
import PrimaryButton from "../../../../../../sharedComponents/buttons/PrimaryButton";
import { HttpsAction } from "../../../../../../commonApiFunction/httpsAction";
import {useQueryClient} from "@tanstack/react-query";
import stringHelperFunctions from "../../../../../../helpers/string.helpers";
import CustomDropDownNewField from "../../../../../../sharedComponents/reduxfields/customDropDownNew";
import numberHelperFunctions from "../../../../../../helpers/number.helpers";
import * as clientActions from "../../../clientActionType";
import {resetForm} from "react-formio/lib/modules/form/actions";
import useCustomNav from "../../../../../../utils/hooks/useCustomNav";

const BillingAddress = ({ handleSubmit, initialValues }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { clientId, id } = useParams();
  const { clientId } = useOutletContext();

  const { goTo: gotoContactInfoPage } = useCustomNav('/agency/clients/edit/profile/contact-information');

  const billingInfoAddress = useSelector((state) => state.client?.billingInfoAddress);
  // const cityList = useSelector((state) => state.client?.cityList?.data);

  const statesArray = queryClient.getQueryData(['list-provinces-states', 233]);

  const [cityList, setCityList] = useState([]);
  const [cityLoaded, setCityLoaded] = useState(false);

  const handleChangeState = ({ value }) => {
    // dispatch(
    //   HttpsAction({
    //     apiSection: "client",
    //     apiName: "city",
    //     reducerName: "CLIENT",
    //     reduxKeyName: "cityList",
    //     actionType: "GET_CITY",
    //     queryParameter: `/${value}`,
    //   })
    // );
    /// IIFE
    (async () => {
      const response = await dispatch(getCityList(value));
      setCityList(response?.data);
    })();
  };

  const onSubmit = async (data) => {
    const { name, addressLine1, cityId, zipCode, billingEmail, provinceOrStateId } = data;
    const res = await dispatch(
      postClientProfileBillingInfoAddress(
        {
          // clientId,
          name: stringHelperFunctions.nullIfEmpty(name),
          addressLine1: stringHelperFunctions.nullIfEmpty(addressLine1),
          provinceOrStateId: numberHelperFunctions.nullIfEmpty(provinceOrStateId),
          cityId: numberHelperFunctions.nullIfEmpty(cityId),
          postalOrZipCode: stringHelperFunctions.nullIfEmpty(zipCode),
          billingEmail: stringHelperFunctions.nullIfEmpty(billingEmail),
        },
        clientId
      )
    );

    if (res?.success)
      // navigate(`/agency/clients/${clientId}/${id}/contactInformation`);
      gotoContactInfoPage();
  };

  useEffect(() => {
    dispatch(getBillingInfoAddress(clientId));
    return () => {
      dispatch({
        type: clientActions.GET_BILLING_INFO_ADDRESSES,
        payload: undefined,
      });
      dispatch(reset('billingAddressForm'));
    };
  }, []);

  // useEffect(() => {
  //   (async () => {
  //     if (billingInfoAddress?.patientCountryId) {
  //       await dispatch(
  //         HttpsAction({
  //           apiSection: "client",
  //           apiName: "state",
  //           reducerName: "CLIENT",
  //           reduxKeyName: "stateList",
  //           actionType: "GET_STATE",
  //           queryParameter: '/233', // billingInfoAddress?.patientCountryId,
  //           positiveCallBack: async () => {
  //             if (billingInfoAddress?.patientProvinceOrStateId)
  //               await dispatch(
  //                 HttpsAction({
  //                   apiSection: "client",
  //                   apiName: "city",
  //                   reducerName: "CLIENT",
  //                   reduxKeyName: "cityList",
  //                   actionType: "GET_CITY",
  //                   queryParameter: `/${billingInfoAddress?.patientProvinceOrStateId}`,
  //                 })
  //               );
  //           },
  //         })
  //       );
  //     }
  //   })();
  // }, [billingInfoAddress?.patientCountryId]);

  useEffect(() => {
    if (billingInfoAddress && clientId) {
      // bind city on load
      setCityList([]);
      if (billingInfoAddress.patientProvinceOrStateId != null && billingInfoAddress.patientProvinceOrStateId !== "0") {
        // IIFE
        (async () => {
          const response = await dispatch(getCityList(billingInfoAddress.patientProvinceOrStateId));
          setCityList(response?.data);
          dispatch(change("residentialAddressForm", 'cityId', billingInfoAddress.patientCityId));
        })();
      }
    }
  }, [billingInfoAddress && clientId]);

  // useEffect(() => {
  //   if (!cityLoaded) {
  //     (async () => {
  //       if (initialValues?.provinceOrStateId != null && initialValues?.provinceOrStateId.length > 0) {
  //         setCityList([]);
  //         const provinceOrStateId = initialValues?.provinceOrStateId?.toString();
  //         const cityId = initialValues?.cityId?.toString();
  //         if (provinceOrStateId != null && provinceOrStateId.length > 0 && provinceOrStateId !== '0') {
  //           // bind city on load
  //           if (cityId != null && cityId.length > 0) {
  //             const response = await dispatch(getCityList(provinceOrStateId));
  //             setCityList(response?.data);
  //             dispatch(change("billingAddressForm", 'cityId', initialValues?.cityId));
  //             setCityLoaded(true);
  //           }
  //         }
  //       }
  //     })();
  //   }
  // }, [initialValues, cityLoaded]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          {/*<div className="col-4">*/}
          {/*    <div className="mb-4 input-field-main">*/}
          {/*        <Field*/}
          {/*            className="form-control mk-wi"*/}
          {/*            name="name"*/}
          {/*            label="Name"*/}
          {/*            type="text"*/}
          {/*            component={CustomInputField}*/}
          {/*            placeholder={'Peace estate'}*/}
          {/*        />*/}
          {/*    </div>*/}
          {/*</div>*/}
          <div className="col-8">
            <div className="mb-4 input-field-main">
              <Field
                className="form-control mk-wi"
                name="addressLine1"
                label="Address"
                type="text"
                component={CustomInputField}
                placeholder="Billing address here"
              />
            </div>
          </div>
          <div className="col-4">
            <div className="mb-4 input-field-main">
              <Field
                className="form-control mk-wi"
                label="State"
                name="provinceOrStateId"
                component={CustomDropDownNewField}
                onChange={({ target }) => handleChangeState(target)}
                handleClear={() => {
                  dispatch(change("billingAddressForm", 'cityId', ''));
                  setCityList([]);
                  return dispatch(change("billingAddressForm", 'provinceOrStateId', ''));
                }}
              >
                <option value="">Select State</option>
                {(statesArray || []).map((res, index) => (
                  <option key={index} value={res.id}>
                    {res.name}
                  </option>
                ))}
              </Field>
            </div>
          </div>
          <div className="col-4">
            <div className="mb-4 input-field-main">
              <Field
                className="form-control mk-wi"
                label="City"
                name="cityId"
                component={CustomDropDownNewField}
              >
                <option value="">Select City</option>
                {(cityList || []).map((res, index) => (
                  <option key={index} value={res.id}>
                    {res.name}
                  </option>
                ))}
              </Field>
            </div>
          </div>
          <div className="col-4">
            <div className="mb-4 input-field-main">
              <Field
                className="form-control mk-wi"
                name="zipCode"
                label="Zip code"
                type="text"
                component={CustomInputField}
                placeholder={"Enter code"}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="mb-4 input-field-main">
              <Field
                className="form-control mk-wi"
                name="billingEmail"
                label="Email address"
                type="text"
                component={CustomInputField}
                // validate={emailValidation}
                placeholder={"Janedoe@gmail.com"}
              />
            </div>
          </div>
          <div
            className="d-flex justify-content-center"
            style={{ marginTop: "40px" }}
          >
            <PrimaryButton
              type="submit"
              className="btn-view-repo gap-2 px-5 py-2"
              text={"Save"}
              onClick={() => undefined}
            />
          </div>
        </div>
      </form>
    </>
  );
};

const mapStateToProps = (state) => {
  const { client: { billingInfoAddress = null } } = state;

  return {
    initialValues: {
      addressLine1: billingInfoAddress?.patientAddressLine1 === 'Nil' ? '' : billingInfoAddress?.patientAddressLine1,
      provinceOrStateId: billingInfoAddress?.patientProvinceOrStateId?.toString() || "",
      cityId: billingInfoAddress?.patientCityId?.toString() || "",
      zipCode: billingInfoAddress?.patientPostalOrZipCode || "",
      latitude: billingInfoAddress?.patientLatitude?.toString() || "",
      longitude: billingInfoAddress?.patientLongitude?.toString() || "",
      billingEmail: billingInfoAddress?.billingEmail || "",
    },
  };
};

export default connect(mapStateToProps)(
  reduxForm({ form: "billingAddressForm", enableReinitialize: true })(BillingAddress)
);
