import React, {useEffect, useState} from 'react';
import {Autocomplete, Dialog, Stack} from "@mui/material";
import TextField from "@mui/material/TextField";
import Calendar from "../../../sharedComponents/calendar";
import {connect, useDispatch, useSelector} from "react-redux";
import {HttpsAction} from "../../../commonApiFunction/httpsAction";
import {INDICATOR_COLOR, MONTHS} from "../Client/ClientProfile/Calender";
import classes from "../TaskDetails/_assets/css/calendar.module.css";
import dateHelperFunctions from "../../../helpers/date.helpers";
import TaskDetails from "../TaskDetails";
import {useQueryClient} from "@tanstack/react-query";

const MoreIcon = (color = '#9957B5') =>
  <svg style={{cursor: 'pointer'}} width="14" height="14" viewBox="0 0 14 14" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.70956 10.0392C6.99927 10.0392 7.27712 10.1543 7.48198 10.3591C7.68684 10.564 7.80193 10.8418 7.80193 11.1315C7.80193 11.4212 7.68684 11.6991 7.48198 11.904C7.27712 12.1088 6.99927 12.2239 6.70956 12.2239C6.41984 12.2239 6.14199 12.1088 5.93714 11.904C5.73228 11.6991 5.61719 11.4212 5.61719 11.1315C5.61719 10.8418 5.73228 10.564 5.93714 10.3591C6.14199 10.1543 6.41984 10.0392 6.70956 10.0392ZM6.70956 6.21587C6.99927 6.21587 7.27712 6.33096 7.48198 6.53582C7.68684 6.74068 7.80193 7.01853 7.80193 7.30824C7.80193 7.59796 7.68684 7.8758 7.48198 8.08066C7.27712 8.28552 6.99927 8.40061 6.70956 8.40061C6.41984 8.40061 6.14199 8.28552 5.93714 8.08066C5.73228 7.8758 5.61719 7.59796 5.61719 7.30824C5.61719 7.01853 5.73228 6.74068 5.93714 6.53582C6.14199 6.33096 6.41984 6.21587 6.70956 6.21587ZM6.70956 2.39258C6.99927 2.39258 7.27712 2.50767 7.48198 2.71253C7.68684 2.91738 7.80193 3.19523 7.80193 3.48495C7.80193 3.77466 7.68684 4.05251 7.48198 4.25737C7.27712 4.46223 6.99927 4.57732 6.70956 4.57732C6.41984 4.57732 6.14199 4.46223 5.93714 4.25737C5.73228 4.05251 5.61719 3.77466 5.61719 3.48495C5.61719 3.19523 5.73228 2.91738 5.93714 2.71253C6.14199 2.50767 6.41984 2.39258 6.70956 2.39258Z"
      fill={color}/>
  </svg>;

const DashboardCalendar = ({ taskDetails, agencyId }) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const preferredCaregiver = useSelector(state => state.common?.preferredCaregiver?.data);
  const nursesArray = queryClient.getQueryData(['list-internal-nurses', agencyId]);
  const eventList = useSelector(state => state.client?.calendarEvent?.data);

  const [allEventList, setAllEventList] = useState([]);
  const [selectedNurseId, setSelectedNurseId] = useState();
  const [showTaskDetails, setShowTaskDetails] = useState(false);
  const [serviceDetails, setServiceDetails] = useState({modal: false, id: null});

  const handleSelectNurse = (_, data) => {
    const toolbarTitle = document.getElementsByClassName(`fc-toolbar-title`)
    const title = toolbarTitle[0].outerText.split(" ");
    if (data?.value) {
      setSelectedNurseId(data.value)
      dispatch(HttpsAction({
        apiSection: 'client',
        apiName: 'serviceRequestTasks',
        queryParameter: `?nurseId=${data.value}&calYear=${title[1]}&calMonth=${MONTHS.find(v => v.month === title[0]).id}&doPaging=false`,
        reducerName: 'CLIENT',
        actionType: 'GET_CALENDAR_SERVICE_REQUEST_TASKS',
        reduxKeyName: 'calendarEvent'
      }))
    } else {
      const dispatchType = `ADD_CLIENT_REMOVE_EVENTS`;
      dispatch({
        type: dispatchType,
        payload: {data: []},
        name: 'calendarEvent',
        meta: {type: 'REMOVE_EVENTS'},
      });
    }
  };

  const handleMonthChange = () => {
    const toolbarTitle = document.getElementsByClassName(`fc-toolbar-title`)
    const title = toolbarTitle[0].outerText.split(" ");
    if (selectedNurseId)
      dispatch(HttpsAction({
        apiSection: 'client',
        apiName: 'serviceRequestTasks',
        queryParameter: `?nurseId=${selectedNurseId}&calYear=${title[1]}&calMonth=${MONTHS.find(v => v.month === title[0]).id}&doPaging=false`,
        reducerName: 'CLIENT',
        actionType: 'GET_CALENDAR_SERVICE_REQUEST_TASKS',
        reduxKeyName: 'calendarEvent'
      }))
  };

  const handleServiceDetailsModal = (id) => {
    if (id) {
      dispatch(HttpsAction({
        apiSection: 'serviceRequests',
        apiName: 'taskDetails',
        queryParameter: `/${id}`,
        actionType: `GET_TASK_DETAILS`,
        reduxKeyName: 'taskDetails',
        reducerName: 'SERVICE_REQUEST',
      }));
    }
    // setServiceDetails(prevState => ({...prevState, modal: !prevState.modal}))
  };

  const renderEventContent = (eventInfo) => {
    const event = eventInfo.event.extendedProps;
    const color = INDICATOR_COLOR.find((v) => v.id === event.taskStatusId).bgColor;
    //${event.assignedNurseId != null ? ' border-end' : ''}
    return (
      <>
        <Stack className={`${classes.customEvent}`} onClick={() => handleServiceDetailsModal(event?.taskId)}>
          <Stack className={`${classes.colorLine}`} sx={{backgroundColor: color}} style={{minWidth: '3px'}}/>
          <Stack width={'100%'} paddingY={'10px'} paddingX={event.assignedNurseId != null ? '5px' : '10px'}>
            <Stack className={classes.moreIcon}>
              {MoreIcon(color)}
            </Stack>
            <Stack gap={event.clockIn != null ? '0' : '3px'} marginTop={event.clockIn != null ? '-2px' : '0'}>
              {event.clockIn != null && <Stack className={classes.time}>
                {`${dateHelperFunctions.convertTo12HourFormat(event.clockIn)} - ${event.clockOut != null ? dateHelperFunctions.convertTo12HourFormat(event.clockOut) : 'N/A'}`}
              </Stack>}
              <Stack className={classes.eventName}>
                {event.serviceRequestType}
              </Stack>
              <Stack className={classes.name} title={`Client name = ${event.patientFirstLastName}`}>
                {event.patientFirstLastName}
              </Stack>
              {event.assignedNurseId != null &&
                <Stack className={classes.name} title={`Nurse name = ${event.assignedNurseFirstLastName}`}>
                  {event.assignedNurseFirstLastName}
                </Stack>}
            </Stack>
          </Stack>
        </Stack>
      </>
    );
  };

  useEffect(() => {
    if (eventList) {
      setAllEventList(() => eventList);
    }
  }, [eventList]);

  useEffect(() => {
    if (taskDetails != null && JSON.stringify(taskDetails) !== '{}') {
      setShowTaskDetails(true);
    }
  }, [taskDetails]);

  useEffect(() => {
    dispatch(HttpsAction({
      reducerName: 'CLIENT',
      actionType: 'GET_CALENDAR_SERVICE_REQUEST_TASKS',
      reduxKeyName: 'calendarEvent',
      removeData: true,
      actionTypeIsDispatchType: false,
    }));

    // dispatch(HttpsAction({
    //   apiSection: 'client',
    //   apiName: 'preferredCaregiver',
    //   reducerName: 'COMMON',
    //   reduxKeyName: 'preferredCaregiver',
    //   actionType: 'GET_NURSE_LIST'
    // }));

    return () => {
      dispatch({
        type: 'ADD_CLIENT_REMOVE_EVENTS',
        payload: {data: []},
        name: 'calendarEvent',
        meta: {type: 'REMOVE_EVENTS'},
      });
    };
  }, []);

  return (
    <>
      <div className="card card-n rounded-lg py-4 px-4  border-0">
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          className={'mb-4'}
          options={(preferredCaregiver || []).map(v => ({label: v?.nurseFullName || "", value: v?.id || ""})) || []}
          renderInput={(params) => <TextField {...params} label="Select a Nurse"/>}
          onChange={handleSelectNurse}
          sx={{maxWidth: '300px'}}
        />

        <Calendar
          handleMonth={handleMonthChange}
          events={allEventList?.map((v, i) => ({...v, date: v?.taskDate || new Date()}))}
          eventContent={renderEventContent}
        />
      </div>

      <Dialog
        open={showTaskDetails}
        onClose={() => setShowTaskDetails(false)}
        maxWidth={"lg"}
        aria-labelledby={"form-dialog-title"}>
        <div className={'p-4'}>
          <TaskDetails showingDataFor="clientCalendar" taskDetails={taskDetails} onClose={() => setShowTaskDetails(false)}/>
        </div>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => {
  const { auth, serviceRequest } = state;

  return {
    agencyId: auth?.authData?.agencyId || auth.agencyId,
    taskDetails: serviceRequest?.taskDetails?.data || {},
  };
};

export default connect(mapStateToProps)(DashboardCalendar);

