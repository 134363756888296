import React, {useEffect, useState} from "react";
import {Field, reduxForm} from "redux-form";
import CustomDropDownField from "../../../../../sharedComponents/reduxfields/customDropDown";
import {required} from "../../../../../helpers/validation";
import MultiSelectDropdown from "../../../../../sharedComponents/multiSelect";
import {HttpsAction} from "../../../../../commonApiFunction/httpsAction";
import {connect, useDispatch, useSelector} from "react-redux";
import {getServiceRequestTasksList} from "../../../../../services/commonServices";

const AddTasks = ({handleSubmit, onClose, updateActivitiesList, serviceRequestTasksArray}) => {
    const dispatch = useDispatch();

    // const taskList = useSelector(state => state?.common?.serviceRequestTasksList || []);

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [taskSelectionValidation, setTaskSelectionValidation] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [filTaskList, setFilTaskList] = useState([]);
    // const [showDateRange, setShowDateRange] = useState(false);
    // const [showSingleDate, setShowSingleDate] = useState(true);

    const onSubmit = async (values) => {
        if (selectedOptions.length > 0) {
            // values.dayName = 'All';
            // if (showDateRange) {
            //     const extractedNames = values.modalFrequencyIds.map(id => {
            //         const matchingItem = frequencyDaysList.find(item => item.id === id);
            //         return matchingItem ? matchingItem.name : null;
            //     });
            //     values.dayName = extractedNames;
            // }
            // values.id = Math.random().toString(36).substring(2);
            // values.taskid = selectedOptions.map(res => res.value);
            // values.taskName = selectedOptions.map(res => res.label);
            // if (showSingleDate) {
            //     values.endDate = values.startDate;
            //     // values.modalFrequencyIds = frequencyDaysList.map(item => item.id);
            // }
            updateActivitiesList(selectedOptions.map(res => {
                const foundCat = filTaskList.find(item => item.id === res.value);
                return {
                    id: res.value,
                    name: res.label,
                    category: foundCat.taskCategoryName,
                    categoryId: foundCat.taskCategoryId,
                    isCompleted: false,
                    completionTime: null,
                    completionLatitude: null,
                    completionLongitude: null,
                };
            }));
            onClose();
        } else {
            setTaskSelectionValidation(true);
        }
    }

    const getSelectedOptions = (selectedOptions) => {
        if (selectedOptions.length > 0) {
            setSelectedOptions(selectedOptions);
            setTaskSelectionValidation(false)
        } else {
            setTaskSelectionValidation(true)
        }
    }

    const filterTask = (e) => {
        let index = e.target.selectedIndex;
        let optionElement = e.target.childNodes[index]
        let optionattr = optionElement.getAttribute('value');
        const filterTask = serviceRequestTasksArray?.filter(item => {
            if (item.taskCategoryId === +optionattr) {
                return item;
            }
        })
        setFilTaskList(filterTask)
    }

    const setTaskCategory = () => {
        const uniqueMap = new Map();
        const uniqueArray = [];
        for (const item of serviceRequestTasksArray) {
            if (!uniqueMap.has(item['taskCategoryId'])) {
                uniqueMap.set(item['taskCategoryId'], true);
                uniqueArray.push(item);
            }
        }
        setCategoryList(uniqueArray);
    }

    useEffect(() => {
        // (async () => {
        //     await dispatch(HttpsAction({
        //         apiSection: 'serviceRequests',
        //         apiName: 'taskList',
        //         reducerName: 'CLIENT',
        //         reduxKeyName: 'careNotesTaskList',
        //         actionType: 'GET_CARE_NOTES_TASKS_LIST',
        //     }))
        // })()
        dispatch(getServiceRequestTasksList());
    }, [])

    useEffect(() => {
        if (serviceRequestTasksArray != null && serviceRequestTasksArray.length > 0) {
            setTaskCategory();
        }
    }, [serviceRequestTasksArray]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} style={{padding: '20px', height: '300px', width: '500px'}}>
                <div className="row">
                    <div className="col-12">
                        <div className="mb-4 input-field-main">
                            <label>Select Task Category <span className="mandatory-field">*</span></label>
                            <Field
                                className="form-control"
                                name="activityCategoryId"
                                component={CustomDropDownField}
                                validate={required}
                                requiredMark={true}
                                onChange={(e) => filterTask(e)}
                            >
                                <option value="">Please select</option>
                                {categoryList?.map((res, index) => {
                                    return <option key={index} value={res.taskCategoryId}>{res.taskCategoryName}</option>
                                })}
                            </Field>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="mb-4 input-field-main">
                            <MultiSelectDropdown
                                dpOptions={filTaskList}
                                getSelectedOptions={getSelectedOptions}
                                selectedOptions={selectedOptions}
                                required={true}
                                requiredMark={true}
                                label="Select Task"
                            />
                            {taskSelectionValidation && (
                                <span className="field-error">This field is required</span>)}
                        </div>
                    </div>
                </div>
                <div className="modal-footer justify-content-center pb-0 gap-2 mt-2">
                    <button type="button" className="btn btn-secondary" onClick={onClose}>
                        Close
                    </button>
                    <button type="submit" className="btn btn-primary">Add</button>
                </div>
            </form>
        </>
    )
};

const mapStateToProps = (state) => {
    const { auth, common } = state;

    return {
        isAdmin: auth?.authData?.isAdmin || false,
        agencyId: auth?.authData?.agencyId || auth.agencyId,
        serviceRequestTasksArray: common?.serviceRequestTasksList || [],
    };
};

export default connect(mapStateToProps)(
    reduxForm({form: "AddTasks", enableReinitialize: true})(AddTasks)
);
