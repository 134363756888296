import React from 'react';
import DashboardCard from "./DashboardCard";

const PatientsIcon = <svg
  width="40"
  height="40"
  viewBox="0 0 40 40"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <rect width="40" height="40" rx="5" fill="#D0E6F9"/>
  <path
    d="M28.2783 28.9996V26.9998C28.2783 25.939 27.8569 24.9216 27.1067 24.1715C26.3566 23.4214 25.3392 23 24.2783 23H16.2783C15.2175 23 14.2 23.4214 13.4499 24.1715C12.6997 24.9216 12.2783 25.939 12.2783 26.9998V28.9996"
    stroke="#1976D2"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
  <path
    d="M20.2793 18.9995C22.4884 18.9995 24.2793 17.2088 24.2793 14.9998C24.2793 12.7908 22.4884 11 20.2793 11C18.0702 11 16.2793 12.7908 16.2793 14.9998C16.2793 17.2088 18.0702 18.9995 20.2793 18.9995Z"
    stroke="#1976D2"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  />
</svg>;

const ServiceRequestIcon = <svg
  width="40"
  height="40"
  viewBox="0 0 40 40"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <rect width="40" height="40" rx="5" fill="#D0E6F9"/>
  <path
    d="M10 28V23H14V24H16V23H24V24H26V23H30V28H10ZM10 18C10 17.45 10.196 16.979 10.588 16.587C10.98 16.195 11.4507 15.9993 12 16H15V14C15 13.45 15.196 12.979 15.588 12.587C15.98 12.195 16.4507 11.9993 17 12H23C23.55 12 24.021 12.196 24.413 12.588C24.805 12.98 25.0007 13.4507 25 14V16H28C28.55 16 29.021 16.196 29.413 16.588C29.805 16.98 30.0007 17.4507 30 18V22H26V20H24V22H16V20H14V22H10V18ZM17 16H23V14H17V16Z"
    fill="#1976D2"
  />
</svg>;

const ActiveNursesIcon = <svg
  width="40"
  height="40"
  viewBox="0 0 40 40"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <rect width="40" height="40" rx="5" fill="#D0E6F9"/>
  <g clipPath="url(#clip0_721_52237)">
    <path
      d="M23.8337 22.8576L20.0002 26.6903L16.1667 22.8576C13.2942 22.9821 11 25.3326 11 28.2332C11 28.8535 11.2464 29.4484 11.685 29.887C12.1236 30.3256 12.7185 30.572 13.3388 30.572H26.6615C27.2818 30.572 27.8767 30.3256 28.3153 29.887C28.754 29.4484 29.0004 28.8535 29.0004 28.2332C29.0004 25.3326 26.7061 22.9821 23.8337 22.8576ZM20.0002 22.2147C21.3642 22.2147 22.6723 21.6729 23.6368 20.7084C24.6013 19.7439 25.1431 18.4357 25.1431 17.0717V12.6448C25.1435 12.3828 25.0638 12.127 24.9147 11.9116C24.7656 11.6962 24.5543 11.5314 24.309 11.4394L20.903 10.1637C20.3209 9.94543 19.6795 9.94543 19.0974 10.1637L15.6913 11.441C15.4463 11.5329 15.2352 11.6974 15.0861 11.9125C14.9371 12.1276 14.8572 12.3831 14.8572 12.6448V17.0717C14.8572 18.4357 15.3991 19.7439 16.3636 20.7084C17.3281 21.6729 18.6362 22.2147 20.0002 22.2147ZM18.393 12.8798C18.393 12.8265 18.4142 12.7754 18.4518 12.7378C18.4895 12.7001 18.5406 12.6789 18.5939 12.6789H19.4646V11.8082C19.4646 11.755 19.4858 11.7039 19.5234 11.6662C19.5611 11.6285 19.6122 11.6073 19.6655 11.6073H20.3349C20.3882 11.6073 20.4393 11.6285 20.4769 11.6662C20.5146 11.7039 20.5358 11.755 20.5358 11.8082V12.6789H21.4065C21.4597 12.6789 21.5108 12.7001 21.5485 12.7378C21.5862 12.7754 21.6074 12.8265 21.6074 12.8798V13.5492C21.6074 13.6025 21.5862 13.6536 21.5485 13.6913C21.5108 13.7289 21.4597 13.7501 21.4065 13.7501H20.5358V14.6208C20.5358 14.6741 20.5146 14.7252 20.4769 14.7629C20.4393 14.8005 20.3882 14.8217 20.3349 14.8217H19.6655C19.6122 14.8217 19.5611 14.8005 19.5234 14.7629C19.4858 14.7252 19.4646 14.6741 19.4646 14.6208V13.7501H18.5939C18.5406 13.7501 18.4895 13.7289 18.4518 13.6913C18.4142 13.6536 18.393 13.6025 18.393 13.5492V12.8798ZM16.7858 16.4289H23.2145V17.0717C23.2145 17.9242 22.8759 18.7418 22.2731 19.3446C21.6703 19.9474 20.8527 20.2861 20.0002 20.2861C19.1477 20.2861 18.3301 19.9474 17.7273 19.3446C17.1245 18.7418 16.7858 17.9242 16.7858 17.0717V16.4289Z"
      fill="#1976D2"
    />
  </g>
  <defs>
    <clipPath id="clip0_721_52237">
      <rect
        width="18.0004"
        height="20.5718"
        fill="white"
        transform="translate(11 10)"
      />
    </clipPath>
  </defs>
</svg>;

const AllCards = ({ agencyDashboardData }) => {
  return (
    <div id="canvas" className="row anylatics-data-box mb-3">
      <DashboardCard
        title={"TOTAL CLIENTS"}
        svg={PatientsIcon}
        number={agencyDashboardData.activePatientsCount}
        percentage={"25%"}
        link={'/agency/clients'}
      />
      <DashboardCard
        title={"SERVICE REQUEST"}
        svg={ServiceRequestIcon}
        number={agencyDashboardData.serviceRequestsCount}
        percentage={"25%"}
        link={'/agency/servicerequest/allrequest'}
      />
      <DashboardCard
        title={"ACTIVE NURSES"}
        svg={ActiveNursesIcon}
        number={agencyDashboardData.activeNursesCount}
        percentage={"25%"}
        // link={'/agency/nurses/nurselist'}
        link={'/agency/nurses/list/internal'}
      />
    </div>
  );
};

export default AllCards;
