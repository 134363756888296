import React, {useEffect, useRef, useState} from "react";
import {NavLink, useLocation, useNavigate, useOutletContext} from "react-router-dom";
import Table from "../../../sharedComponents/table/Table";
import classes from "./css/InitialAssessment.module.css"
import MoreOptionDropdown from "./MoreOptionDropdown";
import {connect, useDispatch, useSelector} from "react-redux";
import {getChartingData} from "../../../services/charting";
import Pagination from "../../../sharedComponents/pagination";
import {pageLimit} from "../../../appConstants";
import storageManager from "../../../helpers/storageManager";
import dateHelperFunctions from "../../../helpers/date.helpers";
import {toastr} from "react-redux-toastr";
import {getFormTemplatesList} from "../../../services/commonServices";
import {Stack} from "@mui/material";
import NewTableLayout from "../shared/NewTableLayout";
import {HttpsAction} from "../../../commonApiFunction/httpsAction";
import useCustomNav from "../../../utils/hooks/useCustomNav";

const InitialAssessment = ({/*toSearch, */}) => {
    const backButtonTextSuffix = 'Charting - Initial Assessments';
    // const backLink = '/agency/charting/initialassessment';
    const backLink = '/agency/charting/initial-assessments';

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { searchText, setPaginationTab } = useOutletContext();

    const { goTo: gotoTemplatesPage } = useCustomNav('/agency/charting/templates');
    const { goTo: gotoNurseChartingPage } = useCustomNav('/agency/nurses/edit/profile/charting');
    const { goTo: gotoPatientChartingPage } = useCustomNav('/agency/clients/edit/charting');

    const initialAssessmentData = useSelector(state => state.charting.initialAssessmentData);

    const chartingIdToSelect = location?.state != null && location?.state?.chartingId != null ? location.state.chartingId : 0;

    const transformedArray = (initialAssessmentData?.rows || [])?.map((item, index) => {
        const highlightRow = chartingIdToSelect === item.chartingId;
        let taskStatusName = item.taskStatusName;
        // if (item.taskStatusId < 3 && item.clockIn == null && offsetCurrentDateTime > scheduledEndDateTimeUtc) return 'Missed';
        if (item.isMissed) taskStatusName = 'Missed';

        return {
            assessmentTitle: <div className="position-relative1 p-0 m-0 d-flex flex-column gap-1">
                <span>{item?.assessmentTitle || 'N/A'}</span>
                {/*<div className="position-absolute bottom1-6p25 start-50 translate-middle cursor-pointer" onClick={() => {*/}
                <div onClick={() => {
                    // let url = null;
                    // // if ('abc' === 'abc')
                    // url = '/agency/charting/forms';
                    // if (url != null) {
                    //     navigate(url, {
                    //         state: {
                    //             templateId: item.formTemplateId,
                    //         },
                    //     });
                    // }
                    gotoTemplatesPage({templateId: item.formTemplateId,});
                }}><span className={`table-inner-link text-break lh-1`}>{item.chartingType}</span></div>
            </div>,
            // assignedNurse: item?.nurseFullName,
            assignedNurse: <span className={classes.assessment_title} onClick={(e) => {
                e.preventDefault();
                if (item.serviceRequestId != null)
                  // navigate(`/agency/clients/${item.patientId}/charting/0`);
                  //   navigate(`/agency/nurses/edit/${item?.nurseId}/charting`, {
                  //       state: {
                  //           showBackButton: true,
                  //           backButtonTextSuffix,
                  //           backLink,
                  //       }
                  //   });
                    gotoNurseChartingPage({
                        id: item?.nurseId,
                        showBackButton: true,
                        backButtonTextSuffix,
                        backLink,
                    });
                else {
                    e.target.parentElement.parentElement.querySelector('td:last-child').children[0].children[0].click();
                    toastr.error('Create a Service Request first');
                }
            }}>{item?.nurseFullName}</span>,
            // accessLevel: item?.accessLevel,
            // careRecipient: item?.patientFullName,
            careRecipient: <span className={classes.assessment_title} onClick={(e) => {
                e.preventDefault();
                if (item.serviceRequestId != null)
                  // navigate(`/agency/clients/${item.patientId}/charting/0`);
                  //   navigate(`/agency/clients/${item.patientId}/charting/0`, {
                  //       state: {
                  //           showBackButton: true,
                  //           backButtonTextSuffix,
                  //           backLink,
                  //       }
                  //   });
                    gotoPatientChartingPage({
                        id: item?.patientId,
                        showBackButton: true,
                        backButtonTextSuffix,
                        backLink,
                    });
                else {
                    // alert(e.target.parentElement.parentElement.querySelector('td:last-child').children[0].className)
                    e.target.parentElement.parentElement.querySelector('td:last-child').children[0].children[0].click();
                    toastr.error('Create a Service Request first');
                }
            }}>{item?.patientFullName}</span>,
            // duration: <><span className="d-block">27/05/23 - 27/05/23</span></>,
            // duration: <><span className="d-block">{item?.startDateEndDate || 'N/A'}</span></>,
            duration: <Stack>
                <Stack>{item.taskDateFormatUs}</Stack>
                <Stack>{item.expectedTimings}</Stack>
            </Stack>,
            // status: <div className={classes.state_main}>
            //     <div className={index % 2 ? classes.active_status_main : classes.inactive_status_main}><span
            //         className={index % 2 ? classes.active_dot : classes.inactive_dot}> </span> <span
            //         className={index % 2 ? classes.active_name : classes.inactive_name}> {index % 2 ? 'Active' : 'Inactive'} </span>
            //     </div>
            // </div>,
            // status: <><span className="d-block">{item?.serviceRequestStatusName || 'N/A'}</span></>,
            'status': <div onClick={() => {
                if (item.taskStatusId != null) {
                    let url = null;
                    if (!item.isMissed && item.taskStatusId === 2) url = `/agency/servicerequest/scheduledrequest`;
                    else if (item.taskStatusId === 3 && item.activityCompletionTime == null) url = `/agency/servicerequest/activerequest`;
                    else if (item.taskStatusId >= 3 && item.activityCompletionTime != null) url = `/agency/servicerequest/completeRequest`; // status-id is 4 or 5
                    else if (item.isMissed) url = `/agency/servicerequest/missedRequests`; // missed (no clock-in)
                    else if (!item.isMissed && item.activityCompletionTime == null) url = `/agency/servicerequest/incompleteVisits`; // in-complete
                    if (url != null) {
                        navigate(url, {
                            state: {
                                taskId: item.taskId,
                            },
                        });
                    }
                }
            }}>
                <span className="table-inner-link text-nowrap">{taskStatusName}</span>
            </div>,
            created: item?.serviceRequestCreationDate || 'N/A',
            expiry: item?.expirationDate == null ? 'N/A' : dateHelperFunctions.updateDateFormat(item?.expirationDate),
            highlightRow,
            menuIcon: <MoreOptionDropdown managingForServiceRequestType={'initialAssessment'} eachData={item}/>,
        }
    });

    const serviceHeaderData = [
        {
            title: "Assessment Title",
            dataIndex: "assessmentTitle",
            // width: '100px',
        },
        {
            title: "Assigned Nurse",
            dataIndex: "assignedNurse",
            width: '140px',
        },
        // {
        //     title: "Access Level",
        //     dataIndex: "accessLevel",
        // },
        {
            title: "Care Recipient",
            dataIndex: "careRecipient",
            width: '150px',
        },
        {
            title: "Duration",
            dataIndex: "duration",
            width: '130px',
        },
        {
            title: "Status",
            dataIndex: "status",
            width: '90px',
        },
        {
            title: "Created",
            dataIndex: "created",
            width: '90px',
        },
        {
            title: "Expiry",
            dataIndex: "expiry",
            width: '90px',
        },
        {
            title: "",
            dataIndex: "menuIcon",
            width: '35px',
        }
    ];

    // const getInitialAssessment = () => {
    //     dispatch(getChartingData({endPoint: 'initialAssessment'}));
    // };
    //
    // const handlePageChange = (page) => {
    //     // storageManager.saveClientListCurrentPage(page);
    //     return dispatch(getChartingData({endPoint: 'initialAssessment'}, page));
    // };

    const getData = (page, searchVal) => {
        dispatch(getChartingData({endPoint: 'initialAssessment'}, page, searchVal));
    };

    const loadMethod = (curPage, toSearch) => {
        const useDefault = curPage == null && toSearch == null;
        if (useDefault) getData(1);
        else {
            if (toSearch != null && toSearch.length > 0)
                getData(1, toSearch);
            else
                getData(curPage);
        }
    };

    useEffect(() => {
        if (initialAssessmentData != null && initialAssessmentData?.rows?.length != null) {
            setPaginationTab(initialAssessmentData);
        }
    }, [initialAssessmentData]);

    // useEffect(() => {
    //     if (toSearch != null && toSearch.length > 0) {
    //         dispatch(getChartingData({endPoint: 'initialAssessment'}, 1, toSearch));
    //     } else {
    //         dispatch(getChartingData({endPoint: 'initialAssessment'}, 1));
    //     }
    // }, [toSearch]);
    //
    // useEffect(() => {
    //     getInitialAssessment();
    // }, []);

    return (
        <>
            {/*<div className="app-main__inner service-request-inner">
                <div className="tab-content" id="myTabContent">
                    <Table headers={serviceHeaderData} rows={transformedArray} hasFixedWidth={true} tableClass="charting-table" />
                </div>
                <Pagination
                    currentPage={initialAssessmentData?.page}
                    totalRecords={initialAssessmentData?.total}
                    limit={initialAssessmentData?.pageSize}
                    onPageChange={handlePageChange}
                />
            </div>*/}
            <NewTableLayout listData={initialAssessmentData} loadMethod={loadMethod} loadDeps={[searchText]}>
                <Table headers={serviceHeaderData} rows={transformedArray} hasFixedWidth={true} tableClass="charting-table" />
            </NewTableLayout>
        </>
    );
};

export default InitialAssessment;
