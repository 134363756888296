import React from "react";
import CustomLabel from "../fields/CustomLabel";
import {fieldBottomMarginClass} from "../../appConstants";

const CustomTextarea = ({
                               input,
                               type,
                               autoComplete,
                               min,
                               max,
                               autoFocus,
                               readOnly,
                               name,
                               label,
                               placeholder,
                               disabled,
                               maxLength,
                               minLength,
                               required,
                               className,
                               labelClass,
                               requiredMark,
                               meta: { touched, error, warning },
                               showErrorsFixed = true,
                               ...custom
                             }) => {
  let containerClassName = `w-100 h-auto p-0 m-0 ${fieldBottomMarginClass} d-flex flex-column position-relative`;

  return (
    <>
      {
        <div className={containerClassName}>
          {!!label && <CustomLabel labelFor={name} requiredMark={requiredMark} readOnly={readOnly} className={labelClass} labelText={label}/>}
          <textarea
            placeholder={placeholder || undefined}
            readOnly={readOnly || false}
            name={name}
            required={required || false}
            inputprops={{maxLength: maxLength ? maxLength : 255}}
            disabled={disabled || false}
            {...input}
            {...custom}
            className={className || undefined}
          />
          {touched && ((error && <span className={`field-error${showErrorsFixed ? ' position-absolute bottom-field-error start-0' : ''}`}>{error}</span>)
            || (warning && <span>{warning}</span>))}
        </div>
      }
    </>
  );
};

export default CustomTextarea;
