import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

const MultiSelectDropdown = (props) => {
  const {
    dpOptions,
    getSelectedOptions,
    selectedLanguages,
    label,
    requiredMark,
    name,
  } = props;
// console.log('dpOptions', dpOptions)
  const dropdownOptions = !!dpOptions ? dpOptions.map((res) => {
    return {
      value: res?.id,
      label: res?.name,
    };
  }) : [];

  return (
    <>
      <label>
        {label} {requiredMark && <span className="mandatory-field">*</span>}
      </label>
      <Select
        name={name}
        closeMenuOnSelect={false}
        components={animatedComponents}
        value={selectedLanguages}
        isMulti
        options={dropdownOptions}
        onChange={(e) => getSelectedOptions(e)}
        styles={{
          multiValueLabel: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: "#0b5ed7",
            color: "#ffffff",
          }),
          multiValueRemove: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: "#0b5ed7",
            color: "#ffffff",
            ":hover": {
              backgroundColor: "#0b5ed7",
              color: "#ffffff",
            },
          }),
          // ...
        }}
      />
    </>
  );
};

export default MultiSelectDropdown;
