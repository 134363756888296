import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {Field, reduxForm} from "redux-form";
import classes from "../index.module.css";
import PrimaryButton from "../../../../sharedComponents/buttons/PrimaryButton";
import CustomInputField from "../../../../sharedComponents/reduxfields/customInputField";
import {toastr} from "react-redux-toastr";
import {HttpsAction} from "../../../../commonApiFunction/httpsAction";
import {putAgencyAction} from "../../../../services/AgencyProfile";
import {useNavigate, useOutletContext} from "react-router-dom";
import {dataURLtoFile, toBase64} from "../../../../helpers/formMethods";
import {createDownloadUrl} from "../../../../helpers/apiMethods";
import storageManager from "../../../../helpers/storageManager";
import FileInput from "../../../../sharedComponents/fileInput";

const maxFileSizeMbs = 20;

const Logo = ({reloadData, handleSubmit, initialValues}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { agencyId } = useOutletContext();

    // const [logoLink, setLogoLink] = useState('');
    const [logoFile, setLogoFile] = useState(null);
    // const [base64LogoPic, setBase64LogoPic] = useState(null);

    // const handleFileBrowse = async (target) => {
    //     const selectedFile = target.files[0];
    //     // console.log('selectedFile', selectedFile);
    //
    //     if (selectedFile != null) {
    //         // Check if file size exceeds 100MB
    //         if (selectedFile.size > maxFileSizeMbs * 1024 * 1024) {
    //             toastr.error(`Selected file exceeds the maximum allowed size of ${maxFileSizeMbs}MB.`)
    //             setLogoFile(null);
    //         } else {
    //             setLogoFile(selectedFile);
    //             const base64Format = await toBase64(selectedFile);
    //             setBase64LogoPic(base64Format);
    //             // const base64File = dataURLtoFile(base64Format, selectedFile.name);
    //             // setLogoFile(base64File);
    //         }
    //     }
    // }

    const onSubmit = async () => {
        if (logoFile != null) {
            const formData = new FormData();
            formData.append('logo', logoFile);
            try {
                // const decodedTokenData = parseTokenAndGetData().data;
                const response = await dispatch(
                  putAgencyAction(formData, "logo", "Agency logo updated successfully"/*, decodedTokenData.agencyId*/)
                );
                if (response?.success) {
                    await dispatch(reloadData(response?.data?.logoLink));
                }
            } catch (error) {
                console.error(error);
            }
        }
    }

    useEffect(() => {
        if (initialValues != null && initialValues.logoLink.length > 0) {
            // setLogoLink(createDownloadUrl(initialValues.logoLink));
            storageManager.saveProfileImageLink(initialValues.logoLink);
        }
    }, [initialValues]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    {/*<div className="col-12">
                        <div className="mb-4 input-field-main">
                            <div className={classes.nurseInfoLabel}>
                                How many Nurses do you have registered with you?
                            </div>
                        </div>
                    </div>*/}
                    <div className="col-12">
                        <div className="mb-4 input-field-main d-flex flex-column gap-3">
                            {/*<Field
                             className="form-control mk-wi"
                             name="agencyLogo"
                             id="agencyLogo"
                             label="Set Agency Logo"
                             type="file"
                             component={CustomInputField}
                             />*/}
                            {/*<label htmlFor="agencyLogo" className="form-label text-dark">
                             Set Agency Logo
                             </label>*/}
                            {/*<button href="#" className={`btn btn-sm d-flex align-content-center`}><span>Set Logo</span></button>
                             <input
                             type="file"
                             className="form-control"
                             id="agencyLogo"
                             placeholder="file"
                             />*/}
                            {/*<label htmlFor="agencyLogo" className="fileBrowseButton" style={{width: '130px'}}>
                                <span className="w-max" style={{width: "80%", textAlign: 'center', lineHeight: '16px'}}>Set Agency Logo</span>
                            </label>
                            <input
                                type="file"
                                id="agencyLogo"
                                style={{display: 'none'}}
                                onChange={({target}) => handleFileBrowse(target)}
                                multiple={false}
                                accept="image/*"
                            />
                            <div><span>Filename:&nbsp;</span><span>{logoFile == null ? 'No file selected' : logoFile.name}</span></div>
                            {base64LogoPic != null && <img id="logo" alt={''} className="neo-img1" src={base64LogoPic} style={{width: '250px', aspectRatio: '1'}}/>}
                            {base64LogoPic == null && logoLink != null && <img id="logo" alt={''} className="neo-img1" src={logoLink} style={{width: '250px', aspectRatio: '1'}}/>}*/}
                            <FileInput
                              id="agencyLogo"
                              label="CHANGE AGENCY LOGO"
                              handleFileBrowse={(selectedFile) => setLogoFile(() => selectedFile)}
                              accept="image/*"
                              fileLink={initialValues?.logoLink || ''}
                              // fileUploadedSuccessfully={logoFile == null}
                            />
                        </div>
                    </div>
                </div>
                <div
                  className="d-flex justify-content-center"
                  style={{marginTop: "40px"}}
                >
                    <PrimaryButton
                        type="submit"
                        className="btn-view-repo gap-2 px-5 py-2"
                        text={"Upload and Save"}
                        onClick={() => undefined}
                    />
                </div>
            </form>
        </>
    )
}

const mapStateToProps = state => {
    // console.log('state', state)
    const {agencyProfile} = state;
    const data = agencyProfile?.agencyProfile?.data;
    console.log('logo data', data)

    return {
        initialValues: {
            // filename: data?.logoFilename,
            // fileSize: data?.logoFileSize,
            logoLink: data?.logoLink || '',
        }
    }
}

export default connect(mapStateToProps)(
    reduxForm({form: "agencyLogo", enableReinitialize: true})(Logo)
);
