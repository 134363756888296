import React from 'react'
import CustomLabel from "../fields/CustomLabel";
import {fieldBottomMarginClass} from "../../appConstants";
import stringHelperFunctions from "../../helpers/string.helpers";

const CustomInputField = ({ input, meta: { touched, error, warning }, style, ...custom }) => {
  let { name, value } = input;
  const fieldId = stringHelperFunctions.camelCaseToSnakeCase(name);
  const { className, label, type, requiredMark, readOnly, disabled, labelClass, placeholder, ...rest1 } = custom;
  const { defaultValue, showErrorsFixed = true, currencySymbol, ...rest2 } = rest1;
  // console.log('test- rest', rest2)
  // console.log('test- input', input)

  const hasCurrencySymbol = currencySymbol != null && currencySymbol.length > 0;
  let inputClassName = className;
  if (hasCurrencySymbol) inputClassName += ' ps-3a';
  let containerClassName = `w-100 h-auto p-0 m-0 ${fieldBottomMarginClass} d-flex flex-column`;
  if (hasCurrencySymbol || showErrorsFixed) containerClassName += ' position-relative';
  if (type === 'hidden') containerClassName = 'w-100 h-0 p-0 m-0';
  if (value == null) value = defaultValue || (hasCurrencySymbol ? 0 : '');

  return (
    <div className={containerClassName}>
      {!!label && <CustomLabel labelFor={name} requiredMark={requiredMark} readOnly={readOnly} className={labelClass} labelText={label}/>}
      <input
        {...input}
        // placeholder={label}
        placeholder={placeholder || undefined}
        type={type || 'text'}
        disabled={disabled || undefined}
        readOnly={readOnly || undefined}
        name={name}
        id={fieldId}
        className={inputClassName || undefined}
        style={style || undefined}

        // required={required || false}
        // inputprops={{maxLength: maxLength ? maxLength : 255}}
        // autoComplete={autoComplete || 'off'}
        // autoFocus={autoFocus || false}
        // min={min || ''}
        // max={max || ''}

        value={value}
      />
      {hasCurrencySymbol && <div className="position-absolute" style={{bottom: '0.5rem', left: '0.5rem'}}>{currencySymbol}</div>}
      {touched && ((error && <span className={`field-error${showErrorsFixed ? ' position-absolute bottom-field-error start-0' : ''}`}>{error}</span>)
        || (warning && <span>{warning}</span>))}
    </div>
  );
}

const CustomInputField1 = ({
                             defaultValue, currencySymbol, input, type, autoComplete, min, max, autoFocus, readOnly, name,
                             label, value, placeholder, disabled, maxLength, minLength, required, className, requiredMark,
                             labelClass, showErrorsFixed = false, meta: {touched, error, warning, active, visited}, ...custom
                           }) => {
  const hasCurrencySymbol = currencySymbol != null && currencySymbol.length > 0;
  let inputClassName = className;
  if (hasCurrencySymbol) inputClassName += ' ps-3a';
  let containerClassName = `w-100 h-auto p-0 m-0 ${fieldBottomMarginClass}`;
  if (type === 'hidden') containerClassName = 'w-100 h-0 p-0 m-0';
  if (hasCurrencySymbol || showErrorsFixed) containerClassName += ' position-relative';
    if (value == null) value = defaultValue || (hasCurrencySymbol ? 0 : '');
  // console.log('test- touched', name, input.name, touched, '"', error, '"', warning, active, visited);
    return (
      <>
          {
              <div className={containerClassName}>
                  {/*<label>{label} {requiredMark && <span className="mandatory-field">*</span>}</label>*/}
                  {label != null && <CustomLabel labelFor={name} requiredMark={requiredMark} readOnly={readOnly} className={labelClass || null} labelText={label} />}
                  <input
                    type={type || 'text'}
                    value={value}
                    onChange={(e) => {
// console.log('test- onChange', e, e.target.value)
                      return input.onChange(e.target.value);
                    }}
                    placeholder={placeholder || ''}
                    autoComplete={autoComplete || 'off'}
                    autoFocus={autoFocus || false}
                    min={min || ''}
                    max={max || ''}
                    readOnly={readOnly || false}
                    name={name}
                    id={name}
                    required={required || false}
                    inputprops={{maxLength: maxLength ? maxLength : 255}}
                    disabled={disabled || false}
                    {...input}
                    {...custom}
                    className={inputClassName || ''}
                  />
                  {hasCurrencySymbol && <div className="position-absolute" style={{bottom: '0.5rem', left: '0.5rem'}}>{currencySymbol}</div>}
                  {touched && error && (<span className={`field-error${showErrorsFixed ? ' position-absolute bottom-field-error  start-0' : ''}`}>{error}</span>)}
              </div>
          }
      </>
    );
}

export default CustomInputField
