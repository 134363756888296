import React from 'react';
import {NavLink, Outlet} from "react-router-dom";

const alertsTabsList = [
  {
    name: 'All Alerts',
    route: 'all',
  },
  {
    name: 'Jobs',
    route: 'jobs',
  },
  {
    name: 'Bids',
    route: 'bids',
  },
  {
    // name: 'Service Requests',
    // route: 'service-requests',
    name: 'Visits',
    route: 'visits',
  },
  {
    name: 'Clock In/Clock Out Time',
    route: 'clock-in-clock-out-time',
  },
  {
    name: 'Transactions',
    route: 'transactions',
  },
  {
    name: 'Expiry/Renewal',
    route: 'expiry-renewal',
  },
];


const AlertsLayout = () => {
  const pathPrefix = '/agency/alerts';

  return (
    <div className="app-main__outer">
      <div className="d-flex flex-column bg-white">
        <div className="app-main__inner">
          <h3 className="pt-4 pb-4">Alerts</h3>
          <div className="row">
            <div className="col-12 col-sm-9 mb-0 d-flex align-items-end w-100">
              <div className="d-flex align-items-center custom-tab-styling">
                <ul id="pills-tab" role="tablist"
                  // className="nav nav-underline mb-0 mtx-4"
                  className="nav nav-tabs p-1"
                >
                  {(alertsTabsList || [])?.map(({name, route}, index) => (
                    <li className="nav-item" role="presentation" key={index}>
                      <NavLink to={`${pathPrefix}/${route}`} className="nav-link tab-nav-link-style" key={index}>{name}</NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="bg-light-gray px-3 py-3">
            <div className="row">
              <div className="container-fluid">
                <div className="tab-content" id="pills-tabContent">
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlertsLayout;
