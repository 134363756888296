import * as chartingRequest from "../Agency/components/ServiceRequest/ChartingActionTypes";

const actionTypeHelperFunctions = {

  getChartingActionType(endpoint) {
    let actionType = 'CHARTING_DATA'; // dummy/placeholder value
    if (endpoint === 'initialAssessment') {
      actionType = chartingRequest.GET_INITIAL_ASSESSMENT;
    }
    if (endpoint === 'supervisoryVisits') {
      actionType = chartingRequest.GET_SUPERVISORY_VISITS;
    }
    if (endpoint === 'dischargeSummaries') {
      actionType = chartingRequest.GET_DISCHARGE_SUMMARIES;
    }
    if (endpoint === 'carePlans') {
      actionType = chartingRequest.GET_CARE_PLANS;
    }
    return actionType;
  },

};

export default actionTypeHelperFunctions;
