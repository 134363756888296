import React, {useEffect} from "react";
import CommonList from "../commonList";
import SearchFilters from "../SearchFilters";
import {useDispatch, useSelector} from "react-redux";
import Pagination from "../../../../sharedComponents/pagination";
import {HttpsAction} from "../../../../commonApiFunction/httpsAction";
import {pageLimit} from "../../../../appConstants";

const Bids = () => {

    const dispatch = useDispatch();

    const alertsData = useSelector(state => state.alerts?.bidsAlerts?.data)

    const onPageChange = (page) => {
        dispatch(HttpsAction({
            apiSection: 'alerts',
            apiName: 'alerts',
            reducerName: 'ALERTS',
            reduxKeyName: 'bidsAlerts',
            actionType: 'GET_BIDS_ALERTS',
            queryParameter: `?page=${page}&pageSize=${pageLimit}&type=bids`
        }))
    };

    useEffect(() => {
        onPageChange(1)
    }, []);

    return (
        <>
            <div className={'row'}>
                <div className={'col-8'}>
                    <div className={'d-flex flex-column gap-3'}>
                        {alertsData?.rows.length > 0 && alertsData?.rows.map((value, index) => (
                            <React.Fragment key={index}>
                                <CommonList index={index} message={value?.alertMessage} title={value?.alertTitle} date={value?.processedDateFormatUs} time={value?.processedTimeAmPm} />
                            </React.Fragment>
                        ))}
                        {alertsData?.rows.length === 0 && <span>No Bid alerts found</span>}
                    </div>
                </div>
                {alertsData?.rows.length > 0 && <div className={'col-4'}>
                    <SearchFilters/>
                </div>}
            </div>
            {alertsData?.rows.length > 0 && <Pagination
              currentPage={alertsData?.page}
              // currentPage={1}
              // totalRecords={0}
              totalRecords={alertsData?.total}
              limit={10}
              onPageChange={onPageChange}
            />}
        </>
    )
};

export default Bids;
