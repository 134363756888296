import React, {useEffect} from "react";
import Table from "../../../sharedComponents/table/Table";
import classes from "./css/InitialAssessment.module.css";
import MoreOptionDropdown from "./MoreOptionDropdown";
import {useLocation, useNavigate, useOutletContext} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {toastr} from "react-redux-toastr";
import dateHelperFunctions from "../../../helpers/date.helpers";
import {Pagination, Stack} from "@mui/material";
import {getChartingData} from "../../../services/charting";
import NewTableLayout from "../shared/NewTableLayout";
import useCustomNav from "../../../utils/hooks/useCustomNav";

const CarePlan = ({/*toSearch, */}) => {
  const backButtonTextSuffix = 'Charting - Supervisory Visits';
  // const backLink = '/agency/charting/careplans';
  const backLink = '/agency/charting/care-plans';

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { searchText, setPaginationTab } = useOutletContext();

  const { goTo: gotoTemplatesPage } = useCustomNav('/agency/charting/templates');
  const { goTo: gotoNurseChartingPage } = useCustomNav('/agency/nurses/edit/profile/charting');
  const { goTo: gotoPatientChartingPage } = useCustomNav('/agency/clients/edit/charting');

  const carePlanData = useSelector(state => state.charting.carePlansData);

  const chartingIdToSelect = location?.state != null && location?.state?.chartingId != null ? location.state.chartingId : 0;

  const transformedArray = (carePlanData?.rows || [])?.map((item, index) => {
    const highlightRow = chartingIdToSelect === item.chartingId;
    return {
      assessmentTitle: <div className="position-relative1 p-0 m-0 d-flex flex-column gap-1">
        <span>{item?.assessmentTitle || 'N/A'}</span>
        {/*<div className="position-absolute bottom-6p25 start-50 translate-middle cursor-pointer" onClick={() => {*/}
        <div onClick={() => {
          // let url = null;
          // // if ('abc' === 'abc')
          // url = '/agency/charting/forms';
          // if (url != null) {
          //   navigate(url, {
          //     state: {
          //       templateId: item.formTemplateId,
          //     },
          //   });
          // }
          gotoTemplatesPage({templateId: item.formTemplateId,});
        }}><span className={`table-inner-link text-break lh-1`}>{item.chartingType}</span></div>
      </div>,
      // assignedNurse: item?.nurseFullName,
      assignedNurse: <span className={classes.assessment_title} onClick={(e) => {
        e.preventDefault();
        if (item.serviceRequestId != null)
          // navigate(`/agency/clients/${item.patientId}/charting/0`);
          // navigate(`/agency/nurses/edit/${item?.nurseId}/charting`, {
          //   state: {
          //     showBackButton: true,
          //     backButtonTextSuffix,
          //     backLink,
          //   }
          // });
          gotoNurseChartingPage({
            id: item?.nurseId,
            showBackButton: true,
            backButtonTextSuffix,
            backLink,
          });
        else {
          e.target.parentElement.parentElement.querySelector('td:last-child').children[0].children[0].click();
          toastr.error('Create a Service Request first');
        }
      }}>{item?.nurseFullName}</span>,
      // accessLevel: item?.accessLevel,
      // careRecipient: item?.patientFullName,
      careRecipient: <span className={classes.assessment_title} onClick={(e) => {
        e.preventDefault();
        if (item.serviceRequestId != null)
          // navigate(`/agency/clients/${item.patientId}/charting/0`);
          // navigate(`/agency/clients/${item.patientId}/charting/0`, {
          //   state: {
          //     showBackButton: true,
          //     backButtonTextSuffix,
          //     backLink,
          //   }
          // });
          gotoPatientChartingPage({
            id: item?.patientId,
            showBackButton: true,
            backButtonTextSuffix,
            backLink,
          });
        else {
          // alert(e.target.parentElement.parentElement.querySelector('td:last-child').children[0].className)
          e.target.parentElement.parentElement.querySelector('td:last-child').children[0].children[0].click();
          toastr.error('Create a Service Request first');
        }
      }}>{item?.patientFullName}</span>,
      // duration: <><span className="d-block">27/05/23 - 27/05/23</span></>,
      // duration: <><span className="d-block">{item?.startDateEndDate || 'N/A'}</span></>,
      duration: <Stack>
        <Stack>{item.taskDateFormatUs}</Stack>
        <Stack>{item.expectedTimings}</Stack>
      </Stack>,
// status: <><span className="d-block">{item?.serviceRequestStatusName || 'N/A'}</span></>,
      'status': <div onClick={() => {
        if (item.taskStatusId != null) {
          let url = null;
          if (!item.isMissed && item.taskStatusId === 2) url = `/agency/servicerequest/scheduledrequest`;
          else if (item.taskStatusId === 3 && item.activityCompletionTime == null) url = `/agency/servicerequest/activerequest`;
          else if (item.taskStatusId >= 3 && item.activityCompletionTime != null) url = `/agency/servicerequest/completeRequest`; // status-id is 4 or 5
          else if (item.isMissed) url = `/agency/servicerequest/missedRequests`; // missed (no clock-in)
          else if (!item.isMissed && item.activityCompletionTime == null) url = `/agency/servicerequest/incompleteVisits`; // in-complete
          if (url != null) {
            navigate(url, {
              state: {
                taskId: item.taskId,
              },
            });
          }
        }
      }}>
        <span className="table-inner-link text-nowrap">{item.taskStatusName}</span>
      </div>,
      created: item?.serviceRequestCreationDate || 'N/A',
      expiry: item?.expirationDate == null ? 'N/A' : dateHelperFunctions.updateDateFormat(item?.expirationDate),
      highlightRow,
      menuIcon: <MoreOptionDropdown managingForServiceRequestType={'carePlans'} eachData={item}/>,
    };
  });

  // const serviceHeaderData = [
  //   {
  //     title: "Care Recipients",
  //     dataIndex: "careRecipients",
  //   },
  //   {
  //     title: "Service Provided",
  //     dataIndex: "serviceProvided",
  //   },
  //   {
  //     title: "Care Recipient",
  //     dataIndex: "careRecipient",
  //   },
  //   {
  //     title: "Start Date",
  //     dataIndex: "startDate",
  //   },
  //   {
  //     title: "End Date",
  //     dataIndex: "endDate",
  //   },
  //   {
  //     title: "Location",
  //     dataIndex: "location",
  //   },
  //   {
  //     title: "Care Giver",
  //     dataIndex: "careGiver",
  //   }, {
  //     title: "",
  //     dataIndex: "menuIcon",
  //   }
  // ];

  const serviceHeaderData = [
    {
      title: "Assessment Title",
      dataIndex: "assessmentTitle",
    },
    {
      title: "Assigned Nurse",
      dataIndex: "assignedNurse",
      width: '140px',
    },
    // {
    //     title: "Access Level",
    //     dataIndex: "accessLevel",
    // },
    {
      title: "Care Recipient",
      dataIndex: "careRecipient",
      width: '150px',
    },
    {
      title: "Duration",
      dataIndex: "duration",
      width: '130px',
    },
    {
      title: "Status",
      dataIndex: "status",
      width: '90px',
    },
    {
      title: "Created",
      dataIndex: "created",
      width: '90px',
    },
    {
      title: "Expiry",
      dataIndex: "expiry",
      width: '90px',
    },
    {
      title: "",
      dataIndex: "menuIcon",
      width: '35px',
    }
  ];

  // const getCarePlans = () => {
  //   dispatch(getChartingData({endPoint: 'carePlans'}));
  // };
  //
  // const handlePageChange = (page) => {
  //   // storageManager.saveClientListCurrentPage(page);
  //   return dispatch(getChartingData({endPoint: 'carePlans'}, page));
  // };

  const getData = (page, searchVal) => {
    dispatch(getChartingData({endPoint: 'carePlans'}, page, searchVal));
  };

  const loadMethod = (curPage, toSearch) => {
    const useDefault = curPage == null && toSearch == null;
    if (useDefault) getData(1);
    else {
      if (toSearch != null && toSearch.length > 0)
        getData(1, toSearch);
      else
        getData(curPage);
    }
  };

  useEffect(() => {
    if (carePlanData != null && carePlanData?.rows?.length != null) {
      setPaginationTab(carePlanData);
    }
  }, [carePlanData]);

  // useEffect(() => {
  //   if (toSearch != null && toSearch.length > 0) {
  //     dispatch(getChartingData({endPoint: 'carePlans'}, 1, toSearch));
  //   } else {
  //     dispatch(getChartingData({endPoint: 'carePlans'}, 1));
  //   }
  // }, [toSearch]);
  //
  // useEffect(() => {
  //   getCarePlans();
  // }, []);

    return (
        <>
            {/*<div className="app-main__inner service-request-inner">
                <div className="tab-content" id="myTabContent">
                    <Table headers={serviceHeaderData} rows={transformedArray} hasFixedWidth={true} tableClass="charting-table" />
                </div>
                <Pagination
                    currentPage={carePlanData?.page}
                    totalRecords={carePlanData?.total}
                    limit={carePlanData?.pageSize}
                    onPageChange={handlePageChange}
                />
            </div>*/}
          <NewTableLayout listData={carePlanData} loadMethod={loadMethod} loadDeps={[searchText]}>
            <Table headers={serviceHeaderData} rows={transformedArray} hasFixedWidth={true} tableClass="charting-table" />
          </NewTableLayout>
        </>
    );
};

export default CarePlan;
