const booleanHelperFunctions = {

  parseBoolean(value, defaultValue = false) {
    if (value != null) {
      if (typeof value === 'boolean')
        return value;
      if (typeof value === 'number') {
        if (value === 1) {
          return true;
        }
        if (value === 0) {
          return false;
        }
      }
      if (typeof value === 'string') {
        switch(value.toLowerCase()) {
          case "true":
          case "1":
          case "on":
          case "yes":
            return true;
          default:
            return false;
        }
      }
    }
    return defaultValue;
  },

  convertToString(value, defaultValue = 'false') {
    if (value != null) {
      if (typeof value === 'boolean')
        return value ? 'true' : 'false';
      if (typeof value === 'number') {
        if (value === 1) {
          return 'true';
        }
        if (value === 0) {
          return 'false';
        }
      }
    }
    return defaultValue;
  },


  nullIfEmpty(value) {
    if (value != null) {
      if (typeof value === 'boolean') return value;
      if (typeof value === 'string') {
        if (value.length !== 0) {
          switch(value.toLowerCase()) {
            case "true":
            case "1":
            case "on":
            case "yes":
              return true;
            default:
              return false;
          }
        }
      }
      if (typeof value === 'number') {
        // return value === 1;
        return value > 0;
      }
    }
    return null;
  },


};

export default booleanHelperFunctions;
