import * as commonServices from "./commonServices.js";
import getApiUrl from "../helpers/apiURLs.js";
import api from "../helpers/interceptor";
import * as chartingRequest from "../Agency/components/ServiceRequest/ChartingActionTypes";
import {GET_CARE_PLANS} from "../Agency/components/ServiceRequest/ChartingActionTypes";
import apiCallHelpers from "../helpers/api.call.helpers";
import actionTypeHelperFunctions from "../helpers/action.type.helpers";

//Toaster Options -- success, info, warning, error

// Add Task
export const addTask = (payload) => {
  return async (dispatch) => {
    dispatch(commonServices.setLoader(true));
    const url = getApiUrl("charting", "createTask");
    const data = api
      .post(url, payload.value)
      .then((response) => {
        dispatch(commonServices.setLoader(false));
        getTasksData(payload.type, "end");
        return response;
      })
      .catch((err) => {dispatch(commonServices.setLoader(false));});
    return data;
  };
};

export const getTasksData = (type) => {
  return async (dispatch) => {
    dispatch(commonServices.setLoader(true));
    const url = getApiUrl("charting", type.endPoint);
    api
      .get(url)
      .then((response) => {
        dispatch(commonServices.getData(response.data, type.type));
        dispatch(commonServices.setLoader(false));
        return response;
      })
      .catch((err) => {dispatch(commonServices.setLoader(false));});
  };
};
export const getChartingData = (type, page, toSearch) => {
    return async (dispatch) => {
        dispatch(commonServices.setLoader(true));
        const chartingTypeId = apiCallHelpers.getChartingTypeId(type.endPoint);
        const actionType = actionTypeHelperFunctions.getChartingActionType(type.endPoint);
        const url = `${getApiUrl("charting", 'chartingData')}?page=${page || 0}&pageSize=10&chartingTypeId=${chartingTypeId}${toSearch != null ? `&searchText=${toSearch}` : ''}`;
        api
            .get(url)
            .then((response) => {
                dispatch({type: actionType, payload: response.data})
                dispatch(commonServices.setLoader(false));
                return response;
            })
            .catch((err) => {
                dispatch(commonServices.setLoader(false));
            });
    };
};

export const postChartingData = (type, payload, successCallback) => {
    return async (dispatch) => {
        dispatch(commonServices.setLoader(true));
        const url = getApiUrl("charting", type);
        return api
            .post(url, payload)
            .then((response) => {
                dispatch(commonServices.setLoader(false));
                if (successCallback != null) successCallback();
                return response;
            })
            .catch((err) => {
                dispatch(commonServices.setLoader(false));
            });
    };
}

export const putChartingData = (payload, id) => {
    return async (dispatch) => {
        dispatch(commonServices.setLoader(true));
        const url = `${getApiUrl("charting", 'chartingData')}/${id}`;
        return api
            .put(url, payload)
            .then((response) => {
                dispatch(commonServices.setLoader(false));
                return response;
            })
            .catch((err) => {
                dispatch(commonServices.setLoader(false));
            });
    };
}

export const deleteChartingData = (id) => {
    return async (dispatch) => {
        dispatch(commonServices.setLoader(true));
        const url = `${getApiUrl("charting", 'chartingData')}/${id}`;
        return api
            .delete(url)
            .then((response) => {
                dispatch(commonServices.setLoader(false));
                return response;
            })
            .catch((err) => {
                dispatch(commonServices.setLoader(false));
            });
    };
}

export const postServiceRequest = (type, payload) => {
    return async (dispatch) => {
        dispatch(commonServices.setLoader(true));
        // const url = getApiUrl("serviceRequests", "createServiceRequest");
        const url = getApiUrl("charting", type);
        const data = api
            .post(url, payload)
            .then((response) => {
                dispatch(commonServices.setLoader(false));
                return response;
            })
            .catch((err) => {
                dispatch(commonServices.setLoader(false));
            });
        return data;
    };
}

export const postAdultAssessment = (payload) => {
    return async (dispatch) => {
        dispatch(commonServices.setLoader(true));
        const url = getApiUrl("charting", "adultAssessment");
        const data = api
            .post(url, payload)
            .then((response) => {
                dispatch(commonServices.setLoader(false));
                return response;
            })
            .catch((err) => {
                dispatch(commonServices.setLoader(false));
            });
        return data;
    };
}

export default {
  addTask,
};
