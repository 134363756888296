import React, {useEffect, useState} from 'react';
import {Stack} from "@mui/material";
import {NavLink, Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import storageManager from "../helpers/storageManager";
import BackLink from "../sharedComponents/buttons/BackLink";
import classes from "../Agency/components/Client/css/index.module.css";
import stringHelperFunctions from "../helpers/string.helpers";
import {useClientMinimalInfo} from "../queries/agency/clientsList";
import {connect} from "react-redux";
// import PersonalInformation from "../Agency/components/Client/ClientProfile/Profile/PersonalInformation";
// import CareInformation from "../Agency/components/Client/ClientProfile/Profile/CareInformation";
// import ResidentialAddress from "../Agency/components/Client/ClientProfile/Profile/ResidentialAddress";
// import BillingAddress from "../Agency/components/Client/ClientProfile/Profile/BillingAddress";
// import ContactInformation from "../Agency/components/Client/ClientProfile/Profile/ContactInformation";
// import Characteristics from "../Agency/components/Client/ClientProfile/Profile/Characteristics";
// import PetInformation from "../Agency/components/Client/ClientProfile/Profile/PetInformation";

const clientProfileTabsList = [
  {
    path: 'profile',
    text: 'Profile',
  }, {
    text: 'Calendar',
    path: 'calendar',
  }, {
    text: 'Care Notes',
    path: 'care-notes/0',
  }, {
    text: 'Notes',
    path: 'notes',
  }, {
    text: 'RESP. Party',
    path: 'responsible-party',
  },
  {
    text: 'Documents',
    path: 'documents',
  },
  {
    text: 'Charting',
    path: 'charting',
  },
  {
    text: 'Healthcare',
    path: 'healthcare',
  },
];

const ClientProfileLayout = ({agencyId}) => {
  const pathPrefix = '/agency/clients/edit';

  const navigate = useNavigate();
  // const { id: nurseId } = useParams();
  const { state: locationState } = useLocation();

  const clientMinimalInfo = useClientMinimalInfo(agencyId, +(locationState?.id || '0'));
  const profileMinimalInfo = clientMinimalInfo?.data || null;

  // const [showBackButton, setShowBackButton] = useState((locationState != null && locationState.showBackButton && locationState.backLink != null && locationState.backButtonTextSuffix != null) || false);
  const [showBackButton, setShowBackButton] = useState(false);
  const [backLink, setBackLink] = useState('');
  const [backButtonTextSuffix, setBackButtonTextSuffix] = useState('');
  const [currentPatientId, setCurrentPatientId] = useState(0);
  const [patientMinimalInfo, setPatientMinimalInfo] = useState(null);

  useEffect(() => {
// console.log('test- profileMinimalInfo', profileMinimalInfo);
    if (profileMinimalInfo != null && JSON.stringify(profileMinimalInfo) !== '{}') {
      setPatientMinimalInfo(profileMinimalInfo);
      storageManager.saveCurrentPatientMinimalInfo(profileMinimalInfo);
    }
  }, [profileMinimalInfo]);

  useEffect(() => {
// console.log('test- patientMinimalInfo', patientMinimalInfo);
    if (patientMinimalInfo == null || JSON.stringify(patientMinimalInfo) === '{}') {
      const minimalInfoStr = storageManager.retrieveCurrentPatientMinimalInfo();
      if (minimalInfoStr != null) {
        setPatientMinimalInfo(JSON.parse(minimalInfoStr));
      }
    }
  }, [patientMinimalInfo]);

  useEffect(() => {
    if (locationState != null && locationState.showBackButton) {
      setShowBackButton(locationState.showBackButton);
      setBackLink(locationState.backLink);
      setBackButtonTextSuffix(locationState.backButtonTextSuffix);
    }

    if (locationState != null && locationState.id != null) {
      setCurrentPatientId(locationState.id);
    }
  }, [locationState]);

  useEffect(() => {
    if (locationState != null) {
      setCurrentPatientId(locationState.id);
      storageManager.saveCurrentPatientId(locationState.id);
    } else {
      setCurrentPatientId(storageManager.retrieveCurrentPatientId());
    }

    return () => {
      storageManager.clearCurrentPatientId();
      storageManager.clearCurrentPatientMinimalInfo();
    };
  }, []);

  return (
    <div className="app-main__outer">
      <div className="main-service-request-page">
        <div className="app-main__inner service-request bg-white">
          {/*<BackLink link={showBackButton && locationState != null && locationState.backLink != null ? locationState.backLink : '/agency/clients'}*/}
          {/*          linkText={showBackButton && locationState != null && locationState.backButtonTextSuffix != null ? locationState.backButtonTextSuffix : 'Client List'}/>*/}
          <BackLink link={showBackButton && backLink ? backLink : '/agency/clients'}
                    linkText={showBackButton && backButtonTextSuffix != null ? backButtonTextSuffix : 'Client List'}/>
          <div className="row pb-4">
            <Stack className={classes.clientNameParent}>
              <Stack className={classes.clientNameRow}>
                <Stack className={classes.clientName}>
                  {`${patientMinimalInfo?.patientFirstName || ""} ${patientMinimalInfo?.patientLastName || ""}`}
                </Stack>
                {/*<Stack className={classes.clientIdButton}>*/}
                {/*    CLIENT - ID {params.clientId}*/}
                {/*</Stack>*/}
              </Stack>
              <Stack className={classes.clientNameRow}>
                {/*<div className="input-group input-search-djkds">*/}
                {/*      <span className="input-group-append d-flex">*/}
                {/*        <button className="btn ms-n5" type="button">*/}
                {/*          <i className="fa fa-search"></i>*/}
                {/*        </button>*/}
                {/*      </span>*/}
                {/*    <input*/}
                {/*        className="form-control hhgrsjd bg-transparent border-0 border"*/}
                {/*        type="search"*/}
                {/*        placeholder="Search..."*/}
                {/*    />*/}
                {/*</div>*/}
                {/*<PrimaryButton*/}
                {/*    type="button"*/}
                {/*    className="btn-view-repo gap-2 px-5 py-2"*/}
                {/*    text={"Save"}*/}
                {/*    onClick={() => undefined}*/}
                {/*/>*/}
              </Stack>
            </Stack>
            <Stack className={classes.clientAddress}>
              {
                patientMinimalInfo?.patientAddressLine1 == null && patientMinimalInfo?.patientProvinceOrState == null && patientMinimalInfo?.patientCity == null && patientMinimalInfo?.patientPostalOrZipCode == null
                  ? 'N/A'
                  : stringHelperFunctions.csvfy(patientMinimalInfo?.patientAddressLine1) + stringHelperFunctions.csvfy(patientMinimalInfo?.patientProvinceOrState) + stringHelperFunctions.csvfy(patientMinimalInfo?.patientCity) + stringHelperFunctions.csvfy(patientMinimalInfo?.patientPostalOrZipCode, true)
              }
            </Stack>
            <Stack className={classes.clientPhoneNumber}>
              {/*404-492-0990 (mobile) 404-492-0990 (home)*/}
            </Stack>

          </div>

          <div className="row align-items-center">
            <div className="col">
              <ul className="nav nav-tabs p-1" id="myTab" role="tablist">
                {(clientProfileTabsList || []).map(({text, path}, index) => (
                  <li className="nav-item" role="presentation" key={index}>
                    <NavLink to={`${pathPrefix}/${path}`} className="nav-link tab-nav-link-style">{text}</NavLink>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <Stack className={classes.contentMain}>
          {patientMinimalInfo != null && currentPatientId > 0 && <Outlet context={{ clientId: currentPatientId }}/>}
        </Stack>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { auth } = state;

  return {
    isAdmin: auth?.authData?.isAdmin || false,
    agencyId: auth?.authData?.agencyId || auth.agencyId,
  };
};

export default connect(mapStateToProps)(ClientProfileLayout);
