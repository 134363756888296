import React, {useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {change, Field, reduxForm} from "redux-form";
import {useNavigate, useOutletContext} from "react-router-dom";
import CustomInputField from "../../../../sharedComponents/reduxfields/customInputField";
import CustomDropDownNewField from "../../../../sharedComponents/reduxfields/customDropDown";
import {emailValidation, numberValidation, required, usPhoneNumberValidation,} from "../../../../helpers/validation";
import {getNurseAction, postNurseAction,} from "../../../../services/NursesProfile";
import MultiSelectDropdown from "../../../../sharedComponents/multiSelect/index";
import {useQueryClient} from "@tanstack/react-query";
import {getStateList} from "../../../../services/commonServices";
import stringHelperFunctions from "../../../../helpers/string.helpers";
import numberHelperFunctions from "../../../../helpers/number.helpers";
import RadioOptionsComponent from "../../../../sharedComponents/radioOptions";
import SaveButton from "../../../../sharedComponents/buttons/SaveButton";
import useCustomNav from "../../../../utils/hooks/useCustomNav";
import {isAdminUser} from "../../../../helpers/apiMethods";


const NurseProfile = ({dataLoaded, initialValues, handleSubmit, pristine, submitting, submitFailed}) => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const { id: nurseId } = useParams();
    const { nurseId } = useOutletContext();

    const gendersArray = queryClient.getQueryData(['list-genders']);
    const statesArray = queryClient.getQueryData(['list-provinces-states', 233]);
    const languagesArray = queryClient.getQueryData(['list-other-languages']);
    const ethnicitiesArray = queryClient.getQueryData(['list-ethnicities']);

    // const isAdmin = window.location.pathname.includes('/admin/agencyNurses/edit');
    const isAdmin = isAdminUser();

    const { goTo: gotoAccountsPage } = useCustomNav(isAdmin ? `/admin/agency/nurses/edit/profile/personal-info/account-details` : '/agency/nurses/edit/profile/personal-info/account-details');

    const nurseProfileData = useSelector(state => state.nurseListing?.nurseProfile)

    const [isLoading, setLoading] = useState(false);
    const [loadedOnce, setLoadedOnce] = useState(false);
    const [loadOnceDone, setLoadOnceDone] = useState(false);

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [languageVal, setLanguageVal] = useState(false);
    const genderOptions = [
        {label: "Male", id: "gendermale", value: '1'},
        {label: "Female", id: "genderfemale", value: '2'},
        {label: "Other", id: "genderother", value: '3'},
    ];
    // const [selectedGenderId, setSelectedGenderId] = useState(-1);


    // const state = useSelector((state) => state.client?.stateList?.data);
    const onSubmit = async (values) => {
// console.log('test- called submit', selectedOptions)
        let payload = Object.assign({}, values);
        if (selectedOptions != null && selectedOptions.length > 0) {
            const getSelectedLangId = selectedOptions.map((res) => res.value);
            payload.otherLanguageSpokenIds = getSelectedLangId;
        }

            payload.firstName = stringHelperFunctions.nullIfEmpty(values.firstName);
            payload.lastName = stringHelperFunctions.nullIfEmpty(values.lastName);
            payload.birthDate = stringHelperFunctions.nullIfEmpty(values.birthDate);
            payload.genderId = numberHelperFunctions.nullIfEmpty(values.genderId, true);
            payload.email = stringHelperFunctions.nullIfEmpty(values.email);
            payload.phoneNumber = stringHelperFunctions.nullIfEmpty(values.phoneNumber);
            payload.drivingLicenceNumber = stringHelperFunctions.nullIfEmpty(values.drivingLicenceNumber);
            payload.dlIssuingProvinceOrStateId = numberHelperFunctions.nullIfEmpty(values.drivingLicenseIssuingProvinceOrStateId, true);
            payload.drivingIssueDate = stringHelperFunctions.nullIfEmpty(values.drivingIssueDate);
            payload.drivingExpiryDate = stringHelperFunctions.nullIfEmpty(values.drivingExpiryDate);
            payload.socialSecurityNumber = stringHelperFunctions.nullIfEmpty(values.socialSecurityNumber);
            payload.ethnicityId = numberHelperFunctions.nullIfEmpty(values.ethnicityId, true);
            try {
                const response = await dispatch(postNurseAction(payload, "personalInfo", nurseId, "Personal Info Updated Successfully"));
                if (response?.success) {
                    // navigate(isAdmin ? `/admin/agencyNurses/edit/${nurseId}/accountdetails` : `/agency/nurses/edit/${nurseId}/accountdetails`)
                    gotoAccountsPage();
                }
            } catch (error) {
                console.error(error);
            }
        // } else {
        //     setLanguageVal(true);
        // }
    };

    const getSelectedOptions = (selectedOptions) => {
        setSelectedOptions(selectedOptions);
        if (selectedOptions.length > 0) {
            setLanguageVal(false);
        } else {
            setLanguageVal(true);
        }
    };

    const defaultMultiSelectValue = (res) => {
        return res?.map((item) => {
            return {
                value: item?.id,
                label: item?.name,
            };
        });
    };

    const handleGenderChanged = async (event) => {
        let {name, value} = event.target;
        // console.log('gender value', typeof value, value);
        dispatch(change('NurseProfileForm', 'genderId', value));
        // setSelectedGenderId(+value);
    }

    useEffect(() => {
        dispatch(getNurseAction("nurseProfile", nurseId));
        setSelectedOptions(defaultMultiSelectValue(initialValues.otherLanguageSpokenIds));
    }, [loadOnceDone]);

    useEffect(() => {
        if (!loadOnceDone && nurseId != null && initialValues != null && dataLoaded) {
            setLoadOnceDone(true);
        }
    }, [dataLoaded]);

    useEffect(() => {
        if (!isLoading && loadedOnce) {
            setLoadedOnce(false);
        }
    }, [loadedOnce]);

    useEffect(() => {
        if (isLoading) {
            setLoading(false);
            setLoadedOnce(true);
        }
    }, [isLoading]);

    useEffect(() => {
        dispatch(getStateList());
        setLoading(true);
    }, [])

    useEffect(() => {
        if (submitFailed) {
            setLanguageVal(true);
        }
    }, [submitFailed]);

    useEffect(() => {
        if (nurseProfileData != null && JSON.stringify(nurseProfileData) !== '{}') {
            if (nurseProfileData.nurseLanguages != null && nurseProfileData.nurseLanguages.length > 0) {
                setSelectedOptions(nurseProfileData.nurseLanguages.map((item) => ({
                    value: item.languageId,
                    label: item.language
                })));
            }
        }
    }, [nurseProfileData]);

    return (
        <>
            <div className="card-white px-4">
                {/*<PersonalInfoInnerTabs></PersonalInfoInnerTabs>*/}
                <form className="pt-3" onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <div className="mb-4">
                                <Field
                                    className="form-control mk-wi"
                                    name="firstName"
                                    label="First Name"
                                    component={CustomInputField}
                                    requiredMark={true}
                                    validate={required}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="mb-4">
                                <Field
                                    className="form-control mk-wi"
                                    name="middleName"
                                    label="Middle Name"
                                    component={CustomInputField}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="mb-4">
                                <Field
                                    className="form-control mk-wi"
                                    name="lastName"
                                    label="Last Name"
                                    component={CustomInputField}
                                    requiredMark={true}
                                    validate={required}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="mb-4">
                                <Field
                                    className="form-control mk-wi"
                                    name="birthDate"
                                    label="Date of Birth"
                                    type="date"
                                    component={CustomInputField}
                                    requiredMark={true}
                                    validate={required}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            {/*<label>Gender <span className="mandatory-field">*</span></label>
                            <Field
                                name="genderId"
                                component={CustomRadioGroup}
                                options={genderOptions}
                                onChange={(e) => undefined}
                                requiredMark={true}
                                // validate={required}
                            />*/}
                            {/*<RadioOptionsComponent
                              label="Gender"
                              optionsList={gendersArray}
                              selectedGenderIdStr={selectedGenderId}
                              handleGenderChanged={handleGenderChanged}
                              requiredMark={true}
                            />*/}
                            {/*<Field
                              className="form-control1 mk-wi"
                              name="genderId"
                              label="Gender"
                              component={RadioOptionsComponent}
                              requiredMark={true}
                              validate={(val) => console.log('VALIDATION- value', val)}
                              optionsList={gendersArray}
                              handleGenderChanged={handleGenderChanged}
                            />*/}

                            {/* <Field
                  className="form-control"
                  name="genderId"
                  component={CustomDropDownNewField}
                  requiredMark={true}

                >
                  <option value="">Please select</option>
                  {GenderDp?.map((res, index) => {
                    return (
                      <option key={index} value={res.id}>
                        {res.name}
                      </option>
                    );
                  })}
                </Field> */}

                            <Field
                              className="form-control1 input-field-main"
                              name="genderId"
                              // label="Please select your gender"
                              label="Gender"
                              component={RadioOptionsComponent}
                              requiredMark={true}
                              // validate={(val) => console.log('VALIDATION- value', val)}
                              validate={required}
                              optionsList={gendersArray}
                              handleGenderChanged={({target}) => {
                                  if (target?.value != null) dispatch(change('NurseProfileForm', 'genderId', +target.value));
                              }}
                            />
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="mb-4">
                                <Field
                                    className="form-control mk-wi"
                                    name="email"
                                    // label="Email Address"
                                    label={<>Email address <strong className="fst-italic">&#x28;Primary Email&#x29;</strong></>}
                                    type="email"
                                    component={CustomInputField}
                                    validate={[required,emailValidation]}
                                    // validate={emailValidation}
                                    requiredMark={true}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="mb-4">
                                <Field
                                    className="form-control mk-wi"
                                    name="phoneNumber"
                                    // label="Phone Number"
                                    label={<>Phone Number <strong className="fst-italic">&#x28;Primary Phone&#x29;</strong></>}
                                    component={CustomInputField}
                                    requiredMark={true}
                                    validate={[required,usPhoneNumberValidation]}
                                    // validate={usPhoneNumberValidation}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <div className="mb-4">
                                <Field
                                    className="form-control mk-wi"
                                    name="drivingLicenceNumber"
                                    label="Driver's License Number"
                                    component={CustomInputField}
                                    // requiredMark={true}
                                    // validate={required}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="mb-4">
                                <Field
                                    className="form-control mk-wi"
                                    name="drivingIssueDate"
                                    label="Driver's License Issue Date"
                                    type="date"
                                    component={CustomInputField}
                                    // requiredMark={true}
                                    // validate={required}
                                ></Field>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="mb-4">
                                <Field
                                    className="form-control mk-wi"
                                    name="drivingExpiryDate"
                                    label="Driver's License Expiring Date"
                                    type="date"
                                    component={CustomInputField}
                                    // requiredMark={true}
                                    // validate={required}
                                ></Field>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="mb-4">
                                <Field
                                    label="Driver's License Issuing State"
                                    className="form-control"
                                    name="drivingLicenseIssuingProvinceOrStateId"
                                    component={CustomDropDownNewField}
                                    // requiredMark={true}
                                    // validate={required}
                                >
                                    <option value="">Please select</option>
                                    {(statesArray || [])?.map((res, index) => {
                                        return (
                                            <option key={index} value={res.id}>
                                                {res.name}
                                            </option>
                                        );
                                    })}
                                </Field>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="mb-4">
                                <Field
                                    className="form-control mk-wi"
                                    name="socialSecurityNumber"
                                    label="Social Security Number"
                                    component={CustomInputField}
                                    // requiredMark={true}
                                    // validate={[required,numberValidation]}
                                    validate={numberValidation}
                                ></Field>
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="mb-4">
                                <Field
                                    label="Ethnicity"
                                    className="form-control"
                                    name="ethnicityId"
                                    component={CustomDropDownNewField}
                                >
                                    <option value="">Please select</option>
                                    {ethnicitiesArray?.map((res, index) => {
                                        return (
                                            <option key={index} value={res.id}>
                                                {res.name}
                                            </option>
                                        );
                                    })}
                                </Field>
                            </div>
                        </div>
                        <div className="col-5 col-xl-4">
                            <div className="mb-4">
                                <MultiSelectDropdown
                                    dpOptions={languagesArray}
                                    getSelectedOptions={getSelectedOptions}
                                    selectedLanguages={selectedOptions}
                                    label="Other Languages Spoken (besides English)"
                                />
                                {/*{languageVal && (
                                    <span className="field-error">This field is required</span>
                                )}*/}
                            </div>
                        </div>
                        <SaveButton pristine={pristine} submitting={submitting} />
                    </div>
                </form>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    const { nurseListing: { nurseProfile = {} } } = state;

    return {
        dataLoaded: nurseProfile != null,
        initialValues: {
            firstName: nurseProfile?.nurseFirstName || "",
            lastName: nurseProfile?.nurseLastName || "",
            birthDate: nurseProfile?.nurseBirthDate || "",
            genderId: (nurseProfile?.nurseGenderId?.toString()) || "3",
            email: nurseProfile?.nursePrimaryEmail || "",
            phoneNumber: nurseProfile?.nursePrimaryPhoneNumber || "",
            drivingLicenceNumber:
                nurseProfile?.nurseCurrentDrivingLicenseNumber || "",
            drivingIssueDate:
                nurseProfile?.nurseCurrentDrivingLicenseIssueDate || "",
            drivingExpiryDate:
                nurseProfile?.nurseCurrentDrivingLicenseExpiryDate || "",
            drivingLicenseIssuingProvinceOrStateId:
                nurseProfile?.nurseCurrentDrivingLicenseIssuingStateId?.toString() || "",
            socialSecurityNumber:
                nurseProfile?.nurseSocialSecurityNumber || "",
            ethnicityId: nurseProfile?.nurseEthnicityId?.toString() || "",
            otherLanguageSpokenIds:
                (nurseProfile?.nurseLanguages || []).map((l) => ({id: l.id, name: l.language})),
        },
    };
};

export default connect(mapStateToProps)(
    reduxForm({form: "NurseProfileForm", enableReinitialize: true})(NurseProfile)
);
