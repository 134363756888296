// courtesy: https://stackoverflow.com/a/61309513
import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";

const useCustomNav = (to) => {
  const navigate = useNavigate();

  const [changeRoute, setChangeRoute] = useState(false);
  const [stateValue, setStateValue] = useState(null);

  const goTo = (state = null) => {
    if (state != null)
      setStateValue(state);
    else
      setChangeRoute(true);
  };

  useEffect(() => {
    if (changeRoute) {
      setChangeRoute(false);
      setStateValue(null);
      setTimeout(navigate, 0, to);
    }
  }, [changeRoute]);

  useEffect(() => {
// console.log('test- stateValue', stateValue);
    if (stateValue != null) {
      setTimeout(navigate, 0, to, { state: stateValue });
      setChangeRoute(false);
      setStateValue(null);
    }
  }, [stateValue]);

  return {goTo};
};

export default useCustomNav;
