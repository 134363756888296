import React, {useEffect, useRef, useState} from "react";
import {NavLink, useLocation, useNavigate, useParams} from "react-router-dom";
import Table from "../../../../sharedComponents/table/Table";
import {connect, useDispatch, useSelector} from "react-redux";
import {HttpsAction} from "../../../../commonApiFunction/httpsAction";
import Card from "@mui/material/Card";
import TableTitle from "../../Dashboard/TableTitle";
import CustomDialog from "../../../../sharedComponents/CustomDialog/index";
import PrimaryButton from "../../../../sharedComponents/buttons/PrimaryButton";
import {useReactToPrint} from "react-to-print";
import BackLink from "../../../../sharedComponents/buttons/BackLink";
import {getAgenciesData, getNursesData} from "../../../redux/actions/AdminActions";
import Pagination from "../../../../sharedComponents/pagination";
import {AGENCIES_NAV_PATHS} from "../index";

const TAB_ARRAY = [
    {
        id: 'allAgencies',
        label: 'All Agencies'
    }, {
        id: 'verifiedAgencies',
        label: 'Verified Agencies'
    }, {
        id: 'unverifiedAgencies',
        label: 'Unverified Agencies'
    },
];

const ViewAgency = ({type}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {tabId: tid} = useParams();

    const tabId = type || tid;

    const agencyListData = useSelector(state => {
        if (tabId === "allAgencies") {
            // return state.admin?.agencyList?.data
            return state.admin?.agenciesList;
        }
        if (tabId === "verifiedAgencies") {
            // return state.admin?.verifiedAgencyList?.data
            return state.admin?.verifiedAgenciesList;
        }
        if (tabId === "unverifiedAgencies") {
            // return state.admin?.unverifiedAgencyList?.data
            return state.admin?.unverifiedAgenciesList;
        }
    });

    const allAgencyListData = useSelector(state => {
        if (tabId === "allAgencies") {
            // return state.admin?.allAgencyList?.data
            return state.admin?.allAgenciesListNoPaging;
        }
        if (tabId === "verifiedAgencies") {
            // return state.admin?.allVerifiedAgencyList?.data
            return state.admin?.allVerifiedAgenciesListNoPaging;
        }
        if (tabId === "unverifiedAgencies") {
            // return state.admin?.allUnverifiedAgencyList?.data
            return state.admin?.allUnverifiedAgenciesListNoPaging;
        }
    });

    const printFormRef = useRef(null);

    const [exportModal, setExportModal] = useState(false);
    const [showBackButton, setShowBackButton] = useState(false);
    const [backLink, setBackLink] = useState('');
    const [backButtonTextSuffix, setBackButtonTextSuffix] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [dataFilters, setDataFilters] = useState({from: null, to: null, searchText: ''});

    const transformedArray = (agencyListData?.rows || [])?.map((item, index) => {
        return {
            id: item.id,
            sn: item?.id,
            name: item?.name,
            email: item?.email,
            phone: item?.phoneNumber,
            address: item?.location || "N/A",
            subscription: item?.subscriptionPlan || "N/A",
            verification: item.isVerified ? "Verified" : "Unverified",
        }
    });

    const exportTransformedArray = (allAgencyListData || [])?.map((item, index) => {
        return {
            id: item.id,
            sn: item?.id,
            name: item?.name,
            email: item?.email,
            phone: item?.phoneNumber,
            address: item?.location || "N/A",
            subscription: item?.subscriptionPlan || "N/A",
            verification: item.isVerified ? "Verified" : "Unverified",
        }
    });


    const serviceHeaderData = [
        {
            title: "S/N",
            dataIndex: "sn",
        },
        {
            title: "Name",
            dataIndex: "name",
        },
        {
            title: "Email address",
            dataIndex: "email",
        },
        {
            title: "Phone",
            dataIndex: "phone",
        },
        {
            title: "Address",
            dataIndex: "address",
        },
        // {
        //     title: "Subscription",
        //     dataIndex: "subscription",
        // },
        {
            title: "Verification",
            dataIndex: "verification",
        }
    ];

    const actionButton = [
        {
            actionName: "Edit",
            buttonAction: (id, index) => {
                if (id) {
                    // handleEditModal()
                    // navigate(`/admin/agency-profile/agencyDetails/${id}`);
                    // navigate(`/admin/agencyNurses/edit`);
                    if (tabId !== 'allAgencies') {
                        let backButtonTextSuffix = '';
                        let backLink = '';
                        backButtonTextSuffix = TAB_ARRAY.find(t => t.id === tabId).label;
                        backLink = AGENCIES_NAV_PATHS.UNVERIFIED_AGENCIES;
                        navigate(`/admin/agency/profile/details`, {
                            state: {
                                agencyId: id,
                                showBackButton: true,
                                backButtonTextSuffix,
                                backLink,
                            },
                        });
                    }
                }
            },
            actionClass: "btn-primary",
            iconClass: "fa-user-plus",
        },
        {
            actionName: tabId === "verifiedAgencies" ? "Unverify Agency" : "Verify Agency",
            buttonAction: (id, index) => {
                if (id) {
                    if (tabId === "unverifiedAgencies") {
                        dispatch(HttpsAction({
                            method: 'PUT',
                            apiSection: 'admin',
                            apiName: 'agencyVerify',
                            queryParameter: `/${id}/true`,
                            positiveCallBack: () => {
                                // getData()
                                dispatch(getAgenciesData(getApiKey(), currentPage));
                            }
                        }))
                    }
                    if (tabId === "verifiedAgencies") {
                        dispatch(HttpsAction({
                            method: 'PUT',
                            apiSection: 'admin',
                            apiName: 'agencyVerify',
                            queryParameter: `/${id}/false`,
                            positiveCallBack: () => {
                                // getData()
                                dispatch(getAgenciesData(getApiKey(), currentPage));
                            }
                        }))
                    }
                }
            },
            actionClass: "btn-primary",
            iconClass: "fa-user-plus",
        },
        // {
        //     actionName: "Delete",
        //     buttonAction: (id, index) => {
        //         dispatch(HttpsAction({
        //             method: 'DELETE',
        //             apiSection: 'admin',
        //             apiName: 'agencyTerminate',
        //             queryParameter: `/${id}`,
        //             positiveCallBack: () => {
        //                 getData()
        //             }
        //         }))
        //     },
        //     actionClass: "btn-danger",
        //     iconClass: "fa-user-plus",
        // },
    ];

    // const allAgencyActionButton = [
    //     {
    //         actionName: "Delete",
    //         buttonAction: (id, index) => {
    //             if (id) {
    //                 // handleEditModal()
    //                 // navigate(`/admin/agencyNurses/edit/${id}/profile`);
    //                 // navigate(`/admin/agencyNurses/edit`);
    //             }
    //         },
    //         actionClass: "btn-danger",
    //         iconClass: "fa-user-plus",
    //     }
    // ];

    // const handleSearch = () => {
    // }
    //
    // const getData = () => {
    //     if (tabId === "allAgencies") {
    //         dispatch(HttpsAction({
    //             apiSection: 'admin',
    //             apiName: 'agencyList',
    //             reduxKeyName: 'agencyList',
    //             reducerName: 'ADMIN',
    //             actionType: 'GET_ADMIN_AGENCY_LIST'
    //         }))
    //     }
    //     if (tabId === "verifiedAgencies") {
    //         dispatch(HttpsAction({
    //             apiSection: 'admin',
    //             apiName: 'agencyList',
    //             reduxKeyName: 'verifiedAgencyList',
    //             queryParameter: `?verified=true`,
    //             reducerName: 'ADMIN',
    //             actionType: 'GET_ADMIN_AGENCY_LIST'
    //         }))
    //     }
    //     if (tabId === "unverifiedAgencies") {
    //         dispatch(HttpsAction({
    //             apiSection: 'admin',
    //             apiName: 'agencyList',
    //             queryParameter: `?verified=false`,
    //             reduxKeyName: 'unverifiedAgencyList',
    //             reducerName: 'ADMIN',
    //             actionType: 'GET_ADMIN_AGENCY_LIST'
    //         }))
    //     }
    // };

    const handleExport = async () => {
        // if (tabId === "allAgencies") {
        //     await dispatch(HttpsAction({
        //         apiSection: 'admin',
        //         apiName: 'agencyList',
        //         reduxKeyName: 'allAgencyList',
        //         queryParameter: '?doPaging=false',
        //         reducerName: 'ADMIN',
        //         actionType: 'GET_ALL_ADMIN_AGENCY_LIST'
        //     }))
        // }
        // if (tabId === "verifiedAgencies") {
        //     await dispatch(HttpsAction({
        //         apiSection: 'admin',
        //         apiName: 'agencyList',
        //         reduxKeyName: 'allVerifiedAgencyList',
        //         queryParameter: `?verified=true&doPaging=false`,
        //         reducerName: 'ADMIN',
        //         actionType: 'GET_ALL_ADMIN_AGENCY_LIST'
        //     }))
        // }
        // if (tabId === "unverifiedAgencies") {
        //     await dispatch(HttpsAction({
        //         apiSection: 'admin',
        //         apiName: 'agencyList',
        //         queryParameter: `?verified=false&doPaging=false`,
        //         reduxKeyName: 'allUnverifiedAgencyList',
        //         reducerName: 'ADMIN',
        //         actionType: 'GET_ALL_ADMIN_AGENCY_LIST'
        //     }))
        // }
        dispatch(getAgenciesData(`${getApiKey()}-export`, 1, `doPaging=false`));
        setExportModal(true);
    };

    const handlePrint = useReactToPrint({
        content: () => printFormRef.current,
        onAfterPrint: () => {
            // Close the modal after printing or cancelling the PDF
            handleCloseFormModal()
        }
    });

    const handleCloseFormModal = () => {
        setExportModal(false)
    };

    // const handleDateFilter = ({from, to}) => {
    //     if (tabId === "allAgencies") {
    //         dispatch(HttpsAction({
    //             apiSection: 'admin',
    //             apiName: 'agencyList',
    //             reduxKeyName: 'agencyList',
    //             queryParameter: `?fromDate=${from}&toDate=${to}`,
    //             reducerName: 'ADMIN',
    //             actionType: 'GET_ADMIN_AGENCY_LIST_BY_DATE'
    //         }))
    //     }
    //     if (tabId === "verifiedAgencies") {
    //         dispatch(HttpsAction({
    //             apiSection: 'admin',
    //             apiName: 'agencyList',
    //             reduxKeyName: 'verifiedAgencyList',
    //             queryParameter: `?verified=true&fromDate=${from}&toDate=${to}`,
    //             reducerName: 'ADMIN',
    //             actionType: 'GET_ADMIN_AGENCY_LIST_BY_DATE'
    //         }))
    //     }
    //     if (tabId === "unverifiedAgencies") {
    //         dispatch(HttpsAction({
    //             apiSection: 'admin',
    //             apiName: 'agencyList',
    //             queryParameter: `?verified=false&fromDate=${from}&toDate=${to}`,
    //             reduxKeyName: 'unverifiedAgencyList',
    //             reducerName: 'ADMIN',
    //             actionType: 'GET_ADMIN_AGENCY_LIST_BY_DATE'
    //         }))
    //     }
    // };

    const handleFilters = (filters) => {
        setDataFilters(filters);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const getApiKey = () => {
        if (tabId === "allAgencies") {
            return "agencies-list";
        }
        if (tabId === "verifiedAgencies") {
            return "verified-agencies-list";
        }
        if (tabId === "unverifiedAgencies") {
            return "unverified-agencies-list";
        }
    };

    useEffect(() => {
        if (location != null && location.state != null && location.state.showBackButton) {
            setShowBackButton(location.state.showBackButton);
            setBackLink(location.state.backLink);
            setBackButtonTextSuffix(location.state.backButtonTextSuffix);
        }
    }, [location]);

    useEffect(() => {
        const {from: fromDate, to: toDate, searchText} = dataFilters;
        if (tabId != null && (searchText || (fromDate !== null && toDate !== null) || currentPage)) {
            let qsFilter = '';
            if (searchText.length > 0) {
                qsFilter += `searchText=${searchText}`;
            }
            if (fromDate != null && toDate != null) {
                if (qsFilter.length > 0) qsFilter += '&';
                qsFilter += `fromDate=${fromDate}&toDate=${toDate}`;
            }
            dispatch(getAgenciesData(getApiKey(), currentPage, qsFilter.length > 0 ? qsFilter : undefined));
        }
    }, [tabId, currentPage, dataFilters]);


    return (
        <>
            <div className="app-main__inner">
                <div className="main-dashboard-page">
                    <div className="col-12 page-back-btn">
                        {/*<BackLink link="/admin/dashboard" linkText="Dashboard" />*/}
                        <BackLink link={showBackButton && backLink ? backLink : '/admin/list/agencies'}
                                  linkText={showBackButton && backButtonTextSuffix != null ? backButtonTextSuffix : 'Agencies Overview'} />
                    </div>
                    <div className="row mb-15">

                        <TableTitle
                            title={TAB_ARRAY.find(v => v.id === tabId)?.label}
                            // onSearch={handleSearch}
                            handleExport={handleExport}
                            // handleDateFilter={handleDateFilter}
                            handleFilters={handleFilters}
                        />
                        <Card>
                            <div>
                                {/*<Table headers={serviceHeaderData} rows={transformedArray} actions={tabId === "allAgencies" ? allAgencyActionButton : actionButton}/>*/}
                                <Table headers={serviceHeaderData} rows={transformedArray} actions={tabId === "allAgencies" ? null : actionButton} />
                                <Pagination
                                  currentPage={agencyListData?.page}
                                  totalRecords={agencyListData?.total}
                                  limit={agencyListData?.pageSize}
                                  onPageChange={handlePageChange}
                                />
                            </div>
                        </Card>
                    </div>
                </div>
            </div>

            <CustomDialog
                open={exportModal && exportTransformedArray != null && exportTransformedArray.length > 0}
                onClose={() => handleCloseFormModal()}
                title={`All ${TAB_ARRAY.find(v => v.id === tabId)?.label}`}
                maxWidth={'lg'}
                actions={
                    <div className="d-flex justify-content-center" style={{marginBottom: "20px"}}>
                        <PrimaryButton
                            type="submit"
                            className="btn-view-repo gap-2 px-5 py-2"
                            text={"Export"}
                            onClick={() => handlePrint()}
                        />
                    </div>
                }>
                <div ref={printFormRef} className={'p-4'}>
                    <Table headers={serviceHeaderData} rows={exportTransformedArray}/>
                </div>
            </CustomDialog>
        </>
    )
};


// const mapStateToProps = (state, ownProps) => {
//     const { admin } = state;
//     if (ownProps?.tabId === "allAgencies") {
//         return {
//             data: admin?.nursesList,
//         };
//     }
//     if (ownProps?.tabId === "verifiedAgencies") {
//         return {
//             data: admin?.nursesList,
//         };
//     }
//     if (ownProps?.tabId === "unverifiedAgencies") {
//         return {
//             data: admin?.nursesList,
//         };
//     }
//     return {};
// };

export default ViewAgency;
// export default connect(mapStateToProps)(ViewAgency);
