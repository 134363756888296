import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {HttpsAction} from "../../../../../../../commonApiFunction/httpsAction";
import Table from "../../../../../../../sharedComponents/table/Table";
import {Stack} from "@mui/material";
import classes from "../../CareNote.module.css";
import PrimaryButton from "../../../../../../../sharedComponents/buttons/PrimaryButton";
import {useParams} from "react-router-dom";
import {getDayName} from "../TasksInformation";
import dateHelperFunctions from "../../../../../../../helpers/date.helpers";

const DailyNotes = ({tableRef, tasksList}) => {

    const {clientId, tabId} = useParams();
    const dispatch = useDispatch();

    // const careList = useSelector(state => state.client?.careList?.data || [])

    // const transformedArray = (careList?.rows?.[Number(tabId) - 1]?.tasks || [])?.map((item, index) => {
    const transformedArray = (tasksList || [])?.map((item, index) => {
        return {
            date: <Stack gap={'5px'} width={'100%'}>
                <Stack className={classes.dayName}>
                    {getDayName(item?.date)}
                </Stack>
                <Stack className={classes.date}>
                    {dateHelperFunctions.updateDateFormat(item?.date, "MM/DD/YYYY")}
                </Stack>
            </Stack>,
            // nurse: <Stack className={classes.overflowEllipsis} width={'100%'}>
            //     {careList?.rows?.[Number(tabId) - 1]?.patientFirstName},
            //     {careList?.rows?.[Number(tabId) - 1]?.patientLastName}
            // </Stack>,
            nurse: <Stack className={classes.overflowEllipsis} width={'100%'}>{item?.assignedNurseFirstName || 'N/A'}</Stack>,
            clock: <Stack width={'100%'}> {dateHelperFunctions.convertTo12HourFormat(item?.clockIn)} - {dateHelperFunctions.convertTo12HourFormat(item?.clockOut)} </Stack>,
            notes: <Stack className={classes.dailyNotesBox}> {item?.notes} </Stack>
        }
    });

    const serviceHeaderData = [
        {
            title: "Date",
            dataIndex: "date",
        },
        {
            title: "Nurse",
            dataIndex: "nurse",
        },
        {
            title: <Stack className={classes.overflowEllipsis}> Clock In/Clock Out </Stack>,
            dataIndex: "clock",
        },
        {
            title: "Notes",
            dataIndex: "notes",
        },
    ];

    return (
        <>
            <Table headers={serviceHeaderData} rows={transformedArray}/>
            {/*<div*/}
            {/*    className="d-flex justify-content-center"*/}
            {/*    style={{marginTop: "40px"}}>*/}
            {/*    <PrimaryButton*/}
            {/*        type="submit"*/}
            {/*        className="btn-view-repo gap-2 px-5 py-2"*/}
            {/*        text={"Save"}*/}
            {/*        onClick={() => undefined}*/}
            {/*    />*/}
            {/*</div>*/}
        </>
    )
};
export default DailyNotes;
