
export function scrollToErrors() {
  const errorElements = document.querySelectorAll('.field-error');
  if (errorElements.length > 0) errorElements[0].parentElement.previousElementSibling.scrollIntoView({block: "start", inline: "nearest"});
}

export const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;
});

export const dataURLtoFile = (dataurl, filename) => {
  // console.log('dataurl type', typeof dataurl, dataurl)
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};
