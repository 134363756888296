import React, { useEffect, useState } from "react";
import { Avatar, Rating } from "@mui/material";
import { NavLink, useNavigate, useParams } from "react-router-dom";

import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { makeAnOfferAction } from "../../../services/messages";
import { toastr } from "react-redux-toastr";
import {parseTokenAndGetData} from "../../../helpers/apiMethods";

const FindNurseCard = ({ nurse }) => {
  // console.log('nurse-data', nurse)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // let skills = nurse?.details?.accountInfo?.skills || [];
  let skills = nurse?.preferredSkills || [];
  let lastLoginDateTime = nurse?.details?.lastLoginExpiryDateTime || null;
  let isNurseOnline = nurse?.details?.isOnline || false;
  let isCurrentlyOnline = isNurseOnline || lastLoginDateTime != null && new Date(Date.parse(lastLoginDateTime)) > new Date();
// console.log('test- is online', nurse.nurseFirstLastName, lastLoginDateTime, (lastLoginDateTime != null ? new Date(Date.parse(lastLoginDateTime)) : 'NO'));

  const [modalVisible, setModalVisible] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState({});
  const [displayName, setDisplayName] = useState('');

  // console.log("authUserInstance", authUserInstance)
  // function to show the modal
  const showModal = () => {
    setModalVisible(true);
  };

  // function to hide the modal
  const closeModal = () => {
    setModalVisible(false);
  };

  // function handlers to be implemented
  const handleOptionSelect = (selectedRateType) => {
// alert(JSON.stringify(selectedRateType))
    setSelectedOffer(selectedRateType);
    // setSelectedOffer(prevState => ({...prevState, selectedRateType}));
  };

  const goToUserChatBox = () => {
    navigate("/agency/messages", {
      state: {
        id: nurse.nurseMinimalInfoWithAddress?.nursePersonalInformationId,
        firstName: nurse.nurseMinimalInfoWithAddress?.nurseFirstName,
        lastName: nurse.nurseMinimalInfoWithAddress?.nurseLastName,
        isOnline: nurse.details?.isOnline,
      },
    });
  };

  // function handler to be implemented
  const handleMakeOfferButton = () => {
    // console.log('selected-offer', selectedOffer);
    if (Object.keys(selectedOffer).length > 0) {
      if (selectedOffer.amount != null && selectedOffer.rateTypeId != null) {
        dispatch(makeAnOfferAction("makeAnOffer", {
          amount: typeof selectedOffer.amount === 'string' ? parseInt(selectedOffer.amount) : selectedOffer.amount,
          rateTypeId: typeof selectedOffer.rateTypeId === 'string' ? parseInt(selectedOffer.rateTypeId) : selectedOffer.rateTypeId,
        }, nurse?.nurseId, function (res) {
          if (res.success) {
            goToUserChatBox();
          } else {
            toastr.error(res.message, "");
          }
        }));
      } else {
        toastr.error("Invalid rate or amount", "");
      }
    } else {
      toastr.error("Please select the offer", "");
    }
  };

  useEffect(() => {
    const decodedTokenData = parseTokenAndGetData().data;
    setDisplayName(`${decodedTokenData.firstName} ${decodedTokenData.lastName}`);
  }, []);

  return (
    <div className="">
      <div className="d-flex align-items-xl-center flex-column flex-xl-row gap-4 gap-xl-0 justify-content-between border-bottom px-4 pt-4 pb-3">
        <div className="d-flex avatar-content  gap-4">
          <div>
            <Avatar
              alt={nurse?.nursePersonalInfo?.nurseFirstName?.toUpperCase()}
              src="/static/images/avatar/1.jpg"
              sx={{ width: 78, height: 78 }}
            />
          </div>
          <div className="nurse-name">
            <NavLink to={`/nurse/detail/${nurse?.nurseId}`} state={{
              showBackButton: true,
              backButtonTextSuffix: 'Find Nurses',
              backLink: '/agency/find-nurses',
            }}>
              <h6 className="mb-0">{nurse?.nurseFirstLastName}</h6>
            </NavLink>

            <p className="mb-0">
              {nurse?.preferredClasses?.[0]?.preferredClass || 'UNKNOWN'}
            </p>
            <span className="d-flex align-items-center gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M14.7142 13.8807C13.9867 14.6083 12.5191 16.0758 11.4136 17.1813C10.6325 17.9624 9.36775 17.9623 8.5867 17.1813C7.50109 16.0957 6.06062 14.6552 5.28612 13.8807C2.68262 11.2772 2.68262 7.05612 5.28612 4.45262C7.88961 1.84913 12.1107 1.84913 14.7142 4.45262C17.3177 7.05612 17.3177 11.2772 14.7142 13.8807Z"
                  stroke="#7C7C7C"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.5002 9.16667C12.5002 10.5474 11.3809 11.6667 10.0002 11.6667C8.61945 11.6667 7.50016 10.5474 7.50016 9.16667C7.50016 7.78596 8.61945 6.66667 10.0002 6.66667C11.3809 6.66667 12.5002 7.78596 12.5002 9.16667Z"
                  stroke="#7C7C7C"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>{" "}
              <span>
                {nurse?.nurseMailingAddress?.nurseAddressLine1 || "N/A"}
              </span>
            </span>
          </div>
        </div>
        <div className="hire-dropdown-group">
          <button onClick={showModal} className="hire-btn">
            Hire
          </button>
          {modalVisible && (
            <div className="hire-dropdown-outer">
              <div className="hire-dropdown">
              {nurse?.preferredRatesList?.map((preference, index) => (
                <button
                  className={`modal-option${
                    selectedOffer.rateTypeId === preference.jobPayRateTypeId
                      ? " modal-option-selected"
                      : ""
                  }${preference.rate == null ? ' no-pointer-events' : ''}`}
                  onClick={() =>
                    handleOptionSelect({
                      rateTypeId: preference.jobPayRateTypeId,
                      amount: preference.rate,
                      // nurseName: nurse.nurseFirstLastName,
                      // agencyName: displayName,
                    })
                  }
                  key={index}
                >
                  {`${preference?.jobPayRateType} - ${
                    preference?.rate ? `$${preference?.rate}` : "N/A"
                  }`}
                </button>
              ))}

              <div className="action-btn-group mt-3 d-flex align-items-center gap-2">
                <button
                  className="modal-action-1"
                  onClick={() => handleMakeOfferButton()}
                >
                  Make an Offer
                </button>
                <button className="modal-action-2" onClick={closeModal}>
                  Cancel
                </button>
              </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="d-flex align-items-stretch gap-4 gap-xl-0 flex-column flex-xl-row justify-content-between px-4 pt-4 pb-3">
        <div className="d-flex align-items-center gap-4 ">
          <ul className="d-flex flex-column gap-3 title-ul p-0 m-0">
            <li className="d-flex gap-3 align-items-start">
              <div className="first-child1">Specialties</div>
              <div className="second-child2">
                {skills.length > 0
                  ? skills.map((skill, index) => (
                      <span key={skill.preferredSkillId}>
                        {skill.preferredSkill}
                        {index < skills.length - 1 && ", "}
                      </span>
                    ))
                  : "No skills available"}
              </div>
            </li>
            <li className="d-flex gap-3 align-items-start">
              <div className="first-child1">Preferred Job Area</div>
              <div className="second-child2">
                {nurse?.preferredLocation || "-"}
              </div>
            </li>
            <li className="d-flex gap-3 align-items-start">
              <div className="first-child1">Employment type</div>
              <div className="second-child2">
                <div className="prise-chip-group d-flex flex-wrap gap-1">
                  {nurse?.preferredRatesList &&
                    nurse?.preferredRatesList.map((preferredRate, index) => (
                      <div
                        className="perise-chip"
                        key={preferredRate.jobPayRateTypeId}
                      >
                        {`${preferredRate?.jobPayRateType} - ${
                          preferredRate?.rate
                            ? `$${preferredRate?.rate}`
                            : "N/A"
                        }`}
                      </div>
                    ))}
                </div>
              </div>
            </li>
            <li className="d-flex gap-3 align-items-start">
              <div className="first-child1">Ratings</div>
              <div className="second-child2">
                <Rating
                  className="mt-0 gap-2"
                  value={nurse?.details?.nurseRatingRounded || 0}
                  defaultValue={0}
                  size="small"
                  readOnly
                />
              </div>
            </li>
          </ul>
          {/* <ul className="d-flex flex-column gap-3 content-ul p-0 m-0"> */}
          {/* <li>
              {nurse.skills
                ? nurse.skills.map((skill, index) => (
                    <span key={skill.id}>
                      {skill.skill}
                      {index < nurse.skills.length - 1 && ", "}
                    </span>
                  ))
                : "No skills available"}
            </li> */}
          {/* <li>
              {nurse?.nurseJobPreferences?.preferredJobProvinceOrState || "-"}
            </li> */}
          {/* 
            <li className="d-flex flex-wrap gap-1">
              {nurse?.preferredRatesList &&
                nurse?.preferredRatesList.map((preferredRate, index) => (
                  <span
                    className="perise-chip"
                    key={preferredRate.jobPayRateTypeId}
                  >
                    {`${preferredRate?.jobPayRateType} - ${
                      preferredRate?.rate ? `$${preferredRate?.rate}` : "N/A"
                    }`}
                  </span>
                ))} */}
          {/* {nurse?.nurseJobPreferences?.preferredJobPayRateType && <span className="perise-chip">
                {nurse?.nurseJobPreferences?.preferredJobPayRateType}
              </span> || "-"
              } */}
          {/* {nurse?.preferredPerHourRate && <span className="perise-chip">
                {nurse?.preferredJobPayRateType}
                Per Diem - $30
              </span>
              }
              {nurse?.preferredPerVisitRate && <span className="perise-chip">
                {nurse?.preferredJobPayRateType}
              </span>
              } */}
          {/* </li> */}
          {/* <li>
              <Rating
                className="mt-0 gap-2"
                defaultValue={nurse?.nurseRating}
                size="small"
                readOnly
              />
            </li> */}
          {/* </ul> */}
        </div>
        <div className="d-flex align-items-center flex-wrap gap-2 align-items-xl-end  flex-xl-column justify-content-between mt-3 mt-md-0">
          <div>
            <button className="send-btn" style={{minWidth: '99px'}} onClick={() => goToUserChatBox()}>
              Chat{" "}
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M15.5483 0.465586C15.1481 0.0548785 14.5559 -0.0981303 14.0037 0.0629316L1.1264 3.80762C0.543759 3.96949 0.13079 4.43415 0.0195441 5.02444C-0.0941024 5.6252 0.30286 6.38783 0.821472 6.70673L4.84792 9.18145C5.26089 9.43512 5.79391 9.3715 6.13565 9.02683L10.7463 4.38744C10.9784 4.14585 11.3626 4.14585 11.5947 4.38744C11.8268 4.62098 11.8268 4.99948 11.5947 5.24107L6.97599 9.88126C6.63345 10.2251 6.56943 10.7607 6.82153 11.1762L9.28174 15.243C9.56985 15.7262 10.0661 16 10.6103 16C10.6743 16 10.7463 16 10.8104 15.9919C11.4346 15.9114 11.9308 15.4846 12.1149 14.8806L15.9325 2.01983C16.1005 1.47222 15.9485 0.876294 15.5483 0.465586Z"
                    fill="#109CF1"
                  />
                </svg>
              </span>
            </button>
          </div>
          <p className="hours-message">
            {/* {nurse?.lastLoginExpiryDateTime && nurse?.lastLoginExpiryDateTime > new Date()
              ? <>
                <div className="round-dott"></div>
                <p>Online</p>
              </>
              : nurse?.lastLoginExpiryDateTime
                ? `Active ${moment().diff(moment(nurse?.lastLoginExpiryDateTime), 'hours')} hours ago`
                : ''
            } */}
            <p className="hours-message">
              {/* {`Active ${moment('2023-10-23T13:24:47.000Z').fromNow(true)} ago`} */}
              {/*{lastLoginDateTime != null && <>
                {isCurrentlyOnline ? (
                  <>
                    <div className="round-dott"></div>
                    <p>Online</p>
                  </>
                ) : (
                  `Active ${moment(lastLoginDateTime).fromNow(
                    true
                  )} ago`
                )}
              </>}*/}
              {isNurseOnline && <>
                <div className="round-dott"></div>
                <p>Online</p>
              </>}
              {/*{!isNurseOnline && lastLoginDateTime == null && <>{" "}</>}*/}
              {/*{!isNurseOnline && lastLoginDateTime != null && <>*/}
              {/*  {`Active ${moment(lastLoginDateTime).fromNow(*/}
              {/*    true*/}
              {/*  )} ago`}*/}
              {/*</>}*/}
            </p>
          </p>
        </div>
      </div>
    </div>
  );
};

export default FindNurseCard;
