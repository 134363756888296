import React, {useEffect, useState} from "react";
import {Stack} from "@mui/material";
import {Field, reduxForm} from "redux-form";
import CustomDropDownField from "../../../../sharedComponents/reduxfields/customDropDown";
import {connect, useDispatch} from "react-redux";
import classes from "../TaskDetails.module.css"
import CustomInputField from "../../../../sharedComponents/reduxfields/customInputField";
import CustomRadioGroup from "../../../../sharedComponents/reduxfields/customRadioGroup";
import PrimaryButton from "../../../../sharedComponents/buttons/PrimaryButton";
import {useNavigate} from "react-router-dom";
import {HttpsAction} from "../../../../commonApiFunction/httpsAction";
import {required} from "../../../../helpers/validation";
import dateHelperFunctions from "../../../../helpers/date.helpers";

const billingRadioOptions = [
    {label: 'Per Hour', id: "perHour", value: '1'},
    {label: 'Per Visit', id: "perVisit", value: '2'},
    {label: 'Per Day', id: "perDay", value: '3'},
];
const payableRadioOptions = [
    {label: 'Per Hour', id: "perHour", value: '1'},
    {label: 'Per Visit', id: "perVisit", value: '2'},
    {label: 'Per Day', id: "perDay", value: '3'},
];
const visitRadioOptions = [
    {label: 'No', id: "no", value: 'false'},
    {label: 'Yes', id: "yes", value: 'true'}
];

const Financial = ({show, handleSubmit, handleSelectTab, initialValues}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [servicesList, setServicesList] = useState([]);
    const [alreadyClockedIn, setAlreadyClockedIn] = useState(false);

    useEffect(() => {
        dispatch(HttpsAction({
            apiSection: 'combos',
            apiName: 'serviceRequestTypesList',
            positiveCallBack: ({data}) => {
                setServicesList(data?.data);
            }
        }));
    }, []);

    const onSubmit = () => {
        // navigate(`/service-details/careNotes`)
        handleSelectTab('', 2)
    };

    useEffect(() => {
        if (initialValues != null && JSON.stringify(initialValues) !== '{}') {
            if (initialValues.clockIn != null && initialValues.clockIn.length > 0) {
                setAlreadyClockedIn(true);
            }
        }
    }, [initialValues]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} style={{display: show ? 'block' : 'none'}}>
                <Stack margin={'15px 0 0 0 '}>
                    <div className={'row'}>
                        <div className={'col-12 mb-4'}>
                            <div className="input-field-main">
                                <label>Service </label>
                                <Field
                                    className="form-control mk-wi read-only"
                                    name="serviceId"
                                    component={CustomDropDownField}
                                >
                                    <option value="">Please Select</option>
                                    {servicesList?.map((res, index)=>{
                                        return <option key={index} value={res.id}>{res.name}</option>
                                    })}
                                </Field>
                            </div>
                        </div>
                        <div className={`col-12 mb-3`}>
                            <Stack className={classes.blueLine}>
                                BILLABLE
                            </Stack>
                        </div>
                        <div className={`custom-col-2-rt`}>
                            <div className="mb-2 input-field-main">
                                <Field
                                    className="form-control mk-wi"
                                    name="regular1"
                                    label="Regular Hours/Mins"
                                    type="text"
                                    component={CustomInputField}
                                    readOnly={true}
                                />
                            </div>
                        </div>
                        <div className={`custom-col-2-ot`}>
                            <div className="mb-2 input-field-main">
                                <Field
                                    className="form-control mk-wi"
                                    name="OverTime1"
                                    label="Over Time Hours/Mins"
                                    type="text"
                                    component={CustomInputField}
                                    readOnly={true}
                                />
                            </div>
                        </div>
                        <div className={`custom-col-2-notr`}>
                            <div className="mb-2 input-field-main">
                                <Field
                                    className="form-control mk-wi"
                                    name="totalHours1"
                                    label="Total Hours/Mins"
                                    type="text"
                                    component={CustomInputField}
                                    readOnly={true}
                                />
                            </div>
                        </div>
                        <div className={`custom-col-1-artc`}>
                            <div className="mb-2 input-field-main">
                                <Field
                                    className="form-control mk-wi"
                                    name="agencyRateToClient"
                                    label="Rate"
                                    type="number"
                                    component={CustomInputField}
                                    readOnly={true}
                                    // readOnly={alreadyClockedIn}
                                    requiredMark={true}
                                    validate={required}
                                    currencySymbol={initialValues.currencySymbol}
                                />
                            </div>
                        </div>
                        <div className={`custom-col-2-notr`}>
                            <div className="mb-2 input-field-main">
                                <Field
                                    className="form-control mk-wi"
                                    name="nurseOvertimeRate"
                                    label="Over Time rate"
                                    type="number"
                                    component={CustomInputField}
                                    readOnly={true}
                                    // readOnly={alreadyClockedIn}
                                />
                            </div>
                        </div>
                        <div className={`col-12`}>
                            <div className="mb-3 input-field-main">
                                <Field
                                    name="payRateTypeId"
                                    component={CustomRadioGroup}
                                    options={billingRadioOptions}
                                    onChange={(e) => undefined}
                                    readOnly={true}
                                />
                            </div>
                        </div>
                        {/*<div className={`col-12`}>
                            <div className="mb-3 input-field-main">
                                <label style={{fontWeight: 600}}> Bill for the Visit: </label>
                                <Field
                                    name="doNotBillClient"
                                    component={CustomRadioGroup}
                                    options={visitRadioOptions}
                                    onChange={(e) => undefined}
                                />
                            </div>
                        </div>*/}
                        <div className={`col-12 mt-4 mb-3`}>
                            <Stack className={classes.blueLine}>
                                PAYABLE
                            </Stack>
                        </div>
                        <div className={`custom-col-2-ot`}>
                            <div className="mb-2 input-field-main">
                                <Field
                                    className="form-control mk-wi"
                                    name="regular2"
                                    label="Regular Hours/Mins"
                                    type="text"
                                    component={CustomInputField}
                                    readOnly={true}
                                />
                            </div>
                        </div>
                        <div className={`custom-col-2-ot`}>
                            <div className="mb-2 input-field-main">
                                <Field
                                    className="form-control mk-wi"
                                    name="OverTime2"
                                    label="Over Time Hours/Mins"
                                    type="text"
                                    component={CustomInputField}
                                    readOnly={true}
                                />
                            </div>
                        </div>
                        <div className={`custom-col-2-notr`}>
                            <div className="mb-2 input-field-main">
                                <Field
                                    className="form-control mk-wi"
                                    name="totalHours2"
                                    label="Total Hours/Mins"
                                    type="text"
                                    component={CustomInputField}
                                    readOnly={true}
                                />
                            </div>
                        </div>
                        <div className={`custom-col-1-artc`}>
                            <div className="mb-2 input-field-main">
                                <Field
                                    className="form-control mk-wi"
                                    name="nurseRate"
                                    label="Rate"
                                    type="number"
                                    component={CustomInputField}
                                    readOnly={true}
                                    // readOnly={alreadyClockedIn}
                                    requiredMark={true}
                                    validate={required}
                                    currencySymbol={initialValues.currencySymbol}
                                />
                            </div>
                        </div>
                        <div className={`custom-col-2-notr`}>
                            <div className="mb-2 input-field-main">
                                <Field
                                    className="form-control mk-wi"
                                    name="nurseOvertimeRate1"
                                    label="Over Time rate"
                                    type="number"
                                    component={CustomInputField}
                                    readOnly={true}
                                    // readOnly={alreadyClockedIn}
                                />
                            </div>
                        </div>
                        <div className={`col-12`}>
                            <div className="mb-0 input-field-main">
                                <Field
                                    name="payRateTypeId1"
                                    component={CustomRadioGroup}
                                    options={payableRadioOptions}
                                    onChange={(e) => undefined}
                                    readOnly={true}
                                />
                            </div>
                        </div>
                    </div>
                </Stack>
                {/*<div
                    className="d-flex justify-content-center"
                    style={{margin: "40px 0 40px 0"}}>
                    <PrimaryButton
                        type="submit"
                        className="btn-view-repo gap-2 px-5 py-2"
                        text={"Save"}
                        onClick={() => undefined}
                    />
                </div>*/}
            </form>
        </>
    )
};

const mapStateToProps = (state, ownProps) => {
    // const data = state.client?.taskDetails?.data;
    const data = ownProps?.taskDetails;
    return {
        initialValues: {
            serviceId: data?.serviceRequestTypeId?.toString() || "",
            agencyRateToClient: data?.agencyRateToClient?.toString() || "",
            nurseRate: data?.nurseRate?.toString() || "",
            nurseOvertimeRate: data?.nurseOvertimeRate?.toString() || "",
            nurseOvertimeRate1: data?.nurseOvertimeRate?.toString() || "",
            payRateTypeId: data?.payRateTypeId?.toString() || "",
            payRateTypeId1: data?.payRateTypeId?.toString() || "",
            doNotBillClient: (!data?.doNotBillClient)?.toString() || "",
            // regular1: data?.withinScheduledHoursWorked?.toString() || "",
            regular1: dateHelperFunctions.hoursAndMinutesToText(data?.totalScheduledHours, data?.totalScheduledMinutesLessHours),
            // regular2: data?.withinScheduledHoursWorked?.toString() || "",
            regular2: dateHelperFunctions.hoursAndMinutesToText(data?.totalScheduledHours, data?.totalScheduledMinutesLessHours),
            // OverTime1: data?.overtimeTotalHoursWorked?.toString() || "",
            OverTime1: dateHelperFunctions.hoursAndMinutesToText(data?.overtimeTotalHoursWorked, data?.overtimeTotalMinutesLessHoursWorked),
            // OverTime2: data?.overtimeTotalHoursWorked?.toString() || "",
            OverTime2: dateHelperFunctions.hoursAndMinutesToText(data?.overtimeTotalHoursWorked, data?.overtimeTotalMinutesLessHoursWorked),
            // totalHours1: data?.totalHoursWorked?.toString() || "",
            totalHours1: dateHelperFunctions.hoursAndMinutesToText(data?.totalHoursWorked, data?.totalMinutesWorkedLessHours),
            // totalHours2: data?.totalHoursWorked?.toString() || "",
            totalHours2: dateHelperFunctions.hoursAndMinutesToText(data?.totalHoursWorked, data?.totalMinutesWorkedLessHours),
            clockIn: data?.clockIn || "",
            currencySymbol: data?.currencySymbol || '',
        }
    }
}

export default connect(mapStateToProps)(
    reduxForm({form: "financial", enableReinitialize: true})(Financial)
);
