import React, {useState} from "react";
import classes from "../CareNote/CareNote.module.css";
import {Stack} from "@mui/material";
import HealthcareInformation from "./HealthcareInformation";
import Allergies from "./Allergies";
import ExtraInformation from "./ExtraInformation";
import Medications from "./Medications";

const Healthcare = () => {

    const handleSelectTab = (index) => {
        setTabs(prevState => prevState.map((v, i) => {
            return i === index ? {...v, select: true} : {...v, select: false}
        }))
    }

    const HEALTHCARE_TABS_ARRAY = [
        {
            name: 'Basic Information',
            id: 'healthcareInformation',
            select: true,
            component: <HealthcareInformation handleSelectTab={handleSelectTab}/>
        }, {
            name: 'Extra Information',
            id: 'extraInformation',
            select: false,
            component: <ExtraInformation handleSelectTab={handleSelectTab}/>
        }, {
            name: 'Medications',
            id: 'medications',
            select: false,
            component: <Medications handleSelectTab={handleSelectTab}/>
        }, {
            name: 'Allergies',
            id: 'allergies',
            select: false,
            component: <Allergies handleSelectTab={handleSelectTab}/>
        }
    ]

    const [tabs, setTabs] = useState(HEALTHCARE_TABS_ARRAY);



    return (
        <>
            <div className={classes.contactMain}>
                <div className={classes.contactFormMain}>
                    <div className="d-flex gap-2">
                        {(tabs || []).map((value, index) => (
                            <button type="button" key={index}
                                    className={`btn ${value.select === true && "btn-primary"} btn-sm`}
                                    onClick={() => handleSelectTab(index)}>
                                {value.name}
                            </button>
                        ))}
                    </div>
                    <Stack sx={{marginTop: '50px'}}>
                        {(tabs || []).map((v, idx) => v.select === true && <React.Fragment key={idx}>{v?.component}</React.Fragment>)}
                    </Stack>
                </div>
            </div>
        </>
    )
};
export default Healthcare;
