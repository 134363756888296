import React, {useEffect, useState} from "react";
import PrimaryButton from "../../../../../sharedComponents/buttons/PrimaryButton";
import plusIcon from "../../../../assets/image/plus-white-bg.svg"
import {Dialog, Stack} from "@mui/material";
import classes from "../../../TaskDetails/_assets/css/calendar.module.css"
import CustomInputField from "../../../../../sharedComponents/reduxfields/customInputField";
import {Field, reduxForm} from "redux-form";
import {connect, useDispatch, useSelector} from "react-redux";
import Calendar from "../../../../../sharedComponents/calendar";
import {HttpsAction} from "../../../../../commonApiFunction/httpsAction";
import TaskDetails from "../../../TaskDetails";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import dateHelperFunctions from "../../../../../helpers/date.helpers";
import useCustomNav from "../../../../../utils/hooks/useCustomNav";

export const INDICATOR_COLOR = [
    {
        "id": 1,
        "name": "Pending",
        "bgColor": "#F08C32"
    },
    {
        "id": 2,
        "name": "Scheduled",
        "bgColor": "#56AFFF"
    },
    {
        "id": 3,
        "name": "Active",
        "bgColor": "#9957B5"
    },
    {
        "id": 4,
        "name": "Completed",
        "bgColor": "#4CAF50"
    },
    {
        "id": 5,
        "name": "Cancelled/Missed",
        "bgColor": "#354863"
    }
]

export const MONTHS = [
    {
        month: 'January',
        id: 1
    }, {
        month: 'February',
        id: 2
    }, {
        month: 'March',
        id: 3
    }, {
        month: 'April',
        id: 4
    }, {
        month: 'May',
        id: 5
    }, {
        month: 'June',
        id: 6
    }, {
        month: 'July',
        id: 7
    }, {
        month: 'August',
        id: 8
    }, {
        month: 'September',
        id: 9
    }, {
        month: 'October',
        id: 10
    }, {
        month: 'November',
        id: 11
    }, {
        month: 'December',
        id: 12
    }
]

const Calender = ({handleSubmit, taskDetails}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const {clientId} = useParams();
    const { clientId } = useOutletContext();

    const { goTo: gotoNewServiceRequestPage } = useCustomNav('/agency/new-service-request');

    // const isServiceDetails = useSelector(state => state.client?.isServiceDetails || false);
    const eventList = useSelector(state => state.client?.calendarEvent?.data);
    const serviceRequestStatusData = useSelector(state => state.client?.serviceRequestStatus?.data);
// console.log({serviceRequestStatusData})
    const [serviceDetails, setServiceDetails] = useState({modal: false, id: null});
    const [allEventList, setAllEventList] = useState([]);
    const [serviceRequestStatus, setServiceRequestStatus] = useState([]);
    // const [serviceRequestStatus, setServiceRequestStatus] = useState(INDICATOR_COLOR.map(e => ({
    //     ...e,
    //     select: false
    // })));
    const [showTaskDetails, setShowTaskDetails] = useState(false);

    const MoreIcon = (color = '#9957B5') =>
        <svg style={{cursor: 'pointer'}} width="14" height="14" viewBox="0 0 14 14" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.70956 10.0392C6.99927 10.0392 7.27712 10.1543 7.48198 10.3591C7.68684 10.564 7.80193 10.8418 7.80193 11.1315C7.80193 11.4212 7.68684 11.6991 7.48198 11.904C7.27712 12.1088 6.99927 12.2239 6.70956 12.2239C6.41984 12.2239 6.14199 12.1088 5.93714 11.904C5.73228 11.6991 5.61719 11.4212 5.61719 11.1315C5.61719 10.8418 5.73228 10.564 5.93714 10.3591C6.14199 10.1543 6.41984 10.0392 6.70956 10.0392ZM6.70956 6.21587C6.99927 6.21587 7.27712 6.33096 7.48198 6.53582C7.68684 6.74068 7.80193 7.01853 7.80193 7.30824C7.80193 7.59796 7.68684 7.8758 7.48198 8.08066C7.27712 8.28552 6.99927 8.40061 6.70956 8.40061C6.41984 8.40061 6.14199 8.28552 5.93714 8.08066C5.73228 7.8758 5.61719 7.59796 5.61719 7.30824C5.61719 7.01853 5.73228 6.74068 5.93714 6.53582C6.14199 6.33096 6.41984 6.21587 6.70956 6.21587ZM6.70956 2.39258C6.99927 2.39258 7.27712 2.50767 7.48198 2.71253C7.68684 2.91738 7.80193 3.19523 7.80193 3.48495C7.80193 3.77466 7.68684 4.05251 7.48198 4.25737C7.27712 4.46223 6.99927 4.57732 6.70956 4.57732C6.41984 4.57732 6.14199 4.46223 5.93714 4.25737C5.73228 4.05251 5.61719 3.77466 5.61719 3.48495C5.61719 3.19523 5.73228 2.91738 5.93714 2.71253C6.14199 2.50767 6.41984 2.39258 6.70956 2.39258Z"
                fill={color}/>
        </svg>

    const handleMonthChange = (month, year) => {
        const toolbarTitle = document.getElementsByClassName(`fc-toolbar-title`)
        const title = toolbarTitle[0].outerText.split(" ");

        dispatch(HttpsAction({
            apiSection: 'client',
            apiName: 'serviceRequestTasks',
            queryParameter: `?patientId=${clientId}&calYear=${title[1]}&calMonth=${MONTHS.find(v => v.month === title[0]).id}&doPaging=false`,
            reducerName: 'CLIENT',
            actionType: 'GET_CALENDAR_SERVICE_REQUEST_TASKS',
            reduxKeyName: 'calendarEvent'
        }))
    };

    const onSubmit = () => {

    }

    const handleServiceDetailsModal = (id) => {
        // dispatch({
        //     type: `ADD_CLIENT_REMOVE_TASK_DETAILS_DATA`,
        //     payload: {},
        //     name: `taskDetails`,
        //     meta: {type: `REMOVE_TASK_DETAILS_DATA`},
        // });
        // if (!serviceDetails.modal) {
        //     dispatch(HttpsAction({
        //         apiSection: 'client',
        //         apiName: 'taskDetails',
        //         queryParameter: `/${id}`,
        //         actionType: `GET_TASK_DETAILS`,
        //         reduxKeyName: 'taskDetails',
        //         reducerName: 'CLIENT',
        //     }));
        // }
        // dispatch({
        //     type: `ADD_CLIENT_UPDATE_SERVICE_DETAILS_MODAL_FLAG`,
        //     payload: !isServiceDetails,
        //     name: `isServiceDetails`,
        //     meta: {type: `UPDATE_SERVICE_DETAILS_MODAL_FLAG`},
        // });
        if (id) {
            dispatch(HttpsAction({
                apiSection: 'serviceRequests',
                apiName: 'taskDetails',
                queryParameter: `/${id}`,
                actionType: `GET_TASK_DETAILS`,
                reduxKeyName: 'taskDetails',
                reducerName: 'SERVICE_REQUEST',
            }));
        }
        setServiceDetails(prevState => ({...prevState, modal: !prevState.modal}))
    }

    const renderEventContent = (eventInfo) => {
        const event = eventInfo.event.extendedProps;
        const color = INDICATOR_COLOR.find((v) => v.id === event.taskStatusId).bgColor;
        //${event.assignedNurseId != null ? ' border-end' : ''}
        return (
            <>
                <Stack className={`${classes.customEvent}`} onClick={() => handleServiceDetailsModal(event?.taskId)}>
                    <Stack className={`${classes.colorLine}`} sx={{backgroundColor: color}} style={{minWidth: '3px'}}/>
                    <Stack width={'100%'} paddingY={'10px'} paddingX={event.assignedNurseId != null ? '5px' : '10px'}>
                        <Stack className={classes.moreIcon}>
                            {MoreIcon(color)}
                        </Stack>
                        <Stack gap={event.clockIn != null ? '0' : '3px'} marginTop={event.clockIn != null ? '-2px' : '0'}>
                            {event.clockIn != null && <Stack className={classes.time}>
                                {`${dateHelperFunctions.convertTo12HourFormat(event.clockIn)} - ${event.clockOut != null ? dateHelperFunctions.convertTo12HourFormat(event.clockOut) : 'N/A'}`}
                            </Stack>}
                            <Stack className={classes.eventName}>
                                {event.serviceRequestType}
                            </Stack>
                            <Stack className={classes.name} title={`Client name = ${event.patientFirstLastName}`}>
                                {event.patientFirstLastName}
                            </Stack>
                            {event.assignedNurseId != null && <Stack className={classes.name} title={`Nurse name = ${event.assignedNurseFirstLastName}`}>
                                {event.assignedNurseFirstLastName}
                            </Stack>}
                        </Stack>
                    </Stack>
                </Stack>
            </>
        );
    };

    const handleSelectServiceRequestType = (id, index) => {
        setServiceRequestStatus(prevState => prevState.map((e, i) => {
            return i === index ? {...e, select: true} : {...e, select: false}
        }));
        setAllEventList(() => (eventList || []).filter(e => e?.taskStatusId === id))
    };

    useEffect(() => {
        if (eventList) {
            console.log('eventList', eventList)
            setAllEventList(() => eventList);
        }
    }, [eventList])

    useEffect(() => {
        if (serviceRequestStatusData)
            // setServiceRequestStatus(() => serviceRequestStatusData.map(e => ({...e, select: false})));
            setServiceRequestStatus(() => serviceRequestStatusData.filter(e => e.id > 1 && e.id < 5).map(e => ({...e, select: false})))
    }, [serviceRequestStatusData])

    useEffect(() => {
        if (taskDetails != null && JSON.stringify(taskDetails) !== '{}') {
            setShowTaskDetails(true);
        }
    }, [taskDetails]);

    useEffect(() => {
        if (!showTaskDetails) {
            dispatch(HttpsAction({
                actionType: `GET_TASK_DETAILS`,
                reduxKeyName: 'taskDetails',
                reducerName: 'SERVICE_REQUEST',
                removeData: true,
            }));
        }
    }, [showTaskDetails]);

    useEffect(() => {
        dispatch(HttpsAction({
            apiSection: 'client',
            apiName: 'serviceRequestStatus',
            reduxKeyName: 'serviceRequestStatus',
            reducerName: 'CLIENT',
            actionType: 'GET_SERVICE_REQUEST_STATUS'
        }));
    }, []);

    return (
        <>
            <div className={'row'}>
                <div className={'col-9'} style={{flex: '1'}}>
                    <div className={classes.calenderScheduler}>
                        <Calendar
                            handleMonth={handleMonthChange}
                            events={allEventList?.map((v, i) => ({...v, date: v?.taskDate || new Date()}))}
                            // events={allEventList?.filter(e => e.assignedNurseId != null).map((v, i) => ({...v, date: v?.startDate || new Date()}))}
                            eventContent={renderEventContent}
                        />
                    </div>
                </div>

                <div className={'col-3'} style={{maxWidth: '215px'}}>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <PrimaryButton
                                type="button"
                                className="btn-view-repo gap-2 px-4 py-2 w-100"
                                text={<Stack className={classes.createNewNurse}>
                                    <img width={40} src={plusIcon} alt={''}/>
                                    Add Service Request
                                </Stack>}
                                onClick={() => {
                                    // navigate(`/agency/servicerequest/addnewrequest/calender-${clientId}`);
                                    const backButtonTextSuffix = 'Client Profile - Calendar';
                                    // const backLink = `/agency/clients/${clientId}/calender/0`;
                                    const backLink = '/agency/clients/edit/calendar';
                                    // navigate('/agency/new-service-request', {
                                    //     state: {
                                    //         patientId: clientId,
                                    //         showBackButton: true,
                                    //         backButtonTextSuffix,
                                    //         backLink,
                                    //     }
                                    // });
                                    gotoNewServiceRequestPage({
                                        patientId: clientId,
                                        showBackButton: true,
                                        backButtonTextSuffix,
                                        backLink,
                                    });
                                }}
                            />
                        </div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {/*<div className={'row mt-3'}>*/}
                            {/*    <div className={'col-6'}>*/}
                            {/*        <div className="mb-4 input-field-main">*/}
                            {/*            <Field*/}
                            {/*                className="form-control mk-wi"*/}
                            {/*                name="from"*/}
                            {/*                label="From:"*/}
                            {/*                type="date"*/}
                            {/*                component={CustomInputField}*/}
                            {/*            />*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*    <div className={'col-6'}>*/}
                            {/*        <div className="mb-4 input-field-main">*/}
                            {/*            <Field*/}
                            {/*                className="form-control mk-wi"*/}
                            {/*                name="to"*/}
                            {/*                label="To:"*/}
                            {/*                type="date"*/}
                            {/*                component={CustomInputField}*/}
                            {/*            />*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className={'row mt-3 px-2'}>
                                <Stack className={classes.taskIndicators}>
                                    <Stack className={classes.taskIndicatorsTitle}>
                                        Task Indicators
                                    </Stack>
                                    <Stack className={'mt-4'} gap={'15px'}>
                                        {/*{serviceRequestStatus?.map((value, index) => (*/}
                                        {(serviceRequestStatus || []).map((value, index) => (
                                            <div
                                                onClick={() => handleSelectServiceRequestType(value.id, index)}
                                                className={classes.indicators}
                                                style={{
                                                    backgroundColor: value.bgColor,
                                                    border: value.select ? '2px solid #000' : 'unset',
                                                    fontSize: '15px',
                                                    fontWeight: 'bolder',
                                                    maxHeight: '44px',
                                                }}
                                                key={index}>
                                                {`${value.name} ${(eventList || []).filter(e => e?.taskStatusId === value.id).length}`}
                                            </div>
                                        ))}
                                    </Stack>
                                </Stack>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Dialog
              open={showTaskDetails}
              onClose={() => { setShowTaskDetails(false)}}
              maxWidth={"lg"}
              aria-labelledby={"form-dialog-title"}>
                <div className={'p-4'}>
                    <TaskDetails showingDataFor="clientCalendar" taskDetails={taskDetails} onClose={() => { setShowTaskDetails(false)}} />
                </div>
            </Dialog>
        </>
    )
};

const mapStateToProps = (state) => {
  const { serviceRequest, client } = state;
// console.log('test- client', client);
  return {
    taskDetails: serviceRequest?.taskDetails?.data || {},
  };
};

export default connect(mapStateToProps)(
    reduxForm({form: "calender"})(Calender)
);
