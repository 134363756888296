import React, {useEffect, useRef, useState} from "react";
import TableTitle from "../../Dashboard/TableTitle";
import {Card} from "react-bootstrap";
import {HttpsAction} from "../../../../commonApiFunction/httpsAction";
import {useDispatch, useSelector} from "react-redux";
import {NavLink, useNavigate} from "react-router-dom";
import Table from "../../../../sharedComponents/table/Table";
import CustomDialog from "../../../../sharedComponents/CustomDialog/index";
import PrimaryButton from "../../../../sharedComponents/buttons/PrimaryButton";
import {useReactToPrint} from "react-to-print";
import {getNursesData} from "../../../redux/actions/AdminActions";
import BackLink from "../../../../sharedComponents/buttons/BackLink";
import storageManager from "../../../../helpers/storageManager";
import Pagination from "../../../../sharedComponents/pagination";
import {ADMIN_NURSES_NAV_TITLES} from "../../../../appConstants";

const VerifiedNurses = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const printFormRef = useRef(null);

    const verifiedNurseList = useSelector(state => state.admin?.verifiedNurseList);
    const allVerifiedNurseList = useSelector(state => state.admin?.allVerifiedNurseList);

    const [exportModal, setExportModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [dataFilters, setDataFilters] = useState({from: null, to: null, searchText: ''});

    const transformedArray = (verifiedNurseList?.rows || [])?.map((item, index) => {
        return {
            id: item.id,
            sn: item?.nurseId,
            name: item?.nurseFullName,
            email: item?.email || 'N/A',
            dateCreated: item?.creationDateFormatUs || 'N/A',
            // hours:
            preferredRates: item?.preferredRates || "N/A",
            verification: item.isVerified ? "Verified" : "Unverified",
            status: item.isAvailable ? "Available" : "Unavailable"
        }
    });
    const exportTransformedArray = (allVerifiedNurseList || [])?.map((item, index) => {
        return {
            id: item.id,
            sn: item?.nurseId,
            name: item?.nurseFullName,
            email: item?.email || 'N/A',
            dateCreated: item?.creationDateFormatUs || 'N/A',
            // hours:
            preferredRates: item?.preferredRates || "N/A",
            verification: item.isVerified ? "Verified" : "Unverified",
            status: item.isAvailable ? "Available" : "Unavailable"
        }
    });
// console.log('test- exportTransformedArray', exportTransformedArray)

    const serviceHeaderData = [
        {
            title: "S/N",
            dataIndex: "sn",
        },
        {
            title: "Name",
            dataIndex: "name",
        },
        {
            title: "Email address",
            dataIndex: "email",
        },
        {
            title: "Date created",
            dataIndex: "dateCreated",
        },
        {
            title: "Preferred rates",
            dataIndex: "preferredRates",
        },
        {
            title: "Verification",
            dataIndex: "verification",
        },
        {
            title: "Status",
            dataIndex: "status",
        },
    ];

    const actionButton = [
        {
            actionName: "Edit",
            buttonAction: (id, index) => {
                if (id) {
                    // handleEditModal()
                    storageManager.saveAdminBackLinkInfo({
                        showBackButton: true,
                        // backButtonTextSuffix: 'Verified Nurses List',
                        // backLink: '/admin/all/verifiedNurses',
                        backButtonTextSuffix: 'Verified Nurses',
                        backLink: '/admin/list/nurses/verified',
                    });
                    // navigate(`/admin/agencyNurses/edit/${id}/profile`/*, {
                    //     state: {
                    //         showBackButton: true,
                    //         backButtonTextSuffix: 'Verified Nurses List',
                    //         backLink: '/admin/all/verifiedNurses',
                    //     },
                    // }*/);
                    // navigate(`/admin/agencyNurses/edit`);
                    navigate(`/admin/agency/nurses/edit/profile/personal-info`, {
                        state: {
                            id,
                            showBackButton: true,
                            backButtonTextSuffix: 'Verified Nurses',
                            backLink: '/admin/list/nurses/verified',
                        },
                    });
                }
            },
            actionClass: "btn-primary",
            iconClass: "fa-user-plus",
        },
        {
            actionName: "Unverify Nurse",
            buttonAction: (id, index) => {
                if (id) {
                    dispatch(HttpsAction({
                        method: 'PUT',
                        apiSection: 'admin',
                        apiName: 'nurseVerify',
                        queryParameter: `/${id}/false`,
                        positiveCallBack: () => {
                            // getData(currentPage);
                            dispatch(getNursesData('verified-nurse-list', currentPage));
                        }
                    }))
                }
            },
            actionClass: "btn-primary",
            iconClass: "fa-user-plus",
        },
        {
            actionName: "Delete",
            buttonAction: (id, index) => {
                dispatch(HttpsAction({
                    method: 'DELETE',
                    apiSection: 'admin',
                    apiName: 'nurseTerminate',
                    queryParameter: `/${id}`,
                    positiveCallBack: () => {
                        // getData(currentPage);
                        dispatch(getNursesData('verified-nurse-list', currentPage));
                    }
                }))
            },
            actionClass: "btn-danger",
            iconClass: "fa-user-plus",
        },
    ];

    // const handleSearch = (e) => {
    //     setSearchTerm(e.target.value);
    // };
    //
    // const getData = (page) => {
    //     dispatch(HttpsAction({
    //         apiSection: 'admin',
    //         apiName: 'nurseList',
    //         queryParameter: `?verified=true&page=${page}`,
    //         reducerName: 'ADMIN',
    //         reduxKeyName: 'verifiedNurseList',
    //         actionType: 'GET_VERIFIED_NURSE_LIST'
    //     }))
    // };

    const handleExport = async () => {
        // await dispatch(HttpsAction({
        //     apiSection: 'admin',
        //     apiName: 'nurseList',
        //     queryParameter: `'?verified=true&doPaging=false`,
        //     reducerName: 'ADMIN',
        //     actionType: 'GET_ADMIN_ALL_VERIFIED_NURSE_LIST',
        //     reduxKeyName: 'allVerifiedNurseList'
        // }));
        dispatch(getNursesData('verified-nurse-list-export', 1, `doPaging=false`));
        setExportModal(true)
    };

    const handlePrint = useReactToPrint({
        content: () => printFormRef.current,
        onAfterPrint: () => {
            // Close the modal after printing or cancelling the PDF
            handleCloseFormModal()
        }
    });

    const handleCloseFormModal = () => {
        setExportModal(false)
    };

    // const handleDateFilter = ({from, to}) => {
    //     // dispatch(getNurseList('verified-nurse-list-date-filter', from, to));
    //     dispatch(getNursesData('verified-nurse-list', 1, `fromDate=${from}&toDate=${to}`));
    // };

    const handleFilters = (filters) => {
        setDataFilters(filters);
    };

    function handlePageChange(page) {
        setCurrentPage(page);
    }

    useEffect(() => {
        const {from: fromDate, to: toDate, searchText} = dataFilters;
        if (searchText || (fromDate !== null && toDate !== null) || currentPage) {
            let qsFilter = '';
            if (searchText.length > 0) {
                qsFilter += `searchText=${searchText}`;
            }
            if (fromDate != null && toDate != null) {
                if (qsFilter.length > 0) qsFilter += '&';
                qsFilter += `fromDate=${fromDate}&toDate=${toDate}`;
            }
            dispatch(getNursesData("verified-nurse-list", currentPage, qsFilter.length > 0 ? qsFilter : undefined));
        }
    }, [currentPage, dataFilters]);

    return (
        <>
            <div className="app-main__inner">
                <div className="main-dashboard-page">
                    <div className="col-12 page-back-btn">
                        <BackLink link="/admin/dashboard" linkText="Nurses Overview" />
                    </div>
                    <div className="row mb-15">

                        <TableTitle
                            title={ADMIN_NURSES_NAV_TITLES.VERIFIED_NURSES}
                            // onSearch={handleSearch}
                            handleExport={handleExport}
                            // handleDateFilter={handleDateFilter}
                            handleFilters={handleFilters}
                        />
                        <Card>
                            <Card.Body>
                                <div>
                                    <Table headers={serviceHeaderData} rows={transformedArray}
                                           actions={actionButton}/>
                                    <Pagination
                                      currentPage={verifiedNurseList?.page}
                                      totalRecords={verifiedNurseList?.total}
                                      limit={verifiedNurseList?.pageSize}
                                      onPageChange={handlePageChange}
                                    />
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>

            <CustomDialog
                open={exportModal && exportTransformedArray != null && exportTransformedArray.length > 0}
                onClose={() => handleCloseFormModal()}
                title={'All Verified Nurses'}
                maxWidth={'lg'}
                actions={
                    <div className="d-flex justify-content-center" style={{marginBottom: "20px"}}>
                        <PrimaryButton
                            type="submit"
                            className="btn-view-repo gap-2 px-5 py-2"
                            text={"Export"}
                            onClick={() => handlePrint()}
                        />
                    </div>
                }>
                <div ref={printFormRef} className={'p-4'}>
                    <Table headers={serviceHeaderData} rows={exportTransformedArray}/>
                </div>
            </CustomDialog>
        </>
    )
};

export default VerifiedNurses;
