import React, {useEffect, useState} from "react";
import {change, Field, reduxForm} from "redux-form";
import CustomInputField from "../../../../sharedComponents/reduxfields/customInputField";
import PrimaryButton from "../../../../sharedComponents/buttons/PrimaryButton";
import {connect, useDispatch, useSelector} from "react-redux";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import MultiSelectDropdown from "../../../../sharedComponents/multiSelect";
import {getCountriesList} from "../../../../services/commonServices";
import {putAgencyAction} from "../../../../services/AgencyProfile";
import {isAdminRole} from "../../../../helpers/apiMethods";
import {required, siteUrlValidation} from "../../../../helpers/validation";

const AgencyDetails = ({reloadData, handleSubmit, initialValues, countriesArray}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const {agencyId} = useParams();
    const { agencyId } = useOutletContext();

    const agencyProfileData = useSelector(state => {
        if (state == null || state.agencyProfile == null || state.agencyProfile.agencyProfile == null)
            return {
                name: '',
                agencyName: '',
                webLink: '',
                webSiteLink: '',
                countriesServed: [],
            }
        return state.agencyProfile.agencyProfile.data;
    });
    const [selectedOptions, setSelectedOptions] = useState(initialValues?.countriesServed || []);

    const getSelectedOptions = (selections) => {
        setSelectedOptions(selections);
    }

    const onSubmit = async (data) => {
        // console.log('submit- data', data)
        const payload = {
            agencyName: data?.agencyName,
            webSiteLink: data?.webSiteLink,
            countriesServed: selectedOptions.map(res => res.value),
        };

        // console.log('submit- payload', payload)

        try {
            // const decodedTokenData = parseTokenAndGetData().data;
            const response = await dispatch(
                putAgencyAction(payload, "details", "Agency details updated successfully"/*, decodedTokenData.agencyId*/)
            );
            if (response?.success) {
                await dispatch(reloadData());
                navigate(isAdminRole ? `/admin/agency-profile/address${agencyId}` : `/agency/agency-profile/address`)
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (agencyProfileData.countriesServed != null && agencyProfileData.countriesServed.length > 0) {
            setSelectedOptions(agencyProfileData.countriesServed.map(item => ({value: item.id, label: item.name})));
        }
        if (agencyProfileData.name != null && agencyProfileData.name.length > 0) {
            dispatch(change('agencyDetails', 'agencyName', agencyProfileData.name));
        }
        if (agencyProfileData.webLink != null && agencyProfileData.webLink.length > 0) {
            dispatch(change('agencyDetails', 'webSiteLink', agencyProfileData.webLink));
        }
    }, [agencyProfileData]);

    useEffect(() => {
        dispatch(getCountriesList());
        return () => {
            setSelectedOptions([]);
        };
    }, []);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-12">
                        <div className="mb-4 input-field-main">
                            <Field
                                className="form-control mk-wi"
                                name="agencyName"
                                label="Agency Name"
                                type="text"
                                component={CustomInputField}
                                placeholder={'Agency 1'}
                                requiredMark={true}
                                validate={required}
                            />
                        </div>
                    </div>
                    {/*<div className="col-12">
                        <div className="mb-4 input-field-main">
                            <Field
                                className="form-control mk-wi"
                                name="phoneNumber"
                                label="Phone number"
                                type="number"
                                component={CustomInputField}
                                placeholder={'Enter phone number'}
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="mb-4 input-field-main">
                            <Field
                                className="form-control mk-wi"
                                name="email"
                                label="Email address"
                                type="text"
                                component={CustomInputField}
                                // validate={emailValidation}
                                placeholder={'Enter email address'}
                            />
                        </div>
                    </div>*/}
                    <div className="col-12">
                        <div className="mb-4 input-field-main">
                            <Field
                                className="form-control mk-wi"
                                name="webSiteLink"
                                label="Website"
                                type="text"
                                component={CustomInputField}
                                placeholder={'Enter link'}
                                requiredMark={true}
                                validate={[required, siteUrlValidation]}
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="mb-4 input-field-main">
                            <MultiSelectDropdown
                                dpOptions={countriesArray}
                                getSelectedOptions={getSelectedOptions}
                                selectedLanguages={selectedOptions}
                                label="Select countries served"
                                requiredMark={true}
                                validate={required}
                            />
                        </div>
                    </div>
                </div>
                <div
                    className="d-flex justify-content-center"
                    style={{marginTop: "40px"}}
                >
                    <PrimaryButton
                        type="submit"
                        className="btn-view-repo gap-2 px-5 py-2"
                        text={"Next"}
                        onClick={() => undefined}
                    />
                </div>
            </form>
        </>
    )
};

const mapStateToProps = state => {
    const {common, agencyProfile} = state;
    const data = agencyProfile?.agencyProfile?.data;
    // console.log('agency details', data)

    return {
        countriesArray: common.countriesList || [],
        initialValues: {
            agencyName: data?.name,
            // phoneNumber: Number(data?.authPersonDetails?.authUserPhoneNumber),
            // email: data?.authUserEmail,
            webSiteLink: data?.webLink,
            countriesServed: (data?.countriesServed || [])?.map(item => ({value: item.id, label: item.name})),
        }
    }
}

export default connect(mapStateToProps)(
    reduxForm({form: "agencyDetails", enableReinitialize: true})(AgencyDetails)
);
