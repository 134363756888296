import React, {useEffect, useState} from "react";
import PersonalInfoInnerTabs from "./personalInfoInnerTabs";
import {Field, reduxForm} from "redux-form";
import MultiSelectDropdown from "../../../../sharedComponents/multiSelect";
import CustomDropDownField from "../../../../sharedComponents/reduxfields/customDropDown";
import CustomInputField from "../../../../sharedComponents/reduxfields/customInputField";
import CustomCheckboxField from "../../../../sharedComponents/reduxfields/CustomCheckBox";
import {required} from "../../../../helpers/validation";
import {connect, useDispatch, useSelector} from "react-redux";
import {HttpsAction} from "../../../../commonApiFunction/httpsAction";
import {useParams} from "react-router";
import {getNurseAction} from "../../../../services/NursesProfile";
import {
    getEmploymentTypesList,
    getLanguagesList, getPreferredShiftTimingsList,
    getProfessionList,
    getStateList,
    getWorkExperienceList
} from "../../../../services/commonServices";
import {useOutletContext} from "react-router-dom";

const JobPreferences = ({handleSubmit, professionList, initialValues, change}) => {
    const dispatch = useDispatch();
    // const { id: nurseId } = useParams();
    const { nurseId } = useOutletContext();

    const stateData = useSelector((state) => state.common ?? []);
    const adminJobPreferences = useSelector((state) => state.admin?.adminJobPreferences?.data);

    const [nurseSkillList, setNurseSkillList] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [perVisit, setPerVisit] = useState(false);
    const [perHour, setPerHour] = useState(false);
    const [perDiem, setPerDiem] = useState(false);
    const [selectedSpecialtiesOptions, setSelectedSpecialtiesOptions] = useState([]);
    const [selectedProfession, setSelectedProfession] = useState('0');

    const getSelectedSpecialtiesOptions = (selectedOptions) => {
        setSelectedSpecialtiesOptions(selectedOptions)
    };

    // const getSelectedProfessionOptions = async (selectedOptions) => {
    //     setSelectedOptions(selectedOptions);
    //     setSelectedProfession(selectedOptions[selectedOptions.length - 1].value)
    //
    //     if (selectedOptions?.[selectedOptions?.length - 1]?.value > 0) {
    //         const responseskills = await dispatch(getNurseAction("nurseSkills", selectedOptions[selectedOptions.length - 1].value));
    //         setNurseSkillList(responseskills.data);
    //     }
    // };

    const getSelectedProfessionOptions = async (event) => {
        const responseskills = await dispatch(getNurseAction("nurseSkills", event.target.value));
        setNurseSkillList(responseskills.data);
    };

    const handleCheckedPerVisit = () => {
        setPerVisit(!perVisit)
    };
    const handleCheckedPerHour = () => {
        setPerHour(!perHour)
    };
    const handleCheckedPerDiem = () => {
        setPerDiem(!perDiem)
    };

    const onSubmit = (data) => {

        dispatch(HttpsAction({
            method: 'PUT',
            apiSection: 'admin',
            apiName: 'jobPreferences',
            queryParameter: `/${nurseId}`,
            data: {
                preferredLanguageId: +data?.preferredLanguage,
                preferredPerDayRate: +data?.defaultPayRatePerDiem || null,
                preferredPerHourRate: +data?.defaultPayRatePerHour || null,
                preferredPerVisitRate: +data?.defaultPayRatePerVisit || null,
                preferredJobShiftTimingsId: +data?.preferredShiftTimings,
                // preferredJobCityId: 115719,
                preferredJobExperienceYearsForSearch: +data?.experience,
                preferredJobLicenseProvinceOrStateId: +data?.licenseState,
                preferredJobClassId: +data?.profession,
                preferredJobSkillIds: selectedSpecialtiesOptions.map(v => v.value) || [],
                preferredLocation: data?.desiredLocation
            }
        }))
    };

    useEffect(() => {
        dispatch(HttpsAction({
            apiSection: 'admin',
            apiName: 'jobPreferences',
            queryParameter: `/${nurseId}`,
            reduxKeyName: 'adminJobPreferences',
            reducerName: 'ADMIN',
            actionType: 'GET_ADMIN_JOB_PREFERENCES'
        }))
        dispatch(getProfessionList());
        dispatch(getStateList());
        dispatch(getLanguagesList());
        dispatch(getWorkExperienceList());
        dispatch(getEmploymentTypesList());
        dispatch(getPreferredShiftTimingsList());
    }, []);

    useEffect(() => {
        if (adminJobPreferences) {
            if (adminJobPreferences?.preferredPerVisitRate) {
                setPerVisit(adminJobPreferences?.preferredPerVisitRate ? true : false)
            }
            if (adminJobPreferences?.preferredPerHourRate) {
                setPerHour(adminJobPreferences?.preferredPerHourRate ? true : false)
            }
            if (adminJobPreferences?.preferredPerDayRate) {
                setPerDiem(adminJobPreferences?.preferredPerDayRate ? true : false)
            }
        }
    }, [adminJobPreferences]);

    useEffect(() => {
        if (adminJobPreferences?.preferredSkills?.length) {
            setSelectedSpecialtiesOptions(adminJobPreferences?.preferredSkills.map(v => ({
                value: v.preferredSkillId,
                label: v.preferredSkill
            })))
        }
    }, [adminJobPreferences?.preferredSkills?.length])

    return (
        <>
            <div className="card-white px-4">
                {/*<PersonalInfoInnerTabs/>*/}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row mt-3">
                        <div className="col-6">
                            <div className="mb-4 input-field-main">
                                {/*<MultiSelectDropdown*/}
                                {/*    dpOptions={professionList}*/}
                                {/*    getSelectedOptions={getSelectedProfessionOptions}*/}
                                {/*    selectedLanguages={selectedOptions}*/}
                                {/*    label="Profession"*/}
                                {/*/>*/}
                                <Field
                                    className="form-control mk-wi"
                                    label="Profession"
                                    name="profession"
                                    component={CustomDropDownField}
                                    onChange={getSelectedProfessionOptions}
                                >
                                    <option value="">Please Select</option>
                                    {(professionList || []).map((res, index) => {
                                        return (
                                            <option key={index} value={res.id}>
                                                {res.name}
                                            </option>
                                        );
                                    })}
                                </Field>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="mb-4 input-field-main">
                                <MultiSelectDropdown
                                    dpOptions={nurseSkillList}
                                    getSelectedOptions={getSelectedSpecialtiesOptions}
                                    selectedLanguages={selectedSpecialtiesOptions}
                                    label="Specialties"
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="mb-4 input-field-main">
                                <Field
                                    className="form-control mk-wi"
                                    label="License State"
                                    name="licenseState"
                                    component={CustomDropDownField}
                                >
                                    <option value="">Please Select</option>
                                    {stateData.stateList != null && stateData.stateList.map((res, index) => {
                                        return (
                                            <option key={index} value={res.id}>
                                                {res.name}
                                            </option>
                                        );
                                    })}
                                </Field>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="mb-4 input-field-main">
                                <Field
                                    className="form-control mk-wi"
                                    label="Experience (Year)"
                                    name="experience"
                                    component={CustomDropDownField}
                                >
                                    <option value="">Please Select</option>
                                    {stateData.workExperienceList?.map((res, index) => {
                                        return (
                                            <option key={index} value={res.id}>
                                                {res.name}
                                            </option>
                                        );
                                    })}
                                </Field>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="mb-4 input-field-main">
                                <Field
                                    className="form-control mk-wi"
                                    label="Preferred Shift Timings"
                                    name="preferredShiftTimings"
                                    component={CustomDropDownField}
                                >
                                    <option value="">Please Select</option>
                                    {stateData.preferredShiftTimingsList?.map((res, index) => {
                                        return (
                                            <option key={index} value={res.id}>
                                                {res.name}
                                            </option>
                                        );
                                    })}
                                </Field>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="mb-4 input-field-main">
                                <Field
                                    className="form-control mk-wi"
                                    name="desiredLocation"
                                    label="Desired Location"
                                    type="text"
                                    component={CustomInputField}
                                />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="mb-4 input-field-main">
                                <Field
                                    className="form-control mk-wi"
                                    label="Preferred Language"
                                    name="preferredLanguage"
                                    component={CustomDropDownField}
                                >
                                    <option value="">Please Select</option>
                                    {stateData.languages?.map((res, index) => {
                                        return (
                                            <option key={index} value={res.id}>
                                                {res.name}
                                            </option>
                                        );
                                    })}
                                </Field>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="col-6">
                                <div className="mb-2 input-field-main">
                                    <label>
                                        Employment Type
                                    </label>
                                    <Field
                                        className="form-check-input me-1"
                                        name="perVisit"
                                        label="per visit"
                                        component={CustomCheckboxField}
                                        onChange={({target}) => handleCheckedPerVisit(target.checked)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="col-6">
                                <div className="mb-4 input-field-main">
                                    <Field
                                        className="form-control mk-wi"
                                        name="defaultPayRatePerVisit"
                                        label="Default Pay Rate (per visit)"
                                        type="number"
                                        component={CustomInputField}
                                        disabled={!perVisit}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="col-6">
                                <div className="mb-2 input-field-main">
                                    <Field
                                        className="form-check-input me-1"
                                        name="perHour"
                                        label="per hour"
                                        component={CustomCheckboxField}
                                        onChange={({target}) => handleCheckedPerHour(target.checked)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="col-6">
                                <div className="mb-4 input-field-main">
                                    <Field
                                        className="form-control mk-wi"
                                        name="defaultPayRatePerHour"
                                        label="Default Pay Rate (per hour)"
                                        type="number"
                                        component={CustomInputField}
                                        disabled={!perHour}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="col-6">
                                <div className="mb-2 input-field-main">
                                    <Field
                                        className="form-check-input me-1"
                                        name="perDiem"
                                        label="per diem"
                                        component={CustomCheckboxField}
                                        onChange={({target}) => handleCheckedPerDiem(target.checked)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="col-6">
                                <div className="mb-4 input-field-main">
                                    <Field
                                        className="form-control mk-wi"
                                        name="defaultPayRatePerDiem"
                                        label="Default Pay Rate (per diem)"
                                        type="number"
                                        component={CustomInputField}
                                        disabled={!perDiem}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="d-flex justify-content-center pt-5">
                                <button type="submit" className="btn btn-primary btn-lg">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
};

const mapStateToProps = state => {
    const {common} = state;
    const data = state.admin?.adminJobPreferences?.data;

    return {
        professionList: common.professionList != null ? common.professionList.filter(item => item.id > 1) : [],
        initialValues: {
            licenseState: data?.preferredJobLicenseProvinceOrStateId?.toString(),
            experience: data?.preferredJobExperienceYearsForSearch?.toString(),
            preferredShiftTimings: data?.preferredJobShiftTimingsId?.toString(),
            desiredLocation: data?.preferredLocation,
            preferredLanguage: data?.preferredLanguageId?.toString(),
            perVisit: data?.preferredPerVisitRate?.toString() ? true : false,
            defaultPayRatePerVisit: data?.preferredPerVisitRate?.toString(),
            perHour: data?.preferredPerHourRate?.toString() ? true : false,
            defaultPayRatePerHour: data?.preferredPerHourRate?.toString(),
            perDiem: data?.preferredPerDayRate?.toString() ? true : false,
            defaultPayRatePerDiem: data?.preferredPerDayRate?.toString(),
            profession: data?.preferredJobClassId?.toString(),
        }
    }

};

export default connect(mapStateToProps)(
    reduxForm({form: "JobPreferences", enableReinitialize: true})(JobPreferences)
);
