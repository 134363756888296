import * as commonServices from './commonServices.js'
import * as serviceRequest from '../Agency/components/ServiceRequest/serviceActionTypes.js';
import getApiUrl from "../helpers/apiURLs.js";
import api from '../helpers/interceptor'
import {toastr} from 'react-redux-toastr'
import {defaultErrorMessage, pageLimit} from "../appConstants";
import {COMPLETED_SERVICE_REQUESTS_LIST, INCOMPLETE_VISITS_LIST, MISSED_SERVICE_REQUESTS_LIST} from "../Agency/components/ServiceRequest/serviceActionTypes.js";
import {GET_CARE_PLANS} from "../Agency/components/ServiceRequest/ChartingActionTypes";

// Create Service Request
export const postServiceCreationData = (payload, endPoint, successCallback) => {
    return async (dispatch) => {
        dispatch(commonServices.setLoader(true));
        const url = getApiUrl('serviceRequests', endPoint);
// console.log('test- url', url, endPoint)
        try {
          const response = await api.post(url, payload);
          dispatch(commonServices.setLoader(false));
          if (endPoint === 'createRequest') {
            toastr.success('New Service Request Created Successfully', '');
          }
          if (endPoint === 'createJobRequest') {
            toastr.success('New Service Request (JOB) Created Successfully', '');
          }
          if (endPoint === 'createJobRequestWithCharting') {
            toastr.success('New Service Request (JOB with charting) Created Successfully', '');
          }
          else if (endPoint === 'assignNurse') {
            toastr.success('Assigned Successfully', '');
            dispatch(getAllServiceRequest('all', 1))
            dispatch(getAllServiceRequest('pending', 1))
          }
          if (successCallback != null) successCallback();
          return response;
        } catch (err) {
          dispatch(commonServices.setLoader(false));
          toastr.error(defaultErrorMessage, '');
          return err
      }
    };
};

// Update Service Request
export const putServiceCreationData = (payload, endPoint, successCallback) => {
    return async (dispatch) => {
        dispatch(commonServices.setLoader(true));
        const url = getApiUrl('serviceRequests', endPoint);
        try {
          const response = await api.put(url, payload);
          dispatch(commonServices.setLoader(false));
          if (endPoint === 'createRequest') {
            toastr.success('New Service Request Created Successfully', '');
          }
          else if (endPoint === 'assignNurse') {
            toastr.success('Assigned Successfully', '');
            dispatch(getAllServiceRequest('all', 1))
            dispatch(getAllServiceRequest('pending', 1))
          }
          if (successCallback != null) successCallback();
          return response;
        } catch (err) {
          dispatch(commonServices.setLoader(false));
          toastr.error(defaultErrorMessage, '');
          return err
      }
    };
};

// Get All Service Request / ?statusId=1 => Pending / ?statusId=2 => Active
// page=1&limit=10&statusId=2

export const getAllServiceRequest = (payload, pageNumber, searchText = '', serviceRequestId = 0) => {
    return async (dispatch) => {
        dispatch(commonServices.setLoader(true));
        let url = '';
        switch (payload) {
          case 'all':
            url = `${getApiUrl('serviceRequests', 'allRequest')}?page=${pageNumber}&pageSize=${pageLimit}`;
            break;
          case 'nurse':
            url = getApiUrl('combos', 'nursesList');
            break;
          case 'nurseListGrouped':
            url = getApiUrl('combos', 'nursesListGrouped');
            break;
          default:
            break;
        }
        if (serviceRequestId > 0) {
          url += `&serviceRequestId=${serviceRequestId}`;
        }
        else if (!(payload === 'nurse' || payload === 'nurseListGrouped') && searchText != null && searchText.length > 0) {
          url += `&searchText=${searchText}`;
        }
        try {
            const response = await api.get(url);
            switch (payload) {
              case 'all':
                  dispatch({type:serviceRequest.ALL_SERVICE_REQUESTS_LIST, allRequestData: response.data})
                  break;
                default:
                  break;
            }
            dispatch(commonServices.setLoader(false));
            return response;
        } catch (err) {
            dispatch(commonServices.setLoader(false));
            toastr.error(defaultErrorMessage, '');
        }
    };
}

export const getCountedVisits = (payload, pageNumber, searchText = '', serviceRequestId = 0) => {
    return async (dispatch) => {
        dispatch(commonServices.setLoader(true));
        let url = '';
        switch (payload) {
          case 'active':
            url = `${getApiUrl('client', 'serviceRequestTasks')}?page=${pageNumber}&pageSize=${pageLimit}&statusId=3`;
            break;
          case 'schedulerequest':
            url = `${getApiUrl('client', 'serviceRequestTasks')}?page=${pageNumber}&pageSize=${pageLimit}&statusId=2`;
            break;
          case 'pending':
            url =`${getApiUrl('client', 'serviceRequestTasks')}?page=${pageNumber}&pageSize=${pageLimit}&statusId=1`;
            break;
          case 'completed':
            url =`${getApiUrl('client', 'serviceRequestTasks')}?page=${pageNumber}&pageSize=${pageLimit}&statusId=4`;
            break;
          case 'missed':
            url =`${getApiUrl('client', 'serviceRequestTasks')}?page=${pageNumber}&pageSize=${pageLimit}&statusId=5`;
            break;
          case 'incomplete':
            url =`${getApiUrl('client', 'serviceRequestTasks')}?page=${pageNumber}&pageSize=${pageLimit}&statusId=6`;
            break;
          case 'initialAssessment':
          case 'supervisoryVisit':
          case 'dischargeSummary':
          case 'carePlan':
             // url = `${getApiUrl('serviceRequests', 'allRequest')}?page=${pageNumber}&pageSize=${pageLimit}&type=${payload}`;
            url =`${getApiUrl('client', 'serviceRequestTasks')}?page=${pageNumber}&pageSize=${pageLimit}&chartingType=${payload}`;
            break;
          default:
            break;
        }
        if (serviceRequestId > 0) {
          url += `&serviceRequestId=${serviceRequestId}`;
        }
        else if (searchText != null && searchText.length > 0) {
          url += `&searchText=${searchText}`;
        }
        try {
            const response = await api.get(url);
            switch (payload) {
                case 'schedulerequest':
                  dispatch({type:serviceRequest.SCHEDULED_SERVICE_REQUESTS_LIST, scheduledRequestData:response.data})
                  break;
                case 'active':
                  dispatch({type:serviceRequest.ACTIVE_SERVICE_REQUESTS_LIST, activeData:response.data})
                  break;
                case 'pending':
                  dispatch({type:serviceRequest.PENDING_SERVICE_REQUESTS_LIST, pendingData:response.data})
                  break;
                case 'completed':
                  dispatch({type:serviceRequest.COMPLETED_SERVICE_REQUESTS_LIST, completedData:response.data})
                  break;
                case 'missed':
                  dispatch({type:serviceRequest.MISSED_SERVICE_REQUESTS_LIST, missedData:response.data})
                  break;
                case 'incomplete':
                  dispatch({type:serviceRequest.INCOMPLETE_VISITS_LIST, incompleteVisitsData:response.data})
                  break;
                case 'initialAssessment':
                  dispatch({type:serviceRequest.GET_INITIAL_ASSESSMENTS, initialAssessments:response.data})
                  break;
                case 'supervisoryVisit':
                  dispatch({type:serviceRequest.GET_SUPERVISORY_VISITS, supervisoryVisits:response.data})
                  break;
                case 'dischargeSummary':
                  dispatch({type:serviceRequest.GET_DISCHARGE_SUMMARIES, dischargeSummaries:response.data})
                  break;
                case 'carePlan':
                  dispatch({type:serviceRequest.GET_CARE_PLANS, carePlans:response.data})
                  break;
                default:
                  break;
            }
            dispatch(commonServices.setLoader(false));
            return response;
        } catch (err) {
            dispatch(commonServices.setLoader(false));
            toastr.error(defaultErrorMessage, '');
        }
    };
}
