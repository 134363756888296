import React, {useEffect, useRef, useState} from 'react';
import downArrow from "../../../assets/image/down-arrow.png";
import DatePicker from "react-datepicker";
import Table from "../../../../sharedComponents/table/Table";
import Pagination from "../../../../sharedComponents/pagination";
import {connect, useDispatch} from "react-redux";
import moment from "moment";
import {debounce} from "lodash";
import {toastr} from "react-redux-toastr";
import {getPayoutHistoryAction} from "../../../../services/transaction";
import XLSX from "xlsx/dist/xlsx.full.min";
import * as FileSaver from "file-saver";
import {pageLimit} from "../../../../appConstants";
import {useOutletContext} from "react-router-dom";
import CommonPaginationNew from "../../../../sharedComponents/pagination/CommonPaginationNew";

const PayoutHistoryTable = ({ payoutHistoryInstance, setPaginationTab }) => {
  const dispatch = useDispatch();

  const payoutHistoryListRows = payoutHistoryInstance?.rows?.map((item) => {
    const {
      serviceRequestType,
      nurseFullName,
      transactionStatus,
    } = item;
    const transactionSerial = `${item?.transactionSerial}`
    const transactionDate = moment(item?.transactionDateTime).format("MM/DD/YY HH:mm");
    const amount = `$${item?.amount}`;
    return {
      transactionSerial,
      nurseFullName,
      serviceRequestType,
      transactionDate,
      amount,
      transactionStatus,
    };
  });

  const serviceHeaderData = [
    {
      title: "Transaction ID",
      dataIndex: "transactionSerial",
    },
    {
      title: "Service Request",
      dataIndex: "serviceRequestType",
    },
    {
      title: "Nurse",
      dataIndex: "nurseFullName",
    },
    {
      title: "Date",
      dataIndex: "transactionDate",
    },
    {
      title: "Amount",
      dataIndex: "amount",
    },
    {
      title: "Status",
      dataIndex: "transactionStatus",
    },
  ];

  const tableRef = useRef();
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [date, setDate] = useState(new Date());
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [fileName, setFileName] = useState("payout_history_sheet");
  const [fileFormat, setFileFormat] = useState("xlsx");

  const handleChange = (range) => {
    if (range) {
      const [fromDate, toDate] = range;
      const formattedFromDate = moment(fromDate).format("YYYY-MM-DD");
      const formattedToDate = moment(toDate).format("YYYY-MM-DD");

      if (formattedFromDate !== 'Invalid date') setFromDate(formattedFromDate); else setFromDate('');
      if (formattedToDate !== 'Invalid date') setToDate(formattedToDate); else setToDate('');
    } else {
      // The range was set to null (probably the 'clear' select)
      setFromDate('');
      setToDate('');
    }
  };

  const onSearch = debounce((e) => {
    setSearchTerm(e.target.value);
  }, 500);

  const handleExport = () => {
    if (payoutHistoryInstance && payoutHistoryInstance.rows && payoutHistoryInstance.rows.length > 0) {
      const bookType = fileFormat;
      const wb = XLSX.utils.table_to_book(tableRef.current, { sheet: "Sheet JS" });
      const wbout = XLSX.write(wb, { bookType, bookSST: true, type: 'array' });

      const blob = new Blob([wbout], { type: 'application/octet-stream' });
      const file = fileName.length ? `${fileName}.${fileFormat}` : `excel-sheet.${fileFormat}`;

      FileSaver.saveAs(blob, file);
    } else {
      toastr.error("Record can't be  export")
    }
  };

  // const handlePageChange = (page) => {
  //   setCurrentPage(page);
  // };

  useEffect(() => {
    if (payoutHistoryInstance != null && payoutHistoryInstance?.rows?.length != null) {
      if (setPaginationTab != null) setPaginationTab(payoutHistoryInstance);
    }
  }, [payoutHistoryInstance]);

  useEffect(() => {
    // console.log("fromDate", fromDate);
    // console.log("toDate", toDate);
    // console.log("searchTerm", searchTerm);
    // console.log("currentPage", currentPage);
    if (searchTerm.length > 0 || fromDate.length > 0 || toDate.length > 0 || currentPage > 0) {
      // let validFromDate = fromDate;
      // let validToDate = toDate;
      //
      // // Convert fromDate and toDate to the expected format
      // if (typeof fromDate === 'object' && fromDate !== null) {
      //   validFromDate = moment(fromDate).format('YYYY-MM-DD');
      // }
      //
      // if (typeof toDate === 'object' && toDate !== null) {
      //   validToDate = moment(toDate).format('YYYY-MM-DD');
      // }
      //
      // // Fall back to null if date is invalid or not provided
      // if (!moment(validFromDate, 'YYYY-MM-DD', true).isValid()) {
      //   validFromDate = null;
      // }
      //
      // if (!moment(validToDate, 'YYYY-MM-DD', true).isValid()) {
      //   validToDate = null;
      // }
      //
      // if (searchTerm || (validFromDate !== null && validToDate !== null) || currentPage) {
      //   console.log("validFromDate", validFromDate);
      //   console.log("validToDate", validToDate);
      //   console.log("searchTerm", searchTerm);
      //   console.log("currentPage", currentPage);
      //
      //   dispatch(
      //     getPayoutHistoryAction(
      //       "agencyTransaction",
      //       {fromDate: validFromDate, toDate: validToDate, searchTerm, currentPage},
      //     )
      //   );
      // }

      console.log('loading payout history', fromDate, toDate);
      dispatch(
        getPayoutHistoryAction(
          "agencyTransaction",
          {fromDate, toDate, searchTerm, currentPage},
        )
      );
    }
  }, [searchTerm, fromDate, toDate, currentPage]);

  return (
    <>
      <div className="bg-white h-table-main" ref={tableRef}>
        <div className="d-flex flex-wrap gap-3 gap-xl-0 justify-content-between px-4 pt-5 pb-4">
          <div className="tabel-title">
            <h3 className="mb-0">Payout History</h3>
          </div>
          <div className="d-flex flex-wrap flex-lg-nowrap gap-2 gap-xl-3 align-items-center justify-content-between justify-content-xl-end">
            <div className="col-12 col-md-4 col-xl-3 px-xl-0">
              <button
                className="export-btn"
                onClick={() =>
                  handleExport(
                    payoutHistoryInstance?.rows,
                    "payout_history_sheet.xlsx"
                  )
                }
              >
                <img src={downArrow}/>
                Export
              </button>
            </div>
            <div className="col-12 col-md-4 col-xl-4 d-flex align-items-center gap-1 px-xl-0">
              <DatePicker
                selected={fromDate ? Date.parse(fromDate) : null}
                onChange={handleChange}
                startDate={fromDate ? Date.parse(fromDate) : null}
                endDate={toDate ? Date.parse(toDate) : null}
                selectsRange
                placeHolder={"Select Date"}
                isClearable
                className="w-100 history-date"
              />
            </div>
            <div className="col-12 col-md-4 col-xl-5 pl-3 pl-xl-0">
              <div className="input-group input-search-djkds history-search">
                <span className="input-group-append d-flex">
                  <button className="btn ms-n5" type="button">
                    <i className="fa fa-search"></i>
                  </button>
                </span>
                <input
                  className="form-control hhgrsjd bg-transparent border-0 border"
                  type="search"
                  placeholder="Search..."
                  onChange={onSearch}
                />
              </div>
            </div>
          </div>
        </div>

        <Table headers={serviceHeaderData} rows={payoutHistoryListRows}/>
      </div>
      <div className="d-flex flex-wrap align-items-center justify-content-between">
        <div>
          {/*<Pagination
            currentPage={payoutHistoryInstance?.page}
            totalRecords={payoutHistoryInstance?.total}
            limit={payoutHistoryInstance?.pageSize}
            onPageChange={handlePageChange}
          ></Pagination>*/}
          <CommonPaginationNew data={payoutHistoryInstance} onPageChange={(page) => setCurrentPage(page)} />
        </div>
        {/*<button className="next-btn" type="button">*/}
        {/*  Next*/}
        {/*  <svg*/}
        {/*    xmlns="http://www.w3.org/2000/svg"*/}
        {/*    width="18"*/}
        {/*    height="18"*/}
        {/*    viewBox="0 0 18 18"*/}
        {/*    fill="none"*/}
        {/*  >*/}
        {/*    <g clip-path="url(#clip0_11170_26112)">*/}
        {/*      <path*/}
        {/*        d="M9 3L7.9425 4.0575L12.1275 8.25H3V9.75H12.1275L7.9425 13.9425L9 15L15 9L9 3Z"*/}
        {/*        fill="#F8F8F8"*/}
        {/*      />*/}
        {/*    </g>*/}
        {/*    <defs>*/}
        {/*      <clipPath id="clip0_11170_26112">*/}
        {/*        <rect width="18" height="18" fill="white" />*/}
        {/*      </clipPath>*/}
        {/*    </defs>*/}
        {/*  </svg>*/}
        {/*</button>*/}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { transaction } = state;
  console.log('transaction', transaction)
  return {
    payoutHistoryInstance: transaction?.agencyTransactionList || {},
  };
};

export default connect(mapStateToProps)(PayoutHistoryTable);
