import { Avatar } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import moment from "moment/moment";
import { useChatScroll } from "./useChatScroll";
import { acceptDeclineOfferAction } from "../../../services/messages";
import { useDispatch } from "react-redux";

const MessageWindow = ({ chatList, selectedUser, chatId, socket }) => {
  const dispatch = useDispatch();

  const [message, setMessage] = useState('');
  const ref = useChatScroll(chatList);

  // const dispatch = useDispatch();

  const handleSendMessage = () => {
    // Emit "send_message" event with payload
    if (message) {
      let payload = {
        from: chatId,
        to: selectedUser?.id,
        text: message,
        messageTypeId: 1
      }
      // console.log("payload", payload)
      if (socket) {
        socket.emit('send_message', payload);
      }
      setMessage('');
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  }

  const handleAcceptDeclineOfferClick = (offerType, offerMsg) => {
    // console.log("offerType", offerType, "offer-message", offerMsg);
    const payload = {
      offerId: offerMsg?.otherInfo?.offerId,
      agencyId: offerMsg?.otherInfo?.agencyId,
      messageId: offerMsg?.messageId,
      agencyPersonalInfoId: offerMsg?.senderId,
      isAcceptedByNurse: offerType === 'accept',
    };
    // console.log("payload", payload)
    dispatch(acceptDeclineOfferAction('acceptDeclineOffer', payload/*, offerMsg?.receiverId*/))
  };

  const getDisplayDate = (chatDate) => {
    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'days').startOf('day');
    if (chatDate.isSame(today, 'day')) {
      return 'TODAY';
    } else if (chatDate.isSame(yesterday, 'day')) {
      return 'YESTERDAY';
    } else {
      return chatDate.format('MM/DD/YYYY');
    }
  };

  // console.log('CHAT- chatList', chatList)

  return (
    <div className="messsagewindow ">
      <div className="window-header w-100 d-flex align-items-center gap-3">
        <Avatar
          alt="Remy Sharp"
          src="/static/images/avatar/1.jpg"
          sx={{ width: 52, height: 52 }}
        />
        <div className="user-name">
          <h6 className="mb-0">{selectedUser?.firstName} {selectedUser?.lastName}</h6>
          <div className="online-box d-flex align-items-center gap-2">
            {selectedUser?.isOnline ?
              (
                <>
                  <div className="round-dott"></div>
                  <p>Online</p>
                </>
              ) :
              (
                <p>Offline</p>
              )
            }
          </div>
        </div>
      </div>
      <div className="chat-box-2 py-4" ref={ref}>
        <div className="chat-content" >
          {chatList && chatList.length > 0 && chatList.map((chatMsg, index) => {
            // console.log('test- chat-msg', index + 1, chatMsg);
            const currentChatDay = moment(chatMsg.messageDateTime).startOf('day');
            const previousChatDay = index > 0 ? moment(chatList[index - 1].messageDateTime).startOf('day') : null;
            return (
              <div key={index} className="w-100 h-auto">
                {(previousChatDay === null || !currentChatDay.isSame(previousChatDay, 'day')) &&
                  <div className="chat-day m-auto mb-5">
                    <h5>{getDisplayDate(currentChatDay)}</h5>
                  </div>
                }
                {chatMsg.senderId === chatId
                  ?  // messages sent by current logged-in user
                  <div className="chat-massage-2">
                    <div className="chat-text">
                      {chatMsg?.messageTitle && <h6>{chatMsg?.messageTitle}</h6>}
                      <p>{chatMsg?.messageText}</p>
                      <div className="text-end">
                        <span>{chatMsg?.messageTime12hr}</span>
                      </div>
                    </div>
                    <div className="polygon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="25"
                        viewBox="0 0 30 25"
                        fill="none"
                      >
                        <path
                          d="M1.00119 2.11222C0.540196 1.41638 1.08424 0.495358 1.91617 0.563243L28.1533 2.70419C28.8892 2.76424 29.3085 3.57374 28.933 4.20947L17.2346 24.0138C16.859 24.6495 15.9477 24.673 15.5399 24.0575L1.00119 2.11222Z"
                          fill="#D2DDEC"
                          filter="url(#drop-shadow)"
                        />
                        <defs>
                          <filter id="drop-shadow">
                            <feDropShadow
                              dx="-4"
                              dy="-2"
                              stdDeviation="2"
                              floodColor="rgba(0,0,0,0.06)"
                            />
                          </filter>
                        </defs>
                      </svg>
                    </div>
                    {/* rest of your code */}
                  </div>
                  :
                  // messages received by current logged-in user
                  chatMsg?.messageTypeId === 1 ?
                    <div className="chat-massage-1" key={index}>
                      <div className="chat-text">
                        {chatMsg?.messageTitle && <h6>{chatMsg?.messageTitle}</h6>}
                        <p>{chatMsg?.messageText}</p>
                        <div className="text-end">
                          <span>{chatMsg.messageTime12hr}</span>
                        </div>
                      </div>
                      <div className="polygon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="25"
                          viewBox="0 0 30 25"
                          fill="none"
                        >
                          <path
                            d="M1.00119 2.11222C0.540196 1.41638 1.08424 0.495358 1.91617 0.563243L28.1533 2.70419C28.8892 2.76424 29.3085 3.57374 28.933 4.20947L17.2346 24.0138C16.859 24.6495 15.9477 24.673 15.5399 24.0575L1.00119 2.11222Z"
                            fill="white"
                            filter="url(#drop-shadow)"
                          />
                          <defs>
                            <filter id="drop-shadow">
                              <feDropShadow
                                dx="-4"
                                dy="-2"
                                stdDeviation="2"
                                floodColor="rgba(0,0,0,0.06)"
                              />
                            </filter>
                          </defs>
                        </svg>
                      </div>
                    </div>
                    :
                    // for below offer type message
                    // chatMsg?.messageTypeId === 6 && chatMsg?.senderId.toString() === chatId?.toString() &&
                    chatMsg?.messageTypeId === 6 && ((chatMsg?.forSenderOnly && chatMsg?.senderId === chatId) || (chatMsg?.forReceiverOnly && chatMsg?.receiverId === chatId)) &&
                    <div className="chat-massage-1" key={index}>
                      <div className="chat-text">
                        {chatMsg?.messageTitle && <h6>{chatMsg?.messageTitle}</h6>}
                        <p>{chatMsg?.messageText}</p>
                        <div className="text-end">
                          <span>{chatMsg.messageTime12hr}</span>
                        </div>
                      </div>
                      <div className="polygon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="25"
                          viewBox="0 0 30 25"
                          fill="none"
                        >
                          <path
                            d="M1.00119 2.11222C0.540196 1.41638 1.08424 0.495358 1.91617 0.563243L28.1533 2.70419C28.8892 2.76424 29.3085 3.57374 28.933 4.20947L17.2346 24.0138C16.859 24.6495 15.9477 24.673 15.5399 24.0575L1.00119 2.11222Z"
                            fill="white"
                            filter="url(#drop-shadow)"
                          />
                          <defs>
                            <filter id="drop-shadow">
                              <feDropShadow
                                dx="-4"
                                dy="-2"
                                stdDeviation="2"
                                floodColor="rgba(0,0,0,0.06)"
                              />
                            </filter>
                          </defs>
                        </svg>
                      </div>
                      <div className="d-flex align-items-center gap-3 mt-4">
                        <button className="btn-accept" onClick={() => handleAcceptDeclineOfferClick("accept", chatMsg)}>Accept</button>
                        <button className="btn-decline" onClick={() => handleAcceptDeclineOfferClick("decline", chatMsg)}>Decline</button>
                      </div>
                    </div>
                }
              </div>
            );
          })}
          {/* {loginUserRoleId == 2 &&
            <><div className="chat-massage-1">
              <div className="chat-text">
                <h6>Offered $45 per Diem!</h6>
                <p>
                  You have made an offer of $45 per diem to Theressa. To start working on the Service request, please wait for acceptance.Thanks
                </p>
                <div className="text-end">
                  <span>10:21</span>
                </div>
              </div>
              <div className="polygon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="25"
                  viewBox="0 0 30 25"
                  fill="none"
                >
                  <path
                    d="M1.00119 2.11222C0.540196 1.41638 1.08424 0.495358 1.91617 0.563243L28.1533 2.70419C28.8892 2.76424 29.3085 3.57374 28.933 4.20947L17.2346 24.0138C16.859 24.6495 15.9477 24.673 15.5399 24.0575L1.00119 2.11222Z"
                    fill="white"
                    filter="url(#drop-shadow)"
                  />
                  <defs>
                    <filter id="drop-shadow">
                      <feDropShadow
                        dx="-4"
                        dy="-2"
                        stdDeviation="2"
                        flood-color="rgba(0,0,0,0.06)"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>
            </div>
            </>
          }
          {loginUserRoleId == 2 &&
            <><div className="chat-massage-1">
              <div className="chat-text">
                <h6>You were hired directly!</h6>
                <p>
                  Click the Accept button below to start working on your service
                  request. This is an hourly job with a rate of $54.00 USD per
                  hour. Respond quickly, otherwise Agency1 might close the job or
                  offer it to someone else.
                </p>
                <div className="text-end">
                  <span>10:21</span>
                </div>
              </div>
              <div className="polygon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="25"
                  viewBox="0 0 30 25"
                  fill="none"
                >
                  <path
                    d="M1.00119 2.11222C0.540196 1.41638 1.08424 0.495358 1.91617 0.563243L28.1533 2.70419C28.8892 2.76424 29.3085 3.57374 28.933 4.20947L17.2346 24.0138C16.859 24.6495 15.9477 24.673 15.5399 24.0575L1.00119 2.11222Z"
                    fill="white"
                    filter="url(#drop-shadow)"
                  />
                  <defs>
                    <filter id="drop-shadow">
                      <feDropShadow
                        dx="-4"
                        dy="-2"
                        stdDeviation="2"
                        flood-color="rgba(0,0,0,0.06)"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>
            </div>
              <div className="d-flex align-items-center gap-3 mt-4">
                <button className="btn-accept" onClick={() => handleAcceptDeclineOfferClick("accept")}>Accept</button>
                <button className="btn-decline" onClick={() => handleAcceptDeclineOfferClick("decline")}>Decline</button>
              </div>
            </>
          } */}
        </div>
      </div>
      <div className="send-message-box">
        <div className="d-flex align-items-center gap-3">
          <div className="send-box w-100">
            <div className="d-flex">
              {/*<button className="attecment-btn">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M13.3928 7.80643L8.14135 13.0579C7.49801 13.7012 6.62545 14.0626 5.71563 14.0626C4.80582 14.0626 3.93326 13.7012 3.28992 13.0579C2.64658 12.4145 2.28516 11.542 2.28516 10.6321C2.28516 9.72232 2.64658 8.84977 3.28992 8.20643L8.54135 2.955C8.97024 2.52611 9.55194 2.28516 10.1585 2.28516C10.765 2.28516 11.3467 2.52611 11.7756 2.955C12.2045 3.38389 12.4455 3.9656 12.4455 4.57214C12.4455 5.17869 12.2045 5.76039 11.7756 6.18928L6.51849 11.4407C6.30404 11.6552 6.01319 11.7756 5.70992 11.7756C5.40665 11.7756 5.11579 11.6552 4.90135 11.4407C4.6869 11.2263 4.56643 10.9354 4.56643 10.6321C4.56643 10.3289 4.6869 10.038 4.90135 9.82357L9.75278 4.97786"
                    stroke="#1372DC"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>*/}

              <input
                type="text"
                className="form-control text-size"
                name="text"
                placeholder="Type your message..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyPress={handleKeyPress}
              />
            </div>
            <button className="send-btn" onClick={handleSendMessage}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
              >
                <path
                  d="M14.7575 7.99928C14.7581 8.25173 14.6869 8.49914 14.5524 8.71276C14.4179 8.92637 14.2255 9.09739 13.9975 9.20595L4.23087 13.8326C4.05211 13.9301 3.85409 13.987 3.65087 13.9993C3.42848 13.9988 3.20974 13.9427 3.01456 13.8361C2.81938 13.7295 2.65396 13.5758 2.53337 13.389C2.41277 13.2021 2.34083 12.9881 2.32409 12.7663C2.30736 12.5445 2.34635 12.3221 2.43753 12.1193L3.95753 8.66595H7.65087C7.82768 8.66595 7.99725 8.59571 8.12227 8.47068C8.24729 8.34566 8.31753 8.17609 8.31753 7.99928C8.31753 7.82247 8.24729 7.6529 8.12227 7.52787C7.99725 7.40285 7.82768 7.33261 7.65087 7.33261H3.95753L2.43753 3.91261C2.32739 3.66408 2.29585 3.38783 2.34713 3.12087C2.39841 2.85391 2.53007 2.60901 2.72445 2.41898C2.91883 2.22894 3.16666 2.10287 3.43471 2.05764C3.70276 2.01242 3.97823 2.05021 4.2242 2.16595L13.9909 6.79261C14.22 6.90021 14.4138 7.07082 14.5496 7.28451C14.6854 7.49819 14.7575 7.74611 14.7575 7.99928Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageWindow;
