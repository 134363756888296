import {useQuery} from "@tanstack/react-query";
import {getAllClients} from "../../services/client";
import {useDispatch} from "react-redux";
import {defaultQueryOptions} from "../../utils/queryClient";
import {pageLimit} from "../../appConstants";
import storageManager from "../../helpers/storageManager";
import {HttpsAction} from "../../commonApiFunction/httpsAction";

export const useClientsList = (agencyId, page = 1, searchText = '') => {
  const dispatch = useDispatch();
  const placeholderData = {count: 0, total: 0, page: 0, pageIndex: 0, pageSize: pageLimit, rows: []};

  let queryKey = ['patients-list', agencyId, page];
  if (searchText != null && searchText.length > 0) {
    queryKey.push(searchText);
  }
  return useQuery({
    queryKey,
    queryFn: async () => {
      const response = await dispatch(getAllClients(page, searchText));
      if (response.success) return response.data;
      return placeholderData;
    },
    ...defaultQueryOptions,
    keepPreviousData: true, // pagination related
    enabled: agencyId > 0,
    staleTime: 1000 * 60 * 60 * 2, // 2 hours
    cacheTime: 1000 * 60 * 60 * 2.1, // 2 hours
    placeholderData,
  });
};

const reloadRelatedClientLists = async (queryClient, agencyId) => {
  await queryClient.refetchQueries({type: 'all', queryKey: ['list-patients', agencyId]}, {
    cancelRefetch: false,
  });
  // (async () => {
  // })();

  await queryClient.refetchQueries({type: 'all', queryKey: ['list-patients-with-latlng', agencyId]}, {
    cancelRefetch: false,
  });
  // (async () => {
  // })();
};

export const invalidateClientsList = (queryClient, agencyId) => {
  // queryClient.invalidateQueries({queryKey: ['list-patients', agencyId]}, {refetchActive: true, refetchInactive: true});
  // queryClient.invalidateQueries({queryKey: ['list-patients-with-latlng', agencyId]}, {refetchActive: true, refetchInactive: true});
  // queryClient.invalidateQueries({queryKey: ['patients-list', agencyId]}, {type: 'inactive', refetchType: 'inactive',});
  // queryClient.invalidateQueries({queryKey: ['patients-list', agencyId]});
  // queryClient.refetchQueries({queryKey: ['patients-list', agencyId]});
  // queryClient.resetQueries({queryKey: ['patients-list', agencyId]});

  // // const page = +(storageManager.retrieveClientListCurrentPage() || '1');
  // const pagesCount = +(storageManager.retrieveClientListTotalPages() || '1');
  // for (let page = 0; page < pagesCount; page++) {
  //   queryClient.refetchQueries({type: 'all', queryKey: ['patients-list', agencyId, page]}, {
  //     cancelRefetch: false,
  //   }).then(() => {
  //     if (page === pagesCount - 1)
  //       reloadRelatedClientLists(queryClient, agencyId);
  //   });
  // }
  queryClient.refetchQueries({type: 'all', queryKey: ['patients-list', agencyId]}, {
    cancelRefetch: false,
  }).then(() => {
      reloadRelatedClientLists(queryClient, agencyId);
  });
};

export const useClientMinimalInfo = (agencyId, clientId) => {
  const dispatch = useDispatch();
  const placeholderData = {};

  let queryKey = ['patient-minimal-info', agencyId, clientId];
  return useQuery({
    queryKey,
    queryFn: async () => {
      const response = await dispatch(HttpsAction({
        apiSection: 'client',
        apiName: 'profileMinimalInfo',
        reduxKeyName: 'profileMinimalInfo',
        reducerName: 'CLIENT',
        actionType: 'GET_CLIENT_PROFILE_MINIMAL_INFO',
        queryParameter: `/${clientId}`,
      }));
      const responseObj = response.data;
      if (responseObj.success) return responseObj.data;
      return placeholderData;
    },
    ...defaultQueryOptions,
    keepPreviousData: true, // pagination related
    enabled: agencyId > 0 && clientId > 0,
    staleTime: 1000 * 60 * 60 * 2, // 2 hours
    cacheTime: 1000 * 60 * 60 * 2.1, // 2 hours
    placeholderData,
  });
};

export const invalidatePatientMinimalInfo = (queryClient, agencyId, clientId) => {
  queryClient.refetchQueries({type: 'all', queryKey: ['patient-minimal-info', agencyId, clientId]}, {
    cancelRefetch: false,
  });
};
