import React from "react";
import {Field, reduxForm} from "redux-form";
import CustomInputField from "../../../../../../sharedComponents/reduxfields/customInputField";
import CustomDropDownField from "../../../../../../sharedComponents/reduxfields/customDropDown";
import {connect, useDispatch} from "react-redux";
import {emailValidation, minLength2, required} from "../../../../../../helpers/validation";
import PrimaryButton from "../../../../../../sharedComponents/buttons/PrimaryButton";
import {HttpsAction} from "../../../../../../commonApiFunction/httpsAction";
import {useParams} from "react-router-dom";
import {getAllPersonsList} from "../../../../../../services/commonServices";

const typeOptions = [
    {label: 'Contact', id: "Contact", value: '1'},
    {label: 'Nurse', id: "Nurse", value: '2'},
    {label: 'Client', id: "Client", value: '3'},
    {label: ' Other source', id: " Other source", value: '4'},
];

const CreateReferral = ({handleSubmit, onClose, clientId}) => {

    const dispatch = useDispatch();
    // const {clientId} = useParams();

    const onSubmit = async (data) => {
        data.referralTypeId = Number(data.referralTypeId);
        // data.genderId = 1;
        dispatch(HttpsAction({
            method: 'POST',
            apiSection: 'client',
            apiName: 'referrals',
            queryParameter: `/${clientId}`,
            data,
            positiveCallBack: async () => {
                onClose()
                await dispatch(getAllPersonsList());
                dispatch(HttpsAction({
                    apiSection: 'client',
                    apiName: 'referrals',
                    queryParameter: `/${clientId}`,
                    reducerName: 'CLIENT',
                    reduxKeyName: 'referrals',
                    actionType: 'GET_REFERRALS'
                }))
            }
        }))
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-12">
                        <div className="col-5">
                            <div className="mb-4 input-field-main" style={{paddingRight: 16}}>
                                <label>Type</label>
                                <Field
                                    className="form-control mk-wi"
                                    name="referralTypeId"
                                    component={CustomDropDownField}
                                    requiredMark={true}
                                    validate={required}
                                >
                                    <option value="">Please select</option>
                                    {(typeOptions || [])?.map((res, index) => {
                                        return <option key={index} value={res.value}>{res.label}</option>
                                    })}
                                </Field>
                            </div>
                        </div>
                    </div>
                    <div className="col-5">
                        <div className="mb-4 input-field-main">
                            <Field
                                className="form-control mk-wi"
                                name="firstName"
                                label="First Name"
                                type="text"
                                component={CustomInputField}
                                requiredMark={true}
                                validate={[required, minLength2]}
                            />
                        </div>
                    </div>
                    <div className="col-5">
                        <div className="mb-4 input-field-main">
                            <Field
                                className="form-control mk-wi"
                                name="lastName"
                                label="Last Name"
                                type="text"
                                component={CustomInputField}
                                requiredMark={true}
                                validate={[required, minLength2]}
                            />
                        </div>
                    </div>
                    <div className="col-5">
                        <div className="mb-4 input-field-main">
                            <Field
                                className="form-control mk-wi"
                                name="email"
                                label="Email address"
                                type="text"
                                component={CustomInputField}
                                validate={[required,emailValidation]}
                            />
                        </div>
                    </div>
                    <div
                        className="d-flex justify-content-center"
                        style={{marginTop: "10px"}}>
                        <PrimaryButton
                            type="submit"
                            className="btn-view-repo gap-2 px-5 py-2"
                            text={"Create"}
                            onClick={() => undefined}
                        />
                    </div>
                </div>
            </form>
        </>
    )
};

export default connect()(
    reduxForm({form: "createReferral"})(CreateReferral)
);
