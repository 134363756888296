import React, { useEffect, useState, useContext, useRef } from "react";
import {useParams, useLocation, useNavigate} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MessageSidebar from "../../../Nurses/components/Messages/MessageSidebar";
import MessageWindow from "../../../Nurses/components/Messages/MessageWindow";
import { HttpsAction } from "../../../commonApiFunction/httpsAction";
import {
  getUserMessagesAction,
  getUserChatMessagesAction,
} from "../../../services/messages";
import { debounce } from "lodash";
import io from "socket.io-client";
import {isLoggedIn, parseTokenAndGetData, redirectOrLogout} from "../../../helpers/apiMethods";

const Messages = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();

  const [socket, setSocket] = useState(null);
  const [initialUserSet, setInitialUserSet] = useState(true); // New auxiliary state
  const [buttonActive, setButtonActive] = useState("all");
  const [allButtonClass, setAllButtonClass] = useState("btn bg-white");
  const [unreadButtonClass, setUnreadButtonClass] = useState("btn bg-none");
  const [findUnreadUserMessageChatList, setFindUnreadUserMessageChatList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [preSelectedUser, setPreSelectedUser] = useState(null);
  const selectedUserRef = useRef(selectedUser);
  const [userId, setUserId] = useState(0);
  const [roleId, setRoleId] = useState(0);
  const [chatId, setChatId] = useState(0);
  const [displayName, setDisplayName] = useState('');
  const [loadedOnce, setLoadedOnce] = useState(false);

  const userListForMessage = useSelector((state) => state.messages?.userListForChat);
  const chatListInstance = useSelector((state) => state.messages?.userChatMessages);
  const unReadMessagesId = useSelector((state) => state.messages?.unReadChatMessagesIds);

  // Select first user by default when page redendered
  // useEffect(() => {
  //   console.log("(userListForMessage[0]", userListForMessage)
  //   if (userListForMessage && userListForMessage.length > 0 && initialUserSet) {
  //     setSelectedUser(userListForMessage[0]);
  //     console.log("userListForMessage[0] 12345",userListForMessage[0])
  //     setInitialUserSet(false);
  //   }
  // }, [initialUserSet]);

  useEffect(() => {
    if (location == null || location.state == null) {
      if (Array.isArray(userListForMessage) && userListForMessage.length > 0 && initialUserSet) {
        // console.log("TEST- (userListForMessage[0]", userListForMessage[0])
        setSelectedUser(userListForMessage[0]);
        let queryParams = {
          senderId: chatId,
          receiverId: Number(userListForMessage[0].id),
        };
        if (queryParams.senderId > 0 && queryParams.receiverId > 0) {
          dispatch(getUserChatMessagesAction("userChatMessages", queryParams));
          // dispatch({
          //   type: `USER_READ_MESSAGES`,
          //   payload: user,
          //   name: "userListForChat",
          //   meta: {type: "USER_READ_MESSAGES"},
          // });
          setInitialUserSet(false);
        }
      } else {
        console.log("userListForMessage is either not an array or empty.");
      }
    }
  }, [userListForMessage]); 

  useEffect(() => {
    selectedUserRef.current = selectedUser;
  }, [selectedUser]);

  useEffect(() => {
    // console.log("authUserInstance", authUserInstance);
    if (socket) {
      socket.on("message", (data) => {
        console.log("CHAT- socket_data", data)
        // const mappedMessage = {
        //   internalComment: null,
        //   createdBy: null,
        //   updatedBy: null,
        //   deletedBy: null,
        //   senderId: data?.senderId,
        //   // receiverId: authUserInstance,
        //   receiverId: data.receiverId,
        //   messageTitle: null,
        //   messageText: data?.text,
        //   isUnread: true,
        //   messageId: data?.messageId,
        //   messageTypeId: data?.messageTypeId,
        //   latitude: null,
        //   longitude: null,
        //   phoneTypeId: null,
        //   phoneNumber: null,
        //   createdAt: data?.creationDatetime,
        //   updatedAt: data?.creationDatetime,
        //   messageTime24hr: data?.messageTime24hr,
        //   deletedAt: null,
        // };
        const mappedMessage = {
          ...data,
        };
        console.log('CHAT- received message', mappedMessage)
        if (
          data?.senderId?.toString() === selectedUserRef.current?.id?.toString()
        ) {
          // dispatch({
          //   type: `USER_CHAT_MESSAGES_LIST`,
          //   payload: mappedMessage,
          //   name: "userChatMessages",
          //   meta: { type: "USER_CHAT_MESSAGES_LIST" },
          // });
          dispatch({
            type: `ADD_SAVED_CHAT_MESSAGE`,
            payload: mappedMessage,
            name: "userChatMessages",
            meta: { type: "ADD_SAVED_CHAT_MESSAGE" },
          });
        } else {
          dispatch({
            type: `NEW_MESSAGE_FOR_UNSELECTED_USER`,
            payload: mappedMessage,
            name: "userListForChat",
            meta: { type: "NEW_MESSAGE_FOR_UNSELECTED_USER" },
          });
        }

        //  Emmit event for unread messages of user
        if (selectedUserRef.current?.id !== data?.senderId) {
          // pushing unread messages ids
          dispatch({
            type: `UNREAD_CHAT_MESSAGES_IDS`,
            payload: data?.messageId,
            name: "unReadChatMessagesIds",
            meta: { type: "UNREAD_CHAT_MESSAGES_IDS" },
          });
          socket.emit("do_read_messages", unReadMessagesId);
        }
      });

      socket.on("message_saved", (data) => {
        console.log("CHAT- message_saved", data);
        // const mappedMessage = {
        //   internalComment: null,
        //   createdBy: null,
        //   updatedBy: null,
        //   deletedBy: null,
        //   senderId: data?.messageData?.senderId,
        //   // receiverId: selectedUserRef.current?.id,
        //   receiverId: data?.messageData?.receiverId,
        //   messageTitle: null,
        //   messageText: data?.messageData?.text,
        //   isUnread: true,
        //   messageId: data?.messageData?.messageId,
        //   // messageTypeId: 1,
        //   messageTypeId: data?.messageData?.messageTypeId,
        //   latitude: null,
        //   longitude: null,
        //   phoneTypeId: null,
        //   phoneNumber: null,
        //   createdAt: data?.messageData?.creationDatetime,
        //   updatedAt: data?.messageData?.creationDatetime,
        //   messageTime24hr: data?.messageTime24hr,
        //   deletedAt: null,
        // };
        const mappedMessage = {
          ...data?.messageData,
        };
        if (
          data?.messageData?.senderId.toString() === chatId.toString()
        ) {
          dispatch({
            type: `ADD_SAVED_CHAT_MESSAGE`,
            payload: mappedMessage,
            name: "userChatMessages",
            meta: { type: "ADD_SAVED_CHAT_MESSAGE" },
          });
        }
      })

      socket.on("do_read_messages", (data) => {
        console.log("CHAT- do_read_messages", data);
      });

      // socket.on("message_self", (data) => {
      //   console.log("CHAT- message_self", data);
      // })

      socket.on("message_sent", (data) => {
        console.log("CHAT- message_sent", data);
      });

      socket.on("user_exists", (data) => {
        console.log("CHAT- user_exists", data);
      });

      socket.on("chat_error", (data) => {
        // alert(data);
        console.log("CHAT- chat_error", data);
      });

      socket.on("messages_read_done_ack", (ack) => {
        console.log("CHAT- Received messages_read_done_ack", ack);
        dispatch({
          type: `USER_READ_MESSAGES`,
          payload: selectedUserRef.current,
          name: "userListForChat",
          meta: { type: "USER_READ_MESSAGES" },
        });
      });

      socket.on("agency_to_nurse_offer", (data) => {
        console.log("CHAT- agency_to_nurse_offer", data);
      });
    }
  }, [socket]);

  function loadUserMessage() {
console.log('location-state', location, location.state)
    if (location != null && location.state != null) {
// alert(location.state?.nursePersonalInformationId + ' - ' + (typeof location.state?.nursePersonalInformationId))
      if (location.state.id > 0) {
        let user = {
          ...location.state,
          // totalChatMessages: "",
          // totalUnreadChatMessages: ""
        };
// alert(JSON.stringify(user));
        setPreSelectedUser(user);
        setSelectedUser(user);
        let queryParams = {
          senderId: chatId,
          receiverId: Number(user.id),
        };
        if (queryParams.senderId > 0 && queryParams.receiverId > 0) {
          dispatch(getUserChatMessagesAction("userChatMessages", queryParams));
          dispatch({
            type: `USER_READ_MESSAGES`,
            payload: user,
            name: "userListForChat",
            meta: {type: "USER_READ_MESSAGES"},
          });
          location.state = {};
        }
      }
    }
  }

  const handleUserClick = (user) => {
    setSelectedUser(user);
    let queryParams = {
      senderId: chatId,
      receiverId: Number(user?.id),
    };
    if (queryParams.senderId > 0 && queryParams.receiverId > 0) {
      dispatch(getUserChatMessagesAction("userChatMessages", queryParams));
      dispatch({
        type: `USER_READ_MESSAGES`,
        payload: user,
        name: "userListForChat",
        meta: {type: "USER_READ_MESSAGES"},
      });
    }
  };

  const handleSearchChange = debounce((value) => {
    dispatch(getUserMessagesAction("userListForChat", chatId, value));
  }, 500);

  const onHandleButtonClick = (e) => {
    const name = e.target.name;
    setButtonActive(name);

    if (name === "all") {
      setAllButtonClass("btn bg-white");
      setUnreadButtonClass("btn bg-none");
    } else {
      setUnreadButtonClass("btn bg-white");
      setAllButtonClass("btn bg-none");
      const unreadUsers =
        userListForMessage &&
        userListForMessage.length > 0 &&
        userListForMessage.filter((user) => user.totalUnreadChatMessages !== 0);
      setFindUnreadUserMessageChatList(unreadUsers);
    }
  };

  useEffect(() => {
    if (loadedOnce) {
      setLoadedOnce(false);
      console.log('token-data', userId, roleId, chatId, `"${displayName}"`);
      loadUserMessage();
      dispatch(getUserMessagesAction("userListForChat", chatId, ""));
    }
  }, [loadedOnce]);

  useEffect(() => {
    let socketInstance = null;
    if (!isLoggedIn()) {
      redirectOrLogout(dispatch, navigate);
    } else {
      const tokenInfo = parseTokenAndGetData();
      const decodedTokenData = tokenInfo.data;
      const chatIdVal = decodedTokenData.chatId;
      setUserId(decodedTokenData.userId);
      setRoleId(decodedTokenData.roleId);
      setChatId(chatIdVal);
      setDisplayName(`${decodedTokenData.firstName} ${decodedTokenData.lastName}`);
      setLoadedOnce(true);
// alert(chatIdVal)

      // ----- Socket Start -------
      socketInstance = io.connect(process.env.REACT_APP_API_BASE_URL, {
        path: "/chatio/socket.io",
        transports: ["websocket", "polling"],
        query: {token: tokenInfo.token}
      });
      setSocket(socketInstance);
      let payload = {
        userId: chatIdVal,
      };
      socketInstance.emit("register_user", payload);
    }
    return () => {
      // Disconnect socket when component unmounts
      if (socketInstance)
        return socketInstance.disconnect();
    };
    // REFRENCE: https://www.oneclickitsolution.com/blog/socket-io-in-reactjs/
    // ----- Socket Ends -------
  }, []);

  return (
    <div className="app-main__outer">
      <div className="d-flex message-main-content">
        <div className="messagesidebar px-0 px-xl-3">
          <MessageSidebar
            handleButtonClick={onHandleButtonClick}
            handleSearchChange={(event) =>
              handleSearchChange(event.target.value)
            }
            allButtonClass={allButtonClass}
            unreadButtonClass={unreadButtonClass}
            userListForChat={
              buttonActive === "all"
                ? userListForMessage
                : findUnreadUserMessageChatList
            }
            handleUserClick={handleUserClick}
            selectedUser={selectedUser}
            preSelectedUser={preSelectedUser}
          />
        </div>
        <div className="messagewindow w-100">
          <MessageWindow
            chatList={chatListInstance}
            selectedUser={selectedUser}
            chatId={chatId}
            socket={socket}
          />
        </div>
      </div>
    </div>
  );
};

export default Messages;
