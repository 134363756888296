import React, {useEffect, useState} from "react";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import CustomDropDownNewField from "../../../../sharedComponents/reduxfields/customDropDownNew";
import CustomInputField from "../../../../sharedComponents/reduxfields/customInputField";
import PrimaryButton from "../../../../sharedComponents/buttons/PrimaryButton";
import Certificationcomnplete from "./Certificationcomplete";
import {connect, useDispatch, useSelector} from "react-redux";
import {change, Field, getFormValues, reduxForm} from "redux-form";
import {getNurseAction, postNurseAction} from "../../../../services/NursesProfile";
import {required} from "../../../../helpers/validation";
import * as nurseAction from "../nurseActionTypes";
import Modal from "../../../../sharedComponents/Modal";
import {useQueryClient} from "@tanstack/react-query";
import booleanHelperFunctions from "../../../../helpers/boolean.helpers";
import {HttpsAction} from "../../../../commonApiFunction/httpsAction";
import numberHelperFunctions from "../../../../helpers/number.helpers";
import stringHelperFunctions from "../../../../helpers/string.helpers";
import SaveButton from "../../../../sharedComponents/buttons/SaveButton";
import CustomTextarea from "../../../../sharedComponents/reduxfields/CustomTextArea";
import classes from "../../Client/ClientProfile/CareNote/CareNote.module.css";
import useCustomNav from "../../../../utils/hooks/useCustomNav";

const CertificationNew = ({formData, listLength, notNewAndNotDeletedListLength, handleSubmit, pristine, submitting, isAdmin, agencyId}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { id : nurseId } = useParams();
  const { nurseId } = useOutletContext();

  const reminderDaysArray = queryClient.getQueryData(['list-reminder-days']);
  const certificateTypesArray = queryClient.getQueryData(['list-certificate-types', agencyId]);

  const { goTo: gotoOtherOrEvalsPage } = useCustomNav(isAdmin ? `/admin/agency/nurses/edit/profile/certs-evals/otherCertifications` : '/agency/nurses/edit/profile/certs-evals/evaluations');

  const [show, setShow] = useState(false);
  const [allowAutoAddition, setAllowAutoAddition] = useState(true);
  const [dataModified, setDataModified] = useState(false);
  const [xtraFieldValue, setXtraFieldValue] = useState(1);

  function convertToArray(removeUiFields = false) {
    const payload = [];
    for (let i = 0; i < listLength; i++) {
      if (formData[`recordType${i + 1}`] !== "deleted") {
        const recordType = formData[`recordType${i + 1}`];
        console.log(`index ==> ${i} ==>`, formData[`recordType${i + 1}`])
        let id = formData[`id${i + 1}`];
        const item = {
          recordType,
          "id": id === 0 ? null : Number(id),
          "certificationEvaluationId": numberHelperFunctions.nullIfEmpty(formData[`certificationEvaluationId${i + 1}`]),
          "expiryDate": stringHelperFunctions.nullIfEmpty(formData[`expiryDate${i + 1}`]),
          "reminderDaysId": numberHelperFunctions.nullIfEmpty(formData[`reminderDaysId${i + 1}`]),
          "note": stringHelperFunctions.nullIfEmpty(formData[`note${i + 1}`]),
          "renewalRequired": booleanHelperFunctions.nullIfEmpty(formData[`renewalRequired${i + 1}`]),
          "renewalDate": stringHelperFunctions.nullIfEmpty(formData[`renewalDate${i + 1}`]),
          "renewalReminderDaysId": numberHelperFunctions.nullIfEmpty(formData[`renewalReminderDaysId${i + 1}`], true),
        };
        if (!removeUiFields) {
          item["checkIfComplete"] = numberHelperFunctions.nullIfEmpty(formData[`checkIfComplete${i + 1}`]);
        }
        payload.push(item);
      } else {
        const recordType = formData[`recordType${i + 1}`];
        let id = formData[`id${i + 1}`];
        payload.push({recordType, "id": id === 0 ? null : Number(id)})
      }
    }
    return payload;
  }

  const HandleClickAddCertification = () => {
    let updatedList = Array.from({length: listLength}, (v, i) => i).map((val, index) => {
      return {
        recordType: formData[`recordType${index + 1}`],
        id: formData[`id${index + 1}`],
        checkIfComplete: formData[`checkIfComplete${index + 1}`],

        certificationEvaluationId: numberHelperFunctions.nullIfEmpty(formData[`certificationEvaluationId${index + 1}`]),
        expiryDate: stringHelperFunctions.nullIfEmpty(formData[`expiryDate${index + 1}`]),
        reminderDaysId: numberHelperFunctions.nullIfEmpty(formData[`reminderDaysId${index + 1}`]),
        note: stringHelperFunctions.nullIfEmpty(formData[`note${index + 1}`]),
        // nurseCertificationRenewalRequired: booleanHelperFunctions.nullIfEmpty(formData[`renewalRequired${index + 1}`]),
        // nurseCertificationRenewalDate: stringHelperFunctions.nullIfEmpty(formData[`renewalDate${index + 1}`]),
        // nurseCertificationRenewalReminderDaysId: numberHelperFunctions.nullIfEmpty(formData[`renewalReminderDaysId${index + 1}`]),
        renewalRequired: booleanHelperFunctions.nullIfEmpty(formData[`renewalRequired${index + 1}`]),
        renewalDate: stringHelperFunctions.nullIfEmpty(formData[`renewalDate${index + 1}`]),
        renewalReminderDaysId: numberHelperFunctions.nullIfEmpty(formData[`renewalReminderDaysId${index + 1}`]),
        // index,
      };
    });
    dispatch(HttpsAction({
      reducerName: 'NURSE',
      reduxKeyName: 'nurseCertifications',
      actionType: 'NURSE_CERTIFICATIONS',
      data: {xtraField: formData['xtraField'], list: updatedList},
      updateData: true,
      actionTypeIsDispatchType: true,
      positiveCallBack: (e) => {
        dispatch({ type: nurseAction.NURSE_ADD_CERTIFICATION });
        setDataModified(true);
      },
    }));
  };

  const handleClose = (data) => {
    setShow(false);
    if (data) {
      const idx = data.index;

      let clonedCertificationsList = Array.from({length: listLength}, (v, i) => i).map((val, index) => {
        return {
          recordType: formData[`recordType${index + 1}`],
          id: formData[`id${index + 1}`],
          checkIfComplete: formData[`checkIfComplete${index + 1}`],

          certificationEvaluationId: formData[`certificationEvaluationId${index + 1}`],
          expiryDate: formData[`expiryDate${index + 1}`],
          reminderDaysId: formData[`reminderDaysId${index + 1}`],
          note: formData[`note${index + 1}`],
          // nurseCertificationRenewalRequired: formData[`renewalRequired${index + 1}`],
          // nurseCertificationRenewalDate: formData[`renewalDate${index + 1}`],
          // nurseCertificationRenewalReminderDaysId: formData[`renewalReminderDaysId${index + 1}`],
          renewalRequired: formData[`renewalRequired${index + 1}`],
          renewalDate: formData[`renewalDate${index + 1}`],
          renewalReminderDaysId: formData[`renewalReminderDaysId${index + 1}`],
          // index,
        };
      });

      if (data && idx) {
        clonedCertificationsList[idx - 1].renewalDate = data.renewalDate || null;
        clonedCertificationsList[idx - 1].renewalReminderDaysId = data.renewalReminderDaysId || null;
        clonedCertificationsList[idx - 1].renewalRequired = data.renewalRequired || false;
      }
      dispatch(HttpsAction({
        reducerName: 'NURSE',
        reduxKeyName: 'nurseCertifications',
        actionType: 'NURSE_CERTIFICATIONS',
        data: {xtraField: formData['xtraField'], list: clonedCertificationsList},
        updateData: true,
        actionTypeIsDispatchType: true,
        positiveCallBack: (_) => {
          setDataModified(true);
        },
      }));
    }
  };

  const handleShow = async (index) => {
    const payload = convertToArray().map(item => ({
      id: item.id,
      renewalRequired: item.renewalRequired,
      renewalDate: item.renewalDate,
      renewalReminderDaysId: item.renewalReminderDaysId,
    }));
    setShow({...payload[index - 1], index});
  };

  const onSubmit = async (data) => {
    const payload = convertToArray(true);
// console.log('test- payload', payload)
    const response = await dispatch(postNurseAction(payload, "certificationsList", nurseId, "Nurse certification Created/Updated Successfully"));
    if (response.success) {
      // await dispatch(getNurseAction("certifications", nurseId));
      // navigate(isAdmin ? `/admin/agencyNurses/edit/${nurseId}/certification` : `/agency/nurses/edit/${nurseId}/certification`)
      // navigate("/agency/nurses/addnewrequest/availabilityblocks")
      // navigate(isAdmin ? `/admin/agencyNurses/edit/${nurseId}/otherCertifications` : `/agency/nurses/edit/${nurseId}/evaluations`);
      gotoOtherOrEvalsPage();
    }
  };

  const HandleClickDeleteCertification = (deletedIndex) => {
    const list = Array.from({ length: listLength }, (v, i) => i);
    let updatedList = list.map((val, index) => {
      return {
        recordType: formData[`recordType${index + 1}`],
        id: formData[`id${index + 1}`],
        checkIfComplete: formData[`checkIfComplete${index + 1}`],

        // certificationEvaluationId: formData[`certificationEvaluationId${index + 1}`],
        // expiryDate: formData[`expiryDate${index + 1}`],
        // reminderDaysId: formData[`reminderDaysId${index + 1}`],
        // note: formData[`note${index + 1}`],
        // renewalRequired: formData[`renewalRequired${index + 1}`],
        // renewalDate: formData[`renewalDate${index + 1}`],
        // renewalReminderDaysId: formData[`renewalReminderDaysId${index + 1}`],

        certificationEvaluationId: numberHelperFunctions.nullIfEmpty(formData[`certificationEvaluationId${index + 1}`]),
        expiryDate: stringHelperFunctions.nullIfEmpty(formData[`expiryDate${index + 1}`]),
        reminderDaysId: numberHelperFunctions.nullIfEmpty(formData[`reminderDaysId${index + 1}`]),
        note: stringHelperFunctions.nullIfEmpty(formData[`note${index + 1}`]),
        // nurseCertificationRenewalRequired: booleanHelperFunctions.nullIfEmpty(formData[`renewalRequired${index + 1}`]),
        // nurseCertificationRenewalDate: stringHelperFunctions.nullIfEmpty(formData[`renewalDate${index + 1}`]),
        // nurseCertificationRenewalReminderDaysId: numberHelperFunctions.nullIfEmpty(formData[`renewalReminderDaysId${index + 1}`]),
        renewalRequired: booleanHelperFunctions.nullIfEmpty(formData[`renewalRequired${index + 1}`]),
        renewalDate: stringHelperFunctions.nullIfEmpty(formData[`renewalDate${index + 1}`]),
        renewalReminderDaysId: numberHelperFunctions.nullIfEmpty(formData[`renewalReminderDaysId${index + 1}`]),
        // index,
      };
    });
    const deletedItemIdValue = formData[`id${deletedIndex}`];
    if (deletedItemIdValue === '0') { // item not present in database, so remove item from list/array
      updatedList.splice(deletedIndex - 1, 1);
    } else { // item present in database, so identify/mark it for deletion for later API call
      updatedList[deletedIndex - 1].recordType = "deleted";
    }
// console.log('test- updatedList', updatedList)
    setAllowAutoAddition(false);
    dispatch(HttpsAction({
      reducerName: 'NURSE',
      reduxKeyName: 'nurseCertifications',
      actionType: 'NURSE_CERTIFICATIONS',
      data: {xtraField: formData['xtraField'], list: updatedList},
      updateData: true,
      actionTypeIsDispatchType: true,
      positiveCallBack: (_) => {
        setDataModified(true);
      },
    }));
  };

  const handleSelectName = (target, index) => {
    let clonedCertificationsList = Array.from({length: listLength}, (v, i) => i).map((val, index) => {
      return {
        recordType: formData[`recordType${index + 1}`],
        id: formData[`id${index + 1}`],
        checkIfComplete: formData[`checkIfComplete${index + 1}`],

        certificationEvaluationId: formData[`certificationEvaluationId${index + 1}`],
        expiryDate: formData[`expiryDate${index + 1}`],
        reminderDaysId: formData[`reminderDaysId${index + 1}`],
        note: formData[`note${index + 1}`],
        // nurseCertificationRenewalRequired: formData[`renewalRequired${index + 1}`],
        // nurseCertificationRenewalDate: formData[`renewalDate${index + 1}`],
        // nurseCertificationRenewalReminderDaysId: formData[`renewalReminderDaysId${index + 1}`],
        renewalRequired: formData[`renewalRequired${index + 1}`],
        renewalDate: formData[`renewalDate${index + 1}`],
        renewalReminderDaysId: formData[`renewalReminderDaysId${index + 1}`],
        // index,
      };
    });
    if (target?.value && index) {
      // clonedCertificationsList[index - 1] = {...clonedCertificationsList[index - 1], checkIfComplete: true};
      clonedCertificationsList[index - 1].checkIfComplete = true;
      clonedCertificationsList[index - 1].certificationEvaluationId = target.value;
    } else if (target?.value === "" && index) {
      // clonedCertificationsList[index - 1] = {...clonedCertificationsList[index - 1], checkIfComplete: false};
      clonedCertificationsList[index - 1].checkIfComplete = false;
    }
    dispatch(HttpsAction({
      reducerName: 'NURSE',
      reduxKeyName: 'nurseCertifications',
      actionType: 'NURSE_CERTIFICATIONS',
      data: {xtraField: formData['xtraField'], list: clonedCertificationsList},
      updateData: true,
      actionTypeIsDispatchType: true,
      positiveCallBack: (_) => {
        setDataModified(true);
      },
    }));
  };

  useEffect(() => {
    if (dataModified) {
      setDataModified(false);
      dispatch(change("CertificationsForm", 'xtraField', String(xtraFieldValue), true));
      setXtraFieldValue(prevState => prevState + 1);
    }
  }, [dataModified]);

  useEffect(() => {
    if (allowAutoAddition && listLength === 0) {
      dispatch({type: nurseAction.NURSE_ADD_CERTIFICATION});
    }
  }, [listLength]);

  useEffect(() => {
    dispatch(getNurseAction("certifications", nurseId));

    return () => {
      dispatch(HttpsAction({
        reducerName: 'NURSE',
        reduxKeyName: 'nurseCertifications',
        actionType: 'NURSE_CERTIFICATIONS',
        removeData: true,
        actionTypeIsDispatchType: true,
      }));
    };
  }, []);
// console.log('test- listLength', listLength)

  if (listLength === 0) {
    return null;
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Field
          name="xtraField"
          type="hidden"
          component={CustomInputField}
        />
        <div className="p-0 m-0 d-flex flex-column justify-content-start align-content-start gap-3">
          {listLength > 0 && (Array.from({length: listLength}, (v, i) => i) || []).map((item, index) => (
            <React.Fragment key={index}>
              <div className="card" style={{
                overflow: "hidden",
                borderWidth: formData != null && JSON.stringify(formData) !== '{}' && formData[`recordType${index + 1}`] === "deleted" ? "0" : "auto",
                height: formData != null && JSON.stringify(formData) !== '{}' && formData[`recordType${index + 1}`] === "deleted" ? "1px" : "auto",
              }}>
                <div className="card-body">
                  <div className={`row ${index === 0 ? '' : 'mt-2'}`}>
                    <div className="col-6 d-flex align-items-center">
                      <div className={`col-4`} style={{fontSize: "18px", fontWeight: 500, whiteSpace: "nowrap"}}>
                        Certification #{index + 1}
                      </div>
                    </div>
                    <div className="col-6 d-flex justify-content-end">
                      <Field
                        name={`recordType${index + 1}`}
                        type="hidden"
                        component={CustomInputField}
                      />
                      <Field
                        name={`id${index + 1}`}
                        type="hidden"
                        component={CustomInputField}
                      />
                      <Field
                        name={`renewalRequired${index + 1}`}
                        type="hidden"
                        component={CustomInputField}
                      />
                      <Field
                        name={`renewalDate${index + 1}`}
                        type="hidden"
                        component={CustomInputField}
                      />
                      <Field
                        name={`renewalReminderDaysId${index + 1}`}
                        type="hidden"
                        component={CustomInputField}
                      />
                      {(notNewAndNotDeletedListLength > 0 || listLength > 1 || index > 0) &&
                        <PrimaryButton
                          type="button"
                          className="btn-view-repo gap-2 px-4 py-2"
                          text={"Delete"}
                          onClick={() => HandleClickDeleteCertification(index + 1)}
                        />}
                    </div>
                    <div className={classes.contactFormHeadingBorder}/>
                    <div style={{padding: '24px 24px 0 24px'}}>
                      <div>
                        <div className="row">
                          <div className="col-12 col-md-4">
                            <Field
                              className="form-control mk-wi"
                              label="Name"
                              name={`certificationEvaluationId${index + 1}`}
                              component={CustomDropDownNewField}
                              requiredMark={true}
                              validate={required}
                              onChange={({target}) => handleSelectName(target, index + 1)}
                            >
                              <option value="">Select Certificate</option>
                              {(certificateTypesArray || []).map((res, index) => (
                                <option key={index} value={res.id}>
                                  {res.name}
                                </option>
                              ))}
                            </Field>
                          </div>
                          <div className="col-12 col-md-4">
                            <Field
                              className="form-control mk-wi"
                              name={`expiryDate${index + 1}`}
                              label="Expiry Date"
                              placeholder="02/04/2032"
                              type="date"
                              component={CustomInputField}
                              requiredMark={true}
                              validate={required}
                              // onChange={(e) => HandleInputChange(e, item.uid)}
                            ></Field>
                          </div>
                          <div className="col-12 col-md-4">
                            <Field
                              className="form-control mk-wi"
                              label="Reminder Days"
                              name={`reminderDaysId${index + 1}`}
                              component={CustomDropDownNewField}
                              requiredMark={true}
                              validate={required}
                              // onChange={(e) => HandleInputChange(e, item.uid)}
                            >
                              <option value="">Select Reminder Days</option>
                              {(reminderDaysArray || []).map((res, index) => (
                                <option key={index} value={res.id}>
                                  {res.name}
                                </option>
                              ))}
                            </Field>
                          </div>
                          <div className="col-12">
                            <Field
                              className="form-control mk-wi"
                              style={{height: '80px'}}
                              type="textarea"
                              name={`note${index + 1}`}
                              label="Note"
                              component={CustomTextarea}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="fileUploadsContainer"></div>
                  {formData != null && formData[`checkIfComplete${index + 1}`] &&
                    <a className="btn btn-primary rounded-0 border-btn-info"
                       onClick={() => handleShow(index + 1)}>
                      Click if complete
                    </a>}
                </div>
              </div>
            </React.Fragment>
          ))}
          <div className={'w-100 d-flex flex-row justify-content-center gap-2'}>
            <PrimaryButton
              type="button"
              className="btn btn-primary w-100 rounded-0 border-btn-info"
              style={{maxWidth: '210px'}}
              text="Add Another Certification"
              onClick={() => HandleClickAddCertification()}
            />
          </div>
        </div>
        <SaveButton pristine={pristine} submitting={submitting}
                    onClick={() => {
                      dispatch(change("CertificationsForm", 'xtraField', String(xtraFieldValue)));
                      setXtraFieldValue(prevState => prevState + 1);
                      return undefined;
                    }}
        />
      </form>
      {show && <Modal isOpen={show} modalTitle={'Certification Complete'} onClose={handleClose}>
        <Certificationcomnplete type={'certifications'} onClose={handleClose} inputData={show} />
      </Modal>}
    </>
  );
};

const mapStateToProps = (state) => {
  if (state.nurseListing?.nurseCertifications != null) {
    const {auth, nurseListing: {nurseCertifications: {xtraField = '0', list: dataList = []} = {xtraField: '0', list: []}}} = state;

    let initialValues = {xtraField};
    dataList?.forEach((cert, index) => {
// console.log('test- cert', cert)
      initialValues[`recordType${index + 1}`] = cert?.recordType || "existing";
      initialValues[`id${index + 1}`] = cert?.id?.toString() || 0;
      initialValues[`checkIfComplete${index + 1}`] = cert.checkIfComplete || false;
      initialValues[`certificationEvaluationId${index + 1}`] = cert?.certificationEvaluationId?.toString() || "";
      initialValues[`expiryDate${index + 1}`] = cert?.expiryDate || "";
      initialValues[`reminderDaysId${index + 1}`] = cert?.reminderDaysId?.toString() || "";
      initialValues[`note${index + 1}`] = cert?.note || "";
      initialValues[`renewalRequired${index + 1}`] = booleanHelperFunctions.convertToString(cert?.renewalRequired);
      initialValues[`renewalDate${index + 1}`] = cert?.renewalDate || "";
      initialValues[`renewalReminderDaysId${index + 1}`] = cert?.renewalReminderDaysId?.toString() || "";
    });
    return {
      isAdmin: auth?.authData?.isAdmin || false,
      agencyId: auth?.authData?.agencyId || auth.agencyId,
      listLength: (dataList || []).length,
      notNewAndNotDeletedListLength: (dataList || []).filter(p => !(p.recordType === 'new' || p.recordType === 'deleted')).length,
      formData: getFormValues('CertificationsForm')(state),
      initialValues,
    };
  }

  return {};
};

export default connect(mapStateToProps)(
  reduxForm({form: "CertificationsForm", enableReinitialize: true})(CertificationNew)
);
