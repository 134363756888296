import React, {useEffect, useState} from "react";
import classes from "../CareNote/CareNote.module.css";
import {Stack} from "@mui/material";
import PrimaryButton from "../../../../../sharedComponents/buttons/PrimaryButton";
import {Field, reduxForm, reset} from "redux-form";
import CustomInputField from "../../../../../sharedComponents/reduxfields/customInputField";
import {connect, useDispatch, useSelector} from "react-redux";
import Table from "../../../../../sharedComponents/table/Table";
import {HttpsAction} from "../../../../../commonApiFunction/httpsAction";
import {useOutletContext, useParams} from "react-router-dom";
import {toastr} from "react-redux-toastr";
import {getDownloadUrl} from "../../../../../helpers/apiMethods";
import dateHelperFunctions from "../../../../../helpers/date.helpers";
import FileInput from "../../../../../sharedComponents/fileInput";
import CustomLabel from "../../../../../sharedComponents/fields/CustomLabel";

export const bytesToMB = (fileSizeInBytes) => {
    if (fileSizeInBytes) {
        const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
        return fileSizeInMB.toFixed(2) + " MB";
    }
}

export const downloadFile = (file) => {
    const a = window.document.createElement("a");
    window.document.body.appendChild(a);
    a.style.display = "none";

    a.href = file.url;
    a.download = file.name;
    a.click();

    // Remove the anchor from the body after triggering the download
    window.document.body.removeChild(a);
};

const Document = ({handleSubmit}) => {
    const dispatch = useDispatch();
    // const {clientId} = useParams();
    const { clientId } = useOutletContext();

    const documentList = useSelector(state => state.client?.documentList?.data || []);

    const [document, setDocument] = useState();

    const transformedArray = (documentList || [])?.map((item, index) => {
        return {
            id: item.id,
            file: item?.documentName,
            size: bytesToMB(parseInt(item?.documentSize)),
            uploadDate: item?.uploadDateFormatUs || 'N/A',
            expires: dateHelperFunctions.updateDateFormat(item?.expiryDate) || 'No Expiry',
        }
    });

    const serviceHeaderData = [
        {
            title: "File",
            dataIndex: "file",
        },
        {
            title: "Size",
            dataIndex: "size",
            width: '110px',
        },
        {
            title: "Date",
            dataIndex: "uploadDate",
            width: '110px',
        },
        {
            title: "Expires",
            dataIndex: "expires",
            width: '110px',
        }
    ];

    const actionButton = [
        {
            actionName: 'DOWNLOAD',
            // buttonAction: (id, index) => downloadDocument(documentList[index].documentId),
            buttonAction: (id, index) => getDownloadUrl('client', 'documents', clientId, documentList[index].documentId),
            actionClass: `btn btn-primary1 border-btn-info px-4 ${classes.blueButton}`,
            iconClass: '',
        },
        // {
        //     actionName: 'RENAME',
        //     buttonAction: (id) => undefined,
        //     actionClass: `btn btn-primary border-btn-info px-4 ${classes.grayButton}`,

        //     iconClass: '',
        // }, 
        {
            actionName: 'DELETE',
            actionClass: `btn btn-primary border-btn-info px-4 ${classes.redButton}`,
            buttonAction: (id, index) => deleteDocument(documentList[index].documentId),
            iconClass: '',
        },
    ]


    const onSubmit = ({expiryDate}) => {
        if (document) {
            const formData = new FormData();

            formData.append('document', document);
            if (expiryDate != null) formData.append('expiryDate', expiryDate);
            formData.append('documentTypeId', 7);

            dispatch(HttpsAction({
                method: 'POST',
                apiSection: 'client',
                apiName: 'documents',
                queryParameter: `/${clientId}`,
                data: formData,
                positiveCallBack: () => {
                    getData();
                    setDocument(null);
                }
            }));
            dispatch(reset('clientDocument'));
        }
    };

    const getData = () => {
        dispatch(HttpsAction({
            apiSection: 'client',
            apiName: 'documents',
            queryParameter: `/${clientId}`,
            actionType: 'GET_CLIENT_DOCUMENT',
            reduxKeyName: 'documentList',
            reducerName: 'CLIENT',
        }))
    }
    const downloadDocument = (id) => {
        dispatch(HttpsAction({
            apiSection: 'client',
            apiName: 'documents',
            queryParameter: `/${clientId}/${id}`,
            positiveCallBack: () => {
            }
        }))
    }
    const deleteDocument = (id) => {
        dispatch(HttpsAction({
            method: 'DELETE',
            apiSection: 'client',
            apiName: 'documents',
            queryParameter: `/${clientId}/${id}`,
            positiveCallBack: () => {
                getData()
            }
        }))
    }

    const uploadDocument = () => {
        window.document.getElementById("form-submit-btn").click();
    };

    // const handleFileBrowse = (selectedFile) => {
    //     // const selectedFile = target.files[0];
    //
    //     // Check if file size exceeds 100MB
    //     if (selectedFile.size > maxFileSizeMbs * 1024 * 1024) {
    //         toastr.error(`Selected file exceeds the maximum allowed size of ${maxFileSizeMbs}MB.`)
    //         setDocument(null);
    //     } else {
    //         setDocument(() => selectedFile);
    //     }
    // }


    useEffect(() => {
        getData();
    }, [])

    return (
        <>
            <Stack className={classes.contactMain}>
                <Stack className={classes.contactFormMain}>
                    <div className={'d-flex justify-content-between'}>
                        <div className={'d-flex justify-content-center align-items-center gap-3'}>
                            {/*<label htmlFor="fileInput" className={classes.fileBrowseButton}>
                                <span className="w-min" style={{
                                    width: "80%",
                                    textAlign: 'center',
                                    lineHeight: '16px'
                                }}>BROWSE TO UPLOAD</span>
                            </label>
                            <input
                                type="file"
                                id="fileInput"
                                style={{display: 'none'}}
                                onChange={({target}) => handleFileBrowse(target)}
                                multiple={false}
                            />
                            <div style={{fontSize: '14px'}}>
                                Maximum allowed file size is {maxFileSizeMbs}.00MB
                            </div>*/}
                            <FileInput
                              id="fileInput"
                              label="BROWSE TO UPLOAD"
                              handleFileBrowse={(selectedFile) => setDocument(() => selectedFile)}
                              accept="*"
                              preview={false}
                              previewSquareWidth="100px"
                              fileUploadedSuccessfully={document == null}
                            />
                        </div>
                        {/*<PrimaryButton
                            type="button"
                            className="btn-view-repo gap-2 px-4 py-2"
                            text={"Start Upload"}
                            onClick={() => uploadDocument()}
                            hidden={document == null}
                        />*/}
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row mt-3" hidden={document == null}>
                            <div className="col-4">
                                <div className="mb-4 input-field-main">
                                    <Field
                                      placeholder={'1/11/2024'}
                                      className="form-control mk-wi"
                                      name="expiryDate"
                                      label="Expires"
                                      type="date"
                                      component={CustomInputField}
                                    />
                                </div>
                            </div>
                            <div className="col-4">
                                <div class="mb-4 input-field-main">
                                    <div class="w-max h-auto p-0 m-0 mb-3 d-flex flex-column position-relative">
                                        <CustomLabel empty={true}/>
                                        <PrimaryButton
                                          type="button"
                                          className="btn-view-repo gap-2 px-4 py-2"
                                          text={"Upload file"}
                                          onClick={() => uploadDocument()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button style={{display: "none"}} type={'submit'} id={'form-submit-btn'}>&nbsp;</button>
                    </form>
                    <div className={'mt-3'}>
                        <Table headers={serviceHeaderData} rows={transformedArray} actions={actionButton} hasFixedWidth={true} actionColWidth="140px" tableClass="charting-table"/>
                    </div>
                </Stack>
            </Stack>
        </>
    )
};

export default connect()(
  reduxForm({form: "clientDocument", enableReinitialize: true})(Document)
);
