import React from "react";

const Modal = ({isOpen, onClose, children, modalTitle, modalDialogClass = null, modalBodyClass = null}) => {
    let bodyClass = "modal-body d-flex flex-column";
    if (modalBodyClass != null) {
      bodyClass += ' ' + modalBodyClass;
    }

    let dialogClass = 'modal-dialog w-100 d-flex align-items-center';
    if (modalDialogClass != null) {
      dialogClass += ' ' + modalDialogClass;
    }

    if (!isOpen) return null;
    return (
        <div
            className={`modal fade w-100 h-100 show active d-flex justify-content-center bg-black bg-opacity-25`}
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div className={dialogClass}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-6" id="exampleModalLabel">
                            {modalTitle}
                        </h1>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={() => onClose()}
                        ></button>
                    </div>
                    <div className={bodyClass}>{children}</div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
