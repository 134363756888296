import React, {useEffect, useState} from "react";
import {Stack} from "@mui/material";
import {change, Field, reduxForm} from "redux-form";
import CustomInputField from "../../../../sharedComponents/reduxfields/customInputField";
import classes from "../../../components/Client/css/careInformation.module.css";
import CustomDropDownField from "../../../../sharedComponents/reduxfields/customDropDown";
import {connect, useDispatch, useSelector} from "react-redux";
import {statusOptions} from "./AccountDetails";
import CustomCheckboxField from "../../../../sharedComponents/reduxfields/CustomCheckBox";
import CustomInputTextareaField from "../../../../sharedComponents/reduxfields/customTextareaField";
import {minLength, minLength10, minLength15, required} from "../../../../helpers/validation";
import PrimaryButton from "../../../../sharedComponents/buttons/PrimaryButton";
import {HttpsAction} from "../../../../commonApiFunction/httpsAction";
import {useParams} from "react-router-dom";
import {getNurseAction} from "../../../../services/NursesProfile";
import CustomTextarea from "../../../../sharedComponents/reduxfields/CustomTextArea";
import stringHelperFunctions from "../../../../helpers/string.helpers";
import booleanHelperFunctions from "../../../../helpers/boolean.helpers";

const NurseActiveInactivateModal = ({handleSubmit, nurseId, onClose, handleChange}) => {
    const dispatch = useDispatch();
    // const {nurseId} = useParams();

    const [isActive, setIsActive] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [saveButtonText, setSaveButtonText] = useState('');

    const data = useSelector(state => state.nurseListing?.nurseAccountDetails);

    const onSubmit = ({
                          active,
                          effectiveEndDate,
                          terminationReason,
                          cancelVerifiedVisits,
                      }) => {

        let payload = {};

        if (active === '0') {
            payload = {
                active: false,
                effectiveEndDate: stringHelperFunctions.nullIfEmpty(effectiveEndDate),
                terminationReason: stringHelperFunctions.nullIfEmpty(terminationReason),
                cancelVerifiedVisits: booleanHelperFunctions.nullIfEmpty(cancelVerifiedVisits),
            }
        } else {
            payload = {
                active: true,
            }
        }

        dispatch(
            HttpsAction({
                method: "PUT",
                apiSection: "nursesProfile",
                apiName: "setEffectiveEndDate",
                queryParameter: `/${nurseId}`,
                data: payload,
                singleOperation: false,
                positiveCallBack: () => {
                    dispatch(getNurseAction("accountInfo", nurseId, undefined, function () {
                        onClose();
                    }));
                },
            })
        );
    };

    function updateSaveButtonText(isActiveSelected) {
        let text = 'Set Effective End Date';
        if (isActiveSelected) text = 'Activate';
        setSaveButtonText(text);
    }

    const handleStatusChanged = target => {
        handleChange(target);
        const {name, value} = target;
        updateSaveButtonText(value === '1');
        setIsActive(value === '1');
        const activeValue = String(data?.active || false);
        setIsDisabled(value === activeValue);
    };

    useEffect(() => {
        const activeValue = String(data?.active || false);
        setIsActive(activeValue === '1');
        updateSaveButtonText(activeValue === '1');
    }, [data]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-10">
                        <div className="mb-4 input-field-main">
                            <label>Status</label>
                            <Field
                                className="form-control mk-wi"
                                name="active"
                                component={CustomDropDownField}
                                validate={required}
                                requiredMark={true}
                                onChange={({target}) => handleStatusChanged(target)}
                            >
                                <option value="">Please select</option>
                                {statusOptions?.map((res, index) => {
                                    return (
                                        <option key={index} value={res.value}>
                                            {res.label}
                                        </option>
                                    );
                                })}
                            </Field>
                        </div>
                    </div>
                    {!isActive &&
                    <>
                        <div className="col-10">
                            <div className="mb-4 input-field-main">
                                <Field
                                    className="form-control mk-wi"
                                    name="effectiveEndDate"
                                    label="Effective End Date"
                                    type="date"
                                    component={CustomInputField}
                                    validate={!isActive ? required : undefined}
                                    requiredMark={!isActive}
                                    onChange={({target}) => handleChange(target)}
                                />
                            </div>
                        </div>
                        <div className="col-10">
                            <div className="mb-4 input-field-main">
                                <Field
                                    className="form-control mk-wi"
                                    name="terminationReason"
                                    // label={
                                    //     <Stack direction={"row"} gap={"5px"}>
                                    //         {" "}
                                    //         <Stack className={classes.reason}>
                                    //             {" "}
                                    //             Reason/Explanation{" "}
                                    //         </Stack>
                                    //         <Stack className={classes.optional}>
                                    //             {" "}
                                    //             (optional){" "}
                                    //         </Stack>{" "}
                                    //     </Stack>
                                    // }
                                    label="Reason/Explanation"
                                    component={CustomTextarea}
                                    validate={minLength10}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="mb-4 input-field-main">
                                <Stack className={classes.careInfoCaution}>
                                    Caution: When you set an effective end date, it will impact the
                                    end date for all scheduled events, and any one-time visits
                                    scheduled after the effective end date will be automatically
                                    canceled. Please be aware that this action cannot be reversed.
                                </Stack>
                            </div>
                        </div>
                        <div className="col-10">
                            <div className="mb-4 input-field-main">
                                <div className={`form-check ${classes.verifiedVisitsCheckbox}`}>
                                    <Field
                                        className="form-check-input"
                                        name="cancelVerifiedVisits"
                                        label="Cancel Verified Visits"
                                        component={CustomCheckboxField}
                                    />
                                </div>
                            </div>
                        </div>
                    </>}
                    <div className="d-flex justify-content-center">
                        <PrimaryButton
                            disabled={isDisabled}
                            type="submit"
                            className="btn-view-repo gap-2 px-5 py-2"
                            text={saveButtonText}
                            onClick={() => undefined}
                        />
                    </div>
                </div>
            </form>
        </>
    );
};

const mapStateToProps = state => {
    const {nurseListing: { nurseAccountDetails = null }} = state;

    return {
        initialValues: {
            active: nurseAccountDetails?.active?.toString(),
            // effectiveEndDate: nurseAccountDetails?.effectiveEndDate || "",
            effectiveEndDate: nurseAccountDetails?.terminationDate || "",
            terminationReason: nurseAccountDetails?.terminationReason || "",
        }
    }
};

export default connect(mapStateToProps)(
  reduxForm({form: "NurseActiveInactivateForm", enableReinitialize: true})(NurseActiveInactivateModal)
);
