import React, { Fragment } from "react";
import CustomLabel from "../fields/CustomLabel";

const CustomDropDownField = (field) => (
  <>
    {field.label != null && <CustomLabel labelFor={field.name} requiredMark={field.requiredMark} className={field.labelClass || null} labelText={field.label} infoIconOnClick={field.infoIconOnClick} />}
    <select
      required={field.required || false}
      onChange={field.onChange}
      className={field.className}
      disabled={field.disabled || field.readOnly || false}
      name={field.name}
      id={field.name}
      value={field.value}
      {...field.input}
    >
      {field.children}
    </select>
    {field.meta.touched && field.meta.error && (<span className="field-error">{field.meta.error}</span>)}
  </>
);

export default CustomDropDownField;
