import React, {useEffect, useState} from "react";
import Table from '../../../../sharedComponents/table/Table'
// import AddTaskModal from './addTaskModal'
// import {useSelector} from "react-redux";
// import {useQueryClient} from "@tanstack/react-query";

const Task = ({getTaskData, tasksListIsNotEmpty, modalTaskList, openModal, removeTask}) => {
    // const queryClient = useQueryClient();

    // const getServiceRequestById = useSelector(state => state.serviceRequest?.getServiceRequestById?.data);
    // const isServiceRequestEdit = useSelector(state => state.serviceRequest?.isServiceRequestEdit);

    // const servicesRequestTypesArray = queryClient.getQueryData(['list-service-request-types', agencyId]);

    // const [isModalOpen, setIsModalOpen] = useState(false);
    // const [modalTaskList, setModalTaskList] = useState([]);

    const headerData = [
        {
            title: "Task Name",
            dataIndex: "taskName",
            width: '200px',
            render: (taskNames) => {
// console.log('test- taskNames', taskNames);
                return (taskNames || []).map(item => {
                    return item.split(',')
                }).join(', ');
            }
        },
        // {
        //     title: "Start Date",
        //     dataIndex: "startDate",
        // },
        // {
        //     title: "End Date",
        //     dataIndex: "endDate",
        // },
        {
            title: "Visit Date",
            dataIndex: "startDateDisplay",
        },
        {
            title: "Clock In",
            dataIndex: "clockIn",
        },
        {
            title: "Clock Out",
            dataIndex: "clockOut",
        },
        {
            title: "Hours",
            dataIndex: "hours",
        },
        {
            title: "Status",
            dataIndex: "statusDisplay",
        },
        // {
        //     title: "Frequency",
        //     dataIndex: "dayName",
        //     width: '200px',
        //     render: (frNames) => {
        //         if (frNames === 'All') return 'All days';
        //         if (frNames === 'Any') return 'Any day';
        //         return (frNames || []).map(item => {
        //             return item.split(',')
        //         }).join(', ');
        //     }
        // },
    ]

    const actionButton = [
        {
            actionName: 'Remove',
            buttonAction: (id) => removeTask(id),
            actionClass: 'btn-danger',
            iconClass: 'fa-user-plus',
            hideActionButtonFunc: (id, _) => id > 0,
        },
    ];

    useEffect(() => {
        getTaskData(modalTaskList);
    }, [modalTaskList]);

    return (
      <div className="col-12 mb-2" style={{maxWidth : "900px"}}>
        <div className="row">
            <div className="col-12">
                <div className="row align-items-center pb-3">
                    <div className="col-12 col-md-6">
                        <div className="tab-inner-heading">Tasks <span className="mandatory-field">*</span></div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="d-flex justify-content-end">
                            <button type="button" className="btn btn-primary" onClick={openModal}>
                                Add Task
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="table-task">
                    <Table headers={headerData} rows={modalTaskList} actions={actionButton} tableClass="charting-table" />
                    {!tasksListIsNotEmpty && (<span className="field-error d-block pt-2">Please Add Task</span>)}
                    {/*<div className="buttonSpace"></div>*/}
                </div>
            </div>
        </div>
      </div>
    );
};

export default Task;
