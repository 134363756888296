import React, {useEffect, useState} from "react";
import {Field, reduxForm} from "redux-form";
import {connect, useDispatch} from "react-redux";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import CustomInputField from "../../../../../../sharedComponents/reduxfields/customInputField";
import {getContactInfoAddress} from "../../../../../../services/client";
import PrimaryButton from "../../../../../../sharedComponents/buttons/PrimaryButton";
import {HttpsAction} from "../../../../../../commonApiFunction/httpsAction";
import stringHelperFunctions from "../../../../../../helpers/string.helpers";
import {required, emailValidation} from "../../../../../../helpers/validation";
import {invalidateClientsList} from "../../../../../../queries/agency/clientsList";
import {useQueryClient} from "@tanstack/react-query";
import {toastr} from "react-redux-toastr";
import CustomLabel from "../../../../../../sharedComponents/fields/CustomLabel";
import * as clientActions from "../../../clientActionType";
import useCustomNav from "../../../../../../utils/hooks/useCustomNav";

const ContactInformation = ({handleSubmit, initialValues, agencyId}) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const {clientId, id} = useParams();
    const { clientId } = useOutletContext();

    const { goTo: gotoCharacteristicsPage } = useCustomNav('/agency/clients/edit/profile/characteristics');

    const [primaryPhoneType, setPrimaryPhoneType] = useState(initialValues?.primaryPhoneType || '');

    const convertToSecondFormat = (inputObj) => {
        const output = {phoneNumbers: []};
        let primaryPhoneSelected = false;

        for (let key in inputObj) {
            if (inputObj.hasOwnProperty(key) && key.startsWith("phoneTypeId")) {
                // Extract the number from the key
                const typeId = parseInt(key.replace("phoneTypeId", ""), 10);
                const isPrimary = primaryPhoneType === 'home' && typeId === 2 || primaryPhoneType === 'other' && typeId === 3 || primaryPhoneType === 'mobile' && typeId === 1;
                output.phoneNumbers.push({
                    phoneTypeId: typeId,
                    phoneNumber: stringHelperFunctions.nullIfEmpty(inputObj[key]),
                    isPrimary,
                });
            }
        }

        return {output, primaryPhoneSelected};
        // return inputObj.map(dataItem => ({phoneTypeId: dataItem.phoneTypeId, phoneNumber: dataItem.phoneNumber}));
    };

    const handlePrimaryPhoneSelection = (type) => {
        setPrimaryPhoneType(type);
    };

    function clearData() {
        dispatch({
            type: clientActions.GET_CONTACT_INFO_ADDRESS,
            payload: null,
        });
    }

    const onSubmit = async (data) => {
        const {output, primaryPhoneSelected} = convertToSecondFormat(data);
        if (primaryPhoneType.length === 0 && output.phoneNumbers.length > 0 && !primaryPhoneSelected) {
            toastr.error('Select primary phone');
            return;
        }

        const payload = {
            // clientId,
            email: stringHelperFunctions.nullIfEmpty(data?.email),
            phonesList: stringHelperFunctions.nullIfEmpty(output.phoneNumbers),
        };
        await dispatch(HttpsAction({
            method: 'PUT',
            apiSection: 'client',
            apiName: 'contactInfoAddress',
            // queryParameter: `${clientId}`,
            data: payload,
            queryParameter: `/${clientId}`,
            positiveCallBack: () => {
                // navigate(`/agency/clients/${clientId}/${id}/characteristics`);
                invalidateClientsList(queryClient, agencyId);
                gotoCharacteristicsPage();
            }
        }))
    };

    useEffect(() => {
        if (initialValues?.primaryPhoneType != null && initialValues.primaryPhoneType.length > 0 && primaryPhoneType.length === 0) {
            setPrimaryPhoneType(initialValues.primaryPhoneType);
        }
    }, [initialValues]);

    useEffect(() => {
        dispatch(getContactInfoAddress(clientId));
        return () => {
            setPrimaryPhoneType('');
            clearData();
        };
    }, []);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-4">
                        <div className="mb-4 input-field-main position-relative">
                            <Field
                              className="form-control mk-wi"
                              name="phoneTypeId1"
                              label="Phone (Mobile)"
                              type="number"
                              component={CustomInputField}
                              placeholder={'09776542099'}
                              // validate={usPhoneNumberValidation}
                            />
                            <div className="position-absolute top-4px end-0 d-flex flex-row gap-1">
                                <CustomLabel labelFor="primary-mobile" labelText="Is Primary?" className="mb-0" notOptional={true}/>
                                <input id="primary-mobile" type="checkbox" checked={primaryPhoneType === 'mobile'} onChange={() => undefined} onClick={() => handlePrimaryPhoneSelection('mobile')}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="mb-4 input-field-main position-relative">
                        <Field
                              className="form-control mk-wi"
                              name="phoneTypeId2"
                              label="Phone (Home)"
                              type="number"
                              component={CustomInputField}
                              placeholder={'09776542099'}
                              // validate={usPhoneNumberValidation}
                            />
                            <div className="position-absolute top-4px end-0 d-flex flex-row gap-1">
                                <CustomLabel labelFor="primary-home" labelText="Is Primary?" className="mb-0" notOptional={true}/>
                                <input id="primary-home" type="checkbox" checked={primaryPhoneType === 'home'} onChange={() => undefined} onClick={() => handlePrimaryPhoneSelection('home')}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="mb-4 input-field-main position-relative">
                            <Field
                              className="form-control mk-wi"
                              name="phoneTypeId3"
                              label="Phone (Other)"
                              type="number"
                              component={CustomInputField}
                              placeholder={'09776542099'}
                              // validate={usPhoneNumberValidation}
                            />
                            <div className="position-absolute top-4px end-0 d-flex flex-row gap-1">
                                <CustomLabel labelFor="primary-other" labelText="Is Primary?" className="mb-0" notOptional={true}  />
                                <input id="primary-other" type="checkbox" checked={primaryPhoneType === 'other'} onChange={() => undefined} onClick={() => handlePrimaryPhoneSelection('other')}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="mb-4 input-field-main">
                            <Field
                              className="form-control mk-wi"
                              name="email"
                              // label={<>Email address <strong className="fst-italic">&#x28;Primary Email&#x29;</strong></>}
                              label="Email address"
                              type="text"
                              component={CustomInputField}
                              requiredMark={true}
                              validate={[required, emailValidation]}
                              placeholder={'Janedoe@gmail.com'}
                            />
                        </div>
                    </div>
                    <div
                      className="d-flex justify-content-center"
                      style={{marginTop: "40px"}}>
                        <PrimaryButton
                          type="submit"
                          className="btn-view-repo gap-2 px-5 py-2"
                          text={"Save"}
                          onClick={() => undefined}
                        />
                    </div>
                </div>
            </form>
        </>
    )
};

const prepareInitialValues = (inputData) => {
    if (inputData == null) return null;
    const output = {};

    let primaryPhoneType = '';
    inputData?.phonesList?.forEach(phoneObj => {
        output[`phoneTypeId${phoneObj.phoneTypeId}`] = phoneObj.phoneNumber;
        if (phoneObj.isPrimary && phoneObj.phoneTypeId === 1) primaryPhoneType = 'mobile';
        if (phoneObj.isPrimary && phoneObj.phoneTypeId === 2) primaryPhoneType = 'home';
        if (phoneObj.isPrimary && phoneObj.phoneTypeId === 3) primaryPhoneType = 'other';
    });
    output.primaryPhoneType = primaryPhoneType;

    output.email = inputData?.email;

    // console.log('output', output);
    return output;
};

const mapStateToProps = state => {
    const { auth, client: { contactInfoAddress = null } = { contactInfoAddress: null } } = state;
    return {
        isAdmin: auth?.authData?.isAdmin || false,
        agencyId: auth?.authData?.agencyId || auth.agencyId,
        initialValues: prepareInitialValues(contactInfoAddress),
    }
};

export default connect(mapStateToProps)(
    reduxForm({form: "contactInformation", enableReinitialize: true})(ContactInformation)
);
