import React, { useState, useEffect } from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {change, destroy, Field, reduxForm} from "redux-form";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import PersonalInfoInnerTabs from "./personalInfoInnerTabs";
import {getCityList, getStateList} from "../../../../services/commonServices";
import Autocomplete from "../../../../sharedComponents/googleAddressAuto/index";
import CustomInputField from "../../../../sharedComponents/reduxfields/customInputField";
import CustomDropDownField from "../../../../sharedComponents/reduxfields/customDropDown";
import { required } from "../../../../helpers/validation";
import {postNurseAction, getNurseAction, clearNurseActionData} from "../../../../services/NursesProfile";
import * as nurseAction from "../nurseActionTypes";
import {useQueryClient} from "@tanstack/react-query";
import numberHelperFunctions from "../../../../helpers/number.helpers";
import stringHelperFunctions from "../../../../helpers/string.helpers";
import SaveButton from "../../../../sharedComponents/buttons/SaveButton";
import useCustomNav from "../../../../utils/hooks/useCustomNav";
import {isAdminUser} from "../../../../helpers/apiMethods";

const MailingInfo = ({ dataLoaded, initialValues, handleSubmit, pristine, submitting, submitFailed }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { id: nurseId } = useParams();
  const { nurseId } = useOutletContext();

  // const isAdmin = window.location.pathname.includes('/admin/agencyNurses/edit');
  const isAdmin = isAdminUser();

  const { goTo: gotoWorkExperiencePage } = useCustomNav(isAdmin ? `/admin/agency/nurses/edit/profile/personal-info/work-experience` : '/agency/nurses/edit/profile/personal-info/work-experience');

  // const getMailingAddressById = useSelector((store) => store.nurseListing?.nurseMailingInfo);

  const statesArray = queryClient.getQueryData(['list-provinces-states', 233]) || [];

  const [cityList, setCityList] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [isSelectedValid, setSelectedValid] = useState(true);
  const [placeData, setPlaceData] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [loadedOnce, setLoadedOnce] = useState(false);
  const [loadOnceDone, setLoadOnceDone] = useState(false);
  const [cityLoaded, setCityLoaded] = useState(false);


  function isValidOrEmptyPlace(plc) {
    return plc != null && (plc.address.length > 0 || (plc.address.length === 0 && plc.latLng.lat.length === 0 && plc.latLng.lng.length === 0));
  }

  function clearData() {
    dispatch({
      type: nurseAction.NURSE_MAILING_INFO,
      payload: null,
    });
  }

  const getStateId = async (e) => {
    let index = e.target.selectedIndex;
    let optionElement = e.target.childNodes[index];
    let optionattr = optionElement.getAttribute("value");
    const response = await dispatch(getCityList(optionattr));
    setCityList(response?.data);
  };

  const handlePlaceSelected = async (place) => {
    console.log('place', place)
// alert(JSON.stringify(place))
    if (place != null) {
      setCityLoaded(true);
      setSelectedPlace(place);
      if (place.provinceOrStateName != null && place.provinceOrStateName.length > 0) {
        setCityList([]);
        const selectedStateItem = statesArray.find(s => s.name === place.provinceOrStateName);
        const selectedStateIdStr = String(selectedStateItem.id);
// alert(JSON.stringify(selectedStateItem))
        // console.log('places-data - selectedStateItem', selectedStateItem);
        dispatch(change("MailingInfoForm", 'provinceOrStateId', selectedStateIdStr));
        const response = await dispatch(getCityList(selectedStateIdStr));
        setCityList(response?.data);
      }
    }

    if (place === null) {
      setSelectedValid(false);
    } else {
      setSelectedValid(true);
    }
  };


  const onSubmit = async (values) => {
    let payload = Object.assign({}, values);

    if (selectedPlace !== null) {
      // let countryIdStr = '233';

      payload.location = stringHelperFunctions.nullIfEmpty(selectedPlace.address);
      // console.log(selectedPlace);
      // return false;
      // payload.countryId = +countryIdStr;
      payload.cityId = numberHelperFunctions.nullIfEmpty(values.cityId, true);
      payload.provinceOrStateId = numberHelperFunctions.nullIfEmpty(values.provinceOrStateId, true);
      payload.postalOrZipCode = stringHelperFunctions.nullIfEmpty(values.postalOrZipCode);
      payload.latitude = numberHelperFunctions.nullIfEmptyFloat(selectedPlace?.latLng?.lat);
      payload.longitude = numberHelperFunctions.nullIfEmptyFloat(selectedPlace?.latLng?.lng);
      // payload.addressLine1 = selectedPlace && selectedPlace.address;
      // payload.addressLine2 = selectedPlace && selectedPlace.address;
      // console.log(payload, "payload");
      // return false;
      try {
        const response = await dispatch(
          postNurseAction(
            payload,
            "mailingInfo",
            nurseId,
            "Mailing Info Updated Successfully"
          )
        );
        if (response?.success) {
          // navigate(isAdmin ? `/admin/agencyNurses/edit/${nurseId}/workexperience` : `/agency/nurses/edit/${nurseId}/workexperience`)
          gotoWorkExperiencePage();
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      setSelectedValid(false);
    }
  };

  useEffect(() => {
    if (selectedPlace != null && cityList != null && cityList.length > 0) {
      if (selectedPlace.cityName != null && selectedPlace.cityName.length > 0) {
        console.log('selected-place', selectedPlace)
        const selectedStateItem = cityList.find(c => c.name === selectedPlace.cityName)
        // console.log('places-data - selectedCityItem', selectedStateItem);
        if (selectedStateItem != null)
          dispatch(change("MailingInfoForm", 'cityId', String(selectedStateItem.id)));
      }
    }
  }, [cityList]);

  useEffect(() => {
    if (!cityLoaded && initialValues?.cityId != null && initialValues?.cityId.length > 0 && cityList != null && cityList.length > 0) {
      setCityLoaded(true);
      dispatch(change("MailingInfoForm", 'cityId', initialValues?.cityId));
    }
  }, [initialValues?.cityId, cityList]);

  useEffect(() => {
    // console.log('init- data loadOnceDone', loadOnceDone)
    // console.log('initialValues-check', initialValues, getMailingAddressById)
    // console.log('init-vals', nurseId, initialValues, getMailingAddressById);
    // console.log('init-vals', nurseId == null, initialValues == null, getMailingAddressById == null);
    // if (getMailingAddressById != null && initialValues != null && nurseId != null) {
    //   const patientCityId = getMailingAddressById?.patientCityId?.toString();
    //   const address = getMailingAddressById?.patientLocation || '';
    //   const lat = getMailingAddressById?.patientLatitude?.toString();
    //   const lng = getMailingAddressById?.patientLongitude?.toString();
    //   // console.log('ok-', getMailingAddressById?.patientLocation, {lat, lng})
    //   setSelectedPlace({address, latLng: {lat, lng}});
    //
    //   const provinceOrStateId = initialValues?.provinceOrStateId;
    //   if (provinceOrStateId != null && provinceOrStateId.length > 0) {
    //     // bind city on load
    //     const optionData = {
    //       optionId: Number(provinceOrStateId),
    //       serviceName: "getCityList",
    //     };
    //     if (patientCityId != null && patientCityId.length > 0) {
    //       setCityList([]);
    //       fetchData(optionData).then(() => {
    //         dispatch(change("MailingInfoForm", 'cityId', patientCityId));
    //       });
    //     }
    //   }
    //
    //   // keep showing form values on reload
    //   dispatch(change("MailingInfoForm", 'provinceOrStateId', provinceOrStateId));
    //   dispatch(change("MailingInfoForm", 'postalOrZipCode', initialValues.postalOrZipCode));
    // }

    // console.log('initialValues-', initialValues, dataLoaded)
    // if (nurseId != null && initialValues != null && getMailingAddressById != null) {
    // if (loadOnceDone && nurseId != null && initialValues != null && dataLoaded) {
    if (loadOnceDone) {
      const provinceOrStateId = initialValues?.provinceOrStateId;
      const cityId = initialValues?.cityId;
      const address = initialValues?.location;
      // console.log('init-address-', initialValues?.location, getMailingAddressById?.patientLocation)
      // const address = initialValues?.location == null || initialValues?.location.length === 0 ? getMailingAddressById?.patientLocation : initialValues?.location;
      // console.log('init-address-', address)
      // const lat = initialValues?.latitude ?? getMailingAddressById?.patientLatitude?.toString();
      // const lng = initialValues?.longitude ?? getMailingAddressById?.patientLongitude?.toString();
      const lat = initialValues?.latitude;
      const lng = initialValues?.longitude;
      const postalOrZipCode = initialValues?.postalOrZipCode;
      // console.log('init-ok-', nurseId, selectedPlace, {address}, {lat, lng}, getMailingAddressById)
      // console.log('init-ok-', nurseId, selectedPlace, {address}, {lat, lng})

      // important code - patientCountry test
      setPlaceData(null);
      // if (placeData == null && !Object.values({address, lat, lng}).every(val => val === null || val === '' || val === false || val === 0))
      if (placeData == null && !Object.values({address, lat, lng}).every(val => val === null))
        // if (placeData == null && isValidOrEmptyPlace({address, lat, lng}))
        setPlaceData({address, latLng: {lat, lng}});
      // if (placeData == null && !Object.values(getMailingAddressById).every(val => val === null || val === '' || val === false || val === 0))
      //   setPlaceData({address: getMailingAddressById?.patientLocation ?? '', latLng: {lat: getMailingAddressById?.patientLatitude ?? '', lng: getMailingAddressById?.patientLongitude ?? ''}});

      if (provinceOrStateId != null && provinceOrStateId.length > 0) {
        setCityList([]);

        // IIFE below
        (async () => {
          const response = await dispatch(getCityList(provinceOrStateId));
          setCityList(response?.data);
        })();

        // // bind city on load
        // if (cityId != null && cityId.length > 0) {
        //   dispatch(change("MailingInfoForm", 'cityId', cityId));
        // }
      }

      // keep showing form values on reload
      dispatch(change("MailingInfoForm", 'provinceOrStateId', provinceOrStateId));
      dispatch(change("MailingInfoForm", 'postalOrZipCode', postalOrZipCode));
    }
  }, [loadOnceDone]);
  // }, [loadOnceDone, getMailingAddressById]);

  useEffect(() => {
    // console.log('init-loadOnceDone-', nurseId, loadOnceDone, dataLoaded, initialValues)
    // console.log('init-loadOnceDone-condition', !loadOnceDone && nurseId != null && initialValues != null && dataLoaded)
    if (!loadOnceDone && nurseId != null && initialValues != null && dataLoaded) {
      setLoadOnceDone(true);
    }
  }, [dataLoaded]);

  useEffect(() => {
    // console.log('init-placeData', placeData)
    if (isValidOrEmptyPlace(placeData)) {
      setTimeout(() => {
        setSelectedPlace(placeData);
      }, 700);
    }
  }, [placeData]);

  useEffect(() => {
    if (!isLoading && loadedOnce) {
      setLoadedOnce(false);
      dispatch(getNurseAction("mailingInfo", nurseId));
    }
  }, [loadedOnce]);

  useEffect(() => {
    if (isLoading) {
      setLoading(false);
      setLoadedOnce(true);
    }
  }, [isLoading]);

  useEffect(() => {
    if (submitFailed && initialValues.address === "") {
      setSelectedValid(false);
    }
  }, [submitFailed]);

  useEffect(() => {
    setLoadOnceDone(false);
    clearData();
    // console.log('init-loading', loading, dataLoaded)
    setSelectedPlace(null);
    setLoading(true);

    return () => {
      if (dataLoaded) clearData();
      dispatch(destroy('MailingInfoForm'));
    }
  }, []);

  return (
    <>
      <div className="card-white px-4">
        {/*<PersonalInfoInnerTabs></PersonalInfoInnerTabs>*/}
        <form className="pt-3" onSubmit={handleSubmit(onSubmit)}>
          <div className="row ">
            <div className="col-12 col-md-12">
              <div className="mb-4">
                <div className="d-flex gap-3">
                  <label>Location <span className="mandatory-field">*</span></label>
                  {
                    selectedPlace && selectedPlace.address &&
                <label className="selectedLocation">&#x28;Selected Location &#x3d; {selectedPlace.address}&#x29; </label>
                }
              </div>
              {
                // nurseId != null && selectedPlace != null && isValidOrEmptyPlace(selectedPlace) &&
                <Autocomplete onPlaceSelected={handlePlaceSelected} initAddress={selectedPlace?.address} disabled={statesArray.length === 0} />
              }
              {
                  !isSelectedValid && (<span className="field-error">This field is required</span>)
                }
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="mb-4">
                <label>
                  State <span className="mandatory-field">*</span>
                </label>
                <Field
                  className="form-control read-only"
                  name="provinceOrStateId"
                  component={CustomDropDownField}
                  onChange={(e) => getStateId(e)}
                  requiredMark={true}
                  validate={required}
                >
                  <option value="">Please select</option>
                  {statesArray?.map((res, index) => {
                    return (
                      <option key={index} value={res.id}>
                        {res.name}
                      </option>
                    );
                  })}
                </Field>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="mb-4">
                <label>
                  City <span className="mandatory-field">*</span>
                </label>
                <Field
                  className="form-control read-only1"
                  name="cityId"
                  component={CustomDropDownField}
                  requiredMark={true}
                  validate={required}
                >
                  <option value="">Please select</option>
                  {cityList?.map((res, index) => {
                    return (
                      <option key={index} value={res.id}>
                        {res.name}
                      </option>
                    );
                  })}
                </Field>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="mb-4">
                <Field
                  className="form-control mk-wi"
                  name="postalOrZipCode"
                  label="Postal Code"
                  type="text"
                  component={CustomInputField}
                  requiredMark={true}
                  validate={required}
                ></Field>
              </div>
            </div>
            <SaveButton pristine={pristine} submitting={submitting} />
          </div>
        </form>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { nurseListing: { nurseMailingInfo = null } } = state;

  return {
    dataLoaded: nurseMailingInfo != null /* && JSON.stringify(nurseMailingInfo) !== '{}'*/,
    initialValues: {
      // countryId: nurseMailingInfo?.nurseCountryId?.toString() || "",
      cityId: numberHelperFunctions.convertToIntegerString(nurseMailingInfo?.nurseCityId),
      provinceOrStateId: numberHelperFunctions.convertToIntegerString(nurseMailingInfo?.nurseProvinceOrStateId),
      postalOrZipCode: stringHelperFunctions.sanitize(nurseMailingInfo?.nursePostalOrZipCode),
      location: stringHelperFunctions.sanitize(nurseMailingInfo?.nurseLocation),
      latitude: numberHelperFunctions.convertToFloatString(nurseMailingInfo?.nurseLatitude),
      longitude: numberHelperFunctions.convertToFloatString(nurseMailingInfo?.nurseLongitude),
    },
  };
};

export default connect(mapStateToProps)(
  reduxForm({ form: "MailingInfoForm", enableReinitialize: true })(MailingInfo)
);
