import React from "react";
import CareList from "./CareList";

const CareNote = () => {

    return (
        <>
            <CareList/>
        </>
    )
};
export default CareNote;