import React, {useEffect, useState} from "react";
import {Card, Col, Row} from "react-bootstrap";
import {NavLink, useNavigate} from "react-router-dom";
import AdminNotifications from "../Dashboard/AdminNotifications";
import DashboardCard from "../Dashboard/DashboardCard";
import {useDispatch, useSelector} from "react-redux";
import Table from "../../../sharedComponents/table/Table";
import {HttpsAction} from "../../../commonApiFunction/httpsAction";
import TableTitle from "../Dashboard/TableTitle";
import CardTab from "../Dashboard/Card";
import AgenciesOverview from "./agenciesOverview";
import {getAgenciesData} from "../../redux/actions/AdminActions";

const AGENCIES_NAV_TITLES = {
    ALL_AGENCIES: "All Agencies",
    VERIFIED_AGENCIES: "Verified Agencies",
    UNVERIFIED_AGENCIES: "Unverified Agencies",
    // ASSIGNED_NURSES: "Assigned Nurses",
};

const AGENCIES_API_KEYS = {
    ALL_AGENCIES: "agencies-list",
    VERIFIED_AGENCIES: "verified-agencies-list",
    UNVERIFIED_AGENCIES: "unverified-agencies-list",
};

export const AGENCIES_NAV_PATHS = {
    // ALL_AGENCIES: '/admin/all/agency/allAgencies',
    // VERIFIED_AGENCIES: '/admin/all/agency/verifiedAgencies',
    // UNVERIFIED_AGENCIES: '/admin/all/agency/unverifiedAgencies',
    ALL_AGENCIES: '/admin/list/agencies/all',
    VERIFIED_AGENCIES: '/admin/list/agencies/verified',
    UNVERIFIED_AGENCIES: '/admin/list/agencies/unverified',
};


const AdminAgencies = () => {
    const backButtonTextSuffix = 'Agencies Overview';
    // const backLink = '/admin/agencies';
    const backLink = '/admin/list/agencies';

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // const {admin} = useSelector((state) => state);
    const agenciesSummary = useSelector(state => state.admin?.agenciesSummary);

    const [activeCard, setActiveCard] = useState(AGENCIES_NAV_TITLES.ALL_AGENCIES);
    // const [searchTerm, setSearchTerm] = useState("");
    const [allAgenciesCount, setAllAgenciesCount] = useState(0);
    const [verifiedAgenciesCount, setVerifiedAgenciesCount] = useState(0);
    const [unverifiedAgenciesCount, setUnverifiedAgenciesCount] = useState(0);

    // const handleSearch = (e) => {
    //     setSearchTerm(e.target.value);
    // };

    const handleSeeAll = (e, title) => {
        const state = {
              showBackButton: true,
              backButtonTextSuffix,
              backLink,
          };
        switch (title) {
            case AGENCIES_NAV_TITLES.ALL_AGENCIES:
                navigate(AGENCIES_NAV_PATHS.ALL_AGENCIES, { state });
                break;
            case AGENCIES_NAV_TITLES.VERIFIED_AGENCIES:
                navigate(AGENCIES_NAV_PATHS.VERIFIED_AGENCIES, { state });
                break;
            case AGENCIES_NAV_TITLES.UNVERIFIED_AGENCIES:
                navigate(AGENCIES_NAV_PATHS.UNVERIFIED_AGENCIES, { state });
                break;
        }
    };

    const renderAgenciesCards = () => {
        const cardData = [
            {
                title: AGENCIES_NAV_TITLES.ALL_AGENCIES,
                // data: admin?.agencyList?.data?.rows,
                // count: admin?.agencyList?.data?.rows?.length || 0
                apiKey: AGENCIES_API_KEYS.ALL_AGENCIES,
                total: allAgenciesCount,
            },
            {
                title: AGENCIES_NAV_TITLES.VERIFIED_AGENCIES,
                // data: admin?.verifiedAgencyList?.data?.rows,
                // count: admin?.verifiedAgencyList?.data?.rows?.length || 0
                apiKey: AGENCIES_API_KEYS.VERIFIED_AGENCIES,
                total: verifiedAgenciesCount,
            },
            {
                title: AGENCIES_NAV_TITLES.UNVERIFIED_AGENCIES,
                // data: admin?.unverifiedAgencyList?.data?.rows,
                // count: admin?.unverifiedAgencyList?.data?.rows?.length || 0
                apiKey: AGENCIES_API_KEYS.UNVERIFIED_AGENCIES,
                total: unverifiedAgenciesCount,
            },
            // {title: AGENCIES_NAV_TITLES.ASSIGNED_NURSES},

        ];

        return cardData.map((card, index) => {
            return (
                <CardTab
                    key={index}
                    title={card.title}
                    isActive={activeCard === card.title}
                    count={card.total}
                    handleClick={setActiveCard}
                    handleSeeAll={handleSeeAll}
                />
            )
        });
    };

    const activeTabApiKey = {
        [AGENCIES_NAV_TITLES.ALL_AGENCIES]: AGENCIES_API_KEYS.ALL_AGENCIES,
        [AGENCIES_NAV_TITLES.VERIFIED_AGENCIES]: AGENCIES_API_KEYS.VERIFIED_AGENCIES,
        [AGENCIES_NAV_TITLES.UNVERIFIED_AGENCIES]: AGENCIES_API_KEYS.UNVERIFIED_AGENCIES,
    }[activeCard];

    // const activeTabTableData = {
    //     // [AGENCIES_NAV_TITLES.ALL_AGENCIES]: admin?.agencyList?.data?.rows,
    //     // [AGENCIES_NAV_TITLES.VERIFIED_AGENCIES]: admin?.verifiedAgencyList?.data?.rows,
    //     // [AGENCIES_NAV_TITLES.UNVERIFIED_AGENCIES]: admin?.unverifiedAgencyList?.data?.rows,
    //     [AGENCIES_NAV_TITLES.ALL_AGENCIES]: admin?.agenciesList,
    //     [AGENCIES_NAV_TITLES.VERIFIED_AGENCIES]: admin?.verifiedAgenciesList,
    //     [AGENCIES_NAV_TITLES.UNVERIFIED_AGENCIES]: admin?.unverifiedAgenciesList,
    //     // [AGENCIES_NAV_TITLES.ASSIGNED_NURSES]: admin?.assignedNurse?.data?.rows,
    // }[activeCard];

    const activeTabSubTitle = {
        [AGENCIES_NAV_TITLES.ALL_AGENCIES]: 'See all agencies and their detail',
        [AGENCIES_NAV_TITLES.VERIFIED_AGENCIES]: 'See details of verified agencies only',
        [AGENCIES_NAV_TITLES.UNVERIFIED_AGENCIES]: 'See details of agencies only that are not verified',
    }[activeCard];
    //
    // const transformedArray = (activeTabTableData?.rows || [])?.map((item, index) => {
    //     return {
    //         id: item.id,
    //         sn: item?.id,
    //         name: item?.name,
    //         email: item?.email,
    //         phone: item?.phoneNumber,
    //         address: item?.location || "N/A",
    //         subscription: item?.subscriptionPlan || "N/A",
    //         verification: item.isVerified ? "Verified" : "Unverified",
    //     }
    // });
    //
    //
    // const serviceHeaderData = [
    //     {
    //         title: "S/N",
    //         dataIndex: "sn",
    //     },
    //     {
    //         title: "Name",
    //         dataIndex: "name",
    //     },
    //     {
    //         title: "Email address",
    //         dataIndex: "email",
    //     },
    //     {
    //         title: "Phone",
    //         dataIndex: "phone",
    //     },
    //     {
    //         title: "Address",
    //         dataIndex: "address",
    //     },
    //     // {
    //     //     title: "Subscription",
    //     //     dataIndex: "subscription",
    //     // },
    //     {
    //         title: "Verification",
    //         dataIndex: "verification",
    //     }
    // ];


    useEffect(() => {
        if (agenciesSummary != null) {
            setAllAgenciesCount(agenciesSummary?.allAgenciesCount || 0);
            setVerifiedAgenciesCount(agenciesSummary?.verifiedCount || 0);
            setUnverifiedAgenciesCount(agenciesSummary?.unverifiedCount || 0);
        }
    }, [agenciesSummary]);

    useEffect(() => {
        // dispatch(HttpsAction({
        //     apiSection: 'admin',
        //     apiName: 'agencyList',
        //     reduxKeyName: 'agencyList',
        //     reducerName: 'ADMIN',
        //     actionType: 'GET_ADMIN_AGENCY_LIST'
        // }))
        // dispatch(HttpsAction({
        //     apiSection: 'admin',
        //     apiName: 'agencyList',
        //     reduxKeyName: 'verifiedAgencyList',
        //     queryParameter: `?verified=true`,
        //     reducerName: 'ADMIN',
        //     actionType: 'GET_ADMIN_VERIFIED_AGENCY_LIST'
        // }))
        // dispatch(HttpsAction({
        //     apiSection: 'admin',
        //     apiName: 'agencyList',
        //     queryParameter: `?verified=false`,
        //     reduxKeyName: 'unverifiedAgencyList',
        //     reducerName: 'ADMIN',
        //     actionType: 'GET_ADMIN_UNVERIFIED_AGENCY_LIST'
        // }))
        dispatch(getAgenciesData('agencies-summary'));
    }, []);

    return (
        <>
            <Row className="mb-15">
                {/*<Col xs={12} sm={8} className="mb-4">*/}
                <Col xs={12} sm={12} className="mb-4">
                    <div id="canvas" className="row anylatics-data-box mt-4 mb-3">
                        {renderAgenciesCards()}
                    </div>
                    {/*<TableTitle
                        title={activeCard}
                        // subTitle={"See all agencies and their details"}
                        subTitle={activeTabSubTitle}
                        isFullView={false}
                    />
                    <Card>
                        <Card.Body>
                            <Table headers={serviceHeaderData} rows={transformedArray} />
                        </Card.Body>
                    </Card>*/}
                    <AgenciesOverview title={activeCard} apiKey={activeTabApiKey} subTitle={activeTabSubTitle} />
                </Col>
                {/*<Col xs={12} sm={4}>
                    <div className="card card-n rounded-lg py-4 px-3  border-0">
                        <Row>
                            <Col
                                sm={9}
                                className="comp-task-row float-start"
                                style={{fontSize: "16px"}}
                            >
                                Notifications
                            </Col>
                            <Col sm={3} className="comp-task-option float-start text-end">
                                <NavLink
                                    className="text-decoration-none"
                                    to="#"
                                    style={{color: "#4C9DED"}}
                                >
                                    See all
                                </NavLink>
                            </Col>
                        </Row>
                        <Row>
                            <AdminNotifications/>
                        </Row>
                    </div>
                </Col>*/}
            </Row>
        </>
    )
};

export default AdminAgencies;
