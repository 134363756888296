import React from 'react';
import infoIcon from "../../assets/images/blue-info.svg"

const CustomLabel = ({labelText, empty = false, labelFor = null, requiredMark = false, notOptional = false, readOnly = false, className = null, infoIconOnClick = null }) => (
  <label htmlFor={empty ? undefined : labelFor} className={className}>
    {!empty && <>
      <span>{labelText}</span>
      {requiredMark && <span className="mandatory-field">&nbsp;*</span>}
      {!requiredMark && !readOnly && !notOptional && <i className="text-lightgrey ms-1">&nbsp;(Optional)</i>}
      {infoIconOnClick && <>&nbsp;&nbsp;<span onClick={infoIconOnClick} style={{
        height: '14px',
        maxHeight: '14px',
        width: '14px',
        maxWidth: '14px',
        cursor: 'pointer',
        display: 'inline-block',
      }}><img src={infoIcon} alt={''}/></span></>}
    </>}
    {empty && <span>&nbsp;</span>}
  </label>
);

export default CustomLabel;
