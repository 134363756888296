import React from 'react';

const CustomRadioGroup = ({ input, disabled, readOnly, initialValue, options, meta: {touched, error, warning, active, visited }}) => {
  // console.log('input', input, initialValue, input.value);
  return (
    <>
      <div className='d-flex'>
        {options.map((option, index) => (
          <div className={`option-column form-check${disabled || readOnly ? ' no-pointer-events' : ''}`} key={index}>
            <input
              type="radio"
              {...input}
              value={option.value}
              checked={input.value === option.value}
              className="form-check-input"
              id={option.id}
            />
            <label className="form-check-label" htmlFor={option.id}>{option.label}</label>
          </div>
        ))}
      </div>
      {touched && error && (<span className="field-error">{error}</span>)}
    </>
  );
};

export default CustomRadioGroup;
