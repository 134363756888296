import React from 'react'

const CustomInputField = ({input, meta, type, autoComplete, min, max, autoFocus, readOnly, name, value, placeholder, disabled, maxLength, minLength, required, className, label = null, labelComponent = null, ...custom }) => (
    <>
    {
        <>
        {label != null ? <label>{label}</label> : labelComponent != null ? labelComponent : ''}
        <input
            type={type}
            value={value}
            placeholder={placeholder || ''}
            autoComplete={autoComplete || 'off'}
            autoFocus={autoFocus || false}
            min={min || ''}
            max={max || ''}
            readOnly={readOnly || false}
            name={name}
            required={required || false}
            inputprops={{ maxLength: maxLength ? maxLength : 255 }}
            disabled={disabled || false}
            {...input}
            {...custom}
            className={className || ''}
        />
            {meta != null && meta.touched && meta.error && (<span className="field-error">{meta.error}</span>)}
        </>
    }
    </>
)

export default CustomInputField
