import React, {useEffect, useRef, useState} from "react";
import ContentHeader from "../index";
import classes from "../AdminTabs.module.css";
import Table from "../../../../../sharedComponents/table/Table";
import Pagination from "../../../../../sharedComponents/pagination";
import {pageLimit} from "../../../../../appConstants";
import CustomDialog from "../../../../../sharedComponents/CustomDialog";
import PrimaryButton from "../../../../../sharedComponents/buttons/PrimaryButton";
import * as FileSaver from "file-saver";
import XLSX from "xlsx/dist/xlsx.full.min";
import {HttpsAction} from "../../../../../commonApiFunction/httpsAction";
import {useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {debounce} from "lodash";

const showSearch = false;

const Subscriptions = () => {

    const exportRef = useRef(null);
    const dispatch = useDispatch(null);
    const {tabId, agencyId} = useParams();

    const adminSubscriptions = useSelector(state => state.admin?.adminSubscriptions?.data);
    const allAdminSubscriptions = useSelector(state => state.admin?.allAdminSubscriptions?.data);

    const currentSubscriptions = (adminSubscriptions?.rows || []).find(v => v.isCurrent === true);

    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState('');

    const transformedArray = (adminSubscriptions?.rows || [])?.map((item, index) => {
        return {
            agency: item?.agencyName,
            date: <div className={'d-flex flex-column gap-1'}>
                <div>
                    {item?.subscriptionDateFormatUs}
                </div>
                <div>
                    {/*12:00 - 01:00*/}
                </div>
            </div>,
            email: item?.agencyEmail,
            subscription: item?.planName,
            amount: item?.planAmount,
        }
    });

    const exportTransformedArray = (allAdminSubscriptions || [])?.map((item, index) => {
        return {
            agency: item?.agencyName,
            date: <div className={'d-flex flex-column gap-1'}>
                <div>
                    {item?.subscriptionDateFormatUs}
                </div>
                <div>
                    {/*12:00 - 01:00*/}
                </div>
            </div>,
            email: item?.agencyEmail,
            subscription: item?.planName,
            amount: item?.planAmount,
        }
    });

    const serviceHeaderData = [
        {
            title: "Agency",
            dataIndex: "agency",
        },
        {
            title: "Email Address",
            dataIndex: "email",
        },
        {
            title: "Subscription",
            dataIndex: "subscription",
        },
        {
            title: "Date",
            dataIndex: "date",
        },
        {
            title: "Amount",
            dataIndex: "amount",
        }
    ];

    const handlePageChange = (page) => {
        dispatch(HttpsAction({
            apiSection: 'admin',
            apiName: 'subscriptions',
            queryParameter: `/${agencyId}?page=${page}&searchText=${search}`,
            reduxKeyName: 'adminSubscriptions',
            reducerName: 'ADMIN',
            actionType: 'GET_ADMIN_SUBSCRIPTIONS'
        }))
    };

    const handleModal = async () => {
        await dispatch(HttpsAction({
            apiSection: 'admin',
            apiName: 'subscriptions',
            queryParameter: `/${agencyId}?doPaging=false`,
            reduxKeyName: 'allAdminSubscriptions',
            reducerName: 'ADMIN',
            actionType: 'GET_ADMIN_SUBSCRIPTIONS'
        }))
        setOpen(!open)
    };

    const handleExport = () => {
        const bookType = 'xlsx';
        const wb = XLSX.utils.table_to_book(exportRef.current, {sheet: "Sheet JS"});
        const wbout = XLSX.write(wb, {bookType, bookSST: true, type: 'array'});

        const blob = new Blob([wbout], {type: 'application/octet-stream'});
        const file = `subscriptions.xlsx`;

        FileSaver.saveAs(blob, file);
        setOpen(false);
    };

    const handleDateFilter = ({from, to}) => {
        dispatch(HttpsAction({
            apiSection: 'admin',
            apiName: 'subscriptions',
            queryParameter: `/${agencyId}?fromDate=${from}&toDate=${to}`,
            reduxKeyName: 'adminSubscriptions',
            reducerName: 'ADMIN',
            actionType: 'GET_ADMIN_SUBSCRIPTIONS'
        }))
    };

    const handleSearch = debounce((value) => {
        setSearch(value);
        dispatch(HttpsAction({
            apiSection: 'admin',
            apiName: 'subscriptions',
            queryParameter: `/${agencyId}?searchText=${value}`,
            reduxKeyName: 'adminSubscriptions',
            reducerName: 'ADMIN',
            actionType: 'GET_ADMIN_SUBSCRIPTIONS'
        }))
    }, 500);

    useEffect(() => {
        dispatch(HttpsAction({
            apiSection: 'admin',
            apiName: 'subscriptions',
            queryParameter: `/${agencyId}`,
            reduxKeyName: 'adminSubscriptions',
            reducerName: 'ADMIN',
            actionType: 'GET_ADMIN_SUBSCRIPTIONS'
        }))
    }, []);

    return (
        <>
            <div className={'p-3'}>
                <div className={'d-flex flex-column gap-2'}>
                    <div className={classes.tabTitle}>
                        Active Plan
                    </div>
                    <div className={classes.basicPlanCard}>
                        <div className={classes.cardTitle}>
                            {currentSubscriptions?.planName}
                        </div>
                        <div className={'mt-2'}>
                            <div className={'d-flex flex-row gap-5'}>
                                <div className={'d-flex flex-column gap-1'}>
                                    <div className={classes.cardTitle}>
                                        Create Date
                                    </div>
                                    <div className={classes.basicPlanDate}>
                                        {currentSubscriptions?.subscriptionDateFormatUs}
                                    </div>
                                </div>
                                <div className={'d-flex flex-column gap-1'}>
                                    <div className={classes.cardTitle}>
                                        Renewal Date
                                    </div>
                                    <div className={classes.basicPlanDate}>
                                        N/A
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ContentHeader
                    title={'Billing History'}
                    handleExport={handleModal}
                    handleDateFilter={handleDateFilter}
                    onSearch={({target}) => handleSearch(target.value)}
                    showSearch={showSearch}
                />
                <div className={classes.contentBox}>
                    <Table headers={serviceHeaderData} rows={transformedArray}/>
                    <Pagination
                        currentPage={adminSubscriptions?.page}
                        totalRecords={adminSubscriptions?.total}
                        limit={pageLimit}
                        onPageChange={handlePageChange}
                    />
                </div>
            </div>
            <CustomDialog
                maxWidth={'lg'}
                onClose={() => handleModal('closeModal')}
                open={open}
                dialogContentRef={exportRef}
                actions={<PrimaryButton
                    type="button"
                    className="btn-view-repo gap-2 px-5 py-2"
                    text={"Export"}
                    onClick={handleExport}
                />}
                title={'Subscriptions'}>
                <div className={'p-4'}>
                    <Table headers={serviceHeaderData} rows={exportTransformedArray}/>
                </div>
            </CustomDialog>
        </>
    )
};

export default Subscriptions;
