import React, {useState} from 'react';
import {Outlet, useLocation, useNavigate, useOutletContext} from "react-router-dom";
import apiCallHelperFunctions from "../helpers/api.call.helpers";
import {chartingTabNewUrls} from "../appConstants";

const ChartingInServiceRequestsLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { searchText, setPaginationTab } = useOutletContext();

  return (
    /*<div className="container-fluid bg-light">
      <div className="bg-light-gray px-3 py-3">
        <div className="row">
          <div className="container-fluid">*/
            <div className="tab-content" id="pills-tabContent">
              <div className="bg-white mt-3">
                <div className="col-sm-3">
                  <div className="d-flex gap-2 innerTabing">
                    {apiCallHelperFunctions.getTabButtonsNew('charting', chartingTabNewUrls, navigate, location.pathname)}
                  </div>
                </div>
                <Outlet context={{searchText, setPaginationTab}}/>
              </div>
            </div>
          /*</div>
        </div>
      </div>
    </div>*/
  );
};

export default ChartingInServiceRequestsLayout;
