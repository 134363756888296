import React, {useState, useEffect} from "react";
import {connect} from 'react-redux'
import {Field, reduxForm} from 'redux-form';
import CustomInputField from '../../../../sharedComponents/reduxfields/customInputField'
import CustomCheckboxField from '../../../../sharedComponents/reduxfields/CustomCheckBox'
import CustomTextareaField from '../../../../sharedComponents/reduxfields/customTextareaField'
import CustomDropDownField from '../../../../sharedComponents/reduxfields/customDropDown'
import {required, emailValidation} from '../../../../helpers/validation'
import Autocomplete from '../../../../sharedComponents/googleAddressAuto/index';
import {useDispatch} from 'react-redux';
import {change} from 'redux-form';
import {registerField, unregisterField} from 'redux-form';
import {getCityList, getNursesList, getStateList} from "../../../../services/commonServices";
import CustomRadioGroup from "../../../../sharedComponents/reduxfields/customRadioGroup";
import {useQueryClient} from "@tanstack/react-query";

const chooseOrCreateClientOptions = [
    {label: 'Choose existing Client', id: "yes1", value: 'true'},
    {label: 'Create a new Client', id: "no1", value: 'false'}
];

const piFormVarName = 'editPatientInformationForm';

const EditPatientInformationTab = ({disableNurseSelection, disablePatientSelection/*, getStateId, cityList*/, address, isSelectedValid, getClientId, clientId, initialValues, agencyId}) => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();

    const gendersArray = queryClient.getQueryData(['list-genders']);
    const statesArray = queryClient.getQueryData(['list-provinces-states', 233]);
    const patientsArray = queryClient.getQueryData(['list-patients', agencyId]);
    // const patientsArray = queryClient.getQueryData(['list-patients-with-latlng', agencyId]);
    const nursesArray = queryClient.getQueryData([disableNurseSelection ? 'list-nurses' : 'list-internal-nurses', agencyId]);

    const [selectedPlace, setSelectedPlace] = useState(null);
    const [citiesList, setCitiesList] = useState([]);
    const [doChooseClient, setDoChooseClient] = useState(true);

    const handlePlaceSelected = place => {
        address(place);
        setSelectedPlace(place);
        if (place.provinceOrStateName != null && place.provinceOrStateName.length > 0) {
            const selectedStateItem = statesArray.find(s => s.name === place.provinceOrStateName)
            dispatch(change(piFormVarName, 'provinceOrStateId', String(selectedStateItem.id)));
            setCitiesList([]);
            // IIFE
            (async () => {
                const response = await dispatch(getCityList(String(selectedStateItem.id)));
                setCitiesList(response?.data);
            })();
        }
    };

    const handleStateChanged = (e) => {
        let index = e.target.selectedIndex;
        let optionElement = e.target.childNodes[index];
        let optionattr = optionElement.getAttribute("value");
        setCitiesList([]);
        // IIFE
        (async () => {
            const response = await dispatch(getCityList(optionattr));
            setCitiesList(response?.data);
        })();
    };

    const handleChooseOrCreateClientSelection = (e) => {
        setDoChooseClient(e.target.value === 'true')
    };

    useEffect(() => {
        if (selectedPlace != null && citiesList != null && citiesList.length > 0) {
            if (selectedPlace.cityName != null && selectedPlace.cityName.length > 0) {
                const selectedStateItem = citiesList.find(c => c.name === selectedPlace.cityName)
                if (selectedStateItem != null)
                    dispatch(change(piFormVarName, 'cityId', String(selectedStateItem.id)));
            }
        }
    }, [citiesList]);

    useEffect(() => {
        const fieldNames = ['firstName', 'lastName', 'middleName', 'age', 'email', 'addressLine1', 'provinceOrStateId', 'cityId', 'postalOrZipCode', 'genderId'];
        fieldNames.forEach(fieldName => {
            if (clientId === null) {
                dispatch(registerField(piFormVarName, fieldName));
            } else {
                dispatch(unregisterField(piFormVarName, fieldName));
                dispatch(change(piFormVarName, fieldName, ''));
                setSelectedPlace(null)
            }
        });
    }, [clientId]);

    useEffect(() => {
        if (statesArray.length === 0) {
            dispatch(getStateList());
        }
    }, [statesArray]);

    useEffect(() => {
        dispatch(change(piFormVarName, 'chooseOrCreateClientSelection', 'true'));
    }, []);

    return (
        <div className="col-12 mb-5" style={{maxWidth: "900px"}}>
            <div className="row">
                <div className="tab-inner-heading">Patient Information</div>
                <div className="neo1 col-12 col-xl-9 py-2 px-4 m-0 border border-black rounded-4 border-opacity-10">
                    <Field
                        name="chooseOrCreateClientSelection"
                        component={CustomRadioGroup}
                        options={chooseOrCreateClientOptions}
                        onChange={(e) => handleChooseOrCreateClientSelection(e)}
                        disabled={disablePatientSelection}
                    />
                    {doChooseClient && <div className="p-0 m-0 border1 border1-black">
                        <div className="form-group row">
                            <div className="col-12 col-sm-6">
                                <label className="tab-inner-sub-heading">Choose Client</label>
                                <Field
                                    className="form-control"
                                    name="chooseClient"
                                    component={CustomDropDownField}
                                    onChange={(e) => getClientId(e)}
                                    disabled={disablePatientSelection}
                                >
                                    <option value={null}>Please select</option>
                                    {patientsArray?.map((res, index) => {
                                        return <option key={index}
                                                       value={res.id}>{`${res.firstName} ${res?.middleName || ''} ${res.lastName}`}</option>
                                    })}
                                </Field>
                            </div>
                        </div>
                    </div>}
                    {
                        !disablePatientSelection && !doChooseClient && (
                            <div className="p-0 m-0 border1 border1-black">
                                <label className="tab-inner-sub-heading">Create new Client</label>
                                <div className="form-group row">
                                    <div className="col-12 col-sm-4">
                                        <Field className="form-control mk-wi" name="firstName" label="First Name"
                                               type="text"
                                               component={CustomInputField} validate={required}
                                               requiredMark={true}></Field>
                                    </div>
                                    <div className="col-12 col-sm-4">
                                        <Field className="form-control mk-wi" name="middleName" label="Middle Name"
                                               type="text"
                                               component={CustomInputField}></Field>
                                    </div>
                                    <div className="col-12 col-sm-4">
                                        <Field className="form-control mk-wi" name="lastName" label="Last Name"
                                               type="text"
                                               component={CustomInputField} validate={required}
                                               requiredMark={true}></Field>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-12 col-sm-6 ">
                                        <Field className="form-control mk-wi" name="age" label="Age" type="number"
                                               component={CustomInputField} validate={required} requiredMark={true}
                                               min="0"></Field>
                                    </div>
                                    <div className="col-12 col-sm-6">
                                        <Field className="form-control mk-wi" name="email" label="Email" type="email"
                                               component={CustomInputField} validate={[required,emailValidation]}
                                               requiredMark={true}></Field>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-sm-12">
                                        <div className="d-flex gap-3">
                                            <label>Landmark <span className="mandatory-field">*</span></label>
                                            {
                                                selectedPlace &&
                                                <label className="selectedLocation">&#x28;Selected
                                                    Location &#x3d; {selectedPlace.address}&#x29; </label>
                                            }
                                        </div>
                                        <Autocomplete onPlaceSelected={handlePlaceSelected}/>
                                        {
                                            !isSelectedValid &&
                                            <span className="field-error">This field is required</span>
                                        }
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-12">
                                        <Field className="form-control mk-wi" name="addressLine1" label="Address"
                                               type="text"
                                               component={CustomInputField} validate={required}
                                               requiredMark={true}></Field>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-12 col-sm-4">
                                        <label>State <span className="mandatory-field">*</span></label>
                                        <Field
                                            className="form-control"
                                            name="provinceOrStateId"
                                            component={CustomDropDownField}
                                            validate={required}
                                            requiredMark={true}
                                            onChange={(e) => handleStateChanged(e)}
                                        >
                                            <option value="">Please select</option>
                                            {statesArray?.map((res, index) => {
                                                return <option key={index} value={res.id}>{res.name}</option>
                                            })}
                                        </Field>
                                    </div>
                                    <div className="col-12 col-sm-4">
                                        <label>City <span className="mandatory-field">*</span></label>
                                        <Field
                                            className="form-control"
                                            name="cityId"
                                            component={CustomDropDownField}
                                            validate={required}
                                            requiredMark={true}
                                        >
                                            <option value="">Please select</option>
                                            {(citiesList || [])?.map((res, index) => {
                                                return <option key={index} value={res.id}>{res.name}</option>
                                            })}
                                        </Field>
                                    </div>
                                    <div className="col-12 col-sm-4">
                                        <Field className="form-control mk-wi" name="postalOrZipCode" label="Postal Code"
                                               type="text" component={CustomInputField} validate={required}
                                               requiredMark={true}
                                               min="0"></Field>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-4">
                                    <label>Gender <span className="mandatory-field">*</span></label>
                                    <Field
                                        className="form-control"
                                        name="genderId"
                                        component={CustomDropDownField}
                                        validate={required}
                                        requiredMark={true}
                                    >
                                        <option value="">Please select</option>
                                        {gendersArray?.map((res, index) => {
                                            return <option key={index} value={res.id}>{res.name}</option>
                                        })}
                                    </Field>
                                </div>
                            </div>
                        )
                    }
                </div>

                {/*{nursesAssigned && <>*/}
                {/*{!jobRelated && initialValues.serviceRequestStatusId === 2 && <>*/}
                {<>
                    <div className="tab-inner-heading mt-3">Assigned Nurse</div>
                    <div className="neo1 col-12 col-xl-9 py-2 px-4 m-0 border border-black rounded-4 border-opacity-10">
                        <div className="col-12 col-sm-6 mb-4">
                            <Field
                              className="form-control"
                              label="Choose Nurse"
                              name="assignedNurseId"
                              component={CustomDropDownField}
                              validate={required}
                              requiredMark={true}
                              disabled={disableNurseSelection}
                            >
                                <option value="">Please Select</option>
                                {nursesArray?.map((res, index) => {
                                    return <option key={index} value={res.id}>{res.nurseFullName}</option>
                                })}
                            </Field>
                        </div>
                    </div>
                </>}
                {initialValues.serviceRequestStatusId !== 2 && <Field name="assignedNurseId" component={CustomInputField} type="hidden"></Field>}
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    const { serviceRequest: { serviceDetails: { data = null } } } = state;

    return {
        initialValues: {
            chooseOrCreateClientSelection: "true",
            chooseClient: data?.patientId?.toString(),
            assignedNurseId: data?.tasks?.[0]?.assignedNurseId?.toString() || "",
            // assignedNurseId: data?.tasks[0]?.assignedNurseId != null ? data?.tasks[0]?.assignedNurseId?.toString() : null,
            serviceRequestStatusId: data?.serviceRequestStatusId,
        }
    }
};

export default connect(mapStateToProps)(
  reduxForm({form: piFormVarName, enableReinitialize: true})(EditPatientInformationTab)
);
