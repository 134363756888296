import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

const CalendarModal = ({ show, handleClose, handleDateSelect }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleCalendarChange = (date) => {
    setSelectedDate(date);
  };

  const handleConfirm = () => {
    handleDateSelect(selectedDate);
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Select a Date</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Calendar onChange={handleCalendarChange} value={selectedDate} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleConfirm}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CalendarModal;
