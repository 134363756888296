import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {Field, getFormValues, reduxForm} from "redux-form";
import classes from "./index.module.css";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import CustomInputField from "../../../../sharedComponents/reduxfields/customInputField";
import CustomRadioGroup from "../../../../sharedComponents/reduxfields/customRadioGroup";
import PrimaryButton from "../../../../sharedComponents/buttons/PrimaryButton";
import {HttpsAction} from "../../../../commonApiFunction/httpsAction";
import {Dialog} from "@mui/material";
import CareNoteCommon from "../../TaskDetails/CareNotes/CareNoteCommon";
import PaymentSuccessModal from "./PaymentSuccessModal";
import useEscapeKey from "../../../../utils/hooks/useEscapeKey";
import numberHelperFunctions from "../../../../helpers/number.helpers";
import dateHelperFunctions from "../../../../helpers/date.helpers";
import BackLink from "../../../../sharedComponents/buttons/BackLink";
import Modal from "../../../../sharedComponents/Modal";

const radioOptions = [
    {label: 'Per Hour', id: "perHour", value: '1'},
    {label: 'Per Visit', id: "perVisit", value: '2'},
    {label: 'Per Diem', id: "perDiem", value: '3'},
];

const PayToCaregiver = ({handleSubmit, taskDetails, initialValues, formData}) => {

    const {taskId} = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEscapeKey(() => {
        navigate("/agency/servicerequest/completeRequest");
    });

    const isPaymentVerified = taskDetails?.nurseNameForVerification != null || false;
    const paymentDone = taskDetails?.paymentTransactionId != null || false;

    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [showSignaturesModal, setShowSignaturesModal] = useState(false);
    const [showDateRange, setShowDateRange] = useState(false);
    const [showSingleDate, setShowSingleDate] = useState(true);
    const [paymentVerified, setPaymentVerified] = useState(false);
    const [verificationData, setVerificationData] = useState({nurseNameForVerification: null, verifiedAt: null});
    const [paymentMade, setPaymentMade] = useState(false);

    const handleModal = () => {
// console.log('test- formData total', formData['total'])
        setShowPaymentModal(prevState => !prevState)
    }

    const handleSignatures = (verification, savedVerificationData) => {
        if (verification != null) {
            setVerificationData(savedVerificationData); // { nurseNameForVerification, verifiedAt}
            setPaymentVerified(true);
            // reload();
        }
        setShowSignaturesModal(prevState => !prevState);
    }

    const reload = () => {
        dispatch(HttpsAction({
            apiSection: 'client',
            apiName: 'serviceRequestTasks',
            queryParameter: `/${taskId}`,
            reduxKeyName: 'serviceRequestTaskDetails',
            reducerName: 'SERVICE_REQUEST',
            actionType: 'GET_SERVICE_REQUEST_TASK_DETAILS'
        }));
    };

    const onSubmit = async (data) => {
// console.log('test- data total', data.total, formData['total'])
        // await dispatch(HttpsAction({
        //     method: 'POST',
        //     apiSection: 'serviceRequests',
        //     apiName: 'moneyBankDetails',
        //     data: {
        //         bankAccountNumber: "IBAN7E98D7FS8GS89",
        //         bankName: "ABC Bank"
        //     },
        // }))

        // if (((taskDetails?.scheduledPaymentAmount || 0) + (taskDetails?.overtimePaymentAmount || 0)) === 0) {
        //     toastr.error('Amount must be greater than 0')
        // } else

        await dispatch(HttpsAction({
            method: 'POST',
            apiSection: 'serviceRequests',
            apiName: 'moneyPayNurse',
            queryParameter: `/${taskDetails?.assignedNurseId}`,
            data: {
                // amount: ((taskDetails?.scheduledPaymentAmount || 0) + (taskDetails?.overtimePaymentAmount || 0)) || 1,
                // amount: formData['total'],
                amount: numberHelperFunctions.nullIfEmptyFloat(data.total, true),
                transactionPurpose: "Payment to Nurse",
                serviceRequestId: numberHelperFunctions.nullIfEmpty(taskDetails?.serviceRequestId, true),
                taskId: numberHelperFunctions.nullIfEmpty(taskDetails?.taskId, true),
            },
            positiveCallBack: () => {
                setPaymentMade(true);
                handleModal();
            }
        }));
    }

    useEffect(() => {
        if (taskId) reload();
    }, [taskId]);

    return (
        <>
            <div className="app-main__outer">
                <div className="main-service-request-page">
                    <div className="app-main__inner service-request bg-white">
                        <BackLink link="/agency/servicerequest/completeRequest" linkText="Completed Visits" />
                        <div className={classes.title}>
                            Service Request Task details for payment
                        </div>
                        <div className={'mt-2'}>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className={'col-sm-7 col-8 mb-3'}>
                                        <div className={classes.contentBox}>
                                            <div className="row">
                                                <div className={'col-12'}>
                                                    <div className="mb-4 input-field-main">
                                                        <Field
                                                            className="form-control mk-wi"
                                                            name="serviceRequired"
                                                            label="Service Required"
                                                            type="text"
                                                            component={CustomInputField}
                                                            // placeholder={'Service selected'}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={`col-12`}>
                                                    <div className="mb-4 input-field-main">
                                                        <label>To be Paid</label>
                                                        <Field
                                                            name="payRateTypeId"
                                                            component={CustomRadioGroup}
                                                            options={radioOptions}
                                                            onChange={(e) => undefined}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={'col-6'}>
                                                    <div className="mb-4 input-field-main">
                                                        <Field
                                                            className="form-control mk-wi"
                                                            name="nurseRate"
                                                            label="Nurse Rate"
                                                            type="number"
                                                            component={CustomInputField}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={'col-6'}>
                                                    <div className="mb-4 input-field-main">
                                                        <Field
                                                            className="form-control mk-wi"
                                                            name="nurseOvertimeRate"
                                                            label="Over Time Rate"
                                                            type="number"
                                                            component={CustomInputField}
                                                            readOnly={true}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={'col-12'}>
                                                    <div className="mb-4 input-field-main">
                                                        <Field
                                                            className="form-control mk-wi"
                                                            name="total"
                                                            label="Total"
                                                            type="number"
                                                            component={CustomInputField}
                                                            // readOnly={paymentVerified || isPaymentVerified}
                                                            readOnly={paymentMade || paymentDone}
                                                            requiredMark={true}
                                                            currencySymbol={initialValues.currencySymbol}
                                                            defaultValue={0}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className={'col-sm-5 col-4 mb-3'}>
                                        <div className={classes.contentBox}>
                                            <div className={'row'}>
                                                {showDateRange && <>
                                                    <div className={'col-6'}>
                                                        <div className="mb-4 input-field-main">
                                                            <Field
                                                              className="form-control mk-wi"
                                                              name="startDate"
                                                              label="Start Date"
                                                              type="text"
                                                              readOnly={true}
                                                              // type="date"
                                                              component={CustomInputField}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={'col-6'}>
                                                        <div className="mb-4 input-field-main">
                                                            <Field
                                                              className="form-control mk-wi"
                                                              name="endDate"
                                                              label="End Date"
                                                              type="text"
                                                              readOnly={true}
                                                              // type="date"
                                                              component={CustomInputField}
                                                            />
                                                        </div>
                                                    </div>
                                                </>}
                                                {showSingleDate && <>
                                                    <div className={'col-6'}>
                                                        <div className="mb-4 input-field-main">
                                                            <Field
                                                              className="form-control mk-wi"
                                                              name="startDate"
                                                              label="Visit Date"
                                                              type="text"
                                                              readOnly={true}
                                                              // type="date"
                                                              component={CustomInputField}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className={'col-6'}>&nbsp;</div>
                                                </>}
                                                <div className={'custom-col-4-cit'}>
                                                    <div className="mb-4 input-field-main">
                                                        <Field
                                                          className="form-control mk-wi"
                                                          name="clockIn"
                                                          label="Clock-in Time"
                                                          type="text"
                                                          readOnly={true}
                                                          // type="time"
                                                          component={CustomInputField}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={'custom-col-4-cit'}>
                                                    <div className="mb-4 input-field-main">
                                                        <Field
                                                            className="form-control mk-wi"
                                                            name="clockOut"
                                                            label="Clock-out Time"
                                                            type="text"
                                                            readOnly={true}
                                                            // type="time"
                                                            component={CustomInputField}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={'custom-col-4-cit'}>
                                                    <div className="mb-4 input-field-main">
                                                        <Field
                                                            className="form-control mk-wi"
                                                            name="workedTotalTimeText"
                                                            label="Time worked"
                                                            type="text"
                                                            readOnly={true}
                                                            component={CustomInputField}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={'custom-col-4-cit'}>
                                                    <div className="mb-4 input-field-main">
                                                        <Field
                                                            className="form-control mk-wi"
                                                            name="scheduledTotalTimeText"
                                                            label="Scheduled Time"
                                                            type="text"
                                                            readOnly={true}
                                                            component={CustomInputField}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={'custom-col-4-cit'}>
                                                    <div className="mb-4 input-field-main">
                                                        <Field
                                                            className="form-control mk-wi"
                                                            name="overtimeText"
                                                            label="Overtime"
                                                            type="text"
                                                            readOnly={true}
                                                            component={CustomInputField}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    {initialValues.workedLess && <span className="text-danger">Work is behind schedule</span>}
                                                    {initialValues.workedMore && <span className="text-success">Overtime has been done</span>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="d-flex justify-content-center gap-3"
                                    style={{marginTop: "40px"}}
                                >
                                    <PrimaryButton
                                        disabled={paymentMade || !paymentVerified && !isPaymentVerified}
                                        // disabled={!paymentVerified}
                                        type="submit"
                                        className={"btn-view-repo gap-2 px-4 py-2"}
                                        text={"Pay"}
                                        onClick={() => undefined}
                                    />
                                    <PrimaryButton
                                        type="button"
                                        className="btn-primary border-btn-info rounded-2 gap-2 px-4 py-2"
                                        text={"Signatures"}
                                        onClick={() => handleSignatures()}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/*<PaymentSuccessModal onClose={handleModal} isOpen={showPaymentModal && taskDetails != null} taskDetails={taskDetails} />*/}
            {showPaymentModal && <PaymentSuccessModal onClose={handleModal} isOpen={showPaymentModal && taskDetails != null} taskDetails={{...taskDetails, total: parseFloat(formData['total'])}}/>}

            {/*<Dialog open={showSignaturesModal} onClose={handleSignatures} maxWidth="lg">*/}
            <Modal isOpen={showSignaturesModal} onClose={handleSignatures} modalTitle="Registered Nurse Signature" modalDialogClass="modal-lg" modalBodyClass="py-1">
                <div>
                    <CareNoteCommon
                        show={showSignaturesModal}
                        addTask={false}
                        onClose={handleSignatures}
                        notes={taskDetails?.notes || 'N/A'}
                        // clientSignature={`agency/${taskDetails?.patientSignatureDocumentLink}`}
                        clientSignature={taskDetails?.patientSignatureDocumentLink != null ? `/agency${taskDetails?.patientSignatureDocumentLink}` : null}
                        // activitiesList={Array(17).fill(taskDetails?.activities[0])}
                        activitiesList={taskDetails?.activities}
                        // caregiverSignature={`agency/${taskDetails?.nurseSignatureDocumentLink}`}
                        caregiverSignature={taskDetails?.nurseSignatureDocumentLink != null ? `/agency${taskDetails?.nurseSignatureDocumentLink}` : null}
                        taskDetails={taskDetails}
                        isJobRelated={taskDetails?.jobId != null}
                        isChartingRelated={taskDetails?.chartingId != null}
                        clockInTime={taskDetails?.clockInAmPm || null}
                        clockOutTime={taskDetails?.clockOutAmPm || null}
                        // rnSignatureEditable={true}
                        rnSignatureEditable={taskDetails?.nurseNameForVerification == null && verificationData?.nurseNameForVerification == null}
                        rnSignatureValue={taskDetails?.nurseNameForVerification || verificationData?.nurseNameForVerification || ''}
                        rnSignatureDate={taskDetails?.paymentVerifiedAt || verificationData?.verifiedAt || ''}
                    />
                </div>
            </Modal>
        </>
    )
};

const mapStateToProps = state => {

    const data = state.serviceRequest?.serviceRequestTaskDetails?.data;
    let total = numberHelperFunctions.formatNumber((data?.scheduledPaymentAmount || 0) + (data?.overtimePaymentAmount || 0))?.toString();
    if (data?.amountPaid != null) total = numberHelperFunctions.formatNumber(data?.amountPaid)?.toString();

    return {
        taskDetails: data,
        formData: getFormValues('payToCaregiver')(state),
        initialValues: {
            payRateTypeId: data?.payRateTypeId?.toString(),
            nurseRate: data?.nurseRate?.toString(),
            nurseOvertimeRate: data?.nurseOvertimeRate?.toString(),
            plannedTotal: numberHelperFunctions.formatNumber(data?.plannedScheduledPaymentAmount)?.toString(),
            calculatedTotal: numberHelperFunctions.formatNumber((data?.scheduledPaymentAmount || 0) + (data?.overtimePaymentAmount || 0))?.toString(),
            total,
            serviceRequired: data?.serviceRequestType,
            endDate: data?.taskDateFormatUs || '',
            startDate: data?.taskDateFormatUs || '',
            clockIn: data?.clockIn || '',
            clockOut: data?.clockOut || '',
            currencySymbol: data?.currencySymbol || '',
            workedTotalTimeText: dateHelperFunctions.hoursAndMinutesToText(data?.totalHoursWorked, data?.totalMinutesWorkedLessHours),
            scheduledTotalTimeText: dateHelperFunctions.hoursAndMinutesToText(data?.totalScheduledHours, data?.totalScheduledMinutesLessHours),
            overtimeText: dateHelperFunctions.hoursAndMinutesToText(data?.overtimeTotalHoursWorked, data?.overtimeTotalMinutesLessHoursWorked),
            workedLess: data?.totalMinutesWorked < data?.totalScheduledMinutes,
            workedMore: data?.totalMinutesWorked > data?.totalScheduledMinutes,
            rnSignatureValue: data?.nurseNameForVerification,
            rnSignatureDate: data?.paymentVerifiedAt,
        }
    }
}


export default connect(mapStateToProps)(
    reduxForm({form: "payToCaregiver", enableReinitialize: true})(PayToCaregiver)
);
