import React, {useRef, useState} from "react";
import {Dialog, Popover, Stack} from "@mui/material";
import TasksInformation from "./TasksInformation";
import {useNavigate, useParams} from "react-router-dom";
import DailyNotes from "./DailyNotes";
import SignatureInformation from "./SignatureInformation";
import classes from "../CareNote.module.css";
import {Field, reduxForm} from "redux-form";
import CustomInputField from "../../../../../../sharedComponents/fields/customInputField";
import PrimaryButton from "../../../../../../sharedComponents/buttons/PrimaryButton";
import {connect, useSelector} from "react-redux";
import PrintCareNoteDetails from "./PrintCareNoteDetails";
import {useReactToPrint} from 'react-to-print';
import * as FileSaver from "file-saver";
import {toastr} from "react-redux-toastr";
import XLSX from "xlsx/dist/xlsx.full.min";

const PROFILE_TABS_ARRAY = [
    {
        name: 'Tasks Information',
        select: true,
        // component: <TasksInformation/>
        component: (tasksList) => <TasksInformation tasksList={tasksList} />
    }, {
        name: 'Daily Notes',
        select: false,
        // component: <DailyNotes/>
        component: (tasksList) => <DailyNotes tasksList={tasksList} />
    }, {
        name: 'Signature Information',
        select: false,
        // component: <SignatureInformation/>
        component: (tasksList) => <SignatureInformation tasksList={tasksList} />
    }
];

const ViewCare = ({handleSubmit, serviceRequestId, patientName}) => {

    const printCareNoteDetailsRef = useRef(null);

    const tasksList = useSelector(state => {
        // return state.client?.careList?.data?.rows || [];
// console.log('sr-id', typeof serviceRequestId, serviceRequestId)
// console.log('sr-data', state.client?.careList?.data)
        const foundServiceRequest = state.client?.careList?.data?.rows?.find(sr => sr.serviceRequestId === +serviceRequestId);
// console.log('foundServiceRequest', foundServiceRequest)
        return foundServiceRequest != null ? foundServiceRequest.tasks || [] : [];
    });


    const [printModal, setPrintModal] = useState(false);
    const [tabs, setTabs] = useState(PROFILE_TABS_ARRAY);

    const handleSelectTab = (i) => {
        setTabs(prevState => prevState.map((value, index) => {
            return index === i ? {...value, select: true} : {...value, select: false}
        }))
    };

    const handleExport = () => {
        if (tasksList?.length > 0) {
            const bookType = 'xlsx';
            const wb = XLSX.utils.table_to_book(printCareNoteDetailsRef.current, {sheet: "Sheet JS"});
            const wbout = XLSX.write(wb, {bookType, bookSST: true, type: 'array'});

            const blob = new Blob([wbout], {type: 'application/octet-stream'});
            const file = 'care-note.xlsx'

            FileSaver.saveAs(blob, file);
            setPrintModal(false)
        } else {
            toastr.error("Record can't be  export")
        }
    };

    const handlePrintModal = () => {
        setPrintModal(!printModal)
    };

    const handlePrint = useReactToPrint({
        content: () => printCareNoteDetailsRef.current,
        onAfterPrint: () => {
            // Close the modal after printing or cancelling the PDF
            handlePrintModal()
        }
    });


    return (
        <>
            <div
                className="tab-pane fade show active"
                id="barden-tab-pane"
                role="tabpanel"
                aria-labelledby="barden-tab"
                tabIndex="0"
            >
                <div className="card-white px-4" style={{paddingTop: '24px'}}>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex gap-2">
                            {(tabs || [])?.map((value, index) => (
                                <button type="button" className={`btn ${value.select === true && "btn-primary"} btn-sm`}
                                        onClick={() => handleSelectTab(index)}
                                >
                                    {value.name}
                                </button>
                            ))}
                        </div>
                        <Stack className={classes.printExportButton}>
                            <Stack className={classes.printButton} width={'56px'} onClick={handlePrintModal}>
                                <svg width="16" height="14" viewBox="0 0 16 14" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13.25 4H2.75C1.505 4 0.5 5.005 0.5 6.25V10.75H3.5V13.75H12.5V10.75H15.5V6.25C15.5 5.005 14.495 4 13.25 4ZM11 12.25H5V8.5H11V12.25ZM13.25 7C12.8375 7 12.5 6.6625 12.5 6.25C12.5 5.8375 12.8375 5.5 13.25 5.5C13.6625 5.5 14 5.8375 14 6.25C14 6.6625 13.6625 7 13.25 7ZM12.5 0.25H3.5V3.25H12.5V0.25Z"
                                        fill="#323232"/>
                                </svg>
                                <Stack className={classes.printButtonText}>
                                    Print
                                </Stack>
                            </Stack>
                            <Stack className={classes.printButton} width={'76px'}
                                   onClick={() => setPrintModal('export')}>
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_8324_100883)">
                                        <path
                                            d="M12.75 2.25H3.75C2.9175 2.25 2.25 2.925 2.25 3.75V14.25C2.25 15.075 2.9175 15.75 3.75 15.75H14.25C15.075 15.75 15.75 15.075 15.75 14.25V5.25L12.75 2.25ZM9 14.25C7.755 14.25 6.75 13.245 6.75 12C6.75 10.755 7.755 9.75 9 9.75C10.245 9.75 11.25 10.755 11.25 12C11.25 13.245 10.245 14.25 9 14.25ZM11.25 6.75H3.75V3.75H11.25V6.75Z"
                                            fill="#323232"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_8324_100883">
                                            <rect width="18" height="18" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>

                                <Stack className={classes.printButtonText}>
                                    Export
                                </Stack>
                            </Stack>
                        </Stack>
                    </div>

                    <Stack sx={{marginTop: '50px'}}>
                        {(tabs || [])?.map((v, idx) => v.select === true && <React.Fragment key={idx}>{v?.component(tasksList)}</React.Fragment>)}
                    </Stack>
                </div>
            </div>
            <Dialog
                open={printModal}
                onClose={handlePrintModal}
                maxWidth={'lg'}
            >
                <div className={'p-4'} ref={printCareNoteDetailsRef}>
                    <div>
                        <PrintCareNoteDetails handlePrint={handlePrint}/>
                    </div>
                </div>
                <div
                    className="d-flex justify-content-center mb-4"
                    style={{marginTop: "10px"}}
                >
                    <PrimaryButton
                        type="button"
                        className="btn-view-repo gap-2 px-5 py-2"
                        text={printModal === 'export' ? "Export" : "Print"}
                        onClick={() => printModal === 'export' ? handleExport() : handlePrint()}
                    />
                </div>
            </Dialog>
        </>
    )
}
export default connect()(
    reduxForm({form: "viewCare", enableReinitialize: true})(ViewCare)
);
