import React from "react";
import {Field, reduxForm} from "redux-form";
import CustomInputField from "../../../sharedComponents/reduxfields/customInputField";
import {emailValidation, minLength2, required} from "../../../helpers/validation";
import {connect, useDispatch} from "react-redux";
import {postCreateClient} from "../../../services/client";
import PrimaryButton from "../../../sharedComponents/buttons/PrimaryButton";
import {useQueryClient} from "@tanstack/react-query";
import {useMutation} from "@tanstack/react-query";
import {toastr} from "react-redux-toastr";
import {invalidateClientsList} from "../../../queries/agency/clientsList";

const CreateNewClientModel = ({agencyId, handleSubmit, onClose}) => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();

    const mutation = useMutation({
        mutationFn: async (payload) => {
            return await dispatch(postCreateClient(payload, 'Client Added Successfully'));
        },
        onSuccess: (data) => {
            // console.log('data after update', data)
            // Invalidate and refetch
            if (data.success) {
                invalidateClientsList(queryClient, agencyId);
                toastr.success('Client Added Successfully');
                onClose(true);
            } else {
                throw new Error('Failed to create new patient/client');
            }
        },
        onError: (error) => {
            console.error('MUTATION-ERROR', error);
        },
    })

    const onSubmit = async (data) => {
        data.genderId = 2;
        // await dispatch(postCreateClient(data, 'Client Added Successfully'));
        mutation.mutate(data);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
                <div className="col-12">
                    <div className="mb-4 input-field-main">
                        <Field
                            className="form-control mk-wi"
                            name="firstName"
                            label="First Name"
                            type="text"
                            component={CustomInputField}
                            requiredMark={true}
                            validate={[required, minLength2]}
                        />
                    </div>
                </div>
                <div className="col-12">
                    <div className="mb-4 input-field-main">
                        <Field
                            className="form-control mk-wi"
                            name="lastName"
                            label="Last Name"
                            type="text"
                            component={CustomInputField}
                            requiredMark={true}
                            validate={[required, minLength2]}
                        />
                    </div>
                </div>
                <div className="col-12">
                    <div className="mb-4 input-field-main">
                        <Field
                            className="form-control mk-wi"
                            name="email"
                            label="Email"
                            type="text"
                            component={CustomInputField}
                            requiredMark={true}
                            validate={[required,emailValidation]}
                        />
                    </div>
                </div>
                <div className={'row justify-content-center'}>
                    <div className="col-3">
                        <PrimaryButton
                            type="submit"
                            className={`btn-view-repo gap-2 px-3 py-2 btn btn-primary w-100 rounded-8 border-btn-info`}
                            text={"Cancel"}
                            onClick={() => onClose(false)}
                        />
                    </div>
                    <div className="col-3">
                        <PrimaryButton
                            type="submit"
                            className="btn-view-repo gap-2 px-3 py-2 w-100"
                            text={"Save"}
                            onClick={() => undefined}
                        />
                    </div>
                </div>
            </div>
        </form>
    );
};

const mapStateToProps = (state) => {
    const { auth } = state;

    return {
        agencyId: auth?.authData?.agencyId || auth.agencyId,
    };
};

export default connect(mapStateToProps)(
    reduxForm({form: "createNewClientModel"})(CreateNewClientModel)
);
