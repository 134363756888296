import React from 'react';
import {NavLink, Outlet, useLocation, useOutletContext, useParams} from "react-router-dom";
import {isAdminUser, isAgencyUser} from "../helpers/apiMethods";

const certsEvalsTabsList = [
  {
    path: 'certifications',
    text: 'Certifications'
  },
  {
    path: 'otherCertifications',
    text: 'Others',
    viewableByAgency: false,
    viewableByAdmin: true,
  },
  {
    path: 'evaluations',
    text: 'Evaluations'
  },
];

const CertsEvalsLayout = () => {
  const isAdmin = isAdminUser();
  const isAgency = isAgencyUser();
  const pathPrefix = `${isAdmin ? "/admin/agency" : "/agency"}/nurses/edit/profile/certs-evals`;

  // const { state: { id: nurseIdStateVal = null } } = useLocation();
  // const { state } = useLocation();
  // const { id: nurseIdParam } = useParams();
  // const nurseId = nurseIdParam || state?.id;

  return (
    <div className="card-white px-4">
      <div>
        <div className="p-0 m-0 d-inline-block">
          <div className="row">
            <div className="col-lg-6 col-6">
              <div className="d-flex gap-2">
                {certsEvalsTabsList.map(({path, text, viewableByAdmin, viewableByAgency}, index) => (
                  <>
                    {((viewableByAgency != null && viewableByAgency === true && isAgency) || (viewableByAdmin != null && viewableByAdmin === true && isAdmin) || viewableByAgency == null || viewableByAdmin == null) &&
                      <React.Fragment key={index}>
                        {/*<NavLink key={index} to={`${pathPrefix}/${path}/${nurseId}`} className="btn btn-sm px-1 nurse-profile-personal-info-tab-link neo1">{text}</NavLink>*/}
                        <NavLink key={index} to={`${pathPrefix}/${path}`} className="btn btn-sm px-1 nurse-profile-personal-info-tab-link neo1">{text}</NavLink>
                      </React.Fragment>
                    }
                  </>
                ))}
              </div>
            </div>
          </div>
          <div className="pt-3">
            <Outlet context={useOutletContext()} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CertsEvalsLayout;
