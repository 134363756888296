import React, {useEffect, useState} from "react";
import {change, Field, reduxForm, registerField, unregisterField} from "redux-form";
import CustomDropDownField from "../../../sharedComponents/reduxfields/customDropDown";
import {required} from "../../../helpers/validation";
import CustomInputField from "../../../sharedComponents/reduxfields/customInputField";
import CheckboxGroup from "../../../sharedComponents/reduxfields/customCheckboxGroup";
import CustomRadioGroup from "../../../sharedComponents/reduxfields/customRadioGroup";
import {getServiceCreationData} from "../../../services/serviceRequest";
import {connect, useDispatch} from "react-redux";
import {getChartingData, postServiceRequest} from "../../../services/charting";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import TextField from "@mui/material/TextField/TextField";
import Typography from "@mui/material/Typography";
import {getFrequenciesList, getServiceRequestTypesList} from "../../../services/commonServices";
import dateHelperFunctions from "../../../helpers/date.helpers";
import * as chartingRequest from "../ServiceRequest/ChartingActionTypes";
import {useQueryClient} from "@tanstack/react-query";
import apiCallHelpers from "../../../helpers/api.call.helpers";
import {toastr} from "react-redux-toastr";

const ratesRadioOptions = [
    {label: 'Base', id: "Base", value: '1'},
    {label: 'Auto', id: "Auto", value: '2'}
];

const chargeMethod = [
    {label: 'Per Hour', id: "Per_Hour", value: '1'},
    {label: 'Per Visit', id: "Per_Visit", value: '2'},
    {label: 'Per Day', id: "Per_Day", value: '3'}
];

const ServiceRequestModal = ({handleSubmit, onClose, eachData, creatingForServiceRequestType, agencyId}) => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();

    const serviceRequestTypesArray = queryClient.getQueryData(['list-service-request-types', agencyId]);

    const [RatesValue, setRatesValue] = useState('1');
    const [chargeValue, setChargeValue] = useState('1');

    const [selectedStartDate, setSelectedStartDate] = useState(null);
    // const [selectedEndDate, setSelectedEndDate] = useState(null);

    const getRatesValue = ({target}) => {
        setRatesValue(target.value)
    }

    const getChargeMethod = ({target}) => {
        setChargeValue(target.value)
    }

    // const handleStartDateChange = (newStartDate) => {
    //     if (newStartDate > selectedEndDate) {
    //         // Prevent selecting a Start Date after the End Date
    //         setSelectedEndDate(null);
    //     }
    //     setSelectedStartDate(newStartDate);
    // };

    const handleDateChange = (newDate) => {
        setSelectedStartDate(newDate);
        const dateVal = new Date(Date.parse(newDate));
        // You can perform additional actions here based on the newDate value
        console.log('Selected Date:', newDate, dateVal);
        dispatch(change('serviceRequestModal', 'startDate', dateVal.toLocaleDateString()));
        // if (showSingleDate)
            dispatch(change('serviceRequestModal', 'endDate', dateVal.toLocaleDateString()));
    };

    // const handleEndDateChange = (newEndDate) => {
    //     if (newEndDate < selectedStartDate) {
    //         // Prevent selecting an End Date before the Start Date
    //         setSelectedStartDate(null);
    //     }
    //     setSelectedEndDate(newEndDate);
    // };


    useEffect(() => {
        dispatch(change('addServiceRequest', 'nurseOvertimeRate', ''));
        if (RatesValue === '2' && chargeValue === '1') {
            dispatch(registerField('addServiceRequest', 'nurseOvertimeRate'));
        } else {
            dispatch(unregisterField('addServiceRequest', 'nurseOvertimeRate'));
        }
    }, [RatesValue]);

    const onSubmit = async (data) => {
        const {serviceRequestTypeId, startDate, endDate, clockIn, clockOut, chargeMethod, agencyRateToClient, nurseRate, nurseOvertimeRate} = data;

        const chartingTypeId = apiCallHelpers.getChartingTypeId(creatingForServiceRequestType);

        const payload = {
            serviceRequestTypeId: +serviceRequestTypeId,
            startDate: dateHelperFunctions.updateDateFormat(startDate),
            endDate: dateHelperFunctions.updateDateFormat(endDate),
            expectedClockIn: clockIn,
            expectedClockOut: clockOut,
            /*ratesInfo: {
                payRateTypeDesc: chargeMethod === '1' ? "hourly" : "other",
                payRateTypeId: +chargeMethod,
                agencyRateToClient: parseFloat(agencyRateToClient),
                nurseRate: parseFloat(nurseRate),
                nurseOvertimeRate: nurseOvertimeRate == null ? undefined : parseFloat(nurseOvertimeRate),
            },*/
            // formDataId: formDataId,
            chartingId: eachData?.id,
            chartingTypeId,
        }
// console.log('test- payload', payload);
        await dispatch(postServiceRequest('createChartingServiceRequest', payload));
        toastr.success('Visit created successfully', '');
        onClose(true);
    }

    return (
        <>
                <div className="app-main__inner service-request-inner">
                <div className="tab-content" id="myTabContent">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={`srtab active`}>
                            <div className="col-12 mb-5">
                                <div className="row">
                                    <div className="">
                                        <div className="col-12 col-sm-6 ">
                                            <Field
                                                className="form-control"
                                                name="serviceRequestTypeId"
                                                label="Select a Service Request"
                                                component={CustomDropDownField}
                                                requiredMark={true}
                                                validate={required}
                                            >
                                                <option value="">Please Select</option>
                                                {(serviceRequestTypesArray || [])?.map((res, index) =>
                                                    <option key={index} value={res.id}>{res.name}</option>
                                                )}
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="date-picker">
                                        <div className="row mt-3">
                                            {/*<div className="col-12 col-sm-6">
                                             <LocalizationProvider dateAdapter={AdapterDateFns}>
                                             <DatePicker
                                             label="Start Date"
                                             // value={selectedStartDate}
                                             sx={{width: '100%'}}
                                             minDate={new Date()} // Disable previous dates for Start Date
                                             onChange={handleDateChange}
                                             renderInput={(params) => <TextField {...params} />}
                                             />
                                             </LocalizationProvider>
                                             </div>
                                             <div className="col-12 col-sm-6">
                                             <LocalizationProvider dateAdapter={AdapterDateFns}>
                                             <DatePicker
                                             label="End Date"
                                             sx={{width: '100%'}}
                                             value={selectedEndDate}
                                             minDate={selectedStartDate} // Disable previous dates and dates before Start Date for End Date
                                             onChange={handleEndDateChange}
                                             renderInput={(params) => <TextField {...params} />}
                                             />
                                             </LocalizationProvider>
                                             </div>
                                             {selectedStartDate && selectedEndDate && selectedEndDate < selectedStartDate && (
                                             <Typography variant="body2" color="error">
                                             End Date should be after Start Date
                                             </Typography>
                                             )}*/}
                                            <div className="col-12 col-sm-6">
                                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                    <DatePicker
                                                      label="Visit Date"
                                                      sx={{width: '100%'}}
                                                      minDate={new Date()} // Disable previous dates for Start Date
                                                      onChange={handleDateChange}
                                                      renderInput={(params) => <TextField {...params} />}
                                                    />
                                                </LocalizationProvider>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row mt-2">
                                            <div className="col-12 col-sm-6">
                                                <Field
                                                  className="form-control mk-wi"
                                                  name="clockIn"
                                                  label="Clock In"
                                                  type="time"
                                                  requiredMark={true}
                                                  component={CustomInputField}
                                                  validate={required}
                                                ></Field>
                                            </div>
                                            <div className="col-12 col-sm-6">
                                                <Field
                                                    className="form-control mk-wi"
                                                    name="clockOut"
                                                    label="Clock Out"
                                                    type="time"
                                                    requiredMark={true}
                                                    component={CustomInputField}
                                                    validate={required}
                                                ></Field>
                                            </div>
                                        </div>
                                    </div>
                                    {/*<div className="form-group">*/}
                                    {/*    <div className="row">*/}
                                    {/*        <div className="col-12 col-sm-6">*/}
                                    {/*            <Field*/}
                                    {/*                className="form-control mk-wi"*/}
                                    {/*                name="hours"*/}
                                    {/*                label="Total Hours"*/}
                                    {/*                type="number"*/}
                                    {/*                requiredMark={true}*/}
                                    {/*                component={CustomInputField}*/}
                                    {/*                validate={required}*/}
                                    {/*                min="0"*/}
                                    {/*            />*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div className="form-group mt-4 w-100 clearfix">*/}
                                    {/*    <div className="col-12 col-sm-12">*/}
                                    {/*        <CheckboxGroup label={'Select Frequency'} buttonCheckBox={true}*/}
                                    {/*                       name="frequencyIds" options={frequenciesArray}*/}
                                    {/*                       requiredMark={true}/>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                    {/*<div className="col-12 col-sm-12 w-100 mt-3">
                                        <h3 className="form-inner-heading">Rates</h3>
                                    </div>
                                    <div className="form-group">
                                        <div className="col-12">
                                            <Field
                                                name="rates"
                                                component={CustomRadioGroup}
                                                options={ratesRadioOptions}
                                                requiredMark={false}
                                                onChange={(e) => getRatesValue(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="row">
                                            <div className="col-12 col-sm-4">
                                                <Field
                                                    className="form-control mk-wi"
                                                    name="agencyRateToClient"
                                                    label="Agency rates to clients"
                                                    type="number"
                                                    component={CustomInputField}
                                                    requiredMark={true}
                                                    validate={required}
                                                    min="0"
                                                />
                                            </div>
                                            <div className="col-12 col-sm-4">
                                                <Field
                                                    className="form-control mk-wi"
                                                    name="nurseRate"
                                                    label="Nurse Rate"
                                                    type="number"
                                                    component={CustomInputField}
                                                    requiredMark={true}
                                                    validate={required}
                                                    min="0"
                                                />
                                            </div>
                                            <div className="col-12 col-sm-4">
                                                <Field
                                                    className="form-control mk-wi"
                                                    name="nurseOvertimeRate"
                                                    label="Over Time Rate"
                                                    type="number"
                                                    component={CustomInputField}
                                                    requiredMark={RatesValue === '2' && chargeValue === '1'}
                                                    validate={RatesValue === '2' && chargeValue === '1' ? required : undefined}
                                                    min="0"
                                                    disabled={RatesValue === '2' && chargeValue === '1'}
                                                    value={'10'}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="col-12">
                                            <Field
                                                name="chargeMethod"
                                                component={CustomRadioGroup}
                                                options={chargeMethod}
                                                requiredMark={false}
                                                onChange={(e) => getChargeMethod(e)}
                                            />
                                        </div>
                                    </div>*/}
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-12 text-center pt-4">
                                    <button
                                        type="submit"
                                        className="btn btn-primary">
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
};

const mapStateToProps = (state) => {
    const { common, auth } = state;
    return {
        agencyId: auth?.authData?.agencyId || auth.agencyId,
    }
};

export default connect(mapStateToProps)(
    reduxForm({form: "serviceRequestModal", enableReinitialize: true})(ServiceRequestModal)
);
