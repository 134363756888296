export const required = value => value ? undefined : 'This field is required';
// export const required = (value) => {
// // console.log('test- required validation', JSON.stringify(value))
//   return value ? undefined : "This field is required";
// };

export const requiredArray = (value) => {
  const errMsg = "This field is required";
  return value ? (Array.isArray(value) && value.length === 0 ? errMsg : undefined) : errMsg;
};

export const emailValidation = (value) => {
  const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  if (value) {
    if (!emailPattern.test(value)) {
      return "Invalid email address";
    }
  }
  return undefined; // Return undefined if the email is valid
};

export const numberValidation = (value) => {
  const numberPattern = /^[0-9]+$/;

  if (value) {
    if (!numberPattern.test(value)) {
      return "Invalid input. Please enter a number";
    }
  }
  return undefined; // Return undefined if the input is valid
};

export const usPhoneNumberValidation = (value) => {
  const phonePattern =
    /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})$/;

  if (value) {
    if (!phonePattern.test(value)) {
      return "Invalid phone number";
    }
  }

  return undefined; // Return undefined if the phone number is valid
};

export const urlValidation = (value) => {
  try {
    new URL(value);
    return undefined;
  } catch (err) {
    return "Invalid url provided";
  }
};

export const siteUrlValidation = (value) => {
  let isValid = false;
  try {
    const newUrl = new URL(value);
    isValid = newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
  } catch (err) {
  }
  if (!isValid) {
    return "Invalid url provided";
  }
  return undefined;
};

// export const requiredNew = (value) => {
//   return value ? undefined : { message: "This field is required", };
// };

export const minLength1 = (value, len) => {
  if (value != null && value.length < len) {
    return `Minimum ${len} characters are required`;
  }

  return undefined; // Return undefined if the input is valid
};
const minLength = min => value =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;

export const minLength2 = minLength(2);
export const minLength5 = minLength(5);
export const minLength10 = minLength(10);
export const minLength15 = minLength(15);
