import React, { useEffect, useState } from "react";
import PrimaryButton from "../../../../sharedComponents/buttons/PrimaryButton";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import PersonalInfoInnerTabs from "./personalInfoInnerTabs";
import {change, Field, getFormValues, reduxForm} from "redux-form";
import { connect, useDispatch, useSelector } from "react-redux";
import { getNurseAction, postNurseAction } from "../../../../services/NursesProfile";
import * as nurshAction from "../nurseActionTypes";
import CustomInputField from "../../../../sharedComponents/reduxfields/customInputField";
import CustomDropDownNewField from "../../../../sharedComponents/reduxfields/customDropDownNew";
import {emailValidation, required, usPhoneNumberValidation} from "../../../../helpers/validation";
import {useQueryClient} from "@tanstack/react-query";
import stringHelperFunctions from "../../../../helpers/string.helpers";
import {getCityList} from "../../../../services/client";
import numberHelperFunctions from "../../../../helpers/number.helpers";
import classes from "../../Client/ClientProfile/CareNote/CareNote.module.css";
import SaveButton from "../../../../sharedComponents/buttons/SaveButton";
import {HttpsAction} from "../../../../commonApiFunction/httpsAction";
import useCustomNav from "../../../../utils/hooks/useCustomNav";


const EmergencyContacts = ({formData, listLength, notNewAndNotDeletedListLength, handleSubmit, pristine, submitting, isAdmin, agencyId}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { id: nurseId } = useParams();
  const { nurseId } = useOutletContext();

  // const isAdmin = window.location.pathname.includes('/admin/agencyNurses/edit');
  //
  // const emergencyContactsList = useSelector(
  //   (state) => {
  //     if (state != null && state.nurseListing != null && state.nurseListing.nurseEmergencyContacts != null) {
  //       // console.log('submit- state.nurseListing.nurseEmergencyContacts', state.nurseListing.nurseEmergencyContacts);
  //     }
  //     return state?.nurseListing?.nurseEmergencyContacts;
  //   }
  // );

  const { goTo: gotoCalendarsPage } = useCustomNav(isAdmin ? `/admin/agency/nurses/edit/profile/calender` : '/agency/nurses/edit/profile/calender');

  const statesArray = queryClient.getQueryData(['list-provinces-states', 233]);
  const phoneTypesArray = queryClient.getQueryData(['list-phone-types']);
  const relationshipsArray = queryClient.getQueryData(['list-relationships']);

  // const [isLoading, setLoading] = useState(false);
  // const [loadedOnce, setLoadedOnce] = useState(false);
  // const [loadOnceDone, setLoadOnceDone] = useState(false);
  const [cityList, setCityList] = useState({});
  const [selectedProvinceOrStateId, setSelectedProvinceOrStateId] = useState(0);
  const [loadedOnce, setLoadedOnce] = useState(false);
  const [allowAutoAddition, setAllowAutoAddition] = useState(true);
  const [dataModified, setDataModified] = useState(false);
  const [xtraFieldValue, setXtraFieldValue] = useState(1);

  const getStateId = async (e) => {
    // let index = e.target.selectedIndex;
    // let optionElement = e.target.childNodes[index];
    // let optionattr = optionElement.getAttribute("value");
    //
    // if (optionattr !== '0') {
    //   /// IIFE
    //   (async () => {
    //     const response = await dispatch(getCityList(optionattr));
    //     setCityList(prevState => ({...prevState, [optionattr]: response?.data}));
    //   })();
    // }

    let index = e.target.selectedIndex;
    let optionElement = e.target.childNodes[index];
    let optionattr = optionElement.getAttribute("value");
    setSelectedProvinceOrStateId(+optionattr);
    if (cityList[optionattr] == null || JSON.stringify(cityList[optionattr] === '{}')) {
      const response = await dispatch(getCityList(optionattr));
      setCityList(prevState => ({...prevState, [optionattr]: response?.data}));
    }
  };

  const onSubmit = async (data) => {
// console.log('test- onSubmit', data)
    //     console.log('data', data)
    //     const payload = [];
    //     emergencyContactsList.forEach((value, index) => {
    //         if (value.recordType !== "deleted") {
    //             const recordType = data[`recordType${index + 1}`];
    //             payload.push({
    //                 id: recordType === 'existing' ? data[`id${index + 1}`] : undefined,
    //                 recordType,
    //                 firstName: stringHelperFunctions.nullIfEmpty(data[`firstName${index + 1}`]),
    //                 lastName: stringHelperFunctions.nullIfEmpty(data[`lastName${index + 1}`]),
    //                 relationshipId: stringHelperFunctions.nullIfEmpty(+data[`relationshipId${index + 1}`]),
    //                 phoneTypeId: stringHelperFunctions.nullIfEmpty(+data[`phoneTypeId${index + 1}`]),
    //                 phoneNumber: stringHelperFunctions.nullIfEmpty(data[`phoneNumber${index + 1}`]),
    //                 address: stringHelperFunctions.nullIfEmpty(data[`address${index + 1}`]),
    //                 cityId: stringHelperFunctions.nullIfEmpty(+data[`cityId${index + 1}`]),
    //                 provinceOrStateId: stringHelperFunctions.nullIfEmpty(+data[`provinceOrStateId${index + 1}`]),
    //                 postalOrZipCode: stringHelperFunctions.nullIfEmpty(data[`postalOrZipCode${index + 1}`]),
    //             });
    //         } else {
    //             payload.push(value)
    //         }
    //     });
    //
    // // console.log('submit- payload', payload)
    //
    //     try {
    //         const response = await dispatch(
    //             postNurseAction(
    //                 payload,
    //                 "emergencyContacts",
    //                 nurseId,
    //                 "Nurse Emergency Contacts Updated Successfully"
    //             )
    //         );
    //         if (response?.success) {
    //             navigate(isAdmin ? `/admin/agencyNurses/edit/${nurseId}/agreement` : `/agency/nurses/edit/${nurseId}/agreement`)
    //         }
    //     } catch (error) {
    //         console.error(error);
    //     }

    try {
      const payload = [];
      for (let i = 0; i < listLength; i++) {
        if (data[`recordType${i + 1}`] !== "deleted") {
          const recordType = data[`recordType${i + 1}`];
          console.log(`index ==> ${i} ==>`, data[`recordType${i + 1}`])
          let id = +data[`id${i + 1}`];
          payload.push({
            recordType,
            id: id === 0 ? undefined : id,
            firstName: stringHelperFunctions.nullIfEmpty(data[`firstName${i + 1}`]),
            lastName: stringHelperFunctions.nullIfEmpty(data[`lastName${i + 1}`]),
            relationshipId: numberHelperFunctions.nullIfEmpty(data[`relationshipId${i + 1}`], true),
            addressLine1: stringHelperFunctions.nullIfEmpty(data[`addressLine1${i + 1}`]),
            provinceOrStateId: numberHelperFunctions.nullIfEmpty(data[`provinceOrStateId${i + 1}`], true),
            cityId: numberHelperFunctions.nullIfEmpty(data[`cityId${i + 1}`], true),
            postalOrZipCode: stringHelperFunctions.nullIfEmpty(data[`postalOrZipCode${i + 1}`]),
            email: stringHelperFunctions.nullIfEmpty(data[`email${i + 1}`]),
            // phoneNumbers: stringHelperFunctions.nullIfEmpty(data[`phoneNumbers${i + 1}`]),
            phoneTypeId: numberHelperFunctions.nullIfEmpty(data[`phoneTypeId${i + 1}`], true),
            phoneNumber: stringHelperFunctions.nullIfEmpty(data[`phoneNumber${i + 1}`]),
          });
        } else {
          const recordType = data[`recordType${i + 1}`];
          let id = +data[`id${i + 1}`];
          payload.push({recordType, id: id === 0 ? undefined : Number(id)})
        }
      }
// console.log('test- payload', payload)
      const response = await dispatch(postNurseAction(payload, "emergencyContacts", nurseId, "Nurse emergency contacts updated successfully"));
      if (response?.success) {
        // navigate(isAdmin ? `/admin/agencyNurses/edit/${nurseId}/agreement` : `/agency/nurses/edit/${nurseId}/agreement`)
        // navigate(isAdmin ? `/admin/agencyNurses/edit/${nurseId}/calender` : `/agency/nurses/edit/${nurseId}/calender`)
        gotoCalendarsPage();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = (deletedIndex) => {
    // if (deletedIndex) {
    //     let clonedList = [...emergencyContactsList];
    //     if (clonedList[deletedIndex - 1]?.id) {
    //         const updatedCertification = {
    //             recordType: 'deleted',
    //             id: clonedList[deletedIndex - 1].id
    //         };
    //
    //         clonedList[deletedIndex - 1] = updatedCertification;
    //
    //         dispatch({
    //             type: 'ADD_NURSE_REMOVE_EDUCATION',
    //             payload: clonedList,
    //             name: 'nurseEmergencyContacts',
    //             meta: {type: 'REMOVE_EDUCATION'},
    //         });
    //     } else {
    //         const updatedList = emergencyContactsList.filter((item, index) => index !== deletedIndex - 1)
    //         dispatch({
    //             type: 'ADD_NURSE_REMOVE_EDUCATION',
    //             payload: updatedList,
    //             name: 'nurseEmergencyContacts',
    //             meta: {type: 'REMOVE_EDUCATION'},
    //         });
    //     }
    // }

    const list = Array.from({ length: listLength }, (v, i) => i);
    let updatedList = list.map((val, index) => {
      return {
        recordType: formData[`recordType${index + 1}`],
        id: formData[`id${index + 1}`],

        firstName: formData[`firstName${index + 1}`],
        lastName: formData[`lastName${index + 1}`],
        relationshipId: formData[`relationshipId${index + 1}`],
        addressLine1: formData[`addressLine1${index + 1}`],
        provinceOrStateId: formData[`provinceOrStateId${index + 1}`],
        cityId: formData[`cityId${index + 1}`],
        postalOrZipCode: formData[`postalOrZipCode${index + 1}`],
        email: formData[`email${index + 1}`],
        // phoneNumbers: formData[`phoneNumbers${index + 1}`],
        phoneTypeId: formData[`phoneTypeId${index + 1}`],
        phoneNumber: formData[`phoneNumber${index + 1}`],
        // index,
      };
    });
    const deletedItemIdValue = formData[`id${deletedIndex}`];
    if (deletedItemIdValue === '0') { // item not present in database, so remove item from list/array
      updatedList.splice(deletedIndex - 1, 1);
    } else { // item present in database, so identify/mark it for deletion for later API call
      updatedList[deletedIndex - 1].recordType = "deleted";
    }
// console.log('test- updatedList', updatedList)
    setAllowAutoAddition(false);
    dispatch(HttpsAction({
      reducerName: 'NURSE',
      reduxKeyName: 'nurseEmergencyContacts',
      actionType: 'NURSE_EMERGENCY_CONTACTS',
      data: {xtraField: formData['xtraField'], list: updatedList},
      updateData: true,
      actionTypeIsDispatchType: true,
      positiveCallBack: (_) => {
        setDataModified(true);
      },
    }));
  };

  const handleAddContact = () => {
    let updatedList = Array.from({length: listLength}, (v, i) => i).map((val, index) => {
      return {
        recordType: formData[`recordType${index + 1}`],
        id: formData[`id${index + 1}`],
        firstName: formData[`firstName${index + 1}`],
        lastName: formData[`lastName${index + 1}`],
        relationshipId: formData[`relationshipId${index + 1}`],
        addressLine1: formData[`addressLine1${index + 1}`],
        provinceOrStateId: formData[`provinceOrStateId${index + 1}`],
        cityId: formData[`cityId${index + 1}`],
        postalOrZipCode: formData[`postalOrZipCode${index + 1}`],
        email: formData[`email${index + 1}`],
        // phoneNumbers: formData[`phoneNumbers${index + 1}`],
        phoneTypeId: formData[`phoneTypeId${index + 1}`],
        phoneNumber: formData[`phoneNumber${index + 1}`],
        // index,
      };
    });
    dispatch(HttpsAction({
      reducerName: 'NURSE',
      reduxKeyName: 'nurseEducationInfo',
      actionType: 'NURSE_EDUCATION',
      data: {xtraField: formData['xtraField'], list: updatedList},
      updateData: true,
      actionTypeIsDispatchType: true,
      positiveCallBack: (e) => {
        dispatch({type: nurshAction.NURSE_ADD_EMERGENCY_CONTACTS});
        setDataModified(true);
      },
    }));
  };

  const handleChangeCity = ({ value }) => {
    console.log(value, "city value");
  };

  // useEffect(() => {
  //   if (emergencyContactsList?.length === 0) {
  //     dispatch({ type: nurshAction.NURSE_ADD_EMERGENCY_CONTACTS });
  //   }
  // }, [emergencyContactsList?.length === 0]);

  // useEffect(() => {
  //   console.log('initialValues', initialValues)
  //   if (emergencyContactsList.length === 0) {
  //     dispatch({ type: nurshAction.NURSE_ADD_EMERGENCY_CONTACTS });
  //   }
  // }, [emergencyContactsList]);

  // console.log('emergencyContactsList', emergencyContactsList)

  // useEffect(() => {
  //   if (loadOnceDone) {
  //     console.log('initialValues', initialValues)
  //     const provinceOrStateId = initialValues?.provinceOrStateId1;
  //     const cityId = initialValues?.cityId1;
  //
  //     if (provinceOrStateId != null && provinceOrStateId.length > 0) {
  //       // bind city on load
  //       const optionData = {
  //         optionId: Number(provinceOrStateId),
  //         serviceName: "getCityList",
  //       };
  //       if (cityId != null && cityId.length > 0) {
  //         setCityList([]);
  //         fetchData(optionData).then(() => {
  //           dispatch(change('EmergencyContacts', 'cityId1', cityId));
  //         });
  //       }
  //     }
  //   }
  // }, [loadOnceDone]);
  //
  // useEffect(() => {
  //   if (!loadOnceDone && nurseId != null && initialValues != null && dataLoaded) {
  //     setTimeout(() => {
  //       setLoadOnceDone(true);
  //     }, 1000);
  //   }
  // }, [dataLoaded]);

  useEffect(() => {
    // if (emergencyContactsList?.length > 0) {
    //   setCityList({});
    //   emergencyContactsList.forEach((value, index) => {
    //     const provinceOrStateId = value?.provinceOrStateId?.toString();
    //     const cityId = value?.cityId?.toString();
    //     if (provinceOrStateId != null && provinceOrStateId.length > 0 && provinceOrStateId !== '0') {
    //       // bind city on load
    //       if (cityId != null && cityId.length > 0) {
    //         if (provinceOrStateId !== '0') {
    //           /// IIFE
    //           (async () => {
    //             const response = await dispatch(getCityList(provinceOrStateId));
    //             setCityList(prevState => ({...prevState, [provinceOrStateId]: response?.data}));
    //             dispatch(change('EmergencyContacts', `cityId${index + 1}`, cityId));
    //           })();
    //         }
    //       }
    //     }
    //   });
    // }
  }, [/*emergencyContactsList*/]);

  useEffect(() => {
// console.log('test- ', listLength, formData, loadedOnce)
    if (listLength > 0 && formData != null && JSON.stringify(formData) !== '{}' && formData['id1'] !== '0' && !loadedOnce) {
      setLoadedOnce(true);
      setCityList({});
// console.log('test- formData', formData)
      const loadedList = Array.from({length: listLength}, (v, i) => i).map((val, index) => {
        return {
          cityId: formData[`cityId${index + 1}`],
          provinceOrStateId: formData[`provinceOrStateId${index + 1}`],
        };
      });
      loadedList.forEach((value, index) => {
// console.log('test- value', value)
        const provinceOrStateId = value?.provinceOrStateId?.toString() || null;
        const cityId = value?.cityId?.toString() || null;
        if (provinceOrStateId != null && provinceOrStateId.length > 0 && provinceOrStateId !== '0') {
          // IIFE
          (async () => {
            const response = await dispatch(getCityList(provinceOrStateId));
            setCityList(prevState => ({...prevState, [provinceOrStateId]: response?.data}));
            // bind city on load
            if (cityId != null && cityId.length > 0 && cityId !== '0') {
              dispatch(change('EmergencyContactsForm', `cityId${index + 1}`, cityId));
            }
          })();
        }
      });
    }
  }, [listLength, formData, loadedOnce]);

  // useEffect(() => {
  //   if (!isLoading && loadedOnce) {
  //     setLoadedOnce(false);
  //     dispatch(getNurseAction("emergencyContacts", nurseId));
  //   }
  // }, [loadedOnce]);
  //
  // useEffect(() => {
  //   if (isLoading && nurseId != null) {
  //     setLoading(false);
  //     setLoadedOnce(true);
  //   }
  // }, [isLoading]);

  useEffect(() => {
    if (dataModified) {
      setDataModified(false);
      dispatch(change("EmergencyContactsForm", 'xtraField', String(xtraFieldValue), true));
      setXtraFieldValue(prevState => prevState + 1);
    }
  }, [dataModified]);

  useEffect(() => {
    if (allowAutoAddition && listLength === 0) {
      dispatch({type: nurshAction.NURSE_ADD_EMERGENCY_CONTACTS});
    }
  }, [listLength]);

  useEffect(() => {
    // setLoadOnceDone(false);
    // setLoading(true);
    dispatch(getNurseAction("emergencyContacts", nurseId));
  }, []);

  // console.log('cityList', cityList)

    return (
      <div>
        <div className="card-white px-4">
          {/*<PersonalInfoInnerTabs></PersonalInfoInnerTabs>*/}
          <form className="pt-3" onSubmit={handleSubmit(onSubmit)}>
            <Field
              name="xtraField"
              type="hidden"
              component={CustomInputField}
            />
            <div className="p-0 m-0 d-flex flex-column justify-content-start align-content-start gap-3">
              {/*{(emergencyContactsList || []).filter(v => v.recordType !== 'deleted').map((value, index) => (*/}
              {listLength > 0 && (Array.from({length: listLength}, (val, idx) => idx) || []).map((value, index) => (
                <React.Fragment key={index}>
                  <div className="card" style={{
                    overflow: "hidden",
                    borderWidth: formData != null && JSON.stringify(formData) !== '{}' && formData[`recordType${index + 1}`] === "deleted" ? "0" : "auto",
                    height: formData != null && JSON.stringify(formData) !== '{}' && formData[`recordType${index + 1}`] === "deleted" ? "1px" : "auto"
                  }}>
                    <div className="card-body">
                      <div className={`row ${index === 0 ? '' : 'mt-2'}`}>
                        <div className="col-6 d-flex align-items-center">
                          <div className={`col-4`} style={{fontSize: "18px", fontWeight: 500, whiteSpace: "nowrap"}}>
                            Contact #{index + 1}
                          </div>
                        </div>
                        <div className="col-6 d-flex justify-content-end">
                          <Field
                            name={`recordType${index + 1}`}
                            type="hidden"
                            component={CustomInputField}
                          />
                          <Field
                            name={`id${index + 1}`}
                            type="hidden"
                            component={CustomInputField}
                          />
                          {(notNewAndNotDeletedListLength > 0 || listLength > 1 || index > 0) &&
                            <PrimaryButton
                              type="button"
                              className="btn-view-repo gap-2 px-4 py-2"
                              text="Delete Contact"
                              onClick={() => handleDelete(index + 1)}
                            />}
                        </div>
                          <div className={classes.contactFormHeadingBorder}/>
                          <div style={{padding: '24px 24px 0 24px'}}>
                            <div>
                              <div className="row">
                                <div className={`col-4 ${index !== 0 && `mt-3`}`}>
                                  <div className="mb-2 input-field-main">
                                    <Field
                                      className="form-control mk-wi"
                                      name={`firstName${index + 1}`}
                                      label="First Name"
                                      component={CustomInputField}
                                      requiredMark={true}
                                      validate={required}
                                    />
                                  </div>
                                </div>
                                <div className={`col-4 ${index !== 0 && `mt-3`}`}>
                                  <div className="mb-2 input-field-main">
                                    <Field
                                      className="form-control mk-wi"
                                      name={`lastName${index + 1}`}
                                      label="Last Name"
                                      component={CustomInputField}
                                      requiredMark={true}
                                      validate={required}
                                    />
                                  </div>
                                </div>
                                <div className={`col-4 ${index !== 0 && `mt-3`}`}>
                                  <div className="mb-2 input-field-main">
                                    <Field
                                      className="form-control mk-wi"
                                      name={`relationshipId${index + 1}`}
                                      label="Relationship"
                                      component={CustomDropDownNewField}
                                      // onChange={({ target }) => handleChangeState(target)}
                                    >
                                      <option value="">Select Relationship</option>
                                      {(relationshipsArray || []).map((res, index) => (
                                        <option key={index} value={res.id}>
                                          {res.name}
                                        </option>
                                      ))}
                                    </Field>
                                  </div>
                                </div>
                                <div className={`col-4 ${index !== 0 && `mt-3`}`}>
                                  <div className="mb-2 input-field-main">
                                    <Field
                                      className="form-control mk-wi"
                                      name={`email${index + 1}`}
                                      label="Email"
                                      type="email"
                                      component={CustomInputField}
                                      requiredMark={true}
                                      validate={[required, emailValidation]}
                                    />
                                  </div>
                                </div>
                                <div className={`col-sm-3 col-2 ${index !== 0 && `mt-3`}`}>
                                  <div className="mb-2 input-field-main">
                                    <Field
                                      className="form-control mk-wi"
                                      name={`phoneTypeId${index + 1}`}
                                      label="Phone"
                                      component={CustomDropDownNewField}
                                      // onChange={({ target }) => handleChangeState(target)}
                                    >
                                      <option value="">Select Phone Type</option>
                                      {(phoneTypesArray || []).map((res, index) => (
                                        <option key={index} value={res.id}>
                                          {res.name}
                                        </option>
                                      ))}
                                    </Field>
                                  </div>
                                </div>
                                <div className={`col-4 ${index !== 0 && `mt-3`}`}>
                                  <div className="mb-2 input-field-main">
                                    <Field
                                      className="form-control mk-wi"
                                      name={`phoneNumber${index + 1}`}
                                      label="Phone Number"
                                      component={CustomInputField}
                                      validate={usPhoneNumberValidation}
                                    />
                                  </div>
                                </div>
                                <div className={`col-12 ${index !== 0 && `mt-3`}`}>
                                  <div className="mb-2 input-field-main">
                                    <Field
                                      className="form-control mk-wi"
                                      name={`addressLine1${index + 1}`}
                                      label="Addresss"
                                      component={CustomInputField}
                                    />
                                  </div>
                                </div>
                                <div className={`col-4 ${index !== 0 && `mt-3`}`}>
                                  <div className="mb-2 input-field-main">
                                    <Field
                                      className="form-control mk-wi"
                                      label="State"
                                      name={`provinceOrStateId${index + 1}`}
                                      component={CustomDropDownNewField}
                                      // onChange={({ target }) => handleChangeState(target)}
                                      onChange={(e) => getStateId(e)}
                                    >
                                      <option value="">Select State</option>
                                      {(statesArray || []).map((res, index) => (
                                        <option key={index} value={res.id}>
                                          {res.name}
                                        </option>
                                      ))}
                                    </Field>
                                  </div>
                                </div>
                                <div className={`col-4 ${index !== 0 && `mt-3`}`}>
                                  <div className="mb-2 input-field-main">
                                    <Field
                                      className="form-control mk-wi"
                                      label="City"
                                      name={`cityId${index + 1}`}
                                      component={CustomDropDownNewField}
                                      onChange={({target}) => handleChangeCity(target)}
                                    >
                                      <option value="">Select City</option>
                                      {/*{(cityList[value[`provinceOrStateId`]] || []).map((res, index) => (
                                       <option key={index} value={res.id}>{res.name}</option>
                                       ))}*/}
                                      {((formData != null && cityList[formData[`provinceOrStateId${index + 1}`]]) || cityList[String(selectedProvinceOrStateId)])?.map((res, index) => {
                                        return <option key={index} value={res.id}>{res.name}</option>
                                      })}
                                    </Field>
                                  </div>
                                </div>
                                <div className={`col-4 ${index !== 0 && `mt-3`}`}>
                                  <div className="mb-2 input-field-main">
                                    <Field
                                      className="form-control mk-wi"
                                      name={`postalOrZipCode${index + 1}`}
                                      label="Postal Code"
                                      placeholder="Enter Code"
                                      component={CustomInputField}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </React.Fragment>
              ))}
              <div className={'w-100 d-flex flex-row justify-content-center gap-2'}>
                <PrimaryButton
                  type="button"
                  className="btn btn-primary w-100 rounded-0 border-btn-info"
                  style={{maxWidth: '210px'}}
                  text="Add New Contact"
                  onClick={() => handleAddContact()}
                />
              </div>
            </div>
            <SaveButton pristine={pristine} submitting={submitting}
                        onClick={() => {
                          dispatch(change("EmergencyContactsForm", 'xtraField', String(xtraFieldValue)));
                          setXtraFieldValue(prevState => prevState + 1);
                          return undefined;
                        }}
            />
          </form>
        </div>
      </div>
    );
};

const mapStateToProps = (state) => {
  if (state?.nurseListing?.nurseEmergencyContacts != null) {
    const {auth, nurseListing: {nurseEmergencyContacts: {xtraField = '0', list: dataList = []} = {xtraField: '0', list: []}}} = state;

    let initialValues = {xtraField};
    if (dataList?.length > 0) {
      dataList?.forEach((contact, index) => {
        const recordType = contact.recordType ?? "existing";
        // initialValues[`recordType${index + 1}`] = recordType;
        // initialValues[`id${index + 1}`] = recordType === "existing" ? contact?.id : 0;
        initialValues[`recordType${index + 1}`] = contact.recordType || "existing";
        initialValues[`id${index + 1}`] = contact?.id?.toString() || '0';
        initialValues[`firstName${index + 1}`] = contact?.firstName || '';
        initialValues[`lastName${index + 1}`] = contact?.lastName || '';
        initialValues[`relationshipId${index + 1}`] = contact?.relationshipId?.toString() || '';
        initialValues[`addressLine1${index + 1}`] = stringHelperFunctions.sanitize(contact?.addressLine1, true, ['Nil']);
        initialValues[`provinceOrStateId${index + 1}`] = contact?.provinceOrStateId?.toString() || '';
        initialValues[`cityId${index + 1}`] = contact?.cityId?.toString() || '';
        initialValues[`postalOrZipCode${index + 1}`] = contact?.postalOrZipCode || '';
        initialValues[`email${index + 1}`] = contact?.email || '';
        // initialValues[`phoneNumbers${index + 1}`] = contact?.phoneNumbers || '';
        initialValues[`phoneTypeId${index + 1}`] = contact?.phoneTypeId?.toString() || '';
        initialValues[`phoneNumber${index + 1}`] = contact?.phoneNumber || '';
      });
    }

    // console.log('initialValues', initialValues)
    return {
      // dataLoaded: nurseEmergencyContacts != null /*&& nurseEmergencyContacts.length > 0*/,
      isAdmin: auth?.authData?.isAdmin || false,
      agencyId: auth?.authData?.agencyId || auth.agencyId,
      listLength: (dataList || []).length,
      notNewAndNotDeletedListLength: (dataList || []).filter(p => !(p.recordType === 'new' || p.recordType === 'deleted')).length,
      formData: getFormValues('EmergencyContactsForm')(state),
      initialValues,
    };
  }

  return {};
};

export default connect(mapStateToProps)(
  reduxForm({form: "EmergencyContactsForm", enableReinitialize: true})(EmergencyContacts)
);
