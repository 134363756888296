import React, {useEffect, useState} from 'react'
import {connect, useDispatch, useSelector} from "react-redux";
import {change, Field, reduxForm} from "redux-form";
import CustomInputField from '../../../../sharedComponents/reduxfields/customInputField'
import CustomDropDownField from '../../../../sharedComponents/reduxfields/customDropDown'
import CheckboxGroup from '../../../../sharedComponents/reduxfields/customCheckboxGroup'
import {required} from '../../../../helpers/validation'
import Modal from '../../../../sharedComponents/Modal'
import MultiSelectDropdown from '../../../../sharedComponents/multiSelect'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker, DesktopTimePicker} from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import dayjs from 'dayjs';
import {useQueryClient} from "@tanstack/react-query";

const etFormVarName = 'editTaskForm';

const EditTaskModal = ({serviceRequestTypeIdForAdd, handleSubmit, submitFailed, isModalOpen, closeModal, modalTitle, updateTasksList, agencyId}) => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();

    const taskData = useSelector(state => state.serviceRequest?.taskData);
    const taskDataIndex = useSelector(state => state.serviceRequest?.taskDataIndex);

    const frequencyDaysList = queryClient.getQueryData(['list-day-frequencies']);
    const serviceRequestTasksArray = queryClient.getQueryData(['list-service-request-tasks', agencyId]);
    const serviceRequestTypesArray = queryClient.getQueryData(['list-service-request-types', agencyId]);

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [taskSelectionValidation, setTaskSelectionValidation] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [filTaskList, setFilTaskList] = useState([]);
    const [showDateRange, setShowDateRange] = useState(false);
    const [showSingleDate, setShowSingleDate] = useState(true);

    const [clockIn, setClockIn] = useState();
    const [clockOut, setClockOut] = useState();
    const [validationError, setValidationError] = useState('');
    const [selectedDate, setSelectedDate] = React.useState(null);


    const getSelectedOptions = (selectedOptions) => {
        setSelectedOptions(selectedOptions)
        if (selectedOptions.length > 0) {
            setTaskSelectionValidation(false)
        } else {
            setTaskSelectionValidation(true)
        }
    }

    const handleClockInChange = (newValue) => {
        setClockIn(newValue);
        const dateVal = new Date(Date.parse(newValue));
        const clockInVal = dateVal.toLocaleTimeString('en-US', {hour12: false}).substring(0, 5);
        console.log('Selected clock-in:', newValue, clockInVal, dateVal);
        if (clockOut != null && newValue.isAfter(clockOut)) {
            setValidationError('Clock In time cannot be after Clock Out time');
        } else if (clockOut != null && newValue.isSame(clockOut)) {
            setValidationError('Clock In time cannot be the same as Clock Out time');
        } else {
            dispatch(change(etFormVarName, 'clockIn', clockInVal));
            setValidationError('');
        }
    };

    const handleClockOutChange = (newValue) => {
        setClockOut(newValue);
        const dateVal = new Date(Date.parse(newValue));
        const clockOutVal = dateVal.toLocaleTimeString('en-US', {hour12: false}).substring(0, 5);
        console.log('Selected clock-out:', newValue, clockOutVal, dateVal);
        if (clockIn != null && newValue.isBefore(clockIn)) {
            setValidationError('Clock Out time cannot be before Clock In time');
        } else if (clockIn != null && newValue.isSame(clockIn)) {
            setValidationError('Clock Out time cannot be the same as Clock In time');
        } else {
            dispatch(change(etFormVarName, 'clockOut', clockOutVal));
            setValidationError('');
        }
    };
    const handleDateChange = (newDate) => {
        setSelectedDate(newDate);
        const dateVal = new Date(Date.parse(newDate));
        // You can perform additional actions here based on the newDate value
        console.log('Selected Date:', newDate, dateVal);
        dispatch(change(etFormVarName, 'startDate', dateVal.toLocaleDateString()));
        if (showSingleDate)
            dispatch(change(etFormVarName, 'endDate', dateVal.toLocaleDateString()));
    };


    const onSubmit = async (values) => {
        if (selectedOptions.length > 0) {
            values.dayName = 'All';
            if (showDateRange) {
                const extractedNames = values.modalFrequencyIds.map(id => {
                    const matchingItem = frequencyDaysList.find(item => item.id === id);
                    return matchingItem ? matchingItem.name : null;
                });
                values.dayName = extractedNames;
            }
            // values.id = Math.random().toString(36).substring(2);
            values.id = taskDataIndex == null ? undefined : +values.id;
            values.recordType = taskDataIndex == null ? "new" : "existing";
            values.isDirty = true;
            values.tasksIds = selectedOptions.map(res => res.value);
            values.tasksNames = selectedOptions.map(res => res.label);
            if (showSingleDate) {
                values.endDate = values.startDate;
                values.modalFrequencyIds = frequencyDaysList.map(item => item.id);
            }
            values.serviceRequestTypeId = taskDataIndex == null ? serviceRequestTypeIdForAdd : +values.serviceRequestTypeId;
            console.log('taskItem- before', values);
            delete values['taskItemCategoryId'];
            updateTasksList(values);
            closeModal();
        } else {
            setTaskSelectionValidation(true);
        }
    }

    const setTaskCategory = () => {
        const uniqueMap = new Map();
        const uniqueArray = [];
        for (const item of serviceRequestTasksArray) {
            if (!uniqueMap.has(item['taskCategoryId'])) {
                uniqueMap.set(item['taskCategoryId'], true);
                uniqueArray.push(item);
            }
        }
        setCategoryList(uniqueArray);
    }

    const filterTask = (e) => {
        let index = e.target.selectedIndex;
        let optionElement = e.target.childNodes[index];
        let optionattr = optionElement.getAttribute('value');
        const filterTask = serviceRequestTasksArray?.filter(item => {
            if (item.taskCategoryId == optionattr) {
                return item;
            }
        })
        setFilTaskList(filterTask);
    }

    useEffect(() => {
        setTaskCategory()
    }, [])

    useEffect(() => {
        submitFailed && setTaskSelectionValidation(true)
    }, [submitFailed]);

    useEffect(() => {
        if (taskData) {
            // setSelectedDate(new Date(Date.parse(taskData?.dateFormatUs.replace('25', 29).replace('26', 29))))
            setSelectedDate(new Date(Date.parse(taskData?.dateFormatUs)));
            setClockIn(dayjs(taskData?.scheduledStartTime, 'HH:mm:ss'));
            setClockOut(dayjs(taskData?.scheduledEndTime, 'HH:mm:ss'));
            setSelectedOptions(taskData?.activities?.map(v => ({value: v.id, label: v.name})));
        }
        // dispatch(getNursesList())
    }, [taskData]);

    return (
        <Modal isOpen={isModalOpen} onClose={closeModal} modalTitle={modalTitle}>
            <form className='addTaskForm' onSubmit={handleSubmit(onSubmit)} id="addTaskForm">
                <Field name="id" component={CustomInputField} type="hidden"></Field>
                {/*<Field name="recordType" component={CustomInputField} type="hidden"></Field>*/}
                {taskDataIndex && <div className="col-12 col-sm-6 mb-4">
                    <Field
                      className="form-control"
                      label="Select a service Request"
                      name="serviceRequestTypeId"
                      component={CustomDropDownField}
                      validate={required}
                      requiredMark={true}
                      // readOnly={!taskDataIndex}
                      // readOnly={true}
                    >
                        <option value="">Please Select</option>
                        {serviceRequestTypesArray?.map((res, index) => {
                            return <option key={index} value={res.id}>{res.name}</option>
                        })}
                    </Field>
                </div>}
                {!taskDataIndex && <Field name="serviceRequestTypeId" component={CustomInputField} type="hidden"></Field>}
                <div className="form-label">
                    {showDateRange && <div className="row">
                        <div className="col-sm-6">
                            <Field
                                className="form-control mk-wi"
                                name="startDate"
                                label="Visit Start Date"
                                type="date"
                                component={CustomInputField}
                                requiredMark={true}
                                validate={required}
                            ></Field>
                        </div>
                        <div className="col-sm-6">
                            <Field
                                className="form-control mk-wi"
                                name="endDate"
                                label="Visit End Date"
                                type="date"
                                component={CustomInputField}
                                requiredMark={true}
                                validate={required}
                            ></Field>
                        </div>
                    </div>}


                    {showSingleDate && <div className="row">
                        <div className="col-sm-6">
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Visit Date"
                                    value={selectedDate}
                                    onChange={(newDate) => handleDateChange(newDate)}
                                    minDate={new Date()} // Set minimum selectable date to the current date
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>}
                </div>
                <div className="form-label pt-2">
                    <div className="row">

                        <div className="col-sm-6">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopTimePicker
                                    label="Clock In"
                                    value={clockIn}
                                    onChange={handleClockInChange}
                                    ampm={false}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className="col-sm-6">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopTimePicker
                                    label="Clock Out"
                                    value={clockOut}
                                    onChange={handleClockOutChange}
                                    ampm={false}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>
                    {validationError && <p style={{color: 'red'}} className="error-message">{validationError}</p>}
                </div>
                <div className='form-group form-label pt-2'>
                    <label>Select Task Category {/*<span className="mandatory-field">*</span>*/}</label>
                    <Field
                        className="form-control"
                        name="taskItemCategoryId"
                        component={CustomDropDownField}
                        validate={required}
                        requiredMark={true}
                        onChange={(e) => filterTask(e)}
                    >
                        <option value="">Please select</option>
                        {categoryList?.map((res, index) => {
                            return <option key={index} value={res.taskCategoryId}>{res.taskCategoryName}</option>
                        })}
                    </Field>
                </div>
                <div className="form-group form-label pt-2">
                    <div className="row">
                        <div className="col-sm-12">
                            <MultiSelectDropdown dpOptions={filTaskList} getSelectedOptions={getSelectedOptions}
                                                 selectedLanguages={selectedOptions} required={true} requiredMark={true}
                                                 label="Select Task"/>
                            {taskSelectionValidation && (<span className="field-error">This field is required</span>)}
                        </div>
                    </div>
                </div>
                {showDateRange && <div className="form-group form-label pt-2">
                    <div className="row">
                        <div className="col-sm-12">
                            <CheckboxGroup label={'Select Frequency'} buttonCheckBox={true} name="modalFrequencyIds"
                                           options={frequencyDaysList} requiredMark={true}/>
                        </div>
                    </div>
                </div>}

                {/*<div className="col-12 col-sm-6 mb-4">
                    <Field
                        className="form-control"
                        label="Assigned Nurse"
                        name="assignedNurseId"
                        component={CustomDropDownField}
                        validate={required}
                        requiredMark={true}
                    >
                        <option value="">Please Select</option>
                        {nursesList?.map((res, index) => {
                            return <option key={index} value={res.id}>{res.nurseFullName}</option>
                        })}
                    </Field>
                </div>*/}

                <div className="modal-footer justify-content-center pt-4 pb-0 gap-2 mt-4">
                    <button type="button" className="btn btn-secondary" onClick={() => closeModal()}>Close</button>
                    <button type="submit" className="btn btn-primary">{taskDataIndex ? "Update" : "Add"}</button>
                </div>
            </form>
        </Modal>
    )
};

const mapStateToProps = (state, ownProps) => {
    const { serviceRequest: { taskData = null } } = state;

    return {
        initialValues: {
            // recordType: "existing",
            // assignedNurseId: taskData?.assignedNurseId?.toString(),
            id: taskData?.id?.toString() || '',
            serviceRequestTypeId: taskData?.serviceRequestTypeId != null ? taskData?.serviceRequestTypeId?.toString() : ownProps.serviceRequestTypeIdForAdd?.toString(),
            startDate: taskData?.date || '',
            endDate: taskData?.date || '',
            clockIn: taskData?.scheduledStartTimeNoSeconds || '',
            clockOut: taskData?.scheduledEndTimeNoSeconds || '',
        }
    }
};


export default connect(mapStateToProps)(
    reduxForm({form: etFormVarName, enableReinitialize: true})(EditTaskModal)
);
