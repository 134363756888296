import React, {useEffect, useState} from "react";
import classes from "../CareNote/CareNote.module.css";
import {Stack} from "@mui/material";
import PrimaryButton from "../../../../../sharedComponents/buttons/PrimaryButton";
import {useDispatch, useSelector} from "react-redux";
import {HttpsAction} from "../../../../../commonApiFunction/httpsAction";
import Modal from "../../../../../sharedComponents/Modal";
import AddNote from "./AddNote";
import {useParams} from "react-router";
import {useOutletContext} from "react-router-dom";

const Notes = () => {

    // const {clientId} = useParams();
    const { clientId } = useOutletContext();

    const dispatch = useDispatch();

    const notes = useSelector(state => state.client?.notes?.data || []);

    const [modal, setModal] = useState(false);
    const [editModal, setEditModal] = useState(false);

    const handleAddModal = () => setModal(pre => !pre)

    const handleEditModal = (id) => {
        if (id)
            dispatch(HttpsAction({
                apiSection: 'client',
                apiName: 'notes',
                queryParameter: `/${clientId}/${id}`,
                reduxKeyName: 'getNoteById',
                reducerName: 'CLIENT',
                actionType: 'GET_NOTE_BY_ID'
            }))
        setEditModal(pre => id)
    }

    const handleRemoveNote = (id) => {
        dispatch(HttpsAction({
            method: 'DELETE',
            apiSection: 'client',
            apiName: 'notes',
            queryParameter: `/${clientId}/${id}`,
            positiveCallBack: () => {
                getNotes()
            }
        }))
    };

    const getNotes = () => {
        dispatch(HttpsAction({
            apiSection: 'client',
            apiName: 'notes',
            queryParameter: `/${clientId}`,
            reducerName: 'CLIENT',
            reduxKeyName: 'notes',
            actionType: 'GET_NOTES'
        }))
    };

    useEffect(() => {
        getNotes();
    }, [])

    return (
        <>
            <Stack className={classes.contentMain}>
                <div className={'row'}>
                    <div className={'col-2'}>
                        <PrimaryButton
                            type="button"
                            className="btn-view-repo rounded-3 gap-2 px-3 py-2"
                            text={"Add Notes"}
                            onClick={handleAddModal}
                        />
                    </div>
                </div>
                {(notes?.rows || []).map((v, i) => (
                    <div key={i} className={'row gap-2'}>
                        <div className={'col-12'}>
                            <Stack className={classes.noteBox}>
                                {v.note}
                                <Stack className={classes.noteButtons}>
                                    <button
                                        type="button"
                                        className={`btn btn-primary rounded-0 border-btn-info ${classes.editDeleteButton}`}
                                        style={{width: '80px'}}
                                        onClick={() => handleEditModal(v.id)}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M7 7H6C5.46957 7 4.96086 7.21071 4.58579 7.58579C4.21071 7.96086 4 8.46957 4 9V18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20H15C15.5304 20 16.0391 19.7893 16.4142 19.4142C16.7893 19.0391 17 18.5304 17 18V17"
                                                stroke="#1976D2" strokeWidth="2" strokeLinecap="round"
                                                strokeLinejoin="round"/>
                                            <path
                                                d="M16 5.00011L19 8.00011M20.385 6.58511C20.7788 6.19126 21.0001 5.65709 21.0001 5.10011C21.0001 4.54312 20.7788 4.00895 20.385 3.61511C19.9912 3.22126 19.457 3 18.9 3C18.343 3 17.8088 3.22126 17.415 3.61511L9 12.0001V15.0001H12L20.385 6.58511Z"
                                                stroke="#1976D2" strokeWidth="2" strokeLinecap="round"
                                                strokeLinejoin="round"/>
                                        </svg>
                                        <span>Edit</span>
                                    </button>
                                    <button
                                        type="button"
                                        className={`btn btn-primary rounded-0 border-btn-info ${classes.editDeleteButton}`}
                                        style={{width: '113px'}}
                                        onClick={() => handleRemoveNote(v.id)}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M19 4H15.5L14.5 3H9.5L8.5 4H5V6H19M6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19V7H6V19Z"
                                                fill="#1976D2"/>
                                        </svg>
                                        <span>Remove</span>
                                    </button>
                                </Stack>
                            </Stack>
                        </div>
                    </div>
                ))}

                {/*<div className={'row mt-5'}>*/}
                {/*    <div className={'col-2'}>*/}
                {/*        <PrimaryButton*/}
                {/*            type="button"*/}
                {/*            className="btn-view-repo rounded-3 gap-2 px-3 py-2"*/}
                {/*            text={"Save Note"}*/}
                {/*            onClick={() => undefined}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*</div>*/}
            </Stack>
            <Modal modalTitle={'Add Note'} onClose={handleAddModal} isOpen={modal}>
                <AddNote onClose={handleAddModal}/>
            </Modal>
            <Modal modalTitle={'Edit Note'} onClose={() => handleEditModal(false)}
                   isOpen={editModal}>
                <AddNote isEdit={editModal} onClose={() => handleEditModal(false)}/>
            </Modal>
        </>
    )
};
export default Notes;
