import React from 'react';
import {Field} from "redux-form";

const validateCheckboxGroup = value => {
    return value ? undefined || value.length < 1 : 'This field is required';
}

const CheckboxGroup = props => {

    const field = ({input, meta, options, buttonCheckBox}) => {

        const {name, onChange, onBlur, onFocus} = input;
        const {touched, error} = meta;
        const inputValue = input.value;
    
        const checkboxes = options?.map(({id, name}, index) => {
    
          const handleChange = (event) => {
            const arr = [...inputValue];
            if (event.target.checked) {
              arr.push(id);
            }
            else {
              arr.splice(arr.indexOf(id), 1);
            }
            onBlur(arr);
            return onChange(arr);
          };

          const checked = inputValue.includes(id);

          return (
            buttonCheckBox ? (
                <div className='frequency'>
                  <label className={`btn btn-outline-primary ${checked ? 'active' : ''}`} key={`checkbox-${index}`}>
                    <input type="checkbox" name={`${name}[${index}]`} value={id} checked={checked} onChange={handleChange} onFocus={onFocus}/>
                    <span>{name}</span>
                  </label>
                </div>
              ) : (
                <div className="form-check form-check-inline">
                  <label className="form-check-label" key={`checkbox-${index}`}>
                    <input type="checkbox" className='form-check-input' name={`${name}[${index}]`} value={id} checked={checked} onChange={handleChange} onFocus={onFocus}/>
                    <span>{name}</span>
                  </label>
                </div>
              )
          );
        });
    
        return (
          <>
              <div className='d-flex flex-wrap gap-3 pos-rel'>
                {checkboxes}
                {touched && error && <span className="field-error checkboxGroupError">{error}</span>}
              </div>
          </>
        );
      };

      return (
        <>
            <div className="col-12">
                <label>{props.label} {props.requiredMark && <span className="mandatory-field">*</span>}</label>
            </div>
            <Field {...props} type="checkbox" component={field} validate={props.requiredMark ? validateCheckboxGroup : undefined}/>
        </>
      )

}

export default CheckboxGroup;
