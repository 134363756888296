import React, { useState, lazy, useEffect } from "react";
import { ReactComponent as SearchIcon } from "../../../Agency/assets/image/ion_search.svg";
import { Avatar } from "@mui/material";
import moment from "moment/moment";

const MessageSidebar = (props) => {
  const {
    selectedUser,
    handleUserClick,
    handleSearchChange,
    handleButtonClick,
    allButtonClass,
    unreadButtonClass,
    userListForChat,
    preSelectedUser,
  } = props;

  const [updatedUserList, setUpdatedUserList] = useState([]);

  useEffect(() => {
    let handled = false;
    const updatedList = [...userListForChat];
// console.log('preSelectedUser', preSelectedUser, updatedList, userListForChat)
    if (updatedList.length > 0) {
      if (preSelectedUser != null && preSelectedUser.id != null) {
        const foundUserItemIndex = updatedList.findIndex(item => item.id === preSelectedUser.id);
        const foundItem = userListForChat[foundUserItemIndex];
        if (foundUserItemIndex > -1) {
          updatedList.splice(foundUserItemIndex, 1);
          updatedList.unshift({...foundItem});
          setUpdatedUserList(updatedList);
          handled = true;
        }
      }
      if (!handled) {
        setUpdatedUserList(updatedList);
      }
    }
// alert(handled)
  }, [preSelectedUser, userListForChat]);

  return (
    <div className="message-content">
      <div className="p-3  pb-0">
        <div className="header-search mb-4">
          <div className="input-group align-items-center">
            <span className="input-group-append">
              <button className="btn" type="button">
                <SearchIcon />
              </button>
            </span>
            <input
              className="form-control"
              type="search"
              placeholder="People, groups, messages.."
              onChange={handleSearchChange}
            />
          </div>
        </div>
        <div className="tab-btn mb-4 d-flex w-100">
          <button
            name="all"
            className={allButtonClass}
            onClick={handleButtonClick}
          >
            All
          </button>
          <button
            name="unread"
            className={unreadButtonClass}
            onClick={handleButtonClick}
          >
            Unread
          </button>
        </div>
      </div>

      <div className="message-list px-2 px-xl-3">
        <div className="message-group-list">
          <ul>
            {/* <li className="d-flex gap-3">
              <div>
                <Avatar
                  alt="Remy Sharp"
                  src="/static/images/avatar/1.jpg"
                  sx={{ width: 52, height: 52 }}
                />
              </div>
              <div className="w-100">
                <div className="people-name d-flex justify-content-between align-items-center">
                  <h6 className="mb-0">Eleanor Pena </h6>
                  <p>9:13 AM</p>
                </div>
                <div className="people-bottom-tex gap-2 d-flex justify-content-between align-items-center">
                  <p className="mb-0 w-100">
                    So, what's your plan this weekend?
                  </p>
                  <span className="message-count">18</span>
                </div>
              </div>
            </li> */}
            {updatedUserList && updatedUserList.length > 0
              ? updatedUserList.map((user, index) => (
                  <li
                    className="d-flex gap-3"
                    key={index}
                    onClick={() => handleUserClick(user)}
                    style={
                      selectedUser?.id === user?.id
                        ? { backgroundColor: "rgba(16, 156, 241, 0.19)" }
                        : {}
                    }
                    tabIndex={index}
                  >
                    <div className="chat-avatar position-relative">
                      <Avatar
                        alt={user?.firstName?.toUpperCase()}
                        src="/static/images/avatar/1.jpg"
                        sx={{ width: 52, height: 52 }}
                      />
                      <div hidden={!user?.isOnline || false} className={`position-absolute top-0 end-0 bg-happy-green rounded-3`} style={{width: '9px', height: '9px', maxWidth: '9px', maxHeight: '9px'}}>&nbsp;</div>
                    </div>
                    <div className="w-100">
                      <div className="people-name d-flex justify-content-between align-items-center">
                        <h6 className="mb-0">
                          {user?.firstName} {user?.lastName}
                        </h6>
                        <p>
                          {user?.lastChatMessageCreatedAt
                            ? moment(user?.lastChatMessageCreatedAt).format(
                                "hh:mm A"
                              )
                            : ""}
                        </p>
                      </div>
                      <div className="people-bottom-tex gap-2 d-flex justify-content-between align-items-center">
                        <p className="mb-0 w-100">
                          {" "}
                          {user?.lastChatMessage}
                          {/* Typing Something... */}
                        </p>
                        {user?.totalUnreadChatMessages !== 0 && (
                          <span className="message-count">
                            {user?.totalUnreadChatMessages}
                            {/* 18 */}
                          </span>
                        )}
                      </div>
                    </div>
                  </li>
                ))
              : "No Data"}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MessageSidebar;
