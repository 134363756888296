import React, {useState, useEffect, lazy} from "react";
import {connect, useSelector} from "react-redux";
import {useDispatch} from 'react-redux';
import Table from "../../../sharedComponents/table/Table";
import {getAllServiceRequest, getCountedVisits} from '../../../services/serviceRequest'
import {HttpsAction} from "../../../commonApiFunction/httpsAction";
import Swal from "sweetalert2";
import {Dialog} from "@mui/material";
import Pagination from "../../../sharedComponents/pagination";
import rowHelperFunctions from "../../../helpers/row.helpers";
import {useLocation, useNavigate, useOutletContext} from "react-router-dom";
import dateHelperFunctions from "../../../helpers/date.helpers";
import NewTableLayout from "../shared/NewTableLayout";
// import TaskDetails from "../TaskDetails";

const TaskDetails = lazy(() => import("../TaskDetails"));

const ScheduledRequest = ({/*searchText, */scheduledServiceRequestsList}) => {
    const backButtonTextSuffix = 'Scheduled Visits';
    const backLink = '/agency/servicerequest/scheduledrequest';

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { searchText, setPaginationTab } = useOutletContext();

    // const isServiceRequestEdit = useSelector(state => state.serviceRequest?.isServiceRequestEdit || false);

    // const [dialog, setDialog] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    // const [showTaskDetails, setShowTaskDetails] = useState(false);
    const [taskIdToEdit, setTaskIdToEdit] = useState(0);

    // const scheduleAssessmentList = useSelector(state => state.serviceRequest?.scheduleAssessment?.data?.rows)

    const taskIdToSelect = location?.state != null && location?.state?.taskId != null ? location.state.taskId : 0;

    const loadMethod = (curPage, toSearch) => {
        // const useDefault = curPage == null && toSearch == null;
        // if (useDefault) dispatch(getAllServiceRequest('schedulerequest', 1));
        // else {
        //     if (toSearch != null && toSearch.length > 0)
        //         dispatch(getAllServiceRequest('schedulerequest', 1, toSearch));
        //     else
        //         dispatch(getAllServiceRequest('schedulerequest', curPage));
        // }

        if (curPage !== currentPage) setCurrentPage(curPage);
        if (location?.state != null && 'serviceRequestId' in location.state) {
            if (toSearch != null && toSearch.length > 0)
                dispatch(getCountedVisits('schedulerequest', curPage, toSearch, location.state.serviceRequestId));
            else
                dispatch(getCountedVisits('schedulerequest', curPage, null, location.state.serviceRequestId));
        } else {
            if (toSearch != null && toSearch.length > 0)
                dispatch(getCountedVisits('schedulerequest', 1, toSearch));
            else
                dispatch(getCountedVisits('schedulerequest', curPage));
        }
    };

    const transformedArray = scheduledServiceRequestsList?.rows?.map(item => {
        // let jobRelatedLink = null;
        // let chartingRelatedLink = null;
        // const hasValidChartingId = item?.chartingId != null && item.chartingId > 0;
        // const hasValidJobId = item?.jobId != null && item.jobId > 0;
        // if (hasValidChartingId) {
        //     if (item?.requiresInitialAssessment) chartingRelatedLink = '/agency/servicerequest/initialAssessments';
        //     if (item?.requiresSupervisoryVisits) chartingRelatedLink = '/agency/servicerequest/supervisoryVisits';
        //     if (item?.requiresDischargeSummary) chartingRelatedLink = '/agency/servicerequest/dischargeSummaries';
        //     if (item?.requiresCarePlan) chartingRelatedLink = '/agency/servicerequest/carePlans';
        // }
        // if (hasValidJobId) {
        //     jobRelatedLink = '/agency/servicerequest/postedjobsinprogress';
        // }
        let { hasValidChartingId, relatedInfoList} = rowHelperFunctions.getRelatedInfo(item);

        const highlightRow = taskIdToSelect === item.taskId;

        return {
            'id': {taskId: item.taskId, nurseId: item.assignedNurse, serviceRequestId: item.serviceRequestId},
            'Recipients': rowHelperFunctions.getClientRowItem(navigate, item.patientId, item.patientFullName, false, backButtonTextSuffix, backLink, hasValidChartingId ? `/agency/clients/${item.patientId}/charting/0` : null),
            'age': item.patientAge,
            // 'serviceRequired': item.serviceRequestType,
            'serviceRequired': <div className="position-relative1 p-0 m-0">
                <span>{item.serviceRequestType}</span>
                {/*<div className="position-absolute top-22 start-50 translate-middle d-flex gap-2">
                    {hasValidJobId && jobRelatedText && <div className="cursor-pointer" onClick={() => {
                        navigate(jobRelatedLink, {
                            state: {
                                jobId: item?.jobId,
                            },
                        });
                    }}>
                        <span className={`badge rounded-pill text-wrap lh-1 text-bg-warning`}>{jobRelatedText}</span>
                    </div>}
                    {hasValidChartingId && chartingRelatedText && <div className="cursor-pointer" onClick={() => {
                        navigate(chartingRelatedLink, {
                            state: {
                                taskId: item?.id,
                            },
                        });
                    }}>
                        <span className={`badge rounded-pill text-wrap lh-1 text-bg-warning`}>{chartingRelatedText}</span>
                    </div>}
                </div>*/}
                {(relatedInfoList || []).length > 0 && <div className="position-absolute top-17 start-50 translate-middle cursor-pointer w-100 d-flex justify-content-center gap-1">{relatedInfoList.map(({relatedTextColor, relatedText, relatedLink, relatedState}, index) => <span key={index} onClick={() => {
                    if (relatedLink != null) navigate(relatedLink, { state: relatedState });
                }} className={`badge rounded-pill text-wrap lh-1 text-bg-${relatedTextColor}`}>{relatedText}</span>)}</div>}
            </div>,
            // 'serviceRequired':
            //   <div onClick={() => handleServiceDetailsModal(item.id)} style={{cursor: 'pointer'}}>
            //       {item.serviceRequestType}
            //   </div>,
            // 'duration': <><span
            //     className="d-block">{`${new Date(item.startDate).toLocaleDateString('en-GB')} - ${new Date(item.endDate).toLocaleDateString('en-GB')}`}</span><span
            //     className="d-block">{`${item.clockIn} - ${item.clockOut}`}</span></>,
            // 'duration': <><div className="d-block" style={{width: '85px'}}>{item.taskDate}</div><div className="d-block">{`${item.scheduledStartTime} - ${item.scheduledEndTime}`}</div></>,
            // 'duration': <><span
            //   className="d-block">{item.startDateEndDate}</span><span
            //   className="d-block">{item.expectedTimings}</span></>,
            // 'duration': <><div className="d-block" style={{width: '85px'}}>{item.taskDate}</div><div className="d-block">{`${item.scheduledStartTime} - ${item.scheduledEndTime}`}</div></>,
            // 'duration': <><span
            //   className="d-block">{item.taskDateFormatUs}</span><span
            //   className="d-block" style={{fontSize: '13px', minWidth: '125px'}}>{item.expectedTimings}</span></>,
            // 'duration': <span className="d-block" style={{fontSize: '12px', minWidth: '180px'}}>{item.expectedTimingsWithDate}</span>,
            'duration': <div className="d-flex flex-column gap-0" style={{minWidth: '125px'}}>
                <span className="d-block" style={{fontSize: '14px'}}>{item.taskDateFormatUs}</span>
                <span className="d-block" style={{fontSize: '12px'}}>{item.scheduledTimingsAmPm}</span>
            </div>,
            'hours': dateHelperFunctions.hoursAndMinutesToText(item.totalScheduledHours, item.totalScheduledMinutesLessHours),
            // 'frequencies': item.frequencies,
            'location': item.patientLocation || 'N/A',
            // 'nurseName': item?.assignedNurseFirstName || 'N/A',
            'nurseName': rowHelperFunctions.getSingleNurseRowItem(navigate, item?.assignedNurseId, item?.assignedNurseFullName, false, item?.assignedNurseIsFreelance, backButtonTextSuffix, backLink, hasValidChartingId ? `/agency/nurses/edit/${item?.assignedNurseId}/charting` : null),
            highlightRow,
            importantRow: item.isCurrentDayTask,
        }
    });

    const serviceHeaderData = [
        {
            title: "Recipients",
            dataIndex: "Recipients",
            // align: 'left',
            width: '200px',
        },
        {
            title: "Age",
            dataIndex: "age",
            width: '47px',
        },
        {
            title: "Service Required",
            dataIndex: "serviceRequired",
            width: '100px',
        },
        {
            title: "Date/Timings",
            dataIndex: "duration",
            width: '130px',
        },
        {
            title: "Hours",
            dataIndex: "hours",
            width: '75px',
        },
        // {
        //     title: "Frequency",
        //     dataIndex: "frequencies",
        //     render: (text) => {
        //         return text.map(item => {
        //             const firstThreeLetters = item.frequency.substring(0, 3);
        //             return firstThreeLetters;
        //         }).join(', ');
        //     }
        // },
        {
            title: "Location",
            dataIndex: "location",
        },
        {
            title: "Nurse",
            dataIndex: "nurseName",
            width: '200px',
        },
        // {
        //     title: "",
        //     dataIndex: "Action",
        // }
    ];

    const actionButton = [
        {
            actionName: 'Edit',
            buttonAction: (rowSpecificData) => {
                handleDialog(rowSpecificData.taskId);
            },
            actionClass: 'btn-primary',
            iconClass: 'fa-user-plus',
        },
        // {
        //     actionName: 'Cancel',
        //     buttonAction: (rowSpecificData) => Swal.fire({
        //         title: 'Are you sure?',
        //         text: "You won't be able to revert this!",
        //         icon: 'warning',
        //         showCancelButton: true,
        //         confirmButtonColor: '#3085d6',
        //         cancelButtonColor: '#d33',
        //         confirmButtonText: 'Yes, delete it!',
        //     }).then((result) => {
        //         if (result.isConfirmed)
        //             dispatch(HttpsAction({
        //                 method: 'PATCH',
        //                 apiSection: 'serviceRequests',
        //                 apiName: 'taskDetails',
        //                 queryParameter: `/${rowSpecificData.taskId}`,
        //                 positiveCallBack: () => {
        //                     dispatch(getCountedVisits('schedulerequest', currentPage));
        //                 }
        //             }))
        //     }),
        //     actionClass: 'btn-danger',
        //     iconClass: 'fa-user-plus',
        // },
        {
            actionName: 'Delete',
            buttonAction: (rowSpecificData) => {
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!',
                }).then((result) => {
                    if (result.isConfirmed) {
                        dispatch(HttpsAction({
                            method: 'DELETE',
                            apiSection: 'serviceRequests',
                            apiName: 'taskDetails',
                            queryParameter: `/${rowSpecificData.taskId}`,
                            positiveCallBack: () => {
                                dispatch(getCountedVisits('schedulerequest', currentPage));
                            }
                        }))
                    }
                });
            },
            actionClass: 'btn-danger',
            iconClass: 'fa-user-plus',
        },
    ];

    const handleDialog = (id) => {
        if (id != null && id > 0) {
            // dispatch(HttpsAction({
            //     apiSection: 'serviceRequests',
            //     apiName: 'taskDetails',
            //     queryParameter: `/${id}`,
            //     actionType: `GET_TASK_DETAILS`,
            //     reduxKeyName: 'taskDetails',
            //     reducerName: 'SERVICE_REQUEST',
            // }));
            setTaskIdToEdit(id);
        }
        // setDialog(() => id)
    };

    const closeViewDialog = () => {
        setTaskIdToEdit(0);
        dispatch(HttpsAction({
            actionType: `GET_TASK_DETAILS`,
            reduxKeyName: 'taskDetails',
            reducerName: 'SERVICE_REQUEST',
            removeData: true,
        }));
    };

    // useEffect(() => {
    //     if (location?.state != null && 'serviceRequestId' in location.state) {
    //         if (searchText != null && searchText.length > 0)
    //             dispatch(getCountedVisits('schedulerequest', currentPage, searchText, location.state.serviceRequestId));
    //         else
    //             dispatch(getCountedVisits('schedulerequest', currentPage, null, location.state.serviceRequestId));
    //     } else {
    //         if (searchText != null && searchText.length > 0)
    //             dispatch(getCountedVisits('schedulerequest', 1, searchText));
    //         else
    //             dispatch(getCountedVisits('schedulerequest', currentPage));
    //     }
    //
    // }, [currentPage, searchText, location?.state]);

    useEffect(() => {
        if (scheduledServiceRequestsList != null && scheduledServiceRequestsList?.rows?.length != null) {
            setPaginationTab(scheduledServiceRequestsList);
        }
    }, [scheduledServiceRequestsList]);

    // useEffect(() => {
    //     if (location?.state != null && 'serviceRequestId' in location.state) {
    //         dispatch(getCountedVisits('schedulerequest', currentPage, null, location.state.serviceRequestId));
    //     } else
    //         dispatch(getCountedVisits('schedulerequest', currentPage));
    // }, [currentPage, location?.state]);
    //
    // useEffect(() => {
    //     dispatch(getCountedVisits('schedulerequest', 1, searchText));
    // }, [searchText]);
    //
    // useEffect(() => {
    //     if (taskIdToEdit > 0) {
    //         setShowTaskDetails(true);
    //     }
    // }, [taskIdToEdit]);
    //
    // useEffect(() => {
    //     if (!showTaskDetails) {
    //         setTaskIdToEdit(0);
    //         dispatch(HttpsAction({
    //             actionType: `GET_TASK_DETAILS`,
    //             reduxKeyName: 'taskDetails',
    //             reducerName: 'SERVICE_REQUEST',
    //             removeData: true,
    //         }));
    //     }
    // }, [showTaskDetails]);

    return (
      <>
          {/*<div className="container-fluid bg-light">
              <div className="bg-light-gray px-3 py-3">
                  <div className="row">
                      <div className="container-fluid">
                          <div className="tab-content" id="pills-tabContent">
                              <div className="bg-white mt-3">
                                  <Table headers={serviceHeaderData} rows={transformedArray} actions={actionButton} hasFixedWidth={true} actionColWidth="75px" tableClass="visits-table" />
                              </div>
                          </div>
                          <Pagination
                            currentPage={scheduledServiceRequestsList?.page}
                            totalRecords={scheduledServiceRequestsList?.total}
                            limit={scheduledServiceRequestsList?.pageSize}
                            onPageChange={(page) => setCurrentPage(page)}
                          ></Pagination>
                      </div>
                  </div>
              </div>
          </div>*/}
          <NewTableLayout listData={scheduledServiceRequestsList} loadMethod={loadMethod} loadDeps={[searchText]}>
              <Table headers={serviceHeaderData} rows={transformedArray} actions={actionButton} hasFixedWidth={true} actionColWidth="75px" tableClass="visits-table" />
          </NewTableLayout>
          <Dialog
            open={taskIdToEdit > 0}
            onClose={() => closeViewDialog()}
            maxWidth={"lg"}
            aria-labelledby={"form-dialog-title"}>
              <div className={'p-4'}>
                  <TaskDetails showingDataFor="scheduledVisits" taskIdToEdit={taskIdToEdit} onClose={(taskModified) => {
                      closeViewDialog();
                      if (taskModified) {
                          // dispatch(getCountedVisits('schedulerequest', currentPage));
                          loadMethod();
                      }
                  }} />
              </div>
          </Dialog>
      </>
    );

};

const mapStateToProps = (state) => {
    const { serviceRequest } = state;
    return {
        scheduledServiceRequestsList: serviceRequest.scheduledRequests || {},
    };
};

export default connect(mapStateToProps)(ScheduledRequest);
