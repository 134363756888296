import React, {useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {change, Field, reduxForm} from "redux-form";
import CustomInputField from "../../../../../../../sharedComponents/reduxfields/customInputField";
import classes from "../../css/Healthcare.module.css"
import CustomDropDownField from "../../../../../../../sharedComponents/reduxfields/customDropDown";
import CustomTextareaField from "../../../../../../../sharedComponents/reduxfields/customTextareaField";
import PrimaryButton from "../../../../../../../sharedComponents/buttons/PrimaryButton";
import {HttpsAction} from "../../../../../../../commonApiFunction/httpsAction";
import CheckboxGroup from "../../../../../../../sharedComponents/reduxfields/customCheckboxGroup";
import MultiSelectDropdown from "../../../../../../../sharedComponents/multiSelect";
import {useParams} from "react-router-dom";
import dateHelperFunctions from "../../../../../../../helpers/date.helpers";
import stringHelperFunctions from "../../../../../../../helpers/string.helpers";
import TextField from "@mui/material/TextField";
import TextSuggestMulti from "./TextSuggestMulti";
import axios from "axios";
import CustomDropDownNewField from "../../../../../../../sharedComponents/reduxfields/customDropDownNew";
import {required, requiredArray} from "../../../../../../../helpers/validation";
import arrayHelperFunctions from "../../../../../../../helpers/array.helpers";
import numberHelperFunctions from "../../../../../../../helpers/number.helpers";
import MultiSelectDropdownNew from "../../../../../../../sharedComponents/reduxfields/MultiSelectDropdown";

// function getFormNamesForRoute(route) {
//     if (route === 'oral') return ['tab'];
//     return ['None'];
// }

function getRouteNameFromItemName(item) {
    const tempItemName = item;
    item = item.toLowerCase();
    if (item.indexOf('injection') > -1 || item.indexOf('injectable') > -1) return 'Intramuscular';
    if (item.indexOf('implant') > -1) return 'Implant';
    return tempItemName;
}

function getRouteNameFromMedicationAndDosageNames(routeNameInMedicationName, dosageName) {
    const tempRouteName = routeNameInMedicationName;
    routeNameInMedicationName = routeNameInMedicationName.toLowerCase();
    if (routeNameInMedicationName.indexOf('injection') > -1) {
        if (dosageName.indexOf('unt') > -1 || dosageName.indexOf('injection') > -1)
            return 'Intramuscular';
        return 'Injection';
    }
    if (routeNameInMedicationName.indexOf('injectable') > -1) {
        if (dosageName.indexOf('unt') > -1 || dosageName.indexOf('injection') > -1)
            return 'Intramuscular';
        return 'Injection';
    }
    if (routeNameInMedicationName.indexOf('implant') > -1) return 'Implant';
    if (routeNameInMedicationName.indexOf('inhalant') > -1) return 'Inhalational';
    return tempRouteName;
}


const NewMedication = ({handleSubmit, clientId, onClose}) => {

    // const {clientId} = useParams();
    const dispatch = useDispatch();

    const singleHealthcareMedication = useSelector(state => state.client?.singleHealthcareMedication);
    const medicationPhysician = useSelector(state => state.client?.medicationPhysician?.data);
    const weekDays = useSelector(state => state.client?.weekDays?.data);
    const dosageRouteTypes = useSelector(state => state.client?.dosageRouteTypes?.data);
    const scheduledTimeOptions = useSelector(state => state.client?.scheduledTimeOptions?.data);
    const scheduledDayOptions = useSelector(state => state.client?.scheduledDayOptions?.data);

    // const dosageRouteTypeNamesFromDb = dosageRouteTypes.map(r => ({...r, routeName: r.name.toLowerCase(), formNames: getFormNamesForRoute(r.name.toLowerCase())}));
    const dosageRouteTypeNamesFromDb = (dosageRouteTypes || []).map(r => ({...r, routeName: r.name.toLowerCase()}));

    const [scheduledDaysId, setScheduledDaysId] = useState('');
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [medicationDataReceived, setMedicationDataReceived] = useState({medicationNames: [], strengthsAndForms: []});
    const [dosageOptions, setDosageOptions] = useState([]);
    const [selectedMedication, setSelectedMedication] = useState('');
    const [allDosageRouteTypes, setAllDosageRouteTypes] = useState([]);
    const [selectedRouteId, setSelectedRouteId] = useState(0);
    const [newRouteOptionAdded, setNewRouteOptionAdded] = useState(null);
    const [selectedDosage, setSelectedDosage] = useState('');

    // console.log({scheduledDayOptions})

    function reload() {
        dispatch(HttpsAction({
            apiSection: 'client',
            apiName: 'healthcareMedication',
            queryParameter: `/${clientId}`,
            actionType: 'GET_HEALTHCARE_MEDICATION',
            reduxKeyName: 'healthcareMedication',
            reducerName: 'CLIENT',
        }));
    }

    const onSubmit = async (values) => {
        let selectedScheduleTime = [];
        const recordType = "new";

        // console.log('test- selectedOptions', selectedOptions)
        if (selectedOptions.length > 0) {
            selectedOptions.forEach(v => selectedScheduleTime.push(v.value));
            selectedScheduleTime = selectedScheduleTime.filter(arrayHelperFunctions.onlyUnique);
        }

        const payload = {
            recordType: stringHelperFunctions.nullIfEmpty(recordType),
            // medication: stringHelperFunctions.nullIfEmpty(values?.medication || ''),
            medication: stringHelperFunctions.nullIfEmpty(selectedMedication || ''),
            dosage: stringHelperFunctions.nullIfEmpty(values?.dosage || ''),
            routeId: numberHelperFunctions.nullIfEmpty(values?.routeId, true),
            startDate: stringHelperFunctions.nullIfEmpty(values?.startDate),
            endDate: stringHelperFunctions.nullIfEmpty(values?.endDate),
            physicianId: numberHelperFunctions.nullIfEmpty(values?.physician, true),
            purpose: stringHelperFunctions.nullIfEmpty(values?.purpose || ''),
            pharmacy: stringHelperFunctions.nullIfEmpty(values?.pharmacy || ''),
            specialInstruction: stringHelperFunctions.nullIfEmpty(values?.specialInstruction || ''),
            notes: stringHelperFunctions.nullIfEmpty(values?.notes || ''),
        }
        if (scheduledDaysId === "3") {
            payload[`schedule`] = {
                scheduledDaysOptionSelected: "frequency",
                scheduledDaysId: numberHelperFunctions.nullIfEmpty(scheduledDaysId, true),
                frequencyIds: arrayHelperFunctions.nullIfEmpty(values.specificDays),
                scheduledTimePeriodIds: arrayHelperFunctions.nullIfEmpty(selectedScheduleTime),
            }
        } else if (scheduledDaysId === "4") {
            payload[`schedule`] = {
                scheduledDaysOptionSelected: "iteration",
                scheduledDaysId: numberHelperFunctions.nullIfEmpty(scheduledDaysId, true),
                everyXDays: numberHelperFunctions.nullIfEmpty(values.everyXDays, true),
                scheduledTimePeriodIds: arrayHelperFunctions.nullIfEmpty(selectedScheduleTime),
            }
        } else {
            payload[`schedule`] = {
                scheduledDaysOptionSelected: "other",
                scheduledDaysId: numberHelperFunctions.nullIfEmpty(scheduledDaysId, true),
                scheduledTimePeriodIds: arrayHelperFunctions.nullIfEmpty(selectedScheduleTime),
            }
        }

        if (newRouteOptionAdded != null) {
            payload['newRouteOptionAdded'] = newRouteOptionAdded;
        }

        if (singleHealthcareMedication) {
            payload['recordType'] = 'existing';
            payload['id'] = singleHealthcareMedication.id;
        }

        // console.log({payload});
        dispatch(HttpsAction({
            method: 'PUT',
            apiSection: 'client',
            apiName: 'healthcareMedication',
            data: payload,
            queryParameter: `/${clientId}`,
            positiveCallBack: () => {
                onClose()
                reload();
            }
        }));
    }

    const getSelectedOptions = (selectedOptions) => {
        setSelectedOptions(selectedOptions);
    }

    const getMedicationsList = (search) => {
        const url = `https://clinicaltables.nlm.nih.gov/api/rxterms/v3/search?terms=${search}&sf=DISPLAY_NAME,STRENGTHS_AND_FORMS,RXCUIS,SXDG_RXCUI,DISPLAY_NAME_SYNONYM&ef=STRENGTHS_AND_FORMS&cf=RXCUIS&df=DISPLAY_NAME`;
        // const url = `https://clinicaltables.nlm.nih.gov/api/rxterms/v3/search?terms=${search}&sf=DISPLAY_NAME,STRENGTHS_AND_FORMS,RXCUIS,SXDG_RXCUI,DISPLAY_NAME_SYNONYM&ef=STRENGTHS_AND_FORMS&cf=DISPLAY_NAME&df=DISPLAY_NAME`;
        return axios.get(url)
            .then(response => {
                const multiList = response.data;
                // console.log('test- response data', multiList);
                // console.log('test- strengths-forms', multiList[2].STRENGTHS_AND_FORMS);
                const medicationNames = multiList[3].map(o => o[0].trim());
                const strengthsAndForms = multiList[2].STRENGTHS_AND_FORMS;
                // console.log('strengthsAndForms', strengthsAndForms);
                setMedicationDataReceived({medicationNames, strengthsAndForms});
                // if (multiList[2].length > 0) setDosageOptions(multiList[2].map(o => ({id: o, name: o})));
                return medicationNames;
            });
    }
    
    const onMedicationSelected = (item) => {
        setSelectedRouteId(0);
        setSelectedDosage('');
        setDosageOptions([]);
        setNewRouteOptionAdded(null);
// console.log('test- item', item)
        setSelectedMedication(item);
        if (item != null && item.length > 0) {
            let routeNameFromItemName = item.substring(item.indexOf('(') + 1, item.indexOf(')'));
            routeNameFromItemName = routeNameFromItemName.replace(' Pill', '');
            let routeName = getRouteNameFromItemName(routeNameFromItemName);

// console.log('test- ', item, routeName, medicationDataReceived);
            if (medicationDataReceived.medicationNames.includes(item)) {
                const foundIndex = medicationDataReceived.medicationNames.findIndex(o => o === item);
// console.log('test- foundIndex', foundIndex);
                if (foundIndex > -1 && medicationDataReceived.strengthsAndForms.length > 0) {
                    // console.log(`test- medicationDataReceived.strengthsAndForms[${foundIndex}]`, medicationDataReceived.strengthsAndForms[foundIndex]);
                    const dosagesList = medicationDataReceived.strengthsAndForms[foundIndex].map(o => ({id: o.trim(), name: o.trim()}));
                    setDosageOptions(dosagesList);
                    if (dosagesList.length === 1) {
                        const dosageName = dosagesList[0].id;
                        setSelectedDosage(dosageName);
                    }

// console.log('test- routeNameFromItemName', routeNameFromItemName, dosagesList, routeName, dosageRouteTypeNamesFromDb);
                    let foundRoute = false;
                    for (const dosageRouteType of dosageRouteTypeNamesFromDb) {
                        // if (item.toLowerCase().indexOf(dosageRouteType.routeName) > -1 && dosagesList.some(o => o.name.toLowerCase().indexOf(dosageRouteType.formName) > -1)) {
                        // if (item.toLowerCase().indexOf(dosageRouteType.routeName) > -1 || dosagesList.some(o => dosageRouteType.formNames.includes(o.name.toLowerCase()))) {
                        // console.log('test- ', routeName.toLowerCase().indexOf(dosageRouteType.routeName), routeName, dosageRouteType.routeName);
                        if (routeName.toLowerCase().indexOf(dosageRouteType.routeName) > -1) {
                            foundRoute = true;
                            setSelectedRouteId(dosageRouteType.id);
                            break;
                        }
                    }
                    if (!foundRoute) {
                        //     const newId = dosageRouteTypes.length + 1;
                        //     // const routeName = getRouteNameFromItemName(item.toLowerCase());
                        //     // setAllDosageRouteTypes([...dosageRouteTypes, {id: newId, name: routeName}]);
                        //     const routeName = get(routeNameFromItemName, dosageName);
                        //     setAllDosageRouteTypes([...dosageRouteTypes, {id: newId, name: routeName}]);
                        //     setSelectedRouteId(newId);

                        if (dosagesList.length === 1) {
                            const dosageName = dosagesList[0].id;
                            routeName = getRouteNameFromMedicationAndDosageNames(routeNameFromItemName, dosageName.toLowerCase());
                            const newId = dosageRouteTypes.length + 1;
                            setAllDosageRouteTypes([...dosageRouteTypes, {id: newId, name: routeName}]);
                            setSelectedRouteId(newId);
                            setNewRouteOptionAdded({id: newId, name: routeName});
                        }
                    }
                }
            }
        }
    }

    const handleDosageSelected = (target) => {
        let index = target.selectedIndex;
        let optionElement = target.childNodes[index];
        let dosageName = optionElement.getAttribute('value');

        if (selectedRouteId === 0) {
            const routeNameInMedicationName = selectedMedication.substring(selectedMedication.indexOf('(') + 1, selectedMedication.indexOf(')'));
            const routeName = getRouteNameFromMedicationAndDosageNames(routeNameInMedicationName, dosageName.toLowerCase());
            let foundRoute = false;
            for (const dosageRouteType of dosageRouteTypeNamesFromDb) {
                // if (item.toLowerCase().indexOf(dosageRouteType.routeName) > -1 && dosagesList.some(o => o.name.toLowerCase().indexOf(dosageRouteType.formName) > -1)) {
                // if (item.toLowerCase().indexOf(dosageRouteType.routeName) > -1 || dosagesList.some(o => dosageRouteType.formNames.includes(o.name.toLowerCase()))) {
                if (routeName.toLowerCase().indexOf(dosageRouteType.routeName) > -1) {
                    foundRoute = true;
                    setSelectedRouteId(dosageRouteType.id);
                    break;
                }
            }

            if (!foundRoute) {
                const newId = dosageRouteTypes.length + 1;
                setAllDosageRouteTypes([...dosageRouteTypes, {id: newId, name: routeName}]);
                setSelectedRouteId(newId);
                setNewRouteOptionAdded({id: newId, name: routeName});
            }
        }
    };

    useEffect(() => {
        dispatch(change('newMedicationForm', 'dosage', selectedDosage));
    }, [selectedDosage]);

    useEffect(() => {
        dispatch(change('newMedicationForm', 'routeId', String(selectedRouteId)));
    }, [selectedRouteId]);

    useEffect(() => {
        if (dosageRouteTypes != null && dosageRouteTypes.length > 0 && singleHealthcareMedication != null) {
            if (allDosageRouteTypes.length === 0) setAllDosageRouteTypes(dosageRouteTypes);
            dispatch(change('newMedicationForm', 'routeId', String(singleHealthcareMedication.routeId)));

            if (singleHealthcareMedication.medication != null && singleHealthcareMedication.medication.length > 0) {
                /// IIFE
                (async () => {
                    setSelectedMedication(singleHealthcareMedication.medication);
                    const medicationName = singleHealthcareMedication.medication.substring(0, singleHealthcareMedication.medication.indexOf('(')).trim();
                    await getMedicationsList(medicationName);
                })();
            }
        }
    }, [singleHealthcareMedication, dosageRouteTypes]);

    useEffect(() => {
        if (medicationDataReceived != null && medicationDataReceived.strengthsAndForms != null && medicationDataReceived.strengthsAndForms.length > 0 && singleHealthcareMedication != null) {
            const dosagesList = medicationDataReceived.strengthsAndForms[0].map(o => ({id: o.trim(), name: o.trim()}));
            setDosageOptions(dosagesList);
        }
    }, [singleHealthcareMedication, medicationDataReceived]);

    useEffect(() => {
        if (dosageOptions != null && dosageOptions.length > 0 && singleHealthcareMedication != null) {
            dispatch(change('newMedicationForm', 'dosage', singleHealthcareMedication.dosage));
        }
    }, [singleHealthcareMedication, dosageOptions]);

    useEffect(() => {
        if (dosageRouteTypes != null && dosageRouteTypes.length > 0) {
            setAllDosageRouteTypes(dosageRouteTypes);
        }
    }, [dosageRouteTypes]);

    useEffect(() => {
        dispatch(HttpsAction({
            apiSection: 'combos',
            apiName: 'physiciansList',
            reducerName: 'CLIENT',
            reduxKeyName: 'medicationPhysician',
            actionType: 'GET_PHYSICIAN'
        }))

        dispatch(HttpsAction({
            apiSection: 'client',
            apiName: 'weekDays',
            reducerName: 'CLIENT',
            reduxKeyName: 'weekDays',
            actionType: 'GET_WEEK_DAYS'
        }))
        dispatch(HttpsAction({
            apiSection: 'client',
            apiName: 'dosageRouteTypes',
            reducerName: 'CLIENT',
            reduxKeyName: 'dosageRouteTypes',
            actionType: 'GET_DOSAGE_ROUTE_TYPES'
        }))
        dispatch(HttpsAction({
            apiSection: 'client',
            apiName: 'scheduledTimeOptions',
            reducerName: 'CLIENT',
            reduxKeyName: 'scheduledTimeOptions',
            actionType: 'GET_SCHEDULE_TIME_OPTIONS'
        }))
        dispatch(HttpsAction({
            apiSection: 'client',
            apiName: 'scheduledDayOptions',
            reducerName: 'CLIENT',
            reduxKeyName: 'scheduledDayOptions',
            actionType: 'GET_SCHEDULE_DAYS_OPTIONS'
        }))
        return () =>
            dispatch({
                type: `ADD_CLIENT_REMOVE_MEDICATION_DATA`,
                payload: '',
                name: `singleHealthcareMedication`,
                meta: {type: `REMOVE_MEDICATION_DATA`}
            })

    }, [])

    useEffect(() => {
        if (singleHealthcareMedication != null) {
            setScheduledDaysId(singleHealthcareMedication?.scheduledDaysId?.toString() || '');
            // const specificDays = (singleHealthcareMedication?.medicationFrequencies || []).map(f => ({id: f.frequencyId, name: f.frequency}));
            // if (specificDays.length > 0) dispatch(change('newMedicationForm', 'specificDays', specificDays));
        }
    }, [singleHealthcareMedication]);

    useEffect(() => {
        if (singleHealthcareMedication?.medicationTimePeriods?.length) {
            const list = singleHealthcareMedication?.medicationTimePeriods?.map((item) => ({
                // ...item,
                value: item?.medicationTimePeriodId,
                label: item?.medicationTimePeriod,
            }));
            const result = Object.values(
              list.reduce((acc, obj) => ({ ...acc, [obj.value]: obj }), {})
            );
            // console.log('test- result', result, list);
            setSelectedOptions(result);
        }
    }, [singleHealthcareMedication?.medicationTimePeriods]);
    // console.log({selectedOptions})

    // useEffect(() => {
    //     if (selectedMedication.length > 0) {
    //
    //     }
    // }, [selectedMedication]);

    return (
        <>
            <div className="app-main__inner service-request-inner">
                <div className="tab-content" id="myTabContent">
                    <div className={classes.modalHeader}>
                        New Medication
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <div className="col-4">
                                <div className="mb-4x mt-4 input-field-main">
                                    <Field
                                        className="form-control mk-wi"
                                        name="medication"
                                        label="Medication"
                                        component={TextSuggestMulti}
                                        getList={getMedicationsList}
                                        onSelected={onMedicationSelected}
                                        requiredMark={true}
                                        validate={required}
                                    />
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="mb-3 input-field-main">
                                    <Field
                                        className="form-control mk-wi"
                                        name="dosage"
                                        label="Dosage"
                                        // type="text"
                                        component={CustomDropDownNewField}
                                        requiredMark={true}
                                        validate={required}
                                        onChange={({ target }) => handleDosageSelected(target)}
                                    >
                                        <option value="">Please Select</option>
                                        {(dosageOptions || []).map((res, index) =>
                                          <option key={index} value={res.id}>{res.name}</option>
                                        )}
                                    </Field>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="mb-3 input-field-main">
                                    <Field
                                        className="form-control mk-wi"
                                        name="routeId"
                                        label="Route"
                                        component={CustomDropDownNewField}
                                        requiredMark={true}
                                        validate={required}
                                    >
                                        <option value="">Please Select</option>
                                        {(allDosageRouteTypes || []).map((res, index) =>
                                            <option key={index} value={res.id}>{res.name}</option>
                                        )}
                                    </Field>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="mb-3 input-field-main">
                                    <Field
                                        className="form-control mk-wi"
                                        name="scheduledDaysId"
                                        label="Scheduled Days"
                                        component={CustomDropDownField}
                                        requiredMark={true}
                                        validate={required}
                                        onChange={({target}) => setScheduledDaysId(() => target.value)}>
                                        <option value="">Please Select</option>
                                        {(scheduledDayOptions || []).map((res, index) =>
                                            <option key={index} value={res.id}>{res.name}</option>
                                        )}
                                    </Field>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="mb-3 input-field-main">
                                    <MultiSelectDropdown
                                        dpOptions={scheduledTimeOptions}
                                        getSelectedOptions={getSelectedOptions}
                                        selectedLanguages={selectedOptions}
                                        label="Scheduled Time"
                                        requiredMark={true}
                                        validate={required}
                                    />
                                    {/*<Field
                                      label="Scheduled Time"
                                      name="selectedScheduleTimePeriodIds"
                                      optionsList={scheduledTimeOptions}
                                      component={MultiSelectDropdownNew}
                                      requiredMark={true}
                                      validate={requiredArray}
                                    />*/}
                                </div>
                            </div>
                            {scheduledDaysId === "3" && <div className={'col-12'}>
                                <div className="mb-3 w-100 clearfix">
                                    <div className="col-12 col-sm-12">
                                        <CheckboxGroup
                                            label={'Specific days'}
                                            buttonCheckBox={true}
                                            name="specificDays"
                                            options={weekDays || []}
                                            requiredMark={scheduledDaysId === "3"}
                                            validate={scheduledDaysId === "3" ? required : undefined}
                                        />
                                    </div>
                                </div>
                            </div>}
                            {scheduledDaysId === "4" && <div className={'col-12'}>
                                <div className="col-2">
                                    <div className="mb-3 input-field-main">
                                        <Field
                                            className="form-control mk-wi"
                                            name="everyXDays"
                                            label="Every X days"
                                            type="number"
                                            component={CustomInputField}
                                            requiredMark={scheduledDaysId === "4"}
                                            validate={scheduledDaysId === "4" ? required : undefined}
                                        />
                                    </div>
                                </div>
                            </div>}
                            <div className="col-6">
                                <div className="mb-3 input-field-main">
                                    <Field
                                        className="form-control mk-wi"
                                        name="startDate"
                                        label="Start date"
                                        type="date"
                                        component={CustomInputField}
                                        requiredMark={true}
                                        validate={required}
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="mb-3 input-field-main">
                                    <Field
                                        className="form-control mk-wi"
                                        name="endDate"
                                        label="End date"
                                        type="date"
                                        component={CustomInputField}
                                        requiredMark={true}
                                        validate={required}
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="mb-3 input-field-main">
                                    <Field
                                        className="form-control mk-wi"
                                        name="physician"
                                        label="Physician"
                                        component={CustomDropDownField}
                                    >
                                        <option value="">Please Select</option>
                                        {(medicationPhysician || [])?.map((res, index) =>
                                            <option key={index} value={res?.id}>
                                                {`${res?.nurseFirstLastName}`}
                                            </option>
                                        )}
                                    </Field>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="mb-3 input-field-main">
                                    <Field
                                        className="form-control mk-wi"
                                        name="purpose"
                                        label="Purpose"
                                        type="text"
                                        component={CustomInputField}
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="mb-3 input-field-main">
                                    <Field
                                        className="form-control mk-wi"
                                        name="pharmacy"
                                        label="Pharmacy"
                                        type="text"
                                        component={CustomInputField}
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="mb-3 input-field-main">
                                    <Field
                                        className="form-control mk-wi"
                                        name="specialInstruction"
                                        label="Special instruction"
                                        type="text"
                                        component={CustomInputField}
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-3 input-field-main">
                                    <Field
                                        className="form-control mk-wi"
                                        name="notes"
                                        label="Notes"
                                        type="text"
                                        component={CustomTextareaField}
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            className="d-flex flex-row justify-content-center gap-3"
                            style={{marginTop: "20px"}}
                        >
                            <PrimaryButton
                                type="submit"
                                className="btn-view-repo gap-2 px-3 py-2"
                                text={"Save"}
                                onClick={() => undefined}
                            />
                            <PrimaryButton
                                type="button"
                                className="btn btn-primary border-btn-info px-3"
                                text={"Cancel"}
                                onClick={() => onClose()}
                            />
                        </div>
                    </form>
                </div>
            </div>

        </>
    )
};

const mapStateToProps = state => {
    const data = state.client?.singleHealthcareMedication;
    // console.log({data})
    return {
        initialValues: {
            medication: data?.medication,
            dosage: data?.dosage,
            routeId: data?.routeId?.toString(),
            scheduledDaysId: data?.scheduledDaysId?.toString() || '',
            startDate: dateHelperFunctions.updateDateFormat(data?.startDate),
            endDate: dateHelperFunctions.updateDateFormat(data?.endDate),
            physician: data?.physicianId?.toString(),
            purpose: data?.purpose,
            pharmacy: data?.pharmacy,
            specialInstruction: data?.specialInstruction,
            notes: data?.notes,
            everyXDays: data?.everyXDays?.toString() || '',
            // specificDays: (data?.medicationFrequencies || []).map(f => ({id: f.frequencyId, name: f.frequency})),
            specificDays: (data?.medicationFrequencies || []).map(f => f.frequencyId),
        }
    }
}

export default connect(mapStateToProps)(
    reduxForm({form: "newMedicationForm", enableReinitialize: true})(NewMedication)
);
