import React, {useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from "react-redux";
import WalletBalanceComponent from "../shared/WalletBalance";
import AddFundsComponent from "../shared/AddFunds";
import PayoutHistoryTable from "./PayoutHistory/PayoutHistoryTable";
import DashboardCalendar from "./DashboardCal";
import AllCards from "./AllCards";
import {
  useAdminsList,
  useAnimalTypesList,
  useCertificateTypesList,
  useDayFrequenciesList, useEduCoursesList, useEduDegreesList, useEmploymentTypesList,
  useEvaluationTypesList,
  useFormTemplatesList, useFormTemplateTypesList, useFreelanceNursesListForAgency,
  useHomeHealthAgenciesList,
  useHospiceAgenciesList,
  useHospitalsList, useInternalNursesListForAgency, useMaritalStatusList,
  useNursesListForAgency,
  usePatientsListForAgency,
  usePatientsListForAgencyWithLatLong,
  usePersonsList, usePhoneTypesList,
  usePhysiciansList,
  usePhysicianTypesList,
  useRegionsList, useRelationshipsList, useReminderDaysList,
  useServiceRequestTasksList,
  useServiceRequestTypesList, useShiftTimingsList, useTriageLevelsList, useWorkExperienceYearsList
} from "../../../services/commonNew";
import {useClientsList} from "../../../queries/agency/clientsList";
import {HttpsAction} from "../../../commonApiFunction/httpsAction";

const AgencyDashboardComponent = ( { agencyId }) => {

  // dynamic data
  const patientsListQuery = usePatientsListForAgency(agencyId);
  const patientsListWithLatLngQuery = usePatientsListForAgencyWithLatLong(agencyId);
  const nursesListQuery = useNursesListForAgency(agencyId);
  const internalNursesListQuery = useInternalNursesListForAgency(agencyId);
  const freelanceNursesListQuery = useFreelanceNursesListForAgency(agencyId);
  const regionsListQuery = useRegionsList(agencyId);
  const adminsListQuery = useAdminsList(agencyId);
  const personsListQuery = usePersonsList(agencyId);
  const physiciansListQuery = usePhysiciansList(agencyId);
  const hospitalsListQuery = useHospitalsList(agencyId);
  const homeHealthAgenciesListQuery = useHomeHealthAgenciesList(agencyId);
  const hospiceAgenciesListQuery = useHospiceAgenciesList(agencyId);
  const iaFormTemplatesListQuery = useFormTemplatesList(agencyId, 'initialAssessment');
  const dsFormTemplatesListQuery = useFormTemplatesList(agencyId, 'dischargeSummary');
  const svFormTemplatesListQuery = useFormTemplatesList(agencyId, 'supervisoryVisit');
  const cpFormTemplatesListQuery = useFormTemplatesList(agencyId, 'carePlan');

  // static data - loaded ONCE from api server
  const serviceRequestTypesQuery = useServiceRequestTypesList(agencyId);
  const tasksListQuery = useServiceRequestTasksList(agencyId);
  const certificateTypesQuery = useCertificateTypesList(agencyId);
  const evaluationTypesQuery = useEvaluationTypesList(agencyId);
  const physicianTypesListQuery = usePhysicianTypesList(agencyId);

  const dayFrequenciesListQuery = useDayFrequenciesList();
  const animalTypesListQuery = useAnimalTypesList();
  const maritalStatusListQuery = useMaritalStatusList();
  const degreesListQuery = useEduDegreesList();
  const coursesListQuery = useEduCoursesList();
  const phoneTypesQuery = usePhoneTypesList();
  const relationshipsQuery = useRelationshipsList();
  const reminderDaysQuery = useReminderDaysList();
  const formTemplateTypesQuery = useFormTemplateTypesList();
  const triageLevelsListQuery = useTriageLevelsList();
  const employmentTypesListQuery = useEmploymentTypesList();
  const workExperienceYearsListQuery = useWorkExperienceYearsList();
  const shiftTimingsListQuery = useShiftTimingsList();

  // preloading
  useClientsList(agencyId);

  const dispatch = useDispatch();

  const agencyDashboardData = useSelector((state) => state.agencyProfile?.agencyDashboardData ?? {});

  const [reloadBalance, setReloadBalance] = useState(false);

  useEffect(() => {
    // load all static data from API calls only ONCE
    if (agencyId != null && agencyId > 0) {
      /// IIFE
      (async () => {
        (await certificateTypesQuery).refetch();
        (await evaluationTypesQuery).refetch();
        (await iaFormTemplatesListQuery).refetch();
        (await dsFormTemplatesListQuery).refetch();
        (await svFormTemplatesListQuery).refetch();
        (await cpFormTemplatesListQuery).refetch();
        (await physicianTypesListQuery).refetch();

        (await dayFrequenciesListQuery).refetch();
        (await animalTypesListQuery).refetch();
        (await maritalStatusListQuery).refetch();
        (await degreesListQuery).refetch();
        (await coursesListQuery).refetch();
        (await serviceRequestTypesQuery).refetch();
        (await tasksListQuery).refetch();
        (await phoneTypesQuery).refetch();
        (await relationshipsQuery).refetch();
        (await reminderDaysQuery).refetch();
        (await formTemplateTypesQuery).refetch();
        (await triageLevelsListQuery).refetch();
        (await employmentTypesListQuery).refetch();
        (await workExperienceYearsListQuery).refetch();
        (await shiftTimingsListQuery).refetch();
      })();

      dispatch(HttpsAction({
        apiSection: 'agencyProfile',
        apiName: 'agencyDashboardData',
        reducerName: 'AGENCY_PROFILE',
        reduxKeyName: 'agencyDashboardData',
        actionType: 'DASHBOARD_DATA',
        newDispatchType: true,
      }));
    }
  }, []);

  return (
    <>
      <div className="app-main__outer">
        <div className="app-main__inner">
          <div className="main-dashboard-page">
            <div className="row mb-5">
              <div className="col-12 page-heading pb-3">
                <h5>Dashboard</h5>
              </div>
              <div className="col-12 col-sm-12 mb-4">
                <AllCards agencyDashboardData={agencyDashboardData}/>
                <DashboardCalendar/>
                <div className="card card-n rounded-lg mt-4 py-4 px-3  border-0">
                  <div className="py-history-content d-flex align-items-center gap-3 gap-lg-0 justify-content-between my-4 flex-wrap">
                    <WalletBalanceComponent reloadBalance={reloadBalance} setReloadBalance={setReloadBalance} />
                    <AddFundsComponent setReloadBalance={setReloadBalance} />
                  </div>
                  <PayoutHistoryTable/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { auth } = state;

  return {
    agencyId: auth?.authData?.agencyId || auth.agencyId,
  };
};

export default connect(mapStateToProps)(AgencyDashboardComponent);
