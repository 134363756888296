import React from "react";
import dayGridPlugin from "@fullcalendar/daygrid";
import FullCalendar from "@fullcalendar/react";

const Calendar = ({events = [], eventContent = () => undefined, handleMonth = () => undefined}) => {

    const handleDateSet = (dateInfo) => {
        const currentMonth = dateInfo.start.getMonth(); // Months are zero-based. So January is 0, and December is 11.
        const currentYear = dateInfo.start.getFullYear();
        console.log({currentMonth: currentMonth+1})
        handleMonth(currentMonth + 1, currentMonth === 11 ? currentYear + 1 : currentYear)
    };

    return (
        <>
            <FullCalendar
                plugins={[dayGridPlugin]}
                initialView="dayGridMonth"
                headerToolbar={{
                    start: "prev,next",
                    center: "title",
                    end: "",
                }}
                events={events}
                eventContent={eventContent}
                datesSet={handleDateSet} // Add this callback
            />
        </>
    )
};
export default Calendar;