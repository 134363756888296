import React, {useEffect, useState} from "react";

// import AdminTabs from "./components/Dashboard/Tabs";
import {
  useAnimalTypesList,
  useDayFrequenciesList,
  useEduCoursesList,
  useEduDegreesList, useEmploymentTypesList, useFormTemplateTypesList,
  useMaritalStatusList,
  usePhoneTypesList,
  useRelationshipsList, useReminderDaysList, useShiftTimingsList, useTriageLevelsList, useWorkExperienceYearsList
} from "../services/commonNew";
import AdminNurses from "./AdminNurses";
import DashboardTabs from "./DashboardTabs";
import {isLoggedIn, redirectOrLogout} from "../helpers/apiMethods";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

const AdminDashboard = () => {
  const navigate = useNavigate();
  // const dispatch = useDispatch();

  const dayFrequenciesListQuery = useDayFrequenciesList();
  const animalTypesListQuery = useAnimalTypesList();
  const maritalStatusListQuery = useMaritalStatusList();
  const degreesListQuery = useEduDegreesList();
  const coursesListQuery = useEduCoursesList();
  const phoneTypesQuery = usePhoneTypesList();
  const relationshipsQuery = useRelationshipsList();
  const reminderDaysQuery = useReminderDaysList();
  const formTemplateTypesQuery = useFormTemplateTypesList();
  const triageLevelsListQuery = useTriageLevelsList();
  const employmentTypesListQuery = useEmploymentTypesList();
  const workExperienceYearsListQuery = useWorkExperienceYearsList();
  const shiftTimingsListQuery = useShiftTimingsList();

  // const [key, setKey] = useState('');

  useEffect(() => {
    // if (!isLoggedIn()) {
    //   redirectOrLogout(dispatch, navigate);
    // } else {
      /// IIFE
      (async () => {
        // (await serviceRequestTypesQuery).refetch();
        // (await tasksListQuery).refetch();

        (await dayFrequenciesListQuery).refetch();
        (await animalTypesListQuery).refetch();
        (await maritalStatusListQuery).refetch();
        (await degreesListQuery).refetch();
        (await coursesListQuery).refetch();
        (await phoneTypesQuery).refetch();
        (await relationshipsQuery).refetch();
        (await reminderDaysQuery).refetch();
        (await formTemplateTypesQuery).refetch();
        (await triageLevelsListQuery).refetch();
        (await employmentTypesListQuery).refetch();
        (await workExperienceYearsListQuery).refetch();
        (await shiftTimingsListQuery).refetch();
      })();
    // }

    // setKey('nurses');
    navigate('/admin/list/nurses');
  }, []);

  // return (
  //   <div className="app-main__inner" style={{backgroundColor: "#fff"}}>
  //     <div className="main-dashboard-page">
  //       <div className="row mb-15">
  //         <div className="col-12 page-heading pb-3">
  //           <h5>Overview</h5>
  //         </div>
  //         <div className="container">
  //           <DashboardTabs currentKey={key} />
  //           <div className="tab-content mb-5">
  //             <div role="tabpanel" id="controlled-tabs-tabpane-nurses" aria-labelledby="controlled-tabs-tab-nurses" className="fade tab-pane active show">
  //               <AdminNurses />
  //             </div>
  //             <div role="tabpanel" id="controlled-tabs-tabpane-agencies" aria-labelledby="controlled-tabs-tab-agencies" className="fade tab-pane"></div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );

  return null;
};

export default AdminDashboard;
