import * as commonTypes from "../helpers/commonActionTypes";
import getApiUrl from "../helpers/apiURLs.js";
import api from "../helpers/interceptor";
import { toastr } from "react-redux-toastr";
import {GET_HEALTH_AGENCIES_LIST, GET_HOSPICE_AGENCIES_LIST, GET_HOSPITALS_LIST} from "../helpers/commonActionTypes";
import {defaultErrorMessage} from "../appConstants";

export const setLoader = (flag) => ({
  type: commonTypes.SET_LOADER,
  loading: flag,
});
export const getData = (value, type) => ({
  type: commonTypes[type],
  payload: value,
});

export const getStateList = (countryId = '233') => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = getApiUrl("commonAPI", "state");
    api
      .get(`${url}/${countryId}`)
      .then((res) => {
        dispatch({ type: commonTypes.GET_STATE_LIST, stateList: res.data });
        dispatch(setLoader(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoader(false));
        toastr.error(defaultErrorMessage, "");
      });
  };
};

export const getProfessionList = () => {
  return async (dispatch) => {
    dispatch(setLoader(true));

    const url = getApiUrl("findNurse", "professionList");
    console.log("professionList url", url);
    api
      .get(`${url}`)
      .then((res) => {
        dispatch({
          type: commonTypes.GET_PROFESSION_LIST,
          payload: res.data.filter(item => item.id > 1),
        });
        dispatch(setLoader(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoader(false));
        toastr.error(defaultErrorMessage, "");
      });
  };
};

export const getWorkExperienceList = () => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = getApiUrl("findNurse", "workExperience");
    api
      .get(`${url}`)
      .then((res) => {
        dispatch({
          type: commonTypes.GET_WORK_EXPERIENCE_LIST,
          payload: res.data,
        });
        dispatch(setLoader(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoader(false));
        toastr.error(defaultErrorMessage, "");
      });
  };
};

export const getPayRateTypeList = () => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = getApiUrl("findNurse", "payRateType");
    api
      .get(`${url}`)
      .then((res) => {
        dispatch({
          type: commonTypes.GET_PAY_RATE_TYPE_LIST,
          payload: res.data,
        });
        dispatch(setLoader(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoader(false));
        toastr.error(defaultErrorMessage, "");
      });
  };
};

export const getSpecialtiesList = (classId) => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = `${getApiUrl("commonAPI", "specialties")}/${classId}`;
    api
      .get(`${url}`)
      .then((res) => {
        dispatch({
          type: commonTypes.GET_SPECIALTIES_LIST,
          payload: res.data,
        });
        dispatch(setLoader(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoader(false));
        toastr.error(defaultErrorMessage, "");
      });
  };
};

export const getEmploymentTypesList = () => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = getApiUrl("commonAPI", "employmentTypes");
    api
      .get(`${url}`)
      .then((res) => {
        dispatch({
          type: commonTypes.GET_EMPLOYMENT_TYPE_LIST,
          payload: res.data,
        });
        dispatch(setLoader(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoader(false));
        toastr.error(defaultErrorMessage, "");
      });
  };
};

export const getPreferredShiftTimingsList = () => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = getApiUrl("commonAPI", "preferredShiftTimings");
    api
      .get(`${url}`)
      .then((res) => {
        dispatch({
          type: commonTypes.GET_PREFERRED_SHIFT_TIMINGS_LIST,
          payload: res.data,
        });
        dispatch(setLoader(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoader(false));
        toastr.error(defaultErrorMessage, "");
      });
  };
};

export const getLanguagesList = () => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = getApiUrl("commonAPI", "language");
    api
      .get(`${url}`)
      .then((res) => {
        dispatch({
          type: commonTypes.GET_LANGUAGES_LIST,
          payload: res.data,
        });
        dispatch(setLoader(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoader(false));
        toastr.error(defaultErrorMessage, "");
      });
  };
};

export const getCityList = (stateId) => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = `${getApiUrl("commonAPI", "city")}/${stateId}`;
    try {
      const response = await api.get(url);
      dispatch(setLoader(false));
      return response;
      // api.get(url).then((res) => {
      //   dispatch({
      //     type: commonTypes.GET_CITIES_LIST,
      //     payload: res.data,
      //   });
      //   dispatch(setLoader(false));
      // });
    } catch (err) {
      dispatch(setLoader(false));
      toastr.error(defaultErrorMessage, "");
    }
  };
};

export const getGendersList = () => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = getApiUrl("combos", "gendersList");
    api
      .get(`${url}`)
      .then((res) => {
        dispatch({ type: commonTypes.GET_GENDER_LIST, payload: res.data });
        dispatch(setLoader(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoader(false));
        toastr.error(defaultErrorMessage, "");
      });
  };
};

export const getAllPersonsList = () => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = getApiUrl("commonAPI", "allPersons");
    api
      .get(`${url}`)
      .then((res) => {
        dispatch({ type: commonTypes.GET_ALL_PERSONS_LIST, payload: res.data });
        dispatch(setLoader(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoader(false));
        toastr.error(defaultErrorMessage, "");
      });
  };
};

export const getRegionsList = () => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = getApiUrl("commonAPI", "regions");
    api
      .get(`${url}`)
      .then((res) => {
        dispatch({ type: commonTypes.GET_REGIONS_LIST, payload: res.data });
        dispatch(setLoader(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoader(false));
        toastr.error(defaultErrorMessage, "");
      });
  };
};

export const getAdminsList = () => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = getApiUrl("commonAPI", "adminsList");
    api
      .get(`${url}`)
      .then((res) => {
        dispatch({ type: commonTypes.GET_ADMINS_LIST, payload: res.data });
        dispatch(setLoader(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoader(false));
        toastr.error(defaultErrorMessage, "");
      });
  };
};

export const getEducationalSubjectsList = () => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = getApiUrl("commonAPI", "educationalSubjectsList");
    api
      .get(`${url}`)
      .then((res) => {
        dispatch({ type: commonTypes.GET_EDUCATIONAL_SUBJECTS_LIST, payload: res.data });
        dispatch(setLoader(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoader(false));
        toastr.error(defaultErrorMessage, "");
      });
  };
};

export const getDegreesList = () => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = getApiUrl("commonAPI", "degreesList");
    api
      .get(`${url}`)
      .then((res) => {
        dispatch({ type: commonTypes.GET_DEGREES_LIST, payload: res.data });
        dispatch(setLoader(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoader(false));
        toastr.error(defaultErrorMessage, "");
      });
  };
};

export const getCommonListing = (payload) => {
  // debugger
  return async (dispatch) => {
    dispatch(setLoader(true));
    let url = "";
    switch (payload) {
      case "language":
        url = `${getApiUrl("commonAPI", payload)}`;
        break;
      case "ethnicity":
        url = `${getApiUrl("commonAPI", payload)}`;
        break;
      default:
        break;
    }
    try {
      const response = await api.get(url);
      dispatch(setLoader(false));
      return response;
    } catch (err) {
      dispatch(setLoader(false));
      toastr.error(defaultErrorMessage, "");
    }
  };
};

export const getCountriesList = () => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = getApiUrl("commonAPI", "countriesList");
    api
      .get(`${url}`)
      .then((res) => {
        dispatch({ type: commonTypes.GET_COUNTRIES_LIST, payload: res.data });
        dispatch(setLoader(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoader(false));
        toastr.error(defaultErrorMessage, "");
      });
  };
};

export const getPhoneTypesList = () => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = getApiUrl("commonAPI", "phoneTypeList");
    api
      .get(`${url}`)
      .then((res) => {
        dispatch({ type: commonTypes.GET_PHONE_TYPE_LIST, payload: res.data });
        dispatch(setLoader(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoader(false));
        toastr.error(defaultErrorMessage, "");
      });
  };
};

export const getRelationshipsList = () => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = getApiUrl("commonAPI", "relationshipsList");
    api
      .get(`${url}`)
      .then((res) => {
        dispatch({ type: commonTypes.GET_RELATIONS_LIST, payload: res.data });
        dispatch(setLoader(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoader(false));
        toastr.error(defaultErrorMessage, "");
      });
  };
};

export const getReminderDaysList = () => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = getApiUrl("commonAPI", "reminderDaysList");
    api
      .get(`${url}`)
      .then((res) => {
        dispatch({ type: commonTypes.GET_REMINDER_DAYS_LIST, payload: res.data });
        dispatch(setLoader(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoader(false));
        toastr.error(defaultErrorMessage, "");
      });
  };
};

export const getCertificateTypesList = () => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = getApiUrl("commonAPI", "certificateTypesList");
    api
      .get(`${url}`)
      .then((res) => {
        dispatch({ type: commonTypes.GET_CERTIFICATE_TYPES_LIST, payload: res.data });
        dispatch(setLoader(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoader(false));
        toastr.error(defaultErrorMessage, "");
      });
  };
};

export const getEvaluationTypesList = () => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = getApiUrl("commonAPI", "evaluationTypesList");
    api
      .get(`${url}`)
      .then((res) => {
        dispatch({ type: commonTypes.GET_EVALUATION_TYPES_LIST, payload: res.data });
        dispatch(setLoader(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoader(false));
        toastr.error(defaultErrorMessage, "");
      });
  };
};

export const getEthnicitiesList = () => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = getApiUrl("commonAPI", "ethnicity");
    api
      .get(`${url}`)
      .then((res) => {
        dispatch({ type: commonTypes.GET_ETHNICITIES_LIST, payload: res.data });
        dispatch(setLoader(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoader(false));
        toastr.error(defaultErrorMessage, "");
      });
  };
};

export const getPatientsList = () => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = getApiUrl("combos", "clientsList");
    api
      .get(`${url}`)
      .then((res) => {
        dispatch({type: commonTypes.GET_PATIENTS_LIST, payload: res.data});
        dispatch(setLoader(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoader(false));
        toastr.error(defaultErrorMessage, "");
      });
  };
};

export const getPatientsWithLatLngList = () => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = getApiUrl("combos", "clientsWithLatLongList");
    api
      .get(`${url}`)
      .then((res) => {
        dispatch({ type: commonTypes.GET_PATIENTS_WITH_LATLNG_LIST, payload: res.data });
        dispatch(setLoader(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoader(false));
        toastr.error(defaultErrorMessage, "");
      });
  };
};

export const getNursesList = () => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = getApiUrl("combos", "nursesList");
    api
      .get(`${url}`)
      .then((res) => {
        dispatch({ type: commonTypes.GET_NURSES_LIST, payload: res.data });
        dispatch(setLoader(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoader(false));
        toastr.error(defaultErrorMessage, "");
      });
  };
};

export const getFormTemplateTypesList = () => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = getApiUrl("commonAPI", "formTemplateTypesList");
    api
      .get(`${url}`)
      .then((res) => {
        dispatch({ type: commonTypes.GET_FORM_TEMPLATE_TYPES_LIST, payload: res.data });
        dispatch(setLoader(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoader(false));
        toastr.error(defaultErrorMessage, "");
      });
  };
};

export const getFormTemplatesList = (type) => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    let url = getApiUrl("commonAPI", "formTemplatesList");
    if (type != null && type.length > 0) {
      url += `?chartingType=${type}`;
    }
    api
      .get(url)
      .then((res) => {
        dispatch({ type: commonTypes.GET_FORM_TEMPLATES_LIST, payload: res.data });
        dispatch(setLoader(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoader(false));
        toastr.error(defaultErrorMessage, "");
      });
  };
};

export const getFrequenciesList = () => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = getApiUrl("combos", "frequencyList");
    api
      .get(`${url}`)
      .then((res) => {
        dispatch({ type: commonTypes.GET_FREQUENCIES_LIST, payload: res.data });
        dispatch(setLoader(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoader(false));
        toastr.error(defaultErrorMessage, "");
      });
  };
};

export const getMaritalStatiiList = () => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = getApiUrl("combos", "maritalStatus");
    api
      .get(`${url}`)
      .then((res) => {
        dispatch({ type: commonTypes.GET_MARITAL_STATII_LIST, payload: res.data });
        dispatch(setLoader(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoader(false));
        toastr.error(defaultErrorMessage, "");
      });
  };
};

// Get Service creation data
export const getServiceRequestTypesList = () => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = getApiUrl("combos", "serviceRequestTypesList");
    api
      .get(`${url}`)
      .then((res) => {
        dispatch({ type: commonTypes.GET_SERVICE_REQUEST_TYPES_LIST, payload: res.data });
        dispatch(setLoader(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoader(false));
        toastr.error(defaultErrorMessage, "");
      });
  };
}

// Get Service creation data
export const getServiceRequestTasksList = () => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = getApiUrl("combos", "serviceRequestTasksList");
    api
      .get(`${url}`)
      .then((res) => {
        dispatch({ type: commonTypes.GET_SERVICE_REQUEST_TASKS_LIST, payload: res.data });
        dispatch(setLoader(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoader(false));
        toastr.error(defaultErrorMessage, "");
      });
    // try {
    //   const response = await api.get(url);
    //   dispatch(setLoader(false));
    //   return response;
    // } catch (err) {
    //   dispatch(setLoader(false));
    //   toastr.error(defaultErrorMessage, "");
    // }
  };
}

export const getPhysiciansList = () => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = getApiUrl("combos", "physiciansList");
    api
      .get(url)
      .then((res) => {
        dispatch({ type: commonTypes.GET_PHYSICIANS_LIST, payload: res.data });
        dispatch(setLoader(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoader(false));
        toastr.error(defaultErrorMessage, "");
      });
  };
}

export const getHospitalsList = () => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = getApiUrl("combos", "hospitalsList");
    api
      .get(url)
      .then((res) => {
        dispatch({ type: commonTypes.GET_HOSPITALS_LIST, payload: res.data });
        dispatch(setLoader(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoader(false));
        toastr.error(defaultErrorMessage, "");
      });
  };
}

export const getHealthAgenciesList = () => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = getApiUrl("combos", "homeHealthAgenciesList");
    api
      .get(url)
      .then((res) => {
        dispatch({ type: commonTypes.GET_HEALTH_AGENCIES_LIST, payload: res.data });
        dispatch(setLoader(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoader(false));
        toastr.error(defaultErrorMessage, "");
      });
  };
}

export const getHospiceAgenciesList = () => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = getApiUrl("combos", "hospiceAgenciesList");
    api
      .get(url)
      .then((res) => {
        dispatch({ type: commonTypes.GET_HOSPICE_AGENCIES_LIST, payload: res.data });
        dispatch(setLoader(false));
      })
      .catch((err) => {
        console.log(err);
        dispatch(setLoader(false));
        toastr.error(defaultErrorMessage, "");
      });
  };
}
