import React, {useEffect, useState} from 'react';
import BackLink from "../sharedComponents/buttons/BackLink";
import {NavLink, Outlet, useLocation, useParams} from "react-router-dom";
import {isAdminUser} from "../helpers/apiMethods";
import storageManager from "../helpers/storageManager";

const NurseProfileLayout = () => {
  const isAdmin = isAdminUser();
  const pathPrefix = `${isAdmin ? "/admin/agency" : "/agency"}/nurses/edit/profile`;

// console.log('test- state', state);
  // const { id: nurseId } = useParams();
  const { state: locationState } = useLocation();
  // const nurseId = state?.id;

  // const [tabId, setTabId] = useState('profile');
  const [showBackButton, setShowBackButton] = useState(false);
  const [backLink, setBackLink] = useState('');
  const [backButtonTextSuffix, setBackButtonTextSuffix] = useState('');
  const [currentNurseId, setCurrentNurseId] = useState(0);

  useEffect(() => {
    if (locationState != null && locationState.showBackButton) {
      setShowBackButton(locationState.showBackButton);
      setBackLink(locationState.backLink);
      setBackButtonTextSuffix(locationState.backButtonTextSuffix);
    } else {
      const backLinkInfoStr = storageManager.retrieveAdminBackLinkInfo();
      if (backLinkInfoStr != null) {
        const backLinkInfo = JSON.parse(backLinkInfoStr);
        setShowBackButton(backLinkInfo.showBackButton);
        setBackLink(backLinkInfo.backLink);
        setBackButtonTextSuffix(backLinkInfo.backButtonTextSuffix);
      }
    }

    if (locationState != null && locationState.id != null) {
      setCurrentNurseId(locationState.id);
    }
  }, [locationState]);

  useEffect(() => {
    if (locationState != null) {
      setCurrentNurseId(locationState.id);
      storageManager.saveCurrentNurseId(locationState.id);
    } else {
      setCurrentNurseId(storageManager.retrieveCurrentNurseId());
    }

    return () => {
      storageManager.clearCurrentNurseId();
    };
  }, []);

  return (
    <div className={`${isAdmin ? 'admin-app-main__outer' : 'app-main__outer'} h-100 w-100`}>
      <div className="main-service-request-page">
        <div className="app-main__inner service-request bg-white">
          <div className="col-12 page-back-btn">
            {/*<BackLink link={showBackButton && backLink ? backLink : (isAdmin ? "/admin/all/unverifiedNurses" : "/agency/nurses/nurselist")}
                      linkText={showBackButton && backButtonTextSuffix != null ? backButtonTextSuffix : `${isAdmin ? "Unverified Nurse" : "Nurse"} List`}/>*/}
            <BackLink link={showBackButton && backLink ? backLink : (isAdmin ? "admin/list/agencies/unverified" : "/agency/nurses/list/internal")}
                      linkText={showBackButton && backButtonTextSuffix != null ? backButtonTextSuffix : `${isAdmin ? "Unverified Nurse" : "Nurse"} List`}/>
          </div>
          <h3>Nurse Profile</h3>
          <div className="row align-items-center">
            <div className="col-sm-12 col-12">
              <ul className="nav nav-tabs p-1" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  {/*<NavLink to={`${pathPrefix}/personal-info/main/${nurseId}`} className="nav-link tab-nav-link-style">Personal Info</NavLink>*/}
                  <NavLink to={`${pathPrefix}/personal-info`} className="nav-link tab-nav-link-style">Personal Info</NavLink>
                </li>
                {!isAdmin && <li className="nav-item" role="presentation">
                  {/*<NavLink to={`${pathPrefix}/calender/${nurseId}`} className="nav-link tab-nav-link-style">Calendar</NavLink>*/}
                  <NavLink to={`${pathPrefix}/calender`} className="nav-link tab-nav-link-style">Calendar</NavLink>
                </li>}
                <li className="nav-item" role="presentation">
                  {/*<NavLink to={`${pathPrefix}/certs-evals/certifications/${nurseId}`} className="nav-link tab-nav-link-style">Certifications & Evaluations</NavLink>*/}
                  <NavLink to={`${pathPrefix}/certs-evals`} className="nav-link tab-nav-link-style">Certifications & Evaluations</NavLink>
                </li>
                <li className="nav-item" role="presentation">
                  {/*<NavLink to={`${pathPrefix}/documents/${nurseId}`} className="nav-link tab-nav-link-style">Documents</NavLink>*/}
                  <NavLink to={`${pathPrefix}/documents`} className="nav-link tab-nav-link-style">Documents</NavLink>
                </li>
                {!isAdmin && <li className="nav-item" role="presentation">
                  {/*<NavLink to={`${pathPrefix}/charting/${nurseId}`} className="nav-link tab-nav-link-style">Charting</NavLink>*/}
                  <NavLink to={`${pathPrefix}/charting`} className="nav-link tab-nav-link-style">Charting</NavLink>
                </li>}
              </ul>
            </div>
            <div className="col-sm-5 col-12"></div>
          </div>
        </div>
        <div className="app-main__inner service-request-inner  nurses-agency-tab" style={{marginBottom: '10px'}}>
          <div className="tab-content" id="myTabContent">
            {currentNurseId > 0 && <Outlet context={{nurseId: currentNurseId}}/>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NurseProfileLayout;
