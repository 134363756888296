import React, {useEffect, useState} from "react";
import Table from "../../../sharedComponents/table/Table";
import {HttpsAction} from "../../../commonApiFunction/httpsAction";
import {useDispatch, useSelector} from "react-redux";
import {hoursConvert, parseTokenAndGetData} from "../../../helpers/apiMethods";
import {Dialog} from "@mui/material";
import {Form} from "react-formio";
// import {formIoData} from "./createNewForm/consts";
import {useNavigate} from "react-router";
import {pageLimit} from "../../../appConstants";
import Pagination from "../../../sharedComponents/pagination";
import storageManager from "../../../helpers/storageManager";
import {getChartingData} from "../../../services/charting";
import dateHelperFunctions from "../../../helpers/date.helpers";
import {useLocation, useOutletContext} from "react-router-dom";
import NewTableLayout from "../shared/NewTableLayout";
import {getCountedVisits} from "../../../services/serviceRequest";

const AgencyChartingForms = ({/*toSearch*/}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { searchText, setPaginationTab } = useOutletContext();

    const [open, setOpen] = useState(false);
    const [formIoData, setFormIoData] = useState({components: []});

    const formTemplatesData = useSelector(state => state?.dynamicForm?.templateList?.data);

    // const templateData = useSelector(state => state.charting?.templateData?.data);

    const templateIdToSelect = location?.state != null && location?.state?.templateId != null ? location.state.templateId : 0;

    const transformedArray = (formTemplatesData?.rows || [])?.map((item, index) => {
        const highlightRow = templateIdToSelect === item.templateId;
        return {
            id: item.id,
            name: item?.name,
            Created: <div className={'d-flex flex-column'}>
                <div>
                    {item?.creationDateFormatUs}
                </div>
                <div>
                    {item?.creationTime}
                </div>
            </div>,
            Modified: <div className={'d-flex flex-column'}>
                <div>
                    {item?.updatedDateFormatUs}
                </div>
                <div>
                    {item?.updatedTime}
                </div>
            </div>,
            Expires: "",
            highlightRow,
        }
    });

    const serviceHeaderData = [
        {
            title: "Name",
            dataIndex: "name",
        },
        {
            title: "Created",
            dataIndex: "Created",
            width: '130px',
        },
        {
            title: "Modified",
            dataIndex: "Modified",
            width: '130px',
        },
        // {
        //     title: "Expires",
        //     dataIndex: "Expires",
        // }
    ];
    const handleOpen = () => setOpen(!open);


    const actionButton = [
        {
            actionName: '',
            buttonAction: async (id, index) => {
                setFormIoData({components: []});
                handleOpen()
                await setFormIoData({components: formTemplatesData?.rows[index].components});
            },
            actionClass: '',
            iconClass: '',
            component: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M8.09756 12C8.09756 14.1333 9.8439 15.8691 12 15.8691C14.1463 15.8691 15.8927 14.1333 15.8927 12C15.8927 9.85697 14.1463 8.12121 12 8.12121C9.8439 8.12121 8.09756 9.85697 8.09756 12ZM17.7366 6.04606C19.4439 7.36485 20.8976 9.29455 21.9415 11.7091C22.0195 11.8933 22.0195 12.1067 21.9415 12.2812C19.8537 17.1103 16.1366 20 12 20H11.9902C7.86341 20 4.14634 17.1103 2.05854 12.2812C1.98049 12.1067 1.98049 11.8933 2.05854 11.7091C4.14634 6.88 7.86341 4 11.9902 4H12C14.0683 4 16.0293 4.71758 17.7366 6.04606ZM12.0015 14.4124C13.3381 14.4124 14.4308 13.3263 14.4308 11.9979C14.4308 10.6597 13.3381 9.57361 12.0015 9.57361C11.8845 9.57361 11.7674 9.58331 11.6601 9.6027C11.621 10.6694 10.743 11.5227 9.66006 11.5227H9.61128C9.58201 11.6779 9.5625 11.833 9.5625 11.9979C9.5625 13.3263 10.6552 14.4124 12.0015 14.4124Z"
                      fill="#0075DB"/>
            </svg>


        },
        {
            actionName: '',
            buttonAction: (id) => navigate(`/agency/lookups/form/${id}`),
            actionClass: '',
            iconClass: '',
            component: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M9.3764 20.0279L18.1628 8.66544C18.6403 8.0527 18.8101 7.3443 18.6509 6.62299C18.513 5.96726 18.1097 5.34377 17.5049 4.87078L16.0299 3.69906C14.7459 2.67784 13.1541 2.78534 12.2415 3.95706L11.2546 5.23735C11.1273 5.39752 11.1591 5.63401 11.3183 5.76301C11.3183 5.76301 13.812 7.76246 13.8651 7.80546C14.0349 7.96671 14.1622 8.1817 14.1941 8.43969C14.2471 8.94493 13.8969 9.41792 13.377 9.48242C13.1329 9.51467 12.8994 9.43942 12.7297 9.29967L10.1086 7.21422C9.98126 7.11855 9.79025 7.13898 9.68413 7.26797L3.45514 15.3303C3.0519 15.8355 2.91395 16.4912 3.0519 17.1255L3.84777 20.5761C3.89021 20.7589 4.04939 20.8879 4.24039 20.8879L7.74222 20.8449C8.37891 20.8341 8.97316 20.5439 9.3764 20.0279ZM14.2797 18.9533H19.9898C20.5469 18.9533 21 19.4123 21 19.9766C21 20.5421 20.5469 21 19.9898 21H14.2797C13.7226 21 13.2695 20.5421 13.2695 19.9766C13.2695 19.4123 13.7226 18.9533 14.2797 18.9533Z"
                      fill="#0075DB"/>
            </svg>

        },
        {
            actionName: '',
            buttonAction: (id, index) => {
                handleCopyForm(index)
            },
            actionClass: '',
            iconClass: '',
            component: <svg width="20" height="20" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M17.6641 8.5026H24.0807L17.6641 2.08594V8.5026ZM7.16406 0.335938H18.8307L25.8307 7.33594V19.0026C25.8307 19.6214 25.5849 20.2149 25.1473 20.6525C24.7097 21.0901 24.1162 21.3359 23.4974 21.3359H7.16406C6.54522 21.3359 5.95173 21.0901 5.51415 20.6525C5.07656 20.2149 4.83073 19.6214 4.83073 19.0026V2.66927C4.83073 2.05043 5.07656 1.45694 5.51415 1.01935C5.95173 0.58177 6.54522 0.335938 7.16406 0.335938ZM2.4974 5.0026V23.6693H23.4974V26.0026H2.4974C1.87856 26.0026 1.28506 25.7568 0.84748 25.3192C0.409895 24.8816 0.164063 24.2881 0.164062 23.6693V5.0026H2.4974Z"
                    fill="#1976D2"/>
            </svg>

        },
        {
            actionName: '',
            buttonAction: async (id) => {
                await dispatch(HttpsAction({
                    method: 'DELETE',
                    apiSection: 'forms',
                    apiName: 'createTemplate',
                    queryParameter: `/${id}`,
                    actionType: 'DELETE_TEMPLATE_LIST',
                    reducerName: 'DYNAMIC_FORM',
                    reduxKeyName: 'templateList'
                }));
                getData()
            },
            actionClass: '',
            iconClass: '',
            component: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M20.2871 5.24297C20.6761 5.24297 21 5.56596 21 5.97696V6.35696C21 6.75795 20.6761 7.09095 20.2871 7.09095H3.71385C3.32386 7.09095 3 6.75795 3 6.35696V5.97696C3 5.56596 3.32386 5.24297 3.71385 5.24297H6.62957C7.22185 5.24297 7.7373 4.82197 7.87054 4.22798L8.02323 3.54598C8.26054 2.61699 9.0415 2 9.93527 2H14.0647C14.9488 2 15.7385 2.61699 15.967 3.49699L16.1304 4.22698C16.2627 4.82197 16.7781 5.24297 17.3714 5.24297H20.2871ZM18.8058 19.134C19.1102 16.2971 19.6432 9.55712 19.6432 9.48913C19.6626 9.28313 19.5955 9.08813 19.4623 8.93113C19.3193 8.78413 19.1384 8.69713 18.9391 8.69713H5.06852C4.86818 8.69713 4.67756 8.78413 4.54529 8.93113C4.41108 9.08813 4.34494 9.28313 4.35467 9.48913C4.35646 9.50162 4.37558 9.73903 4.40755 10.1359C4.54958 11.8992 4.94517 16.8102 5.20079 19.134C5.38168 20.846 6.50498 21.922 8.13206 21.961C9.38763 21.99 10.6811 22 12.0038 22C13.2496 22 14.5149 21.99 15.8094 21.961C17.4929 21.932 18.6152 20.875 18.8058 19.134Z"
                      fill="#1976D2"/>
            </svg>

        },
    ];

    const handleCopyForm = (index) => {
        const data = formTemplatesData?.rows?.[index];
        if (data) {
            dispatch(HttpsAction({
                method: 'POST',
                apiSection: 'forms',
                apiName: 'createTemplate',
                data: {
                    name: data.name,
                    templateTypeId: +data?.templateTypeId,
                    agencyId: data.agencyId,
                    components: data.components
                },
                positiveCallBack: () => {
                    getData(1)
                }
            }));
        }
    };

    const getData = (page, searchVal) => {
        let qsFilter = `?page=${page}`;
        if (searchVal != null && searchVal.length > 0) {
            qsFilter += `&searchText=${searchVal}`;
        }
        dispatch(HttpsAction({
            apiSection: 'forms',
            apiName: 'createTemplate',
            queryParameter: qsFilter,
            actionType: 'GET_TEMPLATE_LIST',
            reducerName: 'DYNAMIC_FORM',
            reduxKeyName: 'templateList'
        }));
    };

    // const handlePageChange = (page) => {
    //     // storageManager.saveClientListCurrentPage(page);
    //     return getData(page);
    // };

    const loadMethod = (curPage, toSearch) => {
        const useDefault = curPage == null && toSearch == null;
        if (useDefault) getData(1);
        else {
            if (toSearch != null && toSearch.length > 0)
                getData(1, toSearch);
            else
                getData(curPage);
        }
    };

    useEffect(() => {
        if (formTemplatesData != null && formTemplatesData?.rows?.length != null) {
            setPaginationTab(formTemplatesData);
        }
    }, [formTemplatesData]);

    // useEffect(() => {
    //     let qsFilter = `?page=${1}`;
    //     if (toSearch.length > 0) {
    //         qsFilter += `&searchText=${toSearch}`;
    //     }
    //     dispatch(HttpsAction({
    //         apiSection: 'forms',
    //         apiName: 'createTemplate',
    //         queryParameter: qsFilter,
    //         actionType: 'GET_TEMPLATE_LIST',
    //         reducerName: 'DYNAMIC_FORM',
    //         reduxKeyName: 'templateList'
    //     }));
    // }, [toSearch]);
    //
    // useEffect(() => {
    //     getData();
    // }, []);

    return (
        <>
            {/*<div className="app-main__inner service-request-inner">
                <div className="tab-content" id="myTabContent">
                    <Table headers={serviceHeaderData} rows={transformedArray} actions={actionButton} tableClass="charting-table" horizontalActionsLayout={true} actionColWidth="200px" />
                    <Pagination
                        currentPage={formTemplatesData?.page}
                        totalRecords={formTemplatesData?.total}
                        limit={pageLimit}
                        onPageChange={handlePageChange}
                    />
                </div>
            </div>*/}

            <NewTableLayout listData={formTemplatesData} loadMethod={loadMethod} loadDeps={[searchText]}>
                <Table headers={serviceHeaderData} rows={transformedArray} actions={actionButton} tableClass="charting-table" horizontalActionsLayout={true} actionColWidth="200px" />
            </NewTableLayout>
            <Dialog
                open={open}
                onClose={handleOpen}
                maxWidth={'xl'}
            >
                <>
                    <div className={'p-4'}>
                        <h2>Preview of Form (Template)</h2>
                        <Form form={formIoData} submission={{data: {}}}/>
                    </div>
                </>
            </Dialog>
        </>
    )
};

export default AgencyChartingForms;
