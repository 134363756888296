// withConfirmationDialog.js
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {postedJobActions} from '../../services/jobPosting'

function withConfirmationDialog(WrappedComponent) {

  return function WithConfirmationDialog(props) {
    const dispatch = useDispatch();
    const [showDialog, setShowDialog] = useState(false);
    const [payload, setPayload] = useState(null);
    const [message, setMessage] = useState('');
    const [apiEndpoint, setApiEndpoint] = useState(null);
    // const [confirmCallbackFunc, setConfirmCallbackFunc] = useState(null);
    // const [hasConfirmCallbackSet, setHasConfirmCallbackSet] = useState(false);
    const [runConfirmCallback, setRunConfirmCallback] = useState(false);

    const openDialog = (payload, message, endpoint) => {
      setPayload(payload)
      setMessage(message);
      setApiEndpoint(endpoint);
      setShowDialog(true);
    };

    const openDialogNew = (message/*, confirmCallback*/) => {
      setMessage(message);
      // setConfirmCallbackFunc(confirmCallback);
      setShowDialog(true);
    };

    const closeDialog = () => {
        setPayload(null)
        setMessage('');
        setApiEndpoint(null);
        setShowDialog(false);
    };

    // function confirmCallback(callback) {
    //   setHasConfirmCallbackSet(callback != null);
    //   if (callback != null) {
    //     callback();
    //     return true;
    //   }
    //   return false;
    // }

    const handleConfirm = () => {
      // dispatch(postedJobActions(payload, apiEndpoint))
      // if (confirmCallback == null) {
      //   alert('ERROR! provide confirm callback');
      //   throw new Error('ERROR! provide confirm callback');
      // } else {
      //   confirmCallback();
      //   closeDialog();
      // }
      setRunConfirmCallback(true);
      closeDialog();
    };

    return (
      <>
        {
            showDialog && (
                <div
                    className={`modal fade w-100 h-100 show active d-flex justify-content-center bg-black bg-opacity-25`}
                    id="exampleModal"
                    tabIndex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog w-100 d-flex align-items-center">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-6" id="exampleModalLabel">
                                    Are you sure ?
                                </h1>
                            </div>
                            <div className="modal-body d-flex flex-column">
                                {message}
                            </div>
                            <div className="modal-footer">
                                <button type="button" onClick={closeDialog} className="btn btn-secondary" data-bs-dismiss="modal">Disagree</button>
                                <button type="button" onClick={handleConfirm} className="btn btn-primary">Agree</button>
                            </div>
                        </div>
                    </div>
            </div>
            )
        }
        <WrappedComponent {...props} openConfirmationDialog={openDialogNew} runConfirmCallback={runConfirmCallback} />
      </>
    );
  };
}

export default withConfirmationDialog;
