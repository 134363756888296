import React, {useEffect, useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";

import CalendarModal from "./Calendar";

import "../../assets/css/responsive.css";
import {Popover, Stack} from "@mui/material";
import {Field, reduxForm, reset} from "redux-form";
import CustomInputField from "../../../sharedComponents/fields/customInputField";
import PrimaryButton from "../../../sharedComponents/buttons/PrimaryButton";
import {connect, useDispatch} from "react-redux";
import {required} from "../../../helpers/validation";
import {debounce} from "lodash";

// const TableTitle = ({ title, isFullView, onSearch, subTitle, handleExport = () => undefined, handleSubmit, handleDateFilter = () => undefined }) => {
const TableTitle = ({ handleSubmit, title, isFullView = true, subTitle, handleFilters = () => undefined, handleExport = () => undefined}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const dropdownRef = useRef();
  const searchInputRef = useRef();

  const [showCalendar, setShowCalendar] = useState(false);
  const [isDropdown, setIsDropdown] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [clearFilters, setClearFilters] = useState(false);

  const handleNavigate = (event) => {
    event.preventDefault();
    navigate("/admin/all/nurses", { state: { type: title } });
  };

  const handleClickFilter = () => {
    setIsDropdown(!isDropdown);
  };

  const handleSearch = debounce((value) => {
    setSearchTerm(value.length === 0 ? '' : value);
  }, 500);

  const handleClearFilters = () => {
    setClearFilters(true);
  };

  const onSubmit = (data) => {
    // handleDateFilter(data);
    setFromDate(data.from);
    setToDate(data.to);
    handleClickFilter();
  };

  useEffect(() => {
    if (clearFilters) {
      // handleFilters({from: null, to: null, searchText: ''});
      searchInputRef.current.value = '';
      setSearchTerm('');
      setFromDate(null);
      setToDate(null);
      dispatch(reset("tableTitle"));
      setClearFilters(false);
    }
  }, [clearFilters]);

  useEffect(() => {
    if (!clearFilters) handleFilters({from: fromDate, to: toDate, searchText: searchTerm});
  }, [clearFilters, searchTerm, fromDate, toDate]);

  return (
    <div className="table-title-wrap">
      <Row>
        <Col xs={6}>
          <div className="left-element">
            <h3>{title}</h3>
            <p>{subTitle}</p>
          </div>
        </Col>
        <Col xs={6} className="d-flex justify-content-end align-items-center">
          {isFullView && (
            <Button variant="secondary" style={{ marginRight: 10, width: '110px' }} onClick={() => handleExport(title)}>
              <i className="bi bi-file-arrow-down"></i> Export
            </Button>
          )}
          <div className="right-element">
            <div className="input-group">
              {isFullView && (
                <>
                  <div className="input-group">
                    <span className="input-group-append">
                      <button className="btn ms-n5" type="button">
                        <i className="fa fa-search"></i>
                      </button>
                    </span>
                    <input
                      ref={searchInputRef}
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      aria-label="search"
                      onChange={({target}) => handleSearch(target.value)}
                    />

                    <Button
                      onClick={() => setShowCalendar(!showCalendar)}
                      disabled
                      variant="secondry"
                      className="btn btn-secondary"
                    >
                      <i className="bi bi-calendar"></i> Filter By:
                    </Button>
                    <Button
                        ref={dropdownRef}
                        onClick={handleClickFilter}
                        // onClick={() => setShowCalendar(!showCalendar)}
                        variant="primary"
                        className="button-with-icon"
                    >
                      <i className="bi bi-calendar"></i> Date
                    </Button>
                    <Button
                      onClick={handleClearFilters}
                      // onClick={() => setShowCalendar(!showCalendar)}
                      variant="secondary"
                      // className="button-with-icon"
                      className="btn btn-secondary"
                    >
                      {/*<span title="clear" className={`position-absolute top-26px end-2px z-1 btn btn-vs btn-close btn-outline-danger`} onClick={handleClear}>&nbsp;</span>*/}
                      <i className="bi bi-x"></i> Clear Filters
                    </Button>
                  </div>
                </>
              )}
              {/*{!isFullView && (*/}
              {/*  <>*/}
              {/*    <Button variant="secondary" className="">*/}
              {/*      <i className="bi bi-file-arrow-down"></i> Export*/}
              {/*    </Button>*/}
              {/*    <Button*/}
              {/*      onClick={() => setShowCalendar(!showCalendar)}*/}
              {/*      variant="primary"*/}
              {/*      className="button-with-icon"*/}
              {/*    >*/}
              {/*      <i className="bi bi-calendar"></i> Date*/}
              {/*    </Button>*/}
              {/*  </>*/}
              {/*)}*/}
            </div>
          </div>
          {/*{!isFullView && (*/}
          {/*  <span>*/}
          {/*    <a*/}
          {/*      onClick={handleNavigate}*/}
          {/*      className="text-decoration-none admin-see-all ml-3"*/}
          {/*    >*/}
          {/*      See all*/}
          {/*    </a>*/}
          {/*  </span>*/}
          {/*)}*/}
        </Col>
      </Row>
      <CalendarModal
        show={showCalendar}
        handleClose={() => setShowCalendar(false)}
        handleDateSelect={() => null}
      />

      <Popover
          open={isDropdown}
          anchorEl={dropdownRef.current}
          onClose={() => setIsDropdown(false)}
          anchorOrigin={{
            vertical: 'bottom',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          PaperProps={{
            style: {
              boxShadow: 'unset',
              width: '260px',
              height: 'auto',
              borderRadius: '5px',
              marginTop: '5px',
              border: '1px solid #D5D5D5',
              padding: '20px'
            },
          }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-12">
              <div className="mb-4 input-field-main">
                <Field
                    className="form-control mk-wi"
                    name="from"
                    label="From"
                    type="date"
                    component={CustomInputField}
                    requiredMark={true}
                    validate={required}
                />
              </div>
            </div>
            <div className="col-12">
              <div className="mb-4 input-field-main">
                <Field
                    className="form-control mk-wi"
                    name="to"
                    label="To"
                    type="date"
                    component={CustomInputField}
                    requiredMark={true}
                    validate={required}
                />
              </div>
            </div>
          </div>
          <div
              className="d-flex justify-content-center"
              style={{marginTop: "20px"}}
          >
            <PrimaryButton
                type="submit"
                className="btn-view-repo gap-2 px-5 py-2"
                text={"Apply Date Filter"}
                onClick={() => undefined}
            />
          </div>
        </form>
      </Popover>
    </div>
  );
};

export default connect()(
    reduxForm({form: "tableTitle", enableReinitialize: true})(TableTitle)
);
