import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useDispatch} from 'react-redux';
import Table from '../../../sharedComponents/table/Table'
import {useLocation, useNavigate, useOutletContext} from "react-router-dom";
import {getAllJobs, postedJobActions} from "../../../services/jobPosting";
import WarningModal from "../../../sharedComponents/warningModal";
import CustomTable from "../../../sharedComponents/customTable";
import Pagination from "../../../sharedComponents/pagination";
import {chartingRelatedText, jobRelatedText, jobsTabUrls} from "../../../appConstants";
import apiCallHelperFunctions from "../../../helpers/api.call.helpers";
import rowHelperFunctions from "../../../helpers/row.helpers";
import CommonPaginationNew from "../../../sharedComponents/pagination/CommonPaginationNew";

const PostedJobsInProgress = ({/*searchText, */postedJobsInProgressList/*, currentPage*/}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { searchText, setPaginationTab } = useOutletContext();

    const [currentPage, setCurrentPage] = useState(0);
    const [modal, setModal] = useState(false);

    const jobIdToSelect = location?.state != null && location?.state?.jobId != null ? location.state.jobId : 0;

    const transformedArray = postedJobsInProgressList?.rows?.map(item => {
        // const hasValidChartingId = item?.templateTypeId != null && item.templateTypeId > 0 && item?.chartingId != null && item.chartingId > 0;
        // let chartingRelatedLink = null;
        // if (hasValidChartingId) {
        //     // if (item?.templateTypeId === 1) chartingRelatedLink = '/agency/servicerequest/initialAssessments';
        //     // if (item?.templateTypeId === 2) chartingRelatedLink = '/agency/servicerequest/supervisoryVisits';
        //     // if (item?.templateTypeId === 3) chartingRelatedLink = '/agency/servicerequest/dischargeSummaries';
        //     // if (item?.templateTypeId === 4) chartingRelatedLink = '/agency/servicerequest/carePlans';
        //     if (item?.templateTypeId === 1) chartingRelatedLink = '/agency/charting/initialassessment';
        //     if (item?.templateTypeId === 2) chartingRelatedLink = '/agency/charting/supervisorvisit';
        //     if (item?.templateTypeId === 3) chartingRelatedLink = '/agency/charting/dischargesummary';
        //     if (item?.templateTypeId === 4) chartingRelatedLink = '/agency/charting/careplan';
        // }
        let { hasValidChartingId, relatedInfoList} = rowHelperFunctions.getRelatedInfo(item, false, false);
        const highlightRow = jobIdToSelect === item.jobId;
        return {
            'id': {jobId: item.jobId, serviceRequestId: item.serviceRequestId},
            // 'jobTitle': item?.jobTitle,
            'jobTitle': <div className="position-relative1 p-0 m-0">
                <span>{item?.jobTitle}</span>
                {/*<div className="position-absolute top-25 start-50 translate-middle d-flex gap-2">
                    {hasValidChartingId && chartingRelatedText && <div className="cursor-pointer" onClick={() => {
                        navigate(chartingRelatedLink, {
                            state: {
                                chartingId: item?.chartingId,
                            },
                        });
                    }}>
                        <span className={`badge rounded-pill text-wrap lh-1 text-bg-warning`}>{chartingRelatedText}</span>
                    </div>}
                </div>*/}
                {(relatedInfoList || []).length > 0 && <div className="position-absolute top-25 start-50 translate-middle cursor-pointer w-100 d-flex justify-content-center gap-1">{relatedInfoList.map(({relatedTextColor, relatedText, relatedLink, relatedState}, index) => <span key={index} onClick={() => {
                    if (relatedLink != null) navigate(relatedLink, { state: relatedState });
                }} className={`badge rounded-pill text-wrap lh-1 text-bg-${relatedTextColor}`}>{relatedText}</span>)}</div>}
            </div>,
            'hours': item.totalHours,
            'location': item.jobLocation || 'N/A',
            'shiftTimings': item.shiftTimings,
            highlightRow,
        }
    });

    const serviceHeaderData = [
        {
            title: "Job Title",
            dataIndex: "jobTitle",
        },
        {
            title: "Hours",
            dataIndex: "hours",
            width: '55px',
        },
        {
            title: "Location",
            dataIndex: "location",
        },
        {
            title: "Shift Timings",
            dataIndex: "shiftTimings",
            width: '120px',
        },
    ];

    const actionButtons = [
        {
            actionName: 'View Service Request',
            buttonAction: (specificData) => navigate('/agency/servicerequest/allrequest', { state: { serviceRequestId: specificData.serviceRequestId } }),
            actionClass: 'btn-primary px-1 word-break',
            iconClass: 'fa-user-plus',
        },
    ];

    useEffect(() => {
        if (postedJobsInProgressList != null && postedJobsInProgressList?.rows?.length != null) {
            setPaginationTab(postedJobsInProgressList);
        }
    }, [postedJobsInProgressList]);

    useEffect(() => {
        if (searchText != null && searchText.length > 0)
            dispatch(getAllJobs('inProgressJobs', 1, searchText));
        else
            dispatch(getAllJobs('inProgressJobs', currentPage));
    }, [currentPage, searchText]);

    return (
      <>
          {/*<div className="container-fluid bg-light">
              <div className="bg-light-gray px-3 py-3">
                  <div className="row">
                      <div className="container-fluid">
                          <div className="tab-content" id="pills-tabContent">
                              <div className="bg-white mt-3">
                                  <div className="col-sm-3">
                                      <div className="d-flex gap-2 innerTabing">*/}
                                          {/*<button type="button" className="btn btn-sm"
                                                  onClick={() => navigate("/agency/servicerequest/postedjobs")}>Pending
                                          </button>
                                          <button type="button" className="btn btn-sm"
                                                  onClick={() => navigate("/agency/servicerequest/postedjobsactive")}>Active
                                          </button>
                                          <button type="button" className="btn btn-sm"
                                                  onClick={() => navigate("/agency/servicerequest/postedjobsaccepted")}>Accepted
                                          </button>
                                          <button type="button" className="btn btn-sm"
                                                  onClick={() => navigate("/agency/servicerequest/postedjobsexpired")}>Expired
                                          </button>
                                          <button type="button" className="btn btn-sm text-nowrap btn-primary"
                                                  onClick={() => navigate("/agency/servicerequest/postedjobsinprogress")}>In-Progress
                                          </button>
                                          <button type="button" className="btn btn-sm"
                                                  onClick={() => navigate("/agency/servicerequest/postedjobscompleted")}>Completed
                                          </button>*/}
                                          {/*{apiCallHelperFunctions.getTabButtons('postedJobs', jobsTabUrls, navigate, location.pathname)}
                                      </div>
                                  </div>*/}
                                  <Table headers={serviceHeaderData} rows={transformedArray} actions={actionButtons} />
                                  <CommonPaginationNew data={postedJobsInProgressList} onPageChange={(page) => setCurrentPage(page)} />
                              {/*</div>
                          </div>
                          <Pagination
                            currentPage={postedJobsInProgressList?.page}
                            totalRecords={postedJobsInProgressList?.total}
                            limit={postedJobsInProgressList?.pageSize}
                            onPageChange={(page) => setCurrentPage(page)}
                          ></Pagination>
                      </div>
                  </div>
              </div>
          </div>*/}
      </>
    );

}

const mapStateToProps = state => {
    const {serviceRequest} = state;
    return {
        postedJobsInProgressList: serviceRequest?.postedJobsInProgress || {},
    }
}

export default connect(mapStateToProps)(PostedJobsInProgress);
