import React from 'react';

const CustomRadioField = ({autoFocus, readOnly = false, name = '', label, disabled = false, required, className = 'form-check-input', spacingClasses = 'm-0 p-0',
                            labelClassName = 'form-check-label d-inline-block pt-1 mb-0', id = '', value = '', selectedValue, onChange, ...input }) => {
  // console.log('test- selectedValue', typeof selectedValue, selectedValue, typeof value, value);
  const valueStr = typeof value === 'string' ? value : String(value);
  return (
    <div className={`option-column${spacingClasses.length > 0 ? ` ${spacingClasses}` : ''} form-check${disabled || readOnly ? ' no-pointer-events' : ''} d-flex align-items-center`}>
      <input
        type="radio"
        name={name}
        value={value}
        disabled={disabled}
        id={id}
        className={className}
        onChange={onChange}
        checked={selectedValue === valueStr}
        // checked={(input?.value || '-1') === value}
        {...input}
      />
      <label className={labelClassName} htmlFor={id}>{label}</label>
    </div>
  );
};

export default CustomRadioField;
