import React, {useEffect, useState} from "react";
import CustomInputField from "../../../../../../sharedComponents/reduxfields/customInputField";
import CustomRadioGroup from "../../../../../../sharedComponents/reduxfields/customRadioGroup";
import CustomDropDownField from "../../../../../../sharedComponents/reduxfields/customDropDown";
import {required} from "../../../../../../helpers/validation";
import {change, Field, reduxForm} from "redux-form";
import {connect, useDispatch, useSelector} from "react-redux";
import {
    getClientById,
    getClientProfilePersonalInfoPersonalTitleAll,
    putPersonalInformation,
} from "../../../../../../services/client";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import PrimaryButton from "../../../../../../sharedComponents/buttons/PrimaryButton";
import * as clientRequest from "../../../clientActionType";
import {HttpsAction} from "../../../../../../commonApiFunction/httpsAction";
import AddSpouseModal from "../../../../../AddSpouseModal";
import CustomLabel from "../../../../../../sharedComponents/fields/CustomLabel";
import CustomRadioField from "../../../../../../sharedComponents/fields/customRadioField";
import {getGendersList, getMaritalStatiiList} from "../../../../../../services/commonServices";
import {useQueryClient} from "@tanstack/react-query";
import stringHelperFunctions from "../../../../../../helpers/string.helpers";
import numberHelperFunctions from "../../../../../../helpers/number.helpers";
import {invalidateClientsList, invalidatePatientMinimalInfo} from "../../../../../../queries/agency/clientsList";
import RadioOptionsComponent from "../../../../../../sharedComponents/radioOptions";
import useCustomNav from "../../../../../../utils/hooks/useCustomNav";

const genderRadioOptions = [
    {label: "Male", id: "male", value: "1"},
    {label: "Female", id: "female", value: "2"},
    {label: "Other", id: "other", value: "3"},
];

const PersonalInformation = ({handleSubmit, initialValues, agencyId}) => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const {clientId, id} = useParams();
    const { clientId } = useOutletContext();

    const clientById = useSelector(state => state.client?.clientById?.data)

    const { goTo: gotoCareInfoPage } = useCustomNav('/agency/clients/edit/profile/care-information');

    const gendersArray = queryClient.getQueryData(['list-genders']);
    const maritalStatusArray = queryClient.getQueryData(['list-marital-status']);
    const titleList = queryClient.getQueryData(['list-personal-titles']);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedGenderId, setSelectedGenderId] = useState(-1);

    const calculateAge = async (dateOfBirth) => {
        // courtesy: https://stackoverflow.com/a/7091965
        var today = new Date();
        var birthDate = new Date(dateOfBirth);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        dispatch(change('clientProfile', 'age', age));
    }

    const handleGenderChanged = async (event) => {
        let {name, value} = event.target;
        dispatch(change('clientProfile', 'genderId', +value));
        setSelectedGenderId(+value);
    }

    const onSubmit = async (data) => {
        const {
            firstName,
            lastName,
            genderId,
            dateOfBirth,
            socialSecurityNumber,
            maritalStatus,
            titleId,
            spouseName
        } = data;

        const res = await dispatch(putPersonalInformation({
            titleId: numberHelperFunctions.nullIfEmpty(titleId, true),
            firstName,
            middleName: null,
            lastName: stringHelperFunctions.nullIfEmpty(lastName),
            genderId: numberHelperFunctions.nullIfEmpty(genderId),
            birthDate: stringHelperFunctions.nullIfEmpty(dateOfBirth),
            // age,
            socialSecurityNumber: stringHelperFunctions.nullIfEmpty(socialSecurityNumber),
            maritalStatusId: numberHelperFunctions.nullIfEmpty(maritalStatus)
        }, clientId))

        if (res?.success) {
            invalidatePatientMinimalInfo(queryClient, agencyId, +clientId);
            invalidateClientsList(queryClient, agencyId);
            // navigate(`/agency/clients/${clientId}/${id}/careInformation`);
            gotoCareInfoPage();
        }
    };

    function openModal() {
        setIsModalOpen(true);
    }

    const closeModal = (newNurseCreated) => {
        setIsModalOpen(false);
    };


    useEffect(() => {
// console.log('initialValues', initialValues)
        if (initialValues != null && initialValues.genderId != null && initialValues.genderId.length > 0) {
            setSelectedGenderId(+initialValues.genderId);
        } else if (clientById != null && clientById.genderId != null && clientById.genderId.length > 0) {
            setSelectedGenderId(clientById.genderId);
        }
    }, [initialValues, clientById]);

    useEffect(() => {
        dispatch(HttpsAction({
            apiSection: 'client',
            apiName: 'getClientById',
            queryParameter: `/${clientId}`,
            reducerName: 'CLIENT',
            reduxKeyName: 'clientById',
            actionType: 'GET_PERSONAL_INFO',
        }));
        dispatch(HttpsAction({
            apiSection: 'client',
            apiName: 'spouse',
            actionType: `GET_SPOUSE`,
            reduxKeyName: 'spouse',
            reducerName: 'CLIENT',
            queryParameter: `/${clientId}`
        }));
    }, []);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-3">
                        <div className="mb-4 input-field-main">
                            {/*<label>Title </label>*/}
                            <Field
                              className="form-control mk-wi"
                              label="Title"
                              name="titleId"
                              component={CustomDropDownField}
                            >
                                <option value="">Please Select</option>
                                {(titleList || []).map((res, index) =>
                                  <option key={index} value={res.id}>{res.name}</option>
                                )}
                            </Field>
                        </div>
                    </div>
                    <div className="custom-col-4-5">
                        <div className="mb-4 input-field-main">
                            <Field
                              className="form-control mk-wi"
                              name="firstName"
                              label="First Name"
                              type="text"
                              component={CustomInputField}
                              requiredMark={true}
                            />
                        </div>
                    </div>
                    <div className="custom-col-4-5">
                        <div className="mb-4 input-field-main">
                            <Field
                              className="form-control mk-wi"
                              name="lastName"
                              label="Last Name"
                              type="text"
                              component={CustomInputField}
                              requiredMark={true}
                            />
                        </div>
                    </div>
                    <div className="col-4">
                    {/*<div className="mb-4 input-field-main">
                        <label>
                            Please Select your gender<span className="mandatory-field">&nbsp;*</span>
                        </label>
                        <Field
                            name="genderId"
                            component={CustomRadioGroup}
                            options={genderRadioOptions}
                            requiredMark={false}
                            onChange={(e) => undefined}
                        />
                    </div>*/}
                        <Field
                          className="form-control1 input-field-main"
                          name="genderId"
                          // label="Please select your gender"
                          label="Gender"
                          component={RadioOptionsComponent}
                          requiredMark={true}
                          // validate={(val) => console.log('VALIDATION- value', val)}
                          validate={required}
                          optionsList={gendersArray}
                          handleGenderChanged={({target}) => {
                              if (target?.value != null) dispatch(change('clientProfile', 'genderId', +target.value));
                          }}
                        />
                </div>
                    {/*<div className="col-12 col-md-4">*/}
                    {/*    <div className="mb-4a input-field-main">*/}
                    {/*        <CustomLabel requiredMark={true} className="form-label1" labelText="Please Select your gender"/>*/}
                    {/*        <div className="row">*/}
                    {/*            {gendersArray.length > 0 && gendersArray.map((item, index) => (*/}
                    {/*              <div key={index} className="col-4 col-md-4">*/}
                    {/*                  <div className="form-check">*/}
                    {/*                      <CustomRadioField*/}
                    {/*                        label={item.name}*/}
                    {/*                        className="form-check-input"*/}
                    {/*                        type="radio"*/}
                    {/*                        name="genderId"*/}
                    {/*                        id={item.id}*/}
                    {/*                        value={item.id}*/}
                    {/*                        // selected={initialValues.genderId}*/}
                    {/*                        selected={selectedGenderId}*/}
                    {/*                        onChange={(e) => handleGenderChanged(e)}*/}
                    {/*                      />*/}
                    {/*                  </div>*/}
                    {/*              </div>*/}
                    {/*            ))}*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="col-4">
                        <div className="mb-4 input-field-main">
                            <Field
                              className="form-control mk-wi"
                              name="dateOfBirth"
                              label="Date of Birth"
                              type="date"
                              component={CustomInputField}
                              // onChange={({target}) => calculateAge(target.value)}
                              onBlur={({target}) => calculateAge(target.value)}
                              // requiredMark={true}
                              // validate={required}
                            />
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="mb-4 input-field-main">
                            <Field
                              readOnly={true}
                              className="form-control mk-wi"
                              name="age"
                              label="Age"
                              type="text"
                              component={CustomInputField}
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="mb-4 input-field-main">
                            <Field
                              className="form-control mk-wi"
                              name="socialSecurityNumber"
                              label="Social security number"
                              type="text"
                              component={CustomInputField}
                              // requiredMark={true}
                              // validate={required}
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="mb-4 input-field-main">
                            <Field
                              className="form-control mk-wi"
                              label="Marital Status"
                              name="maritalStatus"
                              component={CustomDropDownField}
                              // requiredMark={true}
                              // validate={required}
                            >
                                <option value="">Please Select</option>
                                {(maritalStatusArray || [])?.map((res, index) =>
                                  <option key={index} value={res.id}>{res.name}</option>
                                )}
                            </Field>
                        </div>
                    </div>
                    <div className="col-4 position-relative">
                        <div className="mb-4 input-field-main">
                            <Field
                              className="form-control mk-wi read-only"
                              name="spouseName"
                              label="Spouse"
                              type="text"
                              component={CustomInputField}
                              readOnly={true}
                            />
                        </div>
                        {initialValues != null && initialValues.spouseName != null &&
                          <div className="position-absolute" style={{top: '-5px', right: '12px'}}>
                              <button
                                type="button"
                                className="view_report d-flex btn-view-repo align-items-center gap-2 px-3 py-1"
                                onClick={() => openModal()}
                              >
                                  {initialValues.spouseName.length > 0 ? <i className="bi bi-pencil"></i> : <i className="bi bi-plus-lg"></i>}
                                  {initialValues.spouseName.length > 0 ? 'Update' : 'Create'}
                              </button>
                          </div>}
                    </div>
                </div>
                <div
                  className="d-flex justify-content-center"
                  style={{marginTop: "40px"}}
                >
                    <PrimaryButton
                      type="submit"
                      className="btn-view-repo gap-2 px-5 py-2"
                      text={"Save"}
                      onClick={() => undefined}
                    />
                </div>
            </form>
            {isModalOpen && (
              <AddSpouseModal
                isModalOpen={isModalOpen}
                closeModal={(newNurseCreated) => closeModal(newNurseCreated)}
                modalTitle="Add Spouse"
                gendersArray={gendersArray}
                clientId={clientId}
              />
            )}
        </>
    )
};

const mapStateToProps = state => {
    const {auth, client: {clientById = null, spouse = null}} = state;

    return {
        isAdmin: auth?.authData?.isAdmin || false,
        agencyId: auth?.authData?.agencyId || auth.agencyId,
        initialValues: {
            titleId: clientById?.data?.titleId?.toString() || "",
            firstName: clientById?.data?.patientFirstName || "",
            lastName: clientById?.data?.patientLastName || "",
            genderId: clientById?.data?.patientGenderId?.toString() || "",
            dateOfBirth: clientById?.data?.patientBirthDate || "",
            age: clientById?.data?.patientAge?.toString() || "",
            socialSecurityNumber: clientById?.data?.patientSocialSecurityNumber || "",
            maritalStatus: clientById?.data?.patientMaritalStatusId?.toString() || "",
            spouseName: spouse?.data?.spouseFirstLastName ?? '',
        }
    }
};

export default connect(mapStateToProps)(
    reduxForm({form: "clientProfile", enableReinitialize: true})(PersonalInformation)
);
