import React from "react";
import Table from "../../../../../../../sharedComponents/table/Table";
import {Stack} from "@mui/material";
import classes from "../../CareNote.module.css";
import {useParams} from "react-router-dom";
import dateHelperFunctions from "../../../../../../../helpers/date.helpers";

export const getDayName = (date) => {
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    return days[new Date(date).getDay()];
}

const TasksInformation = ({tableRef, tasksList}) => {

    const {tabId} = useParams();

    // const careList = useSelector(state => state.client?.careList?.data || [])

    // const transformedArray = (careList?.rows?.[Number(tabId) - 1]?.tasks || [])?.map((item, index) => {
    const transformedArray = (tasksList || [])?.map((item, index) => {
        return {
            date: <Stack gap={'5px'}>
                <Stack className={classes.dayName}>
                    {getDayName(item?.date)}
                </Stack>
                <Stack className={classes.date}>
                    {dateHelperFunctions.updateDateFormat(item?.date, "MM/DD/YYYY")}
                </Stack>
            </Stack>,
            // nurse: `${careList?.rows?.[Number(tabId) - 1]?.patientFirstName}, ${careList?.rows?.[Number(tabId) - 1]?.patientLastName}`,
            nurse: item?.assignedNurseFirstName || 'N/A',
            clock: `${dateHelperFunctions.convertTo12HourFormat(item?.clockIn)} - ${dateHelperFunctions.convertTo12HourFormat(item?.clockOut)}`,
            taskName: (item?.activities || [])?.map((value, index) => `${value?.category}, `)
        }
    });

    const serviceHeaderData = [
        {
            title: "Date",
            dataIndex: "date",
        },
        {
            title: "Nurse",
            dataIndex: "nurse",
        },
        // {
        //     title: "Nurse",
        //     dataIndex: "nurse",
        // },
        {
            title: "Clock In/Clock Out",
            dataIndex: "clock",
        },
        {
            title: "Tasks Name and Category",
            dataIndex: "taskName",
        },
    ];

    return (
        <>
            <Table headers={serviceHeaderData} rows={transformedArray}/>
        </>
    )
};
export default TasksInformation;
