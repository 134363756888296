import React, {useCallback, useEffect, useState} from 'react'
import {connect, useDispatch, useSelector} from 'react-redux';
import Modal from '../../../sharedComponents/Modal'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { putServiceCreationData } from '../../../services/serviceRequest'
import { styled, lighten, darken } from '@mui/system';
import AddFundsComponent from "../shared/AddFunds";
import {HttpsAction} from "../../../commonApiFunction/httpsAction";
import {parseTokenAndGetData} from "../../../helpers/apiMethods";
import {getAgencyWalletDetailsAction} from "../../../services/transaction";
import numberHelperFunctions from "../../../helpers/number.helpers";
import {useGlobalStorageEvent} from "../../../utils/hooks/useWindowEvent";
// import { makeStyles } from "@mui/styles";


// const useStyles = makeStyles(theme => ({
//   root: {
//     "& .MuiFormLabel-root": {
//       color: "red" // or black
//     }
//   }
// }));

const AssignNurseToServiceRequestModal = ({ isModalOpen, closeModal, modalTitle, nurseList, serviceRequestId, walletDetails, totalAmountReserved, amountReserved}) => {
  const agencyBalance = numberHelperFunctions.nullIfEmptyFloat(walletDetails?.balance) || 0;

  const dispatch = useDispatch();

  // const classes = useStyles();

  const getServiceRequestById = useSelector(state => state.serviceRequest?.serviceDetails?.data || null);

  const [value, setValue] = useState('')
  const [inputValue, setInputValue] = useState('')
  const [totalCost, setTotalCost] = useState(0);
  const [additionalFundsReq, setAdditionalFundsReq] = useState(0);
  const [isFreelanceNurse, setIsFreelanceNurse] = useState(false);

    const transformedArray = nurseList?.map(item => {
      return {
        'label': item.nurseFullName,
        'nurseId': item.id,
        'isHired': item.hiredNurse === 1,
        'groupTitle': item.hiredNurse === 1 ? 'Hired' : 'Internal',
      }
    });

    const handleAssignClick = () => {
      if (value) {
        const obj = {
          "serviceRequestId": serviceRequestId,
          "nurseId": value.nurseId,
        }
        dispatch(putServiceCreationData(obj, 'assignNurseToServiceRequest', function () {
          closeModal(true);
        }));
      }
    };

  function loadAgencyWallet() {
    const decodedTokenData = parseTokenAndGetData().data;
    dispatch(getAgencyWalletDetailsAction("agencyWalletDetails", decodedTokenData.walletId));
  }

  const storageEventHandler = (event) => {
    if (event?.detail?.key === "balance-updated") {
      loadAgencyWallet();
    }
  };

  useEffect(() => {
    if (serviceRequestId != null && serviceRequestId > 0) {
      dispatch(HttpsAction({
        apiSection: 'serviceRequests',
        apiName: 'serviceDetails',
        queryParameter: `/${serviceRequestId}`,
        actionType: `GET_SERVICE_DETAILS_SINGLE_REQUEST`,
        reduxKeyName: 'serviceDetails',
        reducerName: 'SERVICE_REQUEST',
      }));
    }
  }, [serviceRequestId])

  useEffect(() => {
    if (getServiceRequestById) {
      const tasksList = getServiceRequestById?.tasks || [];
      const currentNurseRate = getServiceRequestById?.nurseRate || 0;
      const currentPayRateTypeId = getServiceRequestById?.payRateTypeId || 0;
// console.log('test- tasksList', tasksList, currentNurseRate, currentPayRateTypeId);
      if (tasksList.length > 0 && getServiceRequestById != null) {
        const currentDateTimeUtc = new Date(Date.parse(getServiceRequestById.currentDateTimeUtc.replace(' ', 'T') + '+00:00'));
        const currentDateUtc = new Date(Date.parse(currentDateTimeUtc.toISOString().substring(0, 10)+'T00:00:00+00:00'));
        const nurseRate = currentNurseRate;
        const payRateTypeIdVal = currentPayRateTypeId;
        const currentDate = new Date();
        const daysCount = 1;
        let totalCostVal = 0;
// console.log('test- daysCount', daysCount, totalCostVal, payRateTypeIdVal);
        if (payRateTypeIdVal === 1) {
          tasksList.forEach(task => {
            const scheduledEndDateTimeUtc = new Date(Date.parse(task.scheduledEndDateTimeUtc.replace(' ', 'T') + '+00:00'));
            const taskDateValue = new Date(Date.parse(task.date+'T00:00:00+00:00'));
// console.log('test- task', task.statusId, task.clockIn, task.date, task.scheduledEndTime, currentDateTimeUtc, scheduledEndDateTimeUtc, currentDateTimeUtc > scheduledEndDateTimeUtc);
            if (task.statusId < 3 && task.clockIn == null && currentDateTimeUtc > scheduledEndDateTimeUtc) totalCostVal += 0;
            // else if (task.clockIn != null && taskDateValue < currentDateUtc && task.remainingActivitiesTotal > 0) totalCostVal += 0;
            else if ((task.clockIn != null && taskDateValue < currentDateUtc && task.remainingActivitiesTotal > 0) || (task.clockIn != null && task.clockOut != null && task.remainingActivitiesTotal > 0) || (task.statusId === 4 && task.remainingActivitiesTotal > 0)) totalCostVal += 0;
            else {
              const endTime = new Date(Date.parse(currentDate.toISOString().substring(0, 10) + 'T' + task.scheduledEndTime + '+00:00'));
              const startTime = new Date(Date.parse(currentDate.toISOString().substring(0, 10) + 'T' + task.scheduledStartTime + '+00:00'));
              const hoursCount = (endTime.getTime() - startTime.getTime()) / 1000 / 60 / 60;
// console.log('test- hoursCount', hoursCount);
              totalCostVal += nurseRate * daysCount * hoursCount;
            }
// console.log('test- totalCostVal', totalCostVal, currentDate.toISOString().substring(0, 10), task.scheduledEndTime, task.scheduledStartTime);
          });
        } else {
          tasksList.forEach(task => {
            const scheduledEndDateTimeUtc = new Date(Date.parse(task.scheduledEndDateTimeUtc));
            const taskDateValue = new Date(Date.parse(task.date+'T00:00:00+00:00'));
            if (task.statusId < 3 && task.clockIn == null && currentDateTimeUtc > scheduledEndDateTimeUtc) totalCostVal += 0;
            // else if (task.clockIn != null && taskDateValue < currentDateUtc && task.remainingActivitiesTotal > 0) totalCostVal += 0;
            else if ((task.clockIn != null && taskDateValue < currentDateUtc && task.remainingActivitiesTotal > 0) || (task.clockIn != null && task.clockOut != null && task.remainingActivitiesTotal > 0) || (task.statusId === 4 && task.remainingActivitiesTotal > 0)) totalCostVal += 0;
            else totalCostVal += nurseRate * daysCount;
          });
        }
        setTotalCost(numberHelperFunctions.round(totalCostVal));
        const moreFunds = agencyBalance - totalAmountReserved - totalCostVal;
        if (moreFunds < 0) setAdditionalFundsReq(Math.abs(Math.floor(moreFunds))); // for negative values use Math.floor else use Math.ceil
        else if (totalCostVal >= 0) setAdditionalFundsReq(0);
      } else {
        setTotalCost(0);
      }
    }
  }, [getServiceRequestById]);

  useEffect(() => {
    loadAgencyWallet();
    // window.addEventListener("storage", storageEventHandler);

    // on destroy
    return () => {
      // window.removeEventListener("storage", storageEventHandler);
      dispatch(HttpsAction({
        reducerName: 'SERVICE_REQUEST',
        reduxKeyName: 'serviceDetails',
        actionType: `GET_SERVICE_DETAILS_SINGLE_REQUEST`,
        removeData: true,
        actionTypeIsDispatchType: false,
      }));
    };
  }, []);

  useGlobalStorageEvent(storageEventHandler);

    return (
      <Modal isOpen={isModalOpen} onClose={() => closeModal(false)} modalTitle={modalTitle}>
        <Autocomplete
          // value={value}
          id="combo-box-demo"
          options={transformedArray}
          // renderInput={(params) => <TextField {...params} className={classes.root} label="Select Nurse"/>}
          renderInput={(params) => <TextField {...params} label="Select Nurse"/>}
          sx={{ width: 300 }}
          inputValue={inputValue}
          onInputChange={(e, newInputValue) => {
            setInputValue(newInputValue);
          }}
          onChange={(event, newValue) => {
            if (newValue != null) {
              setIsFreelanceNurse(newValue.isHired);
              setValue(newValue);
            }
          }}
          groupBy={(option) => option.groupTitle}
          getOptionLabel={(option) => option.label}
        />
        {isFreelanceNurse && <>
          <div className="row my-3">
            <div className="col-4 d-flex flex-row justify-content-start align-items-center gap-2">
              <span style={{width: 'max-content'}}>Total Cost:&nbsp;</span><span style={{width: 'max-content'}}>${totalCost}</span>
            </div>
            <div className="col-4 d-flex flex-row justify-content-start align-items-center gap-2">
              {/*<span style={{width: 'max-content'}}>Available Balance:&nbsp;</span><span style={{width: 'max-content'}}>${agencyBalance - totalAmountReserved + amountReserved}</span>*/}
              {/*<span style={{width: 'max-content'}}>Available Balance:&nbsp;</span><span style={{width: 'max-content'}}>${agencyBalance}</span>*/}
              <span style={{width: 'max-content'}}>Available Balance:&nbsp;</span><span style={{width: 'max-content'}}>${(agencyBalance - totalAmountReserved).toFixed(2)}</span>
            </div>
            <div className="col-4 d-flex flex-row justify-content-start align-items-center gap-2">
              {/*<span style={{width: 'max-content'}}>Additional Funds Required:&nbsp;</span><span style={{width: 'max-content'}}>{((agencyBalance - totalAmountReserved + amountReserved - totalCost) > 0 ? `$${agencyBalance - totalAmountReserved + amountReserved}` : `-$${Math.abs(agencyBalance - totalAmountReserved + amountReserved - totalCost)}`)}</span>*/}
              {/*<span style={{width: 'max-content'}}>Additional Funds Required:&nbsp;</span><span className={`text-${(agencyBalance - totalAmountReserved + amountReserved - totalCost) > 0 ? 'success' : 'danger'}`} style={{width: 'max-content'}}>{((agencyBalance - totalAmountReserved + amountReserved - totalCost) > 0 ? `$0` : `$${Math.abs(agencyBalance - totalAmountReserved + amountReserved - totalCost)}`)}</span>*/}
              <span style={{width: 'max-content'}}>Additional Funds Required:&nbsp;</span><span className={`text-${additionalFundsReq > 0 ? 'danger' : 'success'}`} style={{width: 'max-content'}}>{(additionalFundsReq > 0 ? `$${additionalFundsReq}` : `$0`)}</span>
            </div>
          </div>
          {/*<div className="row mt-3">
           {agencyBalance}, {totalAmountReserved}, {amountReserved}, {totalCost}
           </div>*/}
          {totalCost > (agencyBalance - totalAmountReserved + amountReserved) && <div className="row d-flex flex-row justify-content-between align-items-center">
            <span className="text-danger d-inline-block" style={{width: 'max-content'}}>You will have to add funds to your wallet, to continue</span>
            <AddFundsComponent btnClassName="btn btn-primary" style={{maxWidth: '105px'}}/>
          </div>}
        </>}
        <div className="modal-footer justify-content-center pt-4 pb-0 gap-2 mt-4">
          <button type="button" className="btn btn-secondary" onClick={() => closeModal(false)}>Cancel</button>
          <button type="button" className="btn btn-primary" onClick={handleAssignClick} disabled={value === '' || isFreelanceNurse && totalCost > (agencyBalance - totalAmountReserved + amountReserved)}>Assign</button>
        </div>
      </Modal>
    );
}

const mapStateToProps = (state) => {
  const { auth, serviceRequest, transaction } = state;
  const walletDetails = transaction?.agencyWalletDetails || null;
  return {
    isAdmin: auth?.authData?.isAdmin || false,
    agencyId: auth?.authData?.agencyId || auth.agencyId,
    walletDetails,
  };
}
export default connect(mapStateToProps)(AssignNurseToServiceRequestModal);
