import React from "react";
import classes from "./PrimaryButton.module.css"

const PrimaryButton = ({
                           type,
                           className,
                           onClick,
                           icon,
                           text,
                           button,
                           style,
                           disabled = false,
                           ...custom
                       }) => {
    return (
        <>
            <button
                disabled={disabled || false}
                type={type || "button"}
                className={`${className} ${disabled ? classes.disabledBtn : classes.enableButton}` || ""}
                onClick={onClick || undefined}
                style={style || undefined}
                {...button}
                {...custom}
            >
                {icon || ""}
                {text}
            </button>
        </>
    );
};

export default PrimaryButton;
