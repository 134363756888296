import React, {useEffect, useState} from 'react';
import {toBase64} from "../../helpers/formMethods";
import {createDownloadUrl} from "../../helpers/apiMethods";
import {maxUploadFileSizeMbs} from "../../appConstants";
import {toastr} from "react-redux-toastr";

const FileInput = ({id, label, handleFileBrowse, accept, fileUploadedSuccessfully = false, maxFileSizeMbs = maxUploadFileSizeMbs, fileLink = '', multiple = false, preview = true, previewSquareWidth = '250px'}) => {
  const [selectedFilename, setSelectedFilename] = useState('');
  const [logoLink, setLogoLink] = useState('');
  const [base64LogoPic, setBase64LogoPic] = useState(null);

  const handleFileSelected = async target => {
    const selectedFile = target?.files?.[0] || null;
    if (selectedFile != null) {
// console.log('test- selectedFile.name', selectedFile.name);

      // Check if file size exceeds 100MB
      if (selectedFile.size > maxFileSizeMbs * 1024 * 1024) {
        toastr.error(`Selected file exceeds the maximum allowed size of ${maxFileSizeMbs}MB.`)
      } else {
        setSelectedFilename(selectedFile.name);
        if (preview) {
          const base64Format = await toBase64(selectedFile);
          setBase64LogoPic(base64Format);
        }

        handleFileBrowse(selectedFile);
      }
    }
  };

  useEffect(() => {
    if (fileLink.length > 0) setLogoLink(createDownloadUrl(fileLink));
  }, [fileLink]);

  useEffect(() => {
    if (fileUploadedSuccessfully) setSelectedFilename('');
  }, [fileUploadedSuccessfully]);

  return (
    <>
      <div className="d-flex flex-column justify-content-start align-items-start gap-2">
        <span className="fs-6">Maximum allowed file size is {maxFileSizeMbs}.00 MB</span>
        <div className="d-flex justify-content-start align-items-center gap-3">
          <label htmlFor={id} className="fileBrowseButton w-max px-3">
            <span className="w-max" style={{width: "80%", textAlign: 'center', lineHeight: '16px'}}>{label}</span>
          </label>
          <input
            type="file"
            id={id}
            style={{display: 'none'}}
            onChange={({target}) => handleFileSelected(target)}
            multiple={multiple}
            accept={accept}
          />
          <div><span className="fw-bolder">Filename:&nbsp;</span><span className="fst-italic fs-5 text-secondary">{(selectedFilename?.length || 0) === 0 ? 'No file selected' : selectedFilename}</span></div>
        </div>
      </div>
      <div className="d-flex flex-column justify-content-start align-items-start gap-2">
        {preview && (selectedFilename?.length || 0) === 0 && base64LogoPic == null && logoLink.length > 0 && <span className="fw-bolder">Preview of saved file:&nbsp;</span>}
        {preview && (selectedFilename?.length || 0) > 0 && <span className="fw-bolder">Preview of selected file:&nbsp;</span>}
        {preview && base64LogoPic != null && <img id="logo" alt={''} className="neo-img1" src={base64LogoPic} style={{width: previewSquareWidth, aspectRatio: '1'}}/>}
        {preview && base64LogoPic == null && logoLink.length > 0 && <img id="logo" alt={''} className="neo-img1" src={logoLink} style={{width: previewSquareWidth, aspectRatio: '1'}}/>}
      </div>
    </>
  );
};

export default FileInput;
