import React, {useEffect, useState} from "react";
import PrimaryButton from "../../../../sharedComponents/buttons/PrimaryButton";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import PersonalInfoInnerTabs from "./personalInfoInnerTabs";
import {Field, reduxForm} from "redux-form";
import CustomDropDownField from "../../../../sharedComponents/reduxfields/customDropDown";
import {required} from "../../../../helpers/validation";
import {connect, useDispatch} from "react-redux";
import CustomInputField from "../../../../sharedComponents/reduxfields/customInputField";
import CustomRadioGroup from "../../../../sharedComponents/reduxfields/customRadioGroup";
import CustomCheckboxField from "../../../../sharedComponents/reduxfields/CustomCheckBox";
import {
    getNurseAction,
    postNurseAction,
} from "../../../../services/NursesProfile";
import {HttpsAction} from "../../../../commonApiFunction/httpsAction";
import stringHelperFunctions from "../../../../helpers/string.helpers";
import booleanHelperFunctions from "../../../../helpers/boolean.helpers";
import numberHelperFunctions from "../../../../helpers/number.helpers";
import CustomLabel from "../../../../sharedComponents/fields/CustomLabel";
import useCustomNav from "../../../../utils/hooks/useCustomNav";
import {toastr} from "react-redux-toastr";
import {isAdminUser} from "../../../../helpers/apiMethods";

const AdditionalQuestions = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const { id: nurseId } = useParams();
    const { nurseId } = useOutletContext();

    // const isAdmin = window.location.pathname.includes('/admin/agencyNurses/edit');
    const isAdmin = isAdminUser();

    const { goTo: gotoEmergencyContactsPage } = useCustomNav(isAdmin ? `/admin/agency/nurses/edit/profile/personal-info/emergency-contacts` : '/agency/nurses/edit/profile/personal-info/emergency-contacts');

    const {initialValues, handleSubmit, submitFailed} = props;

    const workedForOurCompanyBeforeRadioOptions = [
        {label: "Yes", id: "workedForOurCompanyBeforeyes", value: 'true'},
        {label: "No", id: "workedForOurCompanyBeforeno", value: 'false'},
    ];
    const deniedAccessToMedicaidBenefitsRadioOptions = [
        {label: "Yes", id: "deniedAccessToMedicaidBenefitsyes", value: 'true'},
        {label: "No", id: "deniedAccessToMedicaidBenefitsno", value: 'false'},
    ];
    const isConvictedRadioOptions = [
        {label: "Yes", id: "isConvictedyes", value: 'true'},
        {label: "No", id: "isConvictedno", value: 'false'},
    ];
    const availableForAllHoursRadioOptions = [
        {label: "Yes", id: "availableForAllHoursyes", value: 'true'},
        {label: "No", id: "availableForAllHoursno", value: 'false'},
    ];
    const wantLongTermPositionRadioOptions = [
        {label: "Yes", id: "wantLongTermPositionyes", value: 'true'},
        {label: "No", id: "wantLongTermPositionno", value: 'false'},
    ];
    const dependableTransportAvailableRadioOptions = [
        {label: "Yes", id: "dependableTransportAvailableyes", value: 'true'},
        {label: "No", id: "dependableTransportAvailableno", value: 'false'},
    ];
    const isSmokerRadioOptions = [
        {label: "Yes", id: "isSmokerRadioOptionsyes", value: 'true'},
        {label: "No", id: "isSmokerRadioOptionsno", value: 'false'},
    ];

    useEffect(() => {
        dispatch(getNurseAction("additionalQuestions", nurseId));
    }, []);

    const onSubmit = async (values) => {
        let payload = Object.assign({}, values);

        dispatch(HttpsAction({
            method: 'PUT',
            apiSection: 'nursesProfile',
            apiName: 'nurseAdditionalQuestions',
            queryParameter: `/${nurseId}`,
            data: {
                isConvicted: booleanHelperFunctions.nullIfEmpty(payload?.isConvicted),
                isSmoker: booleanHelperFunctions.nullIfEmpty(payload?.isSmoker),
                availableHours: numberHelperFunctions.nullIfEmpty(payload?.availableHours, true),
                referralSource: "referral 1",
                recentExperience: stringHelperFunctions.nullIfEmpty(payload?.recentExperience),
                convictionDetails: stringHelperFunctions.nullIfEmpty(payload?.convictionDetails),
                hasCatsExperience: booleanHelperFunctions.nullIfEmpty(payload?.hasCatsExperience),
                hasDogsExperience: booleanHelperFunctions.nullIfEmpty(payload?.hasDogsExperience),
                hasMalesExperience: booleanHelperFunctions.nullIfEmpty(payload?.hasMalesExperience),
                availableForAllHours: booleanHelperFunctions.nullIfEmpty(payload?.availableForAllHours),
                hasDrivingExperience: booleanHelperFunctions.nullIfEmpty(payload?.hasDrivingExperience),
                hasBathingExperience: booleanHelperFunctions.nullIfEmpty(payload?.hasBathingExperience),
                hasFemalesExperience: booleanHelperFunctions.nullIfEmpty(payload?.hasFemalesExperience),
                hasSmokingExperience: booleanHelperFunctions.nullIfEmpty(payload?.hasSmokingExperience),
                wantLongTermPosition: booleanHelperFunctions.nullIfEmpty(payload?.wantLongTermPosition),
                hasAlzheimerExperience: booleanHelperFunctions.nullIfEmpty(payload?.hasAlzheimerExperience),
                workMotivationReason: stringHelperFunctions.nullIfEmpty(payload?.workMotivationReason),
                // hasAnyOtherExperience: booleanHelperFunctions.nullIfEmpty(payload?.hasAnyOtherExperience),
                // hasDementiaExperience: booleanHelperFunctions.nullIfEmpty(payload?.hasDementiaExperience),
                // hasDressingExperience: booleanHelperFunctions.nullIfEmpty(payload?.hasDressingExperience),
                smokingFrequencyPerDay: numberHelperFunctions.nullIfEmpty(payload?.smokingFrequencyPerDay, true),
                longTermPositionMaxDays: numberHelperFunctions.nullIfEmpty(payload?.longTermPositionMaxDays, true),
                hasHoyerLiftExperience: booleanHelperFunctions.nullIfEmpty(payload?.hasHoyerLiftExperience),
                hasGaitBeltExperience: booleanHelperFunctions.nullIfEmpty(payload?.hasGaitBeltExperience),
                anyOtherExperienceDetails: stringHelperFunctions.nullIfEmpty(payload?.anyOtherExperienceDetails),
                workedForOurCompanyBefore: booleanHelperFunctions.nullIfEmpty(payload?.workedForOurCompanyBefore),
                hasIncontinenceExperience: booleanHelperFunctions.nullIfEmpty(payload?.hasIncontinenceExperience),
                hasCompanionshipExperience: booleanHelperFunctions.nullIfEmpty(payload?.hasCompanionshipExperience),
                hasTransferAssistExperience: booleanHelperFunctions.nullIfEmpty(payload?.hasTransferAssistExperience),
                dependableTransportAvailable: booleanHelperFunctions.nullIfEmpty(payload?.dependableTransportAvailable),
                deniedAccessToMedicaidBenefits: booleanHelperFunctions.nullIfEmpty(payload?.deniedAccessToMedicaidBenefits),
            },
            positiveCallBack: () => {
                toastr.success('Additional questions updated successfully');
                // navigate(isAdmin ? `/admin/agencyNurses/edit/${nurseId}/emergencyContacts` : `/agency/nurses/edit/${nurseId}/emergencyContacts`)
                gotoEmergencyContactsPage();
            }
        }));
    };

    // console.log('test- initialValues.hasCatsExperience', typeof initialValues.hasCatsExperience, initialValues.hasCatsExperience);

    return (
        <>
            <div className="card-white px-4">
                {/*<PersonalInfoInnerTabs></PersonalInfoInnerTabs>*/}
                <form onSubmit={handleSubmit(onSubmit)} className="pt-3">
                    <div className="row">
                        <div className="col-12 mb-3">
                            <CustomLabel labelText="Have You ever worked for Our company before?" />
                            <Field
                                name="workedForOurCompanyBefore"
                                component={CustomRadioGroup}
                                options={workedForOurCompanyBeforeRadioOptions}
                                requiredMark={false}
                                onChange={(e) => undefined}
                            />
                        </div>
                        <div className="col-12 mb-3">
                            <CustomLabel labelText="Have you ever been denied access to Medicare or Medicaid benefits or found to be ineligible to do so?" />
                            <Field
                                name="deniedAccessToMedicaidBenefits"
                                component={CustomRadioGroup}
                                options={deniedAccessToMedicaidBenefitsRadioOptions}
                                requiredMark={false}
                                onChange={(e) => undefined}
                            />
                        </div>
                        {/* isConvicted */}
                        <div className="col-12 mb-3">
                            <CustomLabel labelText="Have you ever been convicted of a crime or a misdemeanor?" />
                            <Field
                                name="isConvicted"
                                component={CustomRadioGroup}
                                options={isConvictedRadioOptions}
                                requiredMark={false}
                                onChange={(e) => undefined}
                            />
                        </div>

                        <div className="col-12">
                            <Field
                                className="form-control mk-wi"
                                name="convictionDetails"
                                label="If yes, please describe the date and nature of the offense."
                                type="text"
                                component={CustomInputField}
                                placeholder={
                                    "If yes, please describe the date and nature of the offense."
                                }
                            />
                        </div>

                        {/* availableForAllHours */}
                        <div className="col-12 mb-3">
                            <CustomLabel labelText="Are you available for all hours?" />
                            <Field
                                name="availableForAllHours"
                                component={CustomRadioGroup}
                                options={availableForAllHoursRadioOptions}
                                requiredMark={false}
                                onChange={(e) => undefined}
                            />
                        </div>

                        <div className="col-12">
                            <Field
                                className="form-control mk-wi"
                                name="availableHours"
                                label="If no, write your available hours"
                                type="number"
                                component={CustomInputField}
                                placeholder={"If no, write your available hours"}
                            />
                        </div>

                        {/* wantLongTermPosition */}
                        <div className="col-12 mb-3">
                            <CustomLabel labelText="Do you want a long-term position providing care at the care recipient's home?" />
                            <Field
                                name="wantLongTermPosition"
                                component={CustomRadioGroup}
                                options={wantLongTermPositionRadioOptions}
                                requiredMark={false}
                                onChange={(e) => undefined}
                            />
                        </div>

                        <div className="col-12">
                            <Field
                                className="form-control mk-wi"
                                name="longTermPositionMaxDays"
                                label="If yes, choose maximum # of days"
                                type="number"
                                component={CustomInputField}
                                placeholder={"If yes, choose maximum # of days"}
                            />
                        </div>

                        {/* hasIncontinenceExperience */}
                        <div className="col-12 mb-3">
                            <CustomLabel labelText="Please check any that you are willing to work with" />
                            <div className="row">
                                <div className="col-md-3 col-12">
                                    <Field
                                        name="hasIncontinenceExperience"
                                        component={CustomCheckboxField}
                                        label="Incontinence"
                                        className="form-check-input"
                                        id="hasIncontinenceExperience"
                                    />
                                </div>
                                <div className="col-md-3 col-12">
                                    <Field
                                        name="hasHoyerLiftExperience"
                                        component={CustomCheckboxField}
                                        label="Hoyer Lift"
                                        className="form-check-input"
                                        id="hasHoyerLiftExperience"
                                    />
                                </div>
                                <div className="col-md-3 col-12">
                                    <Field
                                        name="hasCompanionshipExperience"
                                        component={CustomCheckboxField}
                                        label="Companionship"
                                        className="form-check-input"
                                        id="hasCompanionshipExperience"
                                    />
                                </div>
                                <div className="col-md-3 col-12">
                                    <Field
                                        name="hasDrivingExperience"
                                        component={CustomCheckboxField}
                                        label="Driving"
                                        className="form-check-input"
                                        id="hasDrivingExperience"
                                    />
                                </div>
                                <div className="col-md-3 col-12">
                                    <Field
                                        name="hasBathingExperience"
                                        component={CustomCheckboxField}
                                        label="Bathing/Dressing"
                                        className="form-check-input"
                                        id="hasBathingExperience"
                                    />
                                </div>
                                <div className="col-md-3 col-12">
                                    <Field
                                        name="hasAlzheimerExperience"
                                        component={CustomCheckboxField}
                                        label="Alzheimer's/Dementia"
                                        className="form-check-input"
                                        id="hasAlzheimerExperience"
                                    />
                                </div>
                                <div className="col-md-3 col-12">
                                    <Field
                                        name="hasTransferAssistExperience"
                                        component={CustomCheckboxField}
                                        label="Transfer Assist"
                                        className="form-check-input"
                                        id="hasTransferAssistExperience"
                                    />
                                </div>
                                <div className="col-md-3 col-12">
                                    <Field
                                        name="hasSmokingExperience"
                                        component={CustomCheckboxField}
                                        label="Smoking"
                                        className="form-check-input"
                                        id="hasSmokingExperience"
                                    />
                                </div>
                                <div className="col-md-3 col-12">
                                    <Field
                                        name="hasMalesExperience"
                                        component={CustomCheckboxField}
                                        label="Smoking"
                                        className="form-check-input"
                                        id="hasMalesExperience"
                                    />
                                </div>
                                <div className="col-md-3 col-12">
                                    <Field
                                        name="hasFemalesExperience"
                                        component={CustomCheckboxField}
                                        label="Females"
                                        className="form-check-input"
                                        id="hasFemalesExperience"
                                    />
                                </div>
                                <div className="col-md-3 col-12">
                                    <Field
                                        name="hasCatsExperience"
                                        component={CustomCheckboxField}
                                        label="Cats"
                                        className="form-check-input"
                                        id="hasCatsExperience"
                                    />
                                </div>
                                <div className="col-md-3 col-12">
                                    <Field
                                        name="hasDogsExperience"
                                        component={CustomCheckboxField}
                                        label="Dogs"
                                        className="form-check-input"
                                        id="hasDogsExperience"
                                    />
                                </div>
                                <div className="col-md-3 col-12">
                                    <Field
                                        name="hasGaitBeltExperience"
                                        component={CustomCheckboxField}
                                        label="Gait Belt"
                                        className="form-check-input"
                                        id="hasGaitBeltExperience"
                                    />
                                </div>
                            </div>
                        </div>
                        {/* hasIncontinenceExperience */}
                        {/* <div className="col-12 col-md-12">
              <label>Please check any you are willing to work with</label>
              <div className="row">
                <div className="col-md-3 col-12">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="hasIncontinenceExperience"
                    />
                    <label
                      className="form-check-label mb-0"
                      for="hasIncontinenceExperience"
                    >
                      Incontinence
                    </label>
                  </div>
                </div>
                <div className="col-md-3 col-12">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="hasHoyerLiftExperience"
                    />
                    <label
                      className="form-check-label mb-0"
                      for="hasHoyerLiftExperience"
                    >
                      Hoyer Lift
                    </label>
                  </div>
                </div>
                <div className="col-md-3 col-12">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="hasCompanionshipExperience"
                    />
                    <label
                      className="form-check-label mb-0"
                      for="hasCompanionshipExperience"
                    >
                      Companionship
                    </label>
                  </div>
                </div>

                <div className="col-md-3 col-12">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="hasDrivingExperience"
                    />
                    <label
                      className="form-check-label mb-0"
                      for="hasDrivingExperience"
                    >
                      Driving
                    </label>
                  </div>
                </div>

                <div className="col-md-3 col-12">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="hasBathingExperience"
                    />
                    <label
                      className="form-check-label mb-0"
                      for="hasBathingExperience"
                    >
                      Bathing/Dressing
                    </label>
                  </div>
                </div>

                <div className="col-md-3 col-12">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="hasAlzheimerExperience"
                    />
                    <label
                      className="form-check-label mb-0"
                      for="hasAlzheimerExperience"
                    >
                      Alzheimer's/Dementia
                    </label>
                  </div>
                </div>

                <div className="col-md-3 col-12">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="hasTransferAssistExperience"
                    />
                    <label
                      className="form-check-label mb-0"
                      for="hasTransferAssistExperience"
                    >
                      Transfer Assist
                    </label>
                  </div>
                </div>

                <div className="col-md-3 col-12">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="hasSmokingExperience"
                    />
                    <label
                      className="form-check-label mb-0"
                      for="hasSmokingExperience"
                    >
                      Smoking
                    </label>
                  </div>
                </div>

                <div className="col-md-3 col-12">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="hasMalesExperience"
                    />
                    <label
                      className="form-check-label mb-0"
                      for="hasMalesExperience"
                    >
                      Males
                    </label>
                  </div>
                </div>

                <div className="col-md-3 col-12">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="hasFemalesExperience"
                    />
                    <label
                      className="form-check-label mb-0"
                      for="hasFemalesExperience"
                    >
                      Females
                    </label>
                  </div>
                </div>

                <div className="col-md-3 col-12">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="hasCatsExperience"
                    />
                    <label
                      className="form-check-label mb-0"
                      for="hasCatsExperience"
                    >
                      Cats
                    </label>
                  </div>
                </div>

                <div className="col-md-3 col-12">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="hasDogsExperience"
                    />
                    <label
                      className="form-check-label mb-0"
                      for="hasDogsExperience"
                    >
                      Dogs
                    </label>
                  </div>
                </div>

                <div className="col-md-3 col-12">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="hasGaitBeltExperience"
                    />
                    <label
                      className="form-check-label mb-0"
                      for="hasGaitBeltExperience"
                    >
                      Gait Belt
                    </label>
                  </div>
                </div>

                <div className="col-md-3 col-12">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="hasAnyOtherExperience"
                    />
                    <label
                      className="form-check-label mb-0"
                      for="hasAnyOtherExperience"
                    >
                      Other:
                    </label>
                  </div>
                </div>
              </div>
            </div> */}

                        {/* dependableTransportAvailable */}
                        <div className="col-12 mb-3">
                            <CustomLabel labelText="Do you have a dependable transport?" />
                            <Field
                                name="dependableTransportAvailable"
                                component={CustomRadioGroup}
                                options={dependableTransportAvailableRadioOptions}
                                requiredMark={false}
                                onChange={(e) => undefined}
                            />
                        </div>

                        {/* isSmoker */}
                        <div className="col-12 mb-3">
                            <CustomLabel labelText="Are you a smoker?" />
                            <Field
                                name="isSmoker"
                                component={CustomRadioGroup}
                                options={isSmokerRadioOptions}
                                requiredMark={false}
                                onChange={(e) => undefined}
                            />
                        </div>

                        <div className="col-12">
                            <Field
                                className="form-control mk-wi"
                                name="smokingFrequencyPerDay"
                                label="If yes, how many per day?"
                                type="number"
                                component={CustomInputField}
                                placeholder={"how many per day"}
                            />
                        </div>

                        <div className="col-12">
                            <Field
                                className="form-control mk-wi"
                                name="anyOtherExperienceDetails"
                                label="How did you find out about us?"
                                type="text"
                                component={CustomInputField}
                                placeholder={"How did you find out about us?"}
                            />
                        </div>
                        <div className="col-12">
                            <Field
                                className="form-control mk-wi"
                                name="recentExperience"
                                label="Share your most recent experiences providing care."
                                type="text"
                                component={CustomInputField}
                                placeholder={
                                    "Share your most recent experiences providing care."
                                }
                            />
                        </div>
                        <div className="col-12">
                            <Field
                                className="form-control mk-wi"
                                name="workMotivationReason"
                                label="What makes you want to work as a carer for us?"
                                type="text"
                                component={CustomInputField}
                                placeholder={"What makes you want to work as a carer for us?"}
                            />
                        </div>
                    </div>
                    <div
                        className="d-flex justify-content-center"
                        style={{margin: "50px 0"}}
                    >
                        <button type="submit" className="btn btn-primary btn-lg">
                            Save
                        </button>
                        {/* <PrimaryButton
          type="button"
          className="btn-view-repo gap-2 px-5 py-2"
          text={"Next"}
          onClick={() =>
            navigate("/agency/nurses/addnewrequest/accountdetails")
          }
        /> */}
                    </div>
                </form>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    const {nurseListing} = state;
    const {nurseAdditionalQuestions} = nurseListing || {};

    return {
        initialValues: {
            isConvicted: booleanHelperFunctions.convertToString(nurseAdditionalQuestions?.isConvicted),
            isSmoker: booleanHelperFunctions.convertToString(nurseAdditionalQuestions?.isSmoker),
            availableHours: numberHelperFunctions.convertToIntegerString(nurseAdditionalQuestions?.availableHours),
            recentExperience: stringHelperFunctions.sanitize(nurseAdditionalQuestions?.recentExperience),
            convictionDetails: stringHelperFunctions.sanitize(nurseAdditionalQuestions?.convictionDetails),
            hasCatsExperience: booleanHelperFunctions.parseBoolean(nurseAdditionalQuestions?.hasCatsExperience),
            hasDogsExperience: booleanHelperFunctions.parseBoolean(nurseAdditionalQuestions?.hasDogsExperience),
            hasMalesExperience: booleanHelperFunctions.parseBoolean(nurseAdditionalQuestions?.hasMalesExperience),
            availableForAllHours: booleanHelperFunctions.convertToString(nurseAdditionalQuestions?.availableForAllHours),
            hasDrivingExperience: booleanHelperFunctions.parseBoolean(nurseAdditionalQuestions?.hasDrivingExperience),
            hasBathingExperience: booleanHelperFunctions.parseBoolean(nurseAdditionalQuestions?.hasBathingExperience),
            hasFemalesExperience: booleanHelperFunctions.parseBoolean(nurseAdditionalQuestions?.hasFemalesExperience),
            hasSmokingExperience: booleanHelperFunctions.parseBoolean(nurseAdditionalQuestions?.hasSmokingExperience),
            wantLongTermPosition: booleanHelperFunctions.convertToString(nurseAdditionalQuestions?.wantLongTermPosition),
            hasAlzheimerExperience: booleanHelperFunctions.parseBoolean(nurseAdditionalQuestions?.hasAlzheimerExperience),
            workMotivationReason: stringHelperFunctions.sanitize(nurseAdditionalQuestions?.workMotivationReason),
            smokingFrequencyPerDay: numberHelperFunctions.convertToIntegerString(nurseAdditionalQuestions?.smokingFrequencyPerDay),
            longTermPositionMaxDays: numberHelperFunctions.convertToIntegerString(nurseAdditionalQuestions?.longTermPositionMaxDays),
            hasHoyerLiftExperience: booleanHelperFunctions.parseBoolean(nurseAdditionalQuestions?.hasHoyerLiftExperience),
            hasGaitBeltExperience: booleanHelperFunctions.parseBoolean(nurseAdditionalQuestions?.hasGaitBeltExperience),
            anyOtherExperienceDetails: stringHelperFunctions.sanitize(nurseAdditionalQuestions?.anyOtherExperienceDetails),
            workedForOurCompanyBefore: booleanHelperFunctions.convertToString(nurseAdditionalQuestions?.workedForOurCompanyBefore),
            hasIncontinenceExperience: booleanHelperFunctions.parseBoolean(nurseAdditionalQuestions?.hasIncontinenceExperience),
            hasCompanionshipExperience: booleanHelperFunctions.parseBoolean(nurseAdditionalQuestions?.hasCompanionshipExperience),
            hasTransferAssistExperience: booleanHelperFunctions.parseBoolean(nurseAdditionalQuestions?.hasTransferAssistExperience),
            dependableTransportAvailable: booleanHelperFunctions.convertToString(nurseAdditionalQuestions?.dependableTransportAvailable),
            deniedAccessToMedicaidBenefits: booleanHelperFunctions.convertToString(nurseAdditionalQuestions?.deniedAccessToMedicaidBenefits),
        },
    };
};
export default connect(mapStateToProps)(
    reduxForm({form: "AdditionalQuestions", enableReinitialize: true})(AdditionalQuestions)
);
