import React, { useEffect, useState } from "react";
import CustomDropDownField from "../../../sharedComponents/reduxfields/customDropDown";
import { required } from "../../../helpers/validation";
import CustomInputField from "../../../sharedComponents/reduxfields/customInputField";
import { useDispatch, useSelector } from "react-redux";
import { HttpsAction } from "../../../commonApiFunction/httpsAction";
import { Field, reduxForm, reset } from "redux-form";
import {getNurseAction} from "../../../services/NursesProfile";
import {getSpecialtiesList} from "../../../services/commonServices";
import {useQueryClient} from "@tanstack/react-query";

const SearchFilter = ({ onOptionChange, onSkillsChanged,
  clearFilter, onChangeRange, onAddressRange, 
  handleChangeProfessionList, selectedProfession,
  handleChangeFromDate, handleChangeToDate,
 }) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const stateData = useSelector((state) => state.common ?? []);

  const languagesArray = queryClient.getQueryData(['list-languages']);
  const professionsArray = queryClient.getQueryData(['list-professions']);
  const statesArray = queryClient.getQueryData(['list-provinces-states', 233]);
  const employmentTypesArray = queryClient.getQueryData(['list-employment-types']);
  const workExperienceYearsArray = queryClient.getQueryData(['list-experience-years']);
  const shiftTimingsArray = queryClient.getQueryData(['list-shift-timings']);

  const [selectedRange, setSelectedRange] = useState(5);
  // const [filteredSpecialties, setFilteredSpecialties] = useState([]);

  const handleRangeChange = (e) => {
    setSelectedRange(e.target.value);
    onChangeRange(e);
  }

  const handleClearAll = () => {
    dispatch(reset("searchFilterForm"));
    clearFilter({maxRate: 5});
    setSelectedRange(5)
  };

  useEffect(() => {
    if (selectedProfession != null && +selectedProfession > 1) {
      dispatch(getSpecialtiesList(selectedProfession));
    }
  }, [selectedProfession]);

  return (
    <div className="nurse-details-filter-right ">
      <div className="search-filters py-4 px-3">
        <div className="title-content d-flex align-items-center justify-content-between">
          <h6>Search Filters</h6>
          <button className="" onClick={() => handleClearAll()}>
            Clear all
          </button>
        </div>
        <div className="form-group">
          <div className="col-12 col-sm-12 search-selecter">
            <label>Profession</label>
            <Field
              className="form-control"
              name="classId"
              component={CustomDropDownField}
              onChange={handleChangeProfessionList}
            >
              <option value="">Please Select</option>
              {professionsArray?.map((res, index) => {
                return (
                  <option key={index} value={res.id}>
                    {res.name}
                  </option>
                );
              })}
            </Field>
          </div>
        </div>
        <div className="form-group">
          <div className="col-12 col-sm-12 search-selecter">
            <label>Specialties</label>
            <Field
              className="form-control"
              name="skillId"
              component={CustomDropDownField}
              validate={required}
              onChange={onSkillsChanged}
            >
              <option value="">Please Select</option>
              {stateData.specialtiesList?.map((res, index) => {
                return (
                  <option key={index} value={res.id}>
                    {res.name}
                  </option>
                );
              })}
            </Field>
          </div>
        </div>
        <div className="form-group">
          <div className="row">
            <div className="col-12 search-selecter">
              <Field
                className="form-control mk-wi"
                name="location"
                label="Desired Location"
                type="text"
                component={CustomInputField}
                onChange={onAddressRange}
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="col-12 col-sm-12 search-selecter">
            <label>Experience (Year)</label>
            <Field
              className="form-control"
              name="experienceYears"
              component={CustomDropDownField}
              onChange={onOptionChange}
            >
              <option value="">Please Select</option>
              {workExperienceYearsArray?.map((res, index) => {
                return (
                  <option key={index} value={res.id}>
                    {res.name}
                  </option>
                );
              })}
            </Field>
          </div>
        </div>
        <div className="form-group">
          <div className="col-12 col-sm-12 search-selecter">
            <label>License State</label>
            <Field
              className="form-control"
              name="drivingLicenseProvinceOrStateId"
              component={CustomDropDownField}
              onChange={onOptionChange}
            >
              <option value="">Please Select</option>
              {statesArray?.map((res, index) => {
                return (
                  <option key={index} value={res.id}>
                    {res.name}
                  </option>
                );
              })}
            </Field>
          </div>
        </div>
        <div className="form-group">
          <div className="col-12 col-sm-12 search-selecter">
            <label>Employment Type</label>
            <Field
              className="form-control"
              name="payRateTypeId"
              component={CustomDropDownField}
              onChange={onOptionChange}
            >
              <option value="">Please Select</option>
              {employmentTypesArray?.map((res, index) => {
                return (
                  <option key={index} value={res.id}>
                    {res.name}
                  </option>
                );
              })}
            </Field>
          </div>
        </div>
        {/* Rate */}
        <div className="form-group">
          <div className="col-12 search-selecter">
            <label>Rate</label>
            <div className="d-flex gap-1 align-items-center w-100">
              {selectedRange &&
                <div 
                // style={{
                //   position: 'absolute', 
                //   transform: 'translateX(-50%)'
                // }}
                >
                  {`${selectedRange}`}
                </div>}
              <input
                type="range"
                className="form-control-range mk-wi w-100 custom-range"
                name="maxRate"
                min="5"
                max="500"
                step="5"
                onChange={handleRangeChange}
                title={`Current range: $${selectedRange}`}
                aria-label={`Current range: $${selectedRange}`}
                value={selectedRange}
              />
            </div>
            <div className="price-range d-flex justify-content-between mt-2">
              <span>$5</span>
              <span>$500+</span>
            </div>
          </div>
        </div>

        <div className="form-group">
          <div className="col-12 search-selecter">
            <label>Date Range</label>
            <div className="d-flex gap-1 align-items-center search-selecter">
              <Field
                className="form-control mk-wi"
                name="startDate"
                type="date"
                component={CustomInputField}
                onChange={handleChangeFromDate}
              ></Field>
              <span className="text-muted">-</span>

              <Field
                className="form-control mk-wi"
                name="endDate"
                type="date"
                component={CustomInputField}
                onChange={handleChangeToDate}
              ></Field>
            </div>
          </div>
        </div>
        <div className="form-group">
          <div className="col-12 col-sm-12 search-selecter">
            <label>Preferred Shift Timings</label>
            <Field
              className="form-control"
              name="shiftTimingsId"
              component={CustomDropDownField}
              onChange={onOptionChange}
            >
              <option value="">Please Select</option>
              {shiftTimingsArray?.map((res, index) => {
                return (
                  <option key={index} value={res.id}>
                    {res.name}
                  </option>
                );
              })}
            </Field>
          </div>
        </div>
        <div className="form-group">
          <div className="col-12 col-sm-12 search-selecter">
            <label>Preferred Language</label>
            <Field
              className="form-control"
              name="languageId"
              component={CustomDropDownField}
              onChange={onOptionChange}
            >
              <option value="">Please Select</option>
              {languagesArray?.map((res, index) => {
                return (
                  <option key={index} value={res.id}>
                    {res.name}
                  </option>
                );
              })}
            </Field>
          </div>
        </div>
      </div>
    </div>
  );
};

// Decorate the SearchFilter component with reduxForm
export default reduxForm({form: "searchFilterForm",})(SearchFilter);
