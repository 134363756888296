import React from 'react';
import HealthcareInformation from "../Agency/components/Client/ClientProfile/Healthcare/HealthcareInformation";
import ExtraInformation from "../Agency/components/Client/ClientProfile/Healthcare/ExtraInformation";
import Medications from "../Agency/components/Client/ClientProfile/Healthcare/Medications";
import Allergies from "../Agency/components/Client/ClientProfile/Healthcare/Allergies";
import classes from "../Agency/components/Client/ClientProfile/CareNote/CareNote.module.css";
import {Stack} from "@mui/material";
import {NavLink, Outlet, useOutletContext} from "react-router-dom";

const healthcareTabsList = [
  {
    text: 'Basic Information',
    path: 'basic-info',
    // component: <HealthcareInformation handleSelectTab={handleSelectTab}/>
  }, {
    text: 'Extra Information',
    path: 'extra-info',
    // component: <ExtraInformation handleSelectTab={handleSelectTab}/>
  }, {
    text: 'Medications',
    path: 'medications',
    // component: <Medications handleSelectTab={handleSelectTab}/>
  }, {
    text: 'Allergies',
    path: 'allergies',
    // component: <Allergies handleSelectTab={handleSelectTab}/>
  }
];


const HealthcareLayout = () => {
  const pathPrefix = '/agency/clients/edit/healthcare';

  return (
    <div className={classes.contactMain}>
      <div className={classes.contactFormMain}>
        <div className="d-flex gap-2">
          {(healthcareTabsList || []).map(({text, path}, index) => (
            <React.Fragment key={index}>
              <NavLink to={`${pathPrefix}/${path}`} className="btn btn-sm px-1 patient-profile-personal-info-tab-link neo1" key={index}>{text}</NavLink>
            </React.Fragment>
          ))}
        </div>
        <Stack sx={{marginTop: '50px'}}>
          <Outlet context={useOutletContext()} />
        </Stack>
      </div>
    </div>
  );
};

export default HealthcareLayout;
