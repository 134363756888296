import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {connect} from "react-redux";

const PersonalInfoTabButton = ({currentParamsId, buttonNavId, text, navigate, isAdmin, adminLink, agencyLink, buttonViewableByAdmin = true, buttonViewableByAgency = true}) => {
  if (isAdmin && !buttonViewableByAdmin) return null;
  if (!isAdmin && !buttonViewableByAgency) return null;
  return (
    <button type="button" className={`btn btn-sm px-1${currentParamsId === buttonNavId ? ' btn-primary' : ''}`}
            onClick={() => navigate(isAdmin ? adminLink : agencyLink)}>{text}</button>
  );
};

const PersonalInfoInnerTabs = ({isAdmin}) => {
  const showAgreementTab = isAdmin || false;

  const params = useParams();
  const navigate = useNavigate();

  // const isAdmin = window.location.pathname.includes('/admin/agencyNurses/edit');

  return (
    <div className="d-flex gap-2 profile-tabs">
      {/*<button
        type="button"
        className={`btn ${params.id === "profile" && "btn-primary"} btn-sm`}
                onClick={() =>
                    navigate(isAdmin ? `/admin/agencyNurses/edit/${params.nurseId}/profile` : `/agency/nurses/edit/${params.nurseId}/profile`)
                }
            >
                Personal Info
            </button>*/}
      <PersonalInfoTabButton navigate={navigate} currentParamsId={params.id} buttonNavId="profile" text="Personal Info" isAdmin={isAdmin}
                             adminLink={`/admin/agencyNurses/edit/${params.nurseId}/profile`} agencyLink={`/agency/nurses/edit/${params.nurseId}/profile`}/>
          {/*{!isAdmin && <button
            type="button"
            className={`btn ${
              params.id === "accountdetails" && "btn-primary"
            } btn-sm`}
            onClick={() =>
              navigate(isAdmin ? `/admin/agencyNurses/edit/${params.nurseId}/accountdetails` : `/agency/nurses/edit/${params.nurseId}/accountdetails`)
            }
          >
            Account Details
          </button>}*/}
      <PersonalInfoTabButton navigate={navigate} currentParamsId={params.id} buttonNavId="accountdetails" text="Account Details" isAdmin={isAdmin}
                             agencyLink={`/agency/nurses/edit/${params.nurseId}/accountdetails`} buttonViewableByAdmin={false} />
      {/*<button
                type="button"
                className={`btn ${params.id === "mailinfo" && "btn-primary"} btn-sm`}
                onClick={() =>
                    navigate(isAdmin ? `/admin/agencyNurses/edit/${params.nurseId}/mailinfo` : `/agency/nurses/edit/${params.nurseId}/mailinfo`)
                }
            >
                Mailing Info
            </button>*/}
      <PersonalInfoTabButton navigate={navigate} currentParamsId={params.id} buttonNavId="mailinfo" text="Mailing Info" isAdmin={isAdmin}
                             adminLink={`/admin/agencyNurses/edit/${params.nurseId}/mailinfo`} agencyLink={`/agency/nurses/edit/${params.nurseId}/mailinfo`} />
            {/*<button
                type="button"
                className={`btn ${
                    params.id === "workexperience" && "btn-primary"
                } btn-sm`}
                onClick={() =>
                    navigate(isAdmin ? `/admin/agencyNurses/edit/${params.nurseId}/workexperience` : `/agency/nurses/edit/${params.nurseId}/workexperience`)
                }
            >
                Work Experience
            </button>*/}
      <PersonalInfoTabButton navigate={navigate} currentParamsId={params.id} buttonNavId="workexperience" text="Work Experience" isAdmin={isAdmin}
                             adminLink={`/admin/agencyNurses/edit/${params.nurseId}/workexperience`} agencyLink={`/agency/nurses/edit/${params.nurseId}/workexperience`} />
            {/*{isAdmin && <button
                type="button"
                className={`btn ${
                    params.id === "jobPreferences" && "btn-primary"
                } btn-sm`}
                onClick={() =>
                    navigate(`/admin/agencyNurses/edit/${params.nurseId}/jobPreferences`)
                }
            >
                Job Preferences
            </button>}*/}
      <PersonalInfoTabButton navigate={navigate} currentParamsId={params.id} buttonNavId="jobPreferences" text="Job Preferences" isAdmin={isAdmin}
                             adminLink={`/admin/agencyNurses/edit/${params.nurseId}/jobPreferences`} buttonViewableByAgency={false} />
            {/*<button
                type="button"
                className={`btn ${params.id === "education" && "btn-primary"} btn-sm`}
                onClick={() =>
                    navigate(isAdmin ? `/admin/agencyNurses/edit/${params.nurseId}/education` : `/agency/nurses/edit/${params.nurseId}/education`)
                }
            >
                Education
            </button>*/}
      <PersonalInfoTabButton navigate={navigate} currentParamsId={params.id} buttonNavId="education" text="Education" isAdmin={isAdmin}
                             adminLink={`/admin/agencyNurses/edit/${params.nurseId}/education`} agencyLink={`/agency/nurses/edit/${params.nurseId}/education`} />
            {/*<button
                type="button"
                className={`btn px-1 ${
                    params.id === "additionalQuestions" && "btn-primary"
                } btn-sm`}
                onClick={() =>
                    navigate(isAdmin ? `/admin/agencyNurses/edit/${params.nurseId}/additionalQuestions` : `/agency/nurses/edit/${params.nurseId}/additionalQuestions`)
                }
            >
                Additional Questions
            </button>*/}
      <PersonalInfoTabButton navigate={navigate} currentParamsId={params.id} buttonNavId="additionalQuestions" text="Additional Questions" isAdmin={isAdmin}
                             adminLink={`/admin/agencyNurses/edit/${params.nurseId}/additionalQuestions`} agencyLink={`/agency/nurses/edit/${params.nurseId}/additionalQuestions`} />
            {/*<button
                type="button"
                className={`btn px-1 ${
                    params.id === "emergencyContacts" && "btn-primary"
                } btn-sm`}
                onClick={() =>
                    navigate(isAdmin ? `/admin/agencyNurses/edit/${params.nurseId}/emergencyContacts` : `/agency/nurses/edit/${params.nurseId}/emergencyContacts`)
                }
            >
                Emergency Contacts
            </button>*/}
      <PersonalInfoTabButton navigate={navigate} currentParamsId={params.id} buttonNavId="emergencyContacts" text="Emergency Contacts" isAdmin={isAdmin}
                             adminLink={`/admin/agencyNurses/edit/${params.nurseId}/emergencyContacts`} agencyLink={`/agency/nurses/edit/${params.nurseId}/emergencyContacts`} />
            {/* <button
        type="button"
        className={`btn ${params.id === "intovideo" && "btn-primary"} btn-sm`}
        onClick={() =>
          navigate(`/agency/nurses/edit/${params.nurseId}/intovideo`)
        }
      >
        Into Video
      </button> */}
          {/*{showAgreementTab && <button
            type="button"
            className={`btn ${params.id === "agreement" && "btn-primary"} btn-sm`}
            onClick={() =>
              navigate(isAdmin ? `/admin/agencyNurses/edit/${params.nurseId}/agreement` : `/agency/nurses/edit/${params.nurseId}/agreement`)
            }
          >
            Agreement
          </button>}*/}
      {showAgreementTab && <PersonalInfoTabButton navigate={navigate} currentParamsId={params.id} buttonNavId="agreement" text="Agreement" isAdmin={isAdmin}
                              adminLink={`/admin/agencyNurses/edit/${params.nurseId}/agreement`} agencyLink={`/agency/nurses/edit/${params.nurseId}/agreement`}/>}
        </div>
    );
};

const mapStateToProps = (state) => {
  const { auth } = state;

  return {
    isAdmin: auth?.authData?.isAdmin || false,
    agencyId: auth?.authData?.agencyId || auth.agencyId,
  };
};

export default connect(mapStateToProps)(PersonalInfoInnerTabs);
// export default PersonalInfoInnerTabs;
