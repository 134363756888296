import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import CustomInputField from "../../../../sharedComponents/fields/customInputField";
import PrimaryButton from "../../../../sharedComponents/buttons/PrimaryButton";
import {connect, useDispatch, useSelector} from "react-redux";
import {Field, formValueSelector, reduxForm, initialize, change} from "redux-form";
import CustomRadioGroup from "../../../../sharedComponents/reduxfields/customRadioGroup";
import {HttpsAction} from "../../../../commonApiFunction/httpsAction";
import CustomDropDownField from "../../../../sharedComponents/fields/customDropDown";
import {useQueryClient} from "@tanstack/react-query";
import booleanHelperFunctions from "../../../../helpers/boolean.helpers";
import numberHelperFunctions from "../../../../helpers/number.helpers";
import stringHelperFunctions from "../../../../helpers/string.helpers";

const radioOptions = [
    {label: "Yes", id: "yes", value: "true"},
    {label: "No", id: "no", value: "false"},
];

const Certificationcomnplete = ({handleSubmit, type, index, formData, initialValues, onClose}) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {nurseId} = useParams();

    // const certificationsList = useSelector(
    //     (state) => {
    //         if (state != null && state.nurseListing != null && state.nurseListing.nurseCertifications != null) {
    //             console.log('submit- state.nurseListing.nurseCertifications', state.nurseListing.nurseCertifications);
    //         }
    //         return state?.nurseListing?.nurseCertifications || [];
    //     }
    // );
    //
    // const evaluationsList = useSelector(
    //     (state) => {
    //         if (state != null && state.nurseListing != null && state.nurseListing.nurseEvaluations != null) {
    //             console.log('submit- state.nurseListing.nurseEvaluations', state.nurseListing.nurseEvaluations);
    //         }
    //         return state?.nurseListing?.nurseEvaluations || [];
    //     }
    // );
    //
    // const clickIfComplete = useSelector(state => state.nurseListing?.clickIfComplete);

    const reminderDaysArray = queryClient.getQueryData(['list-reminder-days']);

    const [disable, setDisable] = useState(true);
    const [loadedOnce, setLoadedOnce] = useState(false);

//     const handleOk = (data) => {
// // console.log('test- handleOk', data)
//         if (type === "certifications" && !disable) {
//             if (certificationsList.length) {
//                 const clonedCertificationsList = [...certificationsList];
//
//                 if (clonedCertificationsList[index - 1]) {
//                     clonedCertificationsList[index - 1] = {
//                         ...clonedCertificationsList[index - 1],
//                         renewalRequired: true,
//                         renewalDate: data?.renewalDate,
//                         renewalReminderDaysId: +data?.renewalReminderDaysId
//                     };
//
//                     // console.log('test- clonedCertificationsList', clonedCertificationsList);
//                     dispatch({
//                         type: 'ADD_NURSE_ADD_CHECK_IF_COMPLETED_DATA',
//                         payload: clonedCertificationsList,
//                         name: 'nurseCertifications',
//                         meta: {type: 'ADD_CHECK_IF_COMPLETED_DATA'},
//                     });
//                     onClose();
//                 }
//             }
//         }
//
//         if (type === "evaluations" && !disable) {
//             if (evaluationsList.length) {
//                 const clonedEvaluationsList = [...evaluationsList];
//
//                 if (clonedEvaluationsList[index - 1]) {
//                     clonedEvaluationsList[index - 1] = {
//                         ...clonedEvaluationsList[index - 1],
//                         renewalRequired: true,
//                         renewalDate: data?.renewalDate,
//                         renewalReminderDaysId: +data?.renewalReminderDaysId
//                     };
//
//                     dispatch({
//                         type: 'ADD_NURSE_ADD_CHECK_IF_COMPLETED_DATA',
//                         payload: clonedEvaluationsList,
//                         name: 'nurseEvaluations',
//                         meta: {type: 'ADD_CHECK_IF_COMPLETED_DATA'},
//                     });
//                     onClose();
//                 }
//             }
//         }
//
//         if (type === "otherCertifications" && !disable) {
//             if (evaluationsList.length) {
//                 const clonedEvaluationsList = [...evaluationsList];
//
//                 if (clonedEvaluationsList[index - 1]) {
//                     clonedEvaluationsList[index - 1] = {
//                         ...clonedEvaluationsList[index - 1],
//                         renewalRequired: true,
//                         renewalDate: data?.renewalDate,
//                         renewalReminderDaysId: +data?.renewalReminderDaysId
//                     };
//
//                     dispatch({
//                         type: 'ADD_NURSE_ADD_CHECK_IF_COMPLETED_DATA',
//                         payload: clonedEvaluationsList,
//                         name: 'nurseOtherCertifications',
//                         meta: {type: 'ADD_CHECK_IF_COMPLETED_DATA'},
//                     });
//                     onClose();
//                 }
//             }
//         }
//         onClose()
//     };

    const onSubmit = (data) => {
// console.log('test temp-form data', data)
        onClose({...data, renewalRequired: data.renewalRequired === "true"});
    };

    const handleSelectRadioButton = (target) => {
// console.log('test- target value', typeof target.value, target.value);
        setDisable(target.value === "false");
        dispatch(change("CertificationCompleteTempForm", 'renewalRequired', target.value));
    };

    // useEffect(() => {
    //     if (clickIfComplete) {
    //         setDisable(clickIfComplete.renewalRequired === 'false');
    //     }
    // }, [clickIfComplete]);

    useEffect(() => {
// console.log('test- initialValues', initialValues);
        if (!loadedOnce && initialValues != null && JSON.stringify(initialValues) !== '{}') {
            setLoadedOnce(true);
            setDisable((initialValues?.renewalRequired || 'false') === 'false');
        }
    }, [initialValues]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Field
                  name="index"
                  type="hidden"
                  component={CustomInputField}
                />
                <Field
                  name="id"
                  type="hidden"
                  component={CustomInputField}
                />
                <div className="row">
                    <div className="col-12">
                        <div className="mb-4 input-field-main">
                            <label>
                                Does this license or evaluation need to be renewed at the future date
                            </label>
                            <Field
                                name="renewalRequired"
                                component={CustomRadioGroup}
                                options={radioOptions}
                                onChange={({target}) => handleSelectRadioButton(target)}
                            />
                        </div>
                    </div>
                    {!disable && <div className="col-12">
                        <div className="mb-4 input-field-main">
                            <Field
                                className="form-control mk-wi"
                                name="renewalDate"
                                label="Renewal Date"
                                type="date"
                                component={CustomInputField}
                                readOnly={disable}
                            />
                        </div>
                    </div>}

                    {!disable && <div className="col-12">
                        <div className="mb-4 input-field-main">
                            <label htmlFor="fname" className="form-label">Reminder Days</label>
                            <Field
                                className="form-control mk-wi"
                                name="renewalReminderDaysId"
                                label="Reminder Date"
                                type="date"
                                component={CustomDropDownField}
                                readOnly={disable}
                            >
                                <option value="">Select Reminder Days</option>
                                {(reminderDaysArray || []).map((res, index) => (
                                    <option key={index} value={res.id}>
                                        {res.name}
                                    </option>
                                ))}
                            </Field>
                        </div>
                    </div>}
                </div>
                <div
                    className="d-flex justify-content-center"
                    style={{marginTop: "40px"}}
                >
                    <PrimaryButton
                      className="btn-view-repo gap-2 px-5 py-2"
                      // type="button"
                      // onClick={() => handleOk(formData)}
                      onClick={() => undefined}
                      type="submit"
                      text={"Ok"}
                    />
                </div>
            </form>
        </>
    );
};

const mapStateToProps = (state, ownerProps) => {
    const checkIfCompleteData = ownerProps.inputData;
// console.log('test- checkIfCompleteData', checkIfCompleteData)
    // const selector = formValueSelector('CertificationCompleteTempForm');
    // const selector = formValueSelector(ownerProps.formName);
    return {
        // reminderDaysArray: common.reminderDaysList || [],
        // formData: selector(state, 'renewalRequired', 'renewalDate', 'renewalReminderDaysId'),
        initialValues: {
            // renewalDate: state.nurseListing?.clickIfComplete?.renewalDate,
            // renewalReminderDaysId: state.nurseListing?.clickIfComplete?.renewalReminderDaysId?.toString(),
            // renewalRequired: state.nurseListing?.clickIfComplete?.renewalRequired ? '1' : '2',
            renewalDate: stringHelperFunctions.sanitize(checkIfCompleteData.renewalDate),
            renewalReminderDaysId: numberHelperFunctions.convertToIntegerString(checkIfCompleteData.renewalReminderDaysId),
            renewalRequired: booleanHelperFunctions.convertToString(checkIfCompleteData.renewalRequired),
            id: numberHelperFunctions.convertToIntegerString(checkIfCompleteData.id),
            index: numberHelperFunctions.convertToIntegerString(checkIfCompleteData.index),
        }
    }
};

export default connect(mapStateToProps)(
    reduxForm({form: "CertificationCompleteTempForm", enableReinitialize: true})(Certificationcomnplete)
);
// export default connect(mapStateToProps)(Certificationcomnplete);
// export default Certificationcomnplete;
// export default (
//     reduxForm({form: "CertificationsForm", enableReinitialize: true})(Certificationcomnplete)
// );
