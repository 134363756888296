import React, {useEffect, useState} from "react";
import {Stack} from "@mui/material";
import classes from "./TaskDetails.module.css"
import PrimaryButton from "../../../sharedComponents/buttons/PrimaryButton";
import deleteIcon from "../../assets/image/delete-blue.svg";
import {useParams} from "react-router-dom";
import Details from './Details'
import Financial from "./Financial";
import {connect, useDispatch, useSelector} from "react-redux";
import {HttpsAction} from "../../../commonApiFunction/httpsAction";
import Activity from "./Activity";
import CareNotes from "./CareNotes";
import WarningModal from "../../../sharedComponents/warningModal";
import {getFormValues} from "redux-form";
import {toastr} from "react-redux-toastr";
import Directions from "./Directions";

const TASK_STATUS_ID = [
    {
        "id": 1,
        "name": "Pending",
    },
    {
        "id": 2,
        "name": "Scheduled",
    },
    {
        "id": 5,
        "name": "Cancelled/Missed",
    }
];

const TaskDetails = ({onClose, taskIdToEdit, showingDataFor, taskData}) => {

    // const {tabId, id, clientId} = useParams();
    const dispatch = useDispatch();
    // const taskDetails = useSelector(state => state.client?.taskDetails?.data);
    const detailsFormValues = useSelector(state => getFormValues('details')(state));

    const [warningModal, setWarningModal] = useState(false);
    const [taskDataLoaded, setTaskDataLoaded] = useState(false);
    const [taskActivitiesList, setTaskActivitiesList] = useState([]);
    const [activitiesLoaded, setActivitiesLoaded] = useState(false);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [activitiesModified, setActivitiesModified] = useState(false);
    const [taskModified, setTaskModified] = useState(false);
    const [taskIdIsValid, setTaskIdIsValid] = useState(false);
    const [loadCalledOnce, setLoadCalledOnce] = useState(false);
    const [initialDataLoaded, setInitialDataLoaded] = useState(false);

    const updateActivitiesList = (modifiedActivitiesList) => {
        const listCopy = [...taskActivitiesList];
        setTaskActivitiesList(listCopy.concat(modifiedActivitiesList));
        setActivitiesModified(true);
    };

    const removeActivity = (id) => {
        const listWithItemRemoved = taskActivitiesList.filter(item => item.id !== id);
        setTaskActivitiesList(listWithItemRemoved);
        setActivitiesModified(true);
    };

    const handleSelectTab = (_, i) => {
        // if (taskIdToEdit > 0 && taskData != null) {
        if (taskData != null) {
            setSelectedTabIndex(i);
            setTabs(prevState => prevState.map((v, index) => {
                return index === i ? {...v, select: true} : {...v, select: false}
            }));
        }
    }

    const TABS_ARRAY = [
        {
            name: "Details",
            id: 'details',
            // component: (taskInfo) => <Details taskDetails={taskInfo} showingDataFor={showingDataFor} handleSelectTab={handleSelectTab} reload={() => reload()} />,
            select: true
        },
        {
            name: "Financial",
            id: 'financial',
            // component: (taskInfo) => <Financial taskDetails={taskInfo} handleSelectTab={handleSelectTab} />,
            select: false
        },
        {
            name: "Care Notes",
            id: 'careNotes',
            // component: (taskInfo, activities) => <CareNotes taskDetails={taskInfo} taskActivitiesList={activities} showingDataFor={showingDataFor} reload={() => reload()} updateActivitiesList={updateActivitiesList} removeActivity={removeActivity} />,
            select: false
        },
        {
            name: "Activity",
            id: 'activity',
            // component: (taskInfo) => <Activity taskDetails={taskInfo} handleSelectTab={handleSelectTab} />,
            select: false
        },
        {
            name: "Directions",
            id: 'directions',
            // component: <Directions handleSelectTab={handleSelectTab}/>,
            select: false
        },
        /*{
            name: "History",
            id: 'history',
            component: <History />,
            select: false
        }*/];

    const [tabs, setTabs] = useState(TABS_ARRAY);


    const handleDelete = async () => {
        const message = "Are you sure you want to delete this service request ?"

        // switch (tabId) {
        //     case `details`:
        if (taskData?.id) {
            setWarningModal(message);
        }

        // break;
        // default : {
        // }
        // }
    };
    const handleDisagree = () => {
        setWarningModal(false);
    };

    const handleAgree = async () => {
        await dispatch(HttpsAction({
            method: 'DELETE',
            apiSection: 'client',
            apiName: 'taskDetails',
            queryParameter: `/${taskData.id}`
        }));
        // const toolbarTitle = document.getElementsByClassName(`fc-toolbar-title`)
        // const title = toolbarTitle[0].outerText.split(" ")

        // dispatch(HttpsAction({
        //     apiSection: 'client',
        //     apiName: 'serviceRequestTasks',
        //     queryParameter: `?patientId=${clientId}&calYear=${title[1]}&calMonth=${MONTHS.find(v => v.month === title[0]).id}&doPaging=false`,
        //     reducerName: 'CLIENT',
        //     actionType: 'GET_CALENDAR_SERVICE_REQUEST_TASKS',
        //     reduxKeyName: 'calendarEvent'
        // }));
        onCloseModal();
    };

    const onCloseModal = () => {
        if (onClose != null) {
            onClose(taskModified);
        } else {
            dispatch({
                type: `ADD_CLIENT_UPDATE_SERVICE_DETAILS_MODAL_FLAG`,
                payload: false,
                name: `isServiceDetails`,
                meta: {type: `UPDATE_SERVICE_DETAILS_MODAL_FLAG`},
            });
        }
    };

    const reload = async () => {
        if (taskIdToEdit > 0) {
            dispatch(HttpsAction({
                actionType: `GET_TASK_DETAILS`,
                reduxKeyName: 'taskDetails',
                reducerName: 'SERVICE_REQUEST',
                removeData: true,
                positiveCallBack: () => {
                    setTimeout(() => {
                        dispatch(HttpsAction({
                            apiSection: 'serviceRequests',
                            apiName: 'taskDetails',
                            queryParameter: `/${taskIdToEdit}`,
                            actionType: `GET_TASK_DETAILS`,
                            reduxKeyName: 'taskDetails',
                            reducerName: 'SERVICE_REQUEST',
                        }));
                    }, 500);
                },
            }));
        }
    };

    const submit = (payload) => {
// console.log('test- detailsFormValues', detailsFormValues)
        if (payload == null) { // saving from care notes tab
            payload = {
                serviceRequestTypeId: +detailsFormValues.serviceRequestTypeId,
                patientId: +detailsFormValues.patientId,
                assignedNurseId: +detailsFormValues.nurseId,
                taskDate: detailsFormValues.startDate,
                scheduledStartTime: detailsFormValues.scheduledStartTime,
                scheduledEndTime: detailsFormValues.scheduledEndTime,
            };
        } // else - saving from details tab
        if (activitiesModified) {
            payload['activityIdsList'] = taskActivitiesList.map(a => a.id);
        }

        dispatch(HttpsAction({
            method: 'PUT',
            queryParameter: `/${taskIdToEdit}`,
            apiSection: 'serviceRequests',
            apiName: 'taskDetails',
            data: payload,
            positiveCallBack: ({data}) => {
                setTaskModified(true);
                reload();
                toastr.success(data.message);
            }
        }));
    };

    useEffect(() => {
        // alert(JSON.stringify(taskData))
        // alert(taskIdToEdit)
        // alert((taskData == null || JSON.stringify(taskData) === '{}') && taskIdToEdit > 0)
        if (!loadCalledOnce && (taskData == null || JSON.stringify(taskData) === '{}') && taskIdToEdit > 0) {
        // if (taskIdToEdit > 0) {
            setLoadCalledOnce(true);
            // setTaskIdIsValid(true);
            reload();
        }
    // }, [loadCalledOnce, taskData, taskIdToEdit]);
    }, [taskIdToEdit]);

    // useEffect(() => {
    //     if (loadCalledOnce) {
    //         alert('test')
    //         reload();
    //     }
    // }, [loadCalledOnce]);

    // useEffect(() => {
    //     if (taskIdIsValid) reload();
    // }, [taskIdIsValid]);

    useEffect(() => {
        if (!activitiesLoaded && taskData != null && JSON.stringify(taskData) !== '{}') {
            const activitiesList = taskData?.activities || [];
            setTaskActivitiesList(activitiesList.length > 0 ? [...activitiesList] : []);
            setActivitiesLoaded(true);
        }
    }, [taskData]);

    // useEffect(() => {
    //     console.log('test- id > 0')
    //     if (activitiesLoaded) {
    //         setTaskDataLoaded(true);
    //     }
    // }, [activitiesLoaded]);

    // if (!taskDataLoaded) return null;
    // if (!initialDataLoaded) return null;

// console.log('test- activitiesList', taskActivitiesList)
// console.log('test- taskData', taskData)

    return (
        <>
            <div className="app-main__outer" style={{padding: '10px', display: initialDataLoaded ? 'block' : 'none'}}>
                <div className="main-service-request-page">
                    <div className="app-main__inner service-request bg-white">
                        <div className={'row'}>
                            <div className={`col-8 ${classes.header}`}>
                                {/*Task Details (Service Request)*/}
                                Visit Details
                            </div>
                            <div className={`col-4 d-flex justify-content-end`}>
                                <svg onClick={() => onCloseModal()}
                                     style={{cursor: 'pointer'}}
                                     width="40" height="40" viewBox="0 0 40 40" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_1880_67785)">
                                        <circle cx="20" cy="20" r="20" fill="#F2F2F2"/>
                                        <path
                                          d="M13.9219 26.0796L20.0007 20.0007M26.0796 13.9219L19.9996 20.0007M19.9996 20.0007L13.9219 13.9219M20.0007 20.0007L26.0796 26.0796"
                                          stroke="#4F4F4F" strokeWidth="1.73913" strokeLinecap="round"
                                          strokeLinejoin="round"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1880_67785">
                                            <rect width="40" height="40" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            {/*<div className={`col-12 d-flex justify-content-end mt-3`}>
                                <Stack direction={'row'} justifyContent={'end'}>
                                    {TASK_STATUS_ID.filter(v => taskData?.taskStatusId === v.id).length > 0 && <PrimaryButton
                                      type={'button'}
                                      className={`btn btn-primary border-btn-info ${classes.headerDeleteBtn}`}
                                      onClick={() => undefined}
                                      text={<Stack className={classes.headerDeleteBtnContent} onClick={handleDelete}>
                                          <img src={deleteIcon} alt={''}/>
                                          <Stack className={classes.buttonText}>
                                              Delete
                                          </Stack>
                                      </Stack>}
                                    />}
                                </Stack>
                            </div>*/}
                            <div className={`col-12 mt-3`}>
                                <div className="row align-items-center">
                                    <div className="col">
                                        <ul className="nav nav-tabs pt-0" id="myTab" role="tablist">
                                            {(tabs || [])?.map((value, index) => (
                                                <li className="nav-item" role="presentation" key={index}>
                                                    <button
                                                        className={`${
                                                            value.select === true && "active"
                                                        } nav-link`}
                                                        id="home-tab"
                                                        data-bs-toggle="tab"
                                                        data-bs-target="#tab-1"
                                                        type="button"
                                                        role="tab"
                                                        aria-controls="tab-1"
                                                        aria-selected="true"
                                                        onClick={() => handleSelectTab(value, index)}
                                                    >
                                                        {value.name}
                                                    </button>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <Stack className={classes.underLine}/>
                            </div>
                            {/*{(tabs || [])?.map((value, index) =>
                                <React.Fragment key={index}>
                                    {value.select === true && value?.component(taskData, value.id === 'careNotes' ? taskActivitiesList : undefined)}
                                </React.Fragment>)}*/}
                            {/*{selectedTabIndex === 0 && <Details doUpdateApiCall={(data) => submit(data)} taskDetails={taskData} showingDataFor={showingDataFor} handleSelectTab={(e) => handleSelectTab(e, 0)} reload={() => reload()}/>}*/}
                            <Details show={selectedTabIndex === 0 && activitiesLoaded} setInitialDataLoaded={(val) => setInitialDataLoaded(val)} doUpdateApiCall={(data) => submit(data)} taskDetails={taskData} showingDataFor={showingDataFor} handleSelectTab={(e) => handleSelectTab(e, 0)} reload={() => reload()}/>
                            {/*{selectedTabIndex === 1 && activitiesLoaded && <Financial taskDetails={taskData} handleSelectTab={(e) => handleSelectTab(e, 1)} />}*/}
                            <Financial show={selectedTabIndex === 1 && activitiesLoaded} taskDetails={taskData} handleSelectTab={(e) => handleSelectTab(e, 1)} />
                            {/*{selectedTabIndex === 2 && activitiesLoaded && <CareNotes doUpdateApiCall={(data) => submit(data)} taskDetails={taskData} taskActivitiesList={taskActivitiesList} showingDataFor={showingDataFor} handleSelectTab={(e) => handleSelectTab(e, 2)} reload={() => alert('reload()')} updateActivitiesList={updateActivitiesList} removeActivity={removeActivity} />}*/}
                            <CareNotes show={selectedTabIndex === 2 && activitiesLoaded} doUpdateApiCall={(data) => submit(data)} taskDetails={taskData} isJobRelated={taskData.jobId != null} isChartingRelated={taskData.chartingId != null} taskActivitiesList={taskActivitiesList} showingDataFor={showingDataFor} handleSelectTab={(e) => handleSelectTab(e, 2)} reload={() => reload()} updateActivitiesList={updateActivitiesList} removeActivity={removeActivity} />
                            {/*{selectedTabIndex === 3 && activitiesLoaded && <Activity taskDetails={taskData} handleSelectTab={(e) => handleSelectTab(e, 3)} />}*/}
                            <Activity show={selectedTabIndex === 3 && activitiesLoaded} taskDetails={taskData} isJobRelated={taskData.jobId != null} isChartingRelated={taskData.chartingId != null} handleSelectTab={(e) => handleSelectTab(e, 3)} />
                            {/*{selectedTabIndex === 4 && <Directions handleSelectTab={(e) => handleSelectTab(e, 4)}/>}*/}
                            <Directions show={selectedTabIndex === 4 && activitiesLoaded} taskDetails={taskData} isJobRelated={taskData.jobId != null} isChartingRelated={taskData.chartingId != null} handleSelectTab={(e) => handleSelectTab(e, 4)} />
                        </div>
                    </div>
                </div>
            </div>

            <WarningModal
                open={warningModal}
                desc={warningModal}
                handleAgree={handleAgree}
                handleDisagree={handleDisagree}
                onClose={() => setWarningModal(false)}
            />
        </>
    )
};

const mapStateToProps = (state) => {
    const { serviceRequest } = state;
    return {
        taskData: serviceRequest?.taskDetails?.data || {},
    };
};

export default connect(mapStateToProps)(TaskDetails);
