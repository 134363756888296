import React, {useEffect, useState} from "react";
import {connect, useSelector} from "react-redux";
import Table from "../../../sharedComponents/table/Table";
import {useDispatch} from 'react-redux';
import {getCountedVisits} from '../../../services/serviceRequest'
import withConfirmationDialog from '../../../sharedComponents/confirmModal'
import {HttpsAction} from "../../../commonApiFunction/httpsAction";
import arrayHelperFunctions from "../../../helpers/array.helpers";
import {useLocation, useNavigate, useOutletContext} from "react-router-dom";
import dateHelperFunctions from "../../../helpers/date.helpers";
import rowHelperFunctions from "../../../helpers/row.helpers";
import apiCallHelperFunctions from "../../../helpers/api.call.helpers";
import {chartingTabUrls} from "../../../appConstants";
import CommonPaginationNew from "../../../sharedComponents/pagination/CommonPaginationNew";
import useCustomNav from "../../../utils/hooks/useCustomNav";


const InitialAssessment = ({/*searchText, */supervisoryVisitsList, /*currentPage, */openConfirmationDialog, runConfirmCallback}) => {
    const hideActions = true;
    const backButtonTextSuffix = 'Charting Visits - Supervisory Visits';
    const backLink = '/agency/servicerequest/supervisoryVisits';

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { searchText, setPaginationTab } = useOutletContext();

    const { goTo: gotoSupervisoryVisitsChartingPage } = useCustomNav('/agency/charting/supervisory-visits');
    const { goTo: gotoNurseChartingPage } = useCustomNav('/agency/nurses/edit/profile/charting');
    const { goTo: gotoPatientChartingPage } = useCustomNav('/agency/clients/edit/charting');

    const isServiceDetails = useSelector(state => state.client?.isServiceDetails || false);

    const [serviceDetails, setServiceDetails] = useState({modal: false, id: null});
    const [srIdForDeletion, setSrIdForDeletion] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);

    // const [nurseList, setNurseList] = useState([]);
    // const [isModalOpen, setIsModalOpen] = useState(false);
    // const [clientId, setClientId] = useState('');

    const taskIdToSelect = location?.state != null && location?.state?.taskId != null ? location.state.taskId : 0;
    const taskIdToSelectByChartingId = location?.state != null && location?.state?.chartingId != null ? location.state.chartingId : 0;

    const transformedArray = supervisoryVisitsList?.rows?.map(item => {
        // const nursesList = item?.tasks?.map(t => t.assignedNurseFirstName).filter(arrayHelperFunctions.onlyUnique);
        let highlightRow = false;
        if (taskIdToSelect > 0) highlightRow = taskIdToSelect === item.taskId;
        if (taskIdToSelectByChartingId > 0) highlightRow = taskIdToSelectByChartingId === item.chartingId;
        let taskStatusName = item.taskStatus;
        if (item.isMissed) taskStatusName = 'Missed';
        let { relatedInfoList} = rowHelperFunctions.getRelatedInfo(item, false, true, false);
        return {
            // 'id': item.serviceRequestId,
            'id': item.taskId,
            // 'Recipients': <> {item.patientFirstName} {item.patientMiddleName} {item.patientLastName} </>,
            // 'Recipients': rowHelperFunctions.getClientRowItem(navigate, item.patientId, item.patientFullName, false, backButtonTextSuffix, backLink, `/agency/clients/${item.patientId}/charting/0`),
            'Recipients': rowHelperFunctions.getClientRowItem(navigate, item.patientId, item.patientFullName, false, undefined, undefined, undefined, () => gotoPatientChartingPage({
                id: item.patientId,
                chartingId: item.chartingId
            })),
            'age': item.patientAge,
            // 'serviceRequired':
            //     <div onClick={() => handleServiceDetailsModal(item.id)} style={{cursor: 'pointer'}}>
            //         {item.serviceRequestType}
            //     </div>,
            'serviceRequired': <div className="position-relative1 p-0 m-0 d-flex flex-column gap-1">
                <span className="mt-4">{item.serviceRequestType}</span>
                {/*<div className="position-absolute bottom-12p5 start-50 translate-middle cursor-pointer" onClick={() => {*/}
                <div onClick={() => {
                    // navigate('/agency/charting/supervisorvisit', {
                    //     state: {
                    //         chartingId: item.chartingId,
                    //     },
                    // });
                    gotoSupervisoryVisitsChartingPage({ chartingId: item.chartingId });
                }}><span className={`table-inner-link text-break lh-1`}>{item.chartingTitle}</span></div>
                {(relatedInfoList || []).length > 0 && <div className="position-absolute top-25 start-50 translate-middle cursor-pointer w-100 d-flex justify-content-center gap-1">{relatedInfoList.map(({relatedTextColor, relatedText, relatedLink, relatedState}, index) => <span key={index} onClick={() => {
                    if (relatedLink != null) navigate(relatedLink, { state: relatedState });
                }} className={`badge rounded-pill text-wrap lh-1 text-bg-${relatedTextColor}`}>{relatedText}</span>)}</div>}
            </div>,
            // 'duration': <><span
            //     className="d-block">{item.startDateEndDate}</span><span
            //     className="d-block">{item.expectedTimings}</span></>,
            // 'hours': item.scheduledHoursPerDay,
            // 'duration': <><span
            //   className="d-block">{item.taskDateFormatUs}</span><span
            //   className="d-block" style={{fontSize: '13px', minWidth: '125px'}}>{item.expectedTimings}</span></>,
            // 'hours': item.scheduledHours,
            'duration': <div className="d-flex flex-column gap-0" style={{minWidth: '125px'}}>
                <span className="d-block" style={{fontSize: '14px'}}>{item.taskDateFormatUs}</span>
                <span className="d-block" style={{fontSize: '12px'}}>{item.scheduledTimingsAmPm}</span>
            </div>,
            'hours': dateHelperFunctions.hoursAndMinutesToText(item.totalScheduledHours, item.totalScheduledMinutesLessHours),
            // 'frequencies': item.frequencies,
            'location': item.patientLocation || 'N/A',
            // 'nurseName': nursesList == null || nursesList.length === 0 ? 'N/A' : (nursesList?.length > 1 ? nursesList?.join(',') : nursesList[0]),
            // 'nurseName': rowHelperFunctions.getSingleNurseRowItem(navigate, item?.assignedNurseId, item?.assignedNurseFullName, false, item?.assignedNurseIsFreelance, backButtonTextSuffix, backLink),
            'nurseName': rowHelperFunctions.getSingleNurseRowItem(navigate, item?.assignedNurseId, item?.assignedNurseFullName, false, item?.assignedNurseIsFreelance, undefined, undefined, undefined, () => gotoNurseChartingPage({
                id: item.assignedNurseId,
                chartingId: item.chartingId,
            })),
            'status': <div onClick={() => {
                const url = apiCallHelperFunctions.getVisitsTabUrl(item);
                if (url != null) {
                    navigate(url, {
                        state: {
                            taskId: item.taskId,
                        },
                    });
                }
            }}>
                {/*<span className="table-inner-link text-nowrap">{item.serviceRequestStatus}</span>*/}
                <span className="table-inner-link text-nowrap">{taskStatusName}</span>
            </div>,
            // 'nursesAssigned': item.nursesAssigned
            highlightRow,
        }
    });

    // const openModal = (id) => {
    //   setClientId(id)
    //   fetchData()
    //   setIsModalOpen(true);
    // };

    // const closeModal = () => {
    //   setClientId('')
    //   setIsModalOpen(false);
    // };

    const serviceHeaderData = [
        {
            title: "Recipients",
            dataIndex: "Recipients",
            // align: 'left',
            width: '200px',
        },
        {
            title: "Age",
            dataIndex: "age",
            width: '47px',
        },
        {
            title: "Service Required",
            dataIndex: "serviceRequired",
            width: '150px',
        },
        {
            title: "Duration",
            dataIndex: "duration",
            width: '130px',
        },
        {
            title: "Hours",
            dataIndex: "hours",
            width: '75px',
        },
        // {
        //     title: "Frequency",
        //     dataIndex: "frequencies",
        //     render: (text) => {
        //         return text.map(item => {
        //             const firstThreeLetters = item.frequency.substring(0, 3);
        //             return firstThreeLetters;
        //         }).join(', ');
        //     }
        // },
        {
            title: "Location",
            dataIndex: "location",
        },
        {
            title: "Nurses",
            dataIndex: "nurseName",
            width: '120px',
        },
        {
            title: "Status",
            dataIndex: "status",
            width: '90px',
        },
        // {
        //     title: "",
        //     dataIndex: "Action",
        // }
    ];

    const deleteServiceRequest = id => {
      const message = "Are you sure you want to delete this record ?"
      // const apiEndPoint = "api/delete"
      // openConfirmationDialog(id, message, apiEndPoint);
      setSrIdForDeletion(id);
      openConfirmationDialog(message);
    }

    const actionButton = [
      // {
      //   actionName:'Assign Nurse',
      //   buttonAction: (id) => openModal(id),
      //   actionClass: 'btn-primary',
      //   iconClass:'fa-user-plus',
      // },
      {
        actionName:'Delete',
        buttonAction: (id) => deleteServiceRequest(id),
        actionClass: 'btn-danger',
        iconClass:'fa-trash'
      }
    ]

    // const fetchData = async () => {
    //   try {
    //     const response = await dispatch(getCountedVisits('nurse'));
    //     setNurseList(response.data)
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };

    const handleServiceDetailsModal = (patientId) => {
        dispatch({
            type: `ADD_CLIENT_REMOVE_TASK_DETAILS_DATA`,
            payload: {},
            name: `taskDetails`,
            meta: {type: `REMOVE_TASK_DETAILS_DATA`},
        });
        if (!serviceDetails.modal) {
            dispatch(HttpsAction({
                apiSection: 'client',
                apiName: 'serviceDetails',
                queryParameter: `/${patientId}`,
                actionType: `GET_SERVICE_DETAILS`,
                reduxKeyName: 'serviceDetails',
                reducerName: 'CLIENT',
            }))
        }
        dispatch({
            type: `ADD_CLIENT_UPDATE_SERVICE_DETAILS_MODAL_FLAG`,
            payload: !isServiceDetails,
            name: `isServiceDetails`,
            meta: {type: `UPDATE_SERVICE_DETAILS_MODAL_FLAG`},
        });
        setServiceDetails(prevState => ({...prevState, modal: !prevState.modal}))
    }

    useEffect(() => {
        if (supervisoryVisitsList != null && supervisoryVisitsList?.rows?.length != null) {
            setPaginationTab(supervisoryVisitsList);
        }
    }, [supervisoryVisitsList]);

    useEffect(() => {
        if (searchText != null && searchText.length > 0)
            dispatch(getCountedVisits('supervisoryVisit', 1, searchText));
        else
            dispatch(getCountedVisits('supervisoryVisit', currentPage));
    }, [currentPage, searchText]);

    useEffect(() => {
        if (runConfirmCallback && srIdForDeletion > 0) {
            dispatch(HttpsAction({
                method: 'DELETE',
                apiSection: 'serviceRequests',
                apiName: 'deleteRequest',
                queryParameter: `/${srIdForDeletion}`,
                // actionType: `GET_SERVICE_DETAILS`,
                // reduxKeyName: 'serviceDetails',
                // reducerName: 'CLIENT',
                noResponseDispatch: true,
                positiveCallBack: (r) => {
                    setSrIdForDeletion(0);
                    dispatch(getCountedVisits('supervisoryVisit', currentPage));
                },
                negativeCallBack: (e) => {
                    alert(JSON.stringify(e));
                },
            }));
        }
    }, [runConfirmCallback]);

    return (
      <>
          {/*<div className="bg-white mt-3">
              <div className="col-sm-3">
                  <div className="d-flex gap-2 innerTabing">*/}
                      {/*<button type="button" className="btn btn-sm px-2"
                       onClick={() => navigate("/agency/servicerequest/initialAssessments")}><span className="text-nowrap">Initial Assessments</span>
                       </button>
                       <button type="button" className="btn btn-sm px-2 btn-primary"
                       onClick={() => navigate("/agency/servicerequest/supervisoryVisits")}><span className="text-nowrap">Supervisory Visits</span>
                       </button>
                       <button type="button" className="btn btn-sm px-2"
                       onClick={() => navigate("/agency/servicerequest/dischargeSummaries")}><span className="text-nowrap">Discharge Summaries</span>
                       </button>
                       <button type="button" className="btn btn-sm px-2"
                       onClick={() => navigate("/agency/servicerequest/carePlans")}><span className="text-nowrap">Care Plans</span>
                       </button>*/}
                      {/*{apiCallHelperFunctions.getTabButtons('charting', chartingTabUrls, navigate, location.pathname)}
                  </div>
              </div>*/}
              <Table headers={serviceHeaderData} rows={transformedArray} actions={hideActions ? null : actionButton} hasFixedWidth={true} tableClass="charting-table" noDataFoundMessage="No charting visits found!" />
              <CommonPaginationNew data={supervisoryVisitsList} onPageChange={(page) => setCurrentPage(page)}/>
          {/*</div>*/}
      </>
    );
};

const mapStateToProps = state => {
    const {serviceRequest} = state;
    return {
        supervisoryVisitsList: serviceRequest?.supervisoryVisits || {},
    }
}

export default withConfirmationDialog(connect(mapStateToProps)(InitialAssessment));
