import React from "react";
import classes from "./index.module.css";
import {connect} from "react-redux";
import {Field, reduxForm} from "redux-form";
import CustomDropDownField from "../../../../sharedComponents/reduxfields/customDropDown";
import CustomInputField from "../../../../sharedComponents/reduxfields/customInputField";
import PrimaryButton from "../../../../sharedComponents/buttons/PrimaryButton";

const SearchFilters = ({handleSubmit}) => {

    const onSubmit = () => {

    }

    return (
        <>
            {/*<div className={classes.filterBox}>*/}
            {/*    <div className={'d-flex flex-row justify-content-between align-items-center'}>*/}
            {/*        <div className={classes.title}>*/}
            {/*            Search Filters*/}
            {/*        </div>*/}
            {/*        <div className={classes.clearAll}>*/}
            {/*            Clear all*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className={classes.alertsFound}>*/}
            {/*        80 alerts found*/}
            {/*    </div>*/}
            {/*    <form onSubmit={handleSubmit(onSubmit)}>*/}
            {/*        <div className="row mt-3">*/}
            {/*            /!*<div className="col-12">*!/*/}
            {/*            /!*    <div className="mb-4 input-field-main">*!/*/}
            {/*            /!*        <label>Alert type </label>*!/*/}
            {/*            /!*        <Field*!/*/}
            {/*            /!*            className="form-control mk-wi"*!/*/}
            {/*            /!*            name="titleId"*!/*/}
            {/*            /!*            component={CustomDropDownField}*!/*/}
            {/*            /!*        >*!/*/}
            {/*            /!*            <option value="">Please Select</option>*!/*/}
            {/*            /!*            {([] || []).map((res, index) =>*!/*/}
            {/*            /!*                <option key={index} value={res.id}>{res.name}</option>*!/*/}
            {/*            /!*            )}*!/*/}
            {/*            /!*        </Field>*!/*/}
            {/*            /!*    </div>*!/*/}
            {/*            /!*</div>*!/*/}
            {/*            <div className="col-12">*/}
            {/*                <div className="mb-4 input-field-main">*/}
            {/*                    <Field*/}
            {/*                        className="form-control mk-wi"*/}
            {/*                        name="date"*/}
            {/*                        label="Date"*/}
            {/*                        type="date"*/}
            {/*                        component={CustomInputField}*/}
            {/*                    />*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-12">*/}
            {/*                <div className="mb-4 input-field-main">*/}
            {/*                    <label>Nurses </label>*/}
            {/*                    <Field*/}
            {/*                        className="form-control mk-wi"*/}
            {/*                        name="titleId"*/}
            {/*                        component={CustomDropDownField}*/}
            {/*                    >*/}
            {/*                        <option value="">Please Select</option>*/}
            {/*                        {([] || []).map((res, index) =>*/}
            {/*                            <option key={index} value={res.id}>{res.name}</option>*/}
            {/*                        )}*/}
            {/*                    </Field>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className="col-12">*/}
            {/*                <div className="mb-4 input-field-main">*/}
            {/*                    <label>Client </label>*/}
            {/*                    <Field*/}
            {/*                        className="form-control mk-wi"*/}
            {/*                        name="titleId"*/}
            {/*                        component={CustomDropDownField}*/}
            {/*                    >*/}
            {/*                        <option value="">Please Select</option>*/}
            {/*                        {([] || []).map((res, index) =>*/}
            {/*                            <option key={index} value={res.id}>{res.name}</option>*/}
            {/*                        )}*/}
            {/*                    </Field>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div*/}
            {/*            className="d-flex justify-content-center"*/}
            {/*            style={{marginTop: "10px"}}*/}
            {/*        >*/}
            {/*            <PrimaryButton*/}
            {/*                type="submit"*/}
            {/*                className="btn-view-repo gap-2 px-5 py-2"*/}
            {/*                text={"Apply Filter"}*/}
            {/*                onClick={() => undefined}*/}
            {/*            />*/}
            {/*        </div>*/}
            {/*    </form>*/}
            {/*</div>*/}
        </>
    )
};

export default connect()(
    reduxForm({
        form: "SearchFilters", enableReinitialize:
            true
    })(SearchFilters)
);