import React, {useEffect} from "react";
import {Field, reduxForm} from "redux-form";
import CustomTextareaField from "../../../../../../sharedComponents/reduxfields/customTextareaField";
import PrimaryButton from "../../../../../../sharedComponents/buttons/PrimaryButton";
import {connect, useDispatch} from "react-redux";
import {HttpsAction} from "../../../../../../commonApiFunction/httpsAction";
import {useParams} from "react-router";

const AddNote = ({isEdit = false, handleSubmit, onClose}) => {

    const {clientId} = useParams();

    const dispatch = useDispatch();

    const onSubmit = async (data) => {
        if (isEdit) {
            await dispatch(HttpsAction({
                method: 'PUT',
                apiSection: 'client',
                apiName: 'notes',
                queryParameter: `/${clientId}/${isEdit}`,
                data: data
            }))

        } else {
            await dispatch(HttpsAction({
                method: 'POST',
                apiSection: 'client',
                apiName: 'notes',
                queryParameter: `/${clientId}`,
                data: data
            }))
        }
        dispatch(HttpsAction({
            apiSection: 'client',
            apiName: 'notes',
            queryParameter: `/${clientId}`,
            reducerName: 'CLIENT',
            reduxKeyName: 'notes',
            actionType: 'GET_NOTES'
        }));
        onClose()
    }

    useEffect(() => {

        return () => {
            dispatch({
                type: `ADD_CLIENT_REMOVE_NOTE_DATE`,
                payload: '',
                name: 'getNoteById',
                meta: {type: `REMOVE_NOTE_DATE`},
            });
        }

    }, [])

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-12">
                        <div className="mb-4 input-field-main">
                            <Field
                                className="form-control mk-wi"
                                name="note"
                                label="Note"
                                type="text"
                                component={CustomTextareaField}
                            />
                        </div>
                    </div>
                </div>
                <div
                    className="d-flex justify-content-center"
                    style={{marginTop: "15px"}}>
                    <PrimaryButton
                        type="submit"
                        className="btn-view-repo gap-2 px-5 py-2"
                        text={isEdit ? "Update Note" : "Save"}
                        onClick={() => undefined}
                    />
                </div>
            </form>
        </>
    )
};
const mapStateToProps = state => {

    const data = state.client?.getNoteById?.data;
    console.log({data})
    return {
        initialValues: {
            note: data?.note
        }
    }

}
export default connect(mapStateToProps)(
    reduxForm({form: "addNote", enableReinitialize: true})(AddNote)
);