import React from 'react';
import CommonPagination from "./CommonPagination";

const Pagination = (props) => {

    return (
        <CommonPagination {...props}/>
    );
};

export default Pagination;
