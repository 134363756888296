import React, {useEffect, useState} from 'react';
import DashboardTabs from "./DashboardTabs";
import AdminNurses from "./AdminNurses";

const AdminNursesPage = () => {
  const [key, setKey] = useState('');

  useEffect(() => {
    setKey('nurses');
  }, []);

  return (
    <div className="app-main__inner" style={{backgroundColor: "#fff"}}>
      <div className="main-dashboard-page">
        <div className="row mb-15">
          <div className="col-12 page-heading pb-3">
            <h5>Overview</h5>
          </div>
          {/*<AdminTabs />*/}
          <div className="container">
            <DashboardTabs currentKey={key}/>
            <div className="tab-content mb-5">
              <div role="tabpanel" id="controlled-tabs-tabpane-nurses" aria-labelledby="controlled-tabs-tab-nurses" className="fade tab-pane active show">
                <AdminNurses/>
              </div>
              <div role="tabpanel" id="controlled-tabs-tabpane-agencies" aria-labelledby="controlled-tabs-tab-agencies" className="fade tab-pane"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminNursesPage;
