import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { useDispatch, connect, useSelector } from "react-redux";

import jobOffer from "../../assets/image/subscriptions-img/Job-offer.svg";
import bigData from "../../assets/image/subscriptions-img/big-data.svg";
import socialCare from "../../assets/image/subscriptions-img/social-care.svg";
import management from "../../assets/image/subscriptions-img/management.svg";
import reminder from "../../assets/image/subscriptions-img/reminder.svg";
import tracking from "../../assets/image/subscriptions-img/tracking.svg";
import chat from "../../assets/image/subscriptions-img/chat.svg";
import monitor from "../../assets/image/subscriptions-img/monitor.svg";
import booking from "../../assets/image/subscriptions-img/booking.svg";
import {
  getSubscriptionPlanAction,
  upgradeSubscriptionPlanAction,
  paypalPaymentOrderPlanAction
} from "../../../services/transaction";
import { toastr } from "react-redux-toastr";

const Subscription = () => {
  const dispatch = useDispatch();
  const [activeCard, setActiveCard] = useState("Premium Plan Access"); // Initial active card is the first one
  const getSubscriptionPlan = useSelector(
    (state) => state.transaction?.subscriptionPlansList ?? []
  );
  const upgaredSubscriptonPlanInstance = useSelector(
    (state) => state.transaction?.upgradSubscriptionPlan ?? {}
  );
  const handleCardClick = card => () => { // Create a click handler
    setActiveCard(card); // Set the clicked card as the active card
  };


  const handleUpgradPlanClick = async (plan) => {
    let payload = {
      subscriptionTypeId: plan?.subscriptionTypeId,
      planAmount: plan?.planAmount,
      planName: plan?.planName,
      features: plan?.features
    }

    try {
      await dispatch(upgradeSubscriptionPlanAction('upgradSubscriptionPlan', plan?.id, payload));
    } catch (error) {
      console.error('Error while upgrading subscription', error);
    }
  };
// Use useEffect to wait on change in upgaredSubscriptonPlanInstance?.orderData
useEffect(() => {
  const paypalUrl = upgaredSubscriptonPlanInstance?.orderData?.url;

  if (paypalUrl) {
    let popupHeight = 600;
    let popupWidth = 800;

    let popupTop = window.screenY + (((window.innerHeight || document.documentElement.clientHeight) - popupHeight) / 2);
    let popupLeft = window.screenX + (((window.innerWidth || document.documentElement.clientWidth) - popupWidth) / 2);

    let win = window.open(paypalUrl, 'PopupWindow', `height=${popupHeight},width=${popupWidth},top=${popupTop},left=${popupLeft},resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes`);

    // Check if Popup Window is blocked
    if (!win || win.closed || typeof win.closed === 'undefined') {
      // POPUP BLOCKED 
      console.log('popup blocked');
    } else {
      let timer = setInterval(() => {
        if (win.closed) {
          clearInterval(timer);
          let orderPayment = {
            orderId: upgaredSubscriptonPlanInstance?.orderData?.orderId
          }
          dispatch(paypalPaymentOrderPlanAction('paypalPaymentOrder', orderPayment));
        }
      }, 500);
    }
  } else {
    console.log('Paypal URL not received');
  }

}, [upgaredSubscriptonPlanInstance?.orderData]);
  // const handleUpgradPlanClick = async (plan) => {
  //   let payload = {
  //     subscriptionTypeId: plan?.subscriptionTypeId,
  //     planAmount: plan?.planAmount,
  //     planName: plan?.planName,
  //     features: plan?.features
  //   }

  //   try {
  //     await dispatch(upgradeSubscriptionPlanAction('upgradSubscriptionPlan', plan?.id, payload))
  //       .then((response) => {
  //         console.log("response", response)
  //       })

  //     console.log("response12345", upgaredSubscriptonPlanInstance?.orderData)

  //     const paypalUrl = upgaredSubscriptonPlanInstance?.orderData?.url;

  //     if (paypalUrl) {
  //       let popupHeight = 600;
  //       let popupWidth = 800;

  //       let popupTop = window.screenY + (((window.innerHeight || document.documentElement.clientHeight) - popupHeight) / 2);
  //       let popupLeft = window.screenX + (((window.innerWidth || document.documentElement.clientWidth) - popupWidth) / 2);

  //       let win = window.open(paypalUrl, 'PopupWindow', `height=${popupHeight},width=${popupWidth},top=${popupTop},left=${popupLeft},resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes`);

  //       // Check if Popup Window is blocked
  //       if (!win || win.closed || typeof win.closed === 'undefined') {
  //         // POPUP BLOCKED 
  //         console.log('popup blocked');
  //       } else {
  //         let timer = setInterval(() => {
  //           if (win.closed) {
  //             clearInterval(timer);
  //             let orderPayment = {
  //               orderId: upgaredSubscriptonPlanInstance?.orderData?.orderId
  //             }
  //             dispatch(paypalPaymentOrderPlanAction('paypalPaymentOrder', orderPayment));
  //           }
  //         }, 500);
  //       }
  //     } else {
  //       console.log('Paypal URL not received');
  //     }
  //   } catch (error) {
  //     console.error('Error while upgrading subscription', error);
  //   }
  // };




  useEffect(() => {
    // dispatch(getSubscriptionPlanAction("subscriptionPlan", ""));
  }, []);

  return (
    <div className="app-main__outer">
      <div className="main-service-request-page">
        <div className="bg-white sbcs-main-header">
          <artical>
            <h5 className="subsc-hadder mb-3">
              {" Maximize your success and earnings"}
            </h5>
            <p className="m-0">
              Try a Ushealthcarenurses Membership to enjoy greater benefits.
            </p>
          </artical>
        </div>
        <section className="subsc-main-content">
          <div className="plan-title text-center">
            <h5 className="">Our Plan</h5>
            <p>We have a plan to suit your business needs.</p>
          </div>
          <div className="plan-content mx-3 mx-md-0 mt-5 flex-wrap d-flex align-items-center justify-content-center gap-5" style={{ cursor: "pointer" }}>

            {getSubscriptionPlan && getSubscriptionPlan?.length > 0 &&
              getSubscriptionPlan.map((plan) => {
                return (
                  <>
                    <div
                      className={`subs-card-1 d-flex align-items-center justify-content-start flex-column ${activeCard === plan?.planName ? 'active' : ''}`}
                      onClick={handleCardClick(plan?.planName)}
                    >
                      <div className="price-title d-flex align-items-end">
                        <span className="dollar-sign">$</span>
                        <h2 className="mb-0">
                          {plan?.planAmount} <span></span>
                        </h2>
                        <p className="month">/month</p>
                      </div>

                      <div className="plan-details">
                        <p className="mt-2">Starting</p>
                        <h4>{plan?.planName}</h4>
                        <ul className="d-flex flex-column gap-2 gap-xl-4">
                          {
                            plan && plan.features.length > 0 &&
                            plan.features.map((feature) => {
                              return (
                                <li key={feature}>
                                  <span className="check-round">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="12"
                                      height="12"
                                      viewBox="0 0 12 13"
                                      fill="none"
                                    >
                                      <path
                                        d="M4.46567 12.5C4.07453 12.5 3.70408 12.326 3.4667 12.026L0.254012 7.97901C0.154685 7.85403 0.0821695 7.71161 0.040612 7.55989C-0.000945585 7.40818 -0.0107306 7.25014 0.0118162 7.09482C0.0343629 6.93949 0.0887993 6.78993 0.172013 6.65468C0.255228 6.51942 0.365588 6.40113 0.496784 6.30656C0.627934 6.2116 0.777451 6.14225 0.936767 6.10249C1.09608 6.06272 1.26206 6.05331 1.4252 6.07482C1.58833 6.09632 1.74541 6.1483 1.88744 6.22778C2.02946 6.30727 2.15363 6.41269 2.25284 6.53802L4.36676 9.19884L9.68168 1.06208C9.85795 0.793422 10.1388 0.602387 10.4626 0.530875C10.7864 0.459363 11.1267 0.513213 11.409 0.680612C11.9961 1.02864 12.1768 1.76586 11.81 2.32648L5.53027 11.9359C5.42307 12.1007 5.27531 12.2381 5.09982 12.3361C4.92432 12.4341 4.7264 12.4897 4.52321 12.4983C4.50343 12.5 4.48545 12.5 4.46567 12.5Z"
                                        fill="#F8F8F8"
                                      />
                                    </svg>
                                  </span>
                                  <p> {feature}</p>
                                </li>
                              )
                            })
                          }
                        </ul>
                      </div>
                      {activeCard === plan?.planName && plan?.planName === "Premium Plan Access" &&
                        <button className="upgrade mx-auto" onClick={(e) => { e.stopPropagation(); handleUpgradPlanClick(plan); }}>Upgrade</button>
                      }
                    </div>
                  </>
                )
              })}
          </div>
        </section>

        <section className="standard-features">
          <div className="sf-content mx-4">
            <h1>Ushealthcarenurses Standard Features</h1>

            <div className="row ">
              <div className="col-12 col-md-6 col-xl-4 mb-4">
                <div className="sf-box p-4 d-flex flex-column gap-3 align-items-start">
                  <div className="sf-box-img">
                    <img src={jobOffer} />
                  </div>
                  <h6 className="mb-0">Unlimited Job Postings</h6>
                  <p className="m-0">
                    Simpler, faster and more accurate scheduling.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-4 mb-4">
                <div className="sf-box p-4 d-flex flex-column gap-3 align-items-start">
                  <div className="sf-box-img">
                    <img src={bigData} />
                  </div>
                  <h6 className="mb-0">Unlimited Database Access</h6>
                  <p className="m-0">
                    Process billing and payroll with accuracy in minutes instead
                    of hours!
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-4 mb-4">
                <div className="sf-box p-4 d-flex flex-column gap-3 align-items-start">
                  <div className="sf-box-img">
                    <img src={socialCare} />
                  </div>
                  <h6 className="mb-0">Nurse Management</h6>
                  <p className="m-0">
                    Streamline staff management and time consuming HR related
                    activities.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-4 mb-4">
                <div className="sf-box p-4 d-flex flex-column gap-3 align-items-start">
                  <div className="sf-box-img">
                    <img src={management} />
                  </div>
                  <h6 className="mb-0">Client Management</h6>
                  <p className="m-0">
                    Easy online access gives nurses access to important
                    information.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-4 mb-4">
                <div className="sf-box p-4 d-flex flex-column gap-3 align-items-start">
                  <div className="sf-box-img">
                    <img src={reminder} />
                  </div>
                  <h6 className="mb-0">Alerts and Notifications</h6>
                  <p className="m-0">
                    Most comprehensive reporting in the industry!
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-4 mb-4">
                <div className="sf-box p-4 d-flex flex-column gap-3 align-items-start">
                  <div className="sf-box-img">
                    <img src={tracking} />
                  </div>
                  <h6 className="mb-0">Electronic Visit Verification</h6>
                  <p className="m-0">
                    Ensure everyone in your agency is using the same version of
                    documents, forms and images.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-4 mb-4 mb-xl-0">
                <div className="sf-box p-4 d-flex flex-column gap-3 align-items-start">
                  <div className="sf-box-img">
                    <img src={chat} />
                  </div>
                  <h6 className="mb-0">Hipaa Complaint Chat</h6>
                  <p className="m-0">Electronic time tracking and much more.</p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-4 mb-4 mb-xl-0">
                <div className="sf-box p-4 d-flex flex-column gap-3 align-items-start">
                  <div className="sf-box-img">
                    <img src={monitor} />
                  </div>
                  <h6 className="mb-0">Dashboard Insights</h6>
                  <p className="m-0">
                    Ushealthcarenurses provides a dedicated portal for client
                    and family members.
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-xl-4">
                <div className="sf-box p-4 d-flex flex-column gap-3 align-items-start">
                  <div className="sf-box-img">
                    <img src={booking} />
                  </div>
                  <h6 className="mb-0">Feature Rich Mobile App</h6>
                  <p className="m-0">
                    Save time and redundancy by not having to add identical
                    payer information over and over again.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Subscription;
