import React, {useEffect, useRef, useState} from "react";
import classes from "../css/Healthcare.module.css";
import Table from "../../../../../../sharedComponents/table/Table";
import PrimaryButton from "../../../../../../sharedComponents/buttons/PrimaryButton";
import NewMedication from "./NewMedication";
import {styled, Dialog, Stack, Switch} from "@mui/material";
import {HttpsAction} from "../../../../../../commonApiFunction/httpsAction";
import {useDispatch, useSelector} from "react-redux";
import {useOutletContext, useParams} from "react-router-dom";
import {useReactToPrint} from "react-to-print";
import XLSX from "xlsx/dist/xlsx.full.min";
import dateHelperFunctions from "../../../../../../helpers/date.helpers";

const Medications = ({handleSelectTab}) => {

    const printRef = useRef();

    const dispatch = useDispatch();
    // const {clientId} = useParams();
    const { clientId } = useOutletContext();

    const medicationList = useSelector(state => state.client?.healthcareMedication?.data.medications);
    const currentDateTimeUtcStr = useSelector(state => state.client?.healthcareMedication?.data.currentDateTimeUtc);
    const offsetCurrentDateTimeStr = useSelector(state => state.client?.healthcareMedication?.data.offsetCurrentDateTime);
    const allHealthcareMedication = useSelector(state => state.client?.allHealthcareMedication?.data.medications);

    const [modal, setModal] = useState();
    const [printModal, setPrintModal] = useState(false);

    const handleAddMedications = (index) => {
        if (index && medicationList?.[index - 1]) {
            dispatch({
                type: 'ADD_CLIENT_SET_MEDICATION_DATA',
                payload: medicationList[index - 1],
                name: 'singleHealthcareMedication',
                meta: {type: 'SET_MEDICATION_DATA'},
            });
        }

        setModal(prevState => !prevState)
    };

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        onAfterPrint: () => {
            // Close the modal after printing or cancelling the PDF
            handlePrintModal();
        }
    });

    const handlePrintModal = () => {
        setPrintModal(!printModal)
    };

    const transformedArray = (medicationList || [])?.map((item, index) => {
        // const currentDateTimeUtc = new Date(Date.parse(currentDateTimeUtcStr));
        // const medicationExpired = currentDateTimeUtc > new Date(Date.parse(`${item.endDate} 00:00:00`));
// console.log('test- medicationExpired', currentDateTimeUtc, item.endDate);
        const offsetCurrentDateTime = new Date(Date.parse(offsetCurrentDateTimeStr));
        const medicationExpired = offsetCurrentDateTime > new Date(Date.parse(`${item.endDate} 00:00:00`));
// console.log('test- medicationExpired', offsetCurrentDateTime, item.endDate);
        return {
            medication: item?.medication,
            dosage: item?.dosage,
            route: item?.route,
            // schedule: item?.specialInstruction,
            schedule: item?.descriptiveSchedule,
            time: item?.medicationTimePeriods?.map((v, i) => v.medicationTimePeriod)?.join(', '),
            // startDate: dateHelperFunctions.updateDateFormat(item?.startDate, 'DD-MM-YYYY'),
            // endDate: dateHelperFunctions.updateDateFormat(item?.endDate, 'DD-MM-YYYY'),
            startDate: item?.startDateFormatUs,
            endDate: item?.endDateFormatUs,
            // status: <button type={'button'}
            //                 className={`btn btn-primary border-btn-info px-4 ${classes.activeButton}`}> {item?.active === 1 ? `Active` : `Inactive`}  </button>,
            status: <button type={'button'}
                            className={`btn btn-primary border-btn-info ${medicationExpired ? classes.inactiveButton : classes.activeButton}`}> {medicationExpired ? `Inactive` : `Active`}  </button>,
            buttons:
                <div className={'d-flex flex-row justify-content-between'}>
                    {/*<button type={'button'} className={`btn btn-primary border-btn-info px-3 ${classes.viewButton}`}>*/}
                    {/*    View*/}
                    {/*</button>*/}
                    <button type={'button'} className={`btn btn-primary border-btn-info px-3 ${classes.viewButton}`}
                            style={{width: '105px'}}
                            onClick={() => {
                                // dispatch(HttpsAction({
                                //     apiSection: 'client',
                                //     apiName: 'healthcareMedication',
                                //     actionType: 'GET_HEALTHCARE_MEDICATION',
                                //     queryParameter: `/${item.id}`,
                                //     reduxKeyName: 'singleHealthcareMedication',
                                //     reducerName: 'CLIENT',
                                // }))
                                handleAddMedications(index + 1)
                            }}>
                        View / Edit
                    </button>
                    {/*<button type={'button'} className={`btn btn-primary border-btn-info px-3 ${classes.viewButton}`}>*/}
                    {/*    Copy*/}
                    {/*</button>*/}
                    <button type={'button'} className={`btn btn-primary border-btn-info px-3 ${classes.viewButton}`}
                            onClick={() => handleDeleteMedication(item.id)}>
                        Delete
                    </button>
                </div>,
        }
    });

    const printTransformedArray = (allHealthcareMedication || [])?.map((item, index) => {
        const offsetCurrentDateTime = new Date(Date.parse(offsetCurrentDateTimeStr));
        const medicationExpired = offsetCurrentDateTime > new Date(Date.parse(`${item.endDate} 00:00:00`));
        return {
            medication: item?.medication,
            dosage: item?.dosage,
            route: item?.route,
            // schedule: item?.specialInstruction,
            schedule: item?.descriptiveSchedule,
            time: item?.medicationTimePeriods?.map((v, i) => v.medicationTimePeriod)?.join(', '),
            // startDate: dateHelperFunctions.updateDateFormat(item?.startDate, 'DD-MM-YYYY'),
            // endDate: dateHelperFunctions.updateDateFormat(item?.endDate, 'DD-MM-YYYY'),
            startDate: item?.startDateFormatUs,
            endDate: item?.endDateFormatUs,
            // status: <button type={'button'}
            //                 className={`btn btn-primary border-btn-info px-4 ${classes.activeButton}`}> {item?.active === 1 ? `Active` : `Inactive`}  </button>,
            status: <button type={'button'}
                            className={`btn btn-primary border-btn-info ${medicationExpired ? classes.inactiveButton : classes.activeButton}`}> {medicationExpired ? `Inactive` : `Active`}  </button>,
        }
    });

    const serviceHeaderData = [
        {
            title: "Medication",
            dataIndex: "medication",
        },
        {
            title: "Dosage",
            dataIndex: "dosage",
        },
        {
            title: "Route",
            dataIndex: "route",
        },
        {
            title: "Schedule",
            dataIndex: "schedule",
        },
        {
            title: "Time",
            dataIndex: "time",
        },
        {
            title: "Start Date",
            dataIndex: "startDate",
        },
        {
            title: "End Date",
            dataIndex: "endDate",
        },
        {
            title: "Status",
            dataIndex: "status",
        },
        {
            title: "",
            dataIndex: "buttons",
        }
    ];

    const handleDeleteMedication = (id) => {
        dispatch(HttpsAction({
            method: 'PUT',
            apiSection: 'client',
            apiName: 'healthcareMedication',
            data: {
                recordType: 'deleted',
                id: id
            },
            queryParameter: `/${clientId}`,
            positiveCallBack: () => {
                getMedication()
            }
        }))
    }

    const getMedication = () => {
        dispatch(HttpsAction({
            apiSection: 'client',
            apiName: 'healthcareMedication',
            actionType: 'GET_HEALTHCARE_MEDICATION',
            reduxKeyName: 'healthcareMedication',
            queryParameter: `/${clientId}`,
            reducerName: 'CLIENT',
        }))
    };

    useEffect(() => {
        getMedication()
        dispatch(HttpsAction({
            apiSection: 'client',
            apiName: 'healthcareMedication',
            actionType: 'GET_HEALTHCARE_MEDICATION',
            reduxKeyName: 'allHealthcareMedication',
            queryParameter: `/${clientId}?doPaging=false`,
            reducerName: 'CLIENT',
        }))
    }, [])

    return (
        <>
            <div className={'d-flex justify-content-end'}>
                <div className={'d-flex'}>
                    <Stack direction="row" spacing={2} alignItems="center">
                        {/*<div>Show Expired</div>*/}
                        {/*<AntSwitch defaultChecked={true} inputProps={{'aria-label': 'ant design'}}/>*/}
                        {/*<div>No</div>*/}
                    </Stack>
                    <button type={'button'} className={`btn btn-primary border-btn-info px-3`}
                            onClick={handlePrintModal}
                            style={{marginLeft: '30px'}}>
                        <div className={'d-flex flex-row gap-2'}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M5.99844 5.39961C5.99844 4.92222 6.18808 4.46438 6.52565 4.12682C6.86321 3.78925 7.32105 3.59961 7.79844 3.59961H16.1984C16.6758 3.59961 17.1337 3.78925 17.4712 4.12682C17.8088 4.46438 17.9984 4.92222 17.9984 5.39961V5.99961H18.5984C19.3941 5.99961 20.1572 6.31568 20.7198 6.87829C21.2824 7.4409 21.5984 8.20396 21.5984 8.99961V14.9996C21.5984 15.477 21.4088 15.9348 21.0712 16.2724C20.7337 16.61 20.2758 16.7996 19.7984 16.7996H17.9984V18.5996C17.9984 19.077 17.8088 19.5348 17.4712 19.8724C17.1337 20.21 16.6758 20.3996 16.1984 20.3996H7.79844C7.32105 20.3996 6.86321 20.21 6.52565 19.8724C6.18808 19.5348 5.99844 19.077 5.99844 18.5996V16.7996H4.19844C3.72105 16.7996 3.26321 16.61 2.92565 16.2724C2.58808 15.9348 2.39844 15.477 2.39844 14.9996V8.99961C2.39844 8.20396 2.71451 7.4409 3.27712 6.87829C3.83973 6.31568 4.60279 5.99961 5.39844 5.99961H5.99844V5.39961ZM16.7984 5.39961C16.7984 5.24048 16.7352 5.08787 16.6227 4.97535C16.5102 4.86282 16.3576 4.79961 16.1984 4.79961H7.79844C7.63931 4.79961 7.4867 4.86282 7.37417 4.97535C7.26165 5.08787 7.19844 5.24048 7.19844 5.39961V5.99961H16.7984V5.39961ZM7.19844 13.7996V18.5996C7.19844 18.7587 7.26165 18.9114 7.37417 19.0239C7.4867 19.1364 7.63931 19.1996 7.79844 19.1996H16.1984C16.3576 19.1996 16.5102 19.1364 16.6227 19.0239C16.7352 18.9114 16.7984 18.7587 16.7984 18.5996V13.7996C16.7984 13.6405 16.7352 13.4879 16.6227 13.3753C16.5102 13.2628 16.3576 13.1996 16.1984 13.1996H7.79844C7.63931 13.1996 7.4867 13.2628 7.37417 13.3753C7.26165 13.4879 7.19844 13.6405 7.19844 13.7996Z"
                                    fill="#1976D2"/>
                            </svg>
                            <div>
                                Print
                            </div>
                        </div>
                    </button>
                    <PrimaryButton
                        type="button"
                        className={`btn-view-repo gap-2 px-5 py-2 ${classes.addButton}`}
                        text={"Add"}
                        onClick={handleAddMedications}
                    />
                </div>
            </div>
            <div className={'mt-5'}>
                <Table headers={serviceHeaderData} rows={transformedArray}/>

            </div>
            {/*<div*/}
            {/*    className="d-flex justify-content-center"*/}
            {/*    style={{marginTop: "40px"}}*/}
            {/*>*/}
            {/*    <PrimaryButton*/}
            {/*        type="submit"*/}
            {/*        className="btn-view-repo gap-2 px-5 py-2"*/}
            {/*        text={"Save"}*/}
            {/*        onClick={() => handleSelectTab(3)}*/}
            {/*    />*/}
            {/*</div>*/}
            <Dialog maxWidth="lg" open={modal} onClose={handleAddMedications} aria-labelledby="form-dialog-title">
                <NewMedication clientId={clientId} onClose={handleAddMedications} edit={modal === "edit"}/>
            </Dialog>

            <Dialog
                open={printModal}
                onClose={handlePrintModal}
                maxWidth={'md'}
            >
                <div className={'p-4'} ref={printRef}>
                    <Table headers={serviceHeaderData} rows={printTransformedArray}/>
                </div>

                <div
                    className="d-flex justify-content-center"
                    style={{margin: "30px"}}
                >
                    <PrimaryButton
                        type="button"
                        className="btn-view-repo gap-2 px-5 py-2"
                        text={"Print"}
                        onClick={() => handlePrint()}
                    />
                </div>
            </Dialog>
        </>
    )
};

export default Medications;
