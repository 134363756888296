import React, {useEffect, useState} from 'react';
import {Stack, Pagination} from "@mui/material";
import {pageLimit} from "../../appConstants";

const CommonPagination = ({currentPage = 1, totalRecords = 0, limit = pageLimit, onPageChange}) => {

    const [page, setPage] = useState(1);

    const pageCount = Math.ceil(totalRecords / limit);

    const handlePageChange = (event, page) => {
        onPageChange(page);
    };

    useEffect(() => {
        if (currentPage) {
            setPage(currentPage)
        }
    }, [currentPage]);

    return (
        <Stack spacing={2} margin={'20px'}>
            <Pagination
                count={pageCount}
                page={page}
                onChange={handlePageChange}
                showFirstButton
                showLastButton
                color="primary"
            />
        </Stack>
    );
};

export default CommonPagination;
