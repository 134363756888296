import React, {useEffect, useState} from "react";
import classes from "../css/Healthcare.module.css";
import Table from "../../../../../../sharedComponents/table/Table";
import PrimaryButton from "../../../../../../sharedComponents/buttons/PrimaryButton";
import Modal from "../../../../../../sharedComponents/Modal";
import NewAllergy from "./NewAllergy";
import {HttpsAction} from "../../../../../../commonApiFunction/httpsAction";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";

const Allergies = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const {clientId} = useParams();
    const { clientId } = useOutletContext();

    const healthcareAllergies = useSelector(state => state.client?.healthcareAllergies?.data)

    const [modal, setModal] = useState(false);
    const [editModal, setEditModal] = useState(false);

    const transformedArray = (healthcareAllergies?.length ? healthcareAllergies : [] || [])?.map((item, index) => {
        return {
            medication: item?.medication,
            notes: item?.notes,
            buttons:
                <div className={'d-flex flex-row justify-content-end'}>
                    {item?.medication !== 'NKDA' && <>
                        {/*<button type={'button'} className={`btn btn-primary border-btn-info px-3 ${classes.viewButton}`}
                                onClick={() => {
                                    setEditModal(true)
                                    dispatch(HttpsAction({
                                        apiSection: 'client',
                                        apiName: 'healthcareAllergies',
                                        actionType: 'GET_HEALTHCARE_ALLERGIES',
                                        queryParameter: `/${clientId}`,
                                        reduxKeyName: 'singleAllergy',
                                        reducerName: 'CLIENT',
                                    }))
                                }}>
                            Edit
                        </button>*/}
                        <button type={'button'} className={`btn btn-primary border-btn-info px-3 ${classes.viewButton}`}
                                onClick={() => handleDeleteAllergies(item)}>
                            Delete
                        </button>
                    </>}
                </div>,
        }
    });

    const serviceHeaderData = [
        {
            title: "Medication",
            dataIndex: "medication",
        },
        {
            title: "Notes",
            dataIndex: "notes",
        },
        {
            title: "",
            dataIndex: "buttons",
        }
    ];

    const handleModal = () => {
        setModal(prevState => !prevState);
    };

    const handleDeleteAllergies = (data) => {
        dispatch(HttpsAction({
            method: 'DELETE',
            apiSection: 'client',
            apiName: 'healthcareAllergies',
            actionType: 'GET_HEALTHCARE_ALLERGIES',
            reduxKeyName: 'healthcareAllergies',
            queryParameter: `/${clientId}/${data.id}`,
            reducerName: 'CLIENT',
            positiveCallBack: () => {
                reload();
            }
        }));
    };

    const reload = () => {
        dispatch(HttpsAction({
            apiSection: 'client',
            apiName: 'healthcareAllergies',
            actionType: 'GET_HEALTHCARE_ALLERGIES',
            reduxKeyName: 'healthcareAllergies',
            queryParameter: `/${clientId}`,
            reducerName: 'CLIENT',
        }));
    };

    useEffect(() => {
        reload();
    }, []);

    return (
        <>
            <div
                className="d-flex justify-content-end">
                <PrimaryButton
                    type="button"
                    className="btn-view-repo gap-2 px-5 py-2"
                    text={"Add"}
                    onClick={() => handleModal()}
                />
            </div>
            <div className={''}>
                <Table headers={serviceHeaderData} rows={transformedArray}/>
            </div>
            {/*<div*/}
            {/*    className="d-flex justify-content-center"*/}
            {/*    style={{marginTop: "40px"}}*/}
            {/*>*/}
            {/*    <PrimaryButton*/}
            {/*        type="submit"*/}
            {/*        className="btn-view-repo gap-2 px-5 py-2"*/}
            {/*        text={"Save"}*/}
            {/*        onClick={() => navigate(`/agency/clients/${clientId}/characteristics/0`)}*/}
            {/*    />*/}
            {/*</div>*/}
            <Modal onClose={handleModal} isOpen={modal} modalTitle={'New Allergy'}>
                <NewAllergy clientId={clientId} onClose={handleModal} reload={reload} />
            </Modal>
            <Modal onClose={() => setEditModal(false)} isOpen={editModal} modalTitle={'Edit Allergy'}>
                <NewAllergy clientId={clientId} onClose={() => setEditModal(false)} isEdit={editModal}/>
            </Modal>
        </>
    )
};
export default Allergies;
