import React from "react";
import {connect, useSelector} from "react-redux";
import {reduxForm} from "redux-form";

import CareNoteCommon from "./CareNoteCommon";
import {noImagePath} from "../../../../appConstants";

const CareNotes = ({show, doUpdateApiCall, reload, showingDataFor, updateActivitiesList, removeActivity, taskActivitiesList, initialValues, isJobRelated, isChartingRelated}) => {

    return (
      <CareNoteCommon
        show={show}
        taskId={initialValues.taskId}
        notes={initialValues?.notes || 'N/A'}
        clientSignature={initialValues?.patientSignatureDocumentLink || null}
        // activitiesList={initialValues?.activities}
        activitiesList={taskActivitiesList}
        caregiverSignature={initialValues?.nurseSignatureDocumentLink || null}
        showingDataFor={showingDataFor}
        doUpdateApiCall={doUpdateApiCall}
        reload={reload}
        updateActivitiesList={updateActivitiesList}
        removeActivity={removeActivity}
        isJobRelated={isJobRelated}
        isChartingRelated={isChartingRelated}
        clockInTime={initialValues?.clockInTime || null}
        clockOutTime={initialValues?.clockOutTime || null}
        rnSignatureEditable={false}
        rnSignatureValue={initialValues.rnSignatureValue}
        rnSignatureDate={initialValues.rnSignatureDate}
      />
    )
};

const mapStateToProps = (state, ownProps) => {
    // const data = state.client?.taskDetails?.data;
    const data = ownProps?.taskDetails;

    return {
        initialValues: {
            notes: data?.notes || "",
            patientSignatureDocumentLink: data?.patientSignatureDocumentLink != null ? `/agency${data?.patientSignatureDocumentLink}` : noImagePath,
            nurseSignatureDocumentLink: data?.nurseSignatureDocumentLink != null ? `/agency${data?.nurseSignatureDocumentLink}` : noImagePath,
            activities: data?.activities || [],
            taskId: data?.id || 0,
            clockInTime: data?.clockInAmPm,
            clockOutTime: data?.clockOutAmPm,
            rnSignatureValue: data?.nurseNameForVerification,
            rnSignatureDate: data?.paymentVerifiedAt,
        }
    }
};

export default connect(mapStateToProps)(
    reduxForm({form: "CareNotes", enableReinitialize: true})(CareNotes)
);
