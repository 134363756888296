import React, {useEffect, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import {Field, reduxForm} from "redux-form";
import {Stack} from "@mui/material";
import CustomDropDownField from "../../../../../../sharedComponents/reduxfields/customDropDown";
import CustomInputField from "../../../../../../sharedComponents/reduxfields/customInputField";
import CustomRadioGroup from "../../../../../../sharedComponents/reduxfields/customRadioGroup";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {postClientProfileCharacteristics} from "../../../../../../services/client";
import Modal from "../../../../../../sharedComponents/Modal";
import classes from "../../../css/characteristics.module.css"
import PrimaryButton from "../../../../../../sharedComponents/buttons/PrimaryButton";
import {HttpsAction} from "../../../../../../commonApiFunction/httpsAction";
import {useQueryClient} from "@tanstack/react-query";
import stringHelperFunctions from "../../../../../../helpers/string.helpers";
import numberHelperFunctions from "../../../../../../helpers/number.helpers";
import arrayHelperFunctions from "../../../../../../helpers/array.helpers";
import useCustomNav from "../../../../../../utils/hooks/useCustomNav";

const advanceDirectiveOptions = [
    {label: 'Yes', id: "yes1", value: 'true'},
    {label: 'No', id: "no1", value: 'false'}
];
const DNROptions = [
    {label: 'Yes', id: "yes2", value: 'true'},
    {label: 'No', id: "no2", value: 'false'}
];
const willOptions = [
    {label: 'Yes', id: "yes3", value: 'true'},
    {label: 'No', id: "no3", value: 'false'}
];

const Characteristics = ({handleSubmit}) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const {clientId, id} = useParams();
    const { clientId } = useOutletContext();

    const { goTo: gotoPetInfoPage } = useCustomNav('/agency/clients/edit/profile/pet-info');

    const characteristicsData = useSelector(state => state.client.characteristics?.data);

    const triageLevelsArray = queryClient.getQueryData(['list-triage-levels']);
    const languagesArray = queryClient.getQueryData(['list-other-languages']);

    const [triageLevelModal, setTriageLevelModal] = useState(false);

    const handleTriageLevelModal = () => setTriageLevelModal(prevState => !prevState);

    const onSubmit = async ({
                                weight,
                                height,
                                In,
                                hobbies,
                                triageLevelId,
                                hasWill,
                                jobTitle,
                                languageId,
                                advancedDirective,
                                DNR,
                                allergies
                            }) => {

        const res = await dispatch(postClientProfileCharacteristics({
            // clientId,
            weightInPounds: numberHelperFunctions.nullIfEmpty(weight),
            // height: Number(`${height}.${In || 0}`),
            heightInFeet: numberHelperFunctions.nullIfEmpty(height),
            heightInInches: numberHelperFunctions.nullIfEmpty(In),
            hobbies: arrayHelperFunctions.nullIfEmpty(hobbies?.split(',').map(s => s.trim())),
            triageLevelId: numberHelperFunctions.nullIfEmpty(triageLevelId),
            hasWill: hasWill === "true",
            languageId: numberHelperFunctions.nullIfEmpty(languageId),
            jobTitleId: null, //numberHelperFunctions.nullIfEmpty(1),
            advanceDirectives: advancedDirective === "true",
            doNotResuscitate: DNR === "true",
            allergies: arrayHelperFunctions.nullIfEmpty(allergies?.split(',').map(s => s.trim())),
        }, clientId))
        if (res?.success)
            // navigate(`/agency/clients/${clientId}/${id}/petInformation`);
            gotoPetInfoPage();
    }

    useEffect(() => {
        dispatch(HttpsAction({
            apiSection: 'client',
            apiName: 'characteristics',
            actionType: 'GET_CHARACTERISTICS',
            reducerName: 'CLIENT',
            queryParameter: `/${clientId}`,
            reduxKeyName: 'characteristics'
        }));
    }, []);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-4">
                        <div className="mb-4 input-field-main">
                            <Field
                              className="form-control mk-wi"
                              name="weight"
                              label="Weight (Ibs)"
                              type="number"
                              component={CustomInputField}
                            />
                        </div>
                    </div>
                    <div className="col-2">
                        <div className={`mb-4 input-field-main ${classes.heightParent}`}>
                            <Field
                              className="form-control mk-wi"
                              name="height"
                              label="Height (ft)"
                              type="number"
                              component={CustomInputField}
                            />
                            <Stack className={classes.inchDash}>
                                -
                            </Stack>
                        </div>
                    </div>
                    <div className="col-2">
                        <div className="mb-4 input-field-main">
                            <Field
                              className="form-control mk-wi"
                              name="In"
                              label="(In)"
                              type="number"
                              component={CustomInputField}
                            />

                        </div>
                    </div>
                    <div className="col-4">
                        <div className="mb-4 input-field-main">
                            <label>Language </label>
                            <Field
                              className="form-control mk-wi"
                              name="languageId"
                              component={CustomDropDownField}
                            >
                                <option value="">Please Select</option>
                                {(languagesArray || []).map((res, index) =>
                                  <option key={index} value={res.id}>{res.name}</option>
                                )}
                            </Field>
                        </div>
                    </div>
                    {/*<div className="col-4">
                     <div className="mb-4 input-field-main">
                     <Field
                     className="form-control mk-wi"
                     name="jobTitleId"
                     label="Job Title"
                     type="text"
                     component={CustomInputField}
                     />
                     </div>
                     </div>*/}
                    <div className="col-4">
                        <div className="mb-4 input-field-main">
                            <Field
                              className="form-control mk-wi"
                              name="hobbies"
                              label="Hobbies"
                              type="text"
                              component={CustomInputField}
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="mb-4 input-field-main">
                            <Field
                              label="Triage Level"
                              infoIconOnClick={handleTriageLevelModal}
                              className="form-control mk-wi"
                              name="triageLevelId"
                              component={CustomDropDownField}
                            >
                                <option value="">Please Select</option>
                                {(triageLevelsArray || []).map((res, index) =>
                                  <option key={index} value={res.id}>{res.name}</option>
                                )}
                            </Field>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="mb-4 input-field-main">
                            <label>
                                Advance Directive
                            </label>
                            <Field
                              name="advancedDirective"
                              component={CustomRadioGroup}
                              options={advanceDirectiveOptions}
                              onChange={(e) => undefined}
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="mb-4 input-field-main">
                            <label>
                                DNR (Do Not Resuscitate)
                            </label>
                            <Field
                              name="DNR"
                              component={CustomRadioGroup}
                              options={DNROptions}
                              onChange={(e) => undefined}
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="mb-4 input-field-main">
                            <Field
                              className="form-control mk-wi"
                              name="allergies"
                              label="Allergies"
                              type="text"
                              component={CustomInputField}
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="mb-4 input-field-main">
                            <label>
                                Will
                            </label>
                            <Field
                              name="hasWill"
                              component={CustomRadioGroup}
                              // initialValue={(characteristicsData.hasWill === 1).toString()}
                              // value={(characteristicsData.hasWill === 1).toString()}
                              options={willOptions}
                              onChange={(e) => undefined}
                            />
                        </div>
                    </div>
                </div>
                <div
                  className="d-flex justify-content-center"
                  style={{marginTop: "40px"}}>
                    <PrimaryButton
                      type="submit"
                      className="btn-view-repo gap-2 px-5 py-2"
                      text={"Save"}
                      onClick={() => undefined}
                    />
                </div>
            </form>
            <Modal modalTitle={'Triage Level'} onClose={handleTriageLevelModal} isOpen={triageLevelModal}>
                <Stack className={classes.triageLevelContentMain}>
                    This describes the level of mobility a client exhibits during emergency situations, such as
                    tornadoes, floods, or fires.
                </Stack>
            </Modal>
        </>
    )
};

const mapStateToProps = state => {
    console.log('state', state)

    const data = state.client?.characteristics?.data;

    return {
        initialValues: {
            weight: data?.weightInPounds?.toString(),
            hobbies: data?.hobbies?.toString(),
            hasWill: data != null && data.hasWill != null && (data.hasWill === 1).toString() || 'false',
            // height: data?.heightInFeet?.toString()?.split('.')?.[0]?.toString(),
            height: data?.heightInFeet?.toString(),
            // In: data?.height?.toString()?.split('.')?.[1]?.toString(),
            In: data?.heightInInches?.toString(),
            languageId: data?.secondaryLanguageId?.toString(),
            // jobTitle: '',
            jobTitle: data?.jobTitleId?.toString(),
            triageLevelId: data?.triageLevelId?.toString(),
            advancedDirective: data != null && data.advanceDirectives != null && (data.advanceDirectives === 1).toString() || 'false',
            DNR: data != null && data.doNotResuscitate != null && (data.doNotResuscitate === 1).toString() || 'false',
            allergies: data?.allergies,
        }
    }
}

export default connect(mapStateToProps)(
    reduxForm({form: "characteristics", enableReinitialize: true})(Characteristics)
);
