import React, {useState} from "react";
import {useSelector} from "react-redux";
import {Stack} from "@mui/material";
import classes from "../../CareNote.module.css";
import Table from "../../../../../../../sharedComponents/table/Table";
import signImage from "../../../../../../assets/image/sign.svg"
import {useParams} from "react-router-dom";
import {getDayName} from "../TasksInformation";
import {apiBaseUrl, noImagePath} from "../../../../../../../appConstants";
import dateHelperFunctions from "../../../../../../../helpers/date.helpers";
import CustomDialog from "../../../../../../../sharedComponents/CustomDialog";
import PrimaryButton from "../../../../../../../sharedComponents/buttons/PrimaryButton";

const SignatureInformation = ({tableRef, tasksList}) => {

    const {tabId} = useParams();

    // const careList = useSelector(state => state.client?.careList?.data || []);
    const [open, setOpen] = useState(false);

    // const transformedArray = (careList?.rows?.[Number(tabId) - 1]?.tasks || [])?.map((item, index) => {
    const transformedArray = (tasksList || [])?.map((item, index) => {
        let clientSignature = `${apiBaseUrl}${item?.patientSignatureDocumentLink ? `/agency${item?.patientSignatureDocumentLink}` : noImagePath}`;
        let nurseSignature = `${apiBaseUrl}${item?.nurseSignatureDocumentLink ? `/agency${item?.nurseSignatureDocumentLink}` : noImagePath}`;

        return {
            date: <Stack gap={'5px'}>
                <Stack className={classes.dayName}>
                    {getDayName(item?.date)}
                </Stack>
                <Stack className={classes.date}>
                    {dateHelperFunctions.updateDateFormat(item?.date, "MM/DD/YYYY")}
                </Stack>
            </Stack>,
            clock: `${dateHelperFunctions.convertTo12HourFormat(item?.clockIn)} - ${dateHelperFunctions.convertTo12HourFormat(item?.clockOut)}`,
            nurseSignature: <img style={{height: '50px', width: '100px', cursor: "pointer"}} src={nurseSignature} alt={''}
                                 onClick={() => handleOpenModal(nurseSignature)}/>,
            clientSignature: <img style={{height: '50px', width: '100px', cursor: "pointer"}} src={clientSignature} alt={''}
                                  onClick={() => handleOpenModal(clientSignature)}/>,
            RNSignature: item?.nurseNameForVerification ? item?.nurseNameForVerification : 'N/A'
        }
    });

    const serviceHeaderData = [
        {
            title: "Date",
            dataIndex: "date",
        },
        {
            title: "Clock In/Clock Out",
            dataIndex: "clock",
        },
        {
            title: "Nurse Signature",
            dataIndex: "nurseSignature",
        },
        {
            title: "Client Signature",
            dataIndex: "clientSignature",
        },
        {
            title: "RN Signature",
            dataIndex: "RNSignature",
        },
    ];


    const handleCloseModal = () => {
        setOpen(false)
    };

    const handleOpenModal = (url) => {
        setOpen(url)
    }

    return (
        <>
            <Table headers={serviceHeaderData} rows={transformedArray}/>

            <CustomDialog
                open={open}
                onClose={() => handleCloseModal()}
                title={'Signature'}
                maxWidth={'sm'}
                actions={
                    <div className="d-flex justify-content-center" style={{marginBottom: "20px"}}>
                        <PrimaryButton
                            type="button"
                            className="btn-view-repo gap-2 px-5 py-2"
                            text={"Close"}
                            onClick={() => handleCloseModal()}
                        />
                    </div>
                }
            >
                <div className={'d-flex justify-content-center align-items-center w-100'}>
                    <img style={{height: '250px', width: '100%'}} src={open} alt={''}/>
                </div>
            </CustomDialog>
        </>
    )
};
export default SignatureInformation;
