import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import storageManager from "../../helpers/storageManager";
import { ReactComponent as SearchIcon } from "../assets/image/search_icon.svg";
import {logout} from "../../services/Auth";
import {connect} from "react-redux";
import {useQueryClient} from "@tanstack/react-query";
import {adminLoginPath} from "../../data/routes.values";

const AdminHeader = ({ adminLogout }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();

  const [isPopupShow, setIsPopupShow] = useState(false);

  useEffect(() => {
    setIsPopupShow(false);
  }, [location.pathname]);

  return (
    <div className="app-header">
      <div className="app-header__mobile-menu">
        <div>
          <button
            type="button"
            className="hamburger hamburger--elastic mobile-toggle-nav"
          >
            <span className="hamburger-box">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.3335 22.6654L24.0002 15.9987L17.3335 9.33203"
                  stroke="#282D35"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8 22.6654L14.6667 15.9987L8 9.33203"
                  stroke="#282D35"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </button>
        </div>
      </div>
      <div className="app-header__menu">
        <span>
          <button
            type="button"
            className="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav"
          >
            <span className="btn-icon-wrapper">
              <i className="fa fa-ellipsis-v fa-w-6"></i>
            </span>
          </button>
        </span>
      </div>
      <div className="app-header__content">
        {/*<div className="app-header-left">
          <div className="search-wrapper">
            <div className="input-group">
              <span className="input-group-append">
                <button className="btn ms-n5" type="button">
                  <SearchIcon />
                </button>
              </span>
              <input
                className="form-control border-end-0 border"
                type="search"
                placeholder="Search for anything"
              />
            </div>
          </div>
        </div>*/}
        <div className="app-header-right">
          {/*<div className="widget-content-right header-notification mr-3">
            <div
              style={{ cursor: "pointer" }}
              onClick={() => navigate(`/admin/alerts`)}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.6273 26.6654V26.292H26.6673H27.6273V25.332V23.9987V23.6011L27.3461 23.3199L24.9607 20.9344V14.6654C24.9607 10.4939 22.8368 6.79276 18.9607 5.51353V5.33203C18.9607 3.69517 17.6375 2.37203 16.0007 2.37203C14.3638 2.37203 13.0407 3.69517 13.0407 5.33203V5.51271C9.15274 6.79015 7.04065 10.4816 7.04065 14.6654V20.9344L4.65516 23.3199L4.37398 23.6011V23.9987V25.332V26.292H5.33398H12.374V26.6654C12.374 28.6591 13.9873 30.292 16.0007 30.292C17.9975 30.292 19.6273 28.6622 19.6273 26.6654Z"
                  fill="#73737C"
                  stroke="#73737C"
                  strokeWidth="1.92"
                />
                <circle
                  cx="22.6673"
                  cy="8.0013"
                  r="5.81333"
                  fill="#F7685B"
                  stroke="#73737C"
                  strokeWidth="0.96"
                />
              </svg>
            </div>
          </div>*/}
          <div className="header-btn-lg pr-0">
            <div className="widget-content p-0">
              <div className="widget-content-left">
                <div className={`btn-group ${isPopupShow && "show"}`}>
                  <a
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    className="p-0 btn"
                    onClick={() => setIsPopupShow(!isPopupShow)}
                  >
                    Admin
                  </a>
                  <div
                    tabIndex="-1"
                    role="menu"
                    aria-hidden="true"
                    className={`dropdown-menu  dropdown-menu-right ${
                      isPopupShow && "show"
                    }`}
                    style={{
                      position: "absolute",
                      willChange: "transform",
                      top: "0px",
                      left: "0px",
                      transform: "translate3d(-33px, 50px, 0px)",
                    }}
                  >
                    {/*<button
                      type="button"
                      tabIndex="0"
                      className="dropdown-item"
                      onClick={() => {
                        navigate("/admin/profile");
                      }}
                    >
                      Settings
                    </button>*/}
                    <button
                      type="button"
                      tabIndex="0"
                      className="dropdown-item"
                      onClick={() => {
                        adminLogout(queryClient).then((res) => {
                          if (res?.success) {
                            // navigate(adminLoginPath, {
                            //   replace: true,
                            //   reloadDocument: true,
                            //   state: {
                            //     reload: true
                            //   },
                            // });
                            setTimeout(navigate, 0, adminLoginPath, { replace: true, reloadDocument: true, state: { reload1: true } });
                            window.location.reload(true);
                          }
                        });
                      }}
                    >
                      Logout
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    adminLogout: async (queryClient) => {
      const logoutAwait = await dispatch(logout());
      queryClient.clear();
      return logoutAwait;
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminHeader);
