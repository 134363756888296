import React from "react";
import {Card} from "react-bootstrap";

const CardTab = ({title = "", count = 0, isActive = false, handleClick = () => undefined, handleSeeAll = () => undefined}) => {

    return (
        <>
            <Card
                className={`dashboard-card ${isActive ? "active" : ""}`}
                style={{width: "300px", height: "128px"}}
                onClick={() => handleClick(title)}
            >
                <Card.Body>
                    <div className="card-content">
                        <p
                            className={`card-title ${isActive ? "active" : ""}`}
                            style={{color: isActive ? "#fff" : "#111"}}
                        >
                            {title}
                        </p>
                        <p className={`nurses-number ${isActive ? "active" : ""}`}>
                            {count}
                        </p>
                        <div className="chart-view-btn mt-2">
            <span>
              <a
                  onClick={(e) => handleSeeAll(e, title)}
                  className={`text-decoration-none ${isActive ? "active" : ""}`}
                  style={{cursor: "pointer"}}
              >
                See all
              </a>
            </span>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    )
};

export default CardTab;