import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {change, Field, reduxForm} from "redux-form";
import CustomInputField from "../../../../sharedComponents/reduxfields/customInputField";
import MultiSelectDropdown from "../../../../sharedComponents/multiSelect";
import PrimaryButton from "../../../../sharedComponents/buttons/PrimaryButton";
import {useNavigate, useOutletContext} from "react-router-dom";
import CustomDropDownField from "../../../../sharedComponents/reduxfields/customDropDown";
import {required} from "../../../../helpers/validation";
import {getCityList, getGendersList, getPhoneTypesList, getStateList} from "../../../../services/commonServices";
import {putAgencyAction} from "../../../../services/AgencyProfile";
import CustomRadioField from "../../../../sharedComponents/fields/customRadioField";
import CustomLabel from "../../../../sharedComponents/fields/CustomLabel";
import {isAdminRole} from "../../../../helpers/apiMethods";
import {useParams} from "react-router";
import CustomDropDownNewField from "../../../../sharedComponents/reduxfields/customDropDownNew";
import RadioOptionsComponent from "../../../../sharedComponents/radioOptions";

const Address = ({reloadData, handleSubmit, statesArray, phoneTypesArray, gendersArray, initialValues}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const {tabId, agencyId} = useParams();
    const { agencyId } = useOutletContext();

    const [cityList, setCityList] = useState([]);
    // const [selectedGenderId, setSelectedGenderId] = useState(-1);
    const [loadedOnce, setLoadedOnce] = useState(false);

    const fetchData = async (res) => {
        try {
            if (res.serviceName === "getCityList") {
                const response = await dispatch(getCityList(res.optionId));
                setCityList(response?.data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const getStateId = (e) => {
        let index = e.target.selectedIndex;
        let optionElement = e.target.childNodes[index];
        let optionattr = optionElement.getAttribute("value");

        if (optionattr != null && optionattr.length > 0) {
            const optionData = {
                optionId: optionattr,
                serviceName: "getCityList",
            };
            fetchData(optionData);
        }
    };

    // const handleGenderChanged = async (event) => {
    //     let {name, value} = event.target;
    //     // console.log('gender value', value);
    //     dispatch(change('authPerson', 'genderId', +value));
    //     setSelectedGenderId(+value);
    // }

    const onSubmit = async (data) => {
        // console.log('submit- data', data);
        const payload = {
            firstName: data?.firstName,
            lastName: data?.lastName,
            // birthDate: data?.birthDate,
            genderId: +data?.genderId,
            email: data?.email,
            phoneTypeId: Number(data?.phoneTypeId),
            phoneNumber: data?.phoneNumber,
            addressLine1: data?.addressLine1,
            addressLine2: data?.addressLine2,
            cityId: Number(data?.cityId),
            provinceOrStateId: Number(data?.provinceOrStateId),
            postalOrZipCode: data?.zipcode,
        };

        // console.log('submit- payload', payload);
        try {
            // const decodedTokenData = parseTokenAndGetData().data;
            const response = await dispatch(
              putAgencyAction(payload, "authPerson", "Agency auth-person information updated successfully"/*, decodedTokenData.agencyId*/)
            );
            if (response?.success) {
                await dispatch(reloadData());
                navigate(isAdminRole ? `/admin/agency-profile/logo/${agencyId}` : `/agency/agency-profile/logo`)
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (loadedOnce) {
// console.log('test- ONCE')
//             if (initialValues.genderId != null && initialValues.genderId.length > 0) {
//                 setSelectedGenderId(+initialValues.genderId);
//             }
            if (initialValues.provinceOrStateId != null && initialValues.provinceOrStateId.length > 0) {
                const optionData = {
                    optionId: initialValues.provinceOrStateId,
                    serviceName: "getCityList",
                };
                fetchData(optionData);
            }
        }
    }, [loadedOnce]);

    useEffect(() => {
// console.log('test- loadedOnce', loadedOnce, initialValues)
        if (!loadedOnce && initialValues != null && JSON.stringify(initialValues) !== '{}') {
            setLoadedOnce(true);
        }
    }, [loadedOnce, initialValues]);

    useEffect(() => {
        dispatch(getStateList());
        dispatch(getPhoneTypesList());
        dispatch(getGendersList());
    }, []);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-9">
                        <div
                          className={`col-12`}
                          style={{ fonSize: "24px", fontWeight: 500 }}
                        >
                            Login/Authenticating Person Information
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className={`col-6`}>
                        <div className="mb-2 input-field-main">
                            <Field
                              className="form-control mk-wi"
                              name="firstName"
                              label="First Name"
                              type="text"
                              component={CustomInputField}
                              requiredMark={true}
                              validate={required}
                            />
                        </div>
                    </div>
                    <div className={`col-6`}>
                        <div className="mb-2 input-field-main">
                            <Field
                              className="form-control mk-wi"
                              name="lastName"
                              label="Last Name"
                              type="text"
                              component={CustomInputField}
                              requiredMark={true}
                              validate={required}
                            />
                        </div>
                    </div>
                    <div className="col-10 col-md-10 nurses-agency-tab1 mb-2">
                        <div className="mb-4a input-field-main">
                           {/* <CustomLabel requiredMark={true} className="form-label1" labelText="Please Select your gender" />
                            <div className="row">
                                {gendersArray.length > 0 && gendersArray.map((item, index) => (
                                  <div key={index} className="col-4 col-md-4">
                                      <div className="form-check">
                                          <CustomRadioField
                                            label={item.name}
                                            className="form-check-input"
                                            type="radio"
                                            name="genderId"
                                            // id={item.id}
                                            value={item.id}
                                            // selected={initialValues.genderId}
                                            selected={selectedGenderId}
                                            onChange={(e) => handleGenderChanged(e)}
                                          />
                                      </div>
                                  </div>
                                ))}
                            </div>*/}
                            <Field
                              className="form-control1 input-field-main"
                              name="genderId"
                              label="Please select your gender"
                              component={RadioOptionsComponent}
                              requiredMark={true}
                              // validate={(val) => console.log('VALIDATION- value', val)}
                              validate={required}
                              optionsList={gendersArray}
                              handleGenderChanged={({target}) => {
                                  if (target?.value != null) dispatch(change('authPerson', 'genderId', +target.value));
                              }}
                            />
                        </div>
                    </div>
                </div>
                <div className="row mt-3a">
                    <div className={`col-4`}>
                        <div className="mbx-2 input-field-main">
                            <Field
                              className="form-control mk-wi"
                              name="phoneNumber"
                              label="Phone Number"
                              type="text"
                              component={CustomInputField}
                              requiredMark={true}
                              validate={required}
                            />
                        </div>
                    </div>
                    <div className={`col-5`}>
                        <div className="mbx-12 input-field-main">
                            <Field
                              label="Phone Type"
                              className="form-control mk-wi"
                              name="phoneTypeId"
                              component={CustomDropDownNewField}
                              // onChange={({ target }) => handleChangeState(target)}
                              requiredMark={true}
                              validate={required}
                            >
                                <option value="">Select Phone Type</option>
                                {(phoneTypesArray || [])?.map((res, index) => (
                                  <option key={index} value={res.id}>
                                      {res.name}
                                  </option>
                                ))}
                            </Field>
                        </div>
                    </div>

                    {/*<div className={`col-2`}>
                     <div className="mb-2 input-field-main">
                     <CustomLabel labelText="Phone" />
                     <Field
                     className="form-control mk-wi"
                     name={`phoneTypeId`}
                     component={CustomDropDownField}
                     // onChange={({ target }) => handleChangeState(target)}
                     >
                     <option value="">Select Phone</option>
                     {(phoneTypeList || []).map((res, index) => (
                     <option key={index} value={res.id}>
                     {res.name}
                     </option>
                     ))}
                     </Field>
                     </div>
                     </div>

                     <div className={`col-4`}>
                     <div className="mb-2 input-field-main">
                     <Field
                     className="form-control mk-wi"
                     name={`phoneNumber`}
                     label=". "
                     type="text"
                     component={CustomInputField}
                     />
                     </div>
                     </div>*/}
                    <div className="input-field-main">
                        <Field
                          className="form-control mk-wi"
                          name="email"
                          label="Email address"
                          type="text"
                          component={CustomInputField}
                          // validate={emailValidation}
                          placeholder={'Enter email address'}
                          requiredMark={true}
                          validate={required}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12">
                        <div className="mb-4 input-field-main">
                            <Field
                              className="form-control mk-wi"
                              name="addressLine1"
                              label="Address Line 1"
                              type="text"
                              component={CustomInputField}
                              placeholder={'Enter address'}
                              requiredMark={true}
                              validate={required}
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="mb-4 input-field-main">
                            <Field
                              className="form-control mk-wi"
                              name="addressLine2"
                              label="Address Line 2"
                                type="text"
                                component={CustomInputField}
                                placeholder={'Enter address'}
                            />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="mb-4 input-field-main">
                            <CustomLabel labelText="State" requiredMark={true} />
                            <Field
                              className="form-control"
                              name="provinceOrStateId"
                              component={CustomDropDownField}
                              validate={required}
                              requiredMark={true}
                              onChange={(e) => getStateId(e)}
                            >
                                <option value="">Please select</option>
                                {statesArray?.map((res, index) => {
                                    return <option key={index} value={res.id}>{res.name}</option>
                                })}
                            </Field>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="mb-4 input-field-main">
                            <CustomLabel labelText="City" requiredMark={true} />
                            <Field
                              className="form-control read-only1"
                              name="cityId"
                              component={CustomDropDownField}
                              validate={required}
                              requiredMark={true}
                            >
                                <option value="">Please select</option>
                                {cityList?.map((res, index) => {
                                    return <option key={index} value={res.id}>{res.name}</option>
                                })}
                            </Field>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="mb-4 input-field-main">
                            <Field
                                className="form-control mk-wi"
                                name="zipcode"
                                label="Zip Code"
                                type="text"
                                component={CustomInputField}
                                validate={required}
                                requiredMark={true}
                            />
                        </div>
                    </div>
                </div>
                <div
                    className="d-flex justify-content-center"
                    style={{marginTop: "40px"}}
                >
                    <PrimaryButton
                        type="submit"
                        className="btn-view-repo gap-2 px-5 py-2"
                        text={"Next"}
                        onClick={() => undefined}
                    />
                </div>
            </form>
        </>
    )
};

const mapStateToProps = state => {
    // console.log('state', state)
    const {common, agencyProfile} = state;
    const data = agencyProfile?.agencyProfile?.data;
    console.log('auth person', data)

    return {
        statesArray: common.stateList || [],
        phoneTypesArray: common.phoneTypesList || [],
        gendersArray: common.genders || [],
        initialValues: {
            firstName: data?.authPersonDetails?.authUserFirstName || '',
            lastName: data?.authPersonDetails?.authUserLastName || '',
            genderId: data?.authPersonDetails?.authUserGenderId?.toString() || '',
            email: data?.authPersonDetails?.authUserEmail || '',
            phoneTypeId: data?.authPersonDetails?.authUserPhoneTypeId?.toString() || '',
            phoneNumber: data?.authPersonDetails?.authUserPhoneNumber || '',
            addressLine1: data?.authPersonAddress?.authUserAddressLine1 || '',
            addressLine2: data?.authPersonAddress?.authUserAddressLine2 || '',
            cityId: data?.authPersonAddress?.authUserCityId?.toString() || '',
            provinceOrStateId: data?.authPersonAddress?.authUserProvinceOrStateId?.toString() || '',
            zipcode: data?.authPersonAddress?.authUserPostalOrZipCode || '',
        }
    }
}


export default connect(mapStateToProps)(
    reduxForm({form: "authPerson", enableReinitialize: true})(Address)
);
