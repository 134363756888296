import React, { Fragment } from "react";
import CustomLabel from "../fields/CustomLabel";
import {fieldBottomMarginClass} from "../../appConstants";

const CustomDropDownNewField = ({input: { value: inputValue, ...restOfInput}, showErrorsFixed = true, ...field}) => {
  let containerClassName = `w-100 h-auto p-0 m-0 ${fieldBottomMarginClass} position-relative`;
  return (
    <div className={containerClassName}>
      {field.label != null && <CustomLabel labelFor={field.name} requiredMark={field.requiredMark} readOnly={field.readOnly} className={field.labelClass || null} labelText={field.label}/>}
      <select
        required={field.required || false}
        onChange={field.onChange}
        className={`${field.className}${field.readOnly ? ' read-only' : ''}`}
        disabled={field.disabled || false}
        name={field.name}
        id={field.name}
        // value={field.value}
        value={inputValue}
        {...restOfInput}
      >
        {field.children}
      </select>
      {field.handleClear && <span title="clear" className={`position-absolute top-38 end-2px btn btn-vs btn-close btn-outline-danger`} onClick={field.handleClear}>&nbsp;</span>}
      {field.meta.touched && field.meta.error && (
        <span className={`field-error${showErrorsFixed ? ' position-absolute bottom-field-error start-0' : ''}`}>{field.meta.error}</span>)}
    </div>
  );
};

export default CustomDropDownNewField;
