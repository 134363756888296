import React from "react";
import stringHelperFunctions from "../../helpers/string.helpers";
import booleanHelperFunctions from "../../helpers/boolean.helpers";

// const CustomCheckboxField = ({
//                                  value,
//                                  input,
//                                  id,
//                                  autoFocus,
//                                  readOnly,
//                                  name,
//                                  label,
//                                  disabled,
//                                  required,
//                                  className,
//                                  meta: {touched, error, warning, active, visited},
//                                  ...custom
//                              }) => {
const CustomCheckboxField = ({ input, meta: { touched, error, warning }, style, ...custom }) => {
  let { name, value } = input;
  const fieldId = stringHelperFunctions.camelCaseToSnakeCase(name);
  const { className, label, requiredMark, readOnly, disabled, labelClass, showErrorsFixed = true, ...rest1 } = custom;
  let { checkedValue = true, ...rest2 } = rest1;
  if (typeof checkedValue === "string") checkedValue = booleanHelperFunctions.parseBoolean(checkedValue);

  // const elementId = name || "";
// console.log('test- CustomCheckboxField', name, elementId, label)
// console.log('test- CustomCheckboxField', input, custom)
//   console.log('test- CustomCheckboxField', label, typeof checkedValue, checkedValue, typeof input.value, input.value, custom)
  return (
    <div className="d-flex gap-1">
      <input
        {...input}
        name={name || undefined}
        id={fieldId || undefined}
        value={value}
        checked={input.value === checkedValue}
        type="checkbox"
        disabled={disabled || false}
        className={className + " me-1" || ""}
        // {...custom}
      />
      <label className="form-check-label mb-0" htmlFor={fieldId || undefined}>
        {label}
      </label>
      <div>
        {touched && error && <span className="field-error">{error}</span>}
      </div>
    </div>
  );
};

export default CustomCheckboxField;
