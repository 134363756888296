import React, {useEffect} from "react";
import {connect, useDispatch} from "react-redux";
import {Field, reduxForm} from "redux-form";
import CustomInputField from "../../../../../../../sharedComponents/reduxfields/customInputField";
import CustomTextareaField from "../../../../../../../sharedComponents/reduxfields/customTextareaField";
import PrimaryButton from "../../../../../../../sharedComponents/buttons/PrimaryButton";
import {HttpsAction} from "../../../../../../../commonApiFunction/httpsAction";
import {useParams} from "react-router-dom";
import {required} from "../../../../../../../helpers/validation";

const NewAllergy = ({onClose, clientId, handleSubmit, reload, isEdit}) => {

    // const {clientId} = useParams();
    const dispatch = useDispatch();

    const onSubmit = ({medication, notes}) => {
        dispatch(HttpsAction({
            method: 'POST',
            apiSection: 'client',
            apiName: 'healthcareAllergies',
            queryParameter: `/${clientId}`,
            data: {
                recordType: "new",
                medication,
                note: notes
            },
            positiveCallBack: () => {
                reload();
            }
        }));
        onClose();
    };

    useEffect(() => {
        return () => {
            dispatch({
                type: `ADD_CLIENT_REMOVE_SINGLE_ALLERGY_DATA`,
                payload: '',
                name: `singleAllergy`,
                meta: {type: `REMOVE_SINGLE_ALLERGY_DATA`}
            })
        }
    }, []);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="mb-4 input-field-main">
                        <div className="col-12">
                            <Field
                                className="form-control mk-wi"
                                name="medication"
                                label="Medication"
                                type="text"
                                component={CustomInputField}
                                requiredMark={true}
                                validate={required}
                            />
                        </div>
                    </div>
                    <div className="mb-4 input-field-main">
                        <div className="col-12">
                            <Field
                                className="form-control mk-wi"
                                name="notes"
                                label="Notes"
                                type="text"
                                component={CustomTextareaField}
                            />
                        </div>
                    </div>
                </div>
                <div
                    className="d-flex flex-row gap-3"
                    style={{marginTop: "20px"}}
                >
                    <PrimaryButton
                        type="submit"
                        className="btn-view-repo gap-2 px-3 py-2"
                        text={"Save"}
                        onClick={() => undefined}
                    />
                    <PrimaryButton
                        type="button"
                        className="btn btn-primary border-btn-info px-3"
                        text={"Cancel"}
                        onClick={() => onClose()}
                    />
                </div>
            </form>
        </>
    )
};

export default connect()(
    reduxForm({form: "newAllergy", enableReinitialize: true})(NewAllergy)
);
