import React from "react";
import { NavLink } from "react-router-dom";

const DashboardCard = ({title,svg,number,percentage,link='#'}) => {
  return (
    <div className="col-12 col-sm-4">
      <div className="widget-chart widget-chart2 text-left mb-3 card-shadow-primary  card  px-3 py-3  border-0">
        <div className="widget-chat-wrapper-outer">
          <div className="widget-chart-content">
            <div className="widget-title text-uppercase">
              {title}
              <span className="float-end">
               {svg}
              </span>
            </div>
            <div className="widget-numbers widget-numbers-3 fsize-4 mb-0 w-100">
              <div className="widget-chart-flex align-items-center">
                <div className=" w-100 float-start widget-chart-data">{number}</div>
                <div className="w-100">
                  <div className="widget-chart-data-text float-start d-flex mt-2">
                    <span className="chart-values">
                      {/*<svg*/}
                      {/*  width="26"*/}
                      {/*  height="16"*/}
                      {/*  viewBox="0 0 26 16"*/}
                      {/*  fill="none"*/}
                      {/*  xmlns="http://www.w3.org/2000/svg"*/}
                      {/*>*/}
                      {/*  <path*/}
                      {/*    d="M17.4287 2H24.2859V8.85714"*/}
                      {/*    stroke="#F7685B"*/}
                      {/*    strokeWidth="3.42857"*/}
                      {/*    strokeLinecap="round"*/}
                      {/*    strokeLinejoin="round"*/}
                      {/*  />*/}
                      {/*  <path*/}
                      {/*    d="M24.2857 2L14.6 11.6857C14.4398 11.8428 14.2244 11.9307 14 11.9307C13.7756 11.9307 13.5602 11.8428 13.4 11.6857L9.45714 7.74286C9.29692 7.58581 9.0815 7.49784 8.85714 7.49784C8.63278 7.49784 8.41737 7.58581 8.25714 7.74286L2 14"*/}
                      {/*    stroke="#F7685B"*/}
                      {/*    strokeWidth="3.42857"*/}
                      {/*    strokeLinecap="round"*/}
                      {/*    strokeLinejoin="round"*/}
                      {/*  />*/}
                      {/*</svg>*/}
                     &nbsp;
                    </span>
                    {/*<span className="chart-shows">{percentage} This week</span>*/}
                  </div>
                  <div className="float-end chart-view-btn mt-2">
                    <span>
                      <NavLink to={link} className="text-decoration-none">See all</NavLink>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardCard;
