import React, {useState, useEffect, useCallback} from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {change, getFormValues, reduxForm} from "redux-form";
import {NavLink, useLocation, useParams} from "react-router-dom";
import PostJobForm from "./postJobForm";
import {getCityList, getProfessionList, getStateList} from "../../../services/commonServices";
import {
  postJob,
  getJobPostingCreationData,
} from "../../../services/jobPosting";
import { useNavigate } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import PrimaryButton from "../../../sharedComponents/buttons/PrimaryButton";
import { HttpsAction } from "../../../commonApiFunction/httpsAction";
import {scrollToErrors} from "../../../helpers/formMethods";
import {useQueryClient} from "@tanstack/react-query";
import useEscapeKey from "../../../utils/hooks/useEscapeKey";
import BackLink from "../../../sharedComponents/buttons/BackLink";
import numberHelperFunctions from "../../../helpers/number.helpers";
import stringHelperFunctions from "../../../helpers/string.helpers";
import {parseTokenAndGetData} from "../../../helpers/apiMethods";
import {getAgencyWalletDetailsAction} from "../../../services/transaction";
import {useGlobalStorageEvent} from "../../../utils/hooks/useWindowEvent";
import useCustomNav from "../../../utils/hooks/useCustomNav";

const PostJob = ({ handleSubmit, submitFailed, initialValues, isAdmin, walletDetails, formData}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { id, agencyId } = useParams();

  const { goTo: gotoPostedJobsListPage } = useCustomNav(isAdmin ? `/admin/agency/servicerequest/postedjobs/pending` : '/agency/servicerequest/postedjobs/pending');

  const agencyBalance = numberHelperFunctions.nullIfEmptyFloat(walletDetails?.balance) || 0;
  const totalAmountReservedCalculated = numberHelperFunctions.nullIfEmptyFloat(walletDetails?.totalAmountReservedCalculated) || 0;

  const getJobById = useSelector((store) => store.serviceRequest?.getJobById?.data);

  const statesArray = queryClient.getQueryData(['list-provinces-states', 233]);

  const [cityList, setCityList] = useState([]);
  const [skills, setSkills] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [isSelectedValid, setSelectedValid] = useState(true);
  const [description, setDescription] = useState("");
  const [isDescriptionValid, setIsDescriptionValid] = useState(true);
  const [selectedSpecialtiesOptions, setSelectedSpecialtiesOptions] = useState([]);
  const [loadedOnce, setLoadedOnce] = useState(false);
  const [cityLoaded, setCityLoaded] = useState(false);

  const [showBackButton, setShowBackButton] = useState((location != null && location.state != null && location.state.showBackButton && location.state.backLink != null && location.state.backButtonTextSuffix != null) || false);

  useEscapeKey(() => {
    navigate(isAdmin ? `/admin/agency-profile/postedJobs/${agencyId}` : "/agency/servicerequest/postedjobs");
  });

  const getStateId = (e) => {
    let index = e.target.selectedIndex;
    let optionElement = e.target.childNodes[index];
    let optionattr = optionElement.getAttribute("value");
    if (optionattr !== "0") {
      /// IIFE
      (async () => {
        const response = await dispatch(getCityList(optionattr));
        setCityList(response?.data);
      })();
    }
  };

  const updateCompleteAddress = (place) => {
    // console.log('places-data', data);
    if (place != null) {
      setCityLoaded(true);
      setSelectedPlace(place);
      if (place.provinceOrStateName != null && place.provinceOrStateName.length > 0) {
        setCityList([]);
        const selectedStateItem = statesArray.find(s => s.name === place.provinceOrStateName);
        const selectedStateIdStr = String(selectedStateItem.id);
        // console.log('places-data - selectedStateItem', selectedStateItem);
        dispatch(change("postJob", 'provinceOrStateId', selectedStateIdStr));

        // IIFE
        (async () => {
          const response = await dispatch(getCityList(selectedStateIdStr));
          setCityList(response?.data);
        })();
      }
    }
    setSelectedValid(place != null);
  };

  const getDescription = (data) => {
    setDescription(data);
    if (data === "<p><br></p>" || data === "") {
      setIsDescriptionValid(false);
    } else {
      setIsDescriptionValid(true);
    }
  };

  const getSelectedOptions = (selectedOptions) => {
    setSelectedSpecialtiesOptions(selectedOptions)
  }

  function loadAgencyWallet() {
    const decodedTokenData = parseTokenAndGetData().data;
    dispatch(getAgencyWalletDetailsAction("agencyWalletDetails", decodedTokenData.walletId));
  }

  const storageEventHandler = (event) => {
    if (event?.detail?.key === "balance-updated") {
      loadAgencyWallet();
    }
  };

  // const onSubmit2 = async (values) => {}

  const onSubmit = async (values) => {
      console.log({selectedPlace})
      const skillIds = [];
      selectedSpecialtiesOptions.forEach(v => skillIds.push(v.value))
      if (id) {
          let payload = Object.assign({}, values);
          const startDate = new Date(values.startDate);
          const endDate = new Date(values.endDate);
          if (startDate > endDate) {
              return toastr.error("Invalid Date Range");
          }

        payload.title = stringHelperFunctions.nullIfEmpty(values.title);
        payload.classId = numberHelperFunctions.nullIfEmpty(values.classId);
        payload.skillIds = skillIds;
        payload.requiredExperienceYears = numberHelperFunctions.nullIfEmpty(values.requiredExperienceYears);
        payload.description = stringHelperFunctions.nullIfEmpty(description);
        payload.startDate = stringHelperFunctions.nullIfEmpty(values.startDate);
        payload.endDate = stringHelperFunctions.nullIfEmpty(values.endDate);
        payload.startTime = stringHelperFunctions.nullIfEmpty(values.startTime);
        payload.endTime = stringHelperFunctions.nullIfEmpty(values.endTime);
        payload.shiftTimingsId = numberHelperFunctions.nullIfEmpty(values.shiftTimingsId);
        payload.shiftHours = numberHelperFunctions.nullIfEmptyFloat(values.shiftHours);
        payload.payRateTypeId = numberHelperFunctions.nullIfEmpty(values.payRateTypeId);
        payload.budgetAmount = numberHelperFunctions.nullIfEmptyFloat(values.budgetAmount);
        // payload.currencyCountryId = values.currencyCountryId || 233; // 233 for USD
        payload.currencyCountryId = numberHelperFunctions.nullIfEmpty(values.currencyCountryId);

        payload.location = numberHelperFunctions.nullIfEmpty(selectedPlace.address);
        payload.latitude = numberHelperFunctions.nullIfEmptyFloat(selectedPlace.latLng.lat, true);
        payload.longitude = numberHelperFunctions.nullIfEmptyFloat(selectedPlace.latLng.lng, true);
        payload.cityId = numberHelperFunctions.nullIfEmpty(values.cityId);
        payload.provinceOrStateId = numberHelperFunctions.nullIfEmpty(values.provinceOrStateId);
        payload.postalOrZipCode = stringHelperFunctions.nullIfEmpty(values.postalOrZipCode);

        // payload.templateTypeId = values.templateTypeId != null ? +values.templateTypeId : undefined;
        payload.templateTypeId = numberHelperFunctions.nullIfEmpty(values.templateTypeId, true);
        delete payload.iAgree;
// console.log('payload', payload)
        let queryParameter = `/${id}`;
        if (isAdmin) {
          queryParameter = `/${agencyId}`+queryParameter
        }
        dispatch(
            HttpsAction({
              method: "PUT",
              apiSection: "jobPosting",
              apiName: "updateJob",
              queryParameter,
              data: payload,
              positiveCallBack: ({data}) => {
                if (data?.success) {
                  // navigate(isAdmin ? `/admin/agency-profile/postedJobs/${agencyId}` : "/agency/servicerequest/postedjobs");
                  gotoPostedJobsListPage();
                }
              },
            })
        );
    } else {
      let payload = Object.assign({}, values);
      const startDate = new Date(values.startDate);
      const endDate = new Date(values.endDate);
      if (
        selectedPlace !== null &&
        String(description) !== "" &&
        String(description) !== "<p><br></p>" &&
        startDate <= endDate
      ) {

        payload.title = stringHelperFunctions.nullIfEmpty(values.title);
        payload.classId = numberHelperFunctions.nullIfEmpty(values.classId);
        payload.skillIds = skillIds;
        payload.requiredExperienceYears = numberHelperFunctions.nullIfEmpty(values.requiredExperienceYears);
        payload.description = stringHelperFunctions.nullIfEmpty(description);
        payload.startDate = stringHelperFunctions.nullIfEmpty(values.startDate);
        payload.endDate = stringHelperFunctions.nullIfEmpty(values.endDate);
        payload.startTime = stringHelperFunctions.nullIfEmpty(values.startTime);
        payload.endTime = stringHelperFunctions.nullIfEmpty(values.endTime);
        payload.shiftTimingsId = numberHelperFunctions.nullIfEmpty(values.shiftTimingsId);
        payload.shiftHours = numberHelperFunctions.nullIfEmpty(values.shiftHours);
        payload.payRateTypeId = numberHelperFunctions.nullIfEmpty(values.payRateTypeId);
        payload.budgetAmount = numberHelperFunctions.nullIfEmptyFloat(values.budgetAmount);
        // payload.currencyCountryId = values.currencyCountryId || 233; // 233 for USD
        payload.currencyCountryId = numberHelperFunctions.nullIfEmpty(values.currencyCountryId);

        payload.location = numberHelperFunctions.nullIfEmpty(selectedPlace.address);
        payload.latitude = numberHelperFunctions.nullIfEmptyFloat(selectedPlace.latLng.lat, true);
        payload.longitude = numberHelperFunctions.nullIfEmptyFloat(selectedPlace.latLng.lng, true);
        payload.cityId = numberHelperFunctions.nullIfEmpty(values.cityId);
        payload.provinceOrStateId = numberHelperFunctions.nullIfEmpty(values.provinceOrStateId);
        payload.postalOrZipCode = stringHelperFunctions.nullIfEmpty(values.postalOrZipCode);

        // payload.templateTypeId = values.templateTypeId != null ? +values.templateTypeId : undefined;
        payload.templateTypeId = numberHelperFunctions.nullIfEmpty(values.templateTypeId, true);
        delete payload.iAgree;
        console.log('payload', payload)

        try {
          const response = await dispatch(postJob(payload, "postJob"));
          if (response?.success) {
            // navigate("/agency/servicerequest/postedjobs");
            gotoPostedJobsListPage();
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        if (selectedPlace === null) {
          setSelectedValid(false);
        } else if (
          String(description) === "" &&
          String(description) === "<p><br></p>"
        ) {
          setIsDescriptionValid(false);
        } else if (startDate > endDate) {
          toastr.error("Invalid Date Range");
        }
      }
    }
  };

  useEffect(() => {
    if (!cityLoaded && initialValues?.cityId != null && initialValues?.cityId.length > 0 && cityList != null && cityList.length > 0) {
      setCityLoaded(true);
      dispatch(change("residentialAddress", 'cityId', initialValues?.cityId));
    }
  }, [initialValues?.cityId, cityList]);

  useEffect(() => {
    if (selectedPlace != null && cityList != null && cityList.length > 0) {
      if (selectedPlace.cityName != null && selectedPlace.cityName.length > 0) {
        const selectedStateItem = cityList.find(c => c.name === selectedPlace.cityName)
        // console.log('places-data - selectedCityItem', selectedStateItem);
        if (selectedStateItem != null)
          dispatch(change("postJob", 'cityId', String(selectedStateItem.id)));
      }
    }
  }, [cityList]);

  useEffect(() => {
    if (getJobById && id) {
      setSelectedPlace({
        address: getJobById?.location || '',
        latLng: {lat: getJobById?.jobLatitude?.toString(), lng: getJobById?.jobLongitude?.toString()}
      });

      // bind city on load
      const optionData = {
        optionId: Number(getJobById.jobProvinceOrStateId),
        serviceName: "getCityList",
      };
      setCityList([]);
      if (getJobById.jobProvinceOrStateId !== "0") {
        // IIFE
        (async () => {
          const response = await dispatch(getCityList(getJobById.jobProvinceOrStateId));
          setCityList(response?.data);
          dispatch(change("postJob", 'cityId', getJobById.jobCityId));
        })();
      }
    }
  }, [getJobById && id])

  useEffect(() => {
    if (loadedOnce) {
      setLoadedOnce(false);
      dispatch({
        type: `ADD_SERVICE_REQUEST_REMOVE_GET_JOB_BY_ID_DATA`,
        payload: {},
        name: 'getJobById',
        meta: { type: "REMOVE_GET_JOB_BY_ID_DATA" },
      });
      dispatch(HttpsAction({
        apiSection: 'findNurse',
        apiName: 'professionList',
        reduxKeyName: 'professionList',
        reducerName: 'POST_JOBS',
        actionType: 'GET_PROFESSION_LIST'
      }));
      // dispatch(HttpsAction({
      //   apiSection: 'commonAPI',
      //   apiName: 'specialties',
      //   reduxKeyName: 'specialties',
      //   reducerName: 'POST_JOBS',
      //   actionType: 'GET_SPECIALTIES_LIST'
      // }));
    }
  }, [loadedOnce]);

  useEffect(() => {
    if (submitFailed) {
      isSelectedValid === null && setSelectedValid(false);
      description === "<p><br></p>" ||
        (description === "" && setIsDescriptionValid(false));
    }
  }, [submitFailed]);

  useEffect(() => {
    if (id) {
      let queryParameter = `/${id}`;
      if (isAdmin) {
        queryParameter = `/${agencyId}`+queryParameter
      }
      dispatch(
        HttpsAction({
          apiSection: "jobPosting",
          apiName: "jobDetails",
          queryParameter,
          reducerName: "SERVICE_REQUEST",
          reduxKeyName: "getJobById",
          actionType: "GET_JOB_BY_ID",
          positiveCallBack: ({data}) => {
            setSelectedSpecialtiesOptions((data?.data?.requiredSkills || []).map(v => ({value: v.id, label: v.skill})))
          }
        })
      );
    }

    return () => {
      dispatch({
        type: `ADD_SERVICE_REQUEST_GET_JOB_BY_ID`,
        payload: "",
        name: "jobDetails",
        meta: { type: "GET_JOB_BY_ID" },
      });
    };
  }, [id]);

  useEffect(() => {
    loadAgencyWallet();
    // window.addEventListener("storage", storageEventHandler);
    setLoadedOnce(true);

    // on destroy
    // return () => {
    //   window.removeEventListener("storage", storageEventHandler);
    // };
  }, []);

  useGlobalStorageEvent(storageEventHandler);

  return (
    <div className="app-main__outer" style={isAdmin ? {paddingLeft: 0} : {}}>
      <div className="main-service-request-page">
        <div className="app-main__inner bg-white">
          <div className="col-12 page-back-btn">
            {/*<BackLink link={isAdmin ? `/admin/agency-profile/postedJobs/${agencyId}` : "/agency/servicerequest/postedjobs"} linkText={isAdmin ? "Jobs" :"Posted Jobs"} />*/}
            <BackLink link={showBackButton && location.state != null && location.state.backLink != null ? location.state.backLink : (isAdmin ? `/admin/agency-profile/postedJobs/${agencyId}` : "/agency/servicerequest/postedjobs")}
                      linkText={showBackButton && location.state != null && location.state.backButtonTextSuffix != null ? location.state.backButtonTextSuffix : `${isAdmin ? "Jobs" :"Posted Jobs"}`} />
          </div>
          <h3 className="pt-4 pb-4">{isAdmin ? "Job Details" : "Publish on Portal"}</h3>
        </div>
        <div className="app-main__inner service-request-inner">
          <div className="tab-content">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='container'>
                <div className="row">
                  <div className="col-sm-12 pl-0 pr-0">
                    <div className="tab-inner-heading mb-0 text-black">Service Details</div>
                  </div>
                  <div className="neo1 col-12 col-xl-10 py-2 px-4 m-0 border border-black rounded-4 border-opacity-10">
                    <PostJobForm
                      statesArray={statesArray}
                      cityList={cityList}
                      getSelectedOptions={getSelectedOptions}
                      selectedSpecialtiesOptions={selectedSpecialtiesOptions}
                      getStateId={(e) => getStateId(e)}
                      updatePlace={updateCompleteAddress}
                      description={getDescription}
                      isSelectedValid={isSelectedValid}
                      isDescriptionValid={isDescriptionValid}
                      agencyBalance={agencyBalance}
                      totalAmountReserved={totalAmountReservedCalculated}
                      formData={formData}
                    />
                    {/*<div className="form-group d-flex flex-row w-100">
                      <div
                        className="d-flex justify-content-center w-100"
                        style={{marginTop: "40px"}}
                      >
                        <PrimaryButton
                          type="submit"
                          className="btn-view-repo gap-2 px-5 py-2 my-0"
                          text={"Submit"}
                          onClick={() => undefined}
                          disabled={totalCost > agencyBalance}
                        />
                      </div>
                    </div>*/}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { auth, transaction, serviceRequest: { getJobById: { data = null } = { data: null} } } = state;
  const walletDetails = transaction?.agencyWalletDetails || null;

  return {
    isAdmin: auth?.authData?.isAdmin || false,
    agencyId: auth?.authData?.agencyId || auth.agencyId,
    walletDetails,
    formData: getFormValues('postJob')(state),
    initialValues: {
      title: data?.title,
      classId: data?.requiredClassId?.toString(),
      // skillIds: data?.skillIds,
      requiredExperienceYears: data?.requiredExperienceYears?.toString(),
      // description: data?.description,
      startDate: data?.startDate?.toString(),
      endDate: data?.endDate?.toString(),
      startTime: data?.startTime,
      endTime: data?.endTime,
      shiftTimingsId: data?.shiftTimingsId?.toString(),
      shiftHours: data?.shiftHours?.toString(),
      payRateTypeId: data?.payRateTypeId?.toString(),
      budgetAmount: data?.budgetAmount?.toString(),
      // currencyCountryId: data?.currencyCountryId?.toString(),
      // location: data?.jobLocation,
      // latitude: data?.jobLatitude?.toString(),
      // longitude: data?.jobLongitude?.toString(),
      cityId: data?.jobCityId?.toString(),
      provinceOrStateId: data?.jobProvinceOrStateId?.toString(),
      postalOrZipCode: data?.jobPostalOrZipCode?.toString(),
      templateTypeId: data?.templateTypeId?.toString(),
    },
  };
};

// const validate = (values, props) => {
//   const errors = {}
//   /* calculate errors here by appending theim to errors object */
//   if(Object.keys(errors) > 0) {
//     //You can concatenate each error in a string
//     for(let key in errors) errors._error += key + ': ' + errors[key]
//     //or juste put the errors object in the global error property
//     errors._error = {...errors}
//   }
//   return errors
// }

export default connect(mapStateToProps)(
  reduxForm({ form: "postJob", enableReinitialize: true,
    onSubmitFail: (errors, dispatch, submitError, props) => {
      scrollToErrors();
      console.log('errors', errors)
      // console.log('errors-props', props)
    }
  })(PostJob)
);
