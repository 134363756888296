import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Nurses from "./components/Nurses/Nurses";
import SearchFilter from "./components/Nurses/searchFilter";
import { ReactComponent as SearchIcon } from "./assets/image/ion_search.svg";
import { useDispatch, useSelector } from "react-redux";
import { HttpsAction } from "../commonApiFunction/httpsAction";
import {
  getEmploymentTypesList,
  getLanguagesList,
  getPreferredShiftTimingsList,
  // getSpecialtiesList,
  getProfessionList,
  getStateList,
  getWorkExperienceList,
  getPayRateTypeList
} from "../services/commonServices";
import Pagination from "../sharedComponents/pagination";
import { debounce } from "lodash";
import moment from "moment/moment";
import { pageLimit } from "../appConstants";

const FindNursesList = () => {
  const params = useParams();
  const dispatch = useDispatch();

  const paginationData = useSelector(
    (state) => state.nurseListing?.findNurse?.data ?? {}
  );

  const [search, setSearch] = useState('')
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState({
    classId: null,
    skillId: null,
    selectedFromDate: null,
    selectedToDate: null,
    payRateTypeId: null,
    maxRate: 5,
    searchText: null,
  });

  const handleChangeProfessionList = (event) => {
    setFilter(prevState => ({ ...prevState, classId: event.target.value, skillId: null }));
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleChangeFromDate = (event) => {
    const formattedFromDate = moment(event.target.value).format('YYYY-MM-DD');
    setFilter(prevState => ({ ...prevState, selectedFromDate: formattedFromDate }));
  };
  const handleChangeToDate = (event) => {
    const formattedToDate = moment(event.target.value).format('YYYY-MM-DD');
    setFilter(prevState => ({ ...prevState, selectedToDate: formattedToDate }));
  };
  
  const onChangeRange = debounce((e) => {
    // setFilter({ ...filter, [e.target.name]: e.target.value });
    setFilter(prevState => ({ ...prevState, maxRate: e.target.value }));
  }, 500);

  const onAddressRange = debounce((e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  }, 500);

  const onSearch = debounce((e) => {
    setSearch(e.target.value);
    setFilter(prevState => ({ ...prevState, searchText: e.target.value }));
  }, 500);

  useEffect(() => {
    const {
      classId,
      skillId,
      selectedFromDate,
      selectedToDate,
      payRateTypeId,
      maxRate,
      searchText,
    } = filter;
    // define an array of keys
    const filterKeys = ['searchText', 'languageId', 'drivingLicenseProvinceOrStateId', 'experienceYears', 'payRateTypeId', 'shiftTimingsId'/*, 'skillId'*/, 'location'];

    // iterate through keys and only add those with a value in the filter object to the query
    let queryParameter = filterKeys.reduce((queryString, key) => {
      if (filter[key] && key !== 'location') {
        return queryString + `&${key}=${encodeURIComponent((filter[key] || "").replace(/\s/g, ''))}`;
      } if (filter[key] && key === 'location') {
        return queryString + `&${key}=${encodeURIComponent((filter[key] || ""))}`;
      } else {
        return queryString;
      }
    }, `?page=${encodeURIComponent(currentPage)}&pageSize=${encodeURIComponent(pageLimit)}`);

    // add rest of the parameters
    if (payRateTypeId != null)
      queryParameter += `&maxRate=${encodeURIComponent((String(maxRate) || "").replace(/\s/g, ''))}`;
    if (classId) {
      queryParameter += `&classId=${encodeURIComponent((classId || "").replace(/\s/g, ''))}`;
    }
    if (skillId) {
      queryParameter += `&skillId=${encodeURIComponent((skillId || "").replace(/\s/g, ''))}`;
    }
    if (selectedFromDate != null && selectedFromDate !== 'Invalid date') {
      queryParameter += `&fromDate=${encodeURIComponent(selectedFromDate || "").replace(/\s/g, '')}`;
    }
    if (selectedToDate != null && selectedToDate !== 'Invalid date') {
      queryParameter += `&toDate=${encodeURIComponent(selectedToDate || "").replace(/\s/g, '')}`;
    }

    dispatch(
      HttpsAction({
        method: "GET",
        actionType: "FIND_NURSE",
        apiSection: "findNurse",
        // apiName: "nurseList",
        apiName: "findList",
        queryParameter,
        reduxKeyName: "findNurse",
        reducerName: "NURSE",
      })
    );
  }, [currentPage, filter]);

  const onFilterChange = (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
  };

  const onSkillsChanged = (e) => {
    setFilter(prevState => ({ ...prevState, skillId: e.target.value }));
  };

  return (
    <>
      <div className="app-main__outer">
        <div className="app-main__inner service-request bg-white">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 col-md-7 col-xl-8 search-wrapper header-search">
              <div className="input-group align-items-center">
                <span className="input-group-append">
                  <button className="btn ms-n5" type="button">
                    <SearchIcon />
                  </button>
                </span>
                <input
                  className="form-control border-end-0 me-3"
                  type="search"
                  name="name"
                  placeholder="Search for anything"
                  onChange={onSearch}
                />
                {/*<button
                  type="button"
                  className="search_btn"
                  style={{ cursor: "pointer" }}
                  onClick={onSearchButtonClick}
                >
                  Search
                </button>*/}
              </div>
            </div>
            {/* <div className="col-12 col-md-4 col-xl-3 d-flex justify-content-end mt-2 mt-md-0">
              <button
                type="button"
                className="view_map_btn"
                onClick={() => () => {}}
              >
                VIEW HEAT MAP
              </button>
            </div> */}
          </div>
          {/* <label className="showing">
            Showing Results <span>1 - 10</span> of <span>20</span>
          </label> */}
          <label className="showing">
            Showing Results <span>{currentPage} - {paginationData.count}</span> of <span>{paginationData.total}</span>
          </label>
        </div>
        <div className="app-main__inner service-request-inner pb-4">
          <div className="row flex-wrap nurse-details-cntainer justify-content-between">
            <div className="col-12 col-md-7 col-xl-8  pe-xl-4">
              <Nurses />
            </div>
            <div className="col-12 col-md-5 col-xl-4 ps-xl-4">
              <SearchFilter
                onSkillsChanged={onSkillsChanged}
                onOptionChange={onFilterChange}
                clearFilter={setFilter}
                onChangeRange={onChangeRange}
                onAddressRange={onAddressRange}
                handleChangeProfessionList={handleChangeProfessionList}
                selectedProfession={filter.classId}
                handleChangeFromDate={handleChangeFromDate}
                handleChangeToDate={handleChangeToDate}
              />
            </div>
          </div>
          <Pagination
            currentPage={currentPage}
            totalRecords={paginationData?.total}
            limit={pageLimit}
            onPageChange={handlePageChange}
          ></Pagination>
        </div>
      </div>
    </>
  );
};

export default FindNursesList;
