import React, {useEffect, useRef, useState} from "react";
import ContentHeader from "../index";
import Table from "../../../../../sharedComponents/table/Table";
import classes from "../AdminTabs.module.css";
import {pageLimit} from "../../../../../appConstants";
import Pagination from "../../../../../sharedComponents/pagination";
import * as FileSaver from "file-saver";
import XLSX from "xlsx/dist/xlsx.full.min";
import PrimaryButton from "../../../../../sharedComponents/buttons/PrimaryButton";
import CustomDialog from "../../../../../sharedComponents/CustomDialog";
import {useDispatch, useSelector} from "react-redux";
import {HttpsAction} from "../../../../../commonApiFunction/httpsAction";
import {useParams} from "react-router";
import { debounce } from "lodash";

const AdminWallet = () => {

    const exportRef = useRef(null);
    const dispatch = useDispatch(null);
    const {tabId, agencyId} = useParams();

    const adminTransactionsList = useSelector(state => state.admin?.adminTransactions?.data);
    const allAdminTransactions = useSelector(state => state.admin?.allAdminTransactions?.data);

    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState('');
    const [date, setDate] = useState({fromDate: null, toDate: null});

    const transformedArray = (adminTransactionsList?.rows || [])?.map((item, index) => {
        return {
            caregiver: item?.nurseFullName,
            date: <div className={'d-flex flex-column gap-1'}>
                <div>
                    {item?.transactionDateFormatUs}
                </div>
                <div>
                    {/*12:00 - 01:00*/}
                </div>
            </div>,
            serviceRequest: item?.serviceRequestType,
            jobID: item?.jobId || 'N/A',
            amount: item?.amount,
        }
    });
    const exportTransformedArray = (allAdminTransactions || [])?.map((item, index) => {
        return {
            caregiver: item?.nurseFullName,
            date: <div className={'d-flex flex-column gap-1'}>
                <div>
                    {item?.transactionDateFormatUs}
                </div>
                <div>
                    {/*12:00 - 01:00*/}
                </div>
            </div>,
            serviceRequest: item?.serviceRequestType,
            jobID: item?.jobId || 'N/A',
            amount: item?.amount,
        }
    });

    const serviceHeaderData = [
        {
            title: "Nurse",
            dataIndex: "caregiver",
        },
        {
            title: "Date",
            dataIndex: "date",
        },
        {
            title: "Service Request",
            dataIndex: "serviceRequest",
        },
        {
            title: "Job ID",
            dataIndex: "jobID",
        },
        {
            title: "Amount",
            dataIndex: "amount",
        }
    ];

    const handlePageChange = (page) => {
        dispatch(HttpsAction({
            apiSection: 'admin',
            apiName: 'transactions',
            queryParameter: `/${agencyId}?page=${page}&searchText=${search}`,
            reduxKeyName: 'adminTransactions',
            reducerName: 'ADMIN',
            actionType: 'GET_ADMIN_TRANSACTIONS'
        }))
    };

    const handleModal = async () => {
        await dispatch(HttpsAction({
            apiSection: 'admin',
            apiName: 'transactions',
            queryParameter: `/${agencyId}?doPaging=false`,
            reduxKeyName: 'allAdminTransactions',
            reducerName: 'ADMIN',
            actionType: 'GET_ADMIN_TRANSACTIONS'
        }))
        setOpen(!open)
    };

    const handleDateFilter = ({from, to}) => {
        setDate({fromDate: from, toDate: to});
        dispatch(HttpsAction({
            apiSection: 'admin',
            apiName: 'transactions',
            queryParameter: `/${agencyId}?fromDate=${from}&toDate=${to}`,
            reduxKeyName: 'adminTransactions',
            reducerName: 'ADMIN',
            actionType: 'GET_ADMIN_TRANSACTIONS'
        }))
    };

    const handleExport = () => {
        const bookType = 'xlsx';
        const wb = XLSX.utils.table_to_book(exportRef.current, {sheet: "Sheet JS"});
        const wbout = XLSX.write(wb, {bookType, bookSST: true, type: 'array'});

        const blob = new Blob([wbout], {type: 'application/octet-stream'});
        const file = `wallet.xlsx`;

        FileSaver.saveAs(blob, file);
        setOpen(false);
    };

    const handleSearch = debounce((value) => {
        setSearch(value);
        let queryParameter =  `/${agencyId}?searchText=${value}`;
        if (date.fromDate != null) {
            queryParameter += `&fromDate=${date.fromDate}&toDate=${date.toDate}`
        }
        dispatch(HttpsAction({
            apiSection: 'admin',
            apiName: 'transactions',
            queryParameter,
            reduxKeyName: 'adminTransactions',
            reducerName: 'ADMIN',
            actionType: 'GET_ADMIN_TRANSACTIONS'
        }))
    }, 500);

    useEffect(() => {
        dispatch(HttpsAction({
            apiSection: 'admin',
            apiName: 'transactions',
            queryParameter: `/${agencyId}`,
            reduxKeyName: 'adminTransactions',
            reducerName: 'ADMIN',
            actionType: 'GET_ADMIN_TRANSACTIONS'
        }))
    }, []);

    return (
        <>
            <div className={'p-3'}>
                <ContentHeader
                    title={'Transactions'}
                    handleExport={handleModal}
                    handleDateFilter={handleDateFilter}
                    onSearch={({target}) => handleSearch(target.value)}
                />

                <div className={classes.contentBox}>
                    <Table headers={serviceHeaderData} rows={transformedArray}/>
                    <Pagination
                        currentPage={adminTransactionsList?.page}
                        totalRecords={adminTransactionsList?.total}
                        limit={pageLimit}
                        onPageChange={handlePageChange}
                    />
                </div>
            </div>

            <CustomDialog
                maxWidth={'lg'}
                onClose={() => handleModal('closeModal')}
                open={open}
                dialogContentRef={exportRef}
                actions={<PrimaryButton
                    type="button"
                    className="btn-view-repo gap-2 px-5 py-2"
                    text={"Export"}
                    onClick={handleExport}
                />}
                title={'Wallet  '}>
                <div className={'p-4'}>
                    <Table headers={serviceHeaderData} rows={exportTransformedArray}/>
                </div>
            </CustomDialog>
        </>
    )
};

export default AdminWallet;
