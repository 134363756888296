import React from 'react';

import CustomLabel from "../fields/CustomLabel";
import Autocomplete from "../googleAddressAuto";
import {fieldBottomMarginClass} from "../../appConstants";

const CustomAddressAutocomplete = ({label, readOnly, disabled, requiredMark, error, errorMessage = 'This field is required', selectedPlaceAddress, handlePlaceSelected, handleClear}) => {
  let containerClassName = `w-100 h-auto p-0 m-0 ${fieldBottomMarginClass}`;
  return (
    // <div className={containerClassName}>
    <>
      <div className="d-flex gap-3 position-relative">
        <CustomLabel requiredMark={requiredMark} readOnly={readOnly} labelText={label}/>
        {
          selectedPlaceAddress != null && selectedPlaceAddress.trim().length > 0 &&
          <label className="selectedLocation">&#x28;Selected Location &#x3d; {selectedPlaceAddress}&#x29; </label>
        }
        {handleClear && <span title="clear" className={`position-absolute top-26px end-2px z-1 btn btn-vs btn-close btn-outline-danger`} onClick={handleClear}>&nbsp;</span>}
      </div>
      <Autocomplete onPlaceSelected={handlePlaceSelected} initAddress={selectedPlaceAddress} disabled={disabled}/>
      {error && <span className="field-error">{errorMessage}</span>}
    </>
    // </div>
  );
};

export default CustomAddressAutocomplete;
