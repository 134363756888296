import React, {useEffect, useRef, useState} from "react";
import Table from "../../../../../../sharedComponents/table/Table";
import {Dialog, Popover, Stack} from "@mui/material";
import classes from '../CareNote.module.css'
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import ViewCare from "../ViewCare";
import {HttpsAction} from "../../../../../../commonApiFunction/httpsAction";
import {connect, useDispatch, useSelector} from "react-redux";
import Pagination from "../../../../../../sharedComponents/pagination";
import CustomInputField from "../../../../../../sharedComponents/fields/customInputField";
import {Field, reduxForm} from "redux-form";
import PrimaryButton from "../../../../../../sharedComponents/buttons/PrimaryButton";
import PrintCareNotes from "../PrintCareNotes";
import {useReactToPrint} from "react-to-print";
import * as FileSaver from "file-saver";
import {toastr} from "react-redux-toastr";
import XLSX from "xlsx/dist/xlsx.full.min";
import {pageLimit} from "../../../../../../appConstants";

const CareList = ({handleSubmit}) => {
    const dropdownRef = useRef();
    const printCareNotesRef = useRef(null);
    // const {clientId, tabId} = useParams();
    const { tabId } = useParams();
    const { clientId } = useOutletContext();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [isDropdown, setIsDropdown] = useState(false);
    const [printModal, setPrintModal] = useState(false);

    const careList = useSelector(state => state.client?.careList?.data || []);

    const transformedArray = (careList?.rows || [])?.map((item, index) => {
        return {
            date: (item?.taskDates || []).reduce((acc, elem) => {
                return acc === null ? [elem] : [...acc, <><span>,</span><br/></>, elem];
            }, null),
            nurse: `${item?.tasks?.[0]?.assignedNurseFullName || 'N/A'} `,
            id: item.id,
            key: index,
        }
    });

    const serviceHeaderData = [
        {
            title: "Date",
            dataIndex: "date",
            key: 0,
        },
        {
            title: "Nurse",
            dataIndex: "nurse",
            key: 1,
        },
    ];

    const actionButton = [
        {
            actionName: 'View',
            // buttonAction: (id, rowIndex) => navigate(`/agency/clients/${clientId}/careNotes/${rowIndex + 1}`),
            buttonAction: (id, rowIndex) => {
                navigate(`/agency/clients/${clientId}/careNotes/${id}`)
                dispatch({
                    type: 'ADD_CLIENT_SET_VIEW_CARE_LIST_INDEX',
                    payload: rowIndex,
                    name: 'viewCareListIndex',
                    meta: {type: 'SET_VIEW_CARE_LIST_INDEX'},
                });
            },
            actionClass: 'btn btn-primary border-btn-info px-4',
            iconClass: '',
        }
    ]

    const handleClickFilter = () => {
        setIsDropdown(!isDropdown)
    }
    const onSubmit = async ({from, to}) => {
        await dispatch(HttpsAction({
            apiSection: 'client',
            apiName: 'getCareList',
            queryParameter: `?patientId=${clientId}&page=${1}&pageSize=${pageLimit}&fromDate=${from}&toDate=${to}`,
            actionType: 'GET_CARE_LIST',
            reducerName: 'CLIENT',
            reduxKeyName: 'careList'
        }));
        handleClickFilter()
    };

    const handlePageChange = (page) => {
        getData(page)
    };

    const getData = (page = 1) => dispatch(HttpsAction({
        apiSection: 'client',
        apiName: 'getCareList',
        queryParameter: `?patientId=${clientId}&page=${page}&pageSize=${pageLimit}`,
        actionType: 'GET_CARE_LIST',
        reducerName: 'CLIENT',
        reduxKeyName: 'careList'
    }));

    const handlePrintModal = () => {
        setPrintModal(!printModal)
    };

    const handlePrint = useReactToPrint({
        content: () => printCareNotesRef.current,
        onAfterPrint: () => {
            // Close the modal after printing or cancelling the PDF
            handlePrintModal()
        }
    });

    const handleExport = () => {
        console.log('careList', careList)
        if (careList?.rows?.length > 0) {
            const bookType = 'xlsx';
            const wb = XLSX.utils.table_to_book(printCareNotesRef.current, {sheet: "Sheet JS"});
            const wbout = XLSX.write(wb, {bookType, bookSST: true, type: 'array'});

            const blob = new Blob([wbout], {type: 'application/octet-stream'});
            const file = 'care-notes.xlsx'

            FileSaver.saveAs(blob, file);
            setPrintModal(false)
        } else {
            toastr.error("Record can't be  export")
        }
    };

    useEffect(() => {
        getData()
    }, []);
    useEffect(() => {
        dispatch(HttpsAction({
            apiSection: 'client',
            apiName: 'getCareList',
            queryParameter: `?patientId=${clientId}&doPaging=false`,
            actionType: 'GET_CARE_LIST',
            reducerName: 'CLIENT',
            reduxKeyName: 'allCareList'
        }))
        dispatch({
            type: 'ADD_CLIENT_REMOVE_VIEW_CARE_LIST_INDEX',
            payload: undefined,
            name: 'viewCareListIndex',
            meta: {type: 'SET_VIEW_CARE_LIST_INDEX'},
        });
    }, []);

    return (
        <>
            {tabId === '0' && <Stack className={classes.contentMain}>
                <Stack className={classes.headerName}>
                    <Stack className={classes.headerTitle}>
                        Care List
                    </Stack>
                    <Stack ref={dropdownRef} className={classes.filterByButton} onClick={handleClickFilter}>
                        <svg width="29" height="27" viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.203125" y="0.734375" width="28.8" height="25.6" rx="8" fill="#C2CFE0"
                                  fillOpacity="0.19"/>
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M21.01 14.9462C22.775 14.9462 24.2109 16.2162 24.2109 17.7769C24.2109 19.3376 22.775 20.6075 21.01 20.6075C19.2439 20.6075 17.807 19.3376 17.807 17.7769C17.807 16.2162 19.2439 14.9462 21.01 14.9462ZM21.01 16.3177C20.0946 16.3177 19.3499 16.9723 19.3499 17.7769C19.3499 18.5824 20.0946 19.2361 21.01 19.2361C21.9244 19.2361 22.6681 18.5824 22.6681 17.7769C22.6681 16.9723 21.9244 16.3177 21.01 16.3177ZM13.4936 17.1266C13.9194 17.1266 14.265 17.4339 14.265 17.8124C14.265 18.1909 13.9194 18.4981 13.4936 18.4981H7.01251C6.58668 18.4981 6.24107 18.1909 6.24107 17.8124C6.24107 17.4339 6.58668 17.1266 7.01251 17.1266H13.4936ZM9.4129 6.07031C11.179 6.07031 12.6149 7.34118 12.6149 8.90187C12.6149 10.4626 11.179 11.7316 9.4129 11.7316C7.64786 11.7316 6.21094 10.4626 6.21094 8.90187C6.21094 7.34118 7.64786 6.07031 9.4129 6.07031ZM9.4129 7.44175C8.49849 7.44175 7.7538 8.09638 7.7538 8.90187C7.7538 9.70645 8.49849 10.3602 9.4129 10.3602C10.3283 10.3602 11.072 9.70645 11.072 8.90187C11.072 8.09638 10.3283 7.44175 9.4129 7.44175ZM22.8647 8.26498C23.2906 8.26498 23.6362 8.57218 23.6362 8.95069C23.6362 9.32921 23.2906 9.63641 22.8647 9.63641H16.3847C15.9589 9.63641 15.6133 9.32921 15.6133 8.95069C15.6133 8.57218 15.9589 8.26498 16.3847 8.26498H22.8647Z"
                                  fill="#434344"/>
                        </svg>

                        <Stack className={classes.filterText}>
                            Filter By
                        </Stack>
                    </Stack>
                </Stack>
                <Stack className={`${classes.printExportButton} mt-3`}>
                    <Stack className={classes.printButton} width={'56px'} onClick={handlePrintModal}>
                        <svg width="16" height="14" viewBox="0 0 16 14" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M13.25 4H2.75C1.505 4 0.5 5.005 0.5 6.25V10.75H3.5V13.75H12.5V10.75H15.5V6.25C15.5 5.005 14.495 4 13.25 4ZM11 12.25H5V8.5H11V12.25ZM13.25 7C12.8375 7 12.5 6.6625 12.5 6.25C12.5 5.8375 12.8375 5.5 13.25 5.5C13.6625 5.5 14 5.8375 14 6.25C14 6.6625 13.6625 7 13.25 7ZM12.5 0.25H3.5V3.25H12.5V0.25Z"
                                fill="#323232"/>
                        </svg>
                        <Stack className={classes.printButtonText}>
                            Print
                        </Stack>
                    </Stack>
                    <Stack className={classes.printButton} width={'76px'} onClick={() => setPrintModal('export')}>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_8324_100883)">
                                <path
                                    d="M12.75 2.25H3.75C2.9175 2.25 2.25 2.925 2.25 3.75V14.25C2.25 15.075 2.9175 15.75 3.75 15.75H14.25C15.075 15.75 15.75 15.075 15.75 14.25V5.25L12.75 2.25ZM9 14.25C7.755 14.25 6.75 13.245 6.75 12C6.75 10.755 7.755 9.75 9 9.75C10.245 9.75 11.25 10.755 11.25 12C11.25 13.245 10.245 14.25 9 14.25ZM11.25 6.75H3.75V3.75H11.25V6.75Z"
                                    fill="#323232"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_8324_100883">
                                    <rect width="18" height="18" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>

                        <Stack className={classes.printButtonText}>
                            Export
                        </Stack>
                    </Stack>
                </Stack>

                <Stack marginTop={'40px'}>
                    <Table headers={serviceHeaderData} rows={transformedArray} actions={actionButton}/>
                </Stack>
                <Pagination
                    currentPage={careList?.page}
                    totalRecords={careList?.total}
                    limit={10}
                    onPageChange={handlePageChange}
                />
            </Stack>}

            {/*{tabId !== "0" && <ViewCare serviceRequestId={tabId} patientName={`${item?.patientFirstName}, ${item?.patientLastName}`} />}*/}
            {tabId !== "0" && <ViewCare serviceRequestId={tabId} patientName=""/>}

            <Popover
                open={isDropdown}
                anchorEl={dropdownRef.current}
                onClose={() => setIsDropdown(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={{
                    style: {
                        boxShadow: 'unset',
                        width: '235px',
                        height: 'auto',
                        borderRadius: '5px',
                        marginTop: '5px',
                        border: '1px solid #D5D5D5',
                        padding: '20px'
                    },
                }}
            >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-12">
                            <div className="mb-4 input-field-main">
                                <Field
                                    className="form-control mk-wi"
                                    name="from"
                                    label="From"
                                    type="date"
                                    component={CustomInputField}
                                />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="mb-4 input-field-main">
                                <Field
                                    className="form-control mk-wi"
                                    name="to"
                                    label="To"
                                    type="date"
                                    component={CustomInputField}
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        className="d-flex justify-content-center"
                        style={{marginTop: "20px"}}
                    >
                        <PrimaryButton
                            type="submit"
                            className="btn-view-repo gap-2 px-5 py-2"
                            text={"Add Filter"}
                            onClick={() => undefined}
                        />
                    </div>
                </form>
            </Popover>

            <Dialog
                open={printModal}
                onClose={handlePrintModal}
                maxWidth={'md'}
            >
                <div className={'p-4'} ref={printCareNotesRef}>
                    <div>
                        <PrintCareNotes/>
                    </div>
                </div>
                <div
                    className="d-flex justify-content-center mb-4"
                    style={{marginTop: "10px"}}
                >
                    <PrimaryButton
                        type="button"
                        className="btn-view-repo gap-2 px-5 py-2"
                        text={printModal === 'export' ? "Export" : "Print"}
                        onClick={() => printModal === 'export' ? handleExport() : handlePrint()}
                    />
                </div>
            </Dialog>
        </>
    )
};
export default connect()(
    reduxForm({form: "careList", enableReinitialize: true})(CareList)
);
