import axios from "axios";
import getAuthHeaders from "./apiCall";
import {apiUrls} from "../helpers/apiURLs";
import {apiBaseUrl} from "../appConstants";
import {isLoggedIn, parseTokenAndGetData} from "../helpers/apiMethods";

// HttpsAction is a function that takes several parameters and returns a Redux action creator function
export const HttpsAction = async ({
                                      method = "GET",
                                      data = {},
                                      headers = {},
                                      positiveCallBack = (e) => e,
                                      negativeCallBack = (e) => e,
                                      finallyCallBack = (e) => e,
                                      actionType,
                                      apiSection,
                                      apiName,
                                      queryParameter,
                                      reduxKeyName,
                                      reducerName,
                                      // pathParameter,
                                      singleOperation = true,
                                      simpleDispatch = false,
                                      dataDispatch = false,
                                      noResponseDispatch = false,
                                      removeData = false,
                                      updateData = false,
                                      actionTypeIsDispatchType = false,
                                      newDispatchType = false,
                                  }) => {
    return async (dispatch) => {
        console.log('dispatch-start-actionType', actionType, {removeData}, {updateData}, {simpleDispatch}, {dataDispatch});
        if (removeData) {
            let dispatchType = `ADD_${reducerName}_${actionType}`;
            if (actionTypeIsDispatchType) dispatchType = actionType;
            console.log('dispatch-info (DELETE)', {dispatchType, reduxKeyName, actionType})
            dispatch({
                reducerName: actionTypeIsDispatchType ? reducerName : undefined,
                actionType: actionTypeIsDispatchType ? actionType : undefined,
                type: dispatchType,
                payload: undefined,
                name: reduxKeyName,
                meta: {type: actionType},
            });
            return await positiveCallBack(null);
        } else if (updateData) {
            let dispatchType = `ADD_${reducerName}_${actionType}`;
            if (actionTypeIsDispatchType) dispatchType = actionType;
            console.log('dispatch-info (UPDATE/REPLACE-DATA)', {dispatchType, reduxKeyName, actionType})
            dispatch({
                reducerName: actionTypeIsDispatchType ? reducerName : undefined,
                actionType: actionTypeIsDispatchType ? actionType : undefined,
                type: actionType,
                payload: data,
                name: reduxKeyName,
                meta: {type: actionType},
            });
            return await positiveCallBack(null);
        }
        else {
            try {
                // Dispatch an action to set loader state to true
                dispatch({type: `SET_LOADER`, loading: true});

                // Define a function to construct the API URL based on provided parameters
                const getUrl = async (key, name, queryParameter/*, pathParameter*/) => {

                    if (isLoggedIn()) {
                        const decodedToken = parseTokenAndGetData().data;
                        if (decodedToken?.isAdmin) {
                            if (apiUrls.admin[key] && apiUrls.admin[key][name]) {
                                return (queryParameter != null && queryParameter.length > 0)
                                  ? `${apiUrls.admin[key][name]["url"]}${queryParameter}`
                                  : apiUrls.admin[key][name]["url"];
                            }
                        }
                        if (decodedToken?.isAgency) {
                            if (apiUrls.agency[key] && apiUrls.agency[key][name]) {
                                return (queryParameter != null && queryParameter.length > 0)
                                  ? `${apiUrls.agency[key][name]["url"]}${queryParameter}`
                                  : apiUrls.agency[key][name]["url"];
                            }
                        }
                        if (decodedToken?.isNurse) {
                            if (apiUrls.nurse[key] && apiUrls.nurse[key][name]) {
                                return (queryParameter != null && queryParameter.length > 0)
                                  ? `${apiUrls.nurse[key][name]["url"]}${queryParameter}`
                                  : apiUrls.nurse[key][name]["url"];
                            }
                        }
                    } else {
                        console.log(key, name, "uuuuu");
                        if (apiUrls.live[key] && apiUrls.live[key][name]) {
                            return (queryParameter != null && queryParameter.length > 0)
                              ? `${apiUrls.live[key][name]["url"]}${queryParameter}`
                              : apiUrls.live[key][name]["url"];
                        }
                    }

                    // if (apiUrls.live[key] && apiUrls.live[key][name]) {
                    //     return (queryParameter != null && queryParameter.length > 0)
                    //         ? `${apiUrls.live[key][name]["url"]}${queryParameter}`
                    //         : apiUrls.live[key][name]["url"];
                    // let finalUrl = apiUrls.live[key][name]["url"];
                    // if (pathParameter != null) finalUrl += `/${pathParameter}`;
                    // if (queryParameter != null) finalUrl += queryParameter;
                    // return finalUrl;
                    // }
                };

                // Add authorization headers to the existing headers
                headers = {...headers, ...getAuthHeaders()};
                // console.log({getUrl:getUrl(
                //         apiSection,
                //         apiName,
                //         queryParameter,
                //         // pathParameter
                //     ).then(e => console.log({ e}))})
                // Construct the full API URL
                const apiUrl = `${apiBaseUrl}${await getUrl(
                  apiSection,
                  apiName,
                  queryParameter,
                  // pathParameter
                )}`;
                console.log('dispatch-info apiUrl', apiUrl)

                // Make an HTTP request using axios
                return await axios({
                    method,
                    url: apiUrl,
                    data,
                    headers,
                }).then(async (response) => {
                    // console.log('response', response)
                    if (noResponseDispatch) {
                        console.log('dispatch-info (no-response)');
                    } else if (actionType && reduxKeyName && reducerName) {
                        // Dispatch a Redux action if actionType and reduxKeyName are provided
                        if (newDispatchType) {
                            const prefix = method.toUpperCase();
                            const dispatchType = `${reducerName}_REDUCER_${method}_${actionType}`;
                            console.log('dispatch-info (new)', {type: dispatchType, reduxKeyName, actionType})
                            dispatch({
                                type: dispatchType,
                                payload: response.data.data,
                                name: reduxKeyName,
                                meta: {type: actionType, method},
                            });
                        } else if (simpleDispatch) {
                            // const dispatchType = actionType;
                            console.log('dispatch-info (simple)', {type: actionType/*, payload: response.data*/})
                            dispatch({
                                type: actionType,
                                payload: response.data.data,
                            });
                        } else if (dataDispatch) {
                            const dispatchType = `ADD_${reducerName}_${actionType}`;
                            console.log('dispatch-info (simple)', {type: dispatchType, reduxKeyName, actionType})
                            dispatch({
                                type: dispatchType,
                                payload: response.data.data,
                                name: reduxKeyName,
                                meta: {type: actionType},
                            });
                        } else {
                            const dispatchType = `ADD_${reducerName}_${actionType}`;
                            console.log('dispatch-info (other)', {dispatchType, reduxKeyName, actionType})
                            dispatch({
                                type: dispatchType,
                                payload: response.data,
                                name: reduxKeyName,
                                meta: {type: actionType},
                            });
                        }
                    }

                    if (singleOperation) {
                        // Dispatch an action to set loader state to false
                        dispatch({type: `SET_LOADER`, loading: false});
                    }

                    // Call the positiveCallBack function with the response
                    return await positiveCallBack(response);
                });
            } catch (e) {
                // Dispatch an action to set loader state to false
                dispatch({type: `SET_LOADER`, loading: false});

                // Call the negativeCallBack function with the error
                return await negativeCallBack(e);
            } finally {
                if (singleOperation) {
                    // Dispatch an action to set loader state to false
                    dispatch({type: `SET_LOADER`, loading: false});
                }

                // Call the finallyCallBack function
                await finallyCallBack();
            }
        }
    };
};

//       // Add authorization headers to the existing headers
//       headers = { ...headers, ...getAuthHeaders() };

//       // Construct the full API URL
//       const apiUrl = `${process.env.REACT_APP_API_BASE_URL}${await getUrl(
//         apiSection,
//         apiName,
//         queryParameter
//       )}`;

//       // Make an HTTP request using axios
//       return await axios({
//         method,
//         url: apiUrl,
//         data,
//         headers,
//       }).then(async (response) => {
//         // Dispatch a Redux action if actionType and reduxKeyName are provided
//         if (actionType && reduxKeyName) {
//           dispatch({
//             type: `ADD_${reducerName}_${actionType}`,
//             payload: response.data,
//             name: reduxKeyName,
//             meta: { type: actionType },
//           });
//         }

//         // Dispatch an action to set loader state to false
//         dispatch({ type: `SET_LOADER`, loading: false });

//         // Call the positiveCallBack function with the response
//         return await positiveCallBack(response);
//       });
//     } catch (e) {
//       // Dispatch an action to set loader state to false
//       dispatch({ type: `SET_LOADER`, loading: false });

//       // Call the negativeCallBack function with the error
//       return await negativeCallBack(e);
//     } finally {
//       // Dispatch an action to set loader state to false
//       dispatch({ type: `SET_LOADER`, loading: false });

//       // Call the finallyCallBack function
//       await finallyCallBack();
//     }
//   };
// };
