import React, {useState} from "react";

const CustomTable = props => {
  const { headers, rows, isSelectable = false, controlSelection, actions, actionColWidth, innerTableHeader = null, noDataFoundMessage = 'No data found!', innerRows} = props
  const [openServiceId,setOpenServiceId] = useState('')
  const rowRenderCount = actions?.length > 0 ? headers.length + 1 : headers.length;
  const widths = headers.map((item, index) => {
    if (item.hasOwnProperty("width") && item.width != null) {
      return item.width;
    }
    return '1fr';
  });
  if (actions?.length > 0) {
    if (actionColWidth != null) {
      widths.push(actionColWidth)
    } else
      widths.push('1fr')
  }
  return (
    <>
    {/*<div className="request-grid custom-table-head expandable-inner" style={{ borderBottom: "1px solid #f3f3f3", display: "grid", gridTemplateColumns: `repeat(${rowRenderCount}, 1fr)`}}>*/}
    <div className={`request-grid custom-table-head expandable-inner${innerTableHeader == null ? ' p-0' : ''}`} style={{ borderBottom: "1px solid #f3f3f3", display: "grid", gridTemplateColumns: widths.join(' ')}}>
        {isSelectable && (
            <div className="item fw-semibold border-bottom-0 form-check">
                <input
                className="form-check-input"
                onChange={(e) => controlSelection(e)}
                id="allTask"
                type="checkbox"
                />
            </div>
        )}
        {headers?.map((item, index) => {
            return (
            <div className="item fw-semibold border-bottom-0"
                // style={{ width: item.hasOwnProperty("width") && item.width, maxWidth: item.hasOwnProperty("maxWidth") && item.maxWidth }}
                // style={{maxWidth: index === 1 ? '55px' : 'auto'}}
                key={index}
            >
                {item.hasOwnProperty("titleRender")
                ? item.titleRender(item.title)
                : item.title}
            </div>
            );
        })}
        {actions?.length > 0 && (
            <div className="item fw-semibold border-bottom-0">Action</div>
        )}
    </div>
    <div className="row">
        <div className="container-fluid">
            <article className={`${openServiceId === 1 ? "is-open" : ""} beefup example-opensingle`}>
                <div className="beefup__head">
                    {rows?.map((item, index) => {
                        return (
                            <div key={index} className="custom-table-wrapper expandable-inner p-0 pt-2">
                                {/*<div className="request-grid custom-table-body" style={{display: "grid", gridTemplateColumns: `repeat(${rowRenderCount}, 1fr)`}}>*/}
                                <div className="request-grid custom-table-body" style={{display: "grid", gridTemplateColumns: innerTableHeader == null ? widths.join(' ') : `16px ${widths.join(' ')}`}}>
                                  {/*<i style={{maxWidth: '12px', left: openServiceId === item.id ? '-35px' : '-15px'}} className={`fa-solid fa-play expandable-inner position-relative ${openServiceId === item.id ? 'fa-rotate-90 top-0' : ''}`} onClick={()=>{setOpenServiceId(openServiceId === item.id ? null : item.id)}}></i>*/}
                                  {innerTableHeader != null && <div style={{maxWidth: '16px'}}><i
                                    className={`fa-solid fa-play top-50 start-4px 2xs${openServiceId === item.id ? ' fa-rotate-90' : ''}`} onClick={() => {
                                    setOpenServiceId(openServiceId === item.id ? null : item.id)
                                  }}></i></div>}
                                  {isSelectable && (
                                    <div className="item fw-normal form-check" key={index}>
                                    <input
                                            className="form-check-input"
                                            onChange={(e) => controlSelection(e)}
                                            id={item.id}
                                            checked={item.isChecked}
                                            type="checkbox"
                                        />
                                        </div>
                                    )}
                                    {headers?.map((headerItem, index) => {
                                    return (
                                        <div className="position-relative item fw-normal d-flex align-items-end"
                                             // style={{width: headerItem.hasOwnProperty("width") && headerItem.width,}}
                                             // style={{maxWidth: index === 1 ? '55px' : 'auto'}}
                                             key={index}
                                        >
                                        {headerItem.hasOwnProperty("render")
                                            ? headerItem.render(item[headerItem.dataIndex], index)
                                            : item[headerItem.dataIndex]}
                                        </div>
                                    );
                                    })}
                                    {
                                    actions?.length > 0 && (
                                        <div className="position-relative item fw-normal d-flex align-items-end">
                                        <div className="d-flex flex-row pb-0 gap-2">
                                            {
                                            actions?.map( (res, index) => {
                                                // return <button title={res.actionName} key={index} type="button" className={`btn ${res.actionClass}`} onClick={() => res.buttonAction(item.id)}><i className={`fa-solid ${res.iconClass}`}></i></button>
                                                return <button key={index} type="button" className={`btn btn-sm ${res.actionClass}`} onClick={() => res.buttonAction(item.id)}>{res.actionName}</button>
                                            })
                                            }
                                        </div>
                                        </div>
                                    )
                                    }
                                </div>
                                <div className={`${openServiceId === item.id ? "" : "d-none"} custom-inner-table-body pb-2`}>
                                    {
                                        item?.taskTable?.length > 0 ? <table className="table">
                                        <thead>
                                            <tr>
                                                <th colspan="1">&nbsp;</th>
                                                {
                                                    innerTableHeader?.map((res, index) => {
                                                        return <th key={index}>{res}</th>
                                                    })
                                                }
                                                <th colspan="1">&nbsp;</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                item?.taskTable?.map((rowData, rowIndex) => {
                                                    // Filter out keys that belong to arrays
                                                    const objectKeys = Object.keys(rowData).filter(key => !Array.isArray(rowData[key]));
                                                    let newRowIndex = rowIndex;
                                                    if (rowData['showDescriptionOnSeparateRow'] != null && rowData['showDescriptionOnSeparateRow'] === true) {
                                                      let foundIndex = objectKeys.findIndex(s => s === 'description');
                                                      objectKeys.splice(foundIndex, 1);
                                                      foundIndex = objectKeys.findIndex(s => s === 'showDescriptionOnSeparateRow');
                                                      objectKeys.splice(foundIndex, 1);
                                                      newRowIndex = rowIndex * 2;
                                                    }

                                                    return (
                                                      <>
                                                        <tr key={newRowIndex}>
                                                            <td colSpan={1}>&nbsp;</td>
                                                            {
                                                                objectKeys.map((key, itemIndex) => (
                                                                    <td key={itemIndex}>{rowData[key]}</td>
                                                                ))
                                                            }
                                                            {Array.isArray(rowData.Action) ? (
                                                                <td>
                                                                    <div className="d-flex flex-row pb-0 gap-2">
                                                                        {
                                                                            rowData?.Action?.map( (res, index) => {
                                                                                return <button key={index} type="button" className={`btn btn-sm ${res.actionClass}`} style={{minWidth: '60px'}} onClick={() => res.buttonAction(res.id)} disabled={item.nursesAssigned}>{res.actionName}</button>
                                                                            })
                                                                        }
                                                                    </div>
                                                                </td>
                                                            ) : (
                                                                ''
                                                            )}
                                                            <td colSpan={1}>&nbsp;</td>
                                                        </tr>
                                                        {rowData?.showDescriptionOnSeparateRow && <tr key={newRowIndex + 1}>
                                                          <td colSpan={objectKeys.length + 1}>
                                                            <div dangerouslySetInnerHTML={{__html: rowData.description}}/>
                                                          </td>
                                                        </tr>}
                                                      </>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table> : <div className="text-center pt-4 pb-2">{noDataFoundMessage}</div>
                                    }
                                </div>
                            </div>
                        );
                    })}
                </div>
            </article>
        </div>
        {rows?.length === 0 && (
            <div className="text-center pt-5 pb-5">{noDataFoundMessage}</div>
        )}
    </div>
    </>
  );
};

export default CustomTable;
