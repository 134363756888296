import React, {useEffect, useState} from 'react';
import {reloadProfileData} from "../services/AgencyProfile";
import {createDownloadUrl, isAdminUser, isAgencyUser} from "../helpers/apiMethods";
import classes from "../Agency/components/AgencyProfile/index.module.css";
import {Stack} from "@mui/material";
import {connect, useDispatch, useSelector} from "react-redux";
import {NavLink, Outlet, useLocation} from "react-router-dom";
import BackLink from "../sharedComponents/buttons/BackLink";

const agencyProfileTabsList = [
  {
    text: 'Agency Details',
    path: 'details',
  }, {
    text: 'Address',
    path: 'address',
  }, {
    text: 'Auth-Person',
    path: 'auth-person',
  }, {
    text: 'Logo',
    path: 'logo',
  }
];


const AgencyProfileLayout = ({/*isAgency, */agencyId: aid}) => {
  const isAdmin = isAdminUser();
  const isAgency = isAgencyUser();
  const pathPrefix = `${isAdmin ? "/admin/agency" : "/agency"}/profile`;

  const dispatch = useDispatch();
  const location = useLocation();

  const pathLastParam = location.pathname.split('/').pop();
  const agencyId = aid || location?.state?.agencyId;

  const agencyProfileData = useSelector(state => state.agencyProfile?.agencyProfile?.data);

  const [profileLogoLink, setProfileLogoLink] = useState('');
  const [tabs, setTabs] = useState(agencyProfileTabsList);
  const [showBackButton, setShowBackButton] = useState(false);
  const [backLink, setBackLink] = useState('');
  const [backButtonTextSuffix, setBackButtonTextSuffix] = useState('');

  useEffect(() => {
    if (location != null && location.state != null && location.state.showBackButton) {
      setShowBackButton(location.state.showBackButton);
      setBackLink(location.state.backLink);
      setBackButtonTextSuffix(location.state.backButtonTextSuffix);
    }
  }, [location]);

  useEffect(() => {
    if (agencyProfileData != null && agencyProfileData.logoLink != null && agencyProfileData.logoLink.length > 0) {
      setProfileLogoLink(agencyProfileData.logoLink);
    }
  }, [agencyProfileData]);

  useEffect(() => {
      if (isAdmin && tabs.length < 5) {
          setTabs(pre => ([...pre,
              {
                  text: 'Wallet',
                  path: 'wallet',
              }, {
                  text: 'Subscriptions',
                  path: 'subscriptions',
              }, {
                  text: 'Posted Jobs',
                  path: 'postedJobs',
              },
          ]));
      }
  }, [isAdmin]);

  useEffect(() => {
    dispatch(reloadProfileData(isAdmin ? agencyId : undefined));
  }, []);

  return (
    <div className="app-main__outer" style={isAdmin ? {paddingLeft: 0} : {}}>
      <div className="main-service-request-page">
        <div className="form p-2 p-sm-4">
          <div className="col-12 page-back-btn">
            <BackLink link={showBackButton && backLink ? backLink : '/admin/list/agencies'}
                      linkText={showBackButton && backButtonTextSuffix != null ? backButtonTextSuffix : 'Agencies Overview'}/>
          </div>
          <div className={classes.agencyTitle}>
            Agency Profile
          </div>
          <div>
            <div className="app-main__inner service-request supervisor-tab">
              <div className="row align-items-center">
                <div className="col">
                  <ul className="nav nav-tabs p-1" id="myTab" role="tablist">
                    {(tabs || []).map(({text, path}, index) => (
                      <li className="nav-item" role="presentation" key={index}>
                        <NavLink to={`${pathPrefix}/${path}`} className={'nav-link tab-nav-link-style'}>{text}</NavLink>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className={classes.underLine}/>
              <div className={'row'}>
                <div
                  className={["wallet", "subscriptions", "postedJobs"].includes(pathLastParam) ? 'col-12' : 'col-md-8 col-9'}
                  style={{flex: '1'}}>
                  <div className={classes.contentBox}>
                    <Outlet context={{agencyId}}/>
                  </div>
                </div>
                {["wallet", "subscriptions", "postedJobs"].includes(pathLastParam) ? <> </> :
                  <div className={'col-3'} style={{minWidth: '250px'}}>
                    <div className={classes.contentBox}>
                      <Stack direction={'row'} alignItems={'center'} marginBottom={'8px'}>
                        {/*<img src={profileImage} alt={''} style={{marginLeft: '-20px'}}/>*/}
                        {profileLogoLink.length > 0 &&
                          <img src={createDownloadUrl(profileLogoLink)} alt={''}
                               className="neo-img1"
                               style={{
                                 marginLeft: '-12px',
                                 marginRight: '10px',
                                 width: '120px',
                                 aspectRatio: '1',
                                 border: '1px solid grey',
                                 borderRadius: '99%'
                               }}
                          />}
                        <Stack gap={'5px'}>
                          <div className={classes.agencyName}>
                            {agencyProfileData?.name}
                          </div>
                          <Stack direction={'row'}>
                            <svg width="14" height="13" viewBox="0 0 14 13" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd"
                                    d="M7.48333 2.21366L8.4291 4.1031C8.52179 4.28859 8.70071 4.41721 8.90819 4.44697L11.0239 4.75151C11.5466 4.82698 11.7547 5.46051 11.3764 5.82352L9.84641 7.29361C9.69606 7.43817 9.62762 7.64599 9.66319 7.85008L10.0242 9.92553C10.1132 10.4389 9.56672 10.8307 9.09949 10.5878L7.20849 9.60717C7.02311 9.51097 6.80108 9.51097 6.61516 9.60717L4.72416 10.5878C4.25693 10.8307 3.71049 10.4389 3.79994 9.92553L4.16047 7.85008C4.19604 7.64599 4.1276 7.43817 3.97724 7.29361L2.4473 5.82352C2.06899 5.46051 2.27701 4.82698 2.79974 4.75151L4.91547 4.44697C5.12295 4.41721 5.3024 4.28859 5.39509 4.1031L6.34032 2.21366C6.57421 1.74649 7.24945 1.74649 7.48333 2.21366Z"
                                    fill="#FFC74A" stroke="#FFC74A"
                                    strokeWidth="1.16448"
                                    strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <svg width="14" height="13" viewBox="0 0 14 13" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd"
                                    d="M7.48333 2.21366L8.4291 4.1031C8.52179 4.28859 8.70071 4.41721 8.90819 4.44697L11.0239 4.75151C11.5466 4.82698 11.7547 5.46051 11.3764 5.82352L9.84641 7.29361C9.69606 7.43817 9.62762 7.64599 9.66319 7.85008L10.0242 9.92553C10.1132 10.4389 9.56672 10.8307 9.09949 10.5878L7.20849 9.60717C7.02311 9.51097 6.80108 9.51097 6.61516 9.60717L4.72416 10.5878C4.25693 10.8307 3.71049 10.4389 3.79994 9.92553L4.16047 7.85008C4.19604 7.64599 4.1276 7.43817 3.97724 7.29361L2.4473 5.82352C2.06899 5.46051 2.27701 4.82698 2.79974 4.75151L4.91547 4.44697C5.12295 4.41721 5.3024 4.28859 5.39509 4.1031L6.34032 2.21366C6.57421 1.74649 7.24945 1.74649 7.48333 2.21366Z"
                                    fill="#FFC74A" stroke="#FFC74A"
                                    strokeWidth="1.16448"
                                    strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <svg width="14" height="13" viewBox="0 0 14 13" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd"
                                    d="M7.48333 2.21366L8.4291 4.1031C8.52179 4.28859 8.70071 4.41721 8.90819 4.44697L11.0239 4.75151C11.5466 4.82698 11.7547 5.46051 11.3764 5.82352L9.84641 7.29361C9.69606 7.43817 9.62762 7.64599 9.66319 7.85008L10.0242 9.92553C10.1132 10.4389 9.56672 10.8307 9.09949 10.5878L7.20849 9.60717C7.02311 9.51097 6.80108 9.51097 6.61516 9.60717L4.72416 10.5878C4.25693 10.8307 3.71049 10.4389 3.79994 9.92553L4.16047 7.85008C4.19604 7.64599 4.1276 7.43817 3.97724 7.29361L2.4473 5.82352C2.06899 5.46051 2.27701 4.82698 2.79974 4.75151L4.91547 4.44697C5.12295 4.41721 5.3024 4.28859 5.39509 4.1031L6.34032 2.21366C6.57421 1.74649 7.24945 1.74649 7.48333 2.21366Z"
                                    fill="#FFC74A" stroke="#FFC74A"
                                    strokeWidth="1.16448"
                                    strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <svg width="14" height="13" viewBox="0 0 14 13" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd"
                                    d="M7.40423 2.21366L8.35 4.1031C8.44269 4.28859 8.62161 4.41721 8.82908 4.44697L10.9448 4.75151C11.4675 4.82698 11.6756 5.46051 11.2972 5.82352L9.76731 7.29361C9.61696 7.43817 9.54852 7.64599 9.58408 7.85008L9.94515 9.92553C10.0341 10.4389 9.48762 10.8307 9.02039 10.5878L7.12939 9.60717C6.94401 9.51097 6.72198 9.51097 6.53606 9.60717L4.64506 10.5878C4.17783 10.8307 3.63139 10.4389 3.72084 9.92553L4.08137 7.85008C4.11693 7.64599 4.04849 7.43817 3.89814 7.29361L2.3682 5.82352C1.98989 5.46051 2.19791 4.82698 2.72064 4.75151L4.83637 4.44697C5.04384 4.41721 5.2233 4.28859 5.31599 4.1031L6.26122 2.21366C6.4951 1.74649 7.17035 1.74649 7.40423 2.21366Z"
                                    stroke="#FFC74A" strokeWidth="1.16448"
                                    strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <svg width="14" height="13" viewBox="0 0 14 13" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd"
                                    d="M7.40423 2.21366L8.35 4.1031C8.44269 4.28859 8.62161 4.41721 8.82908 4.44697L10.9448 4.75151C11.4675 4.82698 11.6756 5.46051 11.2972 5.82352L9.76731 7.29361C9.61696 7.43817 9.54852 7.64599 9.58408 7.85008L9.94515 9.92553C10.0341 10.4389 9.48762 10.8307 9.02039 10.5878L7.12939 9.60717C6.94401 9.51097 6.72198 9.51097 6.53606 9.60717L4.64506 10.5878C4.17783 10.8307 3.63139 10.4389 3.72084 9.92553L4.08137 7.85008C4.11693 7.64599 4.04849 7.43817 3.89814 7.29361L2.3682 5.82352C1.98989 5.46051 2.19791 4.82698 2.72064 4.75151L4.83637 4.44697C5.04384 4.41721 5.2233 4.28859 5.31599 4.1031L6.26122 2.21366C6.4951 1.74649 7.17035 1.74649 7.40423 2.21366Z"
                                    stroke="#FFC74A" strokeWidth="1.16448"
                                    strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>

                          </Stack>
                          <Stack direction={'row'} gap={'10px'}>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <circle cx="10.2894" cy="10.0609" r="9.58823"
                                      fill="#E7E8EB"/>
                              <path
                                d="M12.6003 8.5332L10.7266 10.0568C10.3725 10.3377 9.87445 10.3377 9.52043 10.0568L7.63086 8.5332"
                                stroke="#3E4A62" strokeWidth="0.774911"
                                strokeLinecap="round" strokeLinejoin="round"/>
                              <path
                                d="M12.4474 13.2696H12.4464L8.13189 13.2696L8.13086 13.2696C7.03822 13.2725 6.28199 12.4232 6.28199 11.3117V8.41531C6.28199 7.30404 7.03824 6.45386 8.13189 6.45386H12.4464C13.54 6.45386 14.2963 7.30404 14.2963 8.41531V11.3117C14.2963 12.4232 13.5401 13.2725 12.4474 13.2696Z"
                                stroke="#3E4A62" strokeWidth="0.774911"
                                strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <circle cx="10.0541" cy="10.0609" r="9.58823"
                                      fill="#E7E8EB"/>
                              <path d="M6.45801 9.02441H13.6492" stroke="#3E4A62"
                                    strokeWidth="0.774911" strokeLinecap="round"
                                    strokeLinejoin="round"/>
                              <path d="M12.2281 10.5844H12.2318" stroke="#3E4A62"
                                    strokeWidth="0.774911" strokeLinecap="round"
                                    strokeLinejoin="round"/>
                              <path d="M7.87949 10.5844H7.88319" stroke="#3E4A62"
                                    strokeWidth="0.774911" strokeLinecap="round"
                                    strokeLinejoin="round"/>
                              <path d="M12.2281 12.1371H12.2318" stroke="#3E4A62"
                                    strokeWidth="0.774911" strokeLinecap="round"
                                    strokeLinejoin="round"/>
                              <path d="M10.0367 10.6498H10.0404" stroke="#3E4A62"
                                    strokeWidth="0.774911" strokeLinecap="round"
                                    strokeLinejoin="round"/>
                              <path d="M10.0367 12.1371H10.0404" stroke="#3E4A62"
                                    strokeWidth="0.774911" strokeLinecap="round"
                                    strokeLinejoin="round"/>
                              <path d="M7.87949 12.1371H7.88319" stroke="#3E4A62"
                                    strokeWidth="0.774911" strokeLinecap="round"
                                    strokeLinejoin="round"/>
                              <path d="M12.0688 6.06641V7.26494" stroke="#3E4A62"
                                    strokeWidth="0.774911" strokeLinecap="round"
                                    strokeLinejoin="round"/>
                              <path d="M8.05566 6.06641V7.26494" stroke="#3E4A62"
                                    strokeWidth="0.774911" strokeLinecap="round"
                                    strokeLinejoin="round"/>
                              <path
                                d="M13.6613 8.27702V8.27838V11.8407C13.6613 12.332 13.496 12.67 13.2311 12.8924C12.9565 13.1229 12.5266 13.2695 11.9309 13.2695H8.17642C7.58457 13.2695 7.15528 13.121 6.88 12.8864C6.61355 12.6593 6.44605 12.3132 6.44605 11.8112V8.27838C6.44605 7.78778 6.61193 7.45088 6.87717 7.22914C7.15235 6.99909 7.58261 6.85328 8.17642 6.85328H11.9351C12.5313 6.85328 12.9611 6.99925 13.2349 7.2288C13.4986 7.44989 13.663 7.78614 13.6613 8.27702Z"
                                stroke="#3E4A62" strokeWidth="0.774911"
                                strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <circle cx="9.8187" cy="10.0609" r="9.58823"
                                      fill="#E7E8EB"/>
                              <path
                                d="M10.5647 7.49987C10.5647 7.59786 10.5454 7.6949 10.508 7.78545C10.4705 7.876 10.4156 7.95827 10.3463 8.02759C10.277 8.0969 10.1948 8.15189 10.1043 8.18942C10.0137 8.22695 9.9167 8.24628 9.81871 8.24632C9.72072 8.24635 9.62368 8.22708 9.53313 8.18961C9.44259 8.15214 9.36031 8.0972 9.29099 8.02793C9.22168 7.95866 9.16669 7.87641 9.12916 7.78589C9.09163 7.69537 9.0723 7.59834 9.07227 7.50035C9.0722 7.30245 9.15076 7.11262 9.29065 6.97263C9.43055 6.83265 9.62033 6.75397 9.81823 6.75391C10.0161 6.75384 10.206 6.8324 10.3459 6.9723C10.4859 7.11219 10.5646 7.30197 10.5647 7.49987Z"
                                fill="#666262"/>
                              <path
                                d="M9.81823 10.8064C10.2302 10.8064 10.5642 10.4724 10.5642 10.0604C10.5642 9.64843 10.2302 9.31445 9.81823 9.31445C9.40625 9.31445 9.07227 9.64843 9.07227 10.0604C9.07227 10.4724 9.40625 10.8064 9.81823 10.8064Z"
                                fill="#666262"/>
                              <path
                                d="M9.81921 13.3689C10.2312 13.3689 10.5652 13.0349 10.5652 12.6229C10.5652 12.2109 10.2312 11.877 9.81921 11.877C9.40722 11.877 9.07324 12.2109 9.07324 12.6229C9.07324 13.0349 9.40722 13.3689 9.81921 13.3689Z"
                                fill="#666262"/>
                            </svg>

                          </Stack>

                        </Stack>
                      </Stack>
                      <Stack sx={{border: '1px solid #E7E8EBCC'}}/>
                      <div className={classes.contactDetails}>
                        Contact Details
                      </div>
                      <Stack gap={'15px'}>
                        <Stack direction={'row'} gap={'10px'} alignItems={'center'}>
                          <svg width="18" height="16" viewBox="0 0 18 16" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M12.857 5.4502L9.73027 7.9927C9.13952 8.46137 8.30835 8.46137 7.7176 7.9927L4.56445 5.4502"
                              stroke="#3E4A62" strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"/>
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M12.7106 14.6663C14.9635 14.6726 16.4818 12.8216 16.4818 10.5466V5.45895C16.4818 3.18399 14.9635 1.33301 12.7106 1.33301H5.43821C3.18535 1.33301 1.66699 3.18399 1.66699 5.45895V10.5466C1.66699 12.8216 3.18535 14.6726 5.43821 14.6663H12.7106Z"
                                  stroke="#3E4A62" strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"/>
                          </svg>
                          <div className={classes.contactEmail}>
                            {agencyProfileData?.authPersonDetails?.authUserEmail}
                          </div>
                        </Stack>
                        <Stack direction={'row'} gap={'10px'} alignItems={'center'}>
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M9.56836 1.66699C12.0357 1.94099 13.985 3.88766 14.2617 6.35499"
                              stroke="#3E4A62" strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"/>
                            <path
                              d="M9.56836 4.0293C10.749 4.25863 11.6717 5.18196 11.9017 6.36263"
                              stroke="#3E4A62" strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"/>
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M7.35402 8.31525C10.0133 10.9739 10.6166 7.89814 12.3098 9.5902C13.9422 11.2222 14.8811 11.5491 12.8122 13.6168C12.5532 13.825 10.9072 16.3298 5.12265 10.5468C-0.662596 4.76299 1.84072 3.11529 2.04897 2.85629C4.12218 0.782889 4.44422 1.72658 6.0766 3.35854C7.76909 5.05132 4.6947 5.6566 7.35402 8.31525Z"
                                  stroke="#3E4A62" strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"/>
                          </svg>

                          <div className={classes.contactPhone}>
                            {agencyProfileData?.authPersonDetails?.authUserPhoneNumber}
                          </div>
                        </Stack>
                        <Stack direction={'row'} gap={'10px'} alignItems={'center'}>
                          <div style={{width: '16px', height: '16px'}}>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd"
                                    d="M9.66634 7.00001C9.66634 6.07917 8.92018 5.33301 8.00001 5.33301C7.07917 5.33301 6.33301 6.07917 6.33301 7.00001C6.33301 7.92018 7.07917 8.66634 8.00001 8.66634C8.92018 8.66634 9.66634 7.92018 9.66634 7.00001Z"
                                    stroke="#3E4A62" strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"/>
                              <path fillRule="evenodd" clipRule="evenodd"
                                    d="M7.99967 14C7.20069 14 3 10.5989 3 7.04219C3 4.25776 5.23807 2 7.99967 2C10.7613 2 13 4.25776 13 7.04219C13 10.5989 8.79866 14 7.99967 14Z"
                                    stroke="#3E4A62" strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"/>
                            </svg>
                          </div>

                          <div className={classes.contactPhone}>
                            {agencyProfileData?.authPersonAddress?.authUserAddressLine1}
                          </div>
                        </Stack>
                        <Stack direction={'row'} gap={'10px'} alignItems={'center'}>
                          <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M12.6667 2C13.0203 2 13.3594 2.14048 13.6095 2.39052C13.8595 2.64057 14 2.97971 14 3.33333V12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V3.33333C2 2.97971 2.14048 2.64057 2.39052 2.39052C2.64057 2.14048 2.97971 2 3.33333 2H12.6667ZM12.3333 12.3333V8.8C12.3333 8.2236 12.1044 7.6708 11.6968 7.26322C11.2892 6.85564 10.7364 6.62667 10.16 6.62667C9.59333 6.62667 8.93333 6.97333 8.61333 7.49333V6.75333H6.75333V12.3333H8.61333V9.04667C8.61333 8.53333 9.02667 8.11333 9.54 8.11333C9.78754 8.11333 10.0249 8.21167 10.2 8.3867C10.375 8.56173 10.4733 8.79913 10.4733 9.04667V12.3333H12.3333ZM4.58667 5.70667C4.88371 5.70667 5.16859 5.58867 5.37863 5.37863C5.58867 5.16859 5.70667 4.88371 5.70667 4.58667C5.70667 3.96667 5.20667 3.46 4.58667 3.46C4.28786 3.46 4.00128 3.5787 3.78999 3.78999C3.5787 4.00128 3.46 4.28786 3.46 4.58667C3.46 5.20667 3.96667 5.70667 4.58667 5.70667ZM5.51333 12.3333V6.75333H3.66667V12.3333H5.51333Z"
                              fill="#3E4A62"/>
                          </svg>

                          <div className={classes.contactLinkedinUrl}>
                            {agencyProfileData?.webLink}
                          </div>
                        </Stack>
                      </Stack>
                    </div>
                  </div>}
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// export default AgencyProfileLayout;

const mapStateToProps = (state) => {
  const {auth} = state;

  return {
    isAdmin: auth?.authData?.isAdmin || false,
    agencyId: auth?.authData?.agencyId || auth.agencyId,
  };
};

export default connect(mapStateToProps)(AgencyProfileLayout);

