import * as commonServices from "./commonServices.js";
import getApiUrl from "../helpers/apiURLs.js";
import api from "../helpers/interceptor";
import { redirect } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import storageManager from "../helpers/storageManager";
import {parseTokenAndGetData, validateLoginArea} from "../helpers/apiMethods";
import {defaultErrorMessage} from "../appConstants";


export const addAuth = ({ dataToSend, userType/*, endPoint, message*/ }) => {
  const endPoint = 'login';
  const message = 'Login Successful';
  return async (dispatch) => {
    dispatch(commonServices.setLoader(true));
    const path = getApiUrl("auth", endPoint);
    console.log("api-path", path);

    return api
      .post(path, dataToSend)
      .then((response) => {
        if (response.success && response.data.token != null) {
          const {invalidLoginArea, decodedTokenData} = validateLoginArea(userType, response.data.token);
          if (invalidLoginArea) {
            storageManager.clear();
            throw new Error('INVALID_LOGIN_AREA');
          }

          storageManager.saveToken(response.data.token, response.data.refreshToken);
          const profileImgLink = decodedTokenData.profileImgLink;
          if (profileImgLink!= null) storageManager.saveProfileImageLink(profileImgLink);

          dispatch({
            type: `AUTH_SAVE`,
            payload: decodedTokenData,
            name: "authData",
            meta: { type: "SAVE" },
          });
          toastr.success(message, "");
        }
        dispatch(commonServices.setLoader(false));
        return {success: response.success, message: response.message};
      })
      .catch((err) => {
        var msg =
          err.message === "EMAIL_ALREADY_EXISTS"
            ? "Email already exists"
            : defaultErrorMessage;
        if (err.message === 'INVALID_LOGIN_AREA') {
          msg = 'You are not authorized to login here.';
        }
        toastr.error(msg, "");
        console.log('login ERROR', err);
        dispatch(commonServices.setLoader(false));
      });
  };
};

const doLogout = dispatch => {
  storageManager.clear();

  dispatch({
    type: `AUTH_SAVE`,
    payload: undefined,
    name: "authData",
    meta: {type: "SAVE"},
  });
};

export const logout = () => {
  const endPoint = 'logout';
  const message = 'Logout Successful';
  return async (dispatch) => {
    dispatch(commonServices.setLoader(true));
    const path = getApiUrl("auth", endPoint);
    console.log("api-path", path, endPoint);

    if (path == null) { // not logged in
      doLogout(dispatch);
      return {success: true};
    }

    return api
      .get(path)
      .then((response) => {
        let decodedTokenData = null;
        if (response.success) {
          doLogout(dispatch);
        }
        dispatch(commonServices.setLoader(false));
        toastr.success(message, "");
        return {success: response.success};
      })
      .catch((err) => {
        console.log('logout ERROR', err);
        toastr.error(defaultErrorMessage, "");
        dispatch(commonServices.setLoader(false));
      });
  };
};

export const signup = ({ dataToSend, userType }) => {
  const message = 'Login Successful';
  return async (dispatch) => {
    dispatch(commonServices.setLoader(true));

    let endPoint = '';
    if (userType === 'agency') {
      endPoint = 'agencySignup';
    }
    if (userType === 'nurse') {
      endPoint = 'nursesSignup';
    }

    if (!endPoint) {
      throw new Error('Invalid signup endpoint');
    }

    const path = getApiUrl("auth", endPoint);
    console.log("api-path", path, dataToSend);

    return api
      .post(path, dataToSend)
      .then((response) => {
        dispatch(commonServices.setLoader(false));
        toastr.success(message, "");
        return {success: response.success};
      })
      .catch((err) => {
        var msg =
          err.message === "EMAIL_ALREADY_EXISTS"
            ? "Email already exists"
            : defaultErrorMessage;
        toastr.error(msg, "");
        console.log('login ERROR', err);
        dispatch(commonServices.setLoader(false));
      });
  };
};

export const generateVerificationEmail = ({ email }) => {
  const message = 'Email verified successfully';
  return async (dispatch) => {
    dispatch(commonServices.setLoader(true));
    const path = getApiUrl("auth", 'generateVerifyEmailPasscode');
    console.log("api-path", path, email);

    return api
      .post(path, {email})
      .then((response) => {
        dispatch(commonServices.setLoader(false));
        // toastr.success(message, "");
        return {success: response.success};
      })
      .catch((err) => {
        var msg =
          err.message === "EMAIL_ALREADY_EXISTS"
            ? "Email already exists"
            : defaultErrorMessage;
        toastr.error(msg, "");
        console.log('login ERROR', err);
        dispatch(commonServices.setLoader(false));
      });
  };
};

export const verifyUserEmail = ({ token }) => {
  const message = 'Email verified successfully';
  return async (dispatch) => {
    dispatch(commonServices.setLoader(true));
    const path = getApiUrl("auth", 'verifyEmail');
    console.log("api-path", path, token);

    return api
      .post(path, {token})
      .then((response) => {
        dispatch(commonServices.setLoader(false));
        // toastr.success(message, "");
        return {success: response.success};
      })
      .catch((err) => {
        var msg =
          err.message === "EMAIL_ALREADY_EXISTS"
            ? "Email already exists"
            : defaultErrorMessage;
        toastr.error(msg, "");
        console.log('login ERROR', err);
        dispatch(commonServices.setLoader(false));
      });
  };
};
