import React, {useEffect, useRef, useState} from 'react';
import downArrow from "../../../assets/image/down-arrow.png";
import DatePicker from "react-datepicker";
import Table from "../../../../sharedComponents/table/Table";
import Pagination from "../../../../sharedComponents/pagination";
import {connect, useDispatch} from "react-redux";
import XLSX from "xlsx/dist/xlsx.full.min";
import * as FileSaver from "file-saver";
import {debounce} from "lodash";
import moment from "moment";
import {getSubscriptionHistoryAction} from "../../../../services/transaction";
import {pageLimit} from "../../../../appConstants";
import {useOutletContext} from "react-router-dom";
import CommonPaginationNew from "../../../../sharedComponents/pagination/CommonPaginationNew";

const SubscriptionHistoryTable = ({ subscriptionHistoryInstance, setPaginationTab }) => {
  const dispatch = useDispatch();

  const serviceHeaderData = [
    {
      title: "Type",
      dataIndex: "subscriptionTypeName",
    },
    {
      title: "Date",
      dataIndex: "subscriptionDate",
    },
    {
      title: "Total",
      dataIndex: "subscriptionPlanAmount",
    },
  ];

  const transformedArray = subscriptionHistoryInstance?.rows?.map((item) => {
    const { subscriptionTypeName } = item;
    // const subscriptionDate = moment(item?.subscriptionDate).format("MM/DD/YY HH:mm");
    // const subscriptionPlanAmount = `$${item?.subscriptionPlanAmount}`;
    const subscriptionPlanAmount = `$${item?.planAmount}`;
    return {
      subscriptionTypeName,
      subscriptionDate: item?.subscriptionDateFormatUsWithTime,
      subscriptionPlanAmount,
    };
  });

  const tableRef = useRef();

  const [searchTerm, setSearchTerm] = useState("");
  const [fileName, setFileName] = useState("payout_history_sheet");
  const [fileFormat, setFileFormat] = useState("xlsx");
  const [currentPage, setCurrentPage] = useState(1);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');

  const handleExport = () => {
    const bookType = fileFormat;
    const wb = XLSX.utils.table_to_book(tableRef.current, { sheet: "Sheet JS" });
    const wbout = XLSX.write(wb, { bookType, bookSST: true, type: 'array' });

    const blob = new Blob([wbout], { type: 'application/octet-stream' });
    const file = fileName.length ? `${fileName}.${fileFormat}` : `excel-sheet.${fileFormat}`;

    FileSaver.saveAs(blob, file);
  };

  const onSearch = debounce((e) => {
    setSearchTerm(e.target.value);
  }, 500);

  const handleChange = (range) => {
    if (range) {
      const [fVal, tVal] = range;
      const formattedFromDate = moment(fVal).format("YYYY-MM-DD");
      const formattedToDate = moment(tVal).format("YYYY-MM-DD");

      setFromDate(formattedFromDate);
      setToDate(formattedToDate);
    } else {
      // The range was set to null (probably the 'clear' select)
      setFromDate('');
      setToDate('');
    }
  };

  // const handlePageChange = (page) => {
  //   setCurrentPage(page);
  // };

  useEffect(() => {
    if (subscriptionHistoryInstance != null && subscriptionHistoryInstance?.rows?.length != null) {
      if (setPaginationTab != null) setPaginationTab(subscriptionHistoryInstance);
    }
  }, [subscriptionHistoryInstance]);

  useEffect(() => {
    // console.log("fromDate", fromDate);
    // console.log("toDate", toDate);
    // console.log("searchTerm", searchTerm);
    // console.log("currentPage", currentPage);
    if (searchTerm.length > 0 || fromDate.length > 0 || toDate.length > 0 || currentPage > 0) {
      // let validFromDate = fromDate;
      // let validToDate = toDate;
      //
      // // Convert fromDate and toDate to the expected format
      // if (typeof fromDate === 'object' && fromDate !== null) {
      //   validFromDate = moment(fromDate).format('YYYY-MM-DD');
      // }
      //
      // if (typeof toDate === 'object' && toDate !== null) {
      //   validToDate = moment(toDate).format('YYYY-MM-DD');
      // }
      //
      // // Fall back to null if date is invalid or not provided
      // if (!moment(validFromDate, 'YYYY-MM-DD', true).isValid()) {
      //   validFromDate = null;
      // }
      //
      // if (!moment(validToDate, 'YYYY-MM-DD', true).isValid()) {
      //   validToDate = null;
      // }
      //
      // if (searchTerm || (validFromDate !== null && validToDate !== null) || currentPage) {
      //
      //   dispatch(getSubscriptionHistoryAction("subScriptionHistory", {fromDate, toDate, searchTerm, currentPage}));
      // }

      console.log('loading subscription history');
      dispatch(getSubscriptionHistoryAction("subScriptionHistory", {fromDate, toDate, searchTerm, currentPage}));
    }
  }, [searchTerm, fromDate, toDate, currentPage]);

  return (
    <>
      <div className="bg-white h-table-main position-relative" ref={tableRef}>
        <div className="d-flex flex-wrap gap-3 gap-xl-0 justify-content-between px-4 pt-5 pb-4">
          <div className="tabel-title">
            <h3 className="mb-0">Subscription History</h3>
          </div>
          <div className="flex-grow-1 d-flex flex-wrap flex-lg-nowrap gap-2 gap-xl-3 align-items-center justify-content-between justify-content-xl-end">
            <div className="col-12 col-md-3 col-xl-3 px-xl-0 position-absolute top-4px end-0">
              <button
                className="export-btn ms-auto me-4"
                onClick={() => handleExport(transformedArray, "subscription_history_sheet.xlsx")}
              >
                <img src={downArrow}/>
                Export
              </button>
            </div>
            <div className="col-12 col-md-4 col-xl-4 d-flex align-items-center gap-1 px-xl-0 mt-2">
              <DatePicker
                selected={fromDate ? Date.parse(fromDate) : null}
                onChange={handleChange}
                startDate={fromDate ? Date.parse(fromDate) : null}
                endDate={toDate ? Date.parse(toDate) : null}
                selectsRange
                placeHolder={"Select Date"}
                isClearable
                className="w-100 history-date"
              />
            </div>
            <div className="col-12 col-md-4 col-xl-5 pl-3 pl-xl-0 mt-2">
              <div className="input-group input-search-djkds history-search">
                <span className="input-group-append d-flex">
                  <button className="btn ms-n5" type="button">
                    <i className="fa fa-search"></i>
                  </button>
                </span>
                <input
                  className="form-control hhgrsjd bg-transparent border-0 border"
                  type="search"
                  placeholder="Search..."
                  onChange={onSearch}
                />
              </div>
            </div>
          </div>
        </div>
        <Table headers={serviceHeaderData} rows={transformedArray}/>
      </div>
      {/*<Pagination
        currentPage={subscriptionHistoryInstance?.page}
        totalRecords={subscriptionHistoryInstance?.total}
        limit={subscriptionHistoryInstance?.pageSize}
        onPageChange={handlePageChange}
      ></Pagination>*/}
      <CommonPaginationNew data={subscriptionHistoryInstance} onPageChange={(page) => setCurrentPage(page)} />
    </>
  );
};

const mapStateToProps = (state) => {
  const { transaction } = state;
  return {
    subscriptionHistoryInstance: transaction?.subscriptionHistoryList || {},
  };
};

export default connect(mapStateToProps)(SubscriptionHistoryTable);
