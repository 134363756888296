import getApiUrl from "../helpers/apiURLs.js";
import api from "../helpers/interceptor";
import * as clientActions from "../Agency/components/Client/clientActionType";
import { toastr } from "react-redux-toastr";
import { setLoader } from "./commonServices.js";
import {defaultErrorMessage, pageLimit} from "../appConstants";
import {GET_HEALTHCARE_EXTRA_INFO} from "../Agency/components/Client/clientActionType";

export const postClientProfileCareInfo = (payload, id) => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = `${getApiUrl("client", "clientProfileCareInfo")}/${id}`;
    return api
      .put(url, payload)
      .then((response) => {
        dispatch(setLoader(false));
        return response;
      })
      .catch((err) => {
        dispatch(setLoader(false));
      });
  };
};

export const postClientProfileResidentialInfoAddress = (payload, id) => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = `${getApiUrl("client", "postResidentialInfoAddress")}/${id}`;
    return api
      .put(url, payload)
      .then((response) => {
        dispatch(setLoader(false));
        return response;
      })
      .catch((err) => {
        dispatch(setLoader(false));
      });
  };
};

export const postClientProfileBillingInfoAddress = (payload, id) => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = `${getApiUrl("client", "billingInfoAddress")}/${id}`;
    return api
      .put(url, payload)
      .then((response) => {
        dispatch(setLoader(false));
        return response;
      })
      .catch((err) => {
        dispatch(setLoader(false));
      });
  };
};

export const postClientProfileContactInfoAddress = (payload) => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = getApiUrl("client", "contactInfoAddress");
    return api
      .post(url, payload)
      .then((response) => {
        dispatch(setLoader(false));
        return response;
      })
      .catch((err) => {
        dispatch(setLoader(false));
      });
  };
};

export const postClientProfileCharacteristics = (payload, clientId) => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = getApiUrl("client", "characteristics") + `/${clientId}`;
    return api
      .put(url, payload)
      .then((response) => {
        dispatch(setLoader(false));
        return response;
      })
      .catch((err) => {
        dispatch(setLoader(false));
      });
  };
};

export const postClientProfilePetInfo = (payload, id) => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = `${getApiUrl("client", "petInfo")}/${id}`;
    return api
      .put(url, payload)
      .then((response) => {
        dispatch(setLoader(false));
        return response;
      })
      .catch((err) => {
        dispatch(setLoader(false));
      });
  };
};

export const getContactInfoAddress = (id) => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = `${getApiUrl("client", "contactInfoAddress")}/${id}`;
    return api
      .get(url)
      .then((response) => {
        dispatch({
          type: clientActions.GET_CONTACT_INFO_ADDRESS,
          payload: response.data,
        });
        dispatch(setLoader(false));
        return response;
      })
      .catch((err) => {
        dispatch(setLoader(false));
      });
  };
};

export const getPetInfo = (id) => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = `${getApiUrl("client", "petInfo")}/${id}`;
    return api
      .get(url)
      .then((response) => {
        dispatch({ type: clientActions.GET_PET_INFO, payload: response.data });
        dispatch(setLoader(false));
        return response;
      })
      .catch((err) => {
        dispatch(setLoader(false));
      });
  };
};

export const getResidentialInfoAddress = (id) => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = `${getApiUrl("client", "postResidentialInfoAddress")}/${id}`;
    return api
      .get(url)
      .then((response) => {
        dispatch({
          type: clientActions.GET_RESIDENTIAL_INFO_ADDRESSES,
          payload: response.data,
        });
        dispatch(setLoader(false));
        return response;
      })
      .catch((err) => {
        dispatch(setLoader(false));
      });
  };
};

export const getBillingInfoAddress = (id) => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = `${getApiUrl("client", "billingInfoAddress")}/${id}`;
    return api
      .get(url)
      .then((response) => {
        dispatch({
          type: clientActions.GET_BILLING_INFO_ADDRESSES,
          payload: response.data,
        });
        dispatch(setLoader(false));
        return response;
      })
      .catch((err) => {
        dispatch(setLoader(false));
      });
  };
};

export const postCreateClient = (payload, message) => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = getApiUrl("client", "createClient");
    return api
      .post(url, payload)
      .then((response) => {
        dispatch(setLoader(false));
        toastr.success(message);
        return response;
      })
      .catch((err) => {
        dispatch(setLoader(false));
        if (err.status === 400) {
          toastr.error(err.message || "something went wrong");
        } else {
          toastr.error("something went wrong");
        }
        return err;
      });
  };
};

export const putPersonalInformation = (payload, id) => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = `${getApiUrl("client", "getClientById")}/${id}`;
    return api
      .put(url, payload)
      .then((response) => {
        dispatch(setLoader(false));
        return response;
      })
      .catch((err) => {
        dispatch(setLoader(false));
      });
  };
};

export const getAllClients = (pageNumber, searchText) => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    let url = `${getApiUrl(
      "client",
      "getClientList"
    )}?page=${pageNumber}&pageSize=${pageLimit}`;
    if (searchText != null && searchText.length > 0) {
      url += `&searchText=${searchText}`;
    }
    return api
      .get(url)
      .then((response) => {
        dispatch({
          type: clientActions.GET_ALL_CLIENTS,
          payload: response.data,
        });
        dispatch(setLoader(false));
        return response;
      })
      .catch((err) => {
        dispatch(setLoader(false));
      });
  };
};

export const getClientById = (id) => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = `${getApiUrl("client", "getClientById")}/${id}`;
    return api
      .get(url)
      .then((response) => {
        dispatch({
          type: clientActions.GET_CLIENT_BY_ID,
          payload: response.data,
        });
        dispatch(setLoader(false));
        return response;
      })
      .catch((err) => {
        dispatch(setLoader(false));
      });
  };
};

export const getCareInfoById = (id, callback) => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = `${getApiUrl("client", "clientProfileCareInfo")}/${id}`;
    return api
      .get(url)
      .then((response) => {
        dispatch({
          type: clientActions.GET_CARE_INFO_BY_ID,
          payload: response.data,
        });
        dispatch(setLoader(false));
        callback();
        return response;
      })
      .catch((err) => {
        dispatch(setLoader(false));
      });
  };
};

export const getClientProfilePersonalInfoPersonalTitleAll = () => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = `${getApiUrl(
      "client",
      "clientProfilePersonalInfoPersonalTitleAll"
    )}`;
    return api
      .get(url)
      .then((response) => {
        dispatch({
          type: clientActions.GET_PERSONAL_INFO_ALL_TITLE,
          payload: response.data,
        });
        dispatch(setLoader(false));
        return response;
      })
      .catch((err) => {
        dispatch(setLoader(false));
      });
  };
};

export const getClientProfilePreferredCaregiver = () => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = `${getApiUrl("client", "preferredCaregiver")}`;
    return api
      .get(url)
      .then((response) => {
        dispatch({
          type: clientActions.GET_PREFERRED_CAREGIVER,
          payload: response.data,
        });
        dispatch(setLoader(false));
        return response;
      })
      .catch((err) => {
        dispatch(setLoader(false));
      });
  };
};

export const getCityList = (id) => {
  // let provinceOrStateId = 0;
  // if (typeof id === 'string') provinceOrStateId = +id;
  // if (provinceOrStateId === 0) return Promise.resolve([]);
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = `${getApiUrl("commonAPI", "city")}/${id}`;
    try {
      const response = await api.get(url);
      dispatch({ type: clientActions.GET_CITY_LIST, payload: response.data });
      dispatch(setLoader(false));
      return response;
    } catch (err) {
      dispatch(setLoader(false));
      toastr.error(defaultErrorMessage, "");
    }
  };
};

export const postCreateSpouse = (payload, id, message) => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = `${getApiUrl("client", "createSpouse")}/${id}`;
    return api
      .post(url, payload)
      .then((response) => {
        dispatch(setLoader(false));
        toastr.success(message);
        return response;
      })
      .catch((err) => {
        dispatch(setLoader(false));
        if (err.status === 400) {
          toastr.error(err.message || "something went wrong");
        } else {
          toastr.error("something went wrong");
        }
        return err;
      });
  };
};

export const getClientProfilePetInfo = (id) => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = `${getApiUrl("client", "petInfo")}/${id}`;
    return api
      .get(url)
      .then((response) => {
        dispatch({ type: clientActions.GET_PET_INFO, payload: response.data });
        dispatch(setLoader(false));
        // return response;
      })
      .catch((err) => {
        dispatch(setLoader(false));
      });
  };
};

export const getClientResponsiblePartyContacts = (id) => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = `${getApiUrl("client", "respPartyContact")}/${id}`;
    return api
      .get(url)
      .then((response) => {
        let responseData = [];
        if (response.success) {
          responseData = response.data;
        }
// console.log('test- responseData', responseData)
        dispatch({type: clientActions.GET_RESP_PARTY_CONTACTS, payload: responseData.map((x) => ({
            recordType: x.recordType || 'existing',
            id: x.id,
            firstName: x.firstName,
            lastName: x.lastName,
            genderId: x.genderId,
            relationshipId: x.relationshipId,
            email: x.email,
            phoneTypeId: x.phoneTypeId,
            phoneNumber: x.phoneNumber,
            addressLine1: x.addressLine1,
            cityId: x.cityId,
            provinceOrStateId: x.provinceOrStateId,
            postalOrZipCode: x.postalOrZipCode,
            hipaaDisclosureAuthorization: x.hipaaDisclosureAuthorization,
            medicalDecisions: x.medicalDecisions,
          }))});
        dispatch(setLoader(false));
        // return response;
      })
      .catch((err) => {
        dispatch(setLoader(false));
      });
  };
};

export const getClientProfileHealthcareExtraInfo = (id) => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = `${getApiUrl("client", "healthcareExtraInformation")}/${id}`;
    return api
      .get(url)
      .then((response) => {
        let receivedData = response.data;
        receivedData = {medicationInformation: receivedData?.healthcareMedicationDetails || '', medicalNotes: receivedData?.healthcareMedicalNotes || '', diagnosisList: (receivedData?.healthcareDiagnosisList || []).map(p => ({...p, recordType: p.recordType || 'existing'}))};
        dispatch({ type: clientActions.GET_HEALTHCARE_EXTRA_INFO, payload: receivedData });
        dispatch(setLoader(false));
        // return response;
      })
      .catch((err) => {
        dispatch(setLoader(false));
      });
  };
};

export const postCreatePhysician = (payload, message) => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = getApiUrl("client", "createPhysician");
    return api
      .post(url, payload)
      .then((response) => {
        dispatch(setLoader(false));
        toastr.success(message);
        return response;
      })
      .catch((err) => {
        dispatch(setLoader(false));
        if (err.status === 400) {
          toastr.error(err.message || "something went wrong");
        } else {
          toastr.error("something went wrong");
        }
        return err;
      });
  };
};

export const postCreateAgency = (payload, message) => {
  return async (dispatch) => {
    dispatch(setLoader(true));
    const url = getApiUrl("client", "createAgency");
    return api
      .post(url, payload)
      .then((response) => {
        dispatch(setLoader(false));
        toastr.success(message);
        return response;
      })
      .catch((err) => {
        dispatch(setLoader(false));
        if (err.status === 400) {
          toastr.error(err.message || "something went wrong");
        } else {
          toastr.error("something went wrong");
        }
        return err;
      });
  };
};
