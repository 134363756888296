import React from 'react';
import {Stack} from "@mui/material";
import {NavLink, Outlet, useNavigate, useOutletContext, useParams} from "react-router-dom";
// import PersonalInformation from "../Agency/components/Client/ClientProfile/Profile/PersonalInformation";
// import CareInformation from "../Agency/components/Client/ClientProfile/Profile/CareInformation";
// import ResidentialAddress from "../Agency/components/Client/ClientProfile/Profile/ResidentialAddress";
// import BillingAddress from "../Agency/components/Client/ClientProfile/Profile/BillingAddress";
// import ContactInformation from "../Agency/components/Client/ClientProfile/Profile/ContactInformation";
// import Characteristics from "../Agency/components/Client/ClientProfile/Profile/Characteristics";
// import PetInformation from "../Agency/components/Client/ClientProfile/Profile/PetInformation";

const clientProfileTabsList = [
  {
    path: 'personal-info',
    text: 'Personal Information',
  }, {
    text: 'Care Information',
    path: 'care-information',
  }, {
    text: 'Residential Address',
    path: 'residential-address',
  }, {
    text: 'Billing Address',
    path: 'billing-address',
  }, {
    text: 'Contact Information',
    path: 'contact-information',
  },
  {
    text: 'Characteristics',
    path: 'characteristics',
  }, {
    text: 'Pet Information',
    path: 'pet-info',
  },
];

const ClientPersonalInfoLayout = () => {
  const pathPrefix = '/agency/clients/edit/profile';

  // const navigate = useNavigate();
  // const { id: nurseId } = useParams();

  return (
    <div
      className="tab-pane fade show active"
      id="barden-tab-pane"
      role="tabpanel"
      aria-labelledby="barden-tab"
      tabIndex="0"
    >
      <div className="card-white px-4" style={{paddingTop: '24px'}}>
        <div className="d-flex gap-2">
          {clientProfileTabsList?.map(({path, text}, index) => (
            <React.Fragment key={index}>
              {/*<NavLink key={index} to={`${pathPrefix}/${path}/${nurseId}`} className="btn btn-sm px-1 patient-profile-personal-info-tab-link neo1">{text}</NavLink>*/}
              <NavLink key={index} to={`${pathPrefix}/${path}`} className="btn btn-sm px-1 patient-profile-personal-info-tab-link neo1">{text}</NavLink>
            </React.Fragment>
          ))}
        </div>
        <Stack sx={{marginTop: '50px'}}>
          <Outlet context={useOutletContext()} />
        </Stack>
      </div>
    </div>
  );
};

export default ClientPersonalInfoLayout;
