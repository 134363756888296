import * as commonServices from "./commonServices.js";
import * as messageAction from '../Nurses/components/Messages/messageActionTypes.js'
import getApiUrl from "../helpers/apiURLs.js";
import api from "../helpers/interceptor";
import { toastr } from "react-redux-toastr";
import {defaultErrorMessage} from "../appConstants";

// Message Get Action
export const getUserMessagesAction = (payload, chatId, searchQuery = '') => {
  return async (dispatch) => {
    dispatch(commonServices.setLoader(true));
    let url = '';

    switch (payload) {
      case 'userListForChat':
        // base URL
        url = getApiUrl('userMessages', 'userListForChat') + '?doPaging=false';

        // if searchQuery isn't empty, append it
        if (searchQuery) {
          url += `&searchKeyword=${searchQuery}`;
        }
        break;
      default:
        break;
    }
    try {
      const response = await api.get(url);

      // response.data.filter((obj) => obj?.id !== authUserInstance?.id)
      switch (payload) {
        case 'userListForChat':
          const responseData = response.data ?? [];
          // dispatch({ type: messageAction.USER_MESSAGES_LIST, payload: responseData.filter((obj) => obj?.id !== chatId) });
          dispatch({ type: messageAction.USER_MESSAGES_LIST, payload: responseData});
          break;
        default:
          break;
      }
      dispatch(commonServices.setLoader(false));
      return response;
    } catch (err) {
      dispatch(commonServices.setLoader(false));
      toastr.error(defaultErrorMessage, '');
    }
  };
}
//  Get User Chat Messages
export const getUserChatMessagesAction = (payload, quaryParams = {}) => {
  return async (dispatch) => {
    dispatch(commonServices.setLoader(true));
    let url = '';

    switch (payload) {
      case 'userChatMessages':
        // base URL
        url = getApiUrl('userMessages', 'userChatMessages');

        // if quaryParams isn't empty, append it
        if (quaryParams) {
          url += `?receiverId=${quaryParams.receiverId}&senderId=${quaryParams.senderId}`;
        }
        break;
      default:
        break;
    }
    try {
      const response = await api.get(url);
      switch (payload) {
        case 'userChatMessages':
          const responseData = response.data ?? [];
          // dispatch({ type: messageAction.USER_CHAT_MESSAGES_LIST, payload: responseData });
          dispatch({ type: messageAction.USER_CHAT_MESSAGES_LIST, payload: responseData.map(item => {
              return {
                messageId: item.messageId,
                senderId: item.senderId,
                receiverId: item.receiverId,
                messageTitle: item.messageTitle,
                messageText: item.messageText,
                messageTypeId: item.messageTypeId,
                creationDatetime: item.messageDateTime,
                creationDate: item.messageDate,
                creationTime: item.messageTime,
                messageTime24hr: item.messageTime24hr,
                messageTime12hr: item.messageTime12hrWeb,
                forSenderOnly: item.forSenderOnly,
                forReceiverOnly: item.forReceiverOnly,
                otherInfo: item.otherInfo,
              };
            }) });
          break;
        default:
          break;
      }
      dispatch(commonServices.setLoader(false));
      return response;
    } catch (err) {
      dispatch({ type: messageAction.USER_CHAT_MESSAGES_LIST, payload: [] })
      dispatch(commonServices.setLoader(false));
      console.log("err", err)
      if (!(err.status === 404 && err.message === 'Data Not Found')) {
        toastr.error(defaultErrorMessage, '');
      }
    }
  };
}

export const makeAnOfferAction = (apiRoute, payload, nurseId, successCallback = null) => {
  return async (dispatch) => {
    dispatch(commonServices.setLoader(true));
    let url = '';

    switch (apiRoute) {
      case 'makeAnOffer':
        // base URL
        url = getApiUrl('commonAPI', 'makeAnOffer');
        // if quaryParams isn't empty, append it
        if (nurseId) {
          url += `/${nurseId}`;
        }
        break;
      default:
        break;
    }
    try {
      console.log("payload seledted payload", payload)
      const response = await api.put(url, payload);
      toastr.success("Offer sent successfully", "");
      switch (payload) {
        case 'makeAnOffer':
          dispatch({ type: messageAction.MAKE_AN_OFFER, payload: response.data })
          break;
        default:
          break;
      }
      dispatch(commonServices.setLoader(false));
      if (successCallback != null) successCallback(response);
      return response;
    } catch (err) {
      dispatch(commonServices.setLoader(false));
      toastr.error(defaultErrorMessage, '');
    }
  };
}

export const acceptDeclineOfferAction = (apiRoute, payload, nurseId = null) => {
  return async (dispatch) => {
    dispatch(commonServices.setLoader(true));
    let url = 'accept';

    switch (apiRoute) {
      case 'acceptDeclineOffer':
        // base URL
        url = getApiUrl('userMessages', 'acceptDeclineOffer');
        // if quaryParams isn't empty, append it
        if (apiRoute && nurseId != null) {
          url += `/${nurseId}`;
        }
        break;
      default:
        break;
    }
    try {
      const response = await api.put(url, payload);
      switch (apiRoute) {
        case 'acceptDeclineOffer':
          dispatch({ type: messageAction.ACCEPT_DECLINE_OFFER, payload: response.data })
          break;
        default:
          break;
      }
      dispatch(commonServices.setLoader(false));
      return response;
    } catch (err) {
      dispatch(commonServices.setLoader(false));
      toastr.error(defaultErrorMessage, '');
    }
  };
}

