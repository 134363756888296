import React from 'react'
import CustomLabel from "../fields/CustomLabel";

const CustomInputTextareaField = ({input, readOnly, name, label, value, placeholder, disabled, className, requiredMark, labelClass, meta: {touched, error, warning, active, visited }, ...custom }) => (
    <>
    {
        <>
            {/*<label>{label} {requiredMark && <span className="mandatory-field">*</span>}</label>*/}
            {label && <CustomLabel labelFor={name} requiredMark={requiredMark} readOnly={readOnly} className={labelClass || null} labelText={label}/>}
            <textarea
                value={value}
                placeholder={placeholder || ''}
                readOnly={readOnly || false}
                name={name}
                disabled={disabled || false}
                {...input}
                {...custom}
                className={className || ''}
            />
            {touched && error && (<span className="field-error text-decoration-underline1" style={{position: 'relative', top: '-8px'}}>{error}</span>)}
        </>
    }
    </>
)

export default CustomInputTextareaField
