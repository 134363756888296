import React, {useEffect, useState} from "react";
import {Stack} from "@mui/material";
import {Field, reduxForm} from "redux-form";
import CustomInputField from "../../../../../../sharedComponents/reduxfields/customInputField";
import classes from "../../../css/careInformation.module.css";
import CustomDropDownField from "../../../../../../sharedComponents/reduxfields/customDropDown";
import {connect, useDispatch, useSelector} from "react-redux";
import CustomCheckboxField from "../../../../../../sharedComponents/reduxfields/CustomCheckBox";
import CustomInputTextareaField from "../../../../../../sharedComponents/reduxfields/customTextareaField";
import {minLength10, required} from "../../../../../../helpers/validation";
import PrimaryButton from "../../../../../../sharedComponents/buttons/PrimaryButton";
import {HttpsAction} from "../../../../../../commonApiFunction/httpsAction";
import {useParams} from "react-router-dom";
import {getCareInfoById} from "../../../../../../services/client";
import {invalidateClientsList} from "../../../../../../queries/agency/clientsList";
import {useQueryClient} from "@tanstack/react-query";
import CustomTextarea from "../../../../../../sharedComponents/reduxfields/CustomTextArea";
import stringHelperFunctions from "../../../../../../helpers/string.helpers";
import booleanHelperFunctions from "../../../../../../helpers/boolean.helpers";

export const statusOptions = [
    {label: 'ACTIVE', id: "active", value: '1'},
    {label: 'INACTIVE', id: "inActive", value: '2'},
    {label: 'DISCHARGE', id: "discharge", value: '3'}
];

const PatientActiveDischargeInactivateModal = ({handleSubmit, clientId, onClose, handleChange, agencyId}) => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    // const {clientId} = useParams();

    const [isActive, setIsActive] = useState(false);
    const [isDischarged, setIsDischarged] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [saveButtonText, setSaveButtonText] = useState('');
    // const [dateFieldLabelText, setDateFieldLabelText] = useState('');

    const data = useSelector(state => state.client?.careInfoById);

    const onSubmit = ({
                          statusId,
                          effectiveEndDate,
                          terminationReason,
                          dischargeDate,
                          dischargeReason,
                          allowVerifiedVisitsToBeCancelled,
                      }) => {

        let payload = {};

        if (statusId === '2') {
            payload = {
                statusId: 2,
                effectiveEndDate: stringHelperFunctions.nullIfEmpty(effectiveEndDate),
                terminationReason: stringHelperFunctions.nullIfEmpty(terminationReason),
                allowVerifiedVisitsToBeCancelled: booleanHelperFunctions.nullIfEmpty(allowVerifiedVisitsToBeCancelled),
            }
        } else {
            if (statusId === "1") {
                payload = {
                    statusId: 1
                }
            } else {
                payload = {
                    statusId: 3,
                    // dischargeDate: effectiveEndDate,
                    // dischargeReason: terminationReason,
                    dischargeDate: stringHelperFunctions.nullIfEmpty(dischargeDate),
                    dischargeReason: stringHelperFunctions.nullIfEmpty(dischargeReason),
                    allowVerifiedVisitsToBeCancelled: booleanHelperFunctions.nullIfEmpty(allowVerifiedVisitsToBeCancelled),
                }
            }
        }

        dispatch(
            HttpsAction({
                method: "PUT",
                apiSection: "client",
                apiName: "setEffectiveEndDate",
                queryParameter: `/${clientId}`,
                data: payload,
                singleOperation: false,
                positiveCallBack: () => {
                    invalidateClientsList(queryClient, agencyId);
                    dispatch(getCareInfoById(clientId, function () {
                        onClose();
                    }));
                },
            })
        );
    };

    // function updateDateFieldText(isDischargedSelected) {
    //     let text = 'Effective End Date';
    //     if (isDischargedSelected) text = 'Discharge Date';
    //     setDateFieldLabelText(text);
    // }

    function updateSaveButtonText(isActiveSelected, isDischargedSelected) {
        let text = 'Set Effective End Date';
        if (isActiveSelected) text = 'Activate';
        if (isDischargedSelected) text = 'Discharge Patient';
        setSaveButtonText(text);
    }

    const handleStatusChanged = target => {
        handleChange(target);
        const {name, value} = target;
        updateSaveButtonText(value === '1', value === '3');
        // updateDateFieldText(value === '3');
        setIsActive(value === '1');
        setIsDischarged(value === '3');

        const statusId = data?.statusId || 0;
        setIsDisabled(+value === statusId);
    };

    useEffect(() => {
        const statusId = data?.statusId || 0;
        setIsActive(statusId === 1);
        setIsDischarged(statusId === 3);
        updateSaveButtonText(statusId === 1, statusId === 3);
        // updateDateFieldText(statusId === 3);
    }, [data]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                    <div className="col-10">
                        <div className="mb-3 input-field-main new">
                            <label>Status</label>
                            <Field
                                className="form-control mk-wi"
                                name="statusId"
                                component={CustomDropDownField}
                                validate={required}
                                requiredMark={true}
                                onChange={({target}) => handleStatusChanged(target)}
                            >
                                <option value="">Please select</option>
                                {statusOptions?.map((res, index) => {
                                    return (
                                        <option key={index} value={res.value}>
                                            {res.label}
                                        </option>
                                    );
                                })}
                            </Field>
                        </div>
                    </div>
                    {!isActive && <>
                        {!isDischarged && <div className="col-10">
                            <div className="mb-3 input-field-main new">
                                <Field
                                  className="form-control mk-wi"
                                  name="effectiveEndDate"
                                  label="Effective End Date"
                                  type="date"
                                  component={CustomInputField}
                                  validate={!isActive ? required : undefined}
                                  requiredMark={!isActive}
                                  onChange={({target}) => handleChange(target)}
                                />
                            </div>
                        </div>}
                        {isDischarged && <div className="col-10">
                            <div className="mb-3 input-field-main new">
                                <Field
                                  className="form-control mk-wi"
                                  name="dischargeDate"
                                  label="Discharge Date"
                                  type="date"
                                  component={CustomInputField}
                                  validate={!isActive ? required : undefined}
                                  requiredMark={!isActive}
                                  onChange={({target}) => handleChange(target)}
                                />
                            </div>
                        </div>}
                        {!isDischarged && <div className="col-10">
                            <div className="mb-3 input-field-main new">
                                <Field
                                  className="form-control mk-wi"
                                  name="terminationReason"
                                  // label={
                                  //     <Stack direction={"row"} gap={"5px"}>
                                  //         {" "}
                                  //         <Stack className={classes.reason}>
                                  //             {" "}
                                  //             Termination Reason/Explanation{" "}
                                  //         </Stack>
                                  //         <Stack className={classes.optional}>
                                  //             {" "}
                                  //             (optional){" "}
                                  //         </Stack>{" "}
                                  //     </Stack>
                                  // }
                                  label="Reason/Explanation"
                                  component={CustomTextarea}
                                  validate={minLength10}
                                />
                            </div>
                        </div>}
                        {isDischarged && <div className="col-10">
                            <div className="mb-3 input-field-main new">
                                <Field
                                  className="form-control mk-wi"
                                  name="dischargeReason"
                                  label={
                                      <Stack direction={"row"} gap={"5px"}>
                                          {" "}
                                          <Stack className={classes.reason}>
                                              {" "}
                                              Discharge Reason/Explanation{" "}
                                          </Stack>
                                          <Stack className={classes.optional}>
                                              {" "}
                                              (optional){" "}
                                          </Stack>{" "}
                                      </Stack>
                                  }
                                  component={CustomInputTextareaField}
                                  validate={minLength10}
                                />
                            </div>
                        </div>}
                        {!isDischarged && <div className="col-12">
                            <div className="mb-3 input-field-main new">
                                <Stack className={classes.careInfoCaution}>
                                    Caution: When you set an effective end date, it will impact the
                                    end date for all scheduled events, and any one-time visits
                                    scheduled after the effective end date will be automatically
                                    canceled. Please be aware that this action cannot be reversed.
                                </Stack>
                            </div>
                        </div>}
                        <div className="col-10">
                            <div className="mb-3 input-field-main new">
                                <div className={`form-check ${classes.verifiedVisitsCheckbox}`}>
                                    <Field
                                      className="form-check-input"
                                      name="allowVerifiedVisitsToBeCancelled"
                                      label="Allow Verified Visits to be cancelled"
                                      component={CustomCheckboxField}
                                    />
                                </div>
                            </div>
                        </div>
                    </>}
                    <div className="d-flex justify-content-center">
                        <PrimaryButton
                          disabled={isDisabled}
                          type="submit"
                          className="btn-view-repo gap-2 px-5 py-2"
                          text={saveButtonText}
                          onClick={() => undefined}
                        />
                    </div>
                </div>
            </form>
        </>
    );
};

const mapStateToProps = state => {
    const {client: { careInfoById = null }} = state;

    return {
        initialValues: {
            statusId: careInfoById?.statusId?.toString(),
            effectiveEndDate: careInfoById?.effectiveEndDate || "",
            terminationReason: careInfoById?.terminationReason || "",
            dischargeDate: careInfoById?.dischargeDate || "",
            dischargeReason: careInfoById?.dischargeReason || "",
        }
    }
};

export default connect(mapStateToProps)(
  reduxForm({form: "PatientActiveDischargeInactivateForm", enableReinitialize: true})(PatientActiveDischargeInactivateModal)
);
