import React, {useEffect, useState} from "react";
import TableTitle from "../Dashboard/TableTitle";
import {Card} from "react-bootstrap";
import Table from "../../../sharedComponents/table/Table";
import {HttpsAction} from "../../../commonApiFunction/httpsAction";
import {useNavigate} from "react-router";
import Pagination from "../../../sharedComponents/pagination";
import {getNursesData} from "../../redux/actions/AdminActions";
import {connect, useDispatch} from "react-redux";
import useFetch from "../../../utils/hooks/useFetch";
import {ADMIN_NURSES_NAV_TITLES} from "../../../appConstants";

const NursesOverview = ({title, subTitle, apiKey, /*firstLoad, setFirstLoadCompleted, firstLoadCompleted, */data, isFullView = false}) => {
// console.log('test- data', data);
//     const navigate = useNavigate();
    const dispatch = useDispatch();

    // const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);

    // const handleSearch = (e) => {
    //     setSearchTerm(e.target.value);
    // };

    const serviceHeaderData = [
        {
            title: "S/N",
            dataIndex: "sn",
        },
        {
            title: "Name",
            dataIndex: "name",
        },
        {
            title: "Email address",
            dataIndex: "email",
        },
        {
            title: "Date created",
            dataIndex: "dateCreated",
        },
        {
            title: "Preferred rates",
            dataIndex: "preferredRates",
        },
        {
            title: "Verification",
            dataIndex: "verification",
        },
        {
            title: "Status",
            dataIndex: "status",
        },
    ];

    const withdrawalRequestServiceHeaderData = [
        {
            title: "S/N",
            dataIndex: "sn",
        },
        {
            title: "Nurse Name",
            dataIndex: "name",
        },
        {
            title: "Email address",
            dataIndex: "email",
        },
        {
            title: "Date",
            dataIndex: "date",
        },
        {
            title: "Amount",
            dataIndex: "amount",
        }
    ];

    // const nursesListData = data != null && 'rows' in data ? data : data != null && data?.allNursesList || [];

    const transformedArray = (data?.rows || [])?.map((item, index) => {
        return {
            id: item.id,
            sn: item?.nurseId,
            name: item?.nurseFullName,
            email: item?.email,
            dateCreated: item.creationDateFormatUs,
            // hours:
            preferredRates: item.preferredRates || "N/A",
            verification: item.isVerified ? "Verified" : "Unverified",
            status: item.isAvailable ? "Available" : "Unavailable"
        }
    });

    const withdrawalRequestTransformedArray = (data?.rows || [])?.map((item, index) => {
        return {
            id: item.id,
            sn: item?.nurseId,
            name: item?.nurseFullName,
            email: item?.email,
            date: item.transactionDateFormatUs,
            amount: `$${item.amount}`,
        }
    });

    function handlePageChange(page) {
        setCurrentPage(page);
    }

    useEffect(() => {
        setCurrentPage(1);
    }, [apiKey]);

    useEffect(() => {
        // getData(currentPage);
        dispatch(getNursesData(apiKey, currentPage));
    }, [apiKey, currentPage]);

    return (
        <>
            <TableTitle
                title={title}
                // isFullView={isFullView}
                isFullView={false}
                // onSearch={handleSearch}
                subTitle={subTitle}
            />
            <Card>
                <Card.Body>
                    {title !== ADMIN_NURSES_NAV_TITLES.WITHDRAWAL_REQUEST && <>
                        <Table headers={serviceHeaderData} rows={transformedArray}/>
                        <Pagination
                          // currentPage={nursesListData?.page}
                          // totalRecords={nursesListData?.total}
                          // limit={nursesListData?.pageSize}
                          currentPage={data?.page}
                          totalRecords={data?.total}
                          limit={data?.pageSize}
                          onPageChange={handlePageChange}
                        />
                    </>}
                    {title === ADMIN_NURSES_NAV_TITLES.WITHDRAWAL_REQUEST && <>
                        <Table headers={withdrawalRequestServiceHeaderData} rows={withdrawalRequestTransformedArray} />
                        <Pagination
                          currentPage={data?.page}
                          totalRecords={data?.total}
                          limit={data?.pageSize}
                          onPageChange={handlePageChange}
                        />
                    </>}
                </Card.Body>
            </Card>
        </>
    )
};

const mapStateToProps = (state, ownProps) => {
    const { admin } = state;
    if (ownProps?.apiKey === 'nurse-list') {
        return {
            data: admin?.nursesList,
        };
    }
    if (ownProps?.apiKey === 'verified-nurse-list') {
        return {
            data: admin?.verifiedNurseList,
        };
    }
    if (ownProps?.apiKey === 'unverified-nurse-list') {
        return {
            data: admin?.unverifiedNurseList,
        };
    }
    if (ownProps?.apiKey === 'withdrawal-request-list') {
        return {
            data: admin?.withdrawalRequest,
        };
    }
    return {};
};

// export default NursesOverview;
export default connect(mapStateToProps)(NursesOverview);
