import {Navigate} from "react-router-dom";
import AgenciesLayout from "../layouts/AgenciesLayout";
import AgencyDashboard from "../Agency/components/Dashboard/index";
import PostJob from "../Agency/components/PostJob";
import FindNursesList from "../Agency/FindNursesList";
import ServiceRequestsLayout from "../layouts/ServiceRequestsLayout";
import AllRequest from "../Agency/components/ServiceRequest/AllRequest";
import ScheduledRequest from "../Agency/components/ServiceRequest/scheduledRequests";
import AgencyLoginPage from "../Agency/AgencyLoginPage";
import AdminLoginPage from "../Admin/AdminLoginPage";
import AdminDashboard from "../Admin/AdminDashboard";
import AdminLayout from "../layouts/AdminLayout";
import AllNurses from "../Admin/components/Nurse/AllNurses";
import VerifiedNurses from "../Admin/components/Nurse/VerifiedNurse";
import UnverifiedNurses from "../Admin/components/Nurse/UnverifiedNurses";
import WithdrawalRequest from "../Admin/components/Nurse/WithdrawalRequest";
import AdminAgenciesPage from "../Admin/AdminAgenciesPage";
import ViewAgency from "../Admin/components/Agencies/ViewAgency";
import {isAdminUser, isAgencyUser, isLoggedIn} from "../helpers/apiMethods";
import ActiveRequest from "../Agency/components/ServiceRequest/ActiveRequest";
import PostedJobsPending from "../Agency/components/ServiceRequest/postedJobsPending";
import SrInitialAssessment from "../Agency/components/ServiceRequest/initialAssessments";
import SrSupervisoryVisit from "../Agency/components/ServiceRequest/supervisoryVisits";
import SrDischargeSummary from "../Agency/components/ServiceRequest/dischargeSummaries";
import SrCarePlans from "../Agency/components/ServiceRequest/carePlans";
import CompleteRequest from "../Agency/components/ServiceRequest/CompleteRequest";
import MissedRequests from "../Agency/components/ServiceRequest/MissedRequests";
import IncompleteVisits from "../Agency/components/ServiceRequest/IncompleteVisits";
import PostedJobsInServiceRequestsLayout from "../layouts/PostedJobsInServiceRequestsLayout";
import PostedJobsCompleted from "../Agency/components/ServiceRequest/postedJobsCompleted";
import PostedJobsInProgress from "../Agency/components/ServiceRequest/postedJobsInProgress";
import PostedJobsExpired from "../Agency/components/ServiceRequest/postedJobsExpired";
import PostedJobsAccepted from "../Agency/components/ServiceRequest/postedJobsAccepted";
import PostedJobsActive from "../Agency/components/ServiceRequest/postedJobsActive";
import ChartingInServiceRequestsLayout from "../layouts/ChartingInServiceRequestsLayout";
import NurseList from "../Agency/components/Nurses/NurseList";
import HiredNurses from "../Agency/components/Nurses/HiredNurses";
import NursesListLayout from "../layouts/NursesListLayout";
import Profile from "../Agency/components/Nurses/NuresesNewRequestTabs/profile";
import AccountDetails from "../Agency/components/Nurses/NuresesNewRequestTabs/AccountDetails";
import MailingInfo from "../Agency/components/Nurses/NuresesNewRequestTabs/MailingInfo";
import WorkExperience from "../Agency/components/Nurses/NuresesNewRequestTabs/WorkExperience";
import Education from "../Agency/components/Nurses/NuresesNewRequestTabs/Education";
import AdditionalQuestions from "../Agency/components/Nurses/NuresesNewRequestTabs/addtionalQuestion";
import EmergencyContacts from "../Agency/components/Nurses/NuresesNewRequestTabs/emergencyContact";
import NurseProfileLayout from "../layouts/NurseProfileLayout";
import NursePersonalInfoLayout from "../layouts/NursePersonalInfoLayout";
import NurseCalender from "../Agency/components/Nurses/Profile/Calender";
import NurseDocuments from "../Agency/components/Nurses/NuresesNewRequestTabs/Document";
import NurseCharting from "../Agency/components/Nurses/Profile/NurseCharting";
import CertsEvalsLayout from "../layouts/CertsEvalsLayout";
import CertificationNew from "../Agency/components/Nurses/NuresesNewRequestTabs/CertificationNew";
import OtherCertificationsNew from "../Agency/components/Nurses/NuresesNewRequestTabs/OtherCertificationsNew";
import EvaluationsNew from "../Agency/components/Nurses/NuresesNewRequestTabs/EvaluationsNew";
import JobPreferences from "../Agency/components/Nurses/NuresesNewRequestTabs/jobPreferences";
import AgencyClients from "../Agency/components/Client";
import ClientProfileLayout from "../layouts/ClientProfileLayout";
import ClientPersonalInfoLayout from "../layouts/ClientPersonalInfoLayout";
import PersonalInformation from "../Agency/components/Client/ClientProfile/Profile/PersonalInformation";
import CareInformation from "../Agency/components/Client/ClientProfile/Profile/CareInformation";
import ResidentialAddress from "../Agency/components/Client/ClientProfile/Profile/ResidentialAddress";
import BillingAddress from "../Agency/components/Client/ClientProfile/Profile/BillingAddress";
import ContactInformation from "../Agency/components/Client/ClientProfile/Profile/ContactInformation";
import Characteristics from "../Agency/components/Client/ClientProfile/Profile/Characteristics";
import PetInformation from "../Agency/components/Client/ClientProfile/Profile/PetInformation";
import PatientCalender from "../Agency/components/Client/ClientProfile/Calender";
import CareNote from "../Agency/components/Client/ClientProfile/CareNote";
import Notes from "../Agency/components/Client/ClientProfile/Notes";
import RespParty from "../Agency/components/Client/ClientProfile/RespParty";
import PatientsDocuments from "../Agency/components/Client/ClientProfile/Document";
import Charting from "../Agency/components/Client/ClientProfile/Charting";
import Healthcare from "../Agency/components/Client/ClientProfile/Healthcare";
import HealthcareLayout from "../layouts/HealthcareLayout";
import HealthcareInformation from "../Agency/components/Client/ClientProfile/Healthcare/HealthcareInformation";
import ExtraInformation from "../Agency/components/Client/ClientProfile/Healthcare/ExtraInformation";
import Medications from "../Agency/components/Client/ClientProfile/Healthcare/Medications";
import Allergies from "../Agency/components/Client/ClientProfile/Healthcare/Allergies";
import AllAlerts from "../Agency/components/Alerts/AllAlerts";
import AlertsLayout from "../layouts/AlertsLayout";
import Jobs from "../Agency/components/Alerts/Jobs";
import Bids from "../Agency/components/Alerts/Bids";
import ServiceRequests from "../Agency/components/Alerts/ServiceRequests";
import ClockInClockOutTime from "../Agency/components/Alerts/ClockInClockOutTime";
import Transactions from "../Agency/components/Alerts/Transactions";
import ExpiryRenewal from "../Agency/components/Alerts/ExpiryRenewal";
import AgencyMessages from "../Agency/components/Messages";
import AgencyChartingForms from "../Agency/components/AgencyChartingForms";
import ChartingLayout from "../layouts/ChartingLayout";
import InitialAssessment from "../Agency/components/Charting/InitialAssessment";
import SupervisorVisit from "../Agency/components/Charting/SupervisorVisit";
import DischargeSummary from "../Agency/components/Charting/DischargeSummary";
import CarePlan from "../Agency/components/Charting/CarePlan";
import SubscriptionHistoryComponent from "../Agency/components/Dashboard/SubscriptionHistory/index";
import PayoutHistoryComponent from "../Agency/components/Dashboard/PayoutHistory/index";
import TransactionsLayout from "../layouts/TransactionsLayout";
import SubscriptionOffers from "../Agency/components/Dashboard/Subscription";
import AgencyDetails from "../Agency/components/AgencyProfile/AgencyDetails";
import AgencyProfileLayout from "../layouts/AgencyProfileLayout";
import AgencyAddress from "../Agency/components/AgencyProfile/Address";
import AuthPerson from "../Agency/components/AgencyProfile/AuthPerson";
import AgencyLogo from "../Agency/components/AgencyProfile/Logo";
import AgencyWallet from "../Admin/components/Agencies/Tabs/Wallet";
import Subscriptions from "../Admin/components/Agencies/Tabs/Subscriptions";
import PostedJobs from "../Admin/components/Agencies/Tabs/PostedJobs";
import AdminNursesPage from "../Admin/AdminNursesPage";
import AddNewJobServiceRequest from "../Agency/components/ServiceRequest/NewServiceRequest";
import PayToCaregiver from "../Agency/components/ServiceRequest/PayToCaregiver";
// import AgencySubscription from "../Agency/components/Dashboard/Subscription";

export const adminLoginPath = "/login/admin";
export const adminFirstPagePathAfterLogin = "/admin/dashboard";
export const agencyLoginPath = "/login/agency";
export const agencyFirstPagePathAfterLogin = "/agency/dashboard";
export const agencyVerifyEmailPage = "/agency/verify-email";

function PrivateRoute({ children }) {
  const isAuthenticated = isLoggedIn();
  return isAuthenticated ? children : <Navigate to="/login" />;
}

function AdminRoute({ children }) {
  const isAuthenticated = isLoggedIn() && isAdminUser();
  return isAuthenticated ? children : <Navigate to={adminLoginPath} />;
}

function AgencyRoute({ children }) {
  const isAuthenticated = isLoggedIn() && isAgencyUser();
  return isAuthenticated ? children : <Navigate to={agencyLoginPath} />;
}

export const appRoutesForRouterProvider = [
  {
    path: "/",
    element: <Navigate to={agencyLoginPath} />,
  },
  {
    path: agencyLoginPath,
    element: <AgencyLoginPage />,
    replace: true,
  },
  {
    path: adminLoginPath,
    element: <AdminLoginPage />,
    replace: true,
  },
  {
    path: "/agency/*",
    element: <AgencyRoute><AgenciesLayout /></AgencyRoute>,
    children: [
      {
        path: "dashboard",
        element: <AgencyDashboard />,
        reloadDocument: true,
      },
      {
        path: "subscriptions",
        element: <SubscriptionOffers />,
      },
      {
        element: <TransactionsLayout />,
        children: [
          {
            path: "payout-history",
            element: <PayoutHistoryComponent />,
          },
          {
            path: "subscribed-plans",
            element: <SubscriptionHistoryComponent />,
          },
        ]
      },
      {
        path: "profile/*",
        element: <AgencyProfileLayout />,
        children: [
          {
            index: true,
            element: <Navigate to={'/agency/profile/details'} />,
          },
          {
            path: "details",
            element: <AgencyDetails />,
          },
          {
            path: "address",
            element: <AgencyAddress />,
          },
          {
            path: "auth-person",
            element: <AuthPerson />,
          },
          {
            path: "logo",
            element: <AgencyLogo />,
          },
        ]
      },
      {
        path: "postjob",
        element: <PostJob />,
      },
      {
        path: "postjob/:id",
        element: <PostJob />,
      },
      {
        path: "new-service-request",
        element: <AddNewJobServiceRequest />,
      },
      {
        path: "pay-to-nurse/:taskId/:serviceRequestId",
        element: <PayToCaregiver />,
      },
      {
        path: "servicerequest/*",
        element: <ServiceRequestsLayout />,
        children: [
          {
            path: "allrequest",
            element: <AllRequest />,
          },
          {
            path: "scheduledrequest",
            element: <ScheduledRequest />,
          },
          {
            path: "activerequest",
            element: <ActiveRequest />,
          },
          // {
          //   path: "pendingrequest",
          //   element: <PendingRequest />,
          // },
          {
            path: "postedjobs/*",
            element: <PostedJobsInServiceRequestsLayout />,
            children: [
              {
                index: true,
                element: <Navigate to={'/agency/servicerequest/postedjobs/pending'} />,
              },
              {
                path: "pending",
                element: <PostedJobsPending />,
              },
              {
                path: "active",
                element: <PostedJobsActive />,
              },
              {
                path: "accepted",
                element: <PostedJobsAccepted />,
              },
              {
                path: "expired",
                element: <PostedJobsExpired />,
              },
              {
                path: "inprogress",
                element: <PostedJobsInProgress />,
              },
              {
                path: "completed",
                element: <PostedJobsCompleted />,
              },
            ]
          },
          {
            path: "charting/*",
            element: <ChartingInServiceRequestsLayout />,
            children: [
              {
                index: true,
                element: <Navigate to={'/agency/servicerequest/charting/initialAssessments'} />,
              },
              {
                path: "initialAssessments",
                element: <SrInitialAssessment />,
              },
              {
                path: "supervisoryVisits",
                element: <SrSupervisoryVisit />,
              },
              {
                path: "dischargeSummaries",
                element: <SrDischargeSummary />,
              },
              {
                path: "carePlans",
                element: <SrCarePlans />,
              },
            ]
          },
          {
            path: "completeRequest",
            element: <CompleteRequest />,
          },
          {
            path: "missedRequests",
            element: <MissedRequests />,
          },
          {
            path: "incompleteVisits",
            element: <IncompleteVisits />,
          },
        ]
      },
      {
        path: "nurses/*",
        children: [
          {
            path: "find",
            exact: true,
            element: <FindNursesList />,
          },
          {
            path: "list/*",
            element: <NursesListLayout />,
            children: [
              {
                path: "internal",
                element: <NurseList />,
              },
              {
                path: "hired",
                element: <HiredNurses />,
              },
            ]
          },
          {
            path: "edit/*",
            children: [
              {
                path: "profile/*",
                element: <NurseProfileLayout />,
                children: [
                  // {
                  //   index: true,
                  //   element: <Navigate to={'/agency/nurses/edit/profile/personal-info/*'} />,
                  // },
                  {
                    path: "personal-info/*",
                    element: <NursePersonalInfoLayout />,
                    children: [
                      {
                        index: true,
                        // element: <Navigate to={'/agency/nurses/edit/profile/personal-info/main/:id'} />,
                        element: <Navigate to={'/agency/nurses/edit/profile/personal-info/main'} />,
                      },
                      {
                        // path: 'main/:id',
                        path: 'main',
                        element: <Profile />,
                      },
                      {
                        // path: "account-details/:id",
                        path: "account-details",
                        element: <AccountDetails />,
                      },
                      {
                        // path: "mailing-info/:id",
                        path: "mailing-info",
                        element: <MailingInfo />,
                      },
                      {
                        // path: "work-experience/:id",
                        path: "work-experience",
                        element: <WorkExperience />,
                      },
                      {
                        // path: "job-preferences/:id",
                        path: "job-preferences",
                        element: <JobPreferences />,
                      },
                      {
                        // path: "education/:id",
                        path: "education",
                        element: <Education />,
                      },
                      {
                        // path: "additional-questions/:id",
                        path: "additional-questions",
                        element: <AdditionalQuestions />,
                      },
                      {
                        // path: "emergency-contacts/:id",
                        path: "emergency-contacts",
                        element: <EmergencyContacts />,
                      },
                    ]
                  },
                  {
                    // path: "calender/:id",
                    path: "calender",
                    element: <NurseCalender />,
                  },
                  {
                    path: "certs-evals/*",
                    element: <CertsEvalsLayout />,
                    children: [
                      {
                        index: true,
//                         render: (props) => {
// console.log('test- props', props);
//                           const nurseId = 79;
//                           return <Navigate to={'/agency/nurses/edit/profile/certs-evals/certifications/' + nurseId} />
//                         },
//                         element: <Navigate to={'/agency/nurses/edit/profile/certs-evals/certifications/:id'} />,
                        // element: <Navigate to={'/agency/nurses/edit/profile/certs-evals/certifications/79'} state={{ id: 79 }} />,
                        element: <Navigate to={'/agency/nurses/edit/profile/certs-evals/certifications'} />,
                      },
                      {
                        // path: "certifications/:id",
                        path: "certifications",
                        element: <CertificationNew />,
                      },
                      {
                        // path: "otherCertifications/:id",
                        path: "otherCertifications",
                        element: <OtherCertificationsNew />,
                      },
                      {
                        // path: "evaluations/:id",
                        path: "evaluations",
                        element: <EvaluationsNew />,
                      },
                    ]
                  },
                  {
                    // path: "documents/:id",
                    path: "documents",
                    element: <NurseDocuments />,
                  },
                  {
                    // path: "charting/:id",
                    path: "charting",
                    element: <NurseCharting />,
                  },
                ]
              },
            ]
          },
        ]
      },
      {
        path: "clients/*",
        children: [
          {
            index: true,
            element: <AgencyClients />,
          },
          {
            path: "edit/*",
            element: <ClientProfileLayout />,
            children: [
              // {
              //   index: true,
              //   element: <Navigate to={'/agency/clients/edit/profile'} />,
              // },
              {
                path: "profile/*",
                element: <ClientPersonalInfoLayout />,
                children: [
                  {
                    index: true,
                    element: <Navigate to={'/agency/clients/edit/profile/personal-info'} />,
                  },
                  {
                    // path: "personal-info/:id",
                    path: "personal-info",
                    element: <PersonalInformation />,
                  },
                  {
                    // path: "careInformation/:id",
                    path: "care-information",
                    element: <CareInformation />,
                  },
                  {
                    // path: "residentialAddress/:id",
                    path: "residential-address",
                    element: <ResidentialAddress />,
                  },
                  {
                    // path: "billingAddress/:id",
                    path: "billing-address",
                    element: <BillingAddress />,
                  },
                  {
                    // path: "contactInformation/:id",
                    path: "contact-information",
                    element: <ContactInformation />,
                  },
                  {
                    // path: "characteristics/:id",
                    path: "characteristics",
                    element: <Characteristics />,
                  },
                  {
                    // path: "petInformation/:id",
                    path: "pet-info",
                    element: <PetInformation />,
                  },
                ],
              },
              {
                // path: "calender/:id",
                path: "calendar",
                element: <PatientCalender />,
              },
              {
                // path: "careNotes/:id",
                path: "care-notes/0",
                element: <CareNote />,
              },
              {
                // path: "notes/:id",
                path: "notes",
                element: <Notes />,
              },
              {
                // path: "RespParty/:id",
                path: "responsible-party",
                element: <RespParty />,
              },
              {
                // path: "documents/:id",
                path: "documents",
                element: <PatientsDocuments />,
              },
              {
                // path: "charting/:id",
                path: "charting",
                element: <Charting />,
              },
              {
                // path: "healthcare/:id",
                path: "healthcare/*",
                element: <HealthcareLayout />,
                children: [
                  {
                    index: true,
                    element: <Navigate to={'/agency/clients/edit/healthcare/basic-info'} />,
                  },
                  {
                    // path: "healthcareInformation/:id",
                    path: "basic-info",
                    element: <HealthcareInformation />,
                  },
                  {
                    // path: "extraInformation/:id",
                    path: "extra-info",
                    element: <ExtraInformation />,
                  },
                  {
                    // path: "medications/:id",
                    path: "medications",
                    element: <Medications />,
                  },
                  {
                    // path: "allergies/:id",
                    path: "allergies",
                    element: <Allergies />,
                  },
                ]
              },
            ],
          },
        ]
      },
      {
        path: "alerts/*",
        element: <AlertsLayout />,
        children: [
          {
            index: true,
            element: <Navigate to={'/agency/alerts/all'} />,
          },
          {
            path: "all",
            element: <AllAlerts />,
          },
          {
            path: "jobs",
            element: <Jobs />,
          },
          {
            path: "bids",
            element: <Bids />,
          },
          {
            path: "visits",
            element: <ServiceRequests />,
          },
          {
            path: "clock-in-clock-out-time",
            element: <ClockInClockOutTime />,
          },
          {
            path: "transactions",
            element: <Transactions />,
          },
          {
            path: "expiry-renewal",
            element: <ExpiryRenewal />,
          },
        ]
      },
      {
        path: "messages",
        element: <AgencyMessages />,
      },
      {
        path: "charting/*",
        element: <ChartingLayout />,
        children: [
          {
            index: true,
            element: <Navigate to={'/agency/charting/templates'} />,
          },
          {
            path: "templates",
            element: <AgencyChartingForms />,
          },
          {
            path: "initial-assessments",
            element: <InitialAssessment />,
          },
          {
            path: "supervisory-visits",
            element: <SupervisorVisit />,
          },
          {
            path: "discharge-summaries",
            element: <DischargeSummary />,
          },
          {
            path: "care-plans",
            element: <CarePlan />,
          },
        ]
      },
    ],
  },
  {
    path: "/admin/*",
    element: <AdminRoute><AdminLayout /></AdminRoute>,
    children: [
      {
        path: "dashboard",
        element: <AdminDashboard />,
      },
      {
        path: "list/*",
        children: [
          {
            path: "nurses/*",
            children: [
              {
                index: true,
                element: <Navigate to={'/admin/list/nurses/overview'} />,
              },
              {
                path: "overview",
                element: <AdminNursesPage />,
              },
              {
                path: "all",
                element: <AllNurses />,
              },
              {
                path: "verified",
                element: <VerifiedNurses />,
              },
              {
                path: "unverified",
                element: <UnverifiedNurses />,
              },
              {
                path: "withdrawal-requests",
                element: <WithdrawalRequest />,
              },
            ],
          },
          {
            path: "agencies/*",
            children: [
              {
                index: true,
                element: <Navigate to={'/admin/list/agencies/overview'} />,
              },
              {
                path: "overview",
                element: <AdminAgenciesPage />,
              },
              {
                path: "all",
                element: <ViewAgency type="allAgencies" />,
              },
              {
                path: "verified",
                element: <ViewAgency type="verifiedAgencies" />,
              },
              {
                path: "unverified",
                element: <ViewAgency type="unverifiedAgencies" />,
              },
            ]
          },
        ],
      },
      {
        path: "agency/*",
        children: [
          {
            path: "profile/*",
            element: <AgencyProfileLayout />,
            children: [
              {
                index: true,
                element: <Navigate to={'/agency/profile/details'} />,
              },
              {
                path: "details",
                element: <AgencyDetails />,
              },
              {
                path: "address",
                element: <AgencyAddress />,
              },
              {
                path: "auth-person",
                element: <AuthPerson />,
              },
              {
                path: "logo",
                element: <AgencyLogo />,
              },
              {
                path: "wallet",
                element: <AgencyWallet />,
              },
              {
                path: "subscriptions",
                element: <Subscriptions />,
              },
              {
                path: "postedJobs",
                element: <PostedJobs />,
              },
            ]
          },
          {
            path: "nurses/*",
            children: [
              {
                path: "edit/*",
                children: [
                  {
                    path: "profile/*",
                    element: <NurseProfileLayout />,
                    children: [
                      // {
                      //   index: true,
                      //   element: <Navigate to={'/admin/agency/nurses/edit/profile/personal-info/*'} />,
                      // },
                      {
                        path: "personal-info/*",
                        element: <NursePersonalInfoLayout />,
                        children: [
                          {
                            index: true,
                            element: <Navigate to={'/admin/agency/nurses/edit/profile/personal-info/main'} />,
                          },
                          {
                            // path: 'main/:id',
                            path: 'main',
                            element: <Profile />,
                          },
                          {
                            // path: "account-details/:id",
                            path: "account-details",
                            element: <AccountDetails />,
                          },
                          {
                            // path: "mailing-info/:id",
                            path: "mailing-info",
                            element: <MailingInfo />,
                          },
                          {
                            // path: "work-experience/:id",
                            path: "work-experience",
                            element: <WorkExperience />,
                          },
                          {
                            // path: "job-preferences/:id",
                            path: "job-preferences",
                            element: <JobPreferences />,
                          },
                          {
                            // path: "education/:id",
                            path: "education",
                            element: <Education />,
                          },
                          {
                            // path: "additional-questions/:id",
                            path: "additional-questions",
                            element: <AdditionalQuestions />,
                          },
                          {
                            // path: "emergency-contacts/:id",
                            path: "emergency-contacts",
                            element: <EmergencyContacts />,
                          },
                        ]
                      },
                      {
                        // path: "calender/:id",
                        path: "calender",
                        element: <NurseCalender />,
                      },
                      {
                        path: "certs-evals/*",
                        element: <CertsEvalsLayout />,
                        children: [
                          {
                            index: true,
                            element: <Navigate to={'/admin/agency/nurses/edit/profile/certs-evals/certifications'} />,
                          },
                          {
                            // path: "certifications/:id",
                            path: "certifications",
                            element: <CertificationNew />,
                          },
                          {
                            // path: "otherCertifications/:id",
                            path: "otherCertifications",
                            element: <OtherCertificationsNew />,
                          },
                          {
                            // path: "evaluations/:id",
                            path: "evaluations",
                            element: <EvaluationsNew />,
                          },
                        ]
                      },
                      {
                        // path: "documents/:id",
                        path: "documents",
                        element: <NurseDocuments />,
                      },
                      {
                        // path: "charting/:id",
                        path: "charting",
                        element: <NurseCharting />,
                      },
                    ]
                  },
                ]
              },
            ],
          },
        ],
      },
      // {
      //   path: "all/nurses",
      //   // path: "nurses/all",
      //   element: <AllNurses />,
      // },
      // {
      //   path: "all/verifiedNurses",
      //   // path: "nurses/verified",
      //   element: <VerifiedNurses />,
      // },
      // {
      //   path: "all/unverifiedNurses",
      //   // path: "nurses/unverified",
      //   element: <UnverifiedNurses />,
      // },
      // {
      //   path: "all/withdrawalRequest",
      //   // path: "withdrawalRequests",
      //   element: <WithdrawalRequest />,
      // },
      // {
      //   path: "agencies",
      //   // path: "agencies/all",
      //   element: <AdminAgenciesPage />,
      // },
      // {
      //   path: "all/agency/allAgencies",
      //   // path: "agencies/all",
      //   element: <ViewAgency type="allAgencies" />,
      // },
      // {
      //   path: "all/agency/verifiedAgencies",
      //   // path: "agencies/verified",
      //   element: <ViewAgency type="verifiedAgencies" />,
      // },
      // {
      //   path: "all/agency/unverifiedAgencies",
      //   // path: "agencies/unverified",
      //   element: <ViewAgency type="unverifiedAgencies" />,
      // },
    ],
  },
  {
    path: "*",
    element: <h1>Page not found</h1>,
  },
];
