import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useDispatch} from "react-redux";
import {useNavigate, useOutletContext} from "react-router-dom";
import Table from "../../../sharedComponents/table/Table";
import {getNurseAction} from "../../../services/NursesProfile";
import {HttpsAction} from "../../../commonApiFunction/httpsAction";
import * as nurseAction from "./nurseActionTypes";
import storageManager from "../../../helpers/storageManager";
import CommonPaginationNew from "../../../sharedComponents/pagination/CommonPaginationNew";

const NurseList = ({internalNursesList/*, currentPage, pageLimit, forceReload*/}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { searchText, setPaginationTab, doReload, setTabId } = useOutletContext();

    const [currentPage, setCurrentPage] = useState(1);

    const transformedArray = internalNursesList?.rows?.map((item) => {
        return {
            id: {id: item.id, chatId: item.nursePersonalInfoId, firstName: item.nurseFirstName, lastName: item.nurseLastName, isOnline: item.isOnline},
            // preferredRate: item.preferredRate ?? 'N/A',
            name: `${item?.nurseFullName}`,
            phone: item?.primaryPhone ?? 'N/A',
            emailAddress: item?.primaryEmail ?? 'N/A',
            profession: item?.nurseClass,
            address: item?.nurseLocation ?? 'N/A',
            // hiredDate: item?.hireDate ?? 'N/A',
            createdDate: item?.nurseCreatedAtFormatUs ?? 'N/A',
        };
    });

    const serviceHeaderData = [
        {
            title: "Name",
            dataIndex: "name",
        },
        {
            title: "Profession",
            dataIndex: "profession",
        },
        {
            title: "Email Address",
            dataIndex: "emailAddress",
        },
        {
            title: "Address",
            dataIndex: "address",
        },
        {
            title: "Created Date",
            dataIndex: "createdDate",
        },
        {
            title: "Phone",
            dataIndex: "phone",
        },
        // {
        //     title: "Preferred Rate",
        //     dataIndex: "preferredRate",
        // },
    ];

    const editNurseDetails = (specificData) => {
        // console.log(specificData, "jitendra id");
        // navigate(`/agency/nurses/edit/${specificData.id}/profile`);
        // navigate(`/agency/nurses/edit/profile/personal-info/main/${specificData.id}`);
        navigate(`/agency/nurses/edit/profile/personal-info/main`, { state: { id: specificData.id } });
    };

    const sendMessage = (specificData) => {
        // let itemdata = transformedArray.filter((item) => item.id == id);
        navigate("/agency/messages", {
            // state: itemdata[0]
            state: {
                id: specificData.chatId,
                firstName: specificData.firstName,
                lastName: specificData.lastName,
                isOnline: specificData.isOnline,
            },
        });
    }

    const actionButton = [
        {
            actionName: "Edit",
            buttonAction: (specificData) => editNurseDetails(specificData),
            actionClass: "btn-primary px-1",
            iconClass: "fa-user-plus",
        },
        {
            actionName: 'Message',
            buttonAction: (specificData) => sendMessage(specificData),
            actionClass: "btn-primary px-1",
            iconClass: "fa-user-plus",
        },
    ];

    function loadData() {
        // dispatch({ type: nurseAction.NURSE_LIST, payload: [] });
        let qsFilter = `?page=${currentPage}&freelanceNurses=false`;
        if (searchText != null && searchText.length > 0) {
            qsFilter += `&searchText=${searchText}`;
        }
        dispatch(HttpsAction({
            apiSection: 'nursesProfile',
            apiName: 'nurseList',
            queryParameter: qsFilter,
            reduxKeyName: 'nurseList',
            reducerName: 'NURSE',
            actionType: nurseAction.NURSE_LIST,
            simpleDispatch: true,
        }));
    }

    useEffect(() => {
        if (internalNursesList != null && internalNursesList?.rows?.length != null) {
            setPaginationTab(internalNursesList);
        }
    }, [internalNursesList]);

    useEffect(() => {
        // dispatch(getNurseAction("nurseList", "", currentPage));
        // if (currentPage > 0)
            loadData();
    }, [currentPage, searchText]);

    useEffect(() => {
        if (doReload) loadData();
    }, [doReload]);

    useEffect(() => {
        if (setTabId) setTabId('internal');
    }, []);

    return (
        <div className="bg-white mt-3">
            <Table headers={serviceHeaderData} rows={transformedArray} actions={actionButton} tableClass="charting-table" />
            <CommonPaginationNew data={internalNursesList} onPageChange={(page) => setCurrentPage(page)} />
        </div>
    );
};

const mapStateToProps = (state) => {
    // const { nurseListing } = state;
    const {nurseListing: {nurseList}} = state;
    // console.log('check- nurse-list ==> ', nurseList);

    // const data = nurseList?.data?.rows ? nurseList?.data?.rows : nurseList?.rows;

    return {
        // nurseList: nurseListing.nurseList.rows || [],
        internalNursesList: nurseList || {},
    };
};

export default connect(mapStateToProps)(NurseList);
