import moment from "moment/moment";
import dayjs from "dayjs";

const dateHelperFunctions = {

  updateDateFormat: (date, format = "YYYY-MM-DD") => {
    if (date) {
      return moment(date).format(format)
    }
  },

  hoursConvert: (time) => {
    if (time) {
      // Check correct time format and split into components
      const match = time.toString().match(/^([01]\d|2[0-3]):([0-5]\d)(:[0-5]\d)?$/) || [time];

      if (match.length > 1) { // If time format is correct
        const [, hours, minutes] = match.slice(1); // Destructure the matched parts

        let period = 'AM';
        let adjustedHours = +hours % 12 || 12; // Adjust hours to 12-hour format

        if (+hours >= 12) {
          period = 'PM'; // Set PM for hours greater than or equal to 12
        }

        time = `${adjustedHours}:${minutes} ${period}`; // Format the time as HH:MM AM/PM
      }
    }
    return time ? time.replace('::', ":") : ''; // Return adjusted time or empty string
  },


  convertTo12HourFormat: (time) => {
    if (time) {
      const [hour, minute] = time.split(":");
      const hourNumber = parseInt(hour, 10);

      if (hourNumber === 0) {
        return `12:${minute} AM`;
      } else if (hourNumber < 12) {
        return `${hourNumber}:${minute} AM`;
      } else if (hourNumber === 12) {
        return `12:${minute} PM`;
      } else {
        return `${hourNumber - 12}:${minute} PM`;
      }
    }
    return ""
  },

  convertDateToDayName: (date) => {
    if (date) {
      const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      return days[new Date(date).getDay()];
    }
    return ""
  },

  convertTimeStringToDate: (timeString) => {
    if (timeString != null) {
      // return new Date(Date.parse(`1970-01-01T${timeString}:00`));
      if (timeString.match(/:/g).length > 1)
        return dayjs(timeString, 'HH:mm:ss');
      return dayjs(timeString, 'HH:mm');
    }
    return null;
  },

  timeDiffInHoursAndMins: (ft, tt) => {
    if (ft != null && tt != null) {
      const mins = tt.diff(ft, "minutes", true);
      const totalHours = parseInt(mins / 60);
      const totalMins = dayjs().minute(mins).$m;
      return {hrs: totalHours, mins: totalMins};
    }
    return null;
  },

  hoursAndMinutesToText(hours, mins) {
    if (hours === 0 && mins === 0) return '0 mins';
    let timeFrameText = '';
    if (hours > 0) {
      timeFrameText += `${hours} hr`;
      if (hours > 1) timeFrameText += 's';
    }
    if (mins > 0) {
      timeFrameText += hours > 0 ? `, ${mins} min` : `${mins} min`;
      if (mins > 1) timeFrameText += 's';
    }
    return timeFrameText;
  },

  getFormattedDateForDb(dateVal) {
    return dateVal.toLocaleDateString('en-GB', {month: 'numeric', day: 'numeric', year: 'numeric'}).split('/').reverse().join('-');
  },

};

export default dateHelperFunctions;
