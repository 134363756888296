import React, {useEffect, useRef, useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import TableTitle from "../../Dashboard/TableTitle";
import {Card} from "react-bootstrap";
import Table from "../../../../sharedComponents/table/Table";
import {useDispatch, useSelector} from "react-redux";
import {HttpsAction} from "../../../../commonApiFunction/httpsAction";
import PrimaryButton from "../../../../sharedComponents/buttons/PrimaryButton";
import CustomDialog from "../../../../sharedComponents/CustomDialog/index";
import {useReactToPrint} from "react-to-print";
import {getNursesData} from "../../../redux/actions/AdminActions";
import CustomInputField from "../../../../sharedComponents/fields/customInputField";
import stringHelperFunctions from "../../../../helpers/string.helpers";
import BackLink from "../../../../sharedComponents/buttons/BackLink";
import Pagination from "../../../../sharedComponents/pagination";
import {ADMIN_NURSES_NAV_TITLES} from "../../../../appConstants";


const WithdrawalRequest = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const printFormRef = useRef(null);

    const withdrawalRequest = useSelector(state => state.admin?.withdrawalRequest);
    const allWithdrawalRequest = useSelector(state => state.admin?.allWithdrawalRequest);

    const [exportModal, setExportModal] = useState(false);
    const [showBankDetails, setShowBankDetails] = useState(false);
    const [bankDetails, setBankDetails] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [dataFilters, setDataFilters] = useState({from: null, to: null, searchText: ''});

    const transformedArray = (withdrawalRequest?.rows || [])?.map((item, index) => {
        const statusOk = item.transactionStatusId === 2 || item.transactionStatusId === 5;
        const statusNotOk = item.transactionStatusId === 3 || item.transactionStatusId === 4 || item.transactionStatusId === 6;
        return {
            id: {id: item.id, transactionStatusId: item.transactionStatusId},
            // sn: item?.nurseId,
            sn: item?.transactionId,
            name: item?.nurseFullName,
            email: item?.email,
            phone: item?.phone,
            // dateCreated: item?.nurseCreationDateFormatUs,
            requestDate: item?.transactionDateFormatUs,
            amount: item?.currencySymbol + item?.amount,
            // hours:
            // preferredRates: item?.preferredPerDayRate || "Not Available",
            // verification: item?.isIdentityVerified ? "Verified" : "Unverified",
            // verification: item?.isVerified ? "Verified" : "Unverified",
            // status: item?.isOnline ? "Available" : "Unavailable",
            // status: item?.isAvailable ? "Available" : "Unavailable",
            status: <span className={`${statusNotOk ? 'text-danger' : (statusOk ? 'text-success' : 'text-warning')}`}>{item?.transactionStatusName}</span>,
        }
    });

    const exportTransformedArray = (allWithdrawalRequest || [])?.map((item, index) => {
        const statusOk = item.transactionStatusId === 2 || item.transactionStatusId === 5;
        const statusNotOk = item.transactionStatusId === 3 || item.transactionStatusId === 4 || item.transactionStatusId === 6;
        return {
            id: item.id,
            sn: item?.transactionId,
            name: item?.nurseFullName,
            email: item?.email,
            phone: item?.phone,
            // dateCreated: new Date(item?.nurseCreatedAt).toLocaleDateString(),
            requestDate: item?.transactionDateFormatUs,
            amount: item?.currencySymbol + item?.amount,
            // hours:
            // preferredRates: item?.preferredPerDayRate || "Not Available",
            // verification: item?.isIdentityVerified ? "Verified" : "Unverified",
            // status: item?.isOnline ? "Available" : "Unavailable"
            status: <span className={`${statusNotOk ? 'text-danger' : (statusOk ? 'text-success' : 'text-warning')}`}>{item?.transactionStatusName}</span>,
        }
    });

    const serviceHeaderData = [
        {
            title: "S/N",
            dataIndex: "sn",
        },
        {
            title: "Name",
            dataIndex: "name",
        },
        {
            title: "Email address",
            dataIndex: "email",
        },
        {
            title: "Phone",
            dataIndex: "phone",
        },
        // {
        //     title: "Date created",
        //     dataIndex: "dateCreated",
        // },
        {
            title: "Request Date",
            dataIndex: "requestDate",
        },
        {
            title: "Amount",
            dataIndex: "amount",
        },
        // {
        //     title: "Preferred rates",
        //     dataIndex: "preferredRates",
        // },
        // {
        //     title: "Verification",
        //     dataIndex: "verification",
        // },
        {
            title: "Status",
            dataIndex: "status",
        },
    ];

    const actionButton = [
        {
            actionName: "Bank Details",
            buttonAction: (specificData, index) => {
                const data = withdrawalRequest?.rows[index];
                dispatch(HttpsAction({
                    apiSection: 'admin',
                    apiName: 'moneyBankDetails',
                    method: 'GET',
                    queryParameter: `/${data.nurseId}/${data.requestId}`,
                    positiveCallBack: ({data: res}) => {
                        setBankDetails(res.data);
                        setShowBankDetails(true);
                    }
                }));
            },
            actionClass: "btn-danger",
            iconClass: "fa-user-plus",
        },
        {
            actionName: "Accept",
            buttonAction: (specificData, index) => {
                const data = withdrawalRequest?.rows[index];
                dispatch(HttpsAction({
                    apiSection: 'admin',
                    apiName: 'withdrawalRequest',
                    method: 'PUT',
                    queryParameter: `/${data.nurseId}/${data.requestId}/true`,
                    positiveCallBack: () => {
                        // getData(currentPage);
                        dispatch(getNursesData('withdrawal-request-list', currentPage));
                    }
                }))
            },
            actionClass: "btn-primary",
            iconClass: "fa-user-plus",
            hideActionButtonFunc: (specificData) => specificData.transactionStatusId > 1,
        },
        {
            actionName: "Decline",
            buttonAction: (specificData, index) => {
                const data = withdrawalRequest?.rows[index];
                dispatch(HttpsAction({
                    apiSection: 'admin',
                    apiName: 'withdrawalRequest',
                    method: 'PUT',
                    queryParameter: `/${data.nurseId}/${data.requestId}/false`,
                    positiveCallBack: () => {
                        // getData(currentPage);
                        dispatch(getNursesData('withdrawal-request-list', currentPage));
                    }
                }))
            },
            actionClass: "btn-danger",
            iconClass: "fa-user-plus",
            hideActionButtonFunc: (specificData) => specificData.transactionStatusId > 1,
        },
    ];

    // const handleSearch = (e) => {
    //     setSearchTerm(e.target.value);
    // };
    //
    // const getData = (page) => {
    //     dispatch(HttpsAction({
    //         apiSection: 'admin',
    //         apiName: 'withdrawalRequest',
    //         queryParameter: `?page=${page}`,
    //         reducerName: 'ADMIN',
    //         reduxKeyName: 'withdrawalRequest',
    //         actionType: 'GET_WITHDRAWAL_REQUEST_LIST'
    //     }))
    //
    //     return () => {
    //         dispatch({
    //             type: 'ADD_ADMIN_REMOVE_WITHDRAWAL_REQUEST_LIST',
    //             payload: [],
    //             name: 'withdrawalRequest',
    //             meta: {type: 'REMOVE_WITHDRAWAL_REQUEST_LIST'},
    //         });
    //     }
    // };

    const handleExport = async () => {
        // await dispatch(HttpsAction({
        //     apiSection: 'admin',
        //     apiName: 'withdrawalRequest',
        //     queryParameter: `?doPaging=false`,
        //     reducerName: 'ADMIN',
        //     actionType: 'GET_ADMIN_ALL_WITHDRAWAL_REQUEST_LIST',
        //     reduxKeyName: 'allWithdrawalRequest'
        // }));
        dispatch(getNursesData('withdrawal-request-list-export', 1, `doPaging=false`));
        setExportModal(true)
    };

    const handlePrint = useReactToPrint({
        content: () => printFormRef.current,
        onAfterPrint: () => {
            // Close the modal after printing or cancelling the PDF
            handleCloseFormModal()
        }
    });

    const handleCloseFormModal = () => {
        setExportModal(false)
    };

    // const handleDateFilter = ({from, to}) => {
    //     // dispatch(getNurseList('withdrawal-request-list-date-filter', from, to));
    //     dispatch(getNursesData('withdrawal-request-list', 1, `fromDate=${from}&toDate=${to}`));
    // };

    const handleFilters = (filters) => {
        setDataFilters(filters);
    };

    function handlePageChange(page) {
        setCurrentPage(page);
    }

    useEffect(() => {
        const {from: fromDate, to: toDate, searchText} = dataFilters;
        if (searchText || (fromDate !== null && toDate !== null) || currentPage) {
            let qsFilter = '';
            if (searchText.length > 0) {
                qsFilter += `searchText=${searchText}`;
            }
            if (fromDate != null && toDate != null) {
                if (qsFilter.length > 0) qsFilter += '&';
                qsFilter += `fromDate=${fromDate}&toDate=${toDate}`;
            }
            dispatch(getNursesData("withdrawal-request-list", currentPage, qsFilter.length > 0 ? qsFilter : undefined));
        }
    }, [currentPage, dataFilters]);

    return (
        <>
            <div className="app-main__inner">
                <div className="main-dashboard-page">
                    <div className="col-12 page-back-btn">
                        <BackLink link="/admin/dashboard" linkText="Nurses Overview" />
                    </div>
                    <div className="row mb-15">

                        <TableTitle
                            title={ADMIN_NURSES_NAV_TITLES.WITHDRAWAL_REQUEST}
                            // onSearch={handleSearch}
                            handleExport={handleExport}
                            // handleDateFilter={handleDateFilter}
                            handleFilters={handleFilters}
                        />
                        <Card>
                            <Card.Body>
                                <div>
                                    <Table headers={serviceHeaderData} rows={transformedArray} actions={actionButton} tableClass="charting-table" />
                                    <Pagination
                                      currentPage={withdrawalRequest?.page}
                                      totalRecords={withdrawalRequest?.total}
                                      limit={withdrawalRequest?.pageSize}
                                      onPageChange={handlePageChange}
                                    />
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>

            <CustomDialog
                open={exportModal}
                onClose={() => handleCloseFormModal()}
                title={'All Withdrawal Requests'}
                maxWidth={'lg'}
                actions={
                    <div className="d-flex justify-content-center" style={{marginBottom: "20px"}}>
                        <PrimaryButton
                            type="submit"
                            className="btn-view-repo gap-2 px-5 py-2"
                            text={"Export"}
                            onClick={() => handlePrint()}
                        />
                    </div>
                }>
                <div ref={printFormRef} className={'p-4'}>
                    <Table headers={serviceHeaderData} rows={exportTransformedArray}/>
                </div>
            </CustomDialog>

            <CustomDialog
                open={showBankDetails}
                onClose={() => setShowBankDetails(false)}
                title={'Bank detail of Withdrawal Request'}
                maxWidth={'lg'}
                >
                {bankDetails && <div className={'p-4'}>
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <CustomInputField
                              className="form-control mk-wi"
                              name="transactionId"
                              label="Transaction ID"
                              value={bankDetails.transactionId}
                              // placeholder="N/A"
                              readOnly={true}
                            />
                        </div>
                        <div className="col-12 col-md-4">
                            <CustomInputField
                              className="form-control mk-wi"
                              name="amount"
                              label="Amount"
                              value={bankDetails.currencySymbol + bankDetails.amount}
                              // placeholder="N/A"
                              readOnly={true}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <CustomInputField
                              className="form-control mk-wi"
                              name="bankName"
                              label="Bank Name"
                              value={stringHelperFunctions.sanitize(bankDetails?.bankName, false, ['NULL']) || 'N/A'}
                              // placeholder="N/A"
                              readOnly={true}
                            />
                        </div>
                        <div className="col-12 col-md-4">
                            <CustomInputField
                              className="form-control mk-wi"
                              name="bankAddress"
                              label="Bank Address"
                              value={bankDetails?.bankAddress || 'N/A'}
                              // placeholder="N/A"
                              readOnly={true}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <CustomInputField
                              className="form-control mk-wi"
                              name="beneficiaryName"
                              label="Account Holder Name"
                              value={bankDetails.beneficiaryName || 'N/A'}
                              // placeholder="N/A"
                              readOnly={true}
                            />
                        </div>
                        <div className="col-12 col-md-4">
                            <CustomInputField
                              className="form-control mk-wi"
                              name="bankAccountNumber"
                              label="Account Number"
                              value={stringHelperFunctions.sanitize(bankDetails?.bankAccountNumber, false, ['NULL']) || 'N/A'}
                              // placeholder="N/A"
                              readOnly={true}
                            />
                        </div>
                        <div className="col-12 col-md-4">
                            <CustomInputField
                              className="form-control mk-wi"
                              name="abaNumber"
                              label="ABA Number"
                              value={bankDetails?.abaNumber || 'N/A'}
                              // placeholder="N/A"
                              readOnly={true}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <CustomInputField
                              className="form-control mk-wi"
                              name="balance"
                              label="Balance"
                              value={bankDetails.currencySymbol + bankDetails?.balance}
                              // placeholder="N/A"
                              readOnly={true}
                            />
                        </div>
                    </div>
                </div>}
            </CustomDialog>
        </>
    )
};

export default WithdrawalRequest;
