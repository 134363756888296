import React, { useEffect } from "react";
import FindNurseCard from "./FindNurseCard";
import { useDispatch, useSelector } from "react-redux";
const Nurses = () => {
  const dispatch = useDispatch();

  const nurseList = useSelector(
    (state) => {
      return state.nurseListing?.findNurse?.data?.rows ?? [];
    }
  );

  useEffect(() => {}, [dispatch]);

  return (
    <div>
      {nurseList?.map((nurse, index) => {
        return (
          <div className="nurse-details-box-left ">
            <FindNurseCard nurse={nurse} />
          </div>
        );
      })}
    </div>
  );
};

export default Nurses;
