import React from 'react';
import CustomLabel from "../fields/CustomLabel";
import CustomRadioField from "../fields/customRadioField";

const RadioOptionsComponent = ({ input: { value: inputValue, ...restOfInput}, meta: { touched, error, initial }, showErrorsFixed = true, labelClassName = 'form-label mb-1', className, fieldClassName, eachFieldContainerClassName = 'col-4 col-md-4 form-check', eachFieldClassName = 'form-check-input mk-wi', requiredMark, fieldName = 'genderId', label = 'Please Select your gender', optionsList = [], selectedGenderIdStr, handleGenderChanged }) => {
// console.log('test - field', optionsList, fieldName, label, input);
console.log('test - values', inputValue, error, initial);
// console.log('test- optionsList', optionsList)
//   const defContainerClass = `w-100 ${showErrorsFixed ? 'position-relative ' : ''}p-0 m-0`;
//   const defContainerClass = `mb-3 w-100 ${showErrorsFixed ? 'position-relative' : ''}`;
  const defContainerClass = `w-100 h-auto p-0 m-0 mb-3 d-flex flex-column ${showErrorsFixed ? 'position-relative' : ''}`;
  let fieldContainerClass = className != null ? className + ' ' + defContainerClass : defContainerClass;
  let fieldBodyClass = (fieldClassName || 'd-flex') + ' r-border';
  if (optionsList.length === 0) return null;
  return (
    <div className={fieldContainerClass}>
      <CustomLabel requiredMark={requiredMark} className={labelClassName} labelFor={`${fieldName}_${optionsList[0].name}_1`} labelText={label} />
      <div className={fieldBodyClass}>
        {optionsList.map((opt, index) => {
          const optionIdStr = typeof opt.id === 'string' ? opt.id : String(opt.id);
          return (
            <div key={index} className={eachFieldContainerClassName}>
              <CustomRadioField
                label={opt.name}
                className={eachFieldClassName}
                spacingClasses="m-0 p-0"
                name={fieldName}
                // id={`${fieldName}_${opt.name}_${index+1}`}
                id={`${fieldName}_${opt.name}_${optionIdStr}`}
                value={optionIdStr}
                // selectedValue={inputValue || initial}
                checked={optionIdStr === inputValue}
                onChange={(e) => handleGenderChanged(e)}
                {...restOfInput}
              />
            </div>
          );
        })}
      </div>
      {touched && error && (
        <span className={`field-error${(showErrorsFixed) ? ' position-absolute bottom-field-error start-0' : ''}`}>{error}</span>)}
    </div>
  );
};

export default RadioOptionsComponent;
