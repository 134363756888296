import React from "react";
import {NavLink} from "react-router-dom";

const apiCallHelperFunctions = {

  getChartingTypeId(chartingType) {
    let chartingTypeId = 0;
    if (chartingType === 'initialAssessment') {
      chartingTypeId = 1;
    }
    if (chartingType === 'supervisoryVisits') {
      chartingTypeId = 2;
    }
    if (chartingType === 'dischargeSummaries') {
      chartingTypeId = 3;
    }
    if (chartingType === 'carePlans') {
      chartingTypeId = 4;
    }
    return chartingTypeId;
  },

  getVisitsTabUrl(item) {
    // let url = null;
    // if (!item.isMissed && item.taskStatusId === 2) url = `/agency/servicerequest/scheduledrequest`;
    // else if (item.taskStatusId === 3 && item.activityCompletionTime == null) url = `/agency/servicerequest/activerequest`;
    // else if (item.taskStatusId >= 3 && item.activityCompletionTime != null) url = `/agency/servicerequest/completeRequest`; // status-id is 4 or 5
    // else if (item.isMissed) url = `/agency/servicerequest/missedRequests`; // missed (no clock-in)
    // else if (!item.isMissed && item.activityCompletionTime == null) url = `/agency/servicerequest/incompleteVisits`; // in-complete

    let url = null;
    if (!item.isMissed && item.taskStatusId === 2) url = `/agency/servicerequest/scheduledrequest`;
    else if (item.taskStatusId === 3 && item.remainingActivitiesTotal > 0) url = `/agency/servicerequest/activerequest`;
    else if (item.taskStatusId >= 3 && item.remainingActivitiesTotal === 0) url = `/agency/servicerequest/completeRequest`; // status-id is 4 or 5
    else if (item.isMissed) url = `/agency/servicerequest/missedRequests`; // missed (no clock-in)
    else if (!item.isMissed && item.remainingActivitiesTotal > 0) url = `/agency/servicerequest/incompleteVisits`; // in-complete
    return url;
  },

  getChartingTitle(templateTypeId, setTitleFieldLabel) {
    if (templateTypeId === 1) return 'Assessment Title';
    if (templateTypeId === 2) return 'Visit Title';
    if (templateTypeId === 3) return 'Summary Title';
    if (templateTypeId === 4) return 'Plan Title';
    return '';
  },

  getChartingTabUrl(templateTypeId) {
    if (templateTypeId === 1) return '/agency/charting/initialassessment';
    if (templateTypeId === 2) return '/agency/charting/supervisorvisit';
    if (templateTypeId === 3) return '/agency/charting/dischargesummary';
    if (templateTypeId === 4) return '/agency/charting/careplan';
    return '';
  },

  getChartingTypeKey(templateTypeId) {
// console.log('test- templateTypeId', templateTypeId)
    templateTypeId = templateTypeId || 0;
    templateTypeId = typeof templateTypeId === 'string' ? +templateTypeId : templateTypeId;
    if (templateTypeId === 1) return 'initialAssessment';
    if (templateTypeId === 2) return 'supervisoryVisit';
    if (templateTypeId === 3) return 'dischargeSummary';
    if (templateTypeId === 4) return 'carePlan';
    return '';
  },

  getPostedJobsHeading(routeLastParam) {
    let linkTitleSuffix = 'Pending';
    if (routeLastParam === 'postedjobsactive') {
      linkTitleSuffix = 'Active';
    }
    if (routeLastParam === 'postedjobsaccepted') {
      linkTitleSuffix = 'Accepted';
    }
    if (routeLastParam === 'postedjobsexpired') {
      linkTitleSuffix = 'Expired';
    }
    if (routeLastParam === 'postedjobsinprogress') {
      linkTitleSuffix = 'In Progress';
    }
    if (routeLastParam === 'postedjobscompleted') {
      linkTitleSuffix = 'Completed';
    }
    return linkTitleSuffix;
  },

  getPostedJobsHeadingNew(routeLastParam) {
    let linkTitleSuffix = 'Pending';
    if (routeLastParam === 'active') {
      linkTitleSuffix = 'Active';
    }
    if (routeLastParam === 'accepted') {
      linkTitleSuffix = 'Accepted';
    }
    if (routeLastParam === 'expired') {
      linkTitleSuffix = 'Expired';
    }
    if (routeLastParam === 'inprogress') {
      linkTitleSuffix = 'In Progress';
    }
    if (routeLastParam === 'completed') {
      linkTitleSuffix = 'Completed';
    }
    return linkTitleSuffix;
  },

  getChartingHeading(routeLastParam) {
    let linkTitleSuffix = 'Initial Assessments';
    if (routeLastParam === 'supervisoryVisits') {
      linkTitleSuffix = 'Supervisory Visits';
    }
    if (routeLastParam === 'dischargeSummaries') {
      linkTitleSuffix = 'Discharge Summaries';
    }
    if (routeLastParam === 'carePlans') {
      linkTitleSuffix = 'Care Plans';
    }
    return linkTitleSuffix;
  },

  getChartingHeadingNew(routeLastParam) {
    let linkTitleSuffix = 'Initial Assessments';
    if (routeLastParam === 'supervisory-visits') {
      linkTitleSuffix = 'Supervisory Visits';
    }
    if (routeLastParam === 'discharge-summaries') {
      linkTitleSuffix = 'Discharge Summaries';
    }
    if (routeLastParam === 'care-plans') {
      linkTitleSuffix = 'Care Plans';
    }
    if (routeLastParam === 'templates') {
      linkTitleSuffix = 'Forms (Templates)';
    }
    return linkTitleSuffix;
  },

  getTabButtons(type, tabUrls, navigate, currentPathname) {
    if (type === 'charting') {
      return tabUrls.reduce((acc, cur) => {
        const tabTitle = apiCallHelperFunctions.getChartingHeading(cur.split('/').pop());
        const btnClass = `btn btn-sm px-2 text-nowrap${cur === currentPathname ? ' btn-primary' : ''}`;
        const element = <button type="button" className={btnClass} onClick={() => navigate(cur)}>{tabTitle}</button>;
        return acc == null ? [element] : [...acc, element];
      }, null);
    }
    if (type === 'postedJobs') {
      return tabUrls.reduce((acc, cur) => {
        const tabTitle = apiCallHelperFunctions.getPostedJobsHeading(cur.split('/').pop());
        const btnClass = `btn btn-sm px-2 text-nowrap${cur === currentPathname ? ' btn-primary' : ''}`;
        const element = <button type="button" className={btnClass} onClick={() => navigate(cur)}>{tabTitle}</button>;
        return acc == null ? [element] : [...acc, element];
      }, null);
    }
    return null;
  },

  getTabButtonsNew(type, tabUrls, navigate, currentPathname) {
    if (type === 'charting') {
      return tabUrls.reduce((acc, cur) => {
        const tabTitle = apiCallHelperFunctions.getChartingHeading(cur.split('/').pop());
        // const btnClass = `btn btn-sm px-2 text-nowrap${cur === currentPathname ? ' btn-primary' : ''}`;
        // const element = <button type="button" className={btnClass} onClick={() => navigate(cur)}>{tabTitle}</button>;
        const btnClass = `btn btn-sm px-2 text-nowrap h-auto service-requests-tab-link neo1`;
        const element = <NavLink className={btnClass} to={cur}>{tabTitle}</NavLink>;
        return acc == null ? [element] : [...acc, element];
      }, null);
    }
    if (type === 'postedJobs') {
      return tabUrls.reduce((acc, cur) => {
        const tabTitle = apiCallHelperFunctions.getPostedJobsHeadingNew(cur.split('/').pop());
        // const btnClass = `btn btn-sm px-2 text-nowrap${cur === currentPathname ? ' btn-primary' : ''}`;
        // const element = <button type="button" className={btnClass} onClick={() => navigate(cur)}>{tabTitle}</button>;
        const btnClass = `btn btn-sm px-2 text-nowrap h-auto service-requests-tab-link neo1`;
        const element = <NavLink className={btnClass} to={cur}>{tabTitle}</NavLink>;
        return acc == null ? [element] : [...acc, element];
      }, null);
    }
    return null;
  },

};

export default apiCallHelperFunctions;
