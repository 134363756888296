import React, { useState, useEffect } from "react";
import {connect, useSelector} from "react-redux";
import {change, Field, reduxForm} from "redux-form";
import { useDispatch } from "react-redux";
import CustomDropDownNewField from "../../../../sharedComponents/reduxfields/customDropDownNew";
import CustomInputField from "../../../../sharedComponents/reduxfields/customInputField";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import PersonalInfoInnerTabs from "./personalInfoInnerTabs";
import {
  getNurseAction,
  postNurseAction,
} from "../../../../services/NursesProfile";
import MultiSelectDropdown from "../../../../sharedComponents/multiSelect/index";
import {required, requiredArray} from "../../../../helpers/validation";
import {getAllPersonsList, getRegionsList, getAdminsList, getProfessionList} from "../../../../services/commonServices";
import PrimaryButton from "../../../../sharedComponents/buttons/PrimaryButton";
import Modal from "../../../../sharedComponents/Modal";
import {useQueryClient} from "@tanstack/react-query";
import booleanHelperFunctions from "../../../../helpers/boolean.helpers";
import arrayHelperFunctions from "../../../../helpers/array.helpers";
import numberHelperFunctions from "../../../../helpers/number.helpers";
import stringHelperFunctions from "../../../../helpers/string.helpers";
import CustomRadioGroup from "../../../../sharedComponents/reduxfields/customRadioGroup";
import MultiSelectDropdownNew from "../../../../sharedComponents/reduxfields/MultiSelectDropdown";
import SaveButton from "../../../../sharedComponents/buttons/SaveButton";
import NurseActiveInactivateModal from "./SetEffectiveEndDateModal";
import useCustomNav from "../../../../utils/hooks/useCustomNav";
import {isAdminUser} from "../../../../helpers/apiMethods";

export const statusOptions = [
  {label: 'ACTIVE', id: "active", value: '1'},
  {label: 'INACTIVE', id: "inActive", value: '0'}
];

const mobileAppGeofenceEnabledOptions = [
  {label: 'Yes', id: "yes1", value: 'true'},
  {label: 'No', id: "no1", value: 'false'}
];


const AccountDetails = ({ dataLoaded, statusColor, borderColor, buttonText, initialValues, handleSubmit, pristine, submitting, submitFailed, agencyId }) => {
  const statusWidth = '130px';
  const statusButtonWidth = '190px';

  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { id: nurseId } = useParams();
  const { nurseId } = useOutletContext();

  // const isAdmin = window.location.pathname.includes('/admin/agencyNurses/edit');
  const isAdmin = isAdminUser();

  const { goTo: gotoMailingInfoPage } = useCustomNav(isAdmin ? `/admin/agency/nurses/edit/profile/personal-info/mailing-info` : '/agency/nurses/edit/profile/personal-info/mailing-info');

  // const nurseAccountDetails = useSelector(state => state.nurseListing?.nurseAccountDetails);

  const professionsArray = queryClient.getQueryData(['list-professions']);
  const adminsList = queryClient.getQueryData(['list-admins', agencyId]);
  const regionsList = queryClient.getQueryData(['list-regions', agencyId]);
  const allPersonsList = queryClient.getQueryData(['list-persons', agencyId]);

  // const [nurseClassList, setNurseClassList] = useState([]);
  const [nurseSkillList, setNurseSkillList] = useState([]);
  // const [selectedSkills, setSelectedSkills] = useState([]);
  // const [nurseClassVal, setNurseClassVal] = useState(false);
  const [selectedProfession, setSelectedProfession] = useState('0');

  // const [isLoading, setLoading] = useState(false);
  const [loadedOnce, setLoadedOnce] = useState(false);
  // const [loadOnceDone, setLoadOnceDone] = useState(false);
  const [effectiveEndDateModal, setEffectiveEndDateModal] = useState(false);
  const [effectiveEndDateModalData, setEffectiveEndDateModalData] = useState({});

  // const setSelectedOptions = (selectedOptions) => {
  //   setSelectedSkills(selectedOptions);
  //   // if (selectedOptions.length > 0) {
  //   //   setNurseClassVal(false);
  //   // } else {
  //   //   setNurseClassVal(true);
  //   // }
  // };

  const onSubmit = async (values) => {
    // console.log('test- value', values);
    // if (selectedSkills.length > 0) {
    // const selectedNurseClass = selectedSkills.map((res) => res.value);
    // const selectedNurseSkills = selectedSkills?.map((res) => res.value);

    let payload = Object.assign({}, values);
    delete payload.status;
    delete payload.nurseClassId;
    delete payload.nurseSkills;
    delete payload.nurseSkillsIds;
    delete payload.mobileAppVersion;
    payload.active = booleanHelperFunctions.nullIfEmpty(values.active);
    // payload.nurseClassIds = selectedNurseClass;
    // payload.nurseSkillIds = arrayHelperFunctions.nullIfEmpty(selectedNurseSkills);
    payload.nurseSkillIds = arrayHelperFunctions.nullIfEmpty(values.nurseSkillsIds)?.map((sk) => +sk.value);
    payload.classId = numberHelperFunctions.nullIfEmpty(values.nurseClassId, true);
    payload.applicationDate = stringHelperFunctions.nullIfEmpty(values.applicationDate);
    payload.interviewDate = stringHelperFunctions.nullIfEmpty(values.interviewDate);
    payload.hireDate = stringHelperFunctions.nullIfEmpty(values.hireDate);
    payload.startDate = stringHelperFunctions.nullIfEmpty(values.startDate);
    payload.terminationDate = values.terminationDate.length === 0 ? null : stringHelperFunctions.nullIfEmpty(values.terminationDate);
    payload.nurseAdministratorId = 1; // numberHelperFunctions.nullIfEmpty(values.nurseAdministratorId, true);
    payload.regionId = numberHelperFunctions.nullIfEmpty(values.regionId, true);
    payload.referredByPersonalInfoId = parseInt(values.referredByPersonalInfoId);
    payload.mobileAppGeofenceEnabled = booleanHelperFunctions.nullIfEmpty(values.mobileAppGeofenceEnabled); // TODO: suggestion - make it a checkbox
    payload.mobileAppGeofenceOverrideRadius = numberHelperFunctions.nullIfEmpty(values.mobileAppGeofenceOverrideRadius, true); // TODO: suggestion - make it a checkbox
    payload.mobileAppVersion = stringHelperFunctions.nullIfEmpty(values.mobileAppVersion);

// console.log('payload', payload);
    try {
      const response = await dispatch(
        postNurseAction(
          payload,
          // "accountDetails",
          "accountInfo",
          nurseId,
          "Account Details Updated Successfully"
        )
      );
      if (response?.success) {
        // navigate(isAdmin ? `/admin/agencyNurses/edit/${nurseId}/mailinfo` : `/agency/nurses/edit/${nurseId}/mailinfo`)
        gotoMailingInfoPage();
      }
    } catch (error) {
      console.error(error);
    }
    // } else {
    //   setNurseClassVal(true);
    // }
  };

  const defaultMultiSelectValue = (res) => {
    return res?.map((item) => {
      return {
        value: item?.id,
        label: item?.name,
      };
    });
  };

  const classListChange = (e) => {
    const selectedClasIdStr = e.target.value;
    setSelectedProfession(selectedClasIdStr);
    if (+selectedClasIdStr > 0) {
      loadSkills(selectedClasIdStr);
    }
  };

  const handleEffectiveEndDateModal = () => setEffectiveEndDateModal(pre => !pre)

  const handleChange = ({name, value}) => {
    setEffectiveEndDateModalData(pre => ({...pre, [name]: value}))
  }

  async function loadSkills(professionId) {
    const response = await dispatch(getNurseAction("nurseSkills", professionId));
    if (response.success) setNurseSkillList(arrayHelperFunctions.getMapped(response.data, ['id', 'name'], ['value', 'label']));
  }

  // useEffect(() => {
  //   if (+selectedProfession > 0) {
  //     loadSkills(selectedProfession);
  //   }
  // }, [selectedProfession]);

  // useEffect(() => {
  //   dispatch(getNurseAction("accountInfo", nurseId));
  //   setSelectedSkills(defaultMultiSelectValue(initialValues.nurseSkillIds));
  // }, [loadOnceDone]);
  //
  // useEffect(() => {
  //   if (!loadOnceDone && nurseId != null && initialValues != null && dataLoaded) {
  //     setLoadOnceDone(true);
  //   }
  // }, [dataLoaded]);
  //
  // useEffect(() => {
  //   if (!isLoading && loadedOnce) {
  //     setLoadedOnce(false);
  //   }
  // }, [loadedOnce]);
  //
  // useEffect(() => {
  //   if (isLoading) {
  //     setLoading(false);
  //     setLoadedOnce(true);
  //   }
  // }, [isLoading]);

  // useEffect(() => {
  //   console.log('test- initialValues', initialValues);    // if (initialValues != null && JSON.stringify(initialValues) !== '{}') {}
  //   if ((initialValues?.nurseSkills || []).length > 0) {
  //     setSelectedSkills(initialValues.nurseSkills.map(v => ({value: v.id, label: v.skill})));
  //   }
  // }, [initialValues]);

  // useEffect(() => {
  //   setLoadedOnce(selectedSkills.length > 0);
  // }, [selectedSkills]);

  useEffect(() => {
    // setLoading(true);
    dispatch(getNurseAction("accountInfo", nurseId, 1, function (res) {
      loadSkills(res.data.nurseClassId).then(() => {
        // setSelectedSkills(res.data.skills.map(v => ({value: v.id, label: v.skill})));
        // setLoadedOnce(true);
        dispatch(change("AccountDetailsForm", 'nurseSkillsIds', arrayHelperFunctions.getMapped(res.data.skills, ['id', 'skill'], ['value', 'label'])));
      });
    }));
  }, []);

  // useEffect(() => {
  //   if (submitFailed) {
  //     setNurseClassVal(true);
  //   }
  // }, [submitFailed]);

// console.log('test- initialValues', initialValues);
// console.log('test- initialValues.nurseSkillsIds', initialValues.nurseSkillsIds);

  return (
    <div>
      <div className="card-white px-4">
        {/*<PersonalInfoInnerTabs></PersonalInfoInnerTabs>*/}
        <form className="pt-3" onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-12 d-flex justify-content-between">
              <div className="mb-4">
                <Field
                  className={`form-control mk-wi fw-bolder border border-1 border-${borderColor} text-${statusColor}`}
                  label="Status"
                  name="status"
                  type="text"
                  component={CustomInputField}
                  style={{minWidth: statusWidth, maxWidth: statusWidth, textAlign: 'center'}}
                  readOnly={true}
                />
              </div>
              <div className="mb-4 input-field-main" style={{width: '150px'}}>
                <Field
                  readOnly={true}
                  className="form-control mk-wi"
                  name="terminationDateText"
                  label="Effective End Date"
                  component={CustomInputField}
                />
              </div>
              <div className="mt-4 input-field-main">
                <PrimaryButton
                  type="button"
                  className="btn-view-repo gap-2 px-3 py-2 w-100"
                  // text={initialValues?.active?.toString() === "1" ? "Set Effective End Date" : "Activate"}
                  text={buttonText}
                  style={{minWidth: statusButtonWidth, maxWidth: statusButtonWidth}}
                  onClick={handleEffectiveEndDateModal}
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="mb-4">
                <Field
                  className="form-control"
                  label="Profession"
                  name="nurseClassId"
                  component={CustomDropDownNewField}
                  requiredMark={true}
                  validate={required}
                  onChange={(e) => classListChange(e)}
                >
                <option value="">Please select</option>
                  {(professionsArray || [])?.map((res, index) => {
                    return (
                      <option key={index} value={res.id}>
                        {res.name}
                      </option>
                    );
                  })}
                </Field>

                {/* <MultiSelectDropdown
                  dpOptions={nurseClassList}
                  getSelectedOptions={setSelectedOptions}
                  selectedOptions={selectedSkills}
                  required={true}
                  label="Class"
                /> */}
                {/* {nurseClassVal && (
                  <span className="field-error">This field is required</span>
                )} */}
              </div>
            </div>
            <div className="col-12 col-md-12 position-relative">
              {/*<MultiSelectDropdown
                name="nurseSkillIds"
                dpOptions={nurseSkillList}
                getSelectedOptions={setSelectedOptions}
                selectedLanguages={selectedSkills}
                requiredMark={true}
                validate={required}
                label="Specialities"
              />
               {nurseClassVal && (
                <span className="field-error">This field is required</span>
              )}*/}
              <div className="mb-4">
                <Field
                  label="Specialities"
                  name="nurseSkillsIds"
                  optionsList={nurseSkillList}
                  // setSelectedOptions={setSelectedOptions}
                  // initialSelectedOptions={selectedSkills}
                  component={MultiSelectDropdownNew}
                  requiredMark={true}
                  validate={requiredArray}
                  // validate={(val) => {
                  //   if (loadedOnce) {
                  //     console.log('test-- ', val);
                  //     return 'error field';
                  //   }
                  // }}
                  // handleClear={() => {
                  //   setSelectedSkills([]);
                  //   return dispatch(change("AccountDetailsForm", 'nurseSkillIds', ''));
                  // }}
                />
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="mb-4">
                <Field
                  className="form-control mk-wi"
                  name="applicationDate"
                  label="Application Date"
                  type="date"
                  component={CustomInputField}
                  // requiredMark={true}
                ></Field>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="mb-4">
                <Field
                  className="form-control mk-wi"
                  name="interviewDate"
                  label="Interview Date"
                  type="date"
                  component={CustomInputField}
                  // requiredMark={true}
                ></Field>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="mb-4">
                <Field
                  className="form-control mk-wi"
                  name="hireDate"
                  label="Hire Date"
                  type="date"
                  component={CustomInputField}
                  // requiredMark={true}
                ></Field>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="mb-4">
                <Field
                  className="form-control mk-wi"
                  name="startDate"
                  label="Start Date"
                  type="date"
                  component={CustomInputField}
                  // requiredMark={true}
                ></Field>
              </div>
            </div>
            {/*<div className="col-12 col-md-6">
              <div className="mb-4">
                <Field
                  className="form-control mk-wi"
                  name="terminationDateText"
                  // label="Termination Date"
                  label="Effective End Date"
                  type="text"
                  component={CustomInputField}
                  // requiredMark={true}
                  // 
                  readOnly={true}
                ></Field>
              </div>
            </div>*/}
          {/*</div>
          <div className="row">*/}
            {/*<div className="col-12 col-md-6">
              <div className="mb-4">
                <Field
                  className="form-control"
                  label="Administrator"
                  name="nurseAdministratorId"
                  component={CustomDropDownNewField}
                  requiredMark={true}
                  
                >
                  <option value="">Please select</option>
                  {(adminsList || [])?.map((res, index) => {
                    return (
                      <option key={index} value={res.id}>
                        {res.name}
                      </option>
                    );
                  })}
                </Field>
              </div>
            </div>*/}
            <div className="col-12 col-md-4">
              <div className="mb-4">
                <Field
                  className="form-control"
                  label="Region"
                  name="regionId"
                  component={CustomDropDownNewField}
                  // requiredMark={true}
                >
                  <option value="">Please select</option>
                  {(regionsList || [])?.map((res, index) => {
                    return (
                      <option key={index} value={res.id}>
                        {res.name}
                      </option>
                    );
                  })}
                </Field>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="mb-4">
                <Field
                  className="form-control"
                  label="Referred By"
                  name="referredByPersonalInfoId"
                  component={CustomDropDownNewField}
                  // requiredMark={true}
                >
                  <option value="">Please select</option>
                  {(allPersonsList || [])?.map((res, index) => {
                    return (
                      <option key={index} value={res.id}>
                        {`${res?.name} ( ${res?.personCategory})`}
                      </option>
                    );
                  })}
                </Field>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="mb-4">
                {/*<Field
                 className="form-control"
                 label="Mobile App Geo-Fence"
                 name="mobileAppGeofenceEnabled"
                 component={CustomDropDownNewField}
                 // requiredMark={true}
                 >
                 <option value="">Please select</option>
                 <option value="1">Enabled</option>
                 <option value="2">Disabled</option>
                 </Field>*/}

                <label>
                  Mobile App Geo-Fence Enabled<i className="text-lightgrey ms-1">&nbsp;(Optional)</i>
                </label>
                <Field
                  name="mobileAppGeofenceEnabled"
                  component={CustomRadioGroup}
                  options={mobileAppGeofenceEnabledOptions}
                  onChange={(e) => undefined}
                />
              </div>
            </div>
            <div className="col-12 col-md-5">
              <div className="mb-4">
                <Field
                  className="form-control mk-wi"
                  name="mobileAppGeofenceOverrideRadius"
                  label="Override Geo-Fence Radius: &#x28;meters&#x29;"
                  type="number"
                  component={CustomInputField}
                  placeholder={'0'}
                  // requiredMark={true}
                  // validate={(value) => required(value)}
                />
              </div>
            </div>
            <div className="col-12 col-md-2">
              <div className="mb-4">
                <Field
                  className="form-control mk-wi"
                  name="mobileAppVersion"
                  label="Mobile App Version"
                  type="text"
                  component={CustomInputField}
                  readOnly={true}
                  disabled={true}
                ></Field>
              </div>
            </div>
            <SaveButton pristine={pristine} submitting={submitting} />
          </div>
        </form>
        <Modal isOpen={effectiveEndDateModal} modalTitle={buttonText} onClose={handleEffectiveEndDateModal} modalBodyClass={'pt-1'}>
          <NurseActiveInactivateModal nurseId={nurseId} handleChange={handleChange} onClose={handleEffectiveEndDateModal}/>
        </Modal>
      </div>
    </div>
  );
};

function getStatusUiProps(active) {
  let buttonText = 'Activate';
  let statusColor = 'green';
  let borderColor = 'success';
  if (active != null) {
    if (active) { // active
      buttonText = 'Set Effective End Date';
      statusColor = 'success';
      borderColor = 'success';
    } else { // inactive
      buttonText = 'Activate';
      statusColor = 'danger';
      borderColor = 'danger';
    }
  }
  return {buttonText, statusColor, borderColor};
}

const mapStateToProps = (state) => {
  const { auth, nurseListing: { nurseAccountDetails = {} } } = state;

  let {buttonText, statusColor, borderColor} = getStatusUiProps(nurseAccountDetails?.active);

  return {
    isAdmin: auth?.authData?.isAdmin || false,
    agencyId: auth?.authData?.agencyId || auth.agencyId,
    dataLoaded: nurseAccountDetails != null && JSON.stringify(nurseAccountDetails) !== '{}',
    buttonText,
    statusColor,
    borderColor,
    initialValues: {
      active: numberHelperFunctions.convertToIntegerString(nurseAccountDetails?.active),
      status: stringHelperFunctions.sanitize(nurseAccountDetails?.activeText).toUpperCase(),
      nurseClassId: numberHelperFunctions.convertToIntegerString(nurseAccountDetails?.nurseClassId),
      // nurseClassIds:
      //   (nurseAccountDetails &&
      //     nurseAccountDetails.nurseClasses &&
      //     nurseAccountDetails.nurseClasses.length > 0 &&
      //     nurseAccountDetails.nurseClasses.map((l) => ({
      //       id: l.id,
      //       name: l.class,
      //     }))) ||
      //   [],
      nurseSkillsIds: arrayHelperFunctions.getMapped(nurseAccountDetails?.skills, ['id', 'skill'], ['value', 'label']),
      applicationDate: stringHelperFunctions.sanitize(nurseAccountDetails?.applicationDate),
      interviewDate: stringHelperFunctions.sanitize(nurseAccountDetails?.interviewDate),
      hireDate: stringHelperFunctions.sanitize(nurseAccountDetails?.hireDate),
      startDate: stringHelperFunctions.sanitize(nurseAccountDetails?.startDate),
      terminationDate: stringHelperFunctions.sanitize(nurseAccountDetails?.terminationDate),
      terminationDateText: stringHelperFunctions.sanitize(nurseAccountDetails?.terminationDateFormatUs, false, undefined, 'yyyy-mm-dd'),
      nurseAdministratorId: numberHelperFunctions.convertToIntegerString(nurseAccountDetails?.nurseAdministratorId, 1),
      regionId: numberHelperFunctions.convertToIntegerString(nurseAccountDetails?.nurseRegionId),
      referredByPersonalInfoId: numberHelperFunctions.convertToIntegerString(nurseAccountDetails?.referralPersonalInfoId),
      mobileAppGeofenceEnabled: booleanHelperFunctions.convertToString(nurseAccountDetails?.mobileAppGeofenceEnabled),
      mobileAppGeofenceOverrideRadius: numberHelperFunctions.convertToIntegerString(nurseAccountDetails?.mobileAppGeofenceOverrideRadius),
      mobileAppVersion: stringHelperFunctions.sanitize(nurseAccountDetails?.mobileAppVersion, true, ['0']),
    },
  };
};

export default connect(mapStateToProps)(
  reduxForm({ form: "AccountDetailsForm", enableReinitialize: true })(AccountDetails)
);
