import React, {useState} from 'react'
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { useDispatch } from 'react-redux';
import CustomInputField from '../sharedComponents/reduxfields/customInputField'
import CustomDropDownField from '../sharedComponents/reduxfields/customDropDown'
import {required, emailValidation, usPhoneNumberValidation} from '../helpers/validation'
import Modal from '../sharedComponents/Modal'
import {postCreateAgency, postCreatePhysician} from "../services/client";
import {useQueryClient} from "@tanstack/react-query";

const AddAgencyModal = ({ handleSubmit, isModalOpen, closeModal, modalEntityName, isHospital, isHealthAgency, isHospiceAgency, agencyId }) => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();

    const gendersList = queryClient.getQueryData(['list-genders']);
    const physicianTypesArray = queryClient.getQueryData(['list-physician-types', agencyId]);

    const onSubmit = async (values) => {
        let payload = Object.assign({}, values);
        // payload.firstName = values.firstName;
        // payload.lastName = values.lastName;
        payload.email = values.email;
        // payload.phoneNumber = values.phone;
        // payload.genderId = parseInt(values.genderId);
        payload.name = values.agencyName;
        delete payload.agencyName;
        payload.isHospital = isHospital;
        payload.isHealthAgency = isHealthAgency;
        payload.isHospiceAgency = isHospiceAgency;
        const res = await dispatch(postCreateAgency(payload, `${modalEntityName} added successfully`));
        if (res.success) {
            payload.id = res.data.id;
            closeModal(true, payload);
        }
    }

    return (
      <Modal modalDialogClass="modal-lg" isOpen={isModalOpen} onClose={() => closeModal(false)} modalTitle={`Add ${modalEntityName}`}>
        <form onSubmit={handleSubmit(onSubmit)} >
            <div className="form-group form-label">
                <div className="row">
                    <div className="col-sm-6">
                        <Field
                          className="form-control mk-wi"
                          name="agencyName"
                          label="Name"
                          type="text"
                          component={CustomInputField}
                          requiredMark={true}
                          validate={required}
                        ></Field>
                    </div>
                    <div className="col-sm-6">
                        <Field
                          className="form-control mk-wi"
                          name="email"
                          label="Email"
                          type="email"
                          component={CustomInputField}
                          requiredMark={true}
                          validate={[required,emailValidation]}
                        ></Field>
                    </div>
                </div>
            </div>
            {/*<div className="form-group form-label">
             <h6>Agency User Details</h6>
             </div>
             <div className="form-group form-label">
             <div className="row">
             <div className="col-sm-6">
             <Field
             className="form-control mk-wi"
             name="firstName"
             label="First Name"
                          type="text"
                          component={CustomInputField}
                          requiredMark={true}
                          validate={required}
                        ></Field>
                    </div>
                    <div className="col-sm-6">
                        <Field
                          className="form-control mk-wi"
                          name="lastName"
                          label="Last Name"
                          type="text"
                          component={CustomInputField}
                          requiredMark={true}
                          validate={required}
                        ></Field>
                    </div>
                </div>
            </div>
            <div className="form-group form-label pt-2">
                <div className="row">
                    <div className="col-sm-6">
                        <Field
                          label="Gender"
                          className="form-control"
                          name="genderId"
                          component={CustomDropDownField}
                          validate={required}
                          requiredMark={true}
                        >
                            <option value="">Please select</option>
                            {gendersList?.map((res, index) => {
                                return <option key={index} value={res.id}>{res.name}</option>
                            })}
                        </Field>
                    </div>
                    <div className="col-sm-6">
                        <Field
                          className="form-control mk-wi"
                          name="email"
                          label="Email"
                          type="email"
                          component={CustomInputField}
                          requiredMark={true}
                          validate={emailValidation}
                        ></Field>
                    </div>
                </div>
            </div>*/}
            <div className="modal-footer justify-content-center pt-4 pb-0 gap-2 mt-4">
                <button type="button" className="btn btn-secondary" onClick={() => closeModal(false)}>Cancel</button>
                <button type="submit" className="btn btn-primary">Create</button>
            </div>
        </form>
      </Modal>
    )
}

export default connect()(
  reduxForm({form: "AddAgencyForm"})(AddAgencyModal)
);
