import React, {useEffect, useState} from 'react';
import Pagination from "../../../sharedComponents/pagination";
import {pageLimit} from "../../../appConstants";
import {getCountedVisits} from "../../../services/serviceRequest";

const NewTableLayout = ({children, listData, loadDeps = [], loadMethod}) => {
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (loadMethod != null) loadMethod(currentPage, loadDeps[0] || undefined, loadDeps[1] || undefined, loadDeps[2] || undefined);
  }, [...loadDeps, currentPage]);
// console.log('test- loadDeps', loadDeps);

  return (
    <>
      <div className="tab-content" id="pills-tabContent">
        <div className="bg-white mt-3">
          {children}
        </div>
      </div>
      <Pagination
        currentPage={listData?.page}
        totalRecords={listData?.total}
        limit={listData?.pageSize}
        onPageChange={(page) => setCurrentPage(page)}
      ></Pagination>
    </>
  );
};

export default NewTableLayout;
