import React, {useEffect, useState} from 'react';
import {Stack, Pagination} from "@mui/material";
import {pageLimit} from "../../appConstants";

/**
 * A data object with a rows, count, total, and page properties.
 *
 * @typedef {Object<string, any>} TableDataInfo
 * @property {any[]} rows The heading of the column.
 * @property {number} pageSize The key of the person.
 * @property {number} count The key of the person.
 * @property {number} total The key of the person.
 * @property {number} page The key of the person.
 * @property {number} pageIndex The key of the person.
 */

/**
 * This is a page change event handler
 *
 * @callback PageChangeEventHandler
 * @param {number} page
 */

/**
 * @param {Object} props - this is object param
 // * @property {number} props.currentPage The key of the person.
 * @property {TableDataInfo} props.data - this is object param
 * @property {PageChangeEventHandler} props.onPageChange - this is method param
 */
const CommonPaginationNew = (props) => {
    // const { currentPage, data, onPageChange } = props;
    const { data, onPageChange } = props;
    const { rows, pageSize: limit, count, total, page, pageIndex} = data;

    // const [page, setPage] = useState(1);

    const pageCount = Math.ceil(total / limit);

    const handlePageChange = (event, page) => {
        onPageChange(page);
    };

    // useEffect(() => {
    //     if (currentPage) setPage(currentPage);
    // }, [currentPage]);

    return (
        <Stack spacing={2} margin={'20px'}>
            <Pagination
                count={pageCount}
                // page={page}
                page={page}
                onChange={handlePageChange}
                showFirstButton
                showLastButton
                color="primary"
            />
        </Stack>
    );
};

export default CommonPaginationNew;
