import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Modal from "../../../sharedComponents/Modal";
import moment from "moment/moment";
import CustomInputField from "../../../sharedComponents/fields/customInputField";
import CustomDropDownField from "../../../sharedComponents/fields/customDropDown";
import { addTask, getTasksData } from "../../../services/charting";
import { GET_TASK_TABLE } from "../../../helpers/commonActionTypes";

//setIsAddTaskPopupShow

const CreateNewTaskModel = (props) => {
  const { isModalOpen, closeModal, getTaskTableData, charting ,handleCreateClick, modalTitle } = props;
  const dispatch = useDispatch();

  const [taskData, setTaskData] = useState({
    name: "",
    category: "",
    date: moment(new Date()).format("YYYY-MM-DD"),
    status: "pending",
  });
  const [categoryData, setCategoryData] = useState([
    "Vital Signs/Measures Personal Care",
    "Activity",
    "Elimination",
    "Nutrition/Diet",
    "Environmental",
    "Medications",
    "Personal Care",
  ]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTaskData({ ...taskData, [name]: value });
  };


  return (
    <Modal isOpen={isModalOpen} onClose={closeModal} modalTitle={modalTitle}>
      <CustomInputField
        label={"Task Name"}
        className="form-control mt-2 p-4"
        name="name"
        value={taskData.name}
        onChange={(e) => {
          handleInputChange(e);
        }}
      />
      <label className="mt-2">Category</label>
      <CustomDropDownField
        className="form-select mt-2"
        aria-label="Default select example"
        name="category"
        value={taskData.category}
        onChange={(e) => {
          handleInputChange(e);
        }}
        children={
          <>
            <option selected>Select Category</option>

            {categoryData.map((item) => {
              return <option value={item}>{item}</option>;
            })}
          </>
        }
      />
      <div className="modal-footer d-flex flex-row justify-content-lg-start align-items-center">
        <button
          type="button"
          className="btn btn-outline-primary "
          onClick={() => closeModal()}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => handleCreateClick(taskData)}
        >
          Create
        </button>
      </div>
    </Modal>
  );
};
const mapStateToProps = (state) => {
  const { charting } = state;
  return {
    charting: charting.taskTableData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleCreateClick: (value) =>
      dispatch(addTask({ type: GET_TASK_TABLE, value: value })),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateNewTaskModel);
