import {Fragment, useEffect, useState} from "react";
import {Autocomplete} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";

const TextSuggestMulti = ({ name, getList, onSelected, ...props }) => {

  const value = props.input.value || '';

  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedValue, setValue] = useState('');
  const [loading, setLoading] = useState(open && options.length === 0);
  const [initialValue, setInitialValue] = useState('');

  useEffect(() => {
// console.log('test- ', {loading, options, inputValue})
    if (!loading) {
      return undefined
    }
    // if (inputValue === '') {
    if (inputValue.length === 0) {
      if (options.every(x => x.length > 1)) {
        // setOptions(selectedValue ? [...selectedValue] : options)
        setOptions(selectedValue ? [selectedValue] : options)
      }
      return undefined
    }
    if (options.length === 0) {
      return undefined
    }

    return () => false
  }, [loading, options, inputValue]);

  // useEffect(() => {
  //   // console.log('test- value', control, props.input.value)
  // }, [value]);

  // useEffect(() => {
  //   if (loading) {
  //     // setInitialValue(value);
  //     setValue(value);
  //     setLoading(false);
  //   }
  // }, [options]);

  useEffect(() => {
    if (value != null && value.length > 0) {
      if (value != null && value.length > 0) {
        setOptions([value]);
        setValue(value);
      }
    }
  }, [value]);

  useEffect(() => {
    // console.log('test- value', value, open)
    // console.log('props', props)
    if (!open) {
      // console.log('test- check', options.every(x => x.length > 1))
      // if (value != null && value.length > 0) {
      //   // setLoading(true);
      //   setOptions([value]);
      //   setValue(value);
      // } else
      // console.log('test- options', options)
      const tempOptions = options.filter(Boolean);
      if (tempOptions.every(x => x.length > 1)) {
        // setOptions(selectedValue ? [...selectedValue] : [options]);
        setOptions(selectedValue ? [selectedValue] : [tempOptions]);
      }
    }
  }, [open]);

  return (
    <Autocomplete
      multiple={false}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      // control={control}
      // filterOptions={(x) => x}
      // filterOptions={(x) => {
      //   console.log('filterOptions - x', x)
      //   return x.some(i => i.length > 1);
      // }}
      autoComplete={true}
      // isOptionEqualToValue={(option, optionValue) => (option.email === optionValue.email) }
      isOptionEqualToValue={(option, optionValue) => (option === optionValue) }
      // isOptionEqualToValue={(option, optionValue) => {
      //   console.log('isOptionEqualToValue', option, ' -- ', optionValue)
      //   return (option === optionValue);
      // }}
      // getOptionLabel={(option) => option.name}
      getOptionLabel={(option) => option}
      options={options}
      loading={loading}
      renderOption={(props, option, state, ownerState) => {
        // return(<li {...props}><span>{option.name}</span>: [{option.email}]</li>)
        // console.log('renderOption - option', option)
        // console.log('renderOption - option', props, state, ownerState)
        return(<li {...props}><span>{option}</span></li>)
      }}
      blurOnSelect
      // filterSelectedOptions={true}
      value={selectedValue}
      onChange={(event, item ) => {
        // console.log('test- onChange - item', item)
        setValue(item);
        onSelected(item);
        // field.onChange(item)
      }}
      // inputValue={initialValue || undefined}
      onInputChange={async (event, newInputValue) => {
        const eventType = event?.type || 'None';
        // console.log('event type', eventType)
        if (eventType === 'change') {
          setInitialValue(null);
          setLoading(true);
          let results = await getList(newInputValue);
          // console.log('results', results, selectedValue);
          // setOptions([...results, ...selectedValue]);
          setOptions([...results, selectedValue]);
          setInputValue([...newInputValue]);
          setLoading(false);
        }
        // if (eventType === 'None') {
        //   setOptions([]);
        // }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          // value={selectedValue}
          // key={selectedValue}
          label={`${props.label}`}
          className={props.className}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )
      }
    />
  );
};

export default TextSuggestMulti;
