import React, {useEffect, useState} from "react";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import CustomInputField from "../../../../sharedComponents/reduxfields/customInputField";
import PrimaryButton from "../../../../sharedComponents/buttons/PrimaryButton";
import Certificationcomnplete from "./Certificationcomplete";
import {connect, useDispatch} from "react-redux";
import {change, Field, getFormValues, reduxForm} from "redux-form";
import {getNurseAction, postNurseAction} from "../../../../services/NursesProfile";
import {required} from "../../../../helpers/validation";
import * as nurseAction from "../nurseActionTypes";
import Modal from "../../../../sharedComponents/Modal";
import {useQueryClient} from "@tanstack/react-query";
import booleanHelperFunctions from "../../../../helpers/boolean.helpers";
import classes from "../../Client/ClientProfile/CareNote/CareNote.module.css";
import CustomDropDownNewField from "../../../../sharedComponents/reduxfields/customDropDownNew";
import CustomTextarea from "../../../../sharedComponents/reduxfields/CustomTextArea";
import SaveButton from "../../../../sharedComponents/buttons/SaveButton";
import {HttpsAction} from "../../../../commonApiFunction/httpsAction";
import numberHelperFunctions from "../../../../helpers/number.helpers";
import stringHelperFunctions from "../../../../helpers/string.helpers";
import useCustomNav from "../../../../utils/hooks/useCustomNav";

const OtherCertificationsNew = ({formData, listLength, notNewAndNotDeletedListLength, handleSubmit, pristine, submitting, isAdmin, agencyId}) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const { id: nurseId } = useParams();
    const { nurseId } = useOutletContext();

    const { goTo: gotoEvaluationsPage } = useCustomNav(isAdmin ? `/admin/agency/nurses/edit/profile/certs-evals/evaluations` : '/agency/nurses/edit/profile/certs-evals/evaluations');

    const reminderDaysArray = queryClient.getQueryData(['list-reminder-days']);
    // const certificateTypesArray = queryClient.getQueryData(['list-certificate-types', agencyId]);

    const [show, setShow] = useState(false);
    const [allowAutoAddition, setAllowAutoAddition] = useState(true);
    const [dataModified, setDataModified] = useState(false);
    const [xtraFieldValue, setXtraFieldValue] = useState(1);

    // const certificationsList = useSelector(
    //     (state) => {
    //         if (state != null && state.nurseListing != null && state.nurseListing.nurseOtherCertifications != null) {
    //             console.log('submit- state.nurseListing.otherCertifications', state.nurseListing.nurseOtherCertifications);
    //         }
    //         return state?.nurseListing?.nurseOtherCertifications?.map(v => (v.id !== 0 ? {
    //             ...v,
    //             checkIfComplete: true
    //         } : {...v})) || [];
    //     }
    // );

    function convertDataObjectToArray(dataObj, isSubmittingNow = false) {
        const list = Array.from({length: listLength}, (v, i) => i);
        if (!isSubmittingNow) {
            return list.map((val, index) => {
                return {
                    recordType: dataObj[`recordType${index + 1}`],
                    id: dataObj[`id${index + 1}`],
                    checkIfComplete: dataObj[`checkIfComplete${index + 1}`],
                    otherCertificateName: dataObj[`otherCertificateName${index + 1}`],
                    expiryDate: dataObj[`expiryDate${index + 1}`],
                    reminderDaysId: dataObj[`reminderDaysId${index + 1}`],
                    note: dataObj[`note${index + 1}`],
                    renewalRequired: dataObj[`renewalRequired${index + 1}`],
                    renewalDate: dataObj[`renewalDate${index + 1}`],
                    renewalReminderDaysId: dataObj[`renewalReminderDaysId${index + 1}`],
                    // index,
                };
            });
        }

        const payload = [];
        for (let i = 0; i < listLength; i++) {
            if (dataObj[`recordType${i + 1}`] !== "deleted") {
                const recordType = dataObj[`recordType${i + 1}`];
                console.log(`index ==> ${i} ==>`, dataObj[`recordType${i + 1}`])
                let id = dataObj[`id${i + 1}`];
                const item = {
                    recordType,
                    "id": id === 0 ? null : Number(id),
                    "otherCertificateName": stringHelperFunctions.nullIfEmpty(dataObj[`otherCertificateName${i + 1}`]),
                    "expiryDate": stringHelperFunctions.nullIfEmpty(dataObj[`expiryDate${i + 1}`]),
                    "reminderDaysId": numberHelperFunctions.nullIfEmpty(dataObj[`reminderDaysId${i + 1}`]),
                    "note": stringHelperFunctions.nullIfEmpty(dataObj[`note${i + 1}`]),
                    "renewalRequired": booleanHelperFunctions.nullIfEmpty(dataObj[`renewalRequired${i + 1}`]),
                    "renewalDate": stringHelperFunctions.nullIfEmpty(dataObj[`renewalDate${i + 1}`]),
                    "renewalReminderDaysId": numberHelperFunctions.nullIfEmpty(dataObj[`renewalReminderDaysId${i + 1}`], true),
                };
                payload.push(item);
            } else {
                const recordType = dataObj[`recordType${i + 1}`];
                let id = dataObj[`id${i + 1}`];
                payload.push({recordType, "id": id === 0 ? null : Number(id)})
            }
        }
        return payload;
    }

    const handleShow = async (index) => {
        // await dispatch({
        //     type: 'ADD_NURSE_SET_CLICK_IF_COMPLETE_DATA',
        //     payload: certificationsList[index - 1],
        //     name: 'clickIfComplete',
        //     meta: {type: 'SET_CLICK_IF_COMPLETE_DATA'},
        // });
        // setShow(index)

        const payload = convertDataObjectToArray(formData).map(item => ({
            id: item.id,
            renewalRequired: item.renewalRequired,
            renewalDate: item.renewalDate,
            renewalReminderDaysId: item.renewalReminderDaysId,
        }));
        setShow({...payload[index - 1], index});
    };

    const onSubmit = async (data) => {
        // // let payload = Object.assign({}, values);
        // // console.log('payload', payload);
        // const payload = [];
        // console.log({data})
        // certificationsList.forEach((value, index) => {
        //     if (value.recordType !== "deleted") {
        //         // console.log("submit- value", value);
        //         const recordType = data[`recordType${index + 1}`];
        //         const note = data[`note${index + 1}`];
        //         const renewalRequired = data[`renewalRequired${index + 1}`] === 'true';
        //         payload.push({
        //             id: recordType === 'existing' ? data[`id${index + 1}`] : undefined,
        //             recordType,
        //             otherCertificateName: data[`otherCertificateName${index + 1}`],
        //             expiryDate: data[`expiryDate${index + 1}`],
        //             reminderDaysId: +data[`reminderDaysId${index + 1}`],
        //             note: note == null || note.trim().length === 0 ? undefined : note,
        //             renewalRequired: value?.renewalRequired,
        //             renewalDate: value?.renewalDate,
        //             renewalReminderDaysId: +value?.renewalReminderDaysId,
        //         });
        //     } else {
        //         payload.push(value)
        //     }
        // });
        //
        // console.log('payload', payload);
        //
        // try {
        //     const response = await dispatch(
        //         postNurseAction(
        //             payload,
        //             "otherCertificationsList",
        //             nurseId,
        //             "Nurse certification Created/Updated Successfully"
        //         )
        //     );
        //     if (response?.success) {
        //         navigate(`/agency/nurses/edit/${nurseId}/otherCertifications`);
        //     }
        // } catch (error) {
        //     console.error(error);
        // }

        try {
            const payload = convertDataObjectToArray(data, true);
            const response = await dispatch(postNurseAction(payload, "otherCertificationsList", nurseId, "Nurse certification Created/Updated Successfully"));
            if (response?.success) {
                // navigate(`/agency/nurses/edit/${nurseId}/otherCertifications`);
                gotoEvaluationsPage();
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleSelectName = (target, index) => {
        // if (target?.value && index) {
        //     let clonedCertificationsList = [...certificationsList];
        //     clonedCertificationsList[index - 1] = {...clonedCertificationsList[index - 1], checkIfComplete: true};
        //     dispatch({
        //         type: 'ADD_NURSE_ADD_CLICK_IF_COMPLETE',
        //         payload: clonedCertificationsList,
        //         name: 'nurseOtherCertifications',
        //         meta: {type: 'ADD_CLICK_IF_COMPLETE'},
        //     });
        // } else if (target?.value === "" && index) {
        //     let clonedCertificationsList = [...certificationsList];
        //     clonedCertificationsList[index - 1] = {...clonedCertificationsList[index - 1], checkIfComplete: false};
        //     dispatch({
        //         type: 'ADD_NURSE_ADD_CLICK_IF_COMPLETE',
        //         payload: clonedCertificationsList,
        //         name: 'nurseOtherCertifications',
        //         meta: {type: 'ADD_CLICK_IF_COMPLETE'},
        //     });
        // }

        let clonedCertificationsList = convertDataObjectToArray(formData);
        if (target?.value && index) {
            // clonedCertificationsList[index - 1] = {...clonedCertificationsList[index - 1], checkIfComplete: true};
            clonedCertificationsList[index - 1].checkIfComplete = true;
            clonedCertificationsList[index - 1].certificationEvaluationId = target.value;
        } else if (target?.value === "" && index) {
            // clonedCertificationsList[index - 1] = {...clonedCertificationsList[index - 1], checkIfComplete: false};
            clonedCertificationsList[index - 1].checkIfComplete = false;
        }
        dispatch(HttpsAction({
            reducerName: 'NURSE',
            reduxKeyName: 'nurseOtherCertifications',
            actionType: 'NURSE_OTHER_CERTIFICATIONS',
            data: {xtraField: formData['xtraField'], list: clonedCertificationsList},
            updateData: true,
            actionTypeIsDispatchType: true,
            positiveCallBack: (_) => {
                setDataModified(true);
            },
        }));
    };

    const handleClose = (data) => {
        setShow(false);
        if (data) {
            const idx = data.index;
            let clonedCertificationsList = convertDataObjectToArray(formData);
            if (data && idx) {
                clonedCertificationsList[idx - 1].renewalDate = data.renewalDate || null;
                clonedCertificationsList[idx - 1].renewalReminderDaysId = data.renewalReminderDaysId || null;
                clonedCertificationsList[idx - 1].renewalRequired = data.renewalRequired || false;
            }
            dispatch(HttpsAction({
                reducerName: 'NURSE',
                reduxKeyName: 'nurseOtherCertifications',
                actionType: 'NURSE_OTHER_CERTIFICATIONS',
                data: {xtraField: formData['xtraField'], list: clonedCertificationsList},
                updateData: true,
                actionTypeIsDispatchType: true,
                positiveCallBack: (_) => {
                    setDataModified(true);
                },
            }));
        }
    };

    const HandleClickAddCertification = () => {
        let updatedList = convertDataObjectToArray(formData);
        dispatch(HttpsAction({
            reducerName: 'NURSE',
            reduxKeyName: 'nurseOtherCertifications',
            actionType: 'NURSE_OTHER_CERTIFICATIONS',
            data: {xtraField: formData['xtraField'], list: updatedList},
            updateData: true,
            actionTypeIsDispatchType: true,
            positiveCallBack: (e) => {
                dispatch({type: nurseAction.NURSE_ADD_OTHER_CERTIFICATION});
                setDataModified(true);
            },
        }));
    };

    const HandleClickDeleteCertification = (deletedIndex) => {
        let updatedList = convertDataObjectToArray(formData);
        const deletedItemIdValue = formData[`id${deletedIndex}`];
        if (deletedItemIdValue === '0') { // item not present in database, so remove item from list/array
            updatedList.splice(deletedIndex - 1, 1);
        } else { // item present in database, so identify/mark it for deletion for later API call
            updatedList[deletedIndex - 1].recordType = "deleted";
        }
        setAllowAutoAddition(false);
        dispatch(HttpsAction({
            reducerName: 'NURSE',
            reduxKeyName: 'nurseOtherCertifications',
            actionType: 'NURSE_OTHER_CERTIFICATIONS',
            data: {xtraField: formData['xtraField'], list: updatedList},
            updateData: true,
            actionTypeIsDispatchType: true,
            positiveCallBack: (_) => {
                setDataModified(true);
            },
        }));
    };

    useEffect(() => {
        if (dataModified) {
            setDataModified(false);
            dispatch(change("OtherCertificationsForm", 'xtraField', String(xtraFieldValue), true));
            setXtraFieldValue(prevState => prevState + 1);
        }
    }, [dataModified]);

    useEffect(() => {
        if (allowAutoAddition && listLength === 0) {
            dispatch({type: nurseAction.NURSE_ADD_OTHER_CERTIFICATION});
        }
    }, [listLength]);

    useEffect(() => {
        dispatch(getNurseAction("otherCertifications", nurseId));
        return () => {
            dispatch(HttpsAction({
                reducerName: 'NURSE',
                reduxKeyName: 'nurseOtherCertifications',
                actionType: 'NURSE_OTHER_CERTIFICATIONS',
                removeData: true,
                actionTypeIsDispatchType: true,
            }));
        };
    }, []);

    return (
      <>
          <form onSubmit={handleSubmit(onSubmit)}>
              <Field
                name="xtraField"
                type="hidden"
                component={CustomInputField}
              />
              <div className="p-0 m-0 d-flex flex-column justify-content-start align-content-start gap-3">
                  {listLength > 0 && (Array.from({length: listLength}, (v, i) => i) || []).map((item, index) => (
                    <React.Fragment key={index}>
                        <div className="card" style={{
                            overflow: "hidden",
                            borderWidth: formData != null && JSON.stringify(formData) !== '{}' && formData[`recordType${index + 1}`] === "deleted" ? "0" : "auto",
                            height: formData != null && JSON.stringify(formData) !== '{}' && formData[`recordType${index + 1}`] === "deleted" ? "1px" : "auto",
                        }}>
                            <div className="card-body">
                                <div className={`row ${index === 0 ? '' : 'mt-2'}`}>
                                    <div className="col-6 d-flex align-items-center">
                                        <div className={`col-4`} style={{fontSize: "18px", fontWeight: 500, whiteSpace: "nowrap"}}>
                                            Certification #{index + 1}
                                        </div>
                                    </div>
                                    <div className="col-6 d-flex justify-content-end">
                                        <Field
                                          name={`recordType${index + 1}`}
                                          type="hidden"
                                          component={CustomInputField}
                                        />
                                        <Field
                                          name={`id${index + 1}`}
                                          type="hidden"
                                          component={CustomInputField}
                                        />
                                        <Field
                                          name={`renewalRequired${index + 1}`}
                                          type="hidden"
                                          component={CustomInputField}
                                        />
                                        <Field
                                          name={`renewalDate${index + 1}`}
                                          type="hidden"
                                          component={CustomInputField}
                                        />
                                        <Field
                                          name={`renewalReminderDaysId${index + 1}`}
                                          type="hidden"
                                          component={CustomInputField}
                                        />
                                        {(notNewAndNotDeletedListLength > 0 || listLength > 1 || index > 0) &&
                                          <PrimaryButton
                                            type="button"
                                            className="btn-view-repo gap-2 px-4 py-2"
                                            text={"Delete"}
                                            onClick={() => HandleClickDeleteCertification(index + 1)}
                                          />}
                                    </div>
                                    <div className={classes.contactFormHeadingBorder}/>
                                    <div style={{padding: '24px 24px 0 24px'}}>
                                        <div>
                                            <div className="row">
                                                <div className="col-12 col-md-4">
                                                    <Field
                                                      className="form-control mk-wi"
                                                      name={`otherCertificateName${index + 1}`}
                                                      label="Name"
                                                      placeholder="name of certificate"
                                                      type="text"
                                                      component={CustomInputField}
                                                      requiredMark={true}
                                                      validate={required}
                                                      // onChange={({target}) => handleSelectName(target, index + 1)}
                                                      onBlur={({target}) => handleSelectName(target, index + 1)}
                                                    ></Field>
                                                </div>
                                                <div className="col-12 col-md-4">
                                                    <Field
                                                      className="form-control mk-wi"
                                                      name={`expiryDate${index + 1}`}
                                                      label="Expiry Date"
                                                      placeholder="02/04/2032"
                                                      type="date"
                                                      component={CustomInputField}
                                                      requiredMark={true}
                                                      validate={required}
                                                      // onChange={(e) => HandleInputChange(e, item.uid)}
                                                    ></Field>
                                                </div>
                                                <div className="col-12 col-md-4">
                                                    <Field
                                                      className="form-control mk-wi"
                                                      label="Reminder Days"
                                                      name={`reminderDaysId${index + 1}`}
                                                      component={CustomDropDownNewField}
                                                      requiredMark={true}
                                                      validate={required}
                                                      // onChange={(e) => HandleInputChange(e, item.uid)}
                                                    >
                                                        <option value="">Select Reminder Days</option>
                                                        {(reminderDaysArray || []).map((res, index) => (
                                                          <option key={index} value={res.id}>
                                                              {res.name}
                                                          </option>
                                                        ))}
                                                    </Field>
                                                </div>
                                                <div className="col-12">
                                                    <Field
                                                      className="form-control mk-wi"
                                                      style={{height: '80px'}}
                                                      type="textarea"
                                                      name={`note${index + 1}`}
                                                      label="Note"
                                                      component={CustomTextarea}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="fileUploadsContainer"></div>
                                {formData != null && formData[`checkIfComplete${index + 1}`] &&
                                  <a className="btn btn-primary rounded-0 border-btn-info"
                                     onClick={() => handleShow(index + 1)}>
                                      Click if complete
                                  </a>}
                            </div>
                        </div>
                    </React.Fragment>
                  ))}
                  <div className={'w-100 d-flex flex-row justify-content-center gap-2'}>
                      <PrimaryButton
                        type="button"
                        className="btn btn-primary w-100 rounded-0 border-btn-info"
                        style={{maxWidth: '210px'}}
                        text="Add Another Certification"
                        onClick={() => HandleClickAddCertification()}
                      />
                  </div>
              </div>
              <SaveButton pristine={pristine} submitting={submitting}
                          onClick={() => {
                              dispatch(change("OtherCertificationsForm", 'xtraField', String(xtraFieldValue)));
                              setXtraFieldValue(prevState => prevState + 1);
                              return undefined;
                          }}
              />
          </form>
          {show && <Modal isOpen={show} modalTitle={'Certification Complete'} onClose={handleClose}>
              <Certificationcomnplete type={'certifications'} onClose={handleClose} inputData={show}/>
          </Modal>}
      </>
    );
};

const mapStateToProps = (state) => {
    if (state.nurseListing?.nurseOtherCertifications != null) {
        const {auth, nurseListing: {nurseOtherCertifications: {xtraField = '0', list: dataList = []} = {xtraField: '0', list: []}}} = state;

        let initialValues = {xtraField};
        dataList?.forEach((cert, index) => {
            initialValues[`recordType${index + 1}`] = cert?.recordType || "existing";
            initialValues[`id${index + 1}`] = cert?.id?.toString() || 0;
            initialValues[`checkIfComplete${index + 1}`] = cert.checkIfComplete || false;
            initialValues[`otherCertificateName${index + 1}`] = cert?.otherCertificateName || "";
            initialValues[`expiryDate${index + 1}`] = cert?.expiryDate || "";
            initialValues[`reminderDaysId${index + 1}`] = cert?.reminderDaysId?.toString() || "";
            initialValues[`note${index + 1}`] = cert?.note || "";
            initialValues[`renewalRequired${index + 1}`] = booleanHelperFunctions.convertToString(cert?.renewalRequired);
            initialValues[`renewalDate${index + 1}`] = cert?.renewalDate || "";
            initialValues[`renewalReminderDaysId${index + 1}`] = cert?.renewalReminderDaysId?.toString() || "";
        });
        return {
            isAdmin: auth?.authData?.isAdmin || false,
            agencyId: auth?.authData?.agencyId || auth.agencyId,
            listLength: (dataList || []).length,
            notNewAndNotDeletedListLength: (dataList || []).filter(p => !(p.recordType === 'new' || p.recordType === 'deleted')).length,
            formData: getFormValues('OtherCertificationsForm')(state),
            initialValues,
        };
    }

    return {};
};

export default connect(mapStateToProps)(
  reduxForm({form: "OtherCertificationsForm", enableReinitialize: true})(OtherCertificationsNew)
);
