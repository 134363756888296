import React, {useEffect} from "react";

/**
 * This is a method to render title
 * @typedef {function(string): string} TitleRenderer
 */

/**
 * A table-header-info object with a title, dataIndex(key), and optional width.
 *
 * @typedef {Object<string, any>} TableHeaderInfo
 * @property {string} title The heading of the column.
 * @property {string} dataIndex The key of the person.
 * @property {number=} width The width of the column.
 * @property {TitleRenderer} titleRender - this is method param
 */

//{{title: string}, {dataIndex: number}, {width: number}}

/**
 * This is change event handler
 *
 * @callback changeEventHandlerCallback
 * @param {e} ChangeEvent<HTMLInputElement>
 */

/**
 * This is an input change event handler
 * @typedef {function(ChangeEvent<HTMLInputElement>): void} InputChangeEventHandler
 */

/**
 * @param {Object} props - this is object param
 * @param {TableHeaderInfo[]} props.headers - this is property param
 * @param {any[]} props.rows - this is property param
 * @param {boolean} props.isSelectable - this is property param
 * @param {InputChangeEventHandler} props.controlSelection - this is method param
 * @param {any[]} props.actions - this is property param
 * @param {boolean} props.dropdown - this is property param
 * @param {boolean} props.hasFixedWidth - this is property param
 * @param {string} props.actionColWidth - this is property param
 * @param {string} props.tableClass - this is property param
 */
const Table = (props) => {
  const { headers, rows, isSelectable, controlSelection, actions, dropdown, hasFixedWidth = false, actionColWidth = null, tableClass = null, horizontalActionsLayout = false, noDataFoundMessage = 'No data found!' } = props;
  const IsDropdown = dropdown || false;
  const hasNoData = rows?.length === 0;
  return (
    <div className="w-100">
      <table className={`w-100 table-container${tableClass != null ? ` ${tableClass}` : ''}`} style={{tableLayout: hasNoData ? 'auto' : hasFixedWidth ? "fixed" : "auto"}}>
        <thead>
          <tr className="border-bottom">
            {isSelectable && (
              <th className="isSelectableHeader">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    onChange={(e) => controlSelection(e)}
                    id="allTask"
                    type="checkbox"
                  />
                </div>
              </th>
            )}
            {headers?.map((item, index) => {
              return (
                <th
                  style={{
                    width: item.hasOwnProperty("width") && item.width,
                    textOverflow: item.hasOwnProperty("width") ? "ellipsis" : "unset",
                    overflow: item.hasOwnProperty("width") ? "hidden" : "unset",
                    position: item.hasOwnProperty("relativePosition") ? "relative" : undefined}}
                  key={index}
                >
                  {item.hasOwnProperty("titleRender")
                    ? item.titleRender(item.title)
                    : item.title}
                </th>
              );
            })}
            {actions?.length > 0 && <th style={{width: actionColWidth || 'auto'}}>Action</th>}
          </tr>
        </thead>
        <tbody>
          {rows?.map((item, index) => {
            const highlightRow = item?.highlightRow || false;
            const importantRow = item?.importantRow || false;

            let bodyRowClass = 'border border-bottom pad-left';
            if (highlightRow && importantRow) {
              bodyRowClass = 'border row-important row-blink';
            } else {
              if (highlightRow) {
                bodyRowClass = 'border row-blink';
              }
              if (importantRow) {
                bodyRowClass = 'border row-important';
              }
            }
            return (
              <tr className={bodyRowClass} key={index}>
                {isSelectable && (
                  <td>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        onChange={(e) => controlSelection(e)}
                        id={item.id}
                        checked={item.isChecked}
                        type="checkbox"
                      />
                    </div>
                  </td>
                )}
                {headers?.map((headerItem, index) => {
                  return (
                    <td key={index}
                      className={`position-relative${'align' in headerItem && headerItem.align === 'left' ? ' text-start' : ''}`}
                      style={{
                        width: headerItem.hasOwnProperty("width") && headerItem.width,
                        textOverflow: headerItem.hasOwnProperty("width") ? "ellipsis" : "unset",
                        overflow: headerItem.hasOwnProperty("width") ? "hidden" : "unset",
                        color: `${headerItem.dataIndex === 'transactionStatus' ? '#2ED47A' : ''}`
                      }}
                    >
                      {headerItem.dataIndex === 'transactionStatus' && <span className="me-2">
                        <svg xmlns="http://www.w3.org/2000/svg" width="6" height="7" viewBox="0 0 6 7" fill="none">
                          <circle cx="3" cy="3.60156" r="3" fill="#2ED47A" />
                        </svg>
                      </span>
                      }
                      {headerItem.hasOwnProperty("render")
                        ? headerItem.render(item[headerItem.dataIndex], index)
                        : item[headerItem.dataIndex]}
                        {/*: (headerItem.hasOwnProperty('width') ? 'true' : 'false')}*/}
                    </td>
                  );
                })}
                {actions?.length > 0 && (
                  <td>
                    <div className={`d-flex ${horizontalActionsLayout ? 'flex-row' : 'flex-column'} justify-content-center pb-0 gap-2`}>
                      {actions?.map((res, actionsIndex) => {
                        // return <button title={res.actionName} key={index} type="button" className={`btn ${res.actionClass}`} onClick={() => res.buttonAction(item.id)}><i className={`fa-solid ${res.iconClass}`}></i></button>
                        if (res.actionName !== 'DOWNLOAD')
                          return (
                            <button
                              key={actionsIndex}
                              type="button"
                              className={`btn btn-sm ${res.actionClass}`}
                              onClick={() => res.buttonAction(item.id, index)}
                              disabled={res.disableActionButtonFunc?.(item.id, item) || false}
                              style={{display: (res.hideActionButtonFunc?.(item.id, item) || false) === false ? 'block' : 'none'}}
                            >
                              {" "}
                              {res?.component} {res.actionName}
                            </button>
                          );
                        return (
                          <a key={actionsIndex} href={res.buttonAction(item.id, index)} className={`btn btn-sm ${res.actionClass} d-flex justify-content-center align-items-center btn-sm`} download>{res?.component} <span>{res.actionName}</span></a>
                        );
                      })}
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
          {hasNoData && (
            <tr>
              <td colSpan={24}>{noDataFoundMessage}</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
