import * as type from "./commonActionTypes";
import * as chartingAction from "../Agency/components/ServiceRequest/ChartingActionTypes";
import {defaultPaginationTab} from "../appConstants";

const initialState = {
  taskTableData: defaultPaginationTab,
  initialAssessmentData: defaultPaginationTab,
  supervisoryVisitsData: defaultPaginationTab,
  dischargeSummariesData: defaultPaginationTab,
  carePlansData: defaultPaginationTab,
};

const chartingReducer = (state = initialState, action) => {
  switch (action.type) {
    case type.GET_TASK_TABLE:
      return {
        ...state,
        taskTableData: action.payload,
      };
    case chartingAction.GET_INITIAL_ASSESSMENT:
      return {
        ...state,
        initialAssessmentData: action.payload,
      };
    case chartingAction.GET_SUPERVISORY_VISITS:
      return {
        ...state,
        supervisoryVisitsData: action.payload,
      };
    case chartingAction.GET_DISCHARGE_SUMMARIES:
      return {
        ...state,
        dischargeSummariesData: action.payload,
      };
    case chartingAction.GET_CARE_PLANS:
      return {
        ...state,
        carePlansData: action.payload,
      };

    case `ADD_CHARTING_${action.meta?.type}`:
      return {
        ...state,
        [action.name]: action.payload,
      };
    default:
      return state;
  }
};

export default chartingReducer;
