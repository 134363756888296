import React, {useEffect, useState} from 'react';
import Pagination from "../sharedComponents/pagination";
import {defaultPaginationTab, pageLimit} from "../appConstants";
import AddNurseModal from "../Agency/AddNurseModal";
import {NavLink, Outlet} from "react-router-dom";
import {debounce} from "lodash";
import PaginationSummary from "../sharedComponents/pagination/PaginationSummary";

const tabsInfoList = [
  {
    title: "Nurses List",
    path: "internal",
  },
  {
    title: "Hired Nurses",
    path: "hired",
  },
];

const NursesListLayout = () => {
  const [paginationTab, setPaginationTab] = useState(defaultPaginationTab);
  const [search, setSearch] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [forceReload, setForceReload] = useState(false);
  const [tabId, setTabId] = useState('internal');


  const tabTitle = () => {
    return 'Nurses List';
  };

  const handleSearch = debounce((value) => {
    setSearch(value.length === 0 ? '' : value);
  }, 500);

  const closeModal = (newNurseCreated) => {
    if (newNurseCreated) {
      setForceReload(true);
    }
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (forceReload) setForceReload(false);
  }, [forceReload]);

  return (
    <div className="app-main__outer">
      <div className="main-service-request-page">
        <div className="app-main__inner service-request bg-white page-heading">
          <h5>{tabTitle()}</h5>

          <div className="d-flex align-items-center justify-content-between mb-3 position-relative">
            <PaginationSummary listInfo={paginationTab} />
            {tabId === 'internal' && <div className=" position-absolute top-0 end-0">
              <button
                type="button"
                className="view_report d-flex btn-view-repo align-items-center gap-2 px-3 py-2"
                onClick={() => setIsModalOpen(true)}
              >
                <i className="bi bi-plus-lg"></i> Create New Nurse
              </button>
            </div>}
          </div>

          <div className="row align-items-center">
            <div className="col-sm-9 col-12">
              <ul className="nav nav-tabs p-1" id="myTab" role="tablist">
                {tabsInfoList.map((item, index) => {
                  return (
                    <li key={index} className="nav-item" role="presentation">
                      <NavLink
                        // className={`${
                        //   tabId === item.path ? "active" : ""
                        // } nav-link`}
                        className="nav-link tab-nav-link-style"
                        id={`${item.path}-tab`}
                        // data-bs-toggle="tab"
                        type="button"
                        role="tab"
                        // aria-controls="tab-1"
                        // aria-selected="true"
                        to={`/agency/nurses/list/${item.path}`}
                      >
                        {item.title}
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="col-sm-3 col-12">
              <div className="input-group input-search-djkds me-2 mb-3">
                <span className="input-group-append d-flex">
                  <button className="btn ms-n5" type="button">
                    <i className="fa fa-search"></i>
                  </button>
                </span>
                <input
                  className="form-control hhgrsjd bg-transparent border-0 border"
                  type="search"
                  placeholder="Search..."
                  onChange={({target}) => handleSearch(target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light">
        <div className="bg-light-gray px-3 py-3">
          <div className="row">
            <div className="container-fluid">
              <div className="tab-content" id="pills-tabContent">
                <Outlet context={{searchText: search, setPaginationTab, doReload: forceReload, setTabId}} />
              </div>
              {isModalOpen && (
                <AddNurseModal
                  isModalOpen={isModalOpen}
                  closeModal={(newNurseCreated) => closeModal(newNurseCreated)}
                  modalTitle="Add Nurse"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NursesListLayout;
