import React from "react";
import Table from "../../../../../../sharedComponents/table/Table";
import {useParams} from "react-router";
import {useSelector} from "react-redux";
import {Stack} from "@mui/material";
import classes from "../CareNote.module.css";
import {getDayName} from "./TasksInformation";
import signImage from "../../../../../assets/image/sign.svg";
import dateHelperFunctions from "../../../../../../helpers/date.helpers";
import {apiBaseUrl, noImagePath} from "../../../../../../appConstants";

const PrintCareNoteDetails = () => {

    const {tabId} = useParams();

    const careList = useSelector(state => state.client?.careList?.data || []);
    const viewCareListIndex = useSelector(state => state.client?.viewCareListIndex);

    const transformedArray = (careList?.rows?.[viewCareListIndex]?.tasks || [])?.map((item, index) => {
        let clientSignature = `${apiBaseUrl}${item?.patientSignatureDocumentLink ? `/agency${item?.patientSignatureDocumentLink}` : noImagePath}`;
        let nurseSignature = `${apiBaseUrl}${item?.nurseSignatureDocumentLink ? `/agency${item?.nurseSignatureDocumentLink}` : noImagePath}`;

        return {
            date: <Stack gap={'5px'}>
                <Stack className={classes.dayName}>
                    {getDayName(item?.date)}
                </Stack>
                <Stack className={classes.date}>
                    {item?.date}
                </Stack>
            </Stack>,
            nurse: item?.assignedNurseFullName,
            clock: `${dateHelperFunctions.convertTo12HourFormat(item?.clockIn)} - ${dateHelperFunctions.convertTo12HourFormat(item?.clockOut)}`,
            taskName: (item?.activities || [])?.map((value, index) => `${value?.category}, `),
            notes: <Stack className={classes.dailyNotesBox}> {item?.notes} </Stack>,
            nurseSignature: <img style={{height: '50px', width: '100px'}} src={nurseSignature} alt={''}/>,
            clientSignature: <img style={{height: '50px', width: '100px'}} src={clientSignature} alt={''}/>,
            RNSignature: item?.nurseNameForVerification ? item?.nurseNameForVerification : 'N/A',
        }
    });

    const serviceHeaderData = [
        {
            title: "Date",
            dataIndex: "date",
        },
        {
            title: "Nurse",
            dataIndex: "nurse",
        },
        {
            title: "Clock In/Clock Out",
            dataIndex: "clock",
        },
        {
            title: "Tasks Name and Category",
            dataIndex: "taskName",
        },
        {
            title: "Notes",
            dataIndex: "notes",
        },
        {
            title: "Nurse Signature",
            dataIndex: "nurseSignature",
        },
        {
            title: "Client Signature",
            dataIndex: "clientSignature",
        },
        {
            title: "RN Signature",
            dataIndex: "RNSignature",
        },
    ];


    return (
        <>
            <Table headers={serviceHeaderData} rows={transformedArray}/>
        </>
    )
};

export default PrintCareNoteDetails;
