import React, {useEffect, useRef, useState} from "react";
import moreVertical from "../../assets/image/more-vertical.svg";
import {Dialog, Modal, Popover, Stack} from "@mui/material";
import editIcon from "../../assets/image/edit.svg"
import shareIcon from "../../assets/image/share.svg"
import copyIcon from "../../assets/image/copy.svg"
import deleteIcon from "../../assets/image/mdi_delete.svg"
import ServiceRequestModal from "./ServiceRequestModal";
import {getChartingData} from "../../../services/charting";
import {HttpsAction} from "../../../commonApiFunction/httpsAction";
import CreateInitialAssessmentModal from "./CreateInitialAssessmentModal";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import {Form} from "react-formio";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import PrimaryButton from "../../../sharedComponents/buttons/PrimaryButton";
import {connect, useDispatch, useSelector} from "react-redux";
import {useReactToPrint} from "react-to-print";
import EditCharting from "../Client/ClientProfile/Charting/Edit";
import CustomDialog from "../../../sharedComponents/CustomDialog/index";

const MoreOptionDropdown = ({formDataId, /*formTemplatesList, nursesList, clientsList,*/ eachData, managingForServiceRequestType, agencyId}) => {
    const showAccessLevel = false;

    const printFormRef = useRef(null);
    const dropdownRef = useRef(null);

    const dispatch = useDispatch();

    const viewFormData = useSelector(state => state.charting?.viewForm?.data);
    const viewFormsData = useSelector(state => state.charting?.viewFormsData?.data);

    const [isDropdown, setIsDropdown] = useState(false);
    const [modal, setModal] = useState({
        createInitialAssessment: managingForServiceRequestType === 'initialAssessment',
        createSupervisoryVisit: managingForServiceRequestType === 'supervisoryVisits',
        createDischargeSummary: managingForServiceRequestType === 'dischargeSummaries',
        createCarePlan: managingForServiceRequestType === 'carePlans',
    });
    const [editModal, setEditModal] = useState(false);
    const [viewForm, setViewForm] = useState(false);
    const [editTemplateData, setEditTemplateData] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [menuOptions, setMenuOptions] = useState([]);

    const OPTIONS_ARRAY = [
        {
            icon: editIcon,
            key: 'edit',
            name: 'Edit Assessment'
        },
        {
            icon: shareIcon,
            key: 'create-sr',
            name: 'Create Service Request'
        },
        {
            icon: copyIcon,
            key: 'view-print',
            name: 'View / Print Form'
        },
        {
            icon: editIcon,
            key: 'edit-form',
            name: 'Edit Form'
        },
        {
            icon: deleteIcon,
            key: 'del',
            name: 'Delete'
        },
    ];


    const handleSelectOption = (key) => {
        setIsDropdown(false)
        switch (key) {
            case "create-sr":
                handleCreateServiceRequest(false);
                break;

            case "edit":
                handleEditCharting();
                break;

            case "view-print":
                handleViewForm();
                break;

            case "edit-form":
                handleEditForm();
                break;

            case "del":
                handleDeleteCharting();
                break;
        }
    };


    const getMenuOptionText = (key) => {
        switch (key) {
            case "create-sr":
                // return 'Create Service Request';
                return 'Create visit';

            case "edit":
                return managingForServiceRequestType === 'supervisoryVisits' ? 'Edit Supervisory Visit' : `Edit ${modalTitle.split(' ')[1]}`;

            case "view-print":
                return 'View / Print Form';

            case "edit-form":
                return 'Edit Form';

            case "del":
                return `Delete ${modalTitle.split(' ')[1]}`;
        }
    };

    const handleEditCharting = async () => {
        await dispatch(HttpsAction({
            apiSection: 'charting',
            apiName: 'chartingData',
            reducerName: 'CHARTING',
            queryParameter: `/${eachData.id}`,
            reduxKeyName: 'editInitialAssessmentData',
            actionType: 'GET_INITIAL_ASSESSMENT_BY_ID'
        }));
        setEditModal(true)
    };

    const handleEditForm = async () => {
        dispatch(HttpsAction({
            apiName: 'createTemplate',
            apiSection: 'forms',
            queryParameter: `/${eachData.formTemplateId}`,
            reduxKeyName: 'templateData',
            reducerName: 'CHARTING',
            actionType: 'GET_INITIAL_ASSESSMENT_FORM'
        }));
        await dispatch(HttpsAction({
            apiSection: 'forms',
            apiName: 'formsData',
            queryParameter: `/${eachData?.formDataId}`,
            reduxKeyName: 'formTemplateData',
            actionType: 'GET_FORM_TEMPLATE_BY_ID',
            reducerName: 'CHARTING'
        }));
        eachData['templateId'] = eachData.formTemplateId;
        setEditTemplateData(eachData)
    };

    const handleDeleteCharting = () => {
        dispatch(HttpsAction({
            method: 'DELETE',
            apiSection: 'charting',
            apiName: 'chartingData',
            queryParameter: `/${eachData.id}`,
            positiveCallBack: () => {
                dispatch(getChartingData({endPoint: managingForServiceRequestType}));
            }
        }));
    };


    const handleEditCloseModal = (dataModified) => {
        const dispatchType = `ADD_CHARTING_REMOVE_INITIAL_ASSESSMENT_DATA`;
        dispatch({
            type: dispatchType,
            payload: undefined,
            name: "editInitialAssessmentData",
            meta: {type: "REMOVE_INITIAL_ASSESSMENT_DATA"},
        });
        if (dataModified) {
            dispatch(getChartingData({endPoint: managingForServiceRequestType}));
        }
        setEditModal(false);
    };

    const handleCreateServiceRequest = (dataModified) => {
        setModal(pre => ({...pre, serviceRequest: !pre?.serviceRequest}));
        if (dataModified) {
            dispatch(getChartingData({endPoint: managingForServiceRequestType}));
        }
    };

    const handleViewForm = async () => {
        dispatch(HttpsAction({
            apiSection: 'forms',
            apiName: 'formsData',
            queryParameter: `/${eachData?.formDataId}`,
            reduxKeyName: 'viewFormsData',
            actionType: 'GET_FORM_FORM_DATA_BY_ID',
            reducerName: 'CHARTING'
        }));
        await dispatch(HttpsAction({
            apiName: 'createTemplate',
            apiSection: 'forms',
            queryParameter: `/${eachData.formTemplateId}`,
            reduxKeyName: 'viewForm',
            reducerName: 'CHARTING',
            actionType: 'GET_INITIAL_ASSESSMENT_FORM'
        }));
        setViewForm(true)
    };

    const handleCloseFormModal = () => {
        setViewForm(false);
        const dispatchType = `ADD_CHARTING_REMOVE_VIEW_FORM_DATA`;
        dispatch({
            type: dispatchType,
            payload: undefined,
            name: "viewForm",
            meta: {type: "REMOVE_VIEW_FORM_DATA"},
        });
    };

    const handlePrint = useReactToPrint({
        content: () => printFormRef.current,
        onAfterPrint: () => {
            // Close the modal after printing or cancelling the PDF
            handleCloseFormModal()
        }
    });

    const handleModal = (dataModified) => {
        setEditTemplateData(false);
        if (dataModified) {
            dispatch(getChartingData({endPoint: managingForServiceRequestType}));
        }
    };

    useEffect(() => {
        if (managingForServiceRequestType != null && managingForServiceRequestType.length > 0) {
            if (managingForServiceRequestType === 'initialAssessment') setModalTitle('Initial Assessment');
            if (managingForServiceRequestType === 'supervisoryVisits') setModalTitle('Supervisory Visit');
            if (managingForServiceRequestType === 'dischargeSummaries') setModalTitle('Discharge Summary');
            if (managingForServiceRequestType === 'carePlans') setModalTitle('Care Plan');
        }
    }, [managingForServiceRequestType]);

    useEffect(() => {
        if (eachData.serviceRequestId == null) {
            setMenuOptions(OPTIONS_ARRAY);
        } else {
            setMenuOptions(OPTIONS_ARRAY.filter(item => item.key !== 'create-sr'));
        }
    }, []);

    return (
        <>
            <Stack ref={dropdownRef}>
                <div style={{cursor: 'pointer', height: 28.8, width: 28.8}} onClick={() => setIsDropdown(true)}>
                    <img src={moreVertical} alt={''}/>
                </div>
                <Popover
                    open={isDropdown}
                    anchorEl={dropdownRef.current}
                    onClose={() => setIsDropdown(false)}
                    anchorOrigin={{
                        vertical: 'bottom',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    PaperProps={{
                        style: {
                            boxShadow: 'unset',
                            width: '296px',
                            height: 'auto',
                            borderRadius: '5px',
                            marginTop: '5px',
                            border: '1px solid #D5D5D5'
                        },
                    }}
                >
                    <Stack sx={{padding: '10px 20px'}}>
                        <Stack sx={{fontFamily: 'Poppins', fontSize: '18px', fontWeight: 500}}>
                            More Options
                        </Stack>
                        <Stack sx={{gap: '10px', marginTop: '5px'}}>
                            {(menuOptions || [])?.map((v, i) => (
                                <Stack
                                    sx={{flexDirection: 'row', gap: '10px', alignItems: 'center', cursor: 'pointer'}}
                                    key={i}
                                    onClick={() => handleSelectOption(v.key)}>
                                    <Stack>
                                        <img src={v.icon} alt={''}/>
                                    </Stack>
                                    <Stack
                                        sx={{
                                            fontFamily: 'Poppins',
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            color: '#73737C'
                                        }}>
                                        {getMenuOptionText(v.key)}
                                    </Stack>
                                </Stack>
                            ))}
                        </Stack>

                    </Stack>
                </Popover>
            </Stack>

            <CustomDialog
                open={modal?.serviceRequest}
                onClose={() => handleCreateServiceRequest(false)}
                // title={'Visit Information (' + modalTitle + ')'}
                title={<><span className="fw-bolder">Visit Information</span>&nbsp;<span className="fst-italic">&#x28;{modalTitle}&#x29;</span></>}
                maxWidth={'sm'}>
                <ServiceRequestModal creatingForServiceRequestType={managingForServiceRequestType} eachData={eachData} onClose={(dataModified) => handleCreateServiceRequest(dataModified)}/>
            </CustomDialog>

            <CustomDialog
                open={editModal}
                onClose={() => handleEditCloseModal(false)}
                title={'Edit ' + modalTitle}
                maxWidth={showAccessLevel ? 'sm' : false}
                customWidth={showAccessLevel ? false : '380px'}
            >
                <CreateInitialAssessmentModal
                  creatingForInitialAssessment={modal.createInitialAssessment || false}
                  creatingForSupervisoryVisit={modal.createSupervisoryVisit || false}
                  creatingForDischargeSummary={modal.createDischargeSummary || false}
                  creatingForCarePlan={modal.createCarePlan || false}
                  isOpen={modal.createInitialAssessment || modal?.createSupervisoryVisit || modal?.createDischargeSummary || modal?.createCarePlan}
                  isEdit={true} onClose={(dataModified) => handleEditCloseModal(dataModified)} agencyId={agencyId}
                  showAccessLevel={showAccessLevel}
                />
            </CustomDialog>

            <CustomDialog
                open={viewForm}
                onClose={() => handleCloseFormModal()}
                title={'Form (' + modalTitle + ')'}
                maxWidth={'lg'}
                actions={
                    <div className="d-flex justify-content-center" style={{marginBottom: "20px"}}>
                        <PrimaryButton
                            type="submit"
                            className="btn-view-repo gap-2 px-5 py-2"
                            text={"Print"}
                            onClick={() => handlePrint()}
                        />
                    </div>
                }
            >
                <div ref={printFormRef} className={'p-4'}>
                    {viewFormData?.components ?
                        <Form
                            form={{components: viewFormData?.components || []}}
                            submission={{data: viewFormsData?.data}}
                        />
                        :
                        <h4>
                            No Form
                        </h4>
                    }
                </div>
            </CustomDialog>

            <CustomDialog
                open={editTemplateData}
                onClose={() => handleModal(false)}
                title={'Edit Form (' + modalTitle + ')'}
                maxWidth={'lg'}>
                <EditCharting editTemplateData={editTemplateData} onClose={(dataModified) => handleModal(dataModified)}/>
            </CustomDialog>
        </>
    )
};


const mapStateToProps = (state) => {
    const {common, auth} = state;
    return {
        agencyId: auth?.authData?.agencyId || auth.agencyId,
    };
};

export default connect(mapStateToProps)(MoreOptionDropdown);
