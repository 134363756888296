import React, { useState, useEffect, useContext } from "react";
import {useLocation, useNavigate} from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import {addAuth, doVerifyEmail, generateVerificationEmail} from "../services/Auth";
import CustomInputField from "../sharedComponents/fields/customInputField";

import img1 from "./assets/image/agency-signup.png";
import img2 from "./assets/image/logo.png";
import { redirectOrLogout } from "../helpers/apiMethods";
import {toastr} from "react-redux-toastr";
import {agencyFirstPagePathAfterLogin, agencyVerifyEmailPage} from "../data/routes.values";

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

const AgencyLoginPage = ({ agencyLoginData, generateVerifyEmail }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const routeLocaton = useLocation();

  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState({
    email: "",
    password: "",
  });

  const passwordToggle = () => {
    setPasswordVisible((prevVisible) => !prevVisible);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    let errors = formErrors;

    switch (name) {
      case "email":
        errors.email = validEmailRegex.test(value) ? "" : "Email is not valid!";
        break;
      case "password":
        errors.password =
          value.length < 8
            ? "Password must be at least 8 characters long!"
            : "";
        break;
      default:
        break;
    }
    setFormErrors({ ...errors });
    setLoginData({ ...loginData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    agencyLoginData(loginData).then((res) => {
      if (res?.success) {
        // navigate(agencyFirstPagePathAfterLogin, { replace: true });
        setTimeout(navigate, 0, agencyFirstPagePathAfterLogin, { replace: true, reloadDocument: true });
        window.location.reload(true);
      } else {
        if (res?.message != null) {
          const msg = res.message;
          if (msg.indexOf('account is inactive') > -1) {
            generateVerifyEmail(loginData.email).then(() => {
              toastr.success('A passcode to your email has been sent');
              // navigate(agencyVerifyEmailPage, { replace: true });
              setTimeout(navigate, 0, agencyVerifyEmailPage, {
                replace: true,
                reloadDocument: true,
              });
              window.location.reload(true);
            });
          } else {
            // toastr.warning('Contact administrator to activate your account');
            toastr.error(res.message);
          }
        }
      }
    });
  };

  useEffect(() => {
    if (routeLocaton != null && routeLocaton.state != null) {
      if (routeLocaton.state.reload) {
        navigate('/', {
          replace: true,
        });
      }
    }
  }, [routeLocaton]);

  useEffect(() => {
    redirectOrLogout(dispatch, navigate);
  }, []);

  return (
    // <div className="login-page">
    <main className="main-wrapper">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-sm-12 col-lg-12" style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh'
          }}>
            <div className="login-form-area main-login w-100">
              <div className="col-12 col-sm-12 text-center login-logo-area">
                <img className="login-logo img-fluid" src={img2}/>
              </div>

              <form>
                <h1>Login</h1>
                <div className="col-12 px-0">
                  <div className="form-group">
                    <CustomInputField
                      type="email"
                      className="form-control"
                      placeholder="Email Address"
                      required
                      name="email"
                      onChange={handleChange}
                    />
                    {formErrors.email ? (
                      <span className="validation">
                        <span className="iconWarning"></span>
                        {formErrors.email}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="col-12 px-0 position-relative">
                  <div className="form-group d-flex">
                    <CustomInputField
                      id="password-field"
                      type={isPasswordVisible ? "text" : "password"}
                      autoComplete="off"
                      className="form-control"
                      placeholder="Password"
                      required
                      name="password"
                      onChange={handleChange}
                    />
                    <div
                      style={{display: isPasswordVisible ? 'none' : 'block'}}
                      toggle="#password-field"
                      onClick={() => setPasswordVisible(!isPasswordVisible)}
                    >
                      <span className="toggle-password cursor-pointer fa fa-fw field-icon fa-eye"></span>
                    </div>
                    <div
                      style={{display: !isPasswordVisible ? 'none' : 'block'}}
                      toggle="#password-field"
                      onClick={() => setPasswordVisible(!isPasswordVisible)}
                    >
                      <span className="toggle-password cursor-pointer fa fa-fw field-icon fa-eye-slash"></span>
                    </div>
                  </div>
                </div>
                <div className="col-12 px-0">
                  <div className="form-group">
                    <button
                      onClick={(e) => {
                        handleSubmit(e);
                      }}
                      className="btn btn-primary w-100 mb-4 mt-3"
                      type="submit"
                    >
                      Login
                    </button>
                  </div>
                </div>
              </form>
              <div className="col-12 px-0 text-center">
                <a href="#" className="forgot-pass">
                  Forgot Password?
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    // </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    agencyLoginData: (dataToSend) =>
      dispatch(
        addAuth({
          dataToSend,
          userType: 'agency',
        })
      ),
    generateVerifyEmail: (email) => dispatch(generateVerificationEmail({email})),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AgencyLoginPage);
