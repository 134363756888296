import React, {useEffect, useState} from "react";
import {Field, reduxForm} from "redux-form";
import CustomInputField from "../../../../sharedComponents/reduxfields/customInputField";
import CustomDropDownField from "../../../../sharedComponents/reduxfields/customDropDown";
import CustomRadioGroup from "../../../../sharedComponents/reduxfields/customRadioGroup";
import CheckboxGroup from "../../../../sharedComponents/reduxfields/customCheckboxGroup"
import {required} from '../../../../helpers/validation'
import {connect, useDispatch} from 'react-redux';
import {change} from 'redux-form';
import {registerField, unregisterField} from 'redux-form';

const ratesRadioOptions = [
    {label: 'Base', id: "Base", value: '1'},
    {label: 'Auto', id: "Auto", value: '2'}
];

const chargeMethod = [
    {label: 'Per Hour', id: "Per_Hour", value: '1'},
    {label: 'Per Visit', id: "Per_Visit", value: '2'},
    {label: 'Per Day', id: "Per_Day", value: '3'}
];

// const sriFormVarName = 'editServiceRequestInformationForm';
const sriFormVarName = 'editRequestForm';

const EditServiceRequestInformationTab = ({getRatesValue, getChargeMethod, setRate, RatesValue, chargeValue, initialValues, agencyId}) => {

    const dispatch = useDispatch();

    useEffect(() => {
        //dispatch(change(sriFormVarName, 'nurseOvertimeRate', '', false));
        if (RatesValue === '2' && chargeValue === '1') {
            dispatch(registerField(sriFormVarName, 'nurseOvertimeRate'));
        } else {
            dispatch(unregisterField(sriFormVarName, 'nurseOvertimeRate'));
        }
    }, [RatesValue])

    // useEffect(() => {
    //     if (initialValues != null && JSON.stringify(initialValues) !== '{}') {
    //         // dispatch(change(sriFormVarName, 'agencyRateToClient', initialValues.agencyRateToClient));
    //         // dispatch(change(sriFormVarName, 'nurseRate', initialValues.nurseRate));
    //         // setRate('agencyRateToClient', initialValues.agencyRateToClient);
    //         // setRate('nurseRate', initialValues.nurseRate);
    //         if (initialValues.nurseOvertimeRate != null && initialValues.nurseOvertimeRate.length > 0 && initialValues.payRateTypeId === '1') {
    //             // setRate('nurseOvertimeRate', initialValues.nurseOvertimeRate);
    //             dispatch(registerField(sriFormVarName, 'nurseOvertimeRate'));
    //             // dispatch(change(sriFormVarName, 'nurseOvertimeRate', initialValues.nurseOvertimeRate));
    //         }
    //
    //         // setRateMode(initialValues.ratesSelection);
    //         // setPayRateType(initialValues.payRateTypeId);
    //         // dispatch(change(sriFormVarName, 'ratesSelection', initialValues.ratesSelection));
    //         // dispatch(change(sriFormVarName, 'payRateTypeId', initialValues.payRateTypeId));
    //     }
    // }, [initialValues]);

    return (
        <div className="col-12 mb-5" style={{maxWidth: "900px"}}>
            <div className="row">
                <div className="tab-inner-heading">Service Report Information</div>
                <div className="neo1 col-12 col-xl-9 py-2 px-4 m-0 border border-black rounded-4 border-opacity-10">
                    {/*<div className="col-12 col-sm-6">*/}
                    {/*    <Field*/}
                    {/*        className="form-control"*/}
                    {/*        label="Select a service Request"*/}
                    {/*        name="serviceRequestTypeId"*/}
                    {/*        component={CustomDropDownField}*/}
                    {/*        validate={required}*/}
                    {/*        requiredMark={true}*/}
                    {/*    >*/}
                    {/*        <option value="">Please Select</option>*/}
                    {/*        {serviceListDP?.map((res, index) => {*/}
                    {/*            return <option key={index} value={res.id}>{res.name}</option>*/}
                    {/*        })}*/}
                    {/*    </Field>*/}
                    {/*</div>*/}
                    <div className="col-12 col-sm-12 w-100 mxt-3">
                        <label>Rate Type <span className="mandatory-field">*</span></label>
                        {/*<h3 className="form-inner-heading">Rates</h3>*/}
                        <Field
                            name="ratesSelection"
                            component={CustomRadioGroup}
                            options={ratesRadioOptions}
                            requiredMark={false}
                            onChange={(e) => getRatesValue(e)}
                        />
                    </div>
                    <div className="row">
                        <div className="col-12 col-sm-4">
                            <Field
                                className="form-control mk-wi"
                                name="agencyRateToClient"
                                label="Agency rate to clients"
                                type="number"
                                component={CustomInputField}
                                requiredMark={true}
                                validate={required}
                                min="0"
                                onChange={(e) => setRate('agencyRateToClient', e.target.value)}
                            ></Field>
                        </div>
                        <div className="col-12 col-sm-4">
                            <Field
                                className="form-control mk-wi"
                                name="nurseRate"
                                label="Nurse Rate"
                                type="number"
                                component={CustomInputField}
                                requiredMark={true}
                                validate={required}
                                min="0"
                                onChange={(e) => setRate('nurseRate', e.target.value)}
                            ></Field>
                        </div>
                        <div className="col-12 col-sm-4">
                            {
                                RatesValue === '2' && chargeValue === '1' && (
                                    <Field
                                        className="form-control mk-wi"
                                        name="nurseOvertimeRate"
                                        label="Over Time Rate"
                                        type="number"
                                        component={CustomInputField}
                                        requiredMark={true}
                                        validate={required}
                                        min="0"
                                        onChange={(e) => setRate('nurseOvertimeRate', e.target.value)}
                                    ></Field>
                                )
                            }
                            {/* <Field
               className="form-control mk-wi"
               name="nurseOvertimeRate"
               label="Over Time Rate"
               type="number"
               component={CustomInputField}
               requiredMark={RatesValue === '2' && chargeValue === '1' ? true : false}
               validate={RatesValue === '2' && chargeValue === '1' ? required : undefined}
               min="0"
               disabled={RatesValue === '2' && chargeValue === '1' ? false : true}
               value={'10'}
               ></Field> */}
                        </div>
                    </div>
                    <div className="col-12">
                        <label>Employment Type <span className="mandatory-field">*</span></label>
                        <Field
                            name="payRateTypeId"
                            component={CustomRadioGroup}
                            options={chargeMethod}
                            requiredMark={false}
                            onChange={(e) => getChargeMethod(e)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {

    const data = state.serviceRequest?.serviceDetails?.data;

    return {
        initialValues: {
            ratesSelection: (data?.nurseOvertimeRate == null || data?.nurseOvertimeRate === 0) ? "1" : "2",
            agencyRateToClient: data?.agencyRateToClient?.toString() || '',
            nurseRate: data?.nurseRate?.toString() || '',
            nurseOvertimeRate: data?.nurseOvertimeRate?.toString() || '',
            payRateTypeId: data?.payRateTypeId?.toString() || '',
        }
    }
};

export default connect(mapStateToProps)(
    reduxForm({form: sriFormVarName, enableReinitialize: true})(EditServiceRequestInformationTab)
);

