import {toastr} from "react-redux-toastr";

import getApiUrl from "../../../helpers/apiURLs.js";
import api from "../../../helpers/interceptor.js";
import {
    ADMIN_NURSE_LIST,
    ADMIN_VERIFIED_NURSE_LIST,
    ADMIN_UNVERIFIED_NURSE_LIST,
    GET_ADMIN_ALL_VERIFIED_NURSE_LIST,
    GET_ADMIN_ALL_UNVERIFIED_NURSE_LIST,
    GET_ADMIN_ALL_WITHDRAWAL_REQUEST_LIST,
    GET_ADMIN_ALL_NURSE_LIST,
    ADMIN_NURSES_SUMMARY,
    ADMIN_AGENCIES_SUMMARY,
    ADMIN_AGENCIES_LIST,
    GET_ADMIN_ALL_AGENCIES_LIST,
    ADMIN_VERIFIED_AGENCIES_LIST,
    GET_ADMIN_ALL_VERIFIED_AGENCIES_LIST, ADMIN_UNVERIFIED_AGENCIES_LIST, GET_ADMIN_ALL_UNVERIFIED_AGENCIES_LIST,
} from "../types.js";
import * as commonServices from "../../../services/commonServices.js";
import {ADMIN_WITHDRAWAL_REQUEST_LIST} from "../types";
import {defaultErrorMessage} from "../../../appConstants";

export const getNurseList = (type, from, to) => {
    return async (dispatch) => {
        dispatch(commonServices.setLoader(true));
        let url = "";
        switch (type) {
            case "nurse-list":
                url = getApiUrl("admin", "nurseList");
                break;
            case "nurse-list-date-filter":
                url = `${getApiUrl("admin", "nurseList")}?fromDate=${from}&toDate=${to}`;
                break;
            case "verified-nurse-list":
                // url = getApiUrl("admin", "verifiedNurseList");
                url = `${getApiUrl("admin", "nurseList")}?verified=true`;
                break;
            case "verified-nurse-list-date-filter":
                // url = `${getApiUrl("admin", "verifiedNurseList")}&fromDate=${from}&toDate=${to}`;
                url = `${getApiUrl("admin", "nurseList")}?verified=true&fromDate=${from}&toDate=${to}`;
                break;
            case "unverified-nurse-list":
                // url = getApiUrl("admin", "notVerifiedNurseList");
                url = `${getApiUrl("admin", "nurseList")}?verified=false`;
                break;
            case "unverified-nurse-list-date-filter":
                // url = `${getApiUrl("admin", "notVerifiedNurseList")}&fromDate=${from}&toDate=${to}`;
                url = `${getApiUrl("admin", "nurseList")}?verified=false&fromDate=${from}&toDate=${to}`;
                break;
            case "withdrawal-request-list":
                url = getApiUrl("admin", "withdrawalRequest");
                break;
            case "withdrawal-request-list-date-filter":
                url = `${getApiUrl("admin", "withdrawalRequest")}?fromDate=${from}&toDate=${to}`;
                break;
            default:
                break;
        }
        try {
            const response = await api.get(url);
            if (!response.success) {
                dispatch(commonServices.setLoader(false));
                return;
            }
            switch (type) {
                case "nurse-list":
                    dispatch({type: ADMIN_NURSE_LIST, payload: response?.data});
                    break;
                case "nurse-list-date-filter":
                    dispatch({type: ADMIN_NURSE_LIST, payload: response?.data?.rows});
                    break;
                case "verified-nurse-list-date-filter":
                    dispatch({type: ADMIN_NURSE_LIST, payload: response?.data?.rows});
                    break;
                case "verified-nurse-list":
                    dispatch({type: ADMIN_VERIFIED_NURSE_LIST, payload: response?.data});
                    break;
                case "unverified-nurse-list":
                    dispatch({type: ADMIN_UNVERIFIED_NURSE_LIST, payload: response?.data});
                    break;
                case "unverified-nurse-list-date-filter":
                    dispatch({type: ADMIN_UNVERIFIED_NURSE_LIST, payload: response?.data?.rows});
                    break;
                case "withdrawal-request-list":
                    dispatch({type: ADMIN_WITHDRAWAL_REQUEST_LIST, payload: response?.data});
                    break;
                case "withdrawal-request-list-date-filter":
                    dispatch({type: ADMIN_WITHDRAWAL_REQUEST_LIST, payload: response?.data?.rows});
                    break;
                default:
                    break;
            }
            dispatch(commonServices.setLoader(false));
            return response;
        } catch (err) {
            dispatch(commonServices.setLoader(false));
            toastr.error(defaultErrorMessage, "");
        }
    };
};

export const getNursesData = (type, page = 1, qsFilter = '', firstLoad = 'false') => {
    return async (dispatch) => {
        dispatch(commonServices.setLoader(true));
        let url = "";
        switch (type) {
            case "nurses-summary":
                url = getApiUrl("admin", "nursesSummary") + '?v=1';
                break;
            case "nurse-list":
            case "nurse-list-export":
                url = getApiUrl("admin", "nurseList") + '?v=1';
                break;
            case "verified-nurse-list":
            case "verified-nurse-list-export":
                // url = getApiUrl("admin", "verifiedNurseList");
                url = getApiUrl("admin", "nurseList") + '?verified=true';
                break;
            case "unverified-nurse-list":
            case "unverified-nurse-list-export":
                // url = getApiUrl("admin", "notVerifiedNurseList");
                url = getApiUrl("admin", "nurseList") + '?verified=false';
                break;
            case "withdrawal-request-list":
            case "withdrawal-request-list-export":
                url = getApiUrl("admin", "withdrawalRequest") + '?v=1';
                break;
            default:
                break;
        }
        // if (page || qsFilter) {
        //   url += '?x=1'; // placeholder query string variable pair
        // }
        if (firstLoad === 'true') {
            url += `&firstLoad=${firstLoad}`;
        }
        if (page) {
            url += `&page=${page}`;
        }
        if (qsFilter) {
            url += `&${qsFilter}`;
        }
        try {
            const response = await api.get(url);
            if (!response.success) {
                dispatch(commonServices.setLoader(false));
                return;
            }
            switch (type) {
                case "nurses-summary":
                    dispatch({type: ADMIN_NURSES_SUMMARY, payload: response?.data});
                    break;
                case "nurse-list":
                    dispatch({type: ADMIN_NURSE_LIST, payload: response?.data});
                    break;
                case "nurse-list-export":
                    dispatch({type: GET_ADMIN_ALL_NURSE_LIST, payload: response?.data});
                    break;
                case "verified-nurse-list":
                    dispatch({type: ADMIN_VERIFIED_NURSE_LIST, payload: response?.data});
                    break;
                case "verified-nurse-list-export":
                    dispatch({type: GET_ADMIN_ALL_VERIFIED_NURSE_LIST, payload: response?.data});
                    break;
                case "unverified-nurse-list":
                    dispatch({type: ADMIN_UNVERIFIED_NURSE_LIST, payload: response?.data});
                    break;
                case "unverified-nurse-list-export":
                    dispatch({type: GET_ADMIN_ALL_UNVERIFIED_NURSE_LIST, payload: response?.data});
                    break;
                case "withdrawal-request-list":
                    dispatch({type: ADMIN_WITHDRAWAL_REQUEST_LIST, payload: response?.data});
                    break;
                case "withdrawal-request-list-export":
                    dispatch({type: GET_ADMIN_ALL_WITHDRAWAL_REQUEST_LIST, payload: response?.data});
                    break;
                default:
                    break;
            }
            dispatch(commonServices.setLoader(false));
            return response;
        } catch (err) {
            dispatch(commonServices.setLoader(false));
            toastr.error(defaultErrorMessage, "");
        }
    };
};

export const getAgenciesData = (type, page = 1, qsFilter = '', firstLoad = 'false') => {
    return async (dispatch) => {
        dispatch(commonServices.setLoader(true));
        let url = "";
        switch (type) {
            case "agencies-summary":
                url = getApiUrl("admin", "agenciesSummary") + '?v=1';
                break;
            case "agencies-list":
            case "agencies-list-export":
                url = getApiUrl("admin", "agencyList") + '?v=1';
                break;
            case "verified-agencies-list":
            case "verified-agencies-list-export":
                url = getApiUrl("admin", "agencyList") + '?verified=true';
                break;
            case "unverified-agencies-list":
            case "unverified-agencies-list-export":
                url = getApiUrl("admin", "agencyList") + '?verified=false';
                break;
            default:
                break;
        }
        if (firstLoad === 'true') {
            url += `&firstLoad=${firstLoad}`;
        }
        if (page) {
            url += `&page=${page}`;
        }
        if (qsFilter) {
            url += `&${qsFilter}`;
        }
        try {
            const response = await api.get(url);
            if (!response.success) {
                dispatch(commonServices.setLoader(false));
                return;
            }
            switch (type) {
                case "agencies-summary":
                    dispatch({type: ADMIN_AGENCIES_SUMMARY, payload: response?.data});
                    break;
                case "agencies-list":
                    dispatch({type: ADMIN_AGENCIES_LIST, payload: response?.data});
                    break;
                case "agencies-list-export":
                    dispatch({type: GET_ADMIN_ALL_AGENCIES_LIST, payload: response?.data});
                    break;
                case "verified-agencies-list":
                    dispatch({type: ADMIN_VERIFIED_AGENCIES_LIST, payload: response?.data});
                    break;
                case "verified-agencies-list-export":
                    dispatch({type: GET_ADMIN_ALL_VERIFIED_AGENCIES_LIST, payload: response?.data});
                    break;
                case "unverified-agencies-list":
                    dispatch({type: ADMIN_UNVERIFIED_AGENCIES_LIST, payload: response?.data});
                    break;
                case "unverified-agencies-list-export":
                    dispatch({type: GET_ADMIN_ALL_UNVERIFIED_AGENCIES_LIST, payload: response?.data});
                    break;
                default:
                    break;
            }
            dispatch(commonServices.setLoader(false));
            return response;
        } catch (err) {
            dispatch(commonServices.setLoader(false));
            toastr.error(defaultErrorMessage, "");
        }
    };
};
