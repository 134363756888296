import React from "react";
import classes from "./index.module.css";

const CommonList = ({index, showDetails = false, handleSeeDetails = () => undefined, title = '', message = "", date = "", time = ""}) => {

    return (
        <>
            <div className={classes.listBox}>
                <div className={'w-100 d-flex flex-row gap-3'}>
                    <div className="w-max">
                        <div className={classes.darkCircle}>
                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M20.25 6.60938H16.25V4.60938C16.25 3.49937 15.36 2.60938 14.25 2.60938H10.25C9.14 2.60938 8.25 3.49937 8.25 4.60938V6.60938H4.25C3.14 6.60938 2.26 7.49937 2.26 8.60938L2.25 19.6094C2.25 20.7194 3.14 21.6094 4.25 21.6094H20.25C21.36 21.6094 22.25 20.7194 22.25 19.6094V8.60938C22.25 7.49937 21.36 6.60938 20.25 6.60938ZM14.25 6.60938H10.25V4.60938H14.25V6.60938Z"
                                    fill="white"/>
                            </svg>
                        </div>
                    </div>
                    <div className={'flex-grow-1 d-flex flex-column gap-2'}>
                        <div className={classes.listTitle} style={{display: 'flex', justifyContent: 'space-between'}}>
                            <span>{title}</span>
                            <span className="" style={{fontSize: '13px'}}>&#x28;{date} &#8212; {time}&#x29;</span>
                        </div>
                        <div className={classes.listDesc}>
                            {/*<p dangerouslySetInnerHTML={{__html: message.replace(/(?:\r\n|\r|\n)/g, '<br>')}}/>*/}
                            <div className="d-flex justify-content-between gap-1">
                                <a className={classes.listSeeDetails} data-bs-toggle="collapse" href={`#collapseAlertDetails-${index}`} role="button" aria-expanded="false"
                                   aria-controls="collapseAlertDetails">
                                    View More
                                </a>
                                {showDetails && <div className={classes.listSeeDetails} onClick={handleSeeDetails}>
                                    See Details
                                </div>}
                            </div>
                            <div className="collapse" id={`collapseAlertDetails-${index}`}>
                                <div className="card card-body text-style-1">
                                    <p dangerouslySetInnerHTML={{__html: message.replace(/(?:\r\n|\r|\n)/g, '<br>')}}/>
                                </div>
                            </div>
                        </div>
                        {/*{showDetails && <div className={classes.listSeeDetails} onClick={handleSeeDetails}>*/}
                        {/*    See Details*/}
                        {/*</div>}*/}
                    </div>
                </div>
            </div>
        </>
    )
};

export default CommonList;
