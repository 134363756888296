import React from 'react';

const SaveButton = ({pristine, submitting, onClick, useOtherClass = true}) => {
// console.log('test- touched', pristine, submitting)
  const btnClasses = useOtherClass ? 'btn btn-view-repo gap-2 px-5 py-2' : 'btn btn-primary btn-lg';
  return (
    <div className="col-md-12">
      <div className="d-flex justify-content-center pt-5">
        <button type="submit" className={btnClasses} disabled={pristine || submitting}
                onClick={() => onClick != null ? onClick() : undefined}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default SaveButton;
