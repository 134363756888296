import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {useDispatch} from "react-redux";
import {useNavigate, useOutletContext} from "react-router-dom";
import Table from "../../../sharedComponents/table/Table";
import {getNurseAction} from "../../../services/NursesProfile";
import {HttpsAction} from "../../../commonApiFunction/httpsAction";
import * as nurseAction from "./nurseActionTypes";
import CommonPaginationNew from "../../../sharedComponents/pagination/CommonPaginationNew";

const HiredNurses = ({hiredNursesList/*, currentPage, pageLimit, forceReload*/}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { searchText, setPaginationTab, forceReload, setTabId } = useOutletContext();

    const [currentPage, setCurrentPage] = useState(1);

    const transformedArray = (hiredNursesList?.rows || [])?.map((item) => {
        // console.log('item', item)
        return {
            id: {id: item.id, chatId: item.nursePersonalInfoId, firstName: item.nurseFirstName, lastName: item.nurseLastName, isOnline: item.isOnline},
            // preferredRate: item.preferredRate || 'N/A',
            name: `${item?.nurseFullName}`,
            phone: item?.primaryPhone || 'N/A',
            emailAddress: item?.primaryEmail || 'N/A',
            profession: item?.profession || 'N/A',
            address: item?.nurseAddress?.nurseAddressLine1 || 'N/A',
            hiredDate: item?.hireDateFormatUs || 'N/A',
            // createdDate: item?.nurseCreatedAtFormatUs || 'N/A',
        };
    });

    const serviceHeaderData = [
        {
            title: "Name",
            dataIndex: "name",
        },
        {
            title: "Address",
            dataIndex: "address",
        },
        {
            title: "Phone",
            dataIndex: "phone",
        },
        {
            title: "Profession",
            dataIndex: "profession",
        },
        {
            title: "Hired Date",
            dataIndex: "hiredDate",
        }
    ];

    const actionButton = [
        {
            actionName: "View Profile",
            buttonAction: (specificData) => {
                navigate(`/nurse/detail/${specificData.id}`, {
                    state: {
                        showBackButton: true,
                        backButtonTextSuffix: 'Hired Nurses',
                        backLink: '/agency/nurses/hiredNurses',
                    }
                });
            },
            actionClass: "btn-primary",
            iconClass: "fa-user-plus",
        },
    ];

    function loadData() {
        dispatch(HttpsAction({
            apiSection: 'findNurse',
            apiName: 'nurseList',
            queryParameter: `?page=${currentPage}&freelanceNurses=true`,
            reduxKeyName: 'hiredNurses',
            reducerName: 'NURSE',
            actionType: nurseAction.GET_HIRED_NURSES,
            dataDispatch: true,
        }));
    }

    useEffect(() => {
        if (hiredNursesList != null && hiredNursesList?.rows?.length != null) {
            setPaginationTab(hiredNursesList);
        }
    }, [hiredNursesList]);

    useEffect(() => {
        if (currentPage > 0) loadData();
    }, [currentPage]);

    useEffect(() => {
        if (forceReload) {
            loadData();
        }
    }, [forceReload]);

    useEffect(() => {
        if (setTabId) setTabId('hired');
    }, []);

    return (
        <div className="bg-white mt-3">
            <Table
                headers={serviceHeaderData}
                rows={transformedArray}
                actions={actionButton}
            />
            <CommonPaginationNew data={hiredNursesList} onPageChange={(page) => setCurrentPage(page)} />
        </div>
    );
};

const mapStateToProps = (state) => {
    const { nurseListing: { hiredNurses } } = state;
    return {
        hiredNursesList: hiredNurses || {},
    };
};

export default connect(mapStateToProps)(HiredNurses);
