import React, {useEffect, useRef, useState} from "react";
import moreVertical from "../../assets/image/more-vertical.svg";
import {Dialog, Modal, Popover, Stack} from "@mui/material";
import {connect, useDispatch, useSelector} from "react-redux";
import rightArrowFat from "../../assets/image/right-arrow-fat.svg"
import {useNavigate} from "react-router-dom";
import getApiUrl from "../../../helpers/apiURLs";
import {pageLimit} from "../../../appConstants";
import * as serviceRequest from "./serviceActionTypes";
import EditRequest from "./EditRequest";
import CustomDialog from "../../../sharedComponents/CustomDialog/index";
import {getAllServiceRequest} from "../../../services/serviceRequest";
import {HttpsAction} from "../../../commonApiFunction/httpsAction";
import AssignNurseToServiceRequestModal from "./assignNurseToServiceRequestModal";
import Swal from "sweetalert2";
import AddNewJobServiceRequest from "./NewServiceRequest";

const OPTIONS_ARRAY = [
    {
        icon: rightArrowFat,
        key: 'edit',
        name: 'Edit service request'
    },
    {
        icon: rightArrowFat,
        key: 'assign',
        name: 'Assign Nurse'
    },
    {
        icon: rightArrowFat,
        key: 'view',
        name: 'View'
    },
    {
        icon: rightArrowFat,
        key: 'delete',
        name: 'Delete service request'
    },
];

const SUB_OPTIONS_ARRAY = [
    {
        icon: rightArrowFat,
        key: 'scheduled',
        name: 'View scheduled visits'
    },
    {
        icon: rightArrowFat,
        key: 'active',
        name: 'View active visits'
    },
    // {
    //     icon: rightArrowFat,
    //     key: 'pending',
    // },
    {
        icon: rightArrowFat,
        key: 'completed',
        name: 'View completed visits'
    },
    {
        icon: rightArrowFat,
        key: 'missed',
        name: 'View missed visits'
    },
];


const MoreOptionDropdown = ({serviceRequestId, nursesAssigned, jobRelated, loadMethod, totalAmountReserved, amountReserved}) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getServiceRequestById = useSelector(state => state.serviceRequest?.serviceDetails?.data || null);
    const isServiceRequestEdit = getServiceRequestById != null;
    // const amountReserved = getServiceRequestById?.amountReservedCalculated != null ? getServiceRequestById?.amountReservedCalculated : -1;

    const dropdownRef = useRef(null);
    const subDropdownRef = useRef(null);

    const [isDropdown, setIsDropdown] = useState(false);
    const [isSubDropdown, setIsSubDropdown] = useState(false);
    const [showEditServiceRequestModal, setShowEditServiceRequestModal] = useState(false);
    const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
    const [nurseList, setNurseList] = useState([]);
    const [options, setOptions] = useState([]);


    const handleSelectOption = (key) => {
        setIsDropdown(false)
        setIsSubDropdown(false)
        switch (key) {
            case "edit":
                setIsDropdown(false);
                handleForEditRequest(serviceRequestId);
                break;

            case "assign":
                setIsDropdown(false);
                handleForAssignNurse(serviceRequestId);
                break;

            case "scheduled":
                setIsDropdown(false);
                handleForScheduledVisits(serviceRequestId);
                break;

            case "active":
                setIsDropdown(false);
                handleForActivedVisits(serviceRequestId);
                break;

            // case "pending":
            //     setIsDropdown(false);
            //     handleForPendingVisits(serviceRequestId);
            //     break;

            case "completed":
                setIsDropdown(false);
                handleForCompletedVisits(serviceRequestId);
                break;

            case "missed":
                setIsDropdown(false);
                handleForMissedVisits(serviceRequestId);
                break;

            case "delete":
                setIsDropdown(false);
                handleForDeleteRequest(serviceRequestId);
                break;

        }
    };

    // const getMenuOptionText = (key) => {
    //     switch (key) {
    //         case "edit":
    //             return 'Edit service request';
    //
    //         case "assign":
    //             return 'Assign Nurse';
    //
    //         case "scheduled":
    //             return 'View scheduled visits';
    //
    //         case "active":
    //             return 'View active visits';
    //
    //         // case "pending":
    //         //     return 'View pending visits';
    //
    //         case "completed":
    //             return 'View completed visits';
    //
    //         case "missed":
    //             return 'View missed visits';
    //
    //         case "delete":
    //             return 'Delete service request';
    //
    //     }
    // };

    const handleForEditRequest = async (id) => {
        dispatch(HttpsAction({
            apiSection: 'serviceRequests',
            apiName: 'serviceDetails',
            queryParameter: `/${id}`,
            actionType: `GET_SERVICE_DETAILS_SINGLE_REQUEST`,
            reduxKeyName: 'serviceDetails',
            reducerName: 'SERVICE_REQUEST',
        }));
        setShowEditServiceRequestModal(true);
    };

    const closeEditServiceRequestDialog = (/*id*/) => {
        setShowEditServiceRequestModal(false);
        // dispatch(getAllServiceRequest('all', currentPage));
        if (loadMethod != null) loadMethod();
    };

    const handleForAssignNurse = async (id) => {
        const response = await dispatch(getAllServiceRequest('nurseListGrouped'));
        setNurseList(response.data)
        setIsAssignModalOpen(true);
    };

    const closeModal = (didAssigned = false) => {
        setIsAssignModalOpen(false);
        if (didAssigned) {
            // dispatch(getAllServiceRequest('all', currentPage));
            navigate("/agency/servicerequest/scheduledrequest");
        }
    };

    const handleForScheduledVisits = (id) => {
        navigate('/agency/servicerequest/scheduledrequest', {
            state: {
                serviceRequestId: id,
            },
        });
    };

    const handleForActivedVisits = (id) => {
        navigate('/agency/servicerequest/activerequest', {
            state: {
                serviceRequestId: id,
            },
        });
    };

    const handleForPendingVisits = (id) => {
        navigate('/agency/servicerequest/pendingrequest', {
            state: {
                serviceRequestId: id,
            },
        });
    };

    const handleForCompletedVisits = (id) => {
        navigate('/agency/servicerequest/completeRequest', {
            state: {
                serviceRequestId: id,
            },
        });
    };

    const handleForMissedVisits = (id) => {
        navigate('/agency/servicerequest/missedRequests', {
            state: {
                serviceRequestId: id,
            },
        });
    };

    const handleForDeleteRequest = async (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!',
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(HttpsAction({
                    method: 'DELETE',
                    apiSection: 'serviceRequests',
                    apiName: 'deleteRequest',
                    queryParameter: `/${id}`,
                    positiveCallBack: () => {
                        // dispatch(getAllServiceRequest('all', currentPage));
                        if (loadMethod != null) loadMethod();
                    }
                }))
            }
        });
    };

    // useEffect(() => {
    //     if (isServiceRequestEdit) {
    //         setShowEditServiceRequestModal(true);
    //     }
    // }, [isServiceRequestEdit]);

    useEffect(() => {
        if (nursesAssigned || jobRelated)
            setOptions(OPTIONS_ARRAY.filter(o => o.key !== 'assign'));
        else
            setOptions(OPTIONS_ARRAY);
    }, [nursesAssigned, jobRelated]);
// console.log({isSubDropdown})

    return (
        <>
            <Stack ref={dropdownRef}>
                <div style={{cursor: 'pointer', height: 28.8, width: 28.8}} onClick={() => setIsDropdown(true)}>
                    <img src={moreVertical} alt={''}/>
                </div>
                <div ref={subDropdownRef}>
                    <Popover
                        open={isDropdown}
                        anchorEl={dropdownRef.current}
                        onClose={() => setIsDropdown(false)}
                        anchorOrigin={{
                            vertical: 'bottom',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        PaperProps={{
                            style: {
                                boxShadow: 'unset',
                                width: '296px',
                                height: 'auto',
                                borderRadius: '5px',
                                marginTop: '5px',
                                border: '1px solid #D5D5D5'
                            },
                        }}
                    >
                        <Stack sx={{padding: '10px 20px'}}>
                            <Stack sx={{fontFamily: 'Poppins', fontSize: '18px', fontWeight: 500}}>
                                More Options
                            </Stack>
                            <Stack sx={{gap: '10px', marginTop: '5px'}}>
                                {options.map((v, i) => (
                                    <Stack
                                        sx={{
                                            flexDirection: 'row',
                                            gap: '10px',
                                            alignItems: 'center',
                                            cursor: 'pointer'
                                        }}
                                        key={i}
                                        onClick={() => {
                                            if (v.key === "view") {
                                                setIsSubDropdown(true)
                                            } else {
                                                handleSelectOption(v.key)
                                            }
                                        }}>
                                        <Stack>
                                            <img src={v.icon} alt={''}/>
                                        </Stack>
                                        <Stack
                                            sx={{
                                                fontFamily: 'Poppins',
                                                fontSize: '16px',
                                                fontWeight: 400,
                                                color: '#73737C'
                                            }}>
                                            {/*{getMenuOptionText(v.key)}*/}
                                            {v.name}
                                        </Stack>
                                    </Stack>
                                ))}
                            </Stack>

                        </Stack>
                    </Popover>
                </div>

                <Popover
                    open={isSubDropdown}
                    anchorEl={subDropdownRef.current}
                    onClose={() => setIsSubDropdown(false)}
                    anchorOrigin={{
                        vertical: 'bottom',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    PaperProps={{
                        style: {
                            boxShadow: 'unset',
                            width: '296px',
                            height: 'auto',
                            borderRadius: '5px',
                            marginTop: '5px',
                            border: '1px solid #D5D5D5',
                            marginRight: '-100px'
                        },
                    }}
                >
                    <Stack sx={{padding: '10px 20px'}}>
                        <Stack sx={{fontFamily: 'Poppins', fontSize: '18px', fontWeight: 500, flexDirection: 'row', justifyContent: 'space-between'}}>
                            <span>Sub Options</span><span className="cursor-pointer" onClick={() => setIsSubDropdown(false)}>---></span>
                        </Stack>
                        <Stack sx={{gap: '10px', marginTop: '5px'}}>
                            {SUB_OPTIONS_ARRAY.map((v, i) => (
                                <Stack
                                    sx={{flexDirection: 'row', gap: '10px', alignItems: 'center', cursor: 'pointer'}}
                                    key={i}
                                    onClick={() => handleSelectOption(v.key)}>
                                    <Stack>
                                        <img src={v.icon} alt={''}/>
                                    </Stack>
                                    <Stack
                                        sx={{
                                            fontFamily: 'Poppins',
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            color: '#73737C'
                                        }}>
                                        {/*{getMenuOptionText(v.key)}*/}
                                        {v.name}
                                    </Stack>
                                </Stack>
                            ))}
                        </Stack>

                    </Stack>
                </Popover>
            </Stack>
            {
                isAssignModalOpen && (
                    <AssignNurseToServiceRequestModal isModalOpen={isAssignModalOpen}
                                                      closeModal={(didAssigned) => closeModal(didAssigned)}
                                                      modalTitle="Assign Nurse" nurseList={nurseList}
                                                      serviceRequestId={serviceRequestId}
                                                      totalAmountReserved={totalAmountReserved} amountReserved={amountReserved} />
                )
            }
            <CustomDialog title={"Edit Service Request"} open={showEditServiceRequestModal && isServiceRequestEdit} maxWidth={'md'}
                          onClose={closeEditServiceRequestDialog}>
                {/*<EditRequest onClose={closeEditServiceRequestDialog}/>*/}
                <AddNewJobServiceRequest onClose={closeEditServiceRequestDialog}/>
            </CustomDialog>
        </>
    )
};

const mapStateToProps = (state) => {
    const {common} = state;
    return {
        formTemplatesList: common?.formTemplatesList || [],
        nursesList: common?.nursesList || [],
        clientsList: common?.patientsList || [],
    };
};

export default connect(mapStateToProps)(MoreOptionDropdown);
