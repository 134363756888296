import React, {useEffect, useState} from "react";
import CreateNewClientModel from "./CreateNewClientModel";
import profileIcon from "../../assets/image/iconamoon_profile-fill.svg"
import {useNavigate} from "react-router-dom";
import classes from "./css/index.module.css";
import Table from "../../../sharedComponents/table/Table";
import {Stack} from "@mui/material";
import Modal from "../../../sharedComponents/Modal";
import {reduxForm} from "redux-form";
import {connect} from "react-redux";
import Pagination from "../../../sharedComponents/pagination";
import storageManager from "../../../helpers/storageManager";
import {debounce} from "lodash";
import {useClientsList} from "../../../queries/agency/clientsList";
import PaginationSummary from "../../../sharedComponents/pagination/PaginationSummary";

const AgencyClients = ({agencyId}) => {
    const navigate = useNavigate();

    const [fetchParams, setFetchParams] = useState({page: 1, searchText: ''});
    const clientList = useClientsList(agencyId, fetchParams.page, fetchParams.searchText);

    const [isModelShow, setIsModelShow] = useState(false);

    const transformedArray = (clientList?.data?.rows ? clientList?.data?.rows : clientList?.rows || [])?.map((item, index) => {
        const respParty = item?.responsiblePartyContacts?.length > 0 ? <Stack gap={'10px'} alignItems={'center'}>
            <span className={classes.clientListName}> {item?.responsiblePartyContacts[0]?.firstName ?? 'N/A'} </span>
            <span className={classes.clientListName}> {item?.responsiblePartyContacts[0]?.phoneNumber ?? 'N/A'} </span>
        </Stack> : 'N/A';
        return {
            // name: <span className={classes.clientListName}> {item?.patientFirstLastName} </span>,
            name: <span>{item?.patientFirstLastName}</span>,
            status: item.statusText,
            administrator: item?.administrator ?? 'N/A',
            gender: item?.patientGender,
            // class: item?.serviceRequestType ?? 'N/A',
            respParty,
            phone: item?.patientPhoneNumber ?? 'N/A',
            id: item.id,
        }
    });

    const serviceHeaderData = [
        // {
        //     title: "ID",
        //     dataIndex: "id",
        // },
        {
            title: "Name",
            dataIndex: "name",
            width: '200px'
        },
        {
            title: "Status",
            dataIndex: "status",
        },
        // {
        //     title: "Administrator",
        //     dataIndex: "administrator",
        // },
        {
            title: "Gender",
            dataIndex: "gender",
        },
        // {
        //     title: "Class",
        //     dataIndex: "class",
        // },
        {
            title: "Resp. Party #1",
            dataIndex: "respParty",
        },
        {
            title: "Phone",
            dataIndex: "phone",
        }
    ];

    const handlePageChange = (page) => {
        storageManager.saveClientListCurrentPage(page);
        setFetchParams(prevState => ({...prevState, page: +page}));
    }

    const actionButton = [
        // {
        //     actionName: '',
        //     buttonAction: (id) => undefined,
        //     actionClass: '',
        //     iconClass: '',
        //     component: <img src={emailIcon} alt=""/>
        // }, {
        //     actionName: '',
        //     buttonAction: (id) => undefined,
        //     actionClass: '',
        //     iconClass: '',
        //     component: <img src={copyIcon} alt=""/>
        // },
        {
            actionName: '',
            // buttonAction: (id) => navigate(`/agency/clients/${id}/profile/personalInformation`),
            // buttonAction: (id) => navigate(`/agency/clients/edit/profile/personal-info/main/${id}`),
            buttonAction: (id) => navigate(`/agency/clients/edit/profile/personal-info`, { state: { id } }),
            actionClass: '',
            iconClass: '',
            component: <img src={profileIcon} alt=""/>
        },
    ]

    function loadData(showFirst = false) {
        let pageStr = '1';
        if (!showFirst)
            pageStr = storageManager.retrieveClientListCurrentPage() ?? pageStr;
        setFetchParams(prevState => ({...prevState, page: +pageStr}));
    }

    const handleSearch = debounce((value) => {
        setFetchParams(prevState => ({page: 1, searchText: value}));
    }, 500);

    useEffect(() => {
        if ((clientList?.data?.rows || []).length > 0) {
            storageManager.saveClientListTotalPages(clientList?.data?.pagesCount);
        }
    }, [clientList]);

    useEffect(() => {
        loadData();
    }, []);


    return (
        <>
            <div className="app-main__outer all-alerts">
                <div className="app-main__inner service-request supervisor-tab ">
                    <div className="row align-items-center">
                        <div className="col-md-8 col-lg-8 col-12">
                            <PaginationSummary heading="Client's List" listInfo={clientList?.data} />
                        </div>
                        <div className="col-md-4 text-end col-lg-4 col-12">
                            <a
                              onClick={() => setIsModelShow(true)}
                              className="btn blue-btn blue-dark-btn"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                            >
                                Create New Client
                            </a>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-between">
                        <div className="col-sm-7 col-md-9 col-12">
                            {/*<Pagination
                              currentPage={clientList?.data?.page}
                              totalRecords={clientList?.data?.total || 0}
                              limit={clientList?.data?.pageSize}
                              onPageChange={handlePageChange}
                            />*/}
                        </div>
                        <div className="col-sm-4 col-md-3 col-12 ">
                            <input
                              className="form-control search-filter"
                              type="search"
                              placeholder="Search..."
                              onChange={({target}) => handleSearch(target.value)}
                            />
                        </div>
                    </div>
                </div>

                <div className="app-main__inner service-request-inner">
                    <div className="tab-content" id="myTabContent">
                        <Table headers={serviceHeaderData} rows={transformedArray} actions={actionButton} hasFixedWidth={true} tableClass="visits-table"/>
                    </div>
                    <Pagination
                      currentPage={clientList?.data?.page}
                      totalRecords={clientList?.data?.total}
                      limit={clientList?.data?.pageSize}
                      onPageChange={handlePageChange}
                    />
                </div>

            </div>
            <Modal isOpen={isModelShow} onClose={() => setIsModelShow(() => false)} modalTitle={'Create New Client'}>
                <CreateNewClientModel onClose={(newClientCreated) => {
                    if (newClientCreated) {
                        loadData(true);
                    }
                    setIsModelShow(() => false);
                }}/>
            </Modal>
        </>
    );
};

const mapStateToProps = (state) => {
    const { auth } = state;

    return {
        isAdmin: auth?.authData?.isAdmin || false,
        agencyId: auth?.authData?.agencyId || auth.agencyId,
    };
};

export default connect(mapStateToProps)(
    reduxForm({form: "agencyClients"})(AgencyClients)
);
