import React from "react";
import {chartingRelatedText, jobRelatedText} from "../appConstants";


const rowHelperFunctions = {

  getClientRowItem(navigate, id, name, userIsAdmin = false, backButtonTextSuffix = null, backLink = null, navUrl = null, clickHandler = null) {
    // return <span className="table-inner-link text-nowrap" onClick={(e) => {
    return <span className="table-inner-link text-break" onClick={(e) => {
      if (clickHandler != null) {
        e.stopPropagation();
        e.preventDefault();
        clickHandler(e);
      }
      else {
        if (backButtonTextSuffix == null || backLink == null)
          navigate(navUrl || `/agency/clients/${id}/profile/personalInformation`);
        else
          navigate(navUrl || `/agency/clients/${id}/profile/personalInformation`, {
            state: {
              showBackButton: true,
              backButtonTextSuffix,
              backLink,
            }
          });
      }
    }}>{name}</span>;
  },

  getSingleNurseRowItem(navigate, id, name, userIsAdmin = false, isFreelance = false, backButtonTextSuffix = null, backLink = null, navUrl = null, clickHandler = null) {
    if (id == null || name == null) return 'N/A';
    return <span className="table-inner-link text-break text-nowrap1" onClick={(e) => {
      if (clickHandler != null) {
        e.stopPropagation();
        e.preventDefault();
        clickHandler(e);
      } else {
        if (isFreelance || (backButtonTextSuffix && backLink))
          navigate(navUrl || `/nurse/detail/${id}`, {
            state: {
              showBackButton: true,
              backButtonTextSuffix,
              backLink,
            }
          });
        else navigate(navUrl || `/agency-nurses/edit/${id}/profile`);
      }
    }}>{name}</span>;
  },

  getNurseRowItem(navigate, index, id, name, userIsAdmin = false, isFreelance = false, backButtonTextSuffix = null, backLink = null, navUrl = null) {
// console.log('test- ', {id, name, userIsAdmin, isFreelance, backButtonTextSuffix, backLink, navUrl})
    if (id == null || name == null) return 'N/A';
    return <span key={index} className="table-inner-link text-break text1-nowrap" onClick={(e) => {
      let url = '';
      if (isFreelance) {
        url = `/nurse/detail/${id}`;
      } else {
        url = navUrl || `/agency-nurses/edit/${id}/profile`
      }
      if (backButtonTextSuffix && backLink) {
        navigate(url, {
          state: {
            showBackButton: true,
            backButtonTextSuffix,
            backLink,
          }
        });
      } else {
        navigate(url);
      }
    }}>{name}</span>;
  },

  getRelatedInfo(item, itemIsTask = true, indicateJob = true, indicateCharting = true) {
    let relatedText = null;
    let relatedTextColor = null;
    let relatedLink = null;
    let relatedId = null;
    let relatedInfoList = indicateCharting || indicateJob ? [] : undefined;
    const hasValidChartingId = item?.chartingId != null && item.chartingId > 0;
    const hasValidJobId = item?.jobId != null && item.jobId > 0;
    if (hasValidChartingId) {
      // relatedText = 'Charting';
      relatedText = chartingRelatedText;
      relatedTextColor = 'warning';
      if (item?.requiresInitialAssessment) relatedLink = '/agency/servicerequest/initialAssessments';
      if (item?.requiresSupervisoryVisits) relatedLink = '/agency/servicerequest/supervisoryVisits';
      if (item?.requiresDischargeSummary) relatedLink = '/agency/servicerequest/dischargeSummaries';
      if (item?.requiresCarePlan) relatedLink = '/agency/servicerequest/carePlans';
      relatedId = item?.id;
      const relatedState = itemIsTask ? {taskId: item?.id} : {chartingId: item?.chartingId};
      if (indicateCharting) relatedInfoList.push({relatedState, relatedText: (' '+relatedText).slice(1), relatedTextColor: (' '+relatedTextColor).slice(1), relatedLink: relatedLink == null ? null : (' '+relatedLink).slice(1)});
    }
    if (hasValidJobId) {
      // relatedText = 'Job';
      relatedText = jobRelatedText;
      relatedTextColor = 'info';
      relatedLink = '/agency/servicerequest/postedjobsinprogress';
      relatedId = item?.jobId;
      if (indicateJob) relatedInfoList.push({relatedState: {jobId: item?.jobId}, relatedText: (' '+relatedText).slice(1), relatedTextColor: (' '+relatedTextColor).slice(1), relatedLink: (' '+relatedLink).slice(1)});
    }
    return {hasValidChartingId, relatedInfoList};
  },


};

export default rowHelperFunctions;
