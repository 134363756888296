import React, {useEffect} from 'react';
import {connect, useDispatch} from "react-redux";
import {parseTokenAndGetData} from "../../../helpers/apiMethods";
import {getAgencyWalletDetailsAction} from "../../../services/transaction";

const WalletBalanceComponent = ({ reloadBalance = false, setReloadBalance, agencyWalletDetails }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const decodedTokenData = parseTokenAndGetData().data;
    dispatch(getAgencyWalletDetailsAction("agencyWalletDetails", decodedTokenData.walletId));
  }, []);

  useEffect(() => {
    if (reloadBalance) {
      if (setReloadBalance != null) setReloadBalance(false);
      const decodedTokenData = parseTokenAndGetData().data;
      dispatch(getAgencyWalletDetailsAction("agencyWalletDetails", decodedTokenData.walletId));
    }
  }, [reloadBalance]);

  return (
    <div className="d-flex align-items-center flex-wrap gap-2">
      <h5 className="m-0">Wallet Balance:</h5>
      <h4 className="m-0">${agencyWalletDetails.balance} USD</h4>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { transaction } = state;
  return {
    agencyWalletDetails: transaction.agencyWalletDetails || {},
  };
};

export default connect(mapStateToProps)(WalletBalanceComponent);
