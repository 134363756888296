import getApiUrl from "../helpers/apiURLs.js";
import api from "../helpers/interceptor";
import { toastr } from "react-redux-toastr";
import * as commonServices from "./commonServices";
import {HttpsAction} from "../commonApiFunction/httpsAction";


export const reloadProfileData = (agencyId) => {
  return async (dispatch) => {
    let queryParameter = "";
    if (agencyId) {
      queryParameter += `/${agencyId}`
    }
    dispatch(HttpsAction({
      apiSection: 'agencyProfile',
      apiName: 'agencyProfile',
      reduxKeyName: 'agencyProfile',
      queryParameter,
      reducerName: 'AGENCY_PROFILE',
      actionType: 'GET_AGENCY_PROFILE_DATA'
    }));
  };
};

export const getAgencyAction = (payload, pageNumber, id = null, successCallback = null) => {
  return async (dispatch) => {};
};

export const deleteAgencyAction = (endPoint, message, id = null, successCallback = null) => {
  return async (dispatch) => {};
};

export const putAgencyAction = (payload, endPoint, message, id = null, successCallback = null) => {
  return async (dispatch) => {
    dispatch(commonServices.setLoader(true));
    let url = getApiUrl("agencyProfile", endPoint);
    if (id != null) {
      url += `/${id}`;
    }
    try {
      const response = await api.put(url, payload, endPoint === 'logo');
      if (successCallback != null) { successCallback(); }
      dispatch(commonServices.setLoader(false));
      toastr.success(message);
      return response;
    } catch (err) {
      dispatch(commonServices.setLoader(false));
      if (err.status === 400) {
        toastr.error(err.message || "something went wrong");
      } else {
        toastr.error("something went wrong");
      }
      return err;
    }
  };
};

export const postAgencyAction = (payload, endPoint, message, id = null, successCallback = null) => {
  return async (dispatch) => {};
};


