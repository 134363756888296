import React, {useEffect, useState} from "react";
import {Dialog, Stack} from "@mui/material";
import classes from "../TaskDetails.module.css";
import {apiBaseUrl} from "../../../../appConstants";
import AddTasks from "./AddTasks";
import {connect, useDispatch, useSelector} from "react-redux";
import {change, Field, reduxForm} from "redux-form";
import PrimaryButton from "../../../../sharedComponents/buttons/PrimaryButton";
import CustomInputField from "../../../../sharedComponents/reduxfields/customInputField";
import {useParams} from "react-router";
import {HttpsAction} from "../../../../commonApiFunction/httpsAction";
import {toastr} from "react-redux-toastr";
import dateHelperFunctions from "../../../../helpers/date.helpers";
import {getAllServiceRequest} from "../../../../services/serviceRequest";
import CustomDialog from "../../../../sharedComponents/CustomDialog";

const CareNoteCommon = ({
                            show = false,
                            clientSignature = '',
                            notes = '',
                            caregiverSignature = '',
                            activitiesList = [],
                            addTask = true,
                            handleSubmit,
                            onClose,
                            reload = () => {},
                            taskId = 0,
                            showingDataFor = '',
                            updateActivitiesList,
                            removeActivity,
                            doUpdateApiCall,
                            taskDetails: serviceRequestTaskDetails,
                            isJobRelated,
                            isChartingRelated,
                            clockInTime,
                            clockOutTime,
                            rnSignatureEditable,
                            rnSignatureValue,
                            rnSignatureDate,
                        }) => {

    const dispatch = useDispatch();

    // const {serviceRequestId, taskId} = useParams();

    // const serviceRequestTaskDetails = useSelector(state => state.serviceRequest?.serviceRequestTaskDetails?.data)

    const [dialog, setDialog] = useState(false);
    const [open, setOpen] = useState(false);
    const [isEditable, setIsEditable] = useState(!(showingDataFor === 'activeVisits' || showingDataFor === 'completedVisits' || showingDataFor === 'missedVisits' || showingDataFor === 'incompleteVisits'));

    const handleAddTasks = () => {
        setDialog(!dialog)
    };

    const handleActivityDelete = (e, activityId) => {
        e.preventDefault();
        if (activityId != null && activityId > 0 && taskId > 0) {
            // dispatch(HttpsAction({
            //     method: 'DELETE',
            //     apiSection: 'serviceRequests',
            //     apiName: 'taskActivity',
            //     queryParameter: `/${taskId}/${activityId}`,
            //     positiveCallBack: () => {
            //         // dispatch(getAllServiceRequest('active', currentPage));
            //         reload();
            //     }
            // }));
            removeActivity(activityId);
        }
    };

    const onSubmit = async ({RNSignature}) => {
      if (rnSignatureEditable) {
        if ((serviceRequestTaskDetails?.scheduledPaymentAmount || 0) + (serviceRequestTaskDetails?.overtimePaymentAmount || 0) > 0) {
          if (RNSignature) {
            let taskIdVal = taskId || 0;
            if (typeof taskIdVal === 'string') taskIdVal = +taskIdVal;
            if (taskIdVal <= 0) taskIdVal = serviceRequestTaskDetails.id;

            await dispatch(HttpsAction({
              method: 'POST',
              apiSection: 'serviceRequests',
              apiName: 'verifyNursePayment',
              queryParameter: `/${serviceRequestTaskDetails?.assignedNurseId}`,
              data: {
                // amount: parseFloat(((serviceRequestTaskDetails?.scheduledPaymentAmount || 0) + (serviceRequestTaskDetails?.overtimePaymentAmount || 0))),
                serviceRequestId: serviceRequestTaskDetails.serviceRequestId,
                taskId: taskIdVal,
                nurseNameForVerification: RNSignature,
              },
              positiveCallBack: () => {
                onClose('Verification Success', { nurseNameForVerification: RNSignature, verifiedAt: new Date()});
                doUpdateApiCall(null);
              }
            }));
            // onClose('Verification Success')
          } else {
            toastr.error("Please enter RN Signature");
          }
        } else {
          if (onClose) {
            onClose();
            return toastr.error("Amount must be greater than 0");
          }
        }
      }
    };

    const handleCloseModal = () => {
        setOpen(false)
    };

    const handleOpenModal = (url) => {
        setOpen(url)
    }

  useEffect(() => {
// console.log('test- rnSignatureValue', rnSignatureValue)
    if (rnSignatureValue != null) {
      dispatch(change('CareNoteCommonForm', 'RNSignature', rnSignatureValue));
    }
  }, [rnSignatureValue]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} style={{display: show ? 'block' : 'none'}}>
            {/*<form style={{display: show ? 'block' : 'none'}}>*/}
                <Stack margin={'10px 0 0 0'}>
                    <div className={classes.noteBox}>
                        <div className={classes.noteTitle}>
                            Notes
                        </div>
                        <div className={classes.noteText}>
                            {notes || ''}
                        </div>
                    </div>
                    <div className={'row mt-2'}>
                        <div className={'col-6'}>
                            <div className={classes.noteBox}>
                                <div className={classes.noteTitle}>
                                    Client’s Signature
                                </div>
                                <div className={'mt-3 d-flex align-items-center justify-content-center'}>
                                    {clientSignature &&
                                    <img style={{width: '100px', height: '50px', cursor: "pointer"}}
                                         onClick={() => handleOpenModal(`${apiBaseUrl}${clientSignature}`)}
                                         src={`${apiBaseUrl}${clientSignature}`} alt={''}/>}
                                </div>
                            </div>
                        </div>
                        <div className={'col-6'}>
                            <div className={classes.noteBox}>
                                <div className={classes.noteTitle}>
                                    Caregiver’s Signature
                                </div>
                                <div className={'mt-3 d-flex align-items-center justify-content-center'}>
                                    {caregiverSignature &&
                                    <img style={{width: '100px', height: '50px', cursor: "pointer"}}
                                         onClick={() => handleOpenModal(`${apiBaseUrl}${caregiverSignature}`)}
                                         src={`${apiBaseUrl}${caregiverSignature}`} alt={''}/>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{padding: '15px 30px 0 40px'}}
                         className={'d-flex justify-content-between align-items-center'}>
                        <div data-class={classes.tackCount} style={{width: '142px'}}>
                            Tasks ({(activitiesList || []).length})
                        </div>
                      <div className="d-flex gap-5">
                        <div className={classes.tackCount}>
                          <span>Clock-in:</span>&nbsp;
                          {clockInTime == null ? (<>
                            <svg width="20" height="20" viewBox="0 0 122.879 122.879" enableBackground="new 0 0 122.879 122.879"
                                 xmlns="http://www.w3.org/2000/svg">
                              <title>Not Clocked-in Yet</title>
                              <g>
                                <path fillRule="evenodd" clipRule="evenodd" fill="#FF4141"
                                      d="M61.44,0c33.933,0,61.439,27.507,61.439,61.439 s-27.506,61.439-61.439,61.439C27.507,122.879,0,95.372,0,61.439S27.507,0,61.44,0L61.44,0z M73.451,39.151 c2.75-2.793,7.221-2.805,9.986-0.027c2.764,2.776,2.775,7.292,0.027,10.083L71.4,61.445l12.076,12.249 c2.729,2.77,2.689,7.257-0.08,10.022c-2.773,2.765-7.23,2.758-9.955-0.013L61.446,71.54L49.428,83.728 c-2.75,2.793-7.22,2.805-9.986,0.027c-2.763-2.776-2.776-7.293-0.027-10.084L51.48,61.434L39.403,49.185 c-2.728-2.769-2.689-7.256,0.082-10.022c2.772-2.765,7.229-2.758,9.953,0.013l11.997,12.165L73.451,39.151L73.451,39.151z"/>
                              </g>
                            </svg>
                          </>) : (<>
                            <span className={classes.clockInOutTime}>{clockInTime}</span>
                          </>)}
                        </div>
                        <div className={classes.tackCount}>
                          <span>Clock-out:</span>&nbsp;
                          {clockOutTime == null ? (<>
                            <svg width="20" height="20" viewBox="0 0 122.879 122.879" enableBackground="new 0 0 122.879 122.879"
                                 xmlns="http://www.w3.org/2000/svg">
                              <title>Not Clocked-out Yet</title>
                              <g>
                                <path fillRule="evenodd" clipRule="evenodd" fill="#FF4141"
                                      d="M61.44,0c33.933,0,61.439,27.507,61.439,61.439 s-27.506,61.439-61.439,61.439C27.507,122.879,0,95.372,0,61.439S27.507,0,61.44,0L61.44,0z M73.451,39.151 c2.75-2.793,7.221-2.805,9.986-0.027c2.764,2.776,2.775,7.292,0.027,10.083L71.4,61.445l12.076,12.249 c2.729,2.77,2.689,7.257-0.08,10.022c-2.773,2.765-7.23,2.758-9.955-0.013L61.446,71.54L49.428,83.728 c-2.75,2.793-7.22,2.805-9.986,0.027c-2.763-2.776-2.776-7.293-0.027-10.084L51.48,61.434L39.403,49.185 c-2.728-2.769-2.689-7.256,0.082-10.022c2.772-2.765,7.229-2.758,9.953,0.013l11.997,12.165L73.451,39.151L73.451,39.151z"/>
                              </g>
                            </svg>
                          </>) : (<>
                            <span className={classes.clockInOutTime}>{clockOutTime}</span>
                          </>)}
                        </div>
                      </div>
                      {addTask && !isChartingRelated && isEditable ? (<div>
                        <div className={classes.addTackButton} onClick={handleAddTasks}>
                          <div className={'d-flex flex-row gap-1 align-items-center'}>
                                    <div className={classes.addTackText}>
                                        &nbsp;Add More Tasks&nbsp;
                                    </div>
                                    <svg width="18" height="8" viewBox="0 0 18 18" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.25 6.375L9 11.625L3.75 6.375" stroke="white" strokeWidth="1.5"
                                              strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </div>
                            </div>
                        </div>) : <div style={{width: '142px'}}>&nbsp;</div>}
                    </div>


                    <div className={classes.nameBox}>
                      <div className={'d-flex flex-row gap-5'}>
                        {/*<div className={classes.NAME}>*/}
                        {/*  Serial*/}
                        {/*</div>*/}
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M12.9981 20.0002H10.9981V8.00016L5.49813 13.5002L4.07812 12.0802L11.9981 4.16016L19.9181 12.0802L18.4981 13.5002L12.9981 8.00016V20.0002Z"
                            fill="#666262"/>
                        </svg>

                        <div className={classes.NAME}>
                          NAME
                        </div>
                      </div>
                    </div>


                  <Stack style={{maxHeight: '150px', overflowY: 'auto'}}>
                    {(activitiesList || [])?.map((value, index) => (
                            <div style={{padding: '0 40px', borderBottom: '1px solid #F5F6F8'}} key={index}>
                                <div className={classes.taskName}>
                                  <div style={{display: 'flex', alignItems: 'center', columnGap: '1.75rem'}}>
                                    <div style={{width: '40px'}}>
                                      {index + 1}
                                    </div>
                                    <div style={{flex: '1'}}>
                                      {value?.name}
                                    </div>
                                  </div>
                                  <Stack direction={'row'} gap={2} alignItems={'center'}>
                                    {value?.isCompleted && <div>
                                            <span>Submitted at </span><span>{dateHelperFunctions.convertTo12HourFormat(value?.completionTime)}</span>
                                        </div>}
                                        {!value?.isCompleted ? (<>
                                            <svg width="20" height="20" viewBox="0 0 122.879 122.879" enableBackground="new 0 0 122.879 122.879"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <title>Not Done Yet</title>
                                                <g>
                                                    <path fillRule="evenodd" clipRule="evenodd" fill="#FF4141"
                                                          d="M61.44,0c33.933,0,61.439,27.507,61.439,61.439 s-27.506,61.439-61.439,61.439C27.507,122.879,0,95.372,0,61.439S27.507,0,61.44,0L61.44,0z M73.451,39.151 c2.75-2.793,7.221-2.805,9.986-0.027c2.764,2.776,2.775,7.292,0.027,10.083L71.4,61.445l12.076,12.249 c2.729,2.77,2.689,7.257-0.08,10.022c-2.773,2.765-7.23,2.758-9.955-0.013L61.446,71.54L49.428,83.728 c-2.75,2.793-7.22,2.805-9.986,0.027c-2.763-2.776-2.776-7.293-0.027-10.084L51.48,61.434L39.403,49.185 c-2.728-2.769-2.689-7.256,0.082-10.022c2.772-2.765,7.229-2.758,9.953,0.013l11.997,12.165L73.451,39.151L73.451,39.151z"/>
                                                </g>
                                            </svg>
                                        </>) : (<>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <title>Completed</title>
                                                <rect x="0.416667" y="0.416667" width="19.1667" height="19.1667"
                                                      rx="9.58333"
                                                      fill="#13DC72"/>
                                                <path
                                                  d="M8.72139 15C8.39544 15 8.08673 14.855 7.88892 14.605L5.21168 11.2325C5.1289 11.1284 5.06847 11.0097 5.03384 10.8832C4.99921 10.7568 4.99106 10.6251 5.00985 10.4957C5.02864 10.3662 5.074 10.2416 5.14334 10.1289C5.21269 10.0162 5.30466 9.91761 5.41399 9.8388C5.52328 9.75967 5.64788 9.70188 5.78064 9.66874C5.9134 9.6356 6.05172 9.62776 6.18767 9.64568C6.32361 9.6636 6.45451 9.70692 6.57286 9.77315C6.69122 9.83939 6.79469 9.92724 6.87737 10.0317L8.63897 12.249L13.0681 5.4684C13.215 5.24452 13.449 5.08532 13.7188 5.02573C13.9887 4.96614 14.2723 5.01101 14.5075 5.15051C14.9968 5.44054 15.1474 6.05488 14.8417 6.52207L9.60856 14.53C9.51922 14.6673 9.39609 14.7817 9.24985 14.8634C9.1036 14.945 8.93866 14.9915 8.76934 14.9986C8.75286 15 8.73787 15 8.72139 15Z"
                                                  fill="#F8F8F8"/>
                                                <rect x="0.416667" y="0.416667" width="19.1667" height="19.1667"
                                                      rx="9.58333"
                                                      stroke="#13DC72" strokeWidth="0.833333"/>
                                            </svg>
                                        </>)}

                                        {isEditable && !isChartingRelated && !value?.isCompleted ? (<div onClick={(e) => handleActivityDelete(e, value?.id)} className="m-o p-0 cursor-pointer">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                  d="M19 4H15.5L14.5 3H9.5L8.5 4H5V6H19M6 19C6 19.5304 6.21071 20.0391 6.58579 20.4142C6.96086 20.7893 7.46957 21 8 21H16C16.5304 21 17.0391 20.7893 17.4142 20.4142C17.7893 20.0391 18 19.5304 18 19V7H6V19Z"
                                                  fill="#73737C"/>
                                            </svg>
                                        </div>) : (<div style={{width: '1.5rem'}}>&nbsp;</div>)}
                                    </Stack>
                                </div>
                            </div>
                        ))}
                    </Stack>

                    <div className="col-12 mt-2">
                        <div className="mbx-3 input-field-main">
                            <Field
                              className="form-control mk-wi"
                              name="RNSignature"
                              label="RN Signature"
                              type="text"
                              component={CustomInputField}
                              requiredMark={rnSignatureEditable}
                              readOnly={!rnSignatureEditable}
                            />
                        </div>
                    </div>

                </Stack>
                {/*<span>{rnSignatureEditable === true ? 'true' : 'false'}</span>*/}
                {isEditable && (!isChartingRelated || rnSignatureEditable) && <div
                  className="d-flex justify-content-center"
                  style={{marginTop: "10px"}}
                >
                    <PrimaryButton
                      type="submit"
                      className="btn-view-repo gap-2 px-5 py-2"
                      text={"Save"}
                      onClick={() => undefined}
                    />
                </div>}
            </form>

            <Dialog maxWidth="lg" open={dialog} onClose={handleAddTasks} aria-labelledby="form-dialog-title">
                <AddTasks onClose={handleAddTasks} updateActivitiesList={updateActivitiesList} />
            </Dialog>

            <CustomDialog
                open={open}
                onClose={() => handleCloseModal()}
                title={'Signature'}
                maxWidth={'sm'}
                actions={
                    <div className="d-flex justify-content-center" style={{marginBottom: "20px"}}>
                        <PrimaryButton
                            type="button"
                            className="btn-view-repo gap-2 px-5 py-2"
                            text={"Close"}
                            onClick={() => handleCloseModal()}
                        />
                    </div>
                }
            >
                <div className={'d-flex justify-content-center align-items-center w-100'}>
                    <img style={{height: '250px', width: '100%'}} src={open} alt={''}/>
                </div>
            </CustomDialog>
        </>
    )
};

export default connect()(
    reduxForm({form: "CareNoteCommonForm", enableReinitialize: true})(CareNoteCommon)
);
