import * as commonServices from './commonServices.js'
import getApiUrl from "../helpers/apiURLs.js";
import api from '../helpers/interceptor'
import {toastr} from 'react-redux-toastr'
import {getAllServiceRequest} from './serviceRequest.js'
import {defaultErrorMessage, pageLimit} from "../appConstants";
import * as serviceRequest from "../Agency/components/ServiceRequest/serviceActionTypes";
import {POSTED_JOBS_COMPLETED, POSTED_JOBS_IN_PROGRESS} from "../Agency/components/ServiceRequest/serviceActionTypes";

// Create Job
export const postJob = (payload, endPoint) => {
    return async (dispatch) => {
        dispatch(commonServices.setLoader(true));
        let url = getApiUrl('jobPosting', endPoint)
        try {
            const response = await api.post(url, payload);
            dispatch(commonServices.setLoader(false));
            toastr.success('New Job Created Successfully', '');
            return response;
        } catch (err) {
            dispatch(commonServices.setLoader(false));
            toastr.error(defaultErrorMessage, '');
            return err
        }
    };
};

export const postedJobActions = (payload, endPoint, successCallback) => {
    return async (dispatch) => {
        dispatch(commonServices.setLoader(true));
        let url = '';
        switch(endPoint){
            case 'publishJob':
                url = `${getApiUrl('jobPosting', endPoint)}/${payload.id}`;
                break
            case 'deleteJob':
                url = `${getApiUrl('jobPosting', endPoint)}/${payload.id}`;
                break
            case 'cancelJob':
                url = `${getApiUrl('jobPosting', endPoint)}/${payload.id}`;
                break
            case 'acceptJob':
                url = `${getApiUrl('jobPosting', endPoint)}/${payload.bidId}`;
                break
            default:
                break
        }
        try {
            let response = '';
            switch(endPoint){
                case 'publishJob':
                    response = await api.put(url, payload)
                    toastr.success('Job Published Successfully', '');
                    // dispatch(getAllServiceRequest('postedJobs', '1'));
                    break
                case 'deleteJob':
                    response = await api.delete(url, payload)
                    toastr.success('Job Deleted Successfully', '');
                    // dispatch(getAllServiceRequest('postedJobs', '1'));
                    break
                case 'cancelJob':
                    response = await api.put(url, payload)
                    toastr.success('Job Cancelled Successfully', '');
                    // dispatch(getAllServiceRequest('PostedJobsActive', '1'));
                    break
                case 'acceptJob':
                    response = await api.put(url/*, payload*/)
                    toastr.success('Bid for Job Accepted Successfully', '');
                    // dispatch(getAllServiceRequest('PostedJobsActive', '1'));
                    break
                default:
                    break
            }
            dispatch(commonServices.setLoader(false));
            if (successCallback != null) successCallback();
            return response;
        } catch (err) {
            dispatch(commonServices.setLoader(false));
            toastr.error(defaultErrorMessage, '');
            return err
        }
    };
};

// Get Job posting creation Data
export const getJobPostingCreationData = (endPoint) => {
    return async (dispatch) => {
        dispatch(commonServices.setLoader(true));
        const url = getApiUrl('commonAPI', endPoint)
        try {
            const response = await api.get(url);
            dispatch(commonServices.setLoader(false));
            return response;
        } catch (err) {
            dispatch(commonServices.setLoader(false));
            toastr.error(defaultErrorMessage, '');
        }
    };
}


export const getAllJobs = (endPoint, pageNumber, searchText) => {
    return async (dispatch) => {
        dispatch(commonServices.setLoader(true));
        let url = '';
        switch (endPoint) {
            case 'postedJobs':
                url =`${getApiUrl('jobPosting', 'getJob')}?page=${pageNumber}&pageSize=${pageLimit}&jobStatusId=1`; // Pending
                break;
            case 'PostedJobsActive':
                url = `${getApiUrl('jobPosting', 'getJob')}?page=${pageNumber}&pageSize=${pageLimit}&jobStatusId=2`; // Active
                break;
            case 'PostedJobsAccepted':
                url = `${getApiUrl('jobPosting', 'getJob')}?page=${pageNumber}&pageSize=${pageLimit}&jobStatusId=3`; // Accepted
                break;
            case 'inProgressJobs':
                url = `${getApiUrl('jobPosting', 'getJob')}?page=${pageNumber}&pageSize=${pageLimit}&jobStatusId=4`; // In-Progress
                break;
            case 'completedJobs':
                url = `${getApiUrl('jobPosting', 'getJob')}?page=${pageNumber}&pageSize=${pageLimit}&jobStatusId=5`; // Completed
                break;
            case 'expiredJobs':
                url = `${getApiUrl('jobPosting', 'getJob')}?page=${pageNumber}&pageSize=${pageLimit}&jobStatusId=6`; // Expired
                break;
            default:
                break;
        }
        if (url.length > 0 && searchText != null && searchText.length > 0) {
            url += `&searchText=${searchText}`;
        }
        if (url.length > 0) {
            try {
                const response = await api.get(url);
                switch (endPoint) {
                    case 'postedJobs':
                        dispatch({type:serviceRequest.POSTED_JOBS, postedJobsData:response.data})
                        break;
                    case 'PostedJobsActive':
                        dispatch({type:serviceRequest.POSTED_JOBS_ACTIVE, postedJobsActiveData:response.data})
                        break;
                    case 'PostedJobsAccepted':
                        dispatch({type:serviceRequest.POSTED_JOBS_ACCEPTED, postedJobsAcceptedData:response.data})
                        break;
                    case 'inProgressJobs':
                        dispatch({type:serviceRequest.POSTED_JOBS_IN_PROGRESS, postedJobsInProgressData:response.data})
                        break;
                    case 'completedJobs':
                        dispatch({type:serviceRequest.POSTED_JOBS_COMPLETED, postedJobsExpiredData:response.data})
                        break;
                    case 'expiredJobs':
                        dispatch({type:serviceRequest.POSTED_JOBS_EXPIRED, postedJobsExpiredData:response.data})
                        break;
                    default:
                        break;
                }
                dispatch(commonServices.setLoader(false));
                return response;
            } catch (err) {
                dispatch(commonServices.setLoader(false));
                toastr.error(defaultErrorMessage, '');
            }
        }
    };
}

