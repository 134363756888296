import React, {useState, useEffect} from "react";
import {connect} from 'react-redux'
import {Field} from 'redux-form';
import CustomInputField from '../../../../sharedComponents/reduxfields/customInputField'
import {required, emailValidation} from '../../../../helpers/validation'
import {useDispatch} from 'react-redux';
import {useQueryClient} from "@tanstack/react-query";
import apiCallHelperFunctions from "../../../../helpers/api.call.helpers";
import CustomDropDownNewField from "../../../../sharedComponents/reduxfields/customDropDownNew";


const ChartingTab = ({jobId, agencyId, chartingTypeId}) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const formTemplatesList = queryClient.getQueryData(['list-form-templates', agencyId, apiCallHelperFunctions.getChartingTypeKey(chartingTypeId)]);
  // const statesArray = queryClient.getQueryData(['list-provinces-states', 233]);

  const [titleFieldLabel, setTitleFieldLabel] = useState('');

  useEffect(() => {
    setTitleFieldLabel(apiCallHelperFunctions.getChartingTitle(chartingTypeId));
  }, [chartingTypeId]);

  return (
    // <div className="col-12 mb-2" style={{maxWidth : "900px"}}>
    //   <div className="row">
    //     <div className="tab-inner-heading">Charting Information</div>
    //     <div className="neo1 col-12 col-xl-9 py-2 px-4 m-0 border border-black rounded-4 border-opacity-10">
    <div className="col" style={{width: 'max-content', maxWidth: 'auto'}}>
      <div className="tab-inner-heading" style={{width: 'max-content', maxWidth: 'auto'}}>Charting Information</div>
      <div className="neo1 py-2 px-4 m-0 border border-black rounded-4 border-opacity-10">
        <div className="p-0 m-0 border1 border1-black">
          {/*<label className="tab-inner-sub-heading">Create new Client</label>*/}
          <div className="form-group row">
            <div className="col-12 col-sm-8">
              <Field
                className="form-control mk-wi"
                name="chartingTitle"
                label={titleFieldLabel}
                type="text"
                component={CustomInputField}
                requiredMark={true}
                validate={required}
              />
            </div>
            <div className="col-12 col-sm-8">
              <Field
                label="Choose a Template"
                className="form-control"
                name="templateId"
                component={CustomDropDownNewField}
                requiredMark={true}
                validate={required}
                showErrorsFixed={true}
              >
                <option value="">Please Select</option>
                {formTemplatesList?.map((res, index) => {
                  return <option key={index} value={res.id}>{res.name}</option>
                })}
              </Field>
            </div>
            {/*<div className="col-12 col-sm-8">
             <Field
             label="Expiration date"
             className="form-control mk-wi"
             name="expirationDate"
             type="date"
             component={CustomInputField}
             />
             </div>*/}
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

// const mapStateToProps = state => {
//     const {common} = state;
//
//     return {
//         // statesArray: common?.stateList?.data ? common?.stateList?.data : common?.stateList || []
//         statesArray: common.stateList || [],
//     }
// }

// export default connect(mapStateToProps)(ChartingTab);
export default ChartingTab;
