const arrayHelperFunctions = {

  onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
  },

  nullIfEmpty(value) {
    if (value == null) return null;
    if (value.length === 1 && value[0].length === 0) return null;
    if (value.length === 0) return null;
    return value;
  },

  getMapped(arr, sourceKeys, targetKey, convertToString = true) {
    return (arr || []).map((l) => ({
      [targetKey[0]]: convertToString && typeof l[sourceKeys[0]] !== 'string' ? String(l[sourceKeys[0]]) : l[sourceKeys[0]],
      [targetKey[1]]: convertToString && typeof l[sourceKeys[1]] !== 'string' ? String(l[sourceKeys[1]]) : l[sourceKeys[1]],
    }));
  },

};

export default arrayHelperFunctions;
