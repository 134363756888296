import React, {useEffect, useState} from "react";
import {connect, useSelector} from "react-redux";
import {reduxForm} from "redux-form";
import {useDispatch} from 'react-redux';
import {NavLink, useParams} from "react-router-dom";
import {postServiceCreationData} from '../../../services/serviceRequest';
import {
    getCityList,
    getFrequenciesList,
    getGendersList,
    getPatientsList, getPatientsWithLatLngList,
    getServiceRequestTasksList,
    getServiceRequestTypesList,
    getStateList
} from '../../../services/commonServices'
import {toastr} from 'react-redux-toastr'
import {useNavigate} from "react-router-dom";
import {HttpsAction} from "../../../commonApiFunction/httpsAction";
import dateHelperFunctions from "../../../helpers/date.helpers";
import EditServiceRequestInformationTab from "./EditRequestTabs/EditServiceRequestInformationTab";
import EditPatientInformationTab from "./EditRequestTabs/EditPatientInformationTab";
import EditTask from "./EditRequestTabs/EditTask";
import {useQueryClient} from "@tanstack/react-query";

const erFormVarName = 'editRequestForm';

const EditRequest = ({handleSubmit, submitFailed, agencyId, onClose}) => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {navigateId} = useParams();

    // const isServiceRequestEdit = useSelector(state => state.serviceRequest?.isServiceRequestEdit || false);
     const serviceRequestTaskDetails = useSelector(state => state.serviceRequest?.serviceDetails?.data);

    // const patientsArray = queryClient.getQueryData(['list-patients-with-latlng', agencyId]);
    const frequencyDaysList = queryClient.getQueryData(['list-day-frequencies']);

    const [tabIndex, setTabIndex] = useState(0);
    const [cityList, setCityList] = useState([]);
    const [isTaskSelected, setTaskSelection] = useState(true);
    const [RatesValue, setRatesValue] = useState('1');
    const [chargeValue, setChargeValue] = useState('1');
    const [tasksList, setTasksList] = useState([]);
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [isSelectedValid, setSelectedValid] = useState(true);
    const [clientId, setClientId] = useState(null);
    const [serviceRequestId, setServiceRequestId] = useState(null);
    const [rateData, setRateData] = useState({});
    // const [tasksListUpdated, setTasksListUpdated] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [assignedNurseId, setAssignedNurseId] = useState(0);


    const updateTasksArray = (data) => {
        setTasksList(data);
    }

    // const addToDeletedTasksArray = (deletedItem) => {
    //     setTasksList(prevState => [...prevState, deletedItem]);
    //     setTasksListUpdated(false);
    // }

    // const getStateId = (e) => {
    //     let index = e.target.selectedIndex;
    //     let optionElement = e.target.childNodes[index]
    //     let optionattr = optionElement.getAttribute('value');
    //
    //     const optionData = {
    //         optionId: optionattr,
    //         serviceName: 'getCityList'
    //     }
    //     fetchData(optionData)
    // }

    const getClientId = (e) => {
        let index = e.target.selectedIndex;
        let optionElement = e.target.childNodes[index];
        let optionattr = optionElement.getAttribute('value');
        setClientId(optionattr);
    }

    const getRatesValue = (e) => {
        setRatesValue(e.target.value);
    }

    const getChargeMethod = (e) => {
        setChargeValue(e.target.value);
    }

    const getCompleteAddress = (data) => {
        setSelectedPlace(data);
        if (data === null) {
            setSelectedValid(false);
        } else {
            setSelectedValid(true);
        }
    }

    const updateRate = (key, rateString) => {
        setRateData(prevState => ({...prevState, [key]: parseFloat(rateString)}));
    }

    const onSubmit = async (values) => {
        console.log("---->>values", values);
        let payload = Object.assign({}, values);
        const payRateTypeIdVal = +payload.payRateTypeId;
        let serviceReqInfo = {};
        console.log("---->>tasksList", tasksList);
        const modifiedTaskData = tasksList.map(task => {
            if (task.recordType !== 'deleted') {
                return {
                    recordType: task.recordType,
                    id: task.id || undefined,
                    startDate: dateHelperFunctions.updateDateFormat(task.startDate),
                    endDate: dateHelperFunctions.updateDateFormat(task.endDate),
                    // frequencyIds: task.modalFrequencyIds,
                    expectedClockIn: task.clockIn,
                    expectedClockOut: task.clockOut,
                    tasksIds: task.tasksIds,
                    serviceRequestTypeId: +task.serviceRequestTypeId,
                    assignedNurseId: task.assignedNurseId == null ? null : +task.assignedNurseId,
                };
            } else {
                return {
                    recordType: task.recordType,
                    id: task.id,
                };
            }
        });
        serviceReqInfo = {
            patientSelection: {
                recordType: "existing",
                patientId: +clientId,
            },
            ratesInfo: {
                payRateTypeDesc: +chargeValue === 1 ? "hourly" : "other",
                payRateTypeId: +chargeValue,
                // agencyRateToClient: parseFloat(payload.agencyRateToClient),
                // nurseRate: parseFloat(payload.nurseRate),
                // nurseOvertimeRate: parseFloat(payload.nurseOvertimeRate),
                ...rateData,
            },
            tasksSelected: modifiedTaskData || [],
        };
        console.log('serviceReqInfo', serviceReqInfo)

        await dispatch(HttpsAction({
            method: 'PUT',
            apiSection: 'client',
            apiName: 'serviceDetails',
            queryParameter: `/${serviceRequestId}`,
            data: serviceReqInfo,
        }));
        onClose();

        // dispatch({
        //     type: `ADD_SERVICE_REQUEST_UPDATE_EDIT_FLAG`,
        //     payload: false,
        //     name: `isServiceRequestEdit`,
        //     meta: {type: `UPDATE_EDIT_FLAG`},
        // });
        // dispatch({
        //     type: `ADD_SERVICE_REQUEST_REMOVE_SERVICE_REQUEST_DATA`,
        //     payload: '',
        //     name: `serviceRequestTaskDetails`,
        //     meta: {type: `REMOVE_SERVICE_REQUEST_DATA`},
        // });
        // dispatch({
        //     type: `ADD_COMMON_REMOVE_STATE_DATA`,
        //     payload: '',
        //     name: `stateList`,
        //     meta: {type: `REMOVE_STATE_DATA`},
        // });
    };

    const tabHandler = (index) => {
        setTabIndex(index);
    };

    // const fetchData = async (res) => {
    //     try {
    //         if (res.serviceName === 'getCityList') {
    //             const response = await dispatch(getCityList(res.optionId));
    //             setCityList(response?.data);
    //         }
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };

    useEffect(() => {
        dispatch(getServiceRequestTypesList());
        dispatch(getFrequenciesList());
        dispatch(getGendersList());
        dispatch(getServiceRequestTasksList());
        // dispatch(getPatientsList());
        dispatch(getPatientsWithLatLngList());
    }, []);

    useEffect(() => {
        if (tasksList.length > 0) {
            setTaskSelection(true);
        } else {
            setTaskSelection(false);
        }
    }, [tasksList.length]);

    // useEffect(() => {
    //     alert(tasksList.length + ' - ' + tasksListUpdated)
    //     setTasksListUpdated(true);
    // }, [tasksList]);

    // useEffect(() => {
    //     if (tasksListUpdated) setTasksListUpdated(false);
    // }, [tasksListUpdated]);

    useEffect(() => {
        submitFailed && toastr.error('Please fill all mandatory fields');
        if (submitFailed && clientId === null && selectedPlace === null) {
            setSelectedValid(false);
        }
    }, [submitFailed]);

    // useEffect(() => {
    //     if (serviceRequestTaskDetails?.patientCountryId) {
    //         dispatch(HttpsAction({
    //             apiSection: 'client',
    //             apiName: 'state',
    //             queryParameter: `/${serviceRequestTaskDetails?.patientCountryId}`,
    //             reduxKeyName: 'stateList',
    //             reducerName: 'COMMON',
    //             actionType: 'GET_STATE',
    //         }));
    //         const optionData = {
    //             optionId: serviceRequestTaskDetails?.patientCountryId,
    //             serviceName: 'getStatesList'
    //         };
    //         fetchData(optionData);
    //     }
    // }, [serviceRequestTaskDetails?.patientCountryId]);

    // useEffect(() => {
    //     if (serviceRequestTaskDetails?.patientProvinceOrStateId) {
    //         const optionData = {
    //             optionId: serviceRequestTaskDetails?.patientProvinceOrStateId,
    //             serviceName: 'getCityList'
    //         };
    //         fetchData(optionData);
    //     }
    // }, [serviceRequestTaskDetails?.patientProvinceOrStateId]);

    useEffect(() => {
        if (serviceRequestTaskDetails?.patientId) {
            setClientId(serviceRequestTaskDetails?.patientId);
        }
    }, [serviceRequestTaskDetails?.patientId]);

    useEffect(() => {
        if (!dataLoaded && serviceRequestTaskDetails?.id) {
            setDataLoaded(true);
            setServiceRequestId(serviceRequestTaskDetails?.id);
            setAssignedNurseId(serviceRequestTaskDetails?.tasks?.[0]?.assignedNurseId || 0);
            setRatesValue((serviceRequestTaskDetails?.nurseOvertimeRate == null || serviceRequestTaskDetails?.nurseOvertimeRate === 0) ? "1" : "2");
            setChargeValue(serviceRequestTaskDetails?.payRateTypeId?.toString() || '');
            updateRate('agencyRateToClient', serviceRequestTaskDetails?.agencyRateToClient?.toString() || '');
            updateRate('nurseRate', serviceRequestTaskDetails?.nurseRate?.toString() || '');
            if (!(serviceRequestTaskDetails?.nurseOvertimeRate == null || serviceRequestTaskDetails?.nurseOvertimeRate === 0))
                updateRate('nurseOvertimeRate', serviceRequestTaskDetails?.nurseOvertimeRate?.toString() || '');
        }
    }, [serviceRequestTaskDetails?.id, dataLoaded]);

    return (
        <div className="app-main__outer">
            <div className="main-service-request-page">
                <div className="app-main__inner service-request bg-white">
                    <div className="row align-items-center">
                        <div className="col-sm-7 col-12">
                            <ul className="nav nav-tabs" id="myTab" role="tablist" style={{paddingTop: "unset"}}>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className={`${tabIndex === 0 ? "active" : ""}  nav-link`}
                                        onClick={() => tabHandler(0)}
                                    >
                                        Service Request Information
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className={`${tabIndex === 1 ? "active" : ""}  nav-link`}
                                        onClick={() => tabHandler(1)}
                                    >
                                        Patient Information
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className={`${tabIndex === 2 ? "active" : ""}  nav-link`}
                                        onClick={() => tabHandler(2)}
                                    >
                                        Task
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div className="col-sm-5 col-12"></div>
                    </div>
                </div>
                <div className="app-main__inner service-request-inner">
                    <div className="tab-content" id="myTabContent">
                        <form onSubmit={handleSubmit(onSubmit)} id="serviceRequestForm">
                            <div className={`srtab ${tabIndex === 0 ? "active" : ""}`}>
                                <EditServiceRequestInformationTab agencyId={agencyId}
                                                              getRatesValue={getRatesValue}
                                                              getChargeMethod={getChargeMethod} RatesValue={RatesValue}
                                                              chargeValue={chargeValue} setRate={updateRate}/>
                                <div className="form-group">
                                    <div className="col-12 text-center pt-4">
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() => {
                                                tabHandler(1);
                                            }}
                                        >
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className={`srtab ${tabIndex === 1 ? "active" : ""}`}>
                                <EditPatientInformationTab
                                    address={getCompleteAddress}
                                    isSelectedValid={isSelectedValid}
                                    getClientId={(e) => getClientId(e)}
                                    clientId={clientId}
                                    disablePatientSelection={serviceRequestTaskDetails.jobId != null}
                                    disableNurseSelection={serviceRequestTaskDetails.jobId != null || serviceRequestTaskDetails.serviceRequestStatusId !== 2}
                                    agencyId={agencyId}
                                />
                                <div className="form-group d-flex flex-row">
                                    <div className="col-12 text-center pt-4">
                                        <button
                                            type="button"
                                            className="btn btn-back"
                                            onClick={() => {
                                                tabHandler(0);
                                            }}
                                        >
                                            Back
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() => {
                                                tabHandler(2);
                                            }}
                                        >
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className={`srtab setPosition ${tabIndex === 2 ? "active" : ""}`}>
                                <div className="form-group d-flex flex-row">
                                    <div className="col-12 text-center pt-4">
                                        <button
                                            type="button"
                                            className="btn btn-back"
                                            onClick={() => {
                                                tabHandler(1);
                                            }}
                                        >
                                            Back
                                        </button>
                                        {(serviceRequestTaskDetails.jobId == null && serviceRequestTaskDetails.chartingId == null) && <button type="submit" className="btn btn-primary">
                                            Update
                                        </button>}
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className={`srtab ${tabIndex === 2 ? "active" : ""}`}>
                            <EditTask agencyId={agencyId}
                                      updateTasksArray={updateTasksArray} isTaskSelected={isTaskSelected}
                                      nursesAssigned={serviceRequestTaskDetails.nursesAssigned} assignedNurseId={assignedNurseId}
                                      isJobRelated={serviceRequestTaskDetails.jobId != null}
                                      isChartingRelated={serviceRequestTaskDetails.chartingId != null}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

// export default connect()(
//     reduxForm({
//         form: "addServiceRequest",
//         initialValues: {
//             ratesSelection: '1', // Set the initial value here
//             payRateTypeId: '1'
//         },
//     })(AddNewRequest)
// );

const mapStateToProps = (state) => {
    const { auth } = state;

    return {
        isAdmin: auth?.authData?.isAdmin || false,
        agencyId: auth?.authData?.agencyId || auth.agencyId,
    }
}

export default connect(mapStateToProps)(
    reduxForm({form: erFormVarName, enableReinitialize: true})(EditRequest)
);
