import React, {useEffect, useState} from "react";
import classes from "../css/Healthcare.module.css"
import {connect, useDispatch} from "react-redux";
import {change, Field, getFormValues, reduxForm} from "redux-form";
import CustomInputField from "../../../../../../sharedComponents/reduxfields/customInputField";
import CustomTextareaField from "../../../../../../sharedComponents/reduxfields/customTextareaField";
import PrimaryButton from "../../../../../../sharedComponents/buttons/PrimaryButton";
import {HttpsAction} from "../../../../../../commonApiFunction/httpsAction";
import {useOutletContext, useParams} from "react-router-dom";
import stringHelperFunctions from "../../../../../../helpers/string.helpers";
import * as clientAction from "../../../clientActionType";
import {getClientProfileHealthcareExtraInfo} from "../../../../../../services/client";
import arrayHelperFunctions from "../../../../../../helpers/array.helpers";
import numberHelperFunctions from "../../../../../../helpers/number.helpers";
import useCustomNav from "../../../../../../utils/hooks/useCustomNav";

// const STATIC_DIAGNOSIS = [1, 2, 3];
const maxDiagnosisLimit = 3;

function getIndexName(val) {
    let retVal = 'th';
    if (val === 1) retVal = 'st';
    if (val === 2) retVal = 'nd';
    if (val === 3) retVal = 'rd';
    return val + retVal;
}

const ExtraInformation = ({handleSubmit, formData, listLength, handleSelectTab}) => {
    const dispatch = useDispatch();
    // const {clientId} = useParams();
    const { clientId } = useOutletContext();

    const { goTo: gotoHealthcareMedicationsPage } = useCustomNav('/agency/clients/edit/healthcare/medications');

    // const diagnosis = useSelector(state => state.client?.healthcareExtraInformation?.data);

    const [hadNoData, setHadNoData] = useState(false);

    const onSubmit = (data) => {
        const {medicalNotes, medicationInformation} = data;

        const diagnosisList = [];
        for (let i = 0; i < listLength; i++) {
            if (data[`recordType${i + 1}`] !== "deleted") {
                const recordType = data[`recordType${i + 1}`];
                console.log(`index ==> ${i} ==>`, data[`recordType${i + 1}`])
                let id = data[`id${i + 1}`];

                const diagnosisVal = stringHelperFunctions.nullIfEmpty(data[`diagnosis${i + 1}`]);
                const diagnosisCodeVal = stringHelperFunctions.nullIfEmpty(data[`diagnosisCode${i + 1}`]);
                if (diagnosisVal == null && diagnosisCodeVal == null && recordType === "new") {
                  continue; // do not add/send empty record
                }
                diagnosisList.push({
                    recordType,
                    order: i + 1,
                    "id": numberHelperFunctions.undefinedIfEmpty(id, true),
                    "diagnosis": diagnosisVal,
                    "diagnosisCode": diagnosisCodeVal,
                });
            } else {
                const recordType = data[`recordType${i + 1}`];
                let id = data[`id${i + 1}`];
                diagnosisList.push({recordType, "id": id === 0 ? null : Number(id)})
            }
        }
        const payload = {
            recordType: hadNoData ? "new" : "existing",
            diagnosisList: arrayHelperFunctions.nullIfEmpty(diagnosisList),
            medicalNotes: stringHelperFunctions.nullIfEmpty(medicalNotes),
            medicationDetails: stringHelperFunctions.nullIfEmpty(medicationInformation),
        };
// console.log('payload', payload)

        dispatch(HttpsAction({
            method: hadNoData ? 'POST' : 'PUT',
            apiSection: 'client',
            apiName: 'healthcareExtraInformation',
            queryParameter: `/${clientId}`,
            data: payload,
            positiveCallBack: () => {
                // handleSelectTab(2);
                gotoHealthcareMedicationsPage();
            }
        }));
    }

    // const handleClear = (key) => dispatch(change("extraInformationForm", key, ""));
    const handleClear = (deletedIndex) => {
        const list = Array.from({ length: listLength }, (v, i) => i);
        let updatedList = list.map((val, index) => {
            return {
                id: formData[`id${index + 1}`],
                serial: index,
                diagnosis: formData[`diagnosis${index + 1}`],
                diagnosisCode: formData[`diagnosisCode${index + 1}`],
                // index,
                recordType: formData[`recordType${index + 1}`],
            };
        });
        const deletedItemIdValue = formData[`id${deletedIndex}`];
        if (deletedItemIdValue === '0') { // item not present in database, so remove item from list/array
            updatedList[deletedIndex - 1].recordType = "new";
            updatedList[deletedIndex - 1].diagnosis = "";
            updatedList[deletedIndex - 1].diagnosisCode = "";
        } else { // item present in database, so identify/mark it for deletion for later API call
            updatedList[deletedIndex - 1].recordType = "deleted";
            updatedList[deletedIndex - 1].diagnosis = "";
            updatedList[deletedIndex - 1].diagnosisCode = "";
        }
// console.log('updatedList', updatedList)
        const updatedDataObj = {
            medicationInformation: formData['medicationInformation'],
            medicalNotes: formData['medicationInformation'],
            diagnosisList: updatedList,
        };
// console.log('updatedDataObj', updatedDataObj)
        dispatch(HttpsAction({
            reducerName: 'CLIENT',
            reduxKeyName: 'healthcareExtraInformation',
            actionType: 'GET_HEALTHCARE_EXTRA_INFO',
            data: updatedDataObj,
            updateData: true,
            actionTypeIsDispatchType: true,
        }));
    };
    
    useEffect(() => {
        // console.log('test- listLength < maxDiagnosisLimit', listLength < maxDiagnosisLimit)
        if (listLength < maxDiagnosisLimit) {
            setHadNoData(listLength === 0);
            for (let i = 0; i < (maxDiagnosisLimit - listLength); i++) {
                dispatch({type: clientAction.ADD_HEALTHCARE_DIAGNOSIS});
            }
        }
    }, [listLength < maxDiagnosisLimit]);
// console.log('test- listLength', listLength)

    useEffect(() => {
        dispatch(getClientProfileHealthcareExtraInfo(clientId));
    }, []);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Field
                  id="xtra"
                  name="xtra"
                  type="hidden"
                  component={CustomInputField}
                />
                <div className="row">
                    <div className="col-12">
                        <div className={classes.extraInfoTitle}>
                            Diagnosis
                        </div>
                    </div>
                    {Array.from({ length: listLength }, (value, index) => (
                        <React.Fragment key={index}>
                            <div className={maxDiagnosisLimit === 3 ? 'col-xl-4 col-lg-5 col-md-6 col-sm-12 col-12' : 'col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12'}>
                                <Field
                                  name={`recordType${index + 1}`}
                                  type="hidden"
                                  component={CustomInputField}
                                />
                                <Field
                                  name={`id${index + 1}`}
                                  type="hidden"
                                  component={CustomInputField}
                                />
                                <div className="mb-4 input-field-main">
                                    <Field
                                        className="form-control mk-wi"
                                        name={`diagnosis${index + 1}`}
                                        label={getIndexName(index + 1)}
                                        type="text"
                                        component={CustomInputField}
                                    />
                                    <div className={'d-flex flex-row justify-content-between align-items-end gap-2 mt-3'}>
                                        <div>
                                            <Field
                                                className="form-control mk-wi"
                                                name={`diagnosisCode${index + 1}`}
                                                label="Code"
                                                type="text"
                                                component={CustomInputField}
                                                placeholder={'Code'}
                                            />
                                        </div>
                                        <div>
                                            <button type={'button'}
                                                    className={`btn btn-primary border-btn-info px-4 ${classes.redButton}`}
                                                    style={{marginBottom: '18px'}}
                                                    // onClick={() => handleClear(`diagnosisCode${index + 1}`)}>
                                                    onClick={() => handleClear(index + 1)}>
                                                CLEAR
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    ))}

                    <div className="col-12">
                        <div className="mb-4 input-field-main">
                            <Field
                                className="form-control mk-wi"
                                name="medicalNotes"
                                label="Medical Notes"
                                type="text"
                                component={CustomTextareaField}
                            />
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="mb-4 input-field-main">
                            <Field
                                className="form-control mk-wi"
                                name="medicationInformation"
                                label="Pharmacy/Medication Information"
                                type="text"
                                component={CustomTextareaField}
                            />
                        </div>
                    </div>
                </div>
                <div
                    className="d-flex justify-content-center"
                    style={{marginTop: "40px"}}
                >
                    <PrimaryButton
                        type="submit"
                        className="btn-view-repo gap-2 px-5 py-2"
                        text={"Save"}
                        onClick={() => {
                            dispatch(change("extraInformationForm", 'xtra', '1'));
                            return undefined;
                        }}
                    />
                </div>
            </form>
        </>
    )
};

const mapStateToProps = state => {
    const data = state.client?.healthcareExtraInformation || {};
// console.log('test- data', data)

    const initialValues = {};
    initialValues[`medicationInformation`] = data?.medicationInformation || '';
    initialValues[`medicalNotes`] = data?.medicalNotes || '';

    (data?.diagnosisList || []).forEach((value, index) => {
        initialValues[`recordType${index + 1}`] = value.recordType || "existing";
        initialValues[`id${index + 1}`] = value?.id?.toString() || '0';
        initialValues[`diagnosis${index + 1}`] = value?.diagnosis;
        initialValues[`diagnosisCode${index + 1}`] = value?.diagnosisCode;
        return initialValues;
    })

    return {
        listLength: (data?.diagnosisList || []).length,
        formData: getFormValues('extraInformationForm')(state),
        initialValues,
    };
}

export default connect(mapStateToProps)(
    reduxForm({form: "extraInformationForm", enableReinitialize: true})(ExtraInformation)
);
