import React, {useEffect, useState} from "react";
import CustomInputField from "../../../../sharedComponents/reduxfields/customInputField";
import CustomTextarea from "../../../../sharedComponents/reduxfields/CustomTextArea";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import PrimaryButton from "../../../../sharedComponents/buttons/PrimaryButton";
import PersonalInfoInnerTabs from "./personalInfoInnerTabs";
import {connect, useDispatch, useSelector} from "react-redux";
import {
    getNurseAction,
    postNurseAction,
} from "../../../../services/NursesProfile";
import {change, Field, getFormValues, reduxForm, reset} from "redux-form";
import * as nurshAction from "../nurseActionTypes";
import CustomCheckboxField from "../../../../sharedComponents/reduxfields/CustomCheckBox";
import CustomRadioGroup from "../../../../sharedComponents/reduxfields/customRadioGroup";
import CustomHiddenField from "../../../../sharedComponents/fields/customHiddenField";
import {HttpsAction} from "../../../../commonApiFunction/httpsAction";
import stringHelperFunctions from "../../../../helpers/string.helpers";
import SaveButton from "../../../../sharedComponents/buttons/SaveButton";
import booleanHelperFunctions from "../../../../helpers/boolean.helpers";
import {useQueryClient} from "@tanstack/react-query";
import classes from "../../Client/ClientProfile/CareNote/CareNote.module.css";
import numberHelperFunctions from "../../../../helpers/number.helpers";
import {required} from "../../../../helpers/validation";
import CustomLabel from "../../../../sharedComponents/fields/CustomLabel";
import useCustomNav from "../../../../utils/hooks/useCustomNav";

const canContactEmployeroptions = [
    {label: "Yes", id: "canContactEmployeroptionssyes", value: 'true'},
    {label: "No", id: "canContactEmployeroptionsno", value: 'false'},
];

const WorkExperience = ({formData, listLength, notNewAndNotDeletedListLength, handleSubmit, pristine, submitting, isAdmin, agencyId}) => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    // const { id: nurseId } = useParams();
    const { nurseId } = useOutletContext();

    // const isAdmin = window.location.pathname.includes('/admin/agencyNurses/edit');

    const { goTo: gotoEducationsPage } = useCustomNav(isAdmin ? `/admin/agency/nurses/edit/profile/personal-info/education` : '/agency/nurses/edit/profile/personal-info/education');

    // const workList = useSelector((state) => state.nurseListing?.nurseWorkExperience);
    // console.log({workList})

    const statesArray = queryClient.getQueryData(['list-provinces-states', 233]);

    // const [deleteButton, setDeleteButton] = useState(false);
    const [allowAutoAddition, setAllowAutoAddition] = useState(true);
    const [dataModified, setDataModified] = useState(false);
    const [xtraFieldValue, setXtraFieldValue] = useState(1);
    const [showErrorForCanContactEmployer, setShowErrorForCanContactEmployer] = useState(false);

    // let {
    //     GenderDp,
    //     languages,
    //     ethnicity,
    //     state,
    //     initialValues,
    //     handleSubmit,
    //     submitFailed,
    // } = props;

    const handleAddWorkExperience = () => {
        // console.log("workexperience");
        // dispatch({type: nurshAction.NURSE_ADD_WORK_EXPERIENCE});

        let updatedList = Array.from({length: listLength}, (v, i) => i).map((val, index) => {
// console.log('test- employerName', index + 1, formData[`employerName${index + 1}`])
            return {
                recordType: formData[`recordType${index + 1}`],
                id: formData[`id${index + 1}`],
                companyName: formData[`companyName${index + 1}`],
                companyLocation: formData[`companyLocation${index + 1}`],
                employerName: formData[`employerName${index + 1}`],
                employerPhoneNumber: formData[`employerPhoneNumber${index + 1}`],
                startDate: formData[`startDate${index + 1}`],
                endDate: formData[`endDate${index + 1}`],
                duties: formData[`duties${index + 1}`],
                reasonForLeaving: formData[`reasonForLeaving${index + 1}`],
                hasIncontinenceExperience: formData[`hasIncontinenceExperience${index + 1}`],
                hasHoyerLiftExperience: formData[`hasHoyerLiftExperience${index + 1}`],
                hasCompanionshipExperience: formData[`hasCompanionshipExperience${index + 1}`],
                hasDrivingExperience: formData[`hasDrivingExperience${index + 1}`],
                hasBathingExperience: formData[`hasBathingExperience${index + 1}`],
                hasAlzheimerExperience: formData[`hasAlzheimerExperience${index + 1}`],
                hasTransferAssistExperience: formData[`hasTransferAssistExperience${index + 1}`],
                hasSmokingExperience: formData[`hasSmokingExperience${index + 1}`],
                hasMalesExperience: formData[`hasMalesExperience${index + 1}`],
                hasFemalesExperience: formData[`hasFemalesExperience${index + 1}`],
                hasCatsExperience: formData[`hasCatsExperience${index + 1}`],
                hasDogsExperience: formData[`hasDogsExperience${index + 1}`],
                hasGaitBeltExperience: formData[`hasGaitBeltExperience${index + 1}`],
                hasDementiaExperience: formData[`hasDementiaExperience${index + 1}`],
                hasDressingExperience: formData[`hasDressingExperience${index + 1}`],
                canContactEmployer: formData[`canContactEmployer${index + 1}`],
            };
        });
        // updatedList.push({
        //     recordType: "new",
        //     id: '0',
        //     companyName: "",
        //     companyAddress: "",
        //     employerName: "",
        //     employerPhoneNumber: "",
        //     startDate: "",
        //     endDate: "",
        //     duties: "",
        //     reasonForLeaving: "",
        //     canContactEmployer: "",
        //     hasIncontinenceExperience: "",
        //     hasHoyerLiftExperience: "",
        //     hasCompanionshipExperience: "",
        //     hasDrivingExperience: "",
        //     hasBathingExperience: "",
        //     hasDressingExperience: "",
        //     hasAlzheimerExperience: "",
        //     hasDementiaExperience: "",
        //     hasTransferAssistExperience: "",
        //     hasSmokingExperience: "",
        //     hasMalesExperience: "",
        //     hasFemalesExperience: "",
        //     hasCatsExperience: "",
        //     hasGaitBeltExperience: "",
        //     hasDogsExperience: "",
        //     hasAnyOtherExperience: "",
        //     anyOtherExperienceDetails: "",
        // });
// console.log('test- updatedList', updatedList);
        dispatch(HttpsAction({
            reducerName: 'NURSE',
            reduxKeyName: 'nurseWorkExperience',
            actionType: 'NURSE_WORK_EXPERIENCE',
            data: {xtraField: formData['xtraField'], list: updatedList},
            updateData: true,
            actionTypeIsDispatchType: true,
            positiveCallBack: (res) => {
                dispatch({type: nurshAction.NURSE_ADD_WORK_EXPERIENCE});
            },
        }));
    };

    const handleDelete = (deletedIndex) => {
        // if (deletedIndex) {
        //     let clonedList = [...workList];
        //     if (clonedList[deletedIndex - 1]?.id) {
        //         const updatedCertification = {
        //             recordType: 'deleted',
        //             id: clonedList[deletedIndex - 1].id
        //         };
        //
        //         clonedList[deletedIndex - 1] = updatedCertification;
        //
        //         dispatch({
        //             type: 'ADD_NURSE_REMOVE_WORK_EXPERIENCE',
        //             payload: clonedList,
        //             name: 'nurseWorkExperience',
        //             meta: {type: 'REMOVE_WORK_EXPERIENCE'},
        //         });
        //     } else {
        //         const updatedList = workList.filter((item, index) => index !== deletedIndex - 1)
        //         dispatch({
        //             type: 'ADD_NURSE_REMOVE_WORK_EXPERIENCE',
        //             payload: updatedList,
        //             name: 'nurseWorkExperience',
        //             meta: {type: 'REMOVE_WORK_EXPERIENCE'},
        //         });
        //     }
        // }

        const list = Array.from({ length: listLength }, (v, i) => i);
        let updatedList = list.map((val, index) => {
            return {
                recordType: formData[`recordType${index + 1}`],
                id: formData[`id${index + 1}`],
                companyName: formData[`companyName${index + 1}`],
                companyLocation: formData[`companyLocation${index + 1}`],
                employerName: formData[`employerName${index + 1}`],
                employerPhoneNumber: formData[`employerPhoneNumber${index + 1}`],
                startDate: formData[`startDate${index + 1}`],
                endDate: formData[`endDate${index + 1}`],
                duties: formData[`duties${index + 1}`],
                reasonForLeaving: formData[`reasonForLeaving${index + 1}`],
                hasIncontinenceExperience: formData[`hasIncontinenceExperience${index + 1}`],
                hasHoyerLiftExperience: formData[`hasHoyerLiftExperience${index + 1}`],
                hasCompanionshipExperience: formData[`hasCompanionshipExperience${index + 1}`],
                hasDrivingExperience: formData[`hasDrivingExperience${index + 1}`],
                hasBathingExperience: formData[`hasBathingExperience${index + 1}`],
                hasAlzheimerExperience: formData[`hasAlzheimerExperience${index + 1}`],
                hasTransferAssistExperience: formData[`hasTransferAssistExperience${index + 1}`],
                hasSmokingExperience: formData[`hasSmokingExperience${index + 1}`],
                hasMalesExperience: formData[`hasMalesExperience${index + 1}`],
                hasFemalesExperience: formData[`hasFemalesExperience${index + 1}`],
                hasCatsExperience: formData[`hasCatsExperience${index + 1}`],
                hasDogsExperience: formData[`hasDogsExperience${index + 1}`],
                hasGaitBeltExperience: formData[`hasGaitBeltExperience${index + 1}`],
                hasDementiaExperience: formData[`hasDementiaExperience${index + 1}`],
                hasDressingExperience: formData[`hasDressingExperience${index + 1}`],
                canContactEmployer: formData[`canContactEmployer${index + 1}`],
                // index,
            };
        });
        const deletedItemIdValue = formData[`id${deletedIndex}`];
        if (deletedItemIdValue === '0') { // item not present in database, so remove item from list/array
            updatedList.splice(deletedIndex - 1, 1);
        } else { // item present in database, so identify/mark it for deletion for later API call
            updatedList[deletedIndex - 1].recordType = "deleted";
        }
// console.log('test- updatedList', updatedList)
        setAllowAutoAddition(false);
        dispatch(HttpsAction({
            reducerName: 'NURSE',
            reduxKeyName: 'nurseWorkExperience',
            actionType: 'NURSE_WORK_EXPERIENCE',
            data: {xtraField: formData['xtraField'], list: updatedList},
            updateData: true,
            actionTypeIsDispatchType: true,
            positiveCallBack: (_) => {
                setDataModified(true);
            },
        }));
    };

    const onSubmit = async (data) => {
// console.log('test- onSubmit', data)
        // // let payload = Object.assign({}, values);
        // // console.log(payload, "payload");
        // const payload = [];
        // // console.log(data, "data");
        // // return false;
        // (workList ?? []).forEach((value, index) => {
        //     if (value.recordType !== "deleted") {
        //         // if (index == 0) {
        //         payload.push({
        //             id: data[`id${index + 1}`],
        //             recordType: data[`recordType${index + 1}`] || "new",
        //             companyName: stringHelperFunctions.nullIfEmpty(data[`companyName${index + 1}`]),
        //             companyLocation: stringHelperFunctions.nullIfEmpty(data[`companyLocation${index + 1}`]),
        //             employerName: stringHelperFunctions.nullIfEmpty(data[`employerName${index + 1}`]),
        //             employerPhoneNumber: stringHelperFunctions.nullIfEmpty(data[`employerPhoneNumber${index + 1}`]),
        //             startDate: stringHelperFunctions.nullIfEmpty(data[`startDate${index + 1}`]),
        //             endDate: stringHelperFunctions.nullIfEmpty(data[`endDate${index + 1}`]),
        //             duties: stringHelperFunctions.nullIfEmpty(data[`duties${index + 1}`]),
        //             reasonForLeaving: stringHelperFunctions.nullIfEmpty(data[`reasonForLeaving${index + 1}`]),
        //             canContactEmployer: Boolean(data[`canContactEmployer${index + 1}`]),
        //             hasIncontinenceExperience: Boolean(
        //                 data[`hasIncontinenceExperience${index + 1}`]
        //             ),
        //             hasHoyerLiftExperience: Boolean(
        //                 data[`hasHoyerLiftExperience${index + 1}`]
        //             ),
        //             hasCompanionshipExperience: Boolean(
        //                 data[`hasCompanionshipExperience${index + 1}`]
        //             ),
        //             hasDrivingExperience: Boolean(data[`hasDrivingExperience${index + 1}`]),
        //             hasBathingExperience: Boolean(data[`hasBathingExperience${index + 1}`]),
        //             hasDressingExperience: Boolean(
        //                 data[`hasDressingExperience${index + 1}`]
        //             ),
        //             hasAlzheimerExperience: Boolean(
        //                 data[`hasAlzheimerExperience${index + 1}`]
        //             ),
        //             hasDementiaExperience: Boolean(
        //                 data[`hasDementiaExperience${index + 1}`]
        //             ),
        //             hasTransferAssistExperience: Boolean(
        //                 data[`hasTransferAssistExperience${index + 1}`]
        //             ),
        //             hasSmokingExperience: Boolean(data[`hasSmokingExperience${index + 1}`]),
        //             hasMalesExperience: Boolean(data[`hasMalesExperience${index + 1}`]),
        //             hasFemalesExperience: Boolean(data[`hasFemalesExperience${index + 1}`]),
        //             hasCatsExperience: Boolean(data[`hasCatsExperience${index + 1}`]),
        //             hasDogsExperience: Boolean(data[`hasDogsExperience${index + 1}`]),
        //             hasGaitBeltExperience: Boolean(
        //                 data[`hasGaitBeltExperience${index + 1}`]
        //             ),
        //             hasAnyOtherExperience: Boolean(
        //                 data[`hasAnyOtherExperience${index + 1}`]
        //             ),
        //             anyOtherExperienceDetails:
        //                 data[`anyOtherExperienceDetails${index + 1}`],
        //         });
        //     } else {
        //         payload.push(value)
        //     }
        // });
        //
        // // console.log(payload, "payload");
        // // return false;
        // try {
        //     const response = await dispatch(
        //         postNurseAction(
        //             payload,
        //             "workExperience",
        //             params.nurseId,
        //             "Work Experiences Updated Successfully"
        //         )
        //     );
        //     if (response?.success) {
        //         navigate(isAdmin ? `/admin/agencyNurses/edit/${params.nurseId}/education` : `/agency/nurses/edit/${params.nurseId}/education`)
        //     }
        // } catch (error) {
        //     console.error(error);
        // }

        if (showErrorForCanContactEmployer) return;

        try {
            const payload = [];
            for (let i = 0; i < listLength; i++) {
                if (data[`recordType${i + 1}`] !== "deleted") {
                    const recordType = data[`recordType${i + 1}`];
// console.log(`test- index ==> ${i} ==>`, data[`recordType${i + 1}`], '; employerName:', data[`employerName${i + 1}`])
                    let id = +data[`id${i + 1}`];
                    payload.push({
                        recordType,
                        id: id === 0 ? undefined : id,
                        companyName: stringHelperFunctions.nullIfEmpty(data[`companyName${i + 1}`]),
                        companyLocation: stringHelperFunctions.nullIfEmpty(data[`companyLocation${i + 1}`]),
                        employerName: stringHelperFunctions.nullIfEmpty(data[`employerName${i + 1}`]),
                        employerPhoneNumber: stringHelperFunctions.nullIfEmpty(data[`employerPhoneNumber${i + 1}`]),
                        startDate: stringHelperFunctions.nullIfEmpty(data[`startDate${i + 1}`]),
                        endDate: stringHelperFunctions.nullIfEmpty(data[`endDate${i + 1}`]),
                        duties: stringHelperFunctions.nullIfEmpty(data[`duties${i + 1}`]),
                        reasonForLeaving: stringHelperFunctions.nullIfEmpty(data[`reasonForLeaving${i + 1}`]),
                        hasIncontinenceExperience: booleanHelperFunctions.nullIfEmpty(data[`hasIncontinenceExperience${i + 1}`]),
                        hasHoyerLiftExperience: booleanHelperFunctions.nullIfEmpty(data[`hasHoyerLiftExperience${i + 1}`]),
                        hasCompanionshipExperience: booleanHelperFunctions.nullIfEmpty(data[`hasCompanionshipExperience${i + 1}`]),
                        hasDrivingExperience: booleanHelperFunctions.nullIfEmpty(data[`hasDrivingExperience${i + 1}`]),
                        hasBathingExperience: booleanHelperFunctions.nullIfEmpty(data[`hasBathingExperience${i + 1}`]),
                        hasAlzheimerExperience: booleanHelperFunctions.nullIfEmpty(data[`hasAlzheimerExperience${i + 1}`]),
                        hasTransferAssistExperience: booleanHelperFunctions.nullIfEmpty(data[`hasTransferAssistExperience${i + 1}`]),
                        hasSmokingExperience: booleanHelperFunctions.nullIfEmpty(data[`hasSmokingExperience${i + 1}`]),
                        hasMalesExperience: booleanHelperFunctions.nullIfEmpty(data[`hasMalesExperience${i + 1}`]),
                        hasFemalesExperience: booleanHelperFunctions.nullIfEmpty(data[`hasFemalesExperience${i + 1}`]),
                        hasCatsExperience: booleanHelperFunctions.nullIfEmpty(data[`hasCatsExperience${i + 1}`]),
                        hasDogsExperience: booleanHelperFunctions.nullIfEmpty(data[`hasDogsExperience${i + 1}`]),
                        hasGaitBeltExperience: booleanHelperFunctions.nullIfEmpty(data[`hasGaitBeltExperience${i + 1}`]),
                        hasDementiaExperience: booleanHelperFunctions.nullIfEmpty(data[`hasDementiaExperience${i + 1}`]),
                        hasDressingExperience: booleanHelperFunctions.nullIfEmpty(data[`hasDressingExperience${i + 1}`]),
                        canContactEmployer: booleanHelperFunctions.nullIfEmpty(data[`canContactEmployer${i + 1}`]),
                    });
                } else {
                    const recordType = data[`recordType${i + 1}`];
                    let id = +data[`id${i + 1}`];
                    payload.push({recordType, id: id === 0 ? undefined : Number(id)})
                }
            }

// console.log('test- payload', payload);
            const response = await dispatch(postNurseAction(payload, "workExperience", nurseId, "Work experiences updated successfully"));
            if (response?.success) {
                // navigate(isAdmin ? `/admin/agencyNurses/edit/${nurseId}/education` : `/agency/nurses/edit/${nurseId}/education`)
                gotoEducationsPage();
            }
        } catch (error) {
            console.error(error);
        }
    };

    // const handleCheckSwitch = (e) => {
    //     // e.target.checked = !e.target.checked;
    //     // initialValues[`${e.target.name}`] = e.target.checked;
    //     // let initialValuesupdates = { ...initialValues };
    //     // initialValuesupdates[e.target.name] = e.target.checked;
    //     // initialValues = { ...initialValues, ...initialValuesupdates };
    //     // initialValues[e.target.name] = e.target.checked;
    //     // console.log(initialValues, "initialValues");
    //     // console.log(e.target, "checked");
    // };

    // useEffect(() => {
    //     if (workList?.length === 0 && deleteButton === false) {
    //         dispatch({type: nurshAction.NURSE_ADD_WORK_EXPERIENCE});
    //     }
    // }, [workList?.length === 0 && deleteButton === false]);

    useEffect(() => {
        if (nurseId != null) {
            dispatch(getNurseAction("nurseWorkExperience", nurseId));
        }

        return () => {
            dispatch(HttpsAction({
                reducerName: 'NURSE',
                reduxKeyName: 'nurseWorkExperience',
                actionType: 'NURSE_WORK_EXPERIENCE',
                removeData: true,
                actionTypeIsDispatchType: true,
            }));
            dispatch(reset('WorkExperienceForm'));
        };
    }, []);

    useEffect(() => {
        if (dataModified) {
            setDataModified(false);
            dispatch(change("WorkExperienceForm", 'xtraField', String(xtraFieldValue), true));
            setXtraFieldValue(prevState => prevState + 1);
        }
    }, [dataModified]);

    useEffect(() => {
        if (allowAutoAddition && listLength === 0) {
            dispatch({type: nurshAction.NURSE_ADD_WORK_EXPERIENCE});
        }
    }, [listLength]);
// console.log('test- listLength', listLength)
// console.log('test- formData', formData)

    const handleCanContactEmployer = (e, employerName) => {
        if (e.target.value === 'true' && employerName.length === 0) {
            setShowErrorForCanContactEmployer(true);
        }
        if (e.target.value === 'false') {
            setShowErrorForCanContactEmployer(false);
        }
    };

    return (
        <div>
            <div className="card-white px-4">
                {/*<PersonalInfoInnerTabs></PersonalInfoInnerTabs>*/}
                <form className="pt-3" onSubmit={handleSubmit(onSubmit)}>
                    <Field
                      name="xtraField"
                      type="hidden"
                      component={CustomInputField}
                    />
                    <div className="p-0 m-0 d-flex flex-column justify-content-start align-content-start gap-3">
                        {/*{(workList || []).filter(v => v.recordType !== 'deleted').map((value, index) => (*/}
                        {listLength > 0 && (Array.from({length: listLength}, (val, idx) => idx) || []).map((value, index) => (
                          <React.Fragment key={index}>
                              <div className="card" style={{
                                  overflow: "hidden",
                                  borderWidth: formData != null && JSON.stringify(formData) !== '{}' && formData[`recordType${index + 1}`] === "deleted" ? "0" : "auto",
                                  height: formData != null && JSON.stringify(formData) !== '{}' && formData[`recordType${index + 1}`] === "deleted" ? "1px" : "auto"
                              }}>
                                  <div className="card-body">
                                      <div className={`row ${index === 0 ? '' : 'mt-2'}`}>
                                          <div className="col-6 d-flex align-items-center">
                                              <div className={`col-4`} style={{fontSize: "18px", fontWeight: 500, whiteSpace: "nowrap"}}>
                                                  Work Experience #{index + 1}
                                              </div>
                                          </div>
                                          <div className="col-6 d-flex justify-content-end">
                                              <Field
                                                name={`recordType${index + 1}`}
                                                type="hidden"
                                                component={CustomInputField}
                                              />
                                              <Field
                                                name={`id${index + 1}`}
                                                type="hidden"
                                                component={CustomInputField}
                                              />
                                              {(notNewAndNotDeletedListLength > 0 || listLength > 1 || index > 0) &&
                                                <PrimaryButton
                                                  type="button"
                                                  className="btn-view-repo gap-2 px-4 py-2"
                                                  text="Delete Experience"
                                                  onClick={() => handleDelete(index + 1)}
                                                />}
                                          </div>
                                              <div className={classes.contactFormHeadingBorder}/>
                                              <div style={{padding: '24px 24px 0 24px'}}>
                                                  <div>
                                                      <div className="row">
                                                          <div className="col-12 col-md-6">
                                                              <div className="mb-4">
                                                                  <Field
                                                                    className="form-control mk-wi"
                                                                    name={`companyName${index + 1}`}
                                                                    label="Organization Name"
                                                                    component={CustomInputField}
                                                                    requiredMark={true}
                                                                    validate={required}
                                                                  />
                                                              </div>
                                                          </div>
                                                          <div className="col-12 col-md-6">
                                                              <div className="mb-4">
                                                                  <Field
                                                                    className="form-control mk-wi"
                                                                    name={`companyLocation${index + 1}`}
                                                                    label="Organization address"
                                                                    component={CustomInputField}
                                                                  />
                                                              </div>
                                                          </div>
                                                          <div className="col-12 col-md-6">
                                                              <div className="mb-4">
                                                                  <Field
                                                                    className="form-control mk-wi"
                                                                    name={`employerName${index + 1}`}
                                                                    label="Employer Name"
                                                                    component={CustomInputField}
                                                                  />
                                                              </div>
                                                          </div>
                                                          <div className="col-12 col-md-6">
                                                              <div className="mb-4">
                                                                  <Field
                                                                    className="form-control mk-wi"
                                                                    name={`employerPhoneNumber${index + 1}`}
                                                                    label="Employer Phone Number"
                                                                    component={CustomInputField}
                                                                  />
                                                              </div>
                                                          </div>
                                                          <div className="col-12 col-md-6">
                                                              <div className="mb-4">
                                                                  <Field
                                                                    className="form-control mk-wi"
                                                                    name={`startDate${index + 1}`}
                                                                    label="Start Date"
                                                                    type="date"
                                                                    component={CustomInputField}
                                                                  />
                                                              </div>
                                                          </div>
                                                          <div className="col-12 col-md-6">
                                                              <div className="mb-4">
                                                                  <Field
                                                                    className="form-control mk-wi"
                                                                    name={`endDate${index + 1}`}
                                                                    label="End Date"
                                                                    type="date"
                                                                    component={CustomInputField}
                                                                  />
                                                              </div>
                                                          </div>
                                                          <div className="col-12 col-md-6">
                                                              <div className="mb-4">
                                                                  <Field
                                                                    className="form-control mk-wi"
                                                                    name={`duties${index + 1}`}
                                                                    label="Describe Duties"
                                                                    component={CustomTextarea}
                                                                  />
                                                              </div>
                                                          </div>
                                                          <div className="col-12 col-md-6">
                                                              <div className="mb-4">
                                                                  <Field
                                                                    className="form-control mk-wi"
                                                                    name={`reasonForLeaving${index + 1}`}
                                                                    label="Reason For Leaving"
                                                                    component={CustomTextarea}
                                                                  />
                                                              </div>
                                                          </div>
                                                          <div className="col-12 col-md-12">
                                                              <CustomLabel labelText="Did you get experience with any of the following when you were working with this organization?" />
                                                              <div className="row mb-4">
                                                                  <div className="col-md-3 col-12">
                                                                      <Field
                                                                        component={CustomCheckboxField}
                                                                        label="Incontinence"
                                                                        className="form-check-input me-1"
                                                                        name={`hasIncontinenceExperience${index + 1}`}
                                                                        // cchecked={
                                                                        //   value.hasIncontinenceExperience ? true : false
                                                                        // }
                                                                        // onChange={handleCheckSwitch}
                                                                      />
                                                                  </div>
                                                                  <div className="col-md-3 col-12">
                                                                      <Field
                                                                        component={CustomCheckboxField}
                                                                        label="Hoyer Lift"
                                                                        className="form-check-input me-1"
                                                                        name={`hasHoyerLiftExperience${index + 1}`}
                                                                        // cchecked={
                                                                        //   value.hasHoyerLiftExperience ? true : false
                                                                        // }
                                                                        // onChange={handleCheckSwitch}
                                                                      />
                                                                  </div>
                                                                  <div className="col-md-3 col-12">
                                                                      <Field
                                                                        component={CustomCheckboxField}
                                                                        label="Companionship"
                                                                        className="form-check-input me-1"
                                                                        name={`hasCompanionshipExperience${index + 1}`}
                                                                        // cchecked={
                                                                        //   value.hasCompanionshipExperience ? true : false
                                                                        // }
                                                                        // onChange={handleCheckSwitch}
                                                                      />
                                                                  </div>
                                                                  <div className="col-md-3 col-12">
                                                                      <Field
                                                                        component={CustomCheckboxField}
                                                                        label="Driving"
                                                                        className="form-check-input me-1"
                                                                        name={`hasDrivingExperience${index + 1}`}
                                                                        // cchecked={
                                                                        //   value.hasDrivingExperience ? true : false
                                                                        // }
                                                                        // onChange={handleCheckSwitch}
                                                                      />
                                                                  </div>
                                                                  <div className="col-md-3 col-12">
                                                                      <Field
                                                                        component={CustomCheckboxField}
                                                                        label="Bathing/Dressing"
                                                                        className="form-check-input me-1"
                                                                        name={`hasBathingExperience${index + 1}`}
                                                                        // cchecked={
                                                                        //   value.hasBathingExperience ? true : false
                                                                        // }
                                                                        // onChange={handleCheckSwitch}
                                                                      />
                                                                  </div>
                                                                  <div className="col-md-3 col-12">
                                                                      <Field
                                                                        component={CustomCheckboxField}
                                                                        label="Alzheimer's/Dementia"
                                                                        className="form-check-input me-1"
                                                                        name={`hasAlzheimerExperience${index + 1}`}
                                                                        // cchecked={
                                                                        //   value.hasAlzheimerExperience ? true : false
                                                                        // }
                                                                        // onChange={handleCheckSwitch}
                                                                      />
                                                                  </div>
                                                                  <div className="col-md-3 col-12">
                                                                      <Field
                                                                        component={CustomCheckboxField}
                                                                        label="Transfer Assist"
                                                                        className="form-check-input me-1"
                                                                        name={`hasTransferAssistExperience${index + 1}`}
                                                                        // cchecked={
                                                                        //   value.hasTransferAssistExperience ? true : false
                                                                        // }
                                                                        // onChange={handleCheckSwitch}
                                                                      />
                                                                  </div>
                                                                  <div className="col-md-3 col-12">
                                                                      <Field
                                                                        component={CustomCheckboxField}
                                                                        label="Smoking"
                                                                        className="form-check-input me-1"
                                                                        name={`hasSmokingExperience${index + 1}`}
                                                                        // cchecked={
                                                                        //   value.hasSmokingExperience ? true : false
                                                                        // }
                                                                        // onChange={handleCheckSwitch}
                                                                      />
                                                                  </div>
                                                                  <div className="col-md-3 col-12">
                                                                      <Field
                                                                        component={CustomCheckboxField}
                                                                        label="Male"
                                                                        className="form-check-input me-1"
                                                                        name={`hasMalesExperience${index + 1}`}
                                                                        // cchecked={value.hasMalesExperience ? true : false}
                                                                        // onChange={handleCheckSwitch}
                                                                      />
                                                                  </div>
                                                                  <div className="col-md-3 col-12">
                                                                      <Field
                                                                        component={CustomCheckboxField}
                                                                        label="Females"
                                                                        className="form-check-input me-1"
                                                                        name={`hasFemalesExperience${index + 1}`}
                                                                        // cchecked={
                                                                        //   value.hasFemalesExperience ? true : false
                                                                        // }
                                                                        // onChange={handleCheckSwitch}
                                                                      />
                                                                  </div>
                                                                  <div className="col-md-3 col-12">
                                                                      <Field
                                                                        component={CustomCheckboxField}
                                                                        label="Cats"
                                                                        className="form-check-input me-1"
                                                                        name={`hasCatsExperience${index + 1}`}
                                                                        // cchecked={value.hasCatsExperience ? true : false}
                                                                        // onChange={handleCheckSwitch}
                                                                      />
                                                                  </div>
                                                                  <div className="col-md-3 col-12">
                                                                      <Field
                                                                        component={CustomCheckboxField}
                                                                        label="Dogs"
                                                                        className="form-check-input me-1"
                                                                        name={`hasDogsExperience${index + 1}`}
                                                                        // cchecked={value.hasDogsExperience ? true : false}
                                                                        // onChange={handleCheckSwitch}
                                                                      />
                                                                  </div>
                                                                  <div className="col-md-3 col-12">
                                                                      <Field
                                                                        component={CustomCheckboxField}
                                                                        label="Gait Belt"
                                                                        className="form-check-input me-1"
                                                                        name={`hasGaitBeltExperience${index + 1}`}
                                                                        // cchecked={
                                                                        //   value.hasGaitBeltExperience ? true : false
                                                                        // }
                                                                        // onChange={handleCheckSwitch}
                                                                      />
                                                                  </div>

                                                                  <div className="col-md-3 col-12">
                                                                      <Field
                                                                        component={CustomCheckboxField}
                                                                        label="Other"
                                                                        className="form-check-input me-1"
                                                                        name={`hasAnyOtherExperience${index + 1}`}
                                                                        // cchecked={
                                                                        //   value.hasAnyOtherExperience ? true : false
                                                                        // }
                                                                        // onChange={handleCheckSwitch}
                                                                      />
                                                                  </div>
                                                              </div>
                                                          </div>
                                                          <div className="col-12 col-md-6">
                                                              <CustomLabel labelText="May we contact your previous employer?" />
                                                              <div className="row position-relative">
                                                                  <div className="col-md-3 col-12">
                                                                      <Field
                                                                        name={`canContactEmployer${index + 1}`}
                                                                        component={CustomRadioGroup}
                                                                        options={canContactEmployeroptions}
                                                                        requiredMark={false}
                                                                        onChange={(e) => handleCanContactEmployer(e, formData[`employerName${index + 1}`])}
                                                                      />
                                                                      {showErrorForCanContactEmployer && <span className="field-error position-absolute -bottom-12px w-max">You need to provide Employer name first</span>}
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>

                                          </div>
                                      </div>
                                  </div>
                          </React.Fragment>
                        ))}
                        <div className={'w-100 d-flex flex-row justify-content-center gap-2'}>
                            <PrimaryButton
                              type="button"
                              className="btn btn-primary w-100 rounded-0 border-btn-info"
                              style={{maxWidth: '210px'}}
                              text="Add New Work Experience"
                              onClick={() => handleAddWorkExperience()}
                            />
                        </div>
                    </div>
                    <SaveButton pristine={pristine} submitting={submitting}
                                onClick={() => {
                                    dispatch(change("WorkExperienceForm", 'xtraField', String(xtraFieldValue)));
                                    setXtraFieldValue(prevState => prevState + 1);
                                    return undefined;
                                }}
                    />
                </form>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    if (state?.nurseListing?.nurseWorkExperience != null) {
        const {auth, nurseListing: {nurseWorkExperience: {xtraField = '0', list: dataList = []} = {xtraField: '0', list: []}}} = state;

        let initialValues = {xtraField};
        dataList?.forEach((work, index) => {
// console.log('test- work', work)
            // initialValues[`id${index + 1}`] = work.id ? work.id : "";
            // initialValues[`recordType${index + 1}`] = work.id ? "existing" : "new";
            initialValues[`recordType${index + 1}`] = work.recordType || "existing";
            initialValues[`id${index + 1}`] = work?.id?.toString() || '0';
// console.log('test- company name', index + 1, stringHelperFunctions.sanitize(work.companyName))
            initialValues[`companyName${index + 1}`] = stringHelperFunctions.sanitize(work.companyName);
            initialValues[`companyLocation${index + 1}`] = stringHelperFunctions.sanitize(work.companyLocation);
            initialValues[`employerName${index + 1}`] = stringHelperFunctions.sanitize(work.employerName);
            initialValues[`employerPhoneNumber${index + 1}`] = stringHelperFunctions.sanitize(work.employerPhoneNumber);
            initialValues[`startDate${index + 1}`] = stringHelperFunctions.sanitize(work.startDate);
            initialValues[`endDate${index + 1}`] = stringHelperFunctions.sanitize(work.endDate);
            initialValues[`duties${index + 1}`] = stringHelperFunctions.sanitize(work.duties);
            initialValues[`reasonForLeaving${index + 1}`] = stringHelperFunctions.sanitize(work.reasonForLeaving);
            initialValues[`hasIncontinenceExperience${index + 1}`] = booleanHelperFunctions.parseBoolean(work.hasIncontinenceExperience);
            initialValues[`hasHoyerLiftExperience${index + 1}`] = booleanHelperFunctions.parseBoolean(work.hasHoyerLiftExperience);
            initialValues[`hasCompanionshipExperience${index + 1}`] = booleanHelperFunctions.parseBoolean(work.hasCompanionshipExperience);
            initialValues[`hasDrivingExperience${index + 1}`] = booleanHelperFunctions.parseBoolean(work.hasDrivingExperience);
            initialValues[`hasBathingExperience${index + 1}`] = booleanHelperFunctions.parseBoolean(work.hasBathingExperience);
            initialValues[`hasAlzheimerExperience${index + 1}`] = booleanHelperFunctions.parseBoolean(work.hasAlzheimerExperience);
            initialValues[`hasTransferAssistExperience${index + 1}`] = booleanHelperFunctions.parseBoolean(work.hasTransferAssistExperience);
            initialValues[`hasSmokingExperience${index + 1}`] = booleanHelperFunctions.parseBoolean(work.hasSmokingExperience);
            initialValues[`hasMalesExperience${index + 1}`] = booleanHelperFunctions.parseBoolean(work.hasMalesExperience);
            initialValues[`hasFemalesExperience${index + 1}`] = booleanHelperFunctions.parseBoolean(work.hasFemalesExperience);
            initialValues[`hasCatsExperience${index + 1}`] = booleanHelperFunctions.parseBoolean(work.hasCatsExperience);
            initialValues[`hasDogsExperience${index + 1}`] = booleanHelperFunctions.parseBoolean(work.hasDogsExperience);
            initialValues[`hasGaitBeltExperience${index + 1}`] = booleanHelperFunctions.parseBoolean(work.hasGaitBeltExperience);
            initialValues[`hasDementiaExperience${index + 1}`] = booleanHelperFunctions.parseBoolean(work.hasDementiaExperience);
            initialValues[`hasDressingExperience${index + 1}`] = booleanHelperFunctions.parseBoolean(work.hasDressingExperience);
            initialValues[`canContactEmployer${index + 1}`] = booleanHelperFunctions.convertToString(work.canContactEmployer);
        });
// console.log('test- initialValues', initialValues);

        return {
            // state: common.stateList || [],
            isAdmin: auth?.authData?.isAdmin || false,
            agencyId: auth?.authData?.agencyId || auth.agencyId,
            listLength: (dataList || []).length,
            notNewAndNotDeletedListLength: (dataList || []).filter(p => !(p.recordType === 'new' || p.recordType === 'deleted')).length,
            formData: getFormValues('WorkExperienceForm')(state),
            initialValues,
        };
    }

    return {};
};

export default connect(mapStateToProps)(
  reduxForm({form: "WorkExperienceForm", enableReinitialize: true})(WorkExperience)
);
