import React, {useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from "react-redux";
import ReactQuill from 'react-quill';
import {change, Field} from 'redux-form';
import {required} from '../../../helpers/validation'
import CustomDropDownField from '../../../sharedComponents/reduxfields/customDropDown'
import CustomInputField from '../../../sharedComponents/reduxfields/customInputField'
import CustomCheckboxField from '../../../sharedComponents/reduxfields/CustomCheckBox'
import CustomRadioGroup from "../../../sharedComponents/reduxfields/customRadioGroup";
import Autocomplete from '../../../sharedComponents/googleAddressAuto/index';
import 'react-quill/dist/quill.snow.css';
import {useParams} from "react-router-dom";
import MultiSelectDropdown from "../../../sharedComponents/multiSelect";
import {getSpecialtiesList} from "../../../services/commonServices";
import {useQueryClient} from "@tanstack/react-query";
import AddFundsComponent from "../shared/AddFunds";
import numberHelperFunctions from "../../../helpers/number.helpers";
import stringHelperFunctions from "../../../helpers/string.helpers";
import PrimaryButton from "../../../sharedComponents/buttons/PrimaryButton";

const chargeMethod = [
    {label: 'Per Hour', id: "Per_Hour", value: '1'},
    {label: 'Per Visit', id: "Per_Visit", value: '2'},
    {label: 'Per Day', id: "Per_Day", value: '3'},
];

const PostJobForm = (props, {onOptionChange}) => {
    const {
        statesArray,
        getStateId,
        cityList,
        // skills,
        updatePlace,
        description,
        isSelectedValid,
        isDescriptionValid,
        selectedSpecialtiesOptions,
        getSelectedOptions,
        agencyBalance,
        totalAmountReserved,
        formData,
    } = props;

    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const {id} = useParams();

    const getJobById = useSelector(store => store.serviceRequest?.getJobById?.data);
    const specialties = useSelector(store => store.common?.specialtiesList);

    const professionsArray = queryClient.getQueryData(['list-professions']);
    const shiftTimingsArray = queryClient.getQueryData(['list-shift-timings']);
    const formTemplateTypesList = queryClient.getQueryData(['list-form-template-types']);

    const [value, setValue] = useState('');
    const [selectedPlace, setSelectedPlace] = useState();
    const [selectedProfession, setSelectedProfession] = useState(null);
    const [taskSelectionValidation, setTaskSelectionValidation] = useState(false);
    const [totalCost, setTotalCost] = useState(0);
    const [additionalFundsReq, setAdditionalFundsReq] = useState(0);

    const handlePlaceSelected = place => {
        console.log('place', place)
        updatePlace(place);
        setSelectedPlace(place);
    };

    const getValue = (e) => {
        description(e)
        setValue(e)
    }

    // const filteredSpecialties = (specialties || []).filter(obj => Number(obj.classId) === Number(selectedProfession));
    const filteredSpecialties = (specialties || []);

    const getSelectedOptionsThisComponent = (selectedOptions) => {
        getSelectedOptions(selectedOptions);
        if (selectedOptions.length > 0) {
            setTaskSelectionValidation(false)
        } else {
            setTaskSelectionValidation(true)
        }
    }

    const handleChangeProfessionList = (event) => {
        setSelectedProfession(event.target.value)
        if (event.target.value !== selectedProfession) {
            getSelectedOptions([])
        }
    };

    useEffect(() => {
        if (selectedProfession != null && +selectedProfession > 0) {
            dispatch(getSpecialtiesList(selectedProfession));
        }
    }, [selectedProfession]);

    useEffect(() => {
        if (getJobById && id) {
            getValue(getJobById?.description);
            setSelectedPlace({
                address: getJobById?.jobLocation || '',
                latLng: {lat: getJobById?.jobLatitude?.toString(), lng: getJobById?.jobLongitude?.toString()},
            });
        }
    }, [getJobById && id]);

    useEffect(() => {
        if (formData != null && JSON.stringify(formData) !== '{}') {
            const currentDate = new Date();
            let totalCostVal = 0;
            const startDateStr = stringHelperFunctions.nullIfEmpty(formData['startDate']);
            const endDateStr = stringHelperFunctions.nullIfEmpty(formData['endDate']);
            const startDate = new Date(Date.parse(startDateStr + 'T00:00:00+00:00'));
            const endDate = new Date(Date.parse(endDateStr + 'T00:00:00+00:00'));
            const daysCount = ((endDate.getTime() - startDate.getTime()) / 1000 / 60 / 60 / 24) + 1;

            const payRateTypeIdVal = numberHelperFunctions.nullIfEmpty(formData['payRateTypeId']);
            const nurseRate = numberHelperFunctions.nullIfEmptyFloat(formData['budgetAmount']);

            const startTimeStr = stringHelperFunctions.nullIfEmpty(formData['startTime']);
            const endTimeStr = stringHelperFunctions.nullIfEmpty(formData['endTime']);
            const endTime = new Date(Date.parse(currentDate.toISOString().substring(0, 10) + 'T' + endTimeStr + '+00:00'));
            const startTime = new Date(Date.parse(currentDate.toISOString().substring(0, 10) + 'T' + startTimeStr + '+00:00'));
            const shiftHours = numberHelperFunctions.round((endTime.getTime() - startTime.getTime()) / 1000 / 60 / 60);
            const totalHours = numberHelperFunctions.round(shiftHours * daysCount);

            dispatch(change("postJob", 'shiftHours', String(shiftHours)));
            dispatch(change("postJob", 'totalHours', String(totalHours)));

// console.log('test- totalCostVal', daysCount, startDateStr, endDateStr, payRateTypeIdVal, startTimeStr, endTimeStr, nurseRate);
            if (payRateTypeIdVal === 1) {
                totalCostVal += nurseRate * daysCount * shiftHours;
            } else {
                totalCostVal += nurseRate * daysCount;
            }
            setTotalCost(numberHelperFunctions.round(totalCostVal));
            const moreFunds = agencyBalance - totalAmountReserved - totalCostVal;
            if (moreFunds < 0) setAdditionalFundsReq(Math.abs(Math.floor(moreFunds))); // for negative values use Math.floor else use Math.ceil
            else if (totalCostVal >= 0) setAdditionalFundsReq(0);
        }
    }, [formData]);

    // useEffect(() => {
    //     setSelectedPlace({
    //         address: '',
    //         latLng: {lat: '', lng: ''},
    //     });
    // }, []);

    // console.log('filteredSpecialties', filteredSpecialties)

    return (
      <>
          <div className='col-sm-12 pl-0 pr-0 mt-2'>
              <Field
                className="form-control mk-wi"
                name="title"
                label="Title"
                type="text"
                placeholder={'RN - Cancer Care'}
                component={CustomInputField}
                requiredMark={true}
                validate={(e) => required(e)}
              />
          </div>
          <div className='col-sm-12 pl-0 pr-0 mt-2'>
              <label>Profession <span className="mandatory-field">*</span></label>
              <Field
                className="form-control"
                name="classId"
                component={CustomDropDownField}
                requiredMark={true}
                onChange={handleChangeProfessionList}
                validate={(e) => required(e)}
              >
                  <option value="">Please select</option>
                  {(professionsArray || []).map((res, index) => {
                      return (
                        <option key={index} value={res?.id}>
                            {res?.name}
                        </option>
                      );
                  })}
              </Field>
          </div>
          <div className='col-sm-12 pl-0 pr-0 mt-2'>
              {/*<label>Specialties</label>*/}
              {/*<Field*/}
              {/*    className="form-control"*/}
              {/*    name="skillId"*/}
              {/*    component={CustomDropDownField}*/}
              {/*    validate={required}*/}
              {/*    onChange={onOptionChange}*/}
              {/*>*/}
              {/*    <option value="">Please Select</option>*/}
              {/*    {filteredSpecialties?.map((res, index) => {*/}
              {/*        return (*/}
              {/*            <option key={index} value={res.id}>*/}
              {/*                {res.name}*/}
              {/*            </option>*/}
              {/*        );*/}
              {/*    })}*/}
              {/*</Field>*/}
              <MultiSelectDropdown
                dpOptions={filteredSpecialties || []}
                getSelectedOptions={getSelectedOptionsThisComponent}
                selectedLanguages={selectedSpecialtiesOptions}
                label="Specialties"
              />
              {taskSelectionValidation && (
                <span className="field-error">This field is required</span>)}
              {/*<Field
               className="form-control mk-wi"
               name="specialties"
               // label="Specialties"
               type="hidden"
               placeholder={'Cancer Care'}
               component={CustomInputField}
               />*/}
          </div>
          <div className="col-sm-12 pl-0 pr-0 mt-2">
              <Field
                className="form-control mk-wi"
                name="requiredExperienceYears"
                label="Experience Required (Years)"
                type="number"
                requiredMark={true}
                component={CustomInputField}
                min="0"
                placeholder={'0'}
                validate={required}
              ></Field>
          </div>
          <div className="col-sm-12 pl-0 pr-0 mt-2">
              {/*<div className="tab-inner-heading1 form-heading text-black1">Description</div>*/}
              <label>Description <span className="mandatory-field">*</span></label>
          </div>
          <div className='col-sm-12 pl-0 pr-0 mt-2'>
              <div className='quill'>
                  <ReactQuill theme="snow" value={value} onChange={(e) => getValue(e)}/>
              </div>
          </div>
          <div className="col-sm-12 pl-0 pr-0 mt-2">
              <div className="row ">
                  {
                    !isDescriptionValid && <span className="field-error pt-1">This field is required</span>
                  }
                  <div className="col-sm-6 mt-2">
                      <Field
                        className="form-control mk-wi"
                        name="startDate"
                        label="Start Date"
                        type="date"
                        component={CustomInputField}
                        requiredMark={true}
                        validate={required}
                      ></Field>
                  </div>
                  <div className="col-sm-6 mt-2">
                      <Field
                        className="form-control mk-wi"
                        name="endDate"
                        label="End Date"
                        type="date"
                        requiredMark={true}
                        component={CustomInputField}
                        validate={required}
                      ></Field>
                  </div>
                  <div className="col-sm-6 mt-2">
                      <Field
                        className="form-control mk-wi"
                        name="startTime"
                        label="Start Time"
                        type="time"
                        component={CustomInputField}
                        requiredMark={true}
                        validate={required}
                      ></Field>
                  </div>
                  <div className="col-sm-6 mt-2">
                      <Field
                        className="form-control mk-wi"
                        name="endTime"
                        label="End Time"
                        type="time"
                        requiredMark={true}
                        component={CustomInputField}
                        validate={required}
                      ></Field>
                  </div>
              </div>
          </div>
          <div className="col-sm-12 pl-0 pr-0">
              <div className="row">
                  <div className="col-sm-6 mt-2">
                      <label>Shift Timing <span className="mandatory-field">*</span></label>
                      <Field
                        className="form-control"
                        name="shiftTimingsId"
                        component={CustomDropDownField}
                        requiredMark={true}
                        validate={required}
                      >
                          <option value="">Please select</option>
                          {(shiftTimingsArray || []).map((res, index) => {
                              return (
                                <option key={index} value={res?.id}>{res?.name}</option>
                              );
                          })}
                      </Field>
                  </div>
                  <div className="col-sm-3 mt-2">
                      <Field
                        className="form-control mk-wi"
                        name="shiftHours"
                        label="Shift Hours"
                        type="number"
                        requiredMark={true}
                        component={CustomInputField}
                        validate={required}
                        min="0"
                        readOnly={true}
                      ></Field>
                  </div>
                  <div className="col-sm-3 mt-2">
                      <Field
                        className="form-control mk-wi"
                        name="totalHours"
                        label="Total Hours"
                        type="number"
                        requiredMark={true}
                        component={CustomInputField}
                        validate={required}
                        min="0"
                        readOnly={true}
                      ></Field>
                  </div>
              </div>
          </div>
          <div className="col-sm-12 pl-0 pr-0 mt-2">
              <div className="d-flex gap-3">
                  <label>Location <span className="mandatory-field">*</span></label>
                  {
                    selectedPlace &&
                    <label className="selectedLocation">&#x28;Selected
                        Location &#x3d; {selectedPlace.address}&#x29; </label>
                  }
              </div>
              <Autocomplete onPlaceSelected={handlePlaceSelected} initAddress={selectedPlace?.address} disabled={statesArray?.length === 0}/>
              {
                !isSelectedValid && <span className="field-error">This field is required</span>
              }
          </div>
          <div className="col-sm-12 pl-0 pr-0">
              <div className="row">
                  <div className="col-sm-4 mt-2">
                      <label>State <span className="mandatory-field">*</span></label>
                      <Field
                        className="form-control read-only"
                        name="provinceOrStateId"
                        component={CustomDropDownField}
                        validate={required}
                        requiredMark={true}
                        onChange={(e) => getStateId(e)}
                      >
                          <option value="">Please select</option>
                          {statesArray?.map((res, index) => {
                              return <option key={index} value={res.id}>{res.name}</option>
                          })}
                      </Field>
                  </div>
                  <div className="col-sm-4 mt-2">
                      <label>City <span className="mandatory-field">*</span></label>
                      <Field
                        className="form-control read-only1"
                        name="cityId"
                        component={CustomDropDownField}
                        // validate={required}
                        // requiredMark={true}
                      >
                          <option value="">Please select</option>
                          {cityList?.map((res, index) => {
                              return <option key={index} value={res.id}>{res.name}</option>
                          })}
                      </Field>
                  </div>
                  <div className='col-sm-4 mt-2'>
                      <Field className="form-control mk-wi" name="postalOrZipCode" label="Postal Code"
                             type="text" component={CustomInputField} validate={required} requiredMark={true}
                             minLength={5}></Field>
                  </div>
              </div>
          </div>
          <div className="col-sm-12 pl-0 pr-0 mt-2">
              <label>Employment Type <span className="mandatory-field">*</span></label>
              <Field
                name="payRateTypeId"
                component={CustomRadioGroup}
                options={chargeMethod}
                requiredMark={true}
                validate={required}
              />
          </div>
          <div className="col-sm-12 pl-0 pr-0">
              <div className="row py-0 my-0">
                  <div className="col-sm-6">
                      <Field
                        className="form-control mk-wi"
                        name="budgetAmount"
                        label="Job Budget (USD)"
                        labelClass="mt-2"
                        type="number"
                        requiredMark={true}
                        component={CustomInputField}
                        validate={required}
                        min="5"
                      ></Field>
                  </div>
                  <div className="col-sm-6">
                      <Field
                        className="form-control mk-wi"
                        name="templateTypeId"
                        label="Select Charting Type (if applicable)"
                        component={CustomDropDownField}
                      >
                          <option value="">Please Select</option>
                          {(formTemplateTypesList || [])?.map((res, index) =>
                            <option key={index} value={res.id}>{res.name}</option>
                          )}
                      </Field>
                  </div>
              </div>
          </div>
          <>
              <div className="row d-flex flex-row justify-content-between align-items-center mt-3">
                  <div className="colx-4 d-inline-flex flex-row justify-content-start align-items-center gap-2 w-min">
                      <span style={{width: 'max-content'}}>Total Cost:&nbsp;</span><span style={{width: 'max-content'}}>${totalCost}</span>
                  </div>
                  <div className="colx-4 d-inline-flex flex-row justify-content-start align-items-center gap-2 w-min">
                      <span style={{width: 'max-content'}}>Available Balance:&nbsp;</span><span style={{width: 'max-content'}}>${(agencyBalance - totalAmountReserved).toFixed(2)}</span>
                      {/*<span style={{width: 'max-content'}}>Available Balance:&nbsp;</span><span style={{width: 'max-content'}}>${agencyBalance}</span>*/}
                  </div>
                  <div className="colx-4 d-inline-flex flex-row justify-content-start align-items-center gap-2 w-min">
                      {/*<span style={{width: 'max-content'}}>Additional Funds Required:&nbsp;</span><span style={{width: 'max-content'}}>{((agencyBalance - totalAmountReserved - totalCost) > 0 ? `$${agencyBalance - totalAmountReserved}` : `-$${Math.abs(agencyBalance - totalAmountReserved - totalCost)}`)}</span>*/}
                      {/*<span style={{width: 'max-content'}}>Additional Funds Required:&nbsp;</span><span className={`text-${(agencyBalance - totalAmountReserved - totalCost) > 0 ? 'success' : 'danger'}`} style={{width: 'max-content'}}>{((agencyBalance - totalAmountReserved - totalCost) > 0 ? `$0` : `$${Math.abs(agencyBalance - totalAmountReserved - totalCost)}`)}</span>*/}
                      <span style={{width: 'max-content'}}>Additional Funds Required:&nbsp;</span><span className={`text-${additionalFundsReq > 0 ? 'danger' : 'success'}`} style={{width: 'max-content'}}>{(additionalFundsReq > 0 ? `$${additionalFundsReq}` : `$0`)}</span>
                  </div>
              </div>
              {/*<div className="row mt-3">*/}
              {/*    {agencyBalance}, {totalAmountReserved}, {totalCost}*/}
              {/*</div>*/}
              {totalCost > (agencyBalance - totalAmountReserved) && <div className="row mt-3 d-flex flex-row justify-content-between align-items-center">
                  <span className="text-danger d-inline-block" style={{width: 'max-content'}}>You will have to add funds to your wallet, to continue</span>
                  <AddFundsComponent btnClassName="btn btn-primary" style={{maxWidth: '105px'}}/>
              </div>}
          </>
          {/*<div className="col-sm-12 pl-0 pr-0">
           <div className="form-check mt-3">
           <Field className="form-check-input" name="iAgree"
           label="Please agree to job description and policies" component={CustomCheckboxField}
           validate={required}></Field>
           <p>I have read and do understand the qualifications and duties for the position in which I am
           applying with Certified nurse assistant (CNA). I am qualified and willing to perform the
           duties of the position above with Certified nurse assistant (CNA), as specified in the job
           description, to the best of my ability. I do understand this document will be placed in my
           personnel file.</p>
           </div>
           </div>*/}
          <div className="form-group d-flex flex-row w-100">
              <div
                className="d-flex justify-content-center w-100"
                style={{marginTop: "35px"}}
              >
                  <PrimaryButton
                    type="submit"
                    className="btn-view-repo gap-2 px-5 py-2 my-0"
                    text={"Submit"}
                    onClick={() => undefined}
                    disabled={totalCost > (agencyBalance - totalAmountReserved)}
                  />
              </div>
          </div>
      </>
    )
}

// const mapStateToProps = state => {
//     const {common} = state
//     return {
//         state: common.stateList || []
//     }
// }
//
// export default connect(mapStateToProps)(PostJobForm);
export default PostJobForm;

