import React, {useCallback, useEffect, useState} from "react";
import {connect, useSelector} from "react-redux";
import {change, getFormValues, reduxForm} from "redux-form";
import {useDispatch} from 'react-redux';
import {NavLink, useLocation, useParams} from "react-router-dom";
import ServiceRequestInformationTab from "./NewServiceRequest/ServiceRequestInformationTab";
import PatientInformationTab from "./NewServiceRequest/PatientInformationTab";
import Task from "./NewServiceRequest/Task";
import Charting from "./NewServiceRequest/Charting";
import {postServiceCreationData} from '../../../services/serviceRequest';
import {
    getCityList,
    getFrequenciesList,
    getGendersList,
    getPatientsList, getPatientsWithLatLngList,
    getServiceRequestTasksList,
    getServiceRequestTypesList,
    getStateList
} from '../../../services/commonServices'
import {toastr} from 'react-redux-toastr'
import {useNavigate} from "react-router-dom";
import dateHelperFunctions from "../../../helpers/date.helpers";
import useEscapeKey from "../../../utils/hooks/useEscapeKey";
import BackLink from "../../../sharedComponents/buttons/BackLink";
import AddTaskModal from "./NewServiceRequest/addTaskModal";
import {invalidateClientsList} from "../../../queries/agency/clientsList";
import {useQueryClient} from "@tanstack/react-query";
import stringHelperFunctions from "../../../helpers/string.helpers";
import numberHelperFunctions from "../../../helpers/number.helpers";
import {HttpsAction} from "../../../commonApiFunction/httpsAction";
import arrayHelperFunctions from "../../../helpers/array.helpers";
import booleanHelperFunctions from "../../../helpers/boolean.helpers";
import {parseTokenAndGetData} from "../../../helpers/apiMethods";
import {getAgencyWalletDetailsAction} from "../../../services/transaction";
import AddFundsComponent from "../shared/AddFunds";
import {activeColor, completedColor, missedColor, pendingColor} from "../../../appConstants";
import {useGlobalStorageEvent} from "../../../utils/hooks/useWindowEvent";
import useCustomNav from "../../../utils/hooks/useCustomNav";


const AddNewJobServiceRequest = ({handleSubmit, submitFailed, isAdmin, agencyId, onClose, walletDetails, formData, initialValues}) => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
// console.log('test- location', location)

    useEscapeKey(() => {
        if (onClose != null) onClose();
        else navigate(showBackButton && location.state != null && location.state.backLink != null ? location.state.backLink : '/agency/servicerequest/allrequest');
    });

    const { goTo: gotoPostedJobsListPage } = useCustomNav(isAdmin ? `/admin/agency/servicerequest/postedjobs/pending` : '/agency/servicerequest/postedjobs/pending');

    const { goTo: gotoScheduledVisitsPage } = useCustomNav(isAdmin ? `/admin/agency/servicerequest/scheduledrequest` : '/agency/servicerequest/scheduledrequest');

    const { goTo: gotoAllRequestsPage } = useCustomNav(isAdmin ? `/admin/agency/servicerequest/allrequest` : '/agency/servicerequest/allrequest');

    // const isServiceRequestEdit = useSelector(state => state.serviceRequest?.isServiceRequestEdit || false);
    // const getServiceRequestById = useSelector(state => state.serviceRequest?.getServiceRequestById?.data);
    const getServiceRequestById = useSelector(state => state.serviceRequest?.serviceDetails?.data || null);
    const isServiceRequestEdit = getServiceRequestById != null;
// console.log('test- isServiceRequestEdit', isServiceRequestEdit, getServiceRequestById)

    const [showBackButton, setShowBackButton] = useState((location != null && location.state != null && location.state.showBackButton && location.state.backLink != null && location.state.backButtonTextSuffix != null) || false);
    // const [jobId, setJobId] = useState(location != null && location.state != null && location.state.jobId || 0);
    const jobId = location != null && location.state != null && location.state.jobId || isServiceRequestEdit && getServiceRequestById?.jobId || 0;
    const jobPayRateTypeId = location != null && location.state != null && location.state.payRateTypeId || 0;
    const jobBudgetAmount = location != null && location.state != null && location.state.budgetAmount || 0;
    const currentDateTimeUtcVal = location != null && location.state != null && location.state.currentDateTimeUtc || null;
    const templateTypeId = location != null && location.state != null && location.state.templateTypeId || 0;
    const patientId = location != null && location.state != null && location.state.patientId || 0;
    const nurseId = location != null && location.state != null && location.state.nurseId || 0;
// console.log('test- location', nurseId, patientId, jobId, templateTypeId);

    const defaultServiceRequestTypeId = getServiceRequestById?.tasks?.[0]?.serviceRequestTypeId || 0;
    const assignedNurseId = getServiceRequestById?.tasks?.[0]?.assignedNurseId || 0;
    const firstActivityId = getServiceRequestById?.tasks?.[0]?.activities?.[0]?.id || 0;
    const chartingTypeId = templateTypeId || getServiceRequestById?.chartingTypeId || 0;
    const chartingId = isServiceRequestEdit && getServiceRequestById?.chartingId || 0;
    const isFreelanceNurse = jobId > 0 || getServiceRequestById?.tasks?.[0]?.assignedNurseIsFreelance || false;
    // const totalAmountReserved = getServiceRequestById?.totalAmountReserved || 0;
    // const totalAmountReserved = getServiceRequestById?.totalAmountReservedCalculated || 0;
    const amountReserved = getServiceRequestById?.amountReservedCalculated || 0;

    const showTasksTab = (isServiceRequestEdit && firstActivityId > 0 && firstActivityId !== 1001) || patientId > 0 || nurseId > 0 || (jobId > 0 && chartingTypeId === 0) || (!isServiceRequestEdit && patientId === 0 && nurseId === 0 && jobId === 0 && chartingTypeId === 0);
    const showPatientSelectionTab = isServiceRequestEdit || patientId === 0;
    const showChartingTab = (isServiceRequestEdit && firstActivityId === 1001) || (patientId === 0 && nurseId === 0 && chartingTypeId > 0);

    const currentNurseRate = numberHelperFunctions.nullIfEmptyFloat(formData?.['nurseRate']) || 0;
    const currentPayRateTypeId = numberHelperFunctions.nullIfEmptyFloat(formData?.['payRateTypeId']) || 0;
    const agencyBalance = numberHelperFunctions.nullIfEmptyFloat(walletDetails?.balance) || 0;
    const totalAmountReserved = walletDetails?.totalAmountReservedCalculated || 0;

    const [patientGeoCoordinatesValid, setPatientGeoCoordinatesValid] = useState(true);
    const [chartingDataIsValid, setChartingDataIsValid] = useState(true);
    const [tasksListIsNotEmpty, setTasksListIsNotEmpty] = useState(true);

    const [tabIndex, setTabIndex] = useState(0);
    // const [rateTypeValue, setRateTypeValue] = useState('1');
    // const [chargeValue, setChargeValue] = useState('1');
    const [taskData, setTaskData] = useState([]);
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [clientId, setClientId] = useState(null);
    const [modalTaskList, setModalTaskList] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [totalCost, setTotalCost] = useState(0);
    const [additionalFundsReq, setAdditionalFundsReq] = useState(0);


    const getTaskData = data => {
        setTaskData(data);
    }

    const getClientId = (e) => {
        let index = e.target.selectedIndex;
        let optionElement = e.target.childNodes[index];
        let optionattr = optionElement.getAttribute('value');
        setClientId(optionattr);
    }

    const getCompleteAddress = (data) => {
        setSelectedPlace(data);
        setPatientGeoCoordinatesValid(data != null);
    }

    function loadAgencyWallet() {
        const decodedTokenData = parseTokenAndGetData().data;
        dispatch(getAgencyWalletDetailsAction("agencyWalletDetails", decodedTokenData.walletId));
    }

    const storageEventHandler = (event) => {
        if (event?.detail?.key === "balance-updated") {
            loadAgencyWallet();
        }
    };

    const onSubmit = async (values) => {
// console.log("---->>values", values, taskData);
        const payload = Object.assign({}, values);
        const payRateTypeIdVal = numberHelperFunctions.nullIfEmpty(payload.payRateTypeId, true);
        const rateTypeSelection = numberHelperFunctions.nullIfEmpty(payload.rateTypeSelection, true);
        let errorOccurred = false;
        let newPatientCreated = false;
        // if (isServiceRequestEdit) {
        //     const modifiedTaskData = taskData.map(task => {
        //         return {
        //             recordType: 'existing',
        //             startDate: dateHelperFunctions.updateDateFormat(task.startDate),
        //             endDate: dateHelperFunctions.updateDateFormat(task.endDate),
        //             frequencyIds: task.modalFrequencyIds,
        //             expectedClockIn: task.clockIn,
        //             expectedClockOut: task.clockOut,
        //             tasksIds: task.taskid,
        //         };
        //     });
        //     serviceReqInfo = {
        //         serviceRequestTypeId: +payload.serviceRequestTypeId,
        //         patientSelection: {
        //             recordType: "existing",
        //             patientId: +clientId,
        //         },
        //         patientPayDescription: null,
        //         ratesInfo: {
        //             payRateTypeDesc: payRateTypeIdVal === 1 ? "hourly" : "other",
        //             payRateTypeId: payRateTypeIdVal,
        //             agencyRateToClient: parseFloat(payload.agencyRateToClient),
        //             nurseRate: parseFloat(payload.nurseRate),
        //             nurseOvertimeRate: getServiceRequestById?.getServiceRequestById,
        //         },
        //         tasksSelected: modifiedTaskData || [],
        //     };
        // } else {
            const ratesInfo = {
                payRateTypeDesc: payRateTypeIdVal === 1 ? "hourly" : "other",
                payRateTypeId: payRateTypeIdVal,
                agencyRateToClient: numberHelperFunctions.nullIfEmptyFloat(payload.agencyRateToClient),
                nurseRate: numberHelperFunctions.nullIfEmptyFloat(payload.nurseRate),
            };
            if (payRateTypeIdVal === 1 && rateTypeSelection === 2 && payload.nurseOvertimeRate != null) {
                ratesInfo['nurseOvertimeRate'] = numberHelperFunctions.nullIfEmptyFloat(payload.nurseOvertimeRate);
            }

            let patientSelection = null;
            if (patientId > 0) { // when new service request link/button was clicked from patient calendar page
                patientSelection = {
                    recordType: "existing",
                    patientId: typeof patientId === 'string' ? +patientId : patientId,
                };
            } else {
                if (clientId === null) {
                    if (selectedPlace == null) {
                        console.error('patient location data error');
                        errorOccurred = true;
                        setPatientGeoCoordinatesValid(false);
                    } else {
                        newPatientCreated = true;
                        patientSelection = {
                            recordType: "new",
                            // firstName: payload.firstName,
                            // lastName: payload.lastName,
                            // middleName: payload.middleName || null,
                            // age: +payload.age,
                            // genderId: +payload.genderId,
                            // email: payload.email,
                            // addressLine1: payload.addressLine1,
                            // countryId: 233,
                            // provinceOrStateId: +payload.provinceOrStateId,
                            // cityId: +payload.cityId,
                            // postalOrZipCode: payload.postalOrZipCode,
                            // latitude: selectedPlace && selectedPlace.latLng.lat,
                            // longitude: selectedPlace && selectedPlace.latLng.lng,

                            firstName: stringHelperFunctions.nullIfEmpty(payload.firstName),
                            // middleName: stringHelperFunctions.nullIfEmpty(payload?.middleName),
                            lastName: stringHelperFunctions.nullIfEmpty(payload.lastName),
                            // age: +payload.age,
                            birthDate: stringHelperFunctions.nullIfEmpty(payload.dateOfBirth),
                            genderId: numberHelperFunctions.nullIfEmpty(payload.genderId),
                            email: stringHelperFunctions.nullIfEmpty(payload.email),
                            addressLine1: stringHelperFunctions.nullIfEmpty(payload.addressLine1),
                            // countryId: 233,
                            provinceOrStateId: numberHelperFunctions.nullIfEmpty(payload.provinceOrStateId),
                            cityId: numberHelperFunctions.nullIfEmpty(payload.cityId),
                            postalOrZipCode: stringHelperFunctions.nullIfEmpty(payload.postalOrZipCode),
                            latitude: numberHelperFunctions.nullIfEmptyFloat(selectedPlace?.latLng?.lat),
                            longitude: numberHelperFunctions.nullIfEmptyFloat(selectedPlace?.latLng?.lng),
                        };
                    }
                } else {
                    patientSelection = {
                        recordType: "existing",
                        patientId: +clientId,
                    };
                }
            }

            let modifiedTaskData = [];
            if (showTasksTab) {
                if (taskData.length > 0) {
                    modifiedTaskData = taskData.map(task => {
// console.log('test- task', task);
                        let retValue;
                        if (task.recordType === 'existing') {
                            retValue = {
                                id: task.id,
                                recordType: task.recordType,
                                startDate: task.startDate,
                                endDate: task.endDate,
                                frequencyIds: task.frequencyIds,
                                expectedClockIn: task.scheduledStartTime,
                                expectedClockOut: task.scheduledEndTime,
                                serviceRequestTypeId: task.serviceRequestTypeId,
                                tasksIds: task.activities.map(a => a.id),
                                // tasksIds: task.activities.map(a => a.id),
                            };
                        } else {
                            retValue = {
                                recordType: 'new',
                                // startDate: dateHelperFunctions.updateDateFormat(task.startDate),
                                // endDate: dateHelperFunctions.updateDateFormat(task.endDate),
                                startDate: task.startDate,
                                endDate: task.endDate,
                                frequencyIds: task.modalFrequencyIds,
                                // expectedClockIn: task.clockIn,
                                // expectedClockOut: task.clockOut,
                                expectedClockIn: task.scheduledStartTime,
                                expectedClockOut: task.scheduledEndTime,
                                serviceRequestTypeId: defaultServiceRequestTypeId,
                                tasksIds: task.taskid,
                            };
                        }

                        let nurseIdVal = typeof nurseId === 'string' ? +nurseId : nurseId;
                        if (nurseIdVal === 0)
                            nurseIdVal = typeof assignedNurseId === 'string' ? +assignedNurseId : assignedNurseId;
                        if (nurseIdVal > 0) {
                            retValue['assignedNurseId'] = nurseIdVal;
                        }
// console.log('test- retValue', retValue);
// console.log('test- nurseIdVal', nurseIdVal, nurseId, assignedNurseId);
                        return retValue;
                    });
                } else {
                    if (showTasksTab) {
                        console.error('tasks data error');
                        errorOccurred = true;
                        setTasksListIsNotEmpty(false);
                    }
                }
            } else {
                modifiedTaskData = modalTaskList.map(task => {
// console.log('test- task', task);
                    const retValue = {
                        id: task.id,
                        recordType: 'existing',
                        startDate: task.startDate,
                        endDate: task.endDate,
                        // frequencyIds: (task.frequencies || []).map(e => e.id),
                        frequencyIds: task.frequencyIds,
                        expectedClockIn: task.scheduledStartTime,
                        expectedClockOut: task.scheduledEndTime,
                        serviceRequestTypeId: task.serviceRequestTypeId,
                        tasksIds: task.activities.map(a => a.id),
                    };
                    let nurseIdVal = typeof nurseId === 'string' ? +nurseId : nurseId;
                    if (nurseIdVal === 0)
                        nurseIdVal = typeof assignedNurseId === 'string' ? +assignedNurseId : assignedNurseId;
                    if (nurseIdVal > 0) {
                        retValue['assignedNurseId'] = nurseIdVal;
                    }
// console.log('test- retValue', retValue);
                    return retValue;
                });
            }

            // if ((chartingTypeId > 0) && (payload.chartingTitle == null || payload.chartingTitle.trim().length === 0 || payload.chartingTypeId == null || payload.chartingTypeId.length === 0)) {
            if ((chartingTypeId > 0) && (payload.chartingTitle == null || payload.chartingTitle.trim().length === 0 || payload.templateId == null || payload.templateId.length === 0)) {
                console.error('charting data error', chartingTypeId, payload.chartingTitle, payload.templateId);
                errorOccurred = true;
                setChartingDataIsValid(false);
            }

            const serviceReqInfo = {
                serviceRequestTypeId: numberHelperFunctions.nullIfEmpty(payload.serviceRequestTypeId, true),
                ratesInfo,
                patientSelection,
                tasksSelected: arrayHelperFunctions.nullIfEmpty(modifiedTaskData),
                jobId: numberHelperFunctions.nullIfEmpty(jobId, true),
                amountReserved: isFreelanceNurse ? totalCost : 0,
                // amountReserved: numberHelperFunctions.nullIfEmpty(isFreelanceNurse ? totalCost : 0, true),

                hasChartingInfo: booleanHelperFunctions.nullIfEmpty(chartingTypeId).toString(),
                chartingTypeId: numberHelperFunctions.nullIfEmpty(chartingTypeId, true),
                chartingInfo: (chartingTypeId > 0) ? {
                    chartingId: numberHelperFunctions.nullIfEmpty(chartingId, true),
                    assessmentTitle: payload.chartingTitle,
                    // nurseId: 49,
                    templateId: +payload.templateId,
                    // expirationDate: payload.expirationDate,
                } : undefined,
            };

        // }
// alert(JSON.stringify(serviceReqInfo));
// console.log('errorOccurred', errorOccurred, jobId, chartingId);
// console.log('serviceReqInfo', serviceReqInfo);
        if (!errorOccurred) {
            try {
                let response  = null;
                if (isServiceRequestEdit) {
                    response = await dispatch(HttpsAction({
                        method: 'PUT',
                        apiSection: 'serviceRequests',
                        apiName: 'serviceDetails',
                        queryParameter: `/${getServiceRequestById.id}`,
                        data: serviceReqInfo,
                        // positiveCallBack: (response) => {},
                    }));
// console.log('test- response', response);
                    if ('success' in response.data)
                        response = response.data;
                } else {
                    response = await dispatch(postServiceCreationData(serviceReqInfo, jobId > 0 ? ((chartingTypeId > 0) ? 'createJobRequestWithCharting' : 'createJobRequest') : 'createRequest'));
                }

                if (response && response?.success) {
                    if (newPatientCreated) {
                        invalidateClientsList(queryClient, agencyId);
                    }
                    if (onClose != null) {
                        onClose();
                    } else {
                        if (jobId > 0)
                            // navigate("/agency/servicerequest/postedjobsinprogress", { state: { jobId } });
                            gotoPostedJobsListPage({ jobId });
                        else if (nurseId > 0)
                            // navigate("/agency/servicerequest/scheduledrequest");
                            gotoScheduledVisitsPage();
                        else
                            // navigate("/agency/servicerequest/allrequest");
                            gotoAllRequestsPage();
                    }
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    const tabHandler = (index) => {
        setTabIndex(index);
    };

    useEffect(() => {
        if (taskData.length > 0) {
            setTasksListIsNotEmpty(true);
        } else {
            setTasksListIsNotEmpty(false);
        }
    }, [taskData]);

    useEffect(() => {
        submitFailed && toastr.error('Please fill all mandatory fields');
        if (submitFailed && clientId === null && selectedPlace === null) {
            setPatientGeoCoordinatesValid(false);
        }
    }, [submitFailed]);

    useEffect(() => {
        if (getServiceRequestById?.patientId) {
            setClientId(getServiceRequestById?.patientId);
        }
    }, [getServiceRequestById?.patientId]);

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const removeTask = (id) => {
        const removeTaskItem = modalTaskList.filter(res => res.id !== id)
        setModalTaskList(removeTaskItem);
    };

    const addTask = (item) => {
// console.log('task item', item)
        let currentDateTimeUtc = null;
        if (currentDateTimeUtcVal != null) currentDateTimeUtc = new Date(Date.parse(currentDateTimeUtcVal.replace(' ', 'T') + '+00:00'));
        if (getServiceRequestById != null) currentDateTimeUtc = new Date(Date.parse(getServiceRequestById.currentDateTimeUtc.replace(' ', 'T') + '+00:00'));
        if (currentDateTimeUtc == null) currentDateTimeUtc = new Date();

        const endTime = new Date(Date.parse(currentDateTimeUtc.toISOString().substring(0, 10)+'T'+item.scheduledEndTimeForCalc));
        const startTime = new Date(Date.parse(currentDateTimeUtc.toISOString().substring(0, 10)+'T'+item.scheduledStartTimeForCalc));
        const scheduledHours = numberHelperFunctions.round((endTime.getTime() - startTime.getTime()) / 1000 / 60 / 60);
        const {hrs, mins} = dateHelperFunctions.timeDiffInHoursAndMins(dateHelperFunctions.convertTimeStringToDate(item.scheduledStartTimeForCalc), dateHelperFunctions.convertTimeStringToDate(item.scheduledEndTimeForCalc));
        setModalTaskList([...modalTaskList, {...item, "hours": <span title={dateHelperFunctions.hoursAndMinutesToText(hrs, mins)}>{scheduledHours + ' Hrs'}</span>}]);
    };

    useEffect(() => {
        /// IIFE
        (async () => {
            const data = [];
            if (isServiceRequestEdit && getServiceRequestById) {
                loadAgencyWallet();

                const days = getServiceRequestById?.frequencies?.map(e => e.frequency);
                const frequencyIds = getServiceRequestById?.frequencies?.map(e => e.id);
                const currentDateTimeUtc = new Date(Date.parse(getServiceRequestById.currentDateTimeUtc.replace(' ', 'T')+'+00:00'));
                const currentDateUtc = new Date(Date.parse(currentDateTimeUtc.toISOString().substring(0, 10)+'T00:00:00+00:00'));
                getServiceRequestById?.tasks?.map((v, i) => {
// console.log('test- v', v)
                    const scheduledEndDateTimeUtc = new Date(Date.parse(v.scheduledEndDateTimeUtc.replace(' ', 'T') + '+00:00'));
                    const taskDateValue = new Date(Date.parse(v.date+'T00:00:00+00:00'));
                    // let statusVal = v.status;
// // console.log('test- ', currentDateTimeUtc.toISOString(), currentDateTimeUtcVal, getServiceRequestById.currentDateTimeUtc, v.scheduledEndDateTimeUtc, currentDateTimeUtc > scheduledEndDateTimeUtc)
// // console.log('test- ', v.status, v.statusId, v.remainingActivitiesTotal, v.clockIn, taskDateValue.toISOString(), currentDateUtc.toISOString(), taskDateValue < currentDateTimeUtc)
//                     if (v.statusId < 3 && v.clockIn == null && currentDateTimeUtc > scheduledEndDateTimeUtc) statusVal = 'Missed';
// // console.log('test- condition', v.clockIn != null && taskDateValue < currentDateUtc && v.remainingActivitiesTotal > 0)
// //                     if (v.clockIn != null && taskDateValue < currentDateUtc && v.remainingActivitiesTotal > 0) statusVal = 'Incomplete';
                    // if ((v.clockIn != null && taskDateValue < currentDateUtc && v.remainingActivitiesTotal > 0) || (v.clockIn != null && v.clockOut != null && v.remainingActivitiesTotal > 0) || (v.statusId === 4 && v.remainingActivitiesTotal > 0)) statusVal = 'Incomplete';
                    let statusVal= '';
                    let statusDisplay= '';
                    if (v.statusId < 3 && v.clockIn == null && currentDateTimeUtc > scheduledEndDateTimeUtc) {
                        statusVal = 'Missed';
                        statusDisplay = <span style={{color: missedColor}}>Missed</span>;
                    }
                    else if ((v.clockIn != null && taskDateValue < currentDateUtc && v.remainingActivitiesTotal > 0) || (v.clockIn != null && v.clockOut != null && v.remainingActivitiesTotal > 0) || (v.statusId === 4 && v.remainingActivitiesTotal > 0)) {
                        statusVal = 'Incomplete';
                        statusDisplay = <span style={{color: missedColor}} title={v.remainingActivitiesTotal + ' tasks remaining'}>Incomplete &#x28;{v.remainingActivitiesTotal}&#x29;</span>;
                    }
                    else {
                        statusVal = v.status;
                        statusDisplay = <span style={{color: v.statusId === 1 ? pendingColor : (v.statusId > 3 ? completedColor : (v.statusId === 2 ? '' : activeColor))}}>{v.status}</span>;//39cb82,e6cf01,ffc300,dc3545
                    }

                    const endTime = new Date(Date.parse(currentDateTimeUtc.toISOString().substring(0, 10)+'T'+v.scheduledEndTime));
                    const startTime = new Date(Date.parse(currentDateTimeUtc.toISOString().substring(0, 10)+'T'+v.scheduledStartTime));
                    const scheduledHours = numberHelperFunctions.round((endTime.getTime() - startTime.getTime()) / 1000 / 60 / 60);
                    const {hrs, mins} = dateHelperFunctions.timeDiffInHoursAndMins(dateHelperFunctions.convertTimeStringToDate(v.scheduledStartTime), dateHelperFunctions.convertTimeStringToDate(v.scheduledEndTime));
// console.log('test- scheduledHours', v.remainingActivitiesTotal, taskDateValue.toISOString(), currentDateUtc.toISOString(), endTime.toISOString(), startTime.toISOString(), v.scheduledEndTime, v.scheduledStartTime, scheduledHours)

                    const taskForUi = {
                        // ...v,
                        "startDate": v.date,
                        "endDate": v.date,
                        "startDateDisplay": v.dateFormatUs,
                        "endDateDisplay": v.dateFormatUs,
                        "clockIn": v.scheduledStartTimeAmPm,
                        "clockOut": v.scheduledEndTimeAmPm,
                        "actualClockIn": v.clockIn,
                        "actualClockOut": v.clockOut,
                        "dayName": days,
                        // "taskName": [
                        //     "Suprapubic",
                        //     "Catheter Care:"
                        // ],
                        "taskName": v.activityNames,
                        // "modalFrequencyIds": [
                        //     1,
                        //     2
                        // ],
                        "modalFrequencyIds": (v.frequencies || []).map(e => e.id),
                        "activities": v.activities,
                        "scheduledStartTime": v.scheduledStartTime.substring(0, 5),
                        "scheduledEndTime": v.scheduledEndTime.substring(0, 5),
                        "frequencyIds": frequencyIds,
                        "serviceRequestTypeId": v.serviceRequestTypeId,
                        "id": v.id,
                        "recordType": 'existing',
                        "status": statusVal,
                        "statusDisplay": statusDisplay,
                        "statusId": v.statusId,
                        "scheduledEndDateTimeUtc": v.scheduledEndDateTimeUtc,
                        // "scheduledEndDateTimeUtcCalc": scheduledEndDateTimeUtc,
                        "remainingActivitiesTotal": v.remainingActivitiesTotal,
                        "hours": <span title={dateHelperFunctions.hoursAndMinutesToText(hrs, mins)}>{scheduledHours + ' Hrs'}</span>,
                    };
                    data.push(taskForUi);
// console.log('test- taskForUi', taskForUi)
                });
// console.log('test- data', currentDateTimeUtc, data)
                setModalTaskList(() => data);
            }
            if (!isServiceRequestEdit && jobId > 0) {
                dispatch(change('newServiceRequestForm', 'payRateTypeId', String(jobPayRateTypeId)));
                dispatch(change('newServiceRequestForm', 'nurseRate', String(jobBudgetAmount)));
            }
            if (!isServiceRequestEdit && jobId === 0 && nurseId > 0) {
                dispatch(change('newServiceRequestForm', 'assignedNurseId', String(nurseId)));
            }
        })();
        return () => {
            dispatch(HttpsAction({
                reducerName: 'SERVICE_REQUEST',
                reduxKeyName: 'serviceDetails',
                actionType: `GET_SERVICE_DETAILS_SINGLE_REQUEST`,
                removeData: true,
                actionTypeIsDispatchType: false,
            }));
        };
    }, [isServiceRequestEdit && getServiceRequestById]);

    useEffect(() => {
// console.log('test- CHECK', agencyBalance, totalAmountReserved, agencyBalance - totalAmountReserved)
        let currentDateTimeUtc = null;
        if (currentDateTimeUtcVal != null) currentDateTimeUtc = new Date(Date.parse(currentDateTimeUtcVal.replace(' ', 'T') + '+00:00'));
        if (getServiceRequestById != null) currentDateTimeUtc = new Date(Date.parse(getServiceRequestById.currentDateTimeUtc.replace(' ', 'T') + '+00:00'));
        if (currentDateTimeUtc == null) currentDateTimeUtc = new Date();
        const currentDateUtc = new Date(Date.parse(currentDateTimeUtc.toISOString().substring(0, 10)+'T00:00:00+00:00'));
// console.log('test- modalTaskList', modalTaskList, currentDateTimeUtcVal, currentDateTimeUtc.toISOString(), currentDateTimeUtc, getServiceRequestById)
        if (modalTaskList.length > 0) {
            const nurseRate = currentNurseRate;
            const payRateTypeIdVal = currentPayRateTypeId;
            const currentDate = new Date();
            // const endDate = new Date(Date.parse(currentDate.toISOString().substring(0, 10) + 'T' + task.endDate + ':00+00:00'));
            // const startDate = new Date(Date.parse(currentDate.toISOString().substring(0, 10) + 'T' + task.startDate + ':00+00:00'));
            // const daysCount = Math.round((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)) + 1;
            const daysCount = 1;
            let totalCostVal = 0;
// console.log('test- payRateTypeIdVal', payRateTypeIdVal);
            if (payRateTypeIdVal === 1) {
                modalTaskList.forEach(task => {
// console.log('test- task', task);
                    const scheduledEndDateTimeUtc = new Date(Date.parse(task.scheduledEndDateTimeUtc.replace(' ', 'T') + '+00:00'));
                    const taskDateValue = new Date(Date.parse(task.startDate+'T00:00:00+00:00'));
                    if (task.status === 'Paid' || (task.status !== 'New' && task.statusId < 3 && task.actualClockIn == null && currentDateTimeUtc > scheduledEndDateTimeUtc)) totalCostVal += 0;
                    // else if (task.status !== 'New' && task.actualClockIn != null && taskDateValue < currentDateUtc && task.remainingActivitiesTotal > 0) totalCostVal += 0;
                    else if ((task.actualClockIn != null && taskDateValue < currentDateUtc && task.remainingActivitiesTotal > 0) || (task.actualClockIn != null && task.actualClockOut != null && task.remainingActivitiesTotal > 0) || (task.statusId === 4 && task.remainingActivitiesTotal > 0)) totalCostVal += 0;
                    else {
// console.log('test- check');
                        const endTime = new Date(Date.parse(currentDate.toISOString().substring(0, 10) + 'T' + task.scheduledEndTime + ':00+00:00'));
                        const startTime = new Date(Date.parse(currentDate.toISOString().substring(0, 10) + 'T' + task.scheduledStartTime + ':00+00:00'));
                        const hoursCount = (endTime.getTime() - startTime.getTime()) / 1000 / 60 / 60;
                        totalCostVal += nurseRate * daysCount * hoursCount;
// console.log('test- hoursCount', hoursCount, nurseRate * daysCount * hoursCount);
                    }
                });
            } else {
                modalTaskList.forEach(task => {
                    const scheduledEndDateTimeUtc = new Date(Date.parse(task.scheduledEndDateTimeUtc));
                    const taskDateValue = new Date(Date.parse(task.startDate+'T00:00:00+00:00'));
                    if (task.status === 'Paid' || (task.status !== 'New' && task.statusId < 3 && task.actualClockIn == null && currentDateTimeUtc > scheduledEndDateTimeUtc)) totalCostVal += 0;
                    // else if (task.status !== 'New' && task.actualClockIn != null && taskDateValue < currentDateUtc && task.remainingActivitiesTotal > 0) totalCostVal += 0;
                    else if ((task.actualClockIn != null && taskDateValue < currentDateUtc && task.remainingActivitiesTotal > 0) || (task.actualClockIn != null && task.actualClockOut != null && task.remainingActivitiesTotal > 0) || (task.statusId === 4 && task.remainingActivitiesTotal > 0)) totalCostVal += 0;
                    else totalCostVal += nurseRate * daysCount;
// console.log('test- cost', nurseRate * daysCount, task.status, task.statusId, task.actualClockIn, task.remainingActivitiesTotal);
                });
            }
            setTotalCost(numberHelperFunctions.round(totalCostVal));
            const moreFunds = agencyBalance - totalAmountReserved - totalCostVal;
            if (moreFunds < 0) setAdditionalFundsReq(Math.abs(Math.floor(moreFunds))); // for negative values use Math.floor else use Math.ceil
            else if (totalCostVal >= 0) setAdditionalFundsReq(0);
// console.log('test- totalCostVal', totalCostVal, moreFunds);
        } else {
            loadAgencyWallet();
            setTotalCost(0);
        }
    }, [modalTaskList, currentNurseRate, currentPayRateTypeId]);

    // useEffect(() => {
    //     window.addEventListener("storage", storageEventHandler);
    //     return () => {
    //         window.removeEventListener("storage", storageEventHandler);
    //     };
    // }, []);
    useGlobalStorageEvent(storageEventHandler);

    return (
        /*<div className="w-100 p-0 app-main__outer">
            <div className="w-100 main-service-request-page">*/
            /*</div>
        </div>*/

      <div className="app-main__outer">
          <div className="main-service-request-page">
              <div className="app-main__inner bg-white">
                  {!onClose && <div className="col-12 page-back-btn">
                      <BackLink link={showBackButton && location.state != null && location.state.backLink != null ? location.state.backLink : '/agency/servicerequest/allrequest'}
                                linkText={showBackButton && location.state != null && location.state.backButtonTextSuffix != null ? location.state.backButtonTextSuffix : 'All Requests'}
                                id={nurseId || patientId || undefined}
                      />
                  </div>}
                  {/*<h3>{`${isServiceRequestEdit ? `Edit` : `New`} Service Request`}&nbsp;<span>{jobId > 0 ? (templateTypeId > 0 ? '(Job with charting)' : '(Job)') : (patientId > 0 ? '(Client)' : (nurseId > 0 ? '(Nurse)' : ''))}</span></h3>*/}
                  {!isServiceRequestEdit && <h3 className="pt-4 pb-4">{`New Service Request`}&nbsp;
                      <span>{jobId > 0 ? ((chartingTypeId > 0) ? '(Job with charting)' : '(Job)') : (patientId > 0 ? '(Client)' : (nurseId > 0 ? '(Nurse)' : ''))}</span></h3>}
                  {/*{isServiceRequestEdit &&
                   <svg onClick={() => dispatch({
                   type: `ADD_SERVICE_REQUEST_UPDATE_EDIT_FLAG`,
                   payload: false,
                   name: `isServiceRequestEdit`,
                   meta: {type: `UPDATE_EDIT_FLAG`},
                   })}
                   style={{cursor: 'pointer'}}
                   width="40" height="40" viewBox="0 0 40 40" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                   <g clipPath="url(#clip0_1880_67785)">
                   <circle cx="20" cy="20" r="20" fill="#F2F2F2"/>
                   <path
                   d="M13.9219 26.0796L20.0007 20.0007M26.0796 13.9219L19.9996 20.0007M19.9996 20.0007L13.9219 13.9219M20.0007 20.0007L26.0796 26.0796"
                   stroke="#4F4F4F" strokeWidth="1.73913" strokeLinecap="round"
                   strokeLinejoin="round"/>
                   </g>
                   <defs>
                   <clipPath id="clip0_1880_67785">
                   <rect width="40" height="40" fill="white"/>
                   </clipPath>
                   </defs>
                   </svg>
                   }*/}
              </div>
              <div className="app-main__inner service-request-inner">
                  <div className={`${!onClose ? 'tab-content' : ''}`}>
                      <div className="row align-items-center">
                          <div className="col-12">
                              <ul className={`nav nav-tabs p-1${isServiceRequestEdit ? ' mt-0 pt-0' : ''}`} id="myTab" role="tablist">
                                  <li className="nav-item" role="presentation">
                                      <button
                                        className={`${tabIndex === 0 ? "active" : ""} nav-link`}
                                        onClick={() => tabHandler(0)}
                                      >
                                          Service Request Information
                                      </button>
                                  </li>
                                  {showPatientSelectionTab && <li className="nav-item" role="presentation">
                                      <button
                                        className={`${tabIndex === 1 ? "active" : ""}  nav-link`}
                                        onClick={() => tabHandler(1)}
                                      >
                                          Patient Information
                                      </button>
                                  </li>}
                                  {showChartingTab && <li className="nav-item" role="presentation">
                                      <button
                                        className={`${tabIndex === 2 ? "active" : ""}  nav-link`}
                                        onClick={() => tabHandler(2)}
                                      >
                                          Charting Information
                                      </button>
                                  </li>}
                                  {showTasksTab && <li className="nav-item" role="presentation">
                                      <button
                                        className={`${tabIndex === 3 ? "active" : ""}  nav-link`}
                                        onClick={() => tabHandler(3)}
                                      >
                                          Tasks
                                      </button>
                                  </li>}
                              </ul>
                          </div>
                          <div className="col-sm-5 col-12"></div>
                      </div>
                      <div className="tab-content" id="myTabContent" style={{padding: '30px 0'}}>
                          <form onSubmit={handleSubmit(onSubmit)} id="serviceRequestForm">
                              <div className={`srtab ${tabIndex === 0 ? "active" : ""}`}>
                                  <div className="w-100 flex-col-center-center">
                                      <div className="w-max">
                                          <ServiceRequestInformationTab agencyId={agencyId}
                                            // setRateTypeValue={(e) => setRateTypeValue(e.target.value)}
                                            // getChargeMethod={getChargeMethod}
                                            // RatesValue={rateTypeValue}
                                                                        initialTabValues={initialValues == null ? {} : {
                                                                            rateTypeSelection: initialValues.rateTypeSelection,
                                                                            payRateTypeId: initialValues.payRateTypeId
                                                                        }}
                                                                        disableServiceRequestTypeId={isServiceRequestEdit}
                                            // disableAgencyRateToClient={isServiceRequestEdit && jobId === 0 || !isServiceRequestEdit}
                                                                        disableAgencyRateToClient={false}
                                                                        disableNurseRate={jobId > 0 || isServiceRequestEdit}
                                                                        disablePayRateTypeId={jobId > 0 || isServiceRequestEdit}
                                            // disableRateTypeSelection={templateTypeId > 0 || isServiceRequestEdit}
                                                                        disableRateTypeSelection={isServiceRequestEdit}
                                            // chargeValue={chargeValue}
                                          />
                                          <div className="form-group w-100 d-flex flex-row">
                                              <div className="col text-center pt-4">
                                                  <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={() => {
                                                        if (showPatientSelectionTab) tabHandler(1);
                                                        else if (showChartingTab) tabHandler(2);
                                                        else tabHandler(3);
                                                    }}
                                                  >
                                                      Next
                                                  </button>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              {showPatientSelectionTab && <div className={`srtab ${tabIndex === 1 ? "active" : ""}`}>
                                  <div className="w-100 flex-col-center-center">
                                      <div className="w-max">
                                          <PatientInformationTab
                                            // getStateId={(e) => getStateId(e)}
                                            address={getCompleteAddress}
                                            patientGeoCoordinatesValid={patientGeoCoordinatesValid}
                                            getClientId={(e) => getClientId(e)}
                                            clientId={clientId}
                                            agencyId={agencyId}
                                            showNurseSelection={nurseId > 0 || assignedNurseId > 0}
                                            disableNurseSelection={true}
                                            disablePatientSelection={isServiceRequestEdit}
                                            // nurseId={nurseId > 0 ? nurseId : assignedNurseId}
                                            iAgree={initialValues.iAgree}
                                          />
                                          <div className="form-group w-100 d-flex flex-row">
                                              <div className="col text-center pt-4">
                                                  <button
                                                    type="button"
                                                    className="btn btn-back border"
                                                    onClick={() => {
                                                        tabHandler(0);
                                                    }}
                                                  >
                                                      Back
                                                  </button>
                                                  <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={() => {
                                                        if (showChartingTab) tabHandler(2);
                                                        else tabHandler(3);
                                                    }}
                                                  >
                                                      Next
                                                  </button>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>}
                              {showChartingTab && <div className={`srtab ${tabIndex === 2 ? "active" : ""}`}>
                                  <div className="w-100 flex-col-center-center">
                                      <div className="w-max">
                                          <Charting getTaskData={getTaskData} jobId={jobId} agencyId={agencyId} chartingTypeId={chartingTypeId}/>
                                          <div className="form-group w-100 d-flex flex-row">
                                              <div className="col-8 text-center pt-4">
                                                  <button
                                                    type="button"
                                                    className="btn btn-back border"
                                                    onClick={() => {
                                                        if (showPatientSelectionTab) tabHandler(1);
                                                        else tabHandler(0);
                                                    }}
                                                  >
                                                      Back
                                                  </button>
                                                  {showTasksTab && <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={() => {
                                                        tabHandler(3);
                                                    }}
                                                  >
                                                      Next
                                                  </button>}
                                                  {!showTasksTab && <button type="submit" className="btn btn-primary"
                                                                            disabled={isFreelanceNurse && totalCost > (agencyBalance - totalAmountReserved + amountReserved)}>
                                                      {isServiceRequestEdit ? "Update" : "Create"}
                                                  </button>}
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>}
                              {showTasksTab && <div className={`srtab ${tabIndex === 3 ? "active" : ""}`}>
                                  <div className="w-100 flex-col-center-center">
                                      <div className="w-max">
                                          <Task getTaskData={getTaskData} tasksListIsNotEmpty={tasksListIsNotEmpty} removeTask={removeTask} modalTaskList={modalTaskList}
                                                openModal={openModal}/>
                                          {isFreelanceNurse && <>
                                              <div className="row my-3">
                                                  <div className="col-4 d-flex flex-row justify-content-start align-items-center gap-2">
                                                      <span style={{width: 'max-content'}}>Total Cost:&nbsp;</span><span style={{width: 'max-content'}}>${totalCost}</span>
                                                  </div>
                                                  <div className="col-4 d-flex flex-row justify-content-start align-items-center gap-2">
                                                      {/*<span style={{width: 'max-content'}}>Your Balance:&nbsp;</span><span style={{width: 'max-content'}}>${agencyBalance - totalAmountReserved + amountReserved}</span>*/}
                                                      {/*<span style={{width: 'max-content'}}>Your Balance:&nbsp;</span><span style={{width: 'max-content'}}>${agencyBalance}</span>*/}
                                                      <span style={{width: 'max-content'}}>Available Balance:&nbsp;</span><span
                                                    style={{width: 'max-content'}}>${(agencyBalance - totalAmountReserved).toFixed(2)}</span>
                                                  </div>
                                                  <div className="col-4 d-flex flex-row justify-content-start align-items-center gap-2">
                                                      {/*<span style={{width: 'max-content'}}>Additional Funds Required:&nbsp;</span><span style={{width: 'max-content'}}>{((agencyBalance - totalAmountReserved + amountReserved) > 0 ? `$${agencyBalance - totalAmountReserved + amountReserved}` : `-$${Math.abs(agencyBalance - totalAmountReserved + amountReserved)}`)}</span>*/}
                                                      {/*<span style={{width: 'max-content'}}>Additional Funds Required:&nbsp;</span><span className={`text-${(agencyBalance - totalAmountReserved + amountReserved - totalCost) > 0 ? 'success' : 'danger'}`} style={{width: 'max-content'}}>{((agencyBalance - totalAmountReserved + amountReserved - totalCost) > 0 ? `$0` : `$${Math.abs(agencyBalance - totalAmountReserved + amountReserved - totalCost)}`)}</span>*/}
                                                      {/*<span style={{width: 'max-content'}}>Additional Funds Required:&nbsp;</span><span className={`text-${(agencyBalance - totalAmountReserved - totalCost) > 0 ? 'success' : 'danger'}`} style={{width: 'max-content'}}>{((agencyBalance - totalAmountReserved - totalCost) > 0 ? `$0` : `$${Math.abs(agencyBalance - totalAmountReserved - totalCost)}`)}</span>*/}
                                                      <span style={{width: 'max-content'}}>Additional Funds Required:&nbsp;</span><span
                                                    className={`text-${additionalFundsReq > 0 ? 'danger' : 'success'}`}
                                                    style={{width: 'max-content'}}>{(additionalFundsReq > 0 ? `$${additionalFundsReq}` : `$0`)}</span>
                                                  </div>
                                              </div>
                                              {/*<div className="row">
                                               {agencyBalance} - {totalAmountReserved} - {amountReserved} - {totalCost}
                                               </div>*/}
                                              {totalCost > (agencyBalance - totalAmountReserved + amountReserved) &&
                                                <div className="row d-flex flex-row justify-content-between align-items-center">
                                                    <span className="text-danger d-inline-block" style={{width: 'max-content'}}>You will have to add funds to your wallet, to continue</span>
                                                    <AddFundsComponent btnClassName="btn btn-primary" style={{maxWidth: '105px'}}/>
                                                </div>}
                                          </>}
                                          <div className="form-group w-100 d-flex flex-row">
                                              <div className="col-12 text-center pt-4">
                                                  <button
                                                    type="button"
                                                    className="btn btn-back border"
                                                    onClick={() => {
                                                        if (showChartingTab) tabHandler(2);
                                                        else if (showPatientSelectionTab) tabHandler(1);
                                                        else tabHandler(0);
                                                    }}
                                                  >
                                                      Back
                                                  </button>
                                                  <button type="submit" className="btn btn-primary"
                                                          disabled={isFreelanceNurse && totalCost > (agencyBalance - totalAmountReserved + amountReserved)}>
                                                      {isServiceRequestEdit ? "Update" : "Create"}
                                                  </button>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>}
                          </form>
                      </div>
                      {
                        isModalOpen && (
                          <AddTaskModal isModalOpen={isModalOpen} closeModal={() => closeModal()} modalTitle="Add Task"
                                        addTask={addTask} agencyId={agencyId}/>
                        )
                      }
                  </div>
              </div>
          </div>
      </div>
    );
};

const mapStateToProps = state => {
    const {auth, serviceRequest, transaction} = state;

    const data = serviceRequest?.serviceDetails?.data || null;
    const walletDetails = transaction?.agencyWalletDetails || null;
    // console.log('serviceRequest-data', data)
    let initialValues = {};

    if (data != null) {
        initialValues[`serviceRequestTypeId`] = data?.tasks?.[0].serviceRequestTypeId?.toString();
        initialValues[`payRateTypeId`] = data?.payRateTypeId?.toString() || '';
        initialValues[`agencyRateToClient`] = data?.agencyRateToClient?.toString() || '';
        initialValues[`nurseRate`] = data?.nurseRate?.toString() || '';
        initialValues[`firstName`] = data?.patientFirstName || '';
        initialValues[`lastName`] = data?.patientLastName || '';
        // initialValues[`middleName`] = data?.patientMiddleName?.toString();
        // initialValues[`age`] = data?.patientAge?.toString();
        initialValues[`dateOfBirth`] = data?.birthDate || '';
        initialValues[`email`] = data?.patientPrimaryEmail || '';
        initialValues[`addressLine1`] = data?.patientAddressLine1 || '';
        initialValues[`provinceOrStateId`] = data?.patientProvinceOrStateId?.toString() || '';
        initialValues[`postalOrZipCode`] = data?.patientPostalOrZipCode || '';
        initialValues[`genderId`] = data?.patientGenderId?.toString() || '';
        initialValues[`chooseClient`] = data?.patientId?.toString() || '';
        initialValues[`rateTypeSelection`] = data?.payRateTypeId === 1 && data?.nurseOvertimeRate == null ? '1' : '2';
        initialValues[`chooseOrCreateClientSelection`] = "false";
        initialValues[`iAgree`] = true;
        initialValues[`assignedNurseId`] = (data?.tasks?.[0]?.assignedNurseId || 0).toString();
        initialValues[`nurseOvertimeRate`] = data?.nurseOvertimeRate?.toString() || '';
        initialValues[`chartingTitle`] = data?.chartingTitle || '';
        initialValues[`chartingTypeId`] = data?.chartingTypeId?.toString() || '';
        initialValues[`templateId`] = data?.templateId?.toString() || '';
    } else {
// console.log('CHECK')
        initialValues[`serviceRequestTypeId`] = "";
        initialValues[`payRateTypeId`] = "";
        initialValues[`rateTypeSelection`] = '1';
        initialValues[`payRateTypeId`] = '1';
        initialValues[`agencyRateToClient`] = "";
        initialValues[`nurseRate`] = "";
        // initialValues[`nurseOvertimeRate`] = "";

        initialValues[`chooseOrCreateClientSelection`] = "false";
        initialValues[`chooseClient`] = "";
        initialValues[`firstName`] = "";
        // initialValues[`middleName`] = "";
        initialValues[`lastName`] = "";
        // initialValues[`age`] = "";
        initialValues[`dateOfBirth`] = "";
        initialValues[`email`] = "";
        initialValues[`addressLine1`] = "";
        initialValues[`provinceOrStateId`] = "";
        initialValues[`postalOrZipCode`] = "";
        initialValues[`genderId`] = "";
        initialValues[`iAgree`] = false;
    }

    return {
        isAdmin: auth?.authData?.isAdmin || false,
        agencyId: auth?.authData?.agencyId || auth.agencyId,
        walletDetails,
        formData: getFormValues('newServiceRequestForm')(state),
        initialValues,
    }
}

export default connect(mapStateToProps)(
  reduxForm({form: "newServiceRequestForm", enableReinitialize: true})(AddNewJobServiceRequest)
);
