import React, {useRef, useState} from "react";
import {useDispatch} from "react-redux";
import editIcon from "../../../../../Agency/assets/image/edit.svg";
import deleteIcon from "../../../../../Agency/assets/image/mdi_delete.svg";
import {Popover, Stack} from "@mui/material";
import moreVertical from "../../../../../Agency/assets/image/more-vertical.svg";
import {useNavigate, useParams} from "react-router";
import {HttpsAction} from "../../../../../commonApiFunction/httpsAction";

const PostedJobMoreOptionDropdown = ({id}) => {

    const dropdownRef = useRef(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {tabId, agencyId} = useParams();

    const [isDropdown, setIsDropdown] = useState(false);

    const OPTIONS_ARRAY = [
        {
            icon: editIcon,
            key: 'edit/view',
            name: 'Edit/View'
        },
        {
            icon: deleteIcon,
            key: 'deleteJob',
            name: 'Delete Job'
        },
    ];


    const handleSelectOption = (key) => {
        setIsDropdown(false);
        switch (key) {
            case "deleteJob":
                handleDelete()
                break;

            case "edit/view":
                navigate(`/admin/postJob/${id}/${agencyId}`);
                break;
        }
    };

    const handleDelete = () => {
        dispatch(HttpsAction({
            method: 'DELETE',
            apiSection: 'admin',
            apiName: 'jobs',
            queryParameter: `/${agencyId}/${id}`,
            positiveCallBack: () => {
                dispatch(HttpsAction({
                    apiSection: 'admin',
                    apiName: 'jobs',
                    queryParameter: `/${agencyId}`,
                    reduxKeyName: 'adminJobs',
                    reducerName: 'ADMIN',
                    actionType: 'GET_ADMIN_JOBS'
                }))
            }
        }))
    };

    return (
        <>
            <Stack ref={dropdownRef}>
                <div style={{cursor: 'pointer', height: 28.8, width: 28.8}} onClick={() => setIsDropdown(true)}>
                    <img src={moreVertical} alt={''}/>
                </div>
                <Popover
                    open={isDropdown}
                    anchorEl={dropdownRef.current}
                    onClose={() => setIsDropdown(false)}
                    anchorOrigin={{
                        vertical: 'bottom',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    PaperProps={{
                        style: {
                            boxShadow: 'unset',
                            width: '250px',
                            height: 'auto',
                            borderRadius: '5px',
                            marginTop: '5px',
                            border: '1px solid #D5D5D5'
                        },
                    }}
                >
                    <Stack sx={{padding: '10px 20px'}}>
                        <Stack sx={{fontFamily: 'Poppins', fontSize: '18px', fontWeight: 500}}>
                            More Options
                        </Stack>
                        <Stack sx={{gap: '10px', marginTop: '5px'}}>
                            {(OPTIONS_ARRAY || [])?.map((v, i) => (
                                <Stack
                                    sx={{
                                        flexDirection: 'row',
                                        gap: '10px',
                                        alignItems: 'center',
                                        cursor: 'pointer'
                                    }}
                                    key={i}
                                    onClick={() => handleSelectOption(v.key)}>
                                    <Stack>
                                        <img src={v.icon} alt={''}/>
                                    </Stack>
                                    <Stack
                                        sx={{
                                            fontFamily: 'Poppins',
                                            fontSize: '16px',
                                            fontWeight: 400,
                                            color: '#73737C'
                                        }}>
                                        {v.name}
                                    </Stack>
                                </Stack>
                            ))}
                        </Stack>
                    </Stack>
                </Popover>
            </Stack>
        </>
    )
};

export default (PostedJobMoreOptionDropdown);
