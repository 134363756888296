/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {Card} from "react-bootstrap";
import {useNavigate} from "react-router-dom";

import "../../assets/css/responsive.css";

const DashboardCard = ({title, numberOfNurses, isActive, handleClick = () => undefined, handleSeeAll = () => undefined}) => {
    // const backButtonTextSuffix = 'Nurses Overview';
    // const backLink = '/admin/dashboard';
    //
    // const navigate = useNavigate();
    //
    // const handleNavigate = (event, title) => {
    //     // event.preventDefault();
    //     if (title === "All Nurses") {
    //         navigate("/admin/all/nurses", {
    //             state: {
    //                 // type: title,
    //                 showBackButton: true,
    //                 backButtonTextSuffix,
    //                 backLink,
    //             },
    //         });
    //     }
    //     if (title === "Verified Nurses") {
    //         navigate("/admin/all/verifiedNurses", {
    //             state: {
    //                 // type: title,
    //                 showBackButton: true,
    //                 backButtonTextSuffix,
    //                 backLink,
    //             },
    //         });
    //     }
    //     if (title === "Unverified Nurses") {
    //         navigate("/admin/all/unverifiedNurses", {
    //             state: {
    //                 // type: title,
    //                 showBackButton: true,
    //                 backButtonTextSuffix,
    //                 backLink,
    //             },
    //         });
    //     }
    //     if (title === "Withdrawal Requests") {
    //         navigate("/admin/all/withdrawalRequest", {
    //             state: {
    //                 // type: title,
    //                 showBackButton: true,
    //                 backButtonTextSuffix,
    //                 backLink,
    //             },
    //         });
    //     }
    // };

    return (
        <Card
            className={`dashboard-card ${isActive ? "active" : ""}`}
            style={{width: "300px", height: "128px"}}
            onClick={() => handleClick(title)}
        >
            <Card.Body>
                <div className="card-content">
                    <p
                        className={`card-title ${isActive ? "active" : ""}`}
                        style={{color: isActive ? "#fff" : "#111"}}
                    >
                        {title}
                    </p>
                    <p className={`nurses-number ${isActive ? "active" : ""}`}>
                        {numberOfNurses}
                    </p>
                    <div className="chart-view-btn mt-2">
            <span>
              <a
                  // onClick={(e) => handleNavigate(e, title)}
                  onClick={(e) => handleSeeAll(e, title)}
                  className={`text-decoration-none ${isActive ? "active" : ""}`}
                  style={{cursor: "pointer"}}
              >
                See all
              </a>
            </span>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
};

export default DashboardCard;
